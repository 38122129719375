import { FormControlLabel, Grid } from "@mui/material";
import get from "lodash/get";
import { useIntl } from "react-intl";
import { FormikValues } from "formik";
import { ChangeEvent, FC } from "react";

import CustomDatePicker from "Components/CustomDatePicker";
import CheckboxStyled from "Components/CheckboxStyled";
import TextInput from "Components/ProductForm/TextInput";

import { getErrorsByName } from "Utils/formik";
import * as labelStyles from "Assets/scss/modules/label.module.scss";
import * as styles from "./index.module.scss";

interface TicketFormProps {
  formik: FormikValues;
  onDateChange: (name: string, date: string | null) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TicketForm: FC<TicketFormProps> = ({
  formik,
  onDateChange,
  onChange,
  onCheckboxChange
}) => {
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <label className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.availableFromDate' })}
        </label>
        <CustomDatePicker
          name="onlineProductInfo.availableFrom"
          selectedDate={formik?.values?.onlineProductInfo?.availableFrom || ''}
          onDateChange={(date) => onDateChange('onlineProductInfo.availableFrom', date)}
        />
      </Grid>
      <Grid item xs={6}>
        <label className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.toDate' })}
        </label>
        <CustomDatePicker
          name="onlineProductInfo.availableTo"
          selectedDate={formik?.values?.onlineProductInfo?.availableTo || ''}
          onDateChange={(date) => onDateChange('onlineProductInfo.availableTo', date)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="label.howManyTimesCanBeUsed"
          placeholder="placeholder.addInformation"
          disabled={!!formik?.values?.onlineProductInfo?.unlimitedUseQty}
          name="onlineProductInfo.useQty"
          value={get(formik?.values, 'onlineProductInfo.useQty') || ''}
          validationErrors={getErrorsByName(formik, 'onlineProductInfo.useQty')}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          className={styles.checkbox}
          classes={{
            root: styles.toastLabel,
          }}
          control={(
            <CheckboxStyled
              name="onlineProductInfo.unlimitedUseQty"
              onChange={onCheckboxChange}
              checked={!!formik?.values?.onlineProductInfo?.unlimitedUseQty}
            />
          )}
          label={intl.formatMessage({ id: 'label.unlimitetUse' })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="label.ticketsQuantity"
          placeholder="placeholder.specifyNumber"
          name="quantity"
          value={get(formik?.values, 'quantity') || ''}
          validationErrors={getErrorsByName(formik, 'quantity')}
          onChange={onChange}
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default TicketForm;

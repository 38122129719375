import { cloneDeep } from 'lodash';

import { getValidBountyType } from 'Utils/bounty';
import { BountyType, CompanyType, Currency, AllowMode, UserKind, VisibilityModeType } from 'Constants/enums';
import * as CurrencyUtils from 'Utils/currency';
import { isGranted } from 'Utils/grant';
import * as RoleDef from 'Utils/roleDef';
import { getOrgTypeAsEnum } from 'Utils/organization';
import { Settings } from 'Types/settings.interface';
import { UserData } from 'Types/userData.interface';
import { getAllowModeAsEnum, getRoleDefByName, mergeCapInfo, getExplicitlyAllowed, isForbidden, mergeListInfo } from 'Utils/baseSettings';
import * as ProductFlavour from 'Utils/productFlavour';
import * as ProductDefaults from 'Utils/productDefaults';
import { ALL } from 'Constants/op';
import { BaseSettings, CapInfo, ListInfo } from 'Types/baseSettings.interface';
import { StreamList } from 'Types/streamList.interface';

const productFlavour = ProductFlavour.getCurrent();
const { config: productDefaults } = ProductDefaults.getDefaults(productFlavour);

export function getSettingsPriorityOrder(settings: Settings) {
  const settingsPriorityOrder = [];

  if (settings?.userSettings) {
    settingsPriorityOrder.push(settings.userSettings);
  }

  if (settings?.companySettings) {
    settingsPriorityOrder.push(settings.companySettings);
  }

  if (settings?.orgTypeSettings) {
    settingsPriorityOrder.push(settings.orgTypeSettings);
  }

  if (settings?.systemSettings) {
    settingsPriorityOrder.push(settings.systemSettings);
  }

  return settingsPriorityOrder;
}

export function findFirst<T>(settings: Settings, callback: (s: BaseSettings) => T|null): T|null {
  let foundValue = null;
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);

  settingsPriorityOrder.find((s) => {
    foundValue = callback(s);
    return foundValue;
  });

  return foundValue !== null && foundValue !== undefined ? foundValue : null;
}

export function findAllByKey<T>(settings: Settings, callback: (s: BaseSettings) => Record<string, T>|null, key: string): T[] {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  const list: T[] = [];

  settingsPriorityOrder.forEach((s) => {
    const result: Record<string, T>|null = callback(s);

    if (result && result[key]) {
      list.push(result[key]);
    }
  });

  return list;
}

export function mergeAll<T>(settings: Settings, fetcher: (s: BaseSettings) => Record<string, T>|null, merger: any, key: string): T|null {
  const list: T[] = findAllByKey<T>(settings, fetcher, key);

  if (!list || !list.length) {
    return null;
  }

  if (list.length === 1) {
    return list[0];
  }

  list.reverse();
  let finalValue = list[0];

  list.forEach((item) => {
    finalValue = merger(finalValue, item);
  });

  return finalValue;
}

export function mapAll<T>(settings: Settings, fetcher: (s: BaseSettings) => Record<string, T>|null, list = {}) {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  const listClone: Record<string, T> = cloneDeep(list);

  settingsPriorityOrder.forEach((s) => {
    const mapResult = fetcher(s);

    if (!mapResult) {
      return;
    }

    Object.keys(mapResult).forEach((key) => {
      if (!listClone[key]) {
        listClone[key] = mapResult[key];
      }
    });
  });

  return listClone;
}

export function forEachSettings(settings: Settings, callback: any) {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  settingsPriorityOrder.forEach((item) => {
    callback(item);
  });
}

// ------------------------------------------------------------------------ //
//     ********************  App-relevant methods ********************      //
//  ----------------------------------------------------------------------- //
export const getCapInfo = (settings: Settings, op: string): CapInfo|null => (
  mergeAll<CapInfo>(settings, (s: BaseSettings) => (s.capInfos), mergeCapInfo, op)
);

/**
 * @param settings {Object}
 * @param role {String}
 * @returns {Object}
 */
function getRoleDef({ settings, role }: { settings: Settings; role: string }) {
  let roleDef = findFirst(settings, (s: BaseSettings) => getRoleDefByName(s.roles, role));

  if (!roleDef && productDefaults) {
    roleDef = ProductDefaults.getRoleDef(role);
  }

  return roleDef;
}

/**
 * Prepares an array with all the role names a user can take base on the 'roles' property from user data and if the
 * array is empty, the user will be assigned an array with the default role (user role)
 * @param userData {Object} which contains 'roles' property
 * @returns {Array} of role names (ex: [USER])
 */
function getMyRoles(userData: UserData) {
  const roles = RoleDef.parseRolesList(userData ? userData.roles : null);
  return roles.length ? roles : RoleDef.DEFAULT_USER_ROLES;
}

/**
 * Check if a user is allowed to do a certain operation
 * settings {Object} contains userSettings, companySettings, orgTypeSettings and systemSettings
 */
export const isAllowed = ({ settings, userData, op }: { settings: Settings; userData: UserData; op: string }) => {
  const capInfo = getCapInfo(settings, op);
  const allowMode = capInfo ? getAllowModeAsEnum(capInfo.allowMode) : productDefaults.getAllowed(op);

  if (ProductDefaults.AllowMode.isSupreme(allowMode)) {
    return ProductDefaults.AllowMode.isAllowed(allowMode);
  }

  const myRoles = getMyRoles(userData);

  let isOpAllowed = null;
  let isOpForbidden = null;
  const rolesDef = myRoles
    .map((role) => getRoleDef({ settings, role }))
    .filter((def) => !!def);

  rolesDef.find((role) => {
    if (!role) {
      return false;
    }

    const fn = role?.getExplicitlyAllowed || getExplicitlyAllowed;
    const isExplicitlyAllowed = fn(op, role.ops);

    if (role && isExplicitlyAllowed !== null) {
      isOpAllowed = isExplicitlyAllowed;
      return isExplicitlyAllowed;
    }

    return false;
  });

  if (isOpAllowed !== null) {
    return isOpAllowed;
  }

  rolesDef.find((role) => {
    if (role?.isDefault && role?.ops?.[op]) {
      isOpAllowed = true;
      return true;
    }

    return false;
  });

  rolesDef.find((role) => {
    const fn = (role && role.isForbidden) || isForbidden;

    if (fn(op)) {
      isOpForbidden = true;
      return isOpForbidden;
    }

    return false;
  });

  if (isOpForbidden) {
    return false;
  }

  rolesDef.find((role) => {
    if (!role) {
      return false;
    }

    const fn = role.getExplicitlyAllowed || getExplicitlyAllowed;
    const isAllExplicitAllowed = fn(ALL.name, role.ops) || null;

    if (role && isAllExplicitAllowed !== null) {
      isOpAllowed = isAllExplicitAllowed;
      return isAllExplicitAllowed;
    }

    return false;
  });

  if (isOpAllowed !== null) {
    return isOpAllowed;
  }

  if (allowMode !== AllowMode.Default) {
    return ProductDefaults.AllowMode.isAllowed(allowMode);
  }

  return false;
};

/**
 * @param settings {Object}
 * @param roleName {String}
 * @param roleDef {Object}
 * @returns {Object}
 */
export function getBountyTypes(settings: Settings, roleName: string, roleDef: any) {
  if (roleDef === null || roleDef === undefined) {
    return {};
  }

  const result = mapAll(settings, (s: BaseSettings) => {
    if (!s.bountyTypes) {
      return null;
    }

    const map: Record<string, boolean> = {};

    Object.keys(s.bountyTypes).forEach((type) => {
      const value = s.bountyTypes[type];
      const bt = getValidBountyType(type);
      let allowed = value.enabled && roleDef.isAllowedUnrestrictedAccess && roleDef.isAllowedUnrestrictedAccess(bt);

      if (!allowed) {
        allowed = isGranted(value.grant, [roleName]);
      }

      if (![BountyType.None, BountyType.Unknown].includes(bt)) {
        map[bt] = allowed;
      }
    });

    return map;
  });

  if (productDefaults.bountyTypes) {
    productDefaults.bountyTypes.forEach((type) => {
      if (!result[type]) {
        result[type] = roleDef.isAllowedUnrestrictedAccess && roleDef.isAllowedUnrestrictedAccess(type);
      }
    });
  }

  return result;
}

/**
 * Get available bounty types base on the user configuration
 * @param settings {object}
 * @param userData {object}
 * @returns {Array}
 */
export function getAvailableBountyTypes(settings: Settings, userData: UserData) {
  const map: Record<string, boolean> = {};
  const myRoles = getMyRoles(userData);

  myRoles.forEach((role) => {
    const bountyTypes = getBountyTypes(settings, role, getRoleDef({ settings, role }));

    Object.keys(bountyTypes).forEach((type) => {
      map[type] = map[type] || bountyTypes[type] || false;
    });
  });

  const types: string[] = [];

  Object.keys(map).forEach((key) => {
    if (map[key]) {
      types.push(key);
    }
  });

  return types;
}

export function isSocialPostEnabled(bountyType: BountyType) {
  switch (bountyType) {
    case BountyType.TalentSearch:
    case BountyType.RealEstateCustomer:
      return true;
    default:
      return false;
  }
}

export function hasAccessToBountyType(bountyType: BountyType, settings: Settings, userData: UserData) {
  return bountyType != null && getAvailableBountyTypes(settings, userData).includes(bountyType);
}

export function isPartOfCompany(userData: UserData) {
  return !!(userData && userData.company);
}

export function getMyCurrency(settings: Settings, userData: UserData) {
  const currency = findFirst<Currency>(settings, (s) => s.currency);

  if (currency && CurrencyUtils.isValidCurrency(currency)) {
    return currency;
  }

  if (!isPartOfCompany(userData)) {
    // for individuals, default a currency based on country
    if (userData != null && userData.countryCode != null) {
      return CurrencyUtils.getCurrencyByCountry(userData.countryCode);
    }
  }

  return Currency.USD;
}

export function getOrganizationType(settings: Settings) {
  return findFirst(settings, (s) => s.organizationType);
}

export function getOrgTypes(settings: Settings, roleName: string, roleDef: any) {
  if (roleDef === null || roleDef === undefined) {
    return {};
  }

  return mapAll(settings, (s: BaseSettings) => {
    if (!s.allowedOrgTypes) {
      return null;
    }

    const map: Record<string, boolean> = {};

    Object.keys(s.allowedOrgTypes).forEach((type) => {
      const value = s.allowedOrgTypes[type];
      const bt = getOrgTypeAsEnum(type);
      let allowed = value.enabled;

      if (!allowed) {
        allowed = isGranted(value.grant, [roleName]);
      }

      if (![CompanyType.None, CompanyType.Unknown].includes(bt)) {
        map[bt] = allowed;
      }
    });

    return map;
  });
}

function getListInfo(settings: Settings, listId: string): ListInfo|null {
  return mergeAll<ListInfo>(settings, (s: BaseSettings) => (s.listInfos), mergeListInfo, listId);
}

export function getAvailableOrganizationTypes(settings: Settings, userData: UserData) {
  const map: Record<string, boolean> = {};
  const myRoles = getMyRoles(userData);

  myRoles.forEach((role) => {
    const orgTypes = getOrgTypes(settings, role, getRoleDef({ settings, role }));

    Object.keys(orgTypes).forEach((type) => {
      map[type] = orgTypes[type] || false;
    });
  });

  const types: string[] = [];

  Object.keys(map).forEach((key) => {
    if (map[key]) {
      types.push(key);
    }
  });

  return types;
}

function getCustomListById(settings: Settings, listId: string) {
  if (!listId) {
    return null;
  }

  return findFirst(settings, (item) => {
    if (!item.customLists || !item.customLists.lists) {
      return null;
    }

    return item.customLists.lists[listId];
  });
}

function getEffectiveCustomListById(settings: Settings, listId: string) {
  const def = getCustomListById(settings, listId);

  if (def === null) {
    return null;
  }

  const listInfo = getListInfo(settings, listId);

  if (listInfo === null) {
    return def;
  }

  const defClone = JSON.parse(JSON.stringify(def));

  if (defClone.visibilityType === VisibilityModeType.User) {
    if (defClone.visibilityInfo === null) {
      defClone.visibilityInfo = {};
    }

    defClone.visibilityInfo.visibilityType = VisibilityModeType.Visible;
  }

  if (listInfo.rank !== null) {
    defClone.rank = listInfo.rank;
  }

  if (!listInfo.permissionType) {
    defClone.permissionType = listInfo.permissionType;
  }

  return defClone;
}

function getCustomLists(settings?: Settings) {
  let customLists = {};

  if (settings) {
    forEachSettings(settings, (s: BaseSettings) => {
      if (s.customLists) {
        customLists = { ...customLists, ...s.customLists.lists };
      }
    });
  }

  return customLists;
}

export function getEffectiveCustomLists(settings: Settings) {
  const customLists: Record<string, StreamList> = getCustomLists(settings);
  const set: Record<string, StreamList> = {};

  Object.keys(customLists).forEach((keyDef) => {
    const listDef = getEffectiveCustomListById(settings, keyDef);

    if (listDef !== null) {
      set[keyDef] = listDef;
    }
  });

  return set;
}

export function isEmployer(companyType?: CompanyType) {
  return companyType === CompanyType.Employer;
}

export const isCompany = (kind?: UserKind) => kind === UserKind.Company;

import {
  postEvent,
  deleteApiData,
  getApiData,
  postApiData,
  postRequest,
  postWithEmptyResponse,
  putApiData,
} from 'Services/Api';
import {
  firebaseGetCurrentlySignedInUser,
  firebaseGetCurrentUser,
  firebaseGetMyAccountRef,
  firebaseGetNotificationsRef,
  firebaseGetUserCommentsRef,
  firebaseGetUserDataRef,
  firebaseGetUserRef,
  getMyBlocksRef,
  getSocialActionStateRef,
} from 'Services/FirebaseService';
import { uploadContractAttachment, uploadUserPicture } from 'Services/UploadService';
import {
  AUDIT,
  GET_FOLLOWERS_API,
  GET_FOLLOWING_API,
  GET_USER_CURRENCIES,
  UNASSIGN_MEMBER_API,
  UPDATE_USER_API,
  UPDATE_USER_INFO_API,
  USER_ACCOUNT_API,
  USER_AGREEMENT_API,
  USER_META_ROUTE,
  USER_POLICY_ROUTE,
  USER_TRANSACTIONS_API,
} from 'Constants/apiRoutes';
import { getQueryString } from 'Utils/general';
import { newComment } from 'Utils/comment';
import { AudienceType, AuditObjectType, CommentType, EnvironmentMode, QueueAlias, UserSocial } from 'Constants/enums';
import { CardInfo, CreateCreditCart } from 'Types/cardInfo.interface';
import { UpdateUserResult, UserData, UserInfo } from 'Types/userData.interface';
import { Follower, UpdateUserApi, User } from 'Types/user.interface';
import { Reward } from 'Types/reward.interface';
import { Point } from 'Types/point.interface';
import { UserSocialData } from 'Types/userSocialData.interface';
import { Attachment } from 'Types/attachment.interface';
import { OptimizedUserData } from 'Types/optimizedUserData.interface';
import { ReqProps } from 'Types/reqProps.interface';
import { CompanyUserPrivateInfo } from 'Types/companyUserPrivateInfo.interface';
import { AllowedRole } from 'Types/allowedRole.interface';
import { UserPrivateInfo } from 'Types/userPrivateInfo.interface';
import { KYCData } from 'Types/KYCData.interface';
import { ListResponse } from 'Types/listResponse.interface';
import { AuditLog } from 'Types/auditLog.iterface';
import { AuditLogsRequest } from 'Types/auditLogsRequest.type';
import { UserUpdateReq } from 'Types/userUpdateReq.interface';
import { JournalComment } from 'Types/journalComment.interface';
import { formatApiRoute } from 'Utils/api';
import { AccountInfo } from 'Types/accountInfo.interface';
import { CustomCurrencyStats } from 'Types/currency.interface';
import { Agreement } from 'Types/agreement.interface';
import { Policy } from 'Types/policy.interface';
import { TransactionList } from "Types/transactionList.interface";

export const saveCreditCard = ({ fbPathId, token, cardInfo, companyId }: CreateCreditCart): Promise<CardInfo> => (
  new Promise((resolve) => {
    const ref = firebaseGetUserDataRef(fbPathId).child('payments').push();
    const cardInfoClone = { ...cardInfo };
    cardInfoClone.id = ref.key as string;

    ref.set(cardInfoClone);

    postEvent(
      QueueAlias.NewCreditCard,
      {
        cardId: cardInfoClone.id,
        stripeTokenId: token.id,
        cardInfo: cardInfoClone,
        ...(companyId ? { companyId } : {}),
      },
    );

    resolve(cardInfoClone as CardInfo);
  })
);

export const setMyPoints = ({ amount, currency }: Point) => {
  const currentUser: any = firebaseGetCurrentUser();
  firebaseGetMyAccountRef(currentUser.uid).child(`points/${currency}/amount`).set(amount);
};

export const checkSocialActionState = (myUserId: string, userId: string, socialActionType: string) => new Promise((resolve) => {
  getSocialActionStateRef(userId, socialActionType)
    .once('value', (dataSnapshot) => resolve(dataSnapshot.val()));
});

export const updateSocialActionState = (userId: string, socialActionType: string, userSocial: UserSocialData | null) => {
  const ref = getSocialActionStateRef(userId, socialActionType);

  if (userSocial != null) {
    return ref.set(userSocial);
  }

  ref.remove();
};

export const reportUser = ({ loggedUserId, userId, reason }: { loggedUserId: string, userId: string, reason: string }) => {
  // TODO: is what logic still actual?
  const userBlock = {
    blockedUserId: userId,
    reason,
    blockedAt: new Date().getTime(),
  };

  getMyBlocksRef(loggedUserId).child(userId).set(userBlock);
  postEvent(QueueAlias.ReportUser, { reportedUserId: userId, reason });
};

export const updateAccount = ({ userId, adjSet, adjReward, note }: { userId: string, adjSet: boolean, adjReward: Reward, note: string | null}) => {
  const adjData = {
    accountId: userId,
    set: adjSet,
    reward: adjReward,
    note,
  };

  postEvent(QueueAlias.UpdateAccount, adjData);
};

export const followUser = ({ userId, follow, assumeSuccess }: { userId: string, follow: boolean, assumeSuccess: boolean }) => {
  const userSocial: UserSocialData = {
    actedAt: new Date().getTime(),
    pending: !assumeSuccess,
  };

  postEvent(QueueAlias.FollowUser, { userId, follow });
  updateSocialActionState(userId, UserSocial.Follow, follow ? userSocial : null);
};

export const updateCompanyPicture = (userData: UserData, file: Attachment, userId: string, attachments?: Attachment[]) => async () => {
  const attachment = await uploadUserPicture(attachments, file, userData, userId);
  const photoURL = attachment?.url || null;

  postEvent(QueueAlias.UpdateCompany, { avatar: photoURL, companyId: userData?.company?.id || null });
};

export const getUserCommentsRef = (userId: string) => firebaseGetUserCommentsRef().child('users').child(userId);

export const deleteMyNotifications = () => {
  const user: any = firebaseGetCurrentUser();
  firebaseGetNotificationsRef(user.uid).remove();
};

const isMe = (user: User) => {
  const me: any = firebaseGetCurrentUser();
  return user && user.id === me.uid;
};

export async function updateUserPicture(user: User, avatar: string) {
  if (isMe(user)) {
    firebaseGetUserRef(user.id).child('avatar').set(avatar);
  }

  UserService.updateUser({ userId: user.id, avatar });
}

export async function prepareGroupAttachment(
  params: { userData: UserData, groupProfile: { user: User }, file: Attachment; isGroupAvatar: boolean },
) {
  const { userData, groupProfile, file, isGroupAvatar } = params;
  const attachment = await uploadUserPicture([], file, userData, groupProfile?.user?.id);
  const photoURL = attachment?.url || '';

  if (isGroupAvatar) {
    return updateUserPicture(groupProfile.user, photoURL);
  }
}

export const UserService = {
  getAudits: (id: string, options: AuditLogsRequest): Promise<ListResponse<AuditLog>> => {
    const queryString = getQueryString({ ...options, object_type: AuditObjectType.User, object_id: id });

    return getApiData<ListResponse<AuditLog>>(`${AUDIT}${queryString}`);
  },

  getUser: (id: string): Promise<UserData> => getApiData<UserData>(`/api/v1/users/${id}/profile`),

  getUserInfo: (): Promise<UserInfo> => getApiData<UserInfo>('/api/v1/user/info'),

  getCompanyPrivateInfo: (id: string): Promise<CompanyUserPrivateInfo> => getApiData<CompanyUserPrivateInfo>(`/api/v1/users/${id}/private_data`),

  getUserAccountInfo: (id: string): Promise<AccountInfo> => {
    const endpoint = formatApiRoute({
      endpoint: USER_ACCOUNT_API,
      params: { userId: id },
      queryParams: {},
    });

    return getApiData<AccountInfo>(endpoint);
  },

  getUserTransactions: (id: string, queryParams = {}): Promise<TransactionList[]> => {
    const endpoint = formatApiRoute({
      endpoint: USER_TRANSACTIONS_API,
      params: { userId: id },
      queryParams,
    });

    return getApiData<TransactionList[]>(endpoint);
  },

  getKYC: (id: string): Promise<KYCData> => getApiData<KYCData>(`/api/v1/users/${id}/profile/kyc`),

  // TODO: add response type
  getKYB: (companyId: string) => getApiData(`/api/v1/entities/USER/${companyId}/tags/history`),

  getPrivateInfo: (id: string, options?: Partial<{ show_kyc?: boolean }>): Promise<UserPrivateInfo> => {
    const reqParams = options ? getQueryString(options) : '';

    return getApiData<UserPrivateInfo>(`/api/v1/users/${id}/private${reqParams}`);
  },

  getAllowedRoles: (id: string): Promise<{ roles: AllowedRole[]; }> =>
    getApiData<{ roles: AllowedRole[]; }>(`/api/v1/users/${id}/roles/allowed`),

  updateUserProfile: (userId: string, updates: UserUpdateReq): Promise<void> =>
    postEvent(QueueAlias.UpdateUserProfile, { ...updates, userId }),

  updateAmbassadorProfile: (userId: string, updates: UserUpdateReq): Promise<void> =>
    postEvent(QueueAlias.UpdateUser, { ...updates, userId }),

  updateUserDetails: ({ payload, userId }: { payload: UpdateUserApi, userId: string }): Promise<void> => (
    putApiData(formatApiRoute({ endpoint: UPDATE_USER_API, params: { userId } }), payload)
  ),

  updateUser: (payload?: any): Promise<void> => postEvent(QueueAlias.UpdateUser, payload),
  updateUserRequest: (userId: string, payload: any): Promise<UpdateUserResult> => (
    putApiData<UpdateUserResult>(
      formatApiRoute({ endpoint: UPDATE_USER_INFO_API, params: { userId } }),
      payload,
    )
  ),

  updateEmployer: (companyId: string, updates: any): Promise<void> =>
    postEvent(QueueAlias.UpdateCompany, { ...updates, companyId }),

  uploadContract: (
    attachment: Attachment,
    user: OptimizedUserData,
    userData: UserData,
    attachmentId: string,
  ) => uploadContractAttachment(attachment, user, userData, attachmentId),

  removeAttachment: (userId: string, attachmentId: string) => postEvent(QueueAlias.DeleteAttachment, { userId, attachmentId }),

  updateEnvironmentMode: async (userId: string, isTestUser: boolean) => {
    const token = await firebaseGetCurrentlySignedInUser();
    const envMode = isTestUser ? EnvironmentMode.Test : EnvironmentMode.Default;

    return postWithEmptyResponse(`/api/v1/users/${userId}/envMode?envMode=${envMode}`, token);
  },

  deleteUser: async (userId: string) => deleteApiData(`/api/v1/users/${userId}`),

  updatePIN: async (userId: string, pin: string) => {
    const token = await firebaseGetCurrentlySignedInUser();

    return postWithEmptyResponse(`/api/v1/users/${userId}/pin?pin=${pin}&force=true`, token);
  },

  editUserRoles: (id: string, roles: string) => {
    const data = {
      granteeId: id,
      scope: 'USER',
      grant: true,
      roles,
    };

    postEvent(QueueAlias.UpdateCaps, data);
  },

  updateInviteCode: async (userId: string, inviteCode: string) => {
    const token = await firebaseGetCurrentlySignedInUser();

    return postWithEmptyResponse(`/api/v1/users/${userId}/referral_code?referral_code=${inviteCode}`, token);
  },

  updateBlacklistStatus: async (userId: string, blacklistStatus: boolean) => {
    const token = await firebaseGetCurrentlySignedInUser();
    const status = blacklistStatus ? 'blacklist' : 'unblacklist';

    return postWithEmptyResponse(`/api/v1/users/${userId}/${status}`, token);
  },

  getJournal: (id: string, options: ReqProps): Promise<ListResponse<JournalComment>> => {
    const reqOptions = {
      ...options,
      audience_type: AudienceType.Journal,
    };

    const queryString = getQueryString(reqOptions);

    return getApiData<ListResponse<JournalComment>>(`/api/v1/users/${id}/comments${queryString}`);
  },

  addJournalComment: async (message: string, userId: string, callBack: () => void): Promise<void> => {
    const comment = await newComment(message, CommentType.User, userId);

    postRequest(QueueAlias.AddComment, { comment: { ...comment, audienceType: AudienceType.Journal } })
      .then(() => callBack?.());
  },

  editJournalComment: (comment: Record<string, Comment>, callBack: () => void): void => {
    const { id, ...otherData } = comment;
    postRequest(QueueAlias.EditComment, { ...otherData, commentId: id, audienceType: AudienceType.Journal })
      .then(() => callBack?.());
  },

  removeJournalComment: (commentId: string | null, callBack: () => void): void => {
    postRequest(QueueAlias.DeleteComment, { commentId }).then(() => callBack?.());
  },

  createPSGChat: (reqOptions : { userId?: string, orderId?: string, autoJoin?: boolean, companyId?: string }): Promise<{ roomId: string }> => {
    const queryString = getQueryString(reqOptions);

    return getApiData(`/api/v1/chats/support${queryString}`);
  },

  unassignUser: ({ userId }: { userId: string }) => (
    postApiData(formatApiRoute({ endpoint: UNASSIGN_MEMBER_API, params: { userId } }))
  ),

  getFollowers: (userId: string, queryParams: { start: number, length: number }): Promise<ListResponse<Follower>> => (
    getApiData(formatApiRoute({
      endpoint: GET_FOLLOWERS_API,
      params: { userId },
      queryParams: queryParams || {},
    }))
  ),

  getFollowing: (userId: string, queryParams: { start: number, length: number }): Promise<ListResponse<Follower>> => (
    getApiData(formatApiRoute({
      endpoint: GET_FOLLOWING_API,
      params: { userId },
      queryParams: queryParams || {},
    }))
  ),

  getCurrencies: (userId: string): Promise<CustomCurrencyStats[]> => (
    getApiData(formatApiRoute({
      endpoint: GET_USER_CURRENCIES,
      params: { userId },
    }))
  ),

  getUserMeta: (userId: string): Promise<{ supportAgent: boolean }> => (
    getApiData(formatApiRoute({
      endpoint: USER_META_ROUTE,
      params: { userId },
      queryParams: {},
    }))
  ),

  confirmAgreement: (userId: string, agreement: Agreement) => (
    postApiData(formatApiRoute({ endpoint: USER_AGREEMENT_API, params: { userId } }), agreement)
  ),

  updateUserPolicy: (userId: string, policy: Policy) => (
    postApiData(
      formatApiRoute({
        endpoint: USER_POLICY_ROUTE,
        params: { userId },
        queryParams: {},
      }),
      policy,
    )
  ),
  markFlowAsDone: (userId: string, flow: string) => (
    firebaseGetUserDataRef(userId).child("flows").child(flow).set(true)
  )

};

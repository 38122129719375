import { GenericReducerProp } from 'Constants/genericReducerProp';
import { RootState } from 'Types/redux.types';

export const userStreams = (state: RootState) => state.bountyReducer?.userStreams;
export const bountiesSelector = (state: RootState) => state.bountyReducer?.bounties;

export const answerDetails = (state: RootState) => state.bountyReducer?.answerDetails;

export const answerComments = (state: RootState) => (
  state.bountyReducer?.answerDetails
    ? state.bountyReducer.answerDetails.comments : []
);

export const bountyDetails = (state: RootState) => state.bountyReducer?.bountyDetails;

export const bountyComments = (state: RootState) => state.bountyReducer?.bountyComments;
export const bountyRefreshCountSelector = (state: RootState) => state.bountyReducer[GenericReducerProp.RefreshBountyDetails];
export const bountyAnswers = (state: RootState) => state.bountyReducer?.bountyAnswers;
export const bountyAnswersComments = (state: RootState) => state.bountyReducer.bountyAnswersComments;

export const bountyDefaultFilter = (state: RootState) => state.bountyReducer?.defaultFilter;

export const bountyDefaultSort = (state: RootState) => state.bountyReducer?.defaultSort;

export const subBounties = (state: RootState) => state.bountyReducer?.subBounties;

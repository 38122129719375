import * as DateUtils from 'Utils/date';
import { BountyState } from 'Constants/enums';
import { Bounty } from 'Types/bounty.interface';

export const ACTIVE_BOUNTY_STATES: BountyState[] = [
  BountyState.Active,
  BountyState.Paused,
  BountyState.Sealed,
];

export const DRAFT_BOUNTY_STATES: BountyState[] = [
  BountyState.None,
  BountyState.Draft,
  BountyState.Pending,
  BountyState.Retracted,
  BountyState.Rejected,
];

export const CLOSED_BOUNTY_STATES: BountyState[] = [
  BountyState.Closed,
  BountyState.Expired,
  BountyState.Archived,
];

export const JOB_BOUNTY_STATES: BountyState[] = [
  ...ACTIVE_BOUNTY_STATES,
  ...DRAFT_BOUNTY_STATES,
  ...CLOSED_BOUNTY_STATES,
]
  .filter((b) => !([BountyState.Accepted, BountyState.Declined, BountyState.New, BountyState.Refused].includes(b)));

export const isDraftState = (state: BountyState): boolean =>
  DRAFT_BOUNTY_STATES.includes(state as BountyState);

export const isActiveState = (state: BountyState): boolean =>
  ACTIVE_BOUNTY_STATES.includes(state);

export const isPostableState = (state: BountyState): boolean =>
  [BountyState.Active].includes(state);

export const isClosedState = (state: BountyState): boolean =>
  CLOSED_BOUNTY_STATES.includes(state);

export const isEditableState = (state: BountyState): boolean =>
  isDraftState(state) || isActiveState(state);

export const isExpiredState = (bounty: Bounty): boolean => {
  const { state, expiresAt } = bounty;
  return state === BountyState.Expired || (state === BountyState.Active && !!DateUtils.isExpired(expiresAt!));
};

export const isAllowedToPublish = (state: BountyState): boolean =>
  [BountyState.Draft, BountyState.Retracted].includes(state);

export const isAllowedToRetract = (state: BountyState): boolean => [
  BountyState.Pending,
  BountyState.Active,
  BountyState.Sealed,
  BountyState.Paused,
  BountyState.Closed,
  BountyState.Expired,
]
  .includes(state);

export const isAllowedToDelete = (state: BountyState): boolean => [
  BountyState.Draft,
  BountyState.Rejected,
  BountyState.Retracted,
  BountyState.Archived,
  BountyState.Closed,
]
  .includes(state);

export const isAllowedToPublishResponse = (state: BountyState): boolean =>
  [BountyState.Draft, BountyState.Retracted, BountyState.Rejected].includes(state);

export const isAllowedToMarkAsOfficial = (state: BountyState): boolean =>
  [BountyState.Active, BountyState.Paused, BountyState.Closed].includes(state);

export const isAllowedToPin = (state: BountyState): boolean => [
  BountyState.Active,
  BountyState.Paused,
  BountyState.Closed,
  BountyState.Expired,
]
  .includes(state);

export const isAllowedToReject = (state: BountyState): boolean => [
  BountyState.Pending,
  BountyState.Active,
  BountyState.Paused,
  BountyState.Sealed,
  BountyState.Closed,
  BountyState.Expired,
  BountyState.Archived,
]
  .includes(state);

export const isAllowedToSend = (state: BountyState): boolean => [
  BountyState.Active,
  BountyState.Paused,
]
  .includes(state);

export const isAllowedToClose = (state: BountyState): boolean => [
  BountyState.Active,
  BountyState.Paused,
]
  .includes(state);

export const isAllowedToSimulate = (state: BountyState): boolean => [
  BountyState.Active,
  BountyState.Paused,
]
  .includes(state);

export const isCommentingAllowed = (state: BountyState): boolean =>
  isActiveState(state) || isClosedState(state);

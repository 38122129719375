import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'viitorul',
  title: 'FC Viitorul',
  name: 'FC Viitorul app',
  brandName: 'FC Viitorul',
  appLinkSchema: 'viitorul',
  appTld: 'fcviitorul.ro',
  tags: ['all', 'sports'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  TERMS_OF_SERVICE_URL: 'https://www.fcviitorul.ro/termeni-conditii',
  PRIVACY_STATEMENT_URL: 'https://www.fcviitorul.ro/politica-confidentialitate',
};

export default config;

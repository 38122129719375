export const paginationItemsPerPage = 10;
export const paginationItemsPerPageMobile = 3;

export const KEY_SEP = '-';
export const FB_MATCH_ALL = '\\uf8ff';

// TODO: Move dates const to dates.ts constants
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAYED_DATE_FORMAT = 'dd/MM/yyyy';
export const SECONDARY_DISPLAYED_DATE_FORMAT = 'MM-dd-yyyy';
export const FULL_DATE_FORMAT = 'MMM dd, yyyy, HH:mm';
export const MONTH_HOURS_FORMAT = 'MMM dd, HH:mm';
export const SHORT_YEAR_FORMAT = 'MMM d, ‘yy';
export const SHORT_DATE_FORMAT = 'MMM dd, yyyy';
export const MONTH_FORMAT = 'MMMM';
export const TIME_FORMAT = 'HH:mm';

export const NO_AVATAR_URL = 'https://rebounty.com/s/user-avatar.png';

export const ADMIN_ID = '-MEZQajk4b9mGSy6T6eR';

export const DEFAULT_SURVEY_INITIAL_OPTIONS = 2;

export const TRANSITION_TIMEOUT = {
  enter: 500,
  exit: 500,
};

export const DEFAULT_PIN = '1515';
export const ADMIN_PIN = '101';

// Duplicates of Bootstrap breakpoints
export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const DISCLAIMER_MESSAGE_LENGTH = 500;
export const WARNING_MESSAGE_LENGTH = 500;
export const CONSENT_MESSAGE_LENGTH = 255;
export const BADGE_CODE_SEPARATOR = '--';
export const FILTER_CODE_SEPARATOR = '--';
export const NOT_APPLICABLE = 'n/a';

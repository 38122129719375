import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import Select from '@mui/material/Select';
import { BaseSelectProps } from '@mui/material/Select/Select';
import ControlValidation from 'Components/ControlValidation';
import * as styles from './styles.module.scss';

interface StyledSelectProps extends BaseSelectProps {
  className?: string;
  classNamePaper?: string;
  children?: ReactNode | ReactNode[];
  isSuccess?: boolean;
  validationErrors?: string[] | ReactNode[];
}

const StyledSelect: FC<StyledSelectProps> = ({
  className = '',
  classNamePaper = '',
  children,
  isSuccess = false,
  validationErrors = [],
  ...otherProps
}) => (
  <div
    className={classnames({ [className]: className })}
  >
    <Select
      className={classnames(styles.selectStyled, {
        [className]: className,
        [styles.stateInvalid]: validationErrors?.length > 0,
        [styles.stateSuccess]: isSuccess,
        [styles.stateDisabled]: otherProps.disabled,
        [styles.emptyValue]: !otherProps.value,
      })}
      {...otherProps}
      MenuProps={{ classes: { paper: classnames(styles.dropdownStyle, classNamePaper) }, variant: 'menu' }}
    >
      {children}
    </Select>

    {validationErrors?.length > 0 && <ControlValidation validationErrors={validationErrors} />}
  </div>
);

export default StyledSelect;

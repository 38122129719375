import { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';

import CustomInput from 'Components/CustomInput';
import ControlValidation from 'Components/ControlValidation';

import { getErrorsByName } from 'Utils/formik';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';

interface Range {
  min: number;
  max: number;
}

interface RangeValidation {
  days: Range,
  hours: Range;
  minutes: Range
}

const validation: RangeValidation = {
  days: {
    min: 0,
    max: 100,
  },
  hours: {
    min: 0,
    max: 23,
  },
  minutes: {
    min: 0,
    max: 59,
  },
};

interface ExpiryFormProps {
  values: any; // TODO: add type as we add data type in parent component
  onChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
  errorMessage?: string[];
}

const ExpiryForm: FC<ExpiryFormProps> = ({ onChange, values, errorMessage = [] }) => {
  const intl = useIntl();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const valAsNumber = Number(event.target.value);
    const propName = event.target.name as keyof RangeValidation;

    if (valAsNumber < validation[propName].min || valAsNumber > validation[propName].max) {
      return null;
    }

    onChange(event);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'labels.days' })}
        </Typography>
        <CustomInput
          placeholder={intl.formatMessage({ id: 'labels.days' }).toLowerCase()}
          name="days"
          value={values?.days || ''}
          onChange={handleInputChange}
          validationErrors={getErrorsByName(values, 'days')}
          hasError={errorMessage?.length > 0}
          {...validation.days}
        />
      </Grid>
      <Grid item xs>
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'labels.hours' })}
        </Typography>
        <CustomInput
          placeholder={intl.formatMessage({ id: 'labels.hours' }).toLowerCase()}
          name="hours"
          value={values?.hours || ''}
          onChange={handleInputChange}
          validationErrors={getErrorsByName(values, 'hours')}
          hasError={errorMessage?.length > 0}
          {...validation.hours}
        />
      </Grid>
      <Grid item xs>
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'labels.minutes' })}
        </Typography>
        <CustomInput
          placeholder={intl.formatMessage({ id: 'labels.minutes' }).toLowerCase()}
          name="minutes"
          value={values?.minutes || ''}
          onChange={handleInputChange}
          validationErrors={getErrorsByName(values, 'minutes')}
          hasError={errorMessage?.length > 0}
          {...validation.minutes}
        />
      </Grid>
      {errorMessage?.length > 0 && (
        <Grid xs={12} sx={{ ml: 1 }}>
          <ControlValidation validationErrors={errorMessage} />
        </Grid>
      )}
    </Grid>
  );
};

export default ExpiryForm;

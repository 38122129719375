import { Grid2 as Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useIntl } from 'react-intl';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import StyledSelect from 'Components/StyledSelect';
import TooltipAnchor from 'Components/TooltipAnchor';
import PriceInput from 'Components/ProductForm/PriceInput';
import LoaderOverlay from 'Components/LoaderOverlay';

import { UserService } from 'Services/UserService';

import { CustomCurrencyStatus, RewardType } from 'Constants/enums';
import { SelectOption } from 'Types/option.interface';
import { resetStore } from 'Store/userAccount/actions';
import { ThunkDispatchType } from 'Types/redux.types';
import { notificationToast } from 'Utils/notificationToaster';
import { CustomCurrencyStats } from 'Types/currency.interface';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as userSelectors from 'Store/auth/selectors';

interface CustomTokensPriceFormProps {
  formik: FormikValues;
  currencyOptions: SelectOption[];
  onChange: (e: SelectChangeEvent<unknown>) => void;
}

const CustomTokensPriceForm: FC<CustomTokensPriceFormProps> = ({
  formik, currencyOptions, onChange,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();
  const userUid = useSelector(userSelectors.authUserUidSelector) || '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customTokens, setCustomTokens] = useState<CustomCurrencyStats[]>([]);

  useEffect(() => {
    if (userUid) {
      fetchCustomTokens();
    }
  }, [userUid]);

  useEffect(() => () => {
    dispatch(resetStore());
  }, []);

  const isMoneyType = formik?.values?.currencyType === RewardType.Money;
  const isPointsType = formik?.values?.currencyType === RewardType.Points;

  const fetchCustomTokens = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.getCurrencies(userUid);
      const filteredTokens = response?.filter((customCurrency) => (
        customCurrency?.state
          ? ![CustomCurrencyStatus.Expired, CustomCurrencyStatus.OnCancelation].includes(customCurrency?.state)
          : false
      ));

      setCustomTokens(filteredTokens);
    } catch (error: any) {
      notificationToast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderBoldChunks = (chunks: ReactNode[]) => <b>{chunks}</b>;

  const renderBreak = () => <br />;

  const renderMainPriceInput = ({ isMsrp } = { isMsrp: false }) => (
    isMoneyType ? (
      <Grid size={[12, 8]}>
        <PriceInput
          decimalScale={2}
          fixedDecimalScale
          tooltip={isMsrp ? 'hint.product.retailPrice' : 'hint.product.price'}
          label={isMsrp ? 'label.msrpUSD' : 'label.priceUSD'}
          formik={formik}
          name={isMsrp ? 'msrp.main' : 'price.main'}
          placeholder={isMsrp ? 'placeholder.retailPrice' : 'placeholder.sellingPrice'}
          onChange={onChange}
        />
      </Grid>
    ) : (
      <Grid size={[12, 8]}>
        <PriceInput
          tooltip={isMsrp ? 'hint.product.retailPrice' : 'hint.product.price'}
          label={isMsrp ? 'label.msrpTokens' : 'label.priceTokens'}
          formik={formik}
          name={isMsrp ? 'msrp.points' : 'price.points'}
          placeholder={isMsrp ? 'placeholder.retailPrice' : 'placeholder.sellingPrice'}
          onChange={onChange}
          customCurrencies={customTokens}
        />
      </Grid>
    )
  );

  const renderCurrencySelector = () => (
    <div>
      <label className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: 'private.currencyName' })}
        {' '}
        <TooltipAnchor
          translationKey="hint.product.customCurrency"
          values={{ br: renderBreak, b: renderBoldChunks }}
        />
      </label>
      <StyledSelect
        name="currencyType"
        value={formik?.values?.currencyType || ''}
        onChange={onChange}
        disabled={!customTokens?.length}
      >
        {currencyOptions.map(({ label, value: optionValue }) => (
          <MenuItem key={optionValue} value={optionValue}>{label}</MenuItem>
        ))}
      </StyledSelect>
    </div>
  );

  return (
    <Grid container rowSpacing={2} columnSpacing={1} alignItems="flex-start">
      {(isPointsType || isMoneyType)
        ? renderMainPriceInput()
        : (
          <>
            <Grid  size={[12, 3]}>
              <PriceInput
                decimalScale={2}
                fixedDecimalScale
                tooltip="hint.product.price"
                label="label.priceUSD"
                formik={formik}
                name="price.money"
                placeholder="placeholder.sellingPrice"
                onChange={onChange}
              />
            </Grid>
            <Grid  size={[12, 6]}>
              <PriceInput
                label="label.priceTokens"
                formik={formik}
                name="price.points"
                placeholder="placeholder.sellingPrice"
                onChange={onChange}
                customCurrencies={customTokens}
              />
            </Grid>
          </>
        )}
      <Grid size={[12, isPointsType || isMoneyType ? 4 : 3]}>
        {currencyOptions.length > 0 && renderCurrencySelector()}
      </Grid>

      {(isPointsType || isMoneyType)
        ? renderMainPriceInput({ isMsrp: true })
        : (
          <>
            <Grid size={[12, 3]}>
              <PriceInput
                decimalScale={2}
                fixedDecimalScale
                tooltip="hint.product.retailPrice"
                label="label.msrpUSD"
                formik={formik}
                name="msrp.money"
                placeholder="placeholder.retailPrice"
                onChange={onChange}
              />
            </Grid>
            <Grid size={[12, 6]}>
              <PriceInput
                label="label.msrpTokens"
                formik={formik}
                name="msrp.points"
                placeholder="placeholder.retailPrice"
                onChange={onChange}
                customCurrencies={customTokens}
              />
            </Grid>
          </>
        )}
      <Grid size={[12, isPointsType || isMoneyType ? 4 : 3]}>
        {currencyOptions.length > 0 && renderCurrencySelector()}
      </Grid>

      {isLoading && <LoaderOverlay />}
    </Grid>
  );
};

export default CustomTokensPriceForm;

import { getApiData } from 'Services/Api';
import { TwilioTokenResponse } from 'Types/twilioTokenResponse.interface';

export const CallsService = {
  async getTwilioAccessToken(): Promise<TwilioTokenResponse> {
    return getApiData<TwilioTokenResponse>('/api/v1/twilio/access_token');
  },
};

export default CallsService;

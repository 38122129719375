import { AppDispatch } from 'Types/redux.types';
import { JobsFilters } from 'Types/jobsFilters.type';
import { JobsRequest } from 'Types/jobsRequest.type';

export enum Actions {
  UpdateFilters = 'jobsFiltersReducer/UpdateFilters',
  UpdateReqProps = 'jobsFiltersReducer/UpdateReqProps',
  UpdateTab = 'jobsFiltersReducer/UpdateTab',
  ResetFilters = 'jobsFiltersReducer/ResetFilters',
  UpdateFilterId = 'jobsFiltersReducer/UpdateFilterId',
  ResetFiltersAndReqProps = 'jobsFiltersReducer/ResetFiltersAndReqProps',
}

export enum JobsFiltersReducerAlias {
  CommonPage = 'Common',
  UserProfileTab = 'Profile',
}

export const getJobsFiltersActionName = (actionName: Actions, alias: JobsFiltersReducerAlias): string =>
  `${actionName}_${alias}`;

const updateJobsReqProps = (alias: JobsFiltersReducerAlias) => (reqProps: Partial<JobsRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: getJobsFiltersActionName(Actions.UpdateReqProps, alias), payload: reqProps });

export const updateJobsOffset = (alias: JobsFiltersReducerAlias) =>
  (offset: number) => updateJobsReqProps(alias)({ start: offset });

export const updateJobsLimit = (alias: JobsFiltersReducerAlias) =>
  (limit: number) => updateJobsReqProps(alias)({ limit });

export const updateJobsOrder = (alias: JobsFiltersReducerAlias) =>
  (order: string) => updateJobsReqProps(alias)({ order, start: 0 });

export const updateJobsQuery = (alias: JobsFiltersReducerAlias) =>
  (query: string) => updateJobsReqProps(alias)({ query, start: 0 });

export const updateJobsFilters = (alias: JobsFiltersReducerAlias) =>
  (filters: Partial<JobsFilters>) => (dispatch: AppDispatch) =>
    dispatch({
      type: getJobsFiltersActionName(Actions.UpdateFilters, alias),
      payload: { filters, reqProps: { start: 0, query: '' } }, // set query to '' on tabs switch
    });

export const updateTab = (alias: JobsFiltersReducerAlias) =>
  (tab: string) => (dispatch: AppDispatch) =>
    dispatch({ type: getJobsFiltersActionName(Actions.UpdateTab, alias), payload: tab });

export const resetJobsFilters = (alias: JobsFiltersReducerAlias) => () =>
  (dispatch: AppDispatch) =>
    dispatch({ type: getJobsFiltersActionName(Actions.ResetFilters, alias) });

export const resetJobsFiltersAndReqProps = (alias: JobsFiltersReducerAlias) =>
  (initialFilters = {}) => (dispatch: AppDispatch) =>
    dispatch({ type: getJobsFiltersActionName(Actions.ResetFiltersAndReqProps, alias), payload: initialFilters });

export const updateJobsFilterId = (alias: JobsFiltersReducerAlias) => (actor: string) =>
  (dispatch: AppDispatch) =>
    dispatch({ type: getJobsFiltersActionName(Actions.UpdateFilterId, alias), payload: actor });

import { Bounty } from 'Types/bounty.interface';
import { ListResponse } from 'Types/listResponse.interface';
import { formatApiRoute } from 'Utils/api';
import {
  COMPANY_ORDER_ALERT,
  CLIENT_ORDER_DETAILS_ROUTE,
  CLIENT_ORDERS_ROUTE,
  UPDATE_ORDER_STATUS_ROUTE,
  USER_MERCHANT_ORDERS_ROUTE,
  USER_ORDERS_TMP_ROUTE,
  USER_ORDER_DETAILS_ROUTE,
  USER_SUBORDERS_ROUTE,
} from 'Constants/apiRoutes';
import { OrderStatus } from 'Constants/enums';
import { deleteApiData, getApiData, postApiData } from 'Services/Api';

const OrderService = {
  async getOrders(clientId: string, queryParams: { start: number, length: number }): Promise<ListResponse<Bounty>> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_ORDERS_ROUTE,
      params: { clientId },
      queryParams: queryParams || {},
    });
    return getApiData<ListResponse<Bounty>>(formattedRoute);
  },

  async getOrder(clientId: string, orderId: string): Promise<Bounty> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_ORDER_DETAILS_ROUTE,
      params: { clientId, orderId },
      queryParams: {},
    });
    return getApiData<Bounty>(formattedRoute);
  },

  async getUserOrder(userId: string, orderId: string): Promise<Bounty> {
    const formattedRoute = formatApiRoute({
      endpoint: USER_ORDER_DETAILS_ROUTE,
      params: { userId, orderId },
      queryParams: {},
    });
    return getApiData<Bounty>(formattedRoute);
  },

  async removeOrderAlert(companyId: string, bountyId: string) {
    const formattedRoute = formatApiRoute({
      endpoint: COMPANY_ORDER_ALERT,
      params: { companyId, bountyId },
      queryParams: {},
    });

    return deleteApiData(formattedRoute);
  },

  async updateOrderStatus(orderId: string, status: OrderStatus) {
    const formattedRoute = formatApiRoute({
      endpoint: UPDATE_ORDER_STATUS_ROUTE,
      params: { orderId, status },
      queryParams: {},
    });

    return postApiData(formattedRoute);
  },

  async getMerchantOrders(userId: string, queryParams: { start: number, length: number }): Promise<ListResponse<Bounty>> {
    const formattedRoute = formatApiRoute({
      endpoint: USER_MERCHANT_ORDERS_ROUTE,
      params: { userId },
      queryParams: queryParams || {},
    });
    return getApiData<ListResponse<Bounty>>(formattedRoute);
  },

  async getUserTmpOrders(userId: string, queryParams: { start: number, length: number }): Promise<ListResponse<Bounty>> {
    const formattedRoute = formatApiRoute({
      endpoint: USER_ORDERS_TMP_ROUTE,
      params: { userId },
      queryParams: queryParams || {},
    });
    return getApiData<ListResponse<Bounty>>(formattedRoute);
  },

  async getUserSuborders(userId: string, orderId: string): Promise<ListResponse<Bounty>> {
    const formattedRoute = formatApiRoute({
      endpoint: USER_SUBORDERS_ROUTE,
      params: { userId, orderId },
      queryParams: {},
    });
    return getApiData<ListResponse<Bounty>>(formattedRoute);
  },
};

export default OrderService;

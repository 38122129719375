import { AttachmentAction } from 'Constants/enums';

export const ACTIONS = {
  SET_ATTACHMENTS: 'attachmentsReducer/SetAttachments',
  SET_CHAT_ATTACHMENTS: 'attachmentsReducer/SetChatAttachments',
  ADD_ATTACHMENT: 'attachmentsReducer/AddAttachment',
  ADD_CHAT_ATTACHMENT: 'attachmentsReducer/AddChatAttachment',
  ADD_SINGLE_ATTACHMENT: 'attachmentsReducer/AddSingleAttachment',
  REMOVE_ATTACHMENT: 'attachmentsReducer/RemoveAttachment',
  REMOVE_CHAT_ATTACHMENT: 'attachmentsReducer/RemoveChatAttachment',
  RESET: 'attachmentsReducer/Reset',
  UPDATE_ATTACHMENT: 'attachmentsReducer/UpdateAttachment',
  REORDER_ATTACHMENTS: 'attachmentsReducer/ReorderAttachments',
};

export const setAttachments = ({ attachments, inlineAttachments }) => (dispatch) => (
  dispatch({ type: ACTIONS.SET_ATTACHMENTS, payload: { attachments, inlineAttachments } })
);

export const setInlineAttachments = (inlineAttachments) => (dispatch) => (
  dispatch({ type: ACTIONS.SET_ATTACHMENTS, payload: { inlineAttachments } })
);

export const setChatAttachments = ({ attachments, chatAttachments }) => (dispatch) => (
  dispatch({ type: ACTIONS.SET_CHAT_ATTACHMENTS, payload: { attachments, chatAttachments } })
);

export const addAttachments = (files, action = ACTIONS.ADD_ATTACHMENT) => (dispatch) => (
  files.forEach(({ filename, localUri, mimeType, size, type, id, onlyAsUrl, action: fileAction }) => {
    dispatch({
      type: action,
      payload: {
        filename,
        localUri,
        mimeType,
        size,
        type,
        action: fileAction || { actionUrl: 'https://', actionType: AttachmentAction.OpenUrl },
        id,
        onlyAsUrl,
      },
    });
  })
);

export const addChatAttachments = (files) => (dispatch) => (
  files.forEach(({ filename, localUri, mimeType, size, type }) => {
    dispatch({ type: ACTIONS.ADD_CHAT_ATTACHMENT, payload: { filename, localUri, mimeType, size, type } });
  })
);

export const addAttachment = (file) => (dispatch) => {
  const { filename, localUri, mimeType, size, type } = file;
  dispatch({ type: ACTIONS.ADD_SINGLE_ATTACHMENT, payload: { filename, localUri, mimeType, size, type } });
};

export const removeAttachments = (index) => (dispatch) => (
  dispatch({ type: ACTIONS.REMOVE_ATTACHMENT, payload: index })
);

export const removeChatAttachments = (index) => (dispatch) => (
  dispatch({ type: ACTIONS.REMOVE_CHAT_ATTACHMENT, payload: index })
);

export const eraseAttachments = () => (dispatch) => dispatch({ type: ACTIONS.RESET });

export const updateAttachment = (attach, index) => (dispatch) => (
  dispatch({ type: ACTIONS.UPDATE_ATTACHMENT, payload: { attach, index } })
);

export const reorderAttachments = (inlineAttachments) => (dispatch) => (
  dispatch({ type: ACTIONS.REORDER_ATTACHMENTS, payload: { inlineAttachments } })
);

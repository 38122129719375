import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

export const userProfilePINSchema = Yup.object({
  pin: Yup
    .string()
    .nullable()
    .matches(/[0-9]/, <FormattedMessage id="validation.message.pin.mustBeNumber" />)
    .max(6, <FormattedMessage id="validation.message.pin.tooLong" />)
    .required(<FormattedMessage id="pleaseEnterPin" />),
});

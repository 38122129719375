import { Actions } from 'Store/confirmationModal/actions';
import { ConfirmationModalType } from 'Constants/enums';
import { AnyAction } from '@reduxjs/toolkit';

interface State {
  open: boolean;
  status: ConfirmationModalType;
  message: string;
  title: string;
  autoCLoseTimer: number;
}

const initialState: State = {
  open: false,
  status: ConfirmationModalType.Info,
  message: '',
  title: '',
  autoCLoseTimer: 0,
};

const confirmationReducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.Show:
      return {
        ...state,
        ...payload,
      };

    case Actions.Hide:
      return {
        ...state,
        open: payload,
      };

    default:
      return state;
  }
};

export default confirmationReducer;

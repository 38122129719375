import { cloneDeep } from 'lodash';
import { notificationToast } from 'Utils/notificationToaster';

import { newComment } from 'Utils/comment';
import { addValueOfPoints } from 'Utils/models/Reward';
import { getEffectiveResponseKey, isCreatedByMe, updateBadges } from 'Utils/bounty';
import {
  areRepliesPrivate,
  isAllowedToMarkAsOfficial,
  isAllowedToPin,
  privateRepliesByBountyType,
} from 'Utils/bountyResponse';

import { forResponse, getFavoritePriority, getPriorityForComment, getSortKeysForResponse } from 'Utils/keyUtils';
import { prepareFilters } from 'Utils/general';
import {
  firebaseGetCurrentUser,
  firebaseGetTimestamp,
  firebaseGetUserCommentsRef,
  firebaseGetUserListsRef,
  firebaseGetUserResponsesRef,
  getInboundResponsesRef,
} from 'Services/FirebaseService';
import { postEvent, getApiData } from 'Services/Api';

import * as PointUtils from 'Utils/points';

import { getOutboundResponseRefs, INBOUND_GLOBAL } from 'Services/response/CommonService';
import { convertObjToArray, streamSnapshotToArray } from 'Utils/helpers';
import { updateDataAsyncAction } from 'Store/genericActions';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { RESPONSES } from 'Constants/apiRoutes';
import { BadgeTypes, BountyType, DisplayMode, QueueAlias, SortBy, BountyCardType } from 'Constants/enums';
import { setMyPoints } from 'Services/UserService';
import { GenericReducerName } from 'Constants/genericReducerName';
import { isDraftBountyResponseState } from 'Constants/bountyResponse';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { User } from 'Types/user.interface';
import { Point } from 'Types/point.interface';
import { AppDispatch } from 'Types/redux.types';
import { Comment } from 'Types/comment.interface';

interface StatsResponse {
  ownerId: string;
  userId: string;
  bountyId: string;
  responseId: string;
  creatorId: string;
  bounty: Bounty;
  bountyResponse?: Partial<BountyResponse>;
  ratingValue?: number;
  user?: User;
  badgeType?: BadgeTypes
  commentCount?: number;
  displayMode?: DisplayMode;
}

const addToFavorites = ({
  ownerId, userId, bountyId, responseId, creatorId,
}: StatsResponse) => () => {
  const now = firebaseGetTimestamp();
  firebaseGetUserResponsesRef(ownerId).child(bountyId).child(responseId).child('response/favoritedAt')
    .set(now);

  const prio = getFavoritePriority(new Date().getTime());
  const key = {
    cardType: BountyCardType.RESPONSE,
    creatorId,
    bountyId,
    responseKey: bountyId,
    '.priority': prio,
  };

  //  TODO check prio (prio must be send to set function, but can't accept 2 arguments,
  //  TODO see if .priority from key object is working)
  firebaseGetUserListsRef({ ownerId, userId }).child('favorites').child(responseId).set(key);
  postEvent(QueueAlias.AddFavorite, { responseId, favoritedAt: now });
};

const removeFromFavorites = ({
  ownerId, userId, bountyId, responseId,
}: StatsResponse) => () => {
  firebaseGetUserResponsesRef(ownerId).child(bountyId).child(responseId).child('response/favoritedAt')
    .remove();
  firebaseGetUserListsRef({ ownerId, userId }).child('favorites').child(responseId).remove();
  postEvent(QueueAlias.RemoveFavorite, { responseId });
};

function updateSortKeys(bounty: Bounty, bountyResponse: BountyResponse, ownerId: string, keys: string[]) {
  const keyInfo = forResponse(bountyResponse);

  const ref = getInboundResponseRef(bounty, bountyResponse, ownerId);
  ref?.update(getSortKeysForResponse(keyInfo, keys));
}

function getResponseCardRef(responseKey: string, responseId: string, ownerId: string, type: string) {
  if (type === INBOUND_GLOBAL) {
    return getInboundResponsesRef(ownerId, responseKey).child(responseId);
  }

  console.log('Should never get here'); // eslint-disable-line
}

function getResponseRef(responseKey: string, responseId: string, ownerId: string, type: string) {
  return getResponseCardRef(responseKey, responseId, ownerId, type)?.child('response');
}

function getInboundResponseRef(bounty: Bounty | undefined, bountyResponse: BountyResponse, ownerId: string) {
  return bounty && getResponseRef(getEffectiveResponseKey(bounty), bountyResponse?.id, ownerId, INBOUND_GLOBAL);
}

function getResponseRefs(bounty: Bounty, bountyResponse: BountyResponse, ownerId: string, user: Partial<User>) {
  const responseRefs: any = {};

  if (!isDraftBountyResponseState(bountyResponse.state)) {
    responseRefs[INBOUND_GLOBAL] = getInboundResponseRef(bounty, bountyResponse, ownerId);
  }
  if (isCreatedByMe(bountyResponse, user)) {
    Object.assign(responseRefs, getOutboundResponseRefs(bountyResponse, user));
  }
  return responseRefs;
}

/* **** Answers comments **** */
const getBountyAnswersComments = (bountyResponseId: string) => () => (
  new Promise((resolve) => (
    firebaseGetUserCommentsRef()
      .child('responses')
      .child(bountyResponseId)
      .once('value', (dataSnapshot) => {
        resolve(convertObjToArray(dataSnapshot.val()).reverse());
      })
  ))
);

const prepareBountyAnswersComments = (result: any[]) => (dispatch: AppDispatch) => {
  const promises: Promise<any>[] = [];

  result.forEach((item) => {
    if (item.response.id) {
      promises.push(dispatch(getBountyAnswersComments(item.response.id)));
    }
  });

  Promise.all(promises)
    .then((response) => {
      const comments: Record<string, Comment> = {};
      result.forEach((item: {response: {id: string}}, index: number) => {
        comments[item.response.id] = response[index];
      });

      dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyAnswersComments, comments));
    });
};

// ToDo: refactor this function typescript add StreamSnapshot<DataType> type later
const saveBountyAnswersToStore = (response: any, dispatch: AppDispatch) => {
  const result = streamSnapshotToArray(response);

  dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyAnswers, result));
  dispatch(prepareBountyAnswersComments(result));
};

const getBountyAnswers = (ownerId: string, bounty: Bounty, sortOrder: string) => (dispatch: AppDispatch) => {
  const me: User | any = firebaseGetCurrentUser();
  const privateReplies = privateRepliesByBountyType(bounty.type) || areRepliesPrivate(bounty.responseVisibilityMode);

  if (!bounty?.id || !ownerId) {
    return null;
  }

  if (privateReplies && !isCreatedByMe(bounty, me)) {
    return firebaseGetUserResponsesRef(ownerId)
      .child(bounty.id)
      .orderByChild('response/creator')
      .equalTo(me?.uid)
      .on('value', (dataSnapshot) => saveBountyAnswersToStore(dataSnapshot, dispatch));
  }

  firebaseGetUserResponsesRef(ownerId)
    .child(bounty.id)
    .orderByChild(`response/${sortOrder}`)
    .on('value', (dataSnapshot) => saveBountyAnswersToStore(dataSnapshot, dispatch));
};

const getBountyAnswersAny = (filters = {}) => (dispatch: AppDispatch) => {
  getApiData(`${RESPONSES}/${BountyType.Job}?${prepareFilters(filters)}`)
    .then((response) => {
      dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyAnswers, response.list));
      dispatch(prepareBountyAnswersComments(response.list));
    })
    .catch(() => {
      dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, {}));
    });
};

/* **** Response comments **** */

const increaseResponseComments = ({
  ownerId, bountyId, responseId, commentCount = 0,
}: { ownerId: string; bountyId: string; responseId: string; commentCount: number }) => () => (
  firebaseGetUserResponsesRef(ownerId).child(bountyId).child(responseId).child('response/stats/commentCount')
    .set(commentCount + 1)
);

const addResponseComments = (responseId: string, comment: Partial<Comment>) => () => {
  const testRef = firebaseGetUserCommentsRef().child('responses').child(responseId).push();
  const extComment = { ...comment, id: testRef.key };
  testRef.setWithPriority(extComment, getPriorityForComment(extComment));
  postEvent(QueueAlias.AddComment, { updComment: extComment, commentId: testRef.key });
};

/* **** Like **** */
function increaseLikesStat(bounty: Bounty, bountyResponse: BountyResponse, ownerId: string, responseRefs: {}) {
  adjustLikesStat(bounty, bountyResponse, ownerId, responseRefs, 1);
}

function decreaseLikesStat(bounty: Bounty, bountyResponse: BountyResponse, ownerId: string, responseRefs: {}) {
  adjustLikesStat(bounty, bountyResponse, ownerId, responseRefs, -1);
}

function adjustLikesStat(bounty: Bounty, bountyResponse: BountyResponse, ownerId: string, responseRefs: { [x: string]: { child: (arg0: string) => { (): any; new(): any; set: { (arg0: any): void; new(): any; }; }; }; }, adjustment: number) {
  const bountyResponseClone: BountyResponse = cloneDeep(bountyResponse);

  if (!bountyResponse.stats) {
    bountyResponseClone.stats = { likesCount: 0 };
  }

  const likesCount = bountyResponseClone.stats.likesCount + adjustment;
  bountyResponseClone.stats = { likesCount };

  Object.keys(responseRefs).forEach((ref) => responseRefs[ref].child('stats/likesCount').set(likesCount));
  updateSortKeys(bounty, bountyResponse, ownerId, [SortBy.BountyPopularity]);
}

const toggleLikedStateForResponse = (bounty: Bounty, response: BountyResponse, user: User, ownerId: string) => {
  const responseClone = cloneDeep(response);
  const responseRefs = getResponseRefs(bounty, responseClone, ownerId, user);

  if (responseClone.likedAt) {
    delete responseClone.likedAt;
    decreaseLikesStat(bounty, responseClone, ownerId, responseRefs);
  } else {
    responseClone.likedAt = new Date().getTime();
    increaseLikesStat(bounty, responseClone, ownerId, responseRefs);
  }

  const likedAt = responseClone.likedAt || null;

  Object.keys(responseRefs).forEach((ref) => responseRefs[ref].child('likedAt').set(likedAt));
  postEvent(QueueAlias.LikeResponse, { responseId: responseClone.id, likedAt });
};

const rateBountyResponse = ({
  bounty, bountyResponse, text, rateAmt, availablePts, ownerId,
}: {
    bounty: Bounty;
    bountyResponse: BountyResponse;
    text: string;
    rateAmt: number;
    availablePts: Point;
    ownerId: string;
}) => async (dispatch: AppDispatch) => {
  const bountyResponseClone: BountyResponse = cloneDeep(bountyResponse);
  const currentUser: Partial<User> = firebaseGetCurrentUser();

  const pointCurrency = availablePts.currency;
  const pts: Point = {
    amount: rateAmt,
    currency: pointCurrency,
  };

  if (bountyResponseClone) {
    bountyResponseClone.rating = PointUtils.addRating(bountyResponseClone?.rating, currentUser?.uid, pts);
  }

  const rating = PointUtils.getPoints(bountyResponseClone.rating, pointCurrency);

  const refs: any = getResponseRefs(bounty, bountyResponseClone, ownerId, currentUser);
  Object.keys(refs).forEach((ref) => {
    refs[ref].child('rating').child('points').child(pointCurrency).set(rating);
    refs[ref].child('rating').child('userRatings').set(bountyResponseClone.rating.userRatings);
  });

  updateSortKeys(bounty, bountyResponseClone, ownerId, [SortBy.BountyPopularity]);
  setMyPoints({ amount: Number(availablePts.amount) - Number(pts.amount), currency: pointCurrency }); // this method gives perm denied

  // Todo typescript
  const comment: any = await newComment(text, BountyCardType.RESPONSE, bountyResponseClone?.id);

  comment.reward = addValueOfPoints(pts);

  dispatch(addResponseComments(bountyResponseClone?.id, comment));
};

const changeResponseRatingValue = ({
  bounty, bountyResponse, ownerId, ratingValue,
}: StatsResponse & { bountyResponse: BountyResponse }) => () => {
  const ref: any = getInboundResponseRef(bounty, bountyResponse, ownerId);
  ref.child('rating/starPointsValue').set(ratingValue);
  postEvent(QueueAlias.ChangeBountyRatingValue, { responseId: bountyResponse?.id, starPointsValue: ratingValue });
};

const toggleBadgeType = ({
  bounty, bountyResponse, user, ownerId, badgeType, mark,
}: StatsResponse & { mark: boolean, bountyResponse: BountyResponse, user: User }) => () => {
  const responseClone: BountyResponse = cloneDeep(bountyResponse);
  const isAllowedToPerformOp = isAllowedToMarkAsOfficial(responseClone?.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(`Cannot edit badges when ${responseClone?.state}`);
  }

  responseClone.badges = updateBadges(responseClone, badgeType, mark);
  const responseRefs = getResponseRefs(bounty, responseClone, ownerId, user);

  Object.keys(responseRefs).forEach((ref) => responseRefs[ref].child('badges').set(responseClone?.badges));
  postEvent(QueueAlias.ResponseEditBadge, { responseId: responseClone?.id, badgeType, mark });
};

const pinToTop = ({ bountyResponse, pin }: {bountyResponse: BountyResponse, pin: boolean}) => () => {
  const responseClone = cloneDeep(bountyResponse);
  const isAllowedToPerformOp = isAllowedToPin(responseClone.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(`Cannot edit badges when ${responseClone.state}`);
  }

  const pinnedAt = pin ? firebaseGetTimestamp() : null;

  postEvent(QueueAlias.PinResponse, { responseId: responseClone.id, pin, pinnedAt });
};

export const setResponseDisplayMode = ({ bountyResponse, displayMode }: { bountyResponse: BountyResponse, displayMode?: DisplayMode}) => {
  const changes = {
    displayMode: {
      newValue: displayMode || DisplayMode.Unknown,
      oldValue: bountyResponse?.displayMode || null,
    },
  };

  postEvent(QueueAlias.UpdateResponse, { responseId: bountyResponse?.id, changes });
};

const getReplyCommentStats = ({ ownerId, bountyId, responseId }: { ownerId: string; bountyId: string; responseId: string }) => new Promise((resolve) => (
  firebaseGetUserResponsesRef(ownerId)
    .child(bountyId)
    .child(responseId)
    .child('response/stats/commentCount')
    .once('value', (dataSnapshot) => (resolve(dataSnapshot.val())))
));

export {
  toggleLikedStateForResponse,
  removeFromFavorites,
  addToFavorites,
  rateBountyResponse,
  changeResponseRatingValue,
  toggleBadgeType,
  pinToTop,
  increaseResponseComments,
  addResponseComments,
  getBountyAnswers,
  getBountyAnswersAny,
  getBountyAnswersComments,
  getReplyCommentStats,
};

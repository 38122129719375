import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS } from 'Constants/jobUnit';
import {
  AgeFilterType,
  AnonUsersType,
  ApplicationJourneyPhaseStatus,
  ApplicationStatus,
  AttachmentAction,
  AuthProvider,
  BannerType,
  Benefit,
  BonusType,
  BountyState,
  CustomerBalanceType,
  DeliveryMethod,
  DocumentType,
  Gender,
  SpecialProgramSpecialty,
  MedicalIdPromptType,
  PayPeriodUnit,
  RestrictedOperationStatus,
  SalaryType,
  ShopStatusType,
  TargetApp,
  UserAuthStatus,
  UserRole,
  AnalyticsDateType,
  InviteeRoles,
  ChatStatus,
  LandingPageType,
  ApplicationHrStatus,
  ApplicationHrStatusInterview,
  PerformingFilterTypes,
  ProfileStage,
  CheckoutFieldType,
  TemplateType,
  TerminalPosition,
  ReservationStatus,
  ReservationAttribute,
  ProductSortOptions,
  ViewMode,
  TribalIdMode,
  AddressAutocomplete,
  ChemicalViewMode,
  ChemicalFilters,
  ReportReasonTypes,
  ChemicalKind,
  SupportChat,
  MerchantOrderStatus,
  ClientStatus,
  DeliveryFeeSource,
} from 'Constants/enums';
import {
  getAgeFilterTranslationKey,
  getAnonUsersTranslationKey,
  getApplicationJourneyPhaseStatusTranslationKey,
  getApplicationStatusTranslationKey,
  getAttachmentActionTranslationKey,
  getAuthProviderTranslationKey,
  getBannerTypeTranslationKey,
  getBenefitTranslationKey,
  getBonusPayPeriodUnitTranslationKey,
  getSupportChatTranslationKey,
  getBonusTypeTranslationKey,
  getBountyStateTranslationKey,
  getCustomerBalanceTranslationKey,
  getDayHourOperationStatusTranslationKey,
  getDeliveryMethodTranslationKey,
  getDocTypeTranslationKey,
  getGenderTranslationKey,
  getSpecialProgramSpecialtyTranslationKey,
  getMedicalIdPromptTranslationKey,
  getOptionsFromEnum,
  getPaymentPeriodUnitTranslationKey,
  getSalaryTypeTranslationKey,
  getShopStatusTranslationKey,
  getTargetAppTranslationKey,
  getUserAuthStatusTranslationKey,
  getUserRoleTranslationKey,
  getAnalyticsDateTranslationKey,
  getChatStatusTranslationKey,
  getInviteeRoleTranslationKey,
  getLandingPageTranslationKey,
  getApplicationHrStatusTranslationKey,
  getPerformingFilterTranslationKey,
  getStageTranslationKey,
  getCheckoutFieldTypeTranslationKey,
  getAlertTypeTranslationKey,
  getTerminalPositionTranslationKey,
  getReservationStatusTranslationKey,
  getReservationAttributeTranslationKey,
  getProductSortingTranslationKey,
  getViewModeTranslationKey,
  getAddressAutocompleteTranslationKey,
  getTribalIdTranslationKey,
  getChemicalViewModeTranslationKey,
  getChemicalFiltersTranslationKey,
  getReportReasonTranslationKey,
  getChemicalKindTranslationKey,
  getMerchantOrderStatusTranslationKey,
  getClientStatusTranslationKey,
  getDeliveryFeeSourceTranslationKey,
} from 'Utils/translationKeyFormatters';
import { hashToDictionary } from 'Utils/formatters';
import { CountriesList } from 'Utils/countries';
import { DictionaryItem } from 'Types/dictionaryItem.type';

export const AUTH_PROVIDER_OPTIONS: DictionaryItem<AuthProvider>[] =
  getOptionsFromEnum(AuthProvider, getAuthProviderTranslationKey, true);

export const USER_ROLE_OPTIONS: DictionaryItem<UserRole, ReactNode>[] =
  getOptionsFromEnum(Gender, getUserRoleTranslationKey);

export const APPLICATION_STATUS_OPTIONS: DictionaryItem<ApplicationStatus, ReactNode>[] =
  getOptionsFromEnum(ApplicationStatus, getApplicationStatusTranslationKey);

export const JOB_APPLICATION_STATUS_OPTIONS: DictionaryItem<ApplicationStatus, ReactNode>[] =
  APPLICATION_STATUS_OPTIONS.filter((o) => [
    ApplicationStatus.New,
    ApplicationStatus.Accepted,
    ApplicationStatus.Pending,
    ApplicationStatus.Declined,
    ApplicationStatus.KYCRejected,
    ApplicationStatus.Unavailable,
  ]
    .includes(o.value));

export const APPLICATION_JOURNEY_PHASE_STATUS_OPTIONS: DictionaryItem<ApplicationJourneyPhaseStatus, string>[] =
  getOptionsFromEnum(ApplicationJourneyPhaseStatus, getApplicationJourneyPhaseStatusTranslationKey);

export const APPLICATION_HR_STATUS_OPTIONS: DictionaryItem<ApplicationHrStatus, ReactNode>[] =
  getOptionsFromEnum(ApplicationHrStatus, getApplicationHrStatusTranslationKey);

export const APPLICATION_HR_STATUS_INTERVIEW_OPTIONS: DictionaryItem<ApplicationHrStatusInterview, ReactNode>[] =
  getOptionsFromEnum(ApplicationHrStatusInterview, getApplicationHrStatusTranslationKey);

export const GENDER_OPTIONS: DictionaryItem<Gender, ReactNode>[] =
  getOptionsFromEnum(Gender, getGenderTranslationKey);

export const STAGE_OPTIONS: DictionaryItem<ProfileStage, ReactNode>[] =
  getOptionsFromEnum(ProfileStage, getStageTranslationKey);

export const PAGE_ITEMS_LIMIT_OPTIONS: DictionaryItem<number>[] = [10, 20, 50]
  .map((value) => ({ label: value.toString(), value }));

export const SALARY_TYPE_OPTIONS: DictionaryItem<SalaryType, ReactNode>[] =
  getOptionsFromEnum(SalaryType, getSalaryTypeTranslationKey);

export const BENEFIT_OPTIONS: DictionaryItem<Benefit, ReactNode>[] =
  getOptionsFromEnum(Benefit, getBenefitTranslationKey)
    .filter((o) => ![Benefit.Unknown, Benefit.None].includes(o.value));

export const BONUS_TYPE_OPTIONS: DictionaryItem<BonusType, ReactNode>[] =
  getOptionsFromEnum(BonusType, getBonusTypeTranslationKey);

export const BONUS_PAY_PERIOD_UNIT_TRANSLATED_OPTIONS: DictionaryItem<PayPeriodUnit, ReactNode>[] = hashToDictionary(PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS)
  .map((o) => ({
    ...o,
    label: <FormattedMessage id={getBonusPayPeriodUnitTranslationKey(o.value as PayPeriodUnit)} />,
  })) as DictionaryItem<PayPeriodUnit, ReactNode>[];

export const PAY_PERIOD_UNIT_TRANSLATED_OPTIONS: DictionaryItem<PayPeriodUnit, ReactNode>[] = hashToDictionary(PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS)
  .map((o) => ({
    ...o,
    label: <FormattedMessage id={getPaymentPeriodUnitTranslationKey(o.value as PayPeriodUnit)} />,
  })) as DictionaryItem<PayPeriodUnit, ReactNode>[];

export const MANDATORY_DOC_OPTIONS: DictionaryItem<DocumentType, ReactNode>[] =
  getOptionsFromEnum(DocumentType, getDocTypeTranslationKey);

export const JOB_MANDATORY_DOC_OPTIONS = MANDATORY_DOC_OPTIONS
  .filter((d) => ([
    DocumentType.Passport, DocumentType.NationalId, DocumentType.DrivingLicence, DocumentType.Covid19Vax,
    DocumentType.WorkPermit, DocumentType.Visa, DocumentType.Resume, DocumentType.CriminalBgCheck,
    DocumentType.ResidentCard, DocumentType.EAD, DocumentType.SSN,
  ] as DocumentType[]).includes(d.value));

export const AGE_FILTER_OPTIONS: DictionaryItem<AgeFilterType, ReactNode>[] =
  getOptionsFromEnum(AgeFilterType, getAgeFilterTranslationKey);

export const CHECKOUT_FIELDS_OPTIONS: DictionaryItem<CheckoutFieldType, ReactNode>[] =
  getOptionsFromEnum(CheckoutFieldType, getCheckoutFieldTypeTranslationKey);

export const LANDING_PAGE_OPTIONS: DictionaryItem<LandingPageType, ReactNode>[] =
  getOptionsFromEnum(LandingPageType, getLandingPageTranslationKey);

export const ANALYTICS_DATE_OPTIONS: DictionaryItem<AnalyticsDateType, ReactNode>[] =
  getOptionsFromEnum(AnalyticsDateType, getAnalyticsDateTranslationKey);

export const SHOP_STATUS_OPTIONS: DictionaryItem<ShopStatusType, ReactNode>[] =
  getOptionsFromEnum(ShopStatusType, getShopStatusTranslationKey);

export const PERFORMING_FILTER_OPTIONS: DictionaryItem<PerformingFilterTypes, ReactNode>[] =
  getOptionsFromEnum(PerformingFilterTypes, getPerformingFilterTranslationKey);

export const CHAT_STATUS_OPTIONS: DictionaryItem<ChatStatus, ReactNode>[] =
  getOptionsFromEnum(ChatStatus, getChatStatusTranslationKey);

export const ANON_USERS_OPTIONS: DictionaryItem<AnonUsersType, ReactNode>[] =
  getOptionsFromEnum(AnonUsersType, getAnonUsersTranslationKey);

export const MEDICAL_ID_PROMPT_OPTIONS: DictionaryItem<MedicalIdPromptType, ReactNode>[] =
  getOptionsFromEnum(MedicalIdPromptType, getMedicalIdPromptTranslationKey);

export const CUSTOMER_BALANCE_OPTIONS: DictionaryItem<CustomerBalanceType, ReactNode>[] =
  getOptionsFromEnum(CustomerBalanceType, getCustomerBalanceTranslationKey);

export const DELIVERY_METHOD_OPTIONS: DictionaryItem<DeliveryMethod, ReactNode>[] =
  getOptionsFromEnum(DeliveryMethod, getDeliveryMethodTranslationKey);

export const USER_AUTH_STATUS_OPTIONS: DictionaryItem<UserAuthStatus, ReactNode>[] =
  getOptionsFromEnum(UserAuthStatus, getUserAuthStatusTranslationKey);

export const BANNER_TYPE_OPTIONS: DictionaryItem<BannerType, ReactNode>[] =
  getOptionsFromEnum(
    Object.values(BannerType)?.filter((value) => ![BannerType.Unknown, BannerType.None].includes(value)),
    getBannerTypeTranslationKey,
  );

export const TARGET_APP_OPTIONS: DictionaryItem<TargetApp, ReactNode>[] =
  getOptionsFromEnum(TargetApp, getTargetAppTranslationKey);

export const ATTACHMENT_ACTION_OPTIONS: DictionaryItem<AttachmentAction, ReactNode>[] =
  getOptionsFromEnum(AttachmentAction, getAttachmentActionTranslationKey);

export const INVITEE_ROLE_OPTIONS: DictionaryItem<InviteeRoles, ReactNode>[] =
  getOptionsFromEnum(InviteeRoles, getInviteeRoleTranslationKey);

export const JOB_PROGRAM_OPTIONS: DictionaryItem<SpecialProgramSpecialty, ReactNode>[] =
  getOptionsFromEnum(SpecialProgramSpecialty, getSpecialProgramSpecialtyTranslationKey);

export const PHONE_CODE_OPTIONS: DictionaryItem<string, string, { shortLabel: string }>[] = (CountriesList as unknown as { phoneCode: string; label: string; code: string }[])
  .map((o) => ({
    value: o.code,
    label: `${o.phoneCode} ${o.label}`,
    shortLabel: o.phoneCode,
  }));

export const BOUNTY_STATE_OPTIONS: DictionaryItem<BountyState, ReactNode>[] =
  getOptionsFromEnum(BountyState, getBountyStateTranslationKey);

export const DAY_HOUR_OPERATION_STATUS_OPTIONS: DictionaryItem<RestrictedOperationStatus, ReactNode>[] =
  getOptionsFromEnum(RestrictedOperationStatus, getDayHourOperationStatusTranslationKey);

export const ALERT_TYPE_OPTIONS: DictionaryItem<TemplateType, ReactNode>[] =
  getOptionsFromEnum(TemplateType, getAlertTypeTranslationKey);

export const TERMINAL_POSITION_OPTIONS: DictionaryItem<TerminalPosition, ReactNode>[] =
  getOptionsFromEnum(TerminalPosition, getTerminalPositionTranslationKey);

export const PARTY_SIZE_OPTIONS = [
  {
    label: '1-2',
    value: '1-2',
  },
  {
    label: '3-4',
    value: '3-4',
  },
  {
    label: '5-6',
    value: '5-6',
  },
  {
    label: '7-8',
    value: '7-8',
  },
  {
    label: '9-10',
    value: '9-10',
  },
  {
    label: '10+',
    value: '10',
  },
];

export const RESERVATION_STATUSES_OPTIONS =
  getOptionsFromEnum(ReservationStatus, getReservationStatusTranslationKey, true);

export const RESERVATION_ATTRIBUTE_OPTIONS: DictionaryItem<ReservationAttribute, ReactNode>[] =
  getOptionsFromEnum(ReservationAttribute, getReservationAttributeTranslationKey);

export const PRODUCT_SORTING_OPTIONS: DictionaryItem<ProductSortOptions, ReactNode>[] =
  getOptionsFromEnum(ProductSortOptions, getProductSortingTranslationKey);

export const VIEW_MODE_OPTIONS: DictionaryItem<ViewMode, ReactNode>[] =
  getOptionsFromEnum(ViewMode, getViewModeTranslationKey);

export const ADDRESS_AUTOCOMPLETE_OPTIONS: DictionaryItem<AddressAutocomplete, ReactNode>[] =
  getOptionsFromEnum(AddressAutocomplete, getAddressAutocompleteTranslationKey);

export const CHEMICAL_VIEW_MODE: DictionaryItem<ChemicalViewMode, ReactNode>[] =
  getOptionsFromEnum(ChemicalViewMode, getChemicalViewModeTranslationKey);

export const CHEMICAL_KIND_OPTIONS: DictionaryItem<ChemicalKind, ReactNode>[] =
  getOptionsFromEnum(ChemicalKind, getChemicalKindTranslationKey);

export const TRIBAL_ID_OPTIONS: DictionaryItem<TribalIdMode, ReactNode>[] =
  getOptionsFromEnum(TribalIdMode, getTribalIdTranslationKey);

export const CHEMICAL_FILTERS_OPTIONS: DictionaryItem<ChemicalFilters, ReactNode>[] =
  getOptionsFromEnum(ChemicalFilters, getChemicalFiltersTranslationKey);

export const REPORT_REASON_OPTIONS: DictionaryItem<ReportReasonTypes, ReactNode>[] =
  getOptionsFromEnum(ReportReasonTypes, getReportReasonTranslationKey);

export const SUPPORT_CHAT_OPTIONS: DictionaryItem<SupportChat, ReactNode>[] =
  getOptionsFromEnum(SupportChat, getSupportChatTranslationKey);

export const MERCHANT_ORDER_STATUS_OPTIONS: DictionaryItem<MerchantOrderStatus, ReactNode>[] =
  getOptionsFromEnum(MerchantOrderStatus, getMerchantOrderStatusTranslationKey);

export const CLIENT_STATUS_OPTIONS: DictionaryItem<ClientStatus, ReactNode>[] =
  getOptionsFromEnum(ClientStatus, getClientStatusTranslationKey);

export const DELIVERY_FEE_SOURCE_OPTIONS: DictionaryItem<DeliveryFeeSource, ReactNode>[] =
  getOptionsFromEnum(DeliveryFeeSource, getDeliveryFeeSourceTranslationKey);

import { firebaseGetUserResponsesRef, getCompanySpecialsRef } from 'Services/FirebaseService';
import { getCompanyFolder } from 'Utils/bounty';
import { BountyType, SortBy } from 'Constants/enums';
import { streamSnapshotToArray } from 'Utils/helpers';
import { ThunkDispatchType } from 'Types/redux.types';
import { getBountyAnswers } from 'Services/response/StatsService';

export const ACTIONS = {
  SET_IS_LOADING: 'matchesReducer/setIsLoading',
  SET_LIST: 'matchesReducer/setList',
  SET_DETAILS: 'matchesReducer/setDetails',
  SET_REPLIES: 'matchesReducer/setReplies',
};

export const getMatches = ({ ownerId }: { ownerId: string }) => (dispatch: ThunkDispatchType) => {
  const companyFolder = getCompanyFolder(BountyType.Match);

  if (companyFolder) {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

    getCompanySpecialsRef(ownerId)
      .child(companyFolder)
      .on('value', (dataSnapshot) => {
        const result = streamSnapshotToArray(dataSnapshot);
        dispatch({ type: ACTIONS.SET_LIST, payload: result });
        dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
      }, () => {
        dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
      });
  }
};

export const unsubscribeMatches = ({ ownerId }: { ownerId: string }) => (dispatch: ThunkDispatchType) => {
  const companyFolder = getCompanyFolder(BountyType.Match);

  if (companyFolder) {
    getCompanySpecialsRef(ownerId).child(companyFolder).off();
    dispatch({ type: ACTIONS.SET_LIST, payload: [] });
  }
};

export const getMatchDetails = (params: { ownerId: string, matchId: string }) => (dispatch: ThunkDispatchType) => {
  const { ownerId, matchId } = params;
  const companyFolder = getCompanyFolder(BountyType.Match);

  if (companyFolder) {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

    getCompanySpecialsRef(ownerId)
      .child(companyFolder)
      .child(matchId)
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val() || {};

        dispatch({ type: ACTIONS.SET_DETAILS, payload: result });
        dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
        dispatch(getBountyAnswers(ownerId, result.bounty, SortBy.BountyCreated));
      }, () => {
        dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
      });
  }
};

export const getMatchReplies = (params: { ownerId: string, matchId: string }) => (dispatch: ThunkDispatchType) => {
  const { ownerId, matchId } = params;

  firebaseGetUserResponsesRef(ownerId)
    .child(matchId)
    .orderByChild(`response/${SortBy.BountyCreated}`)
    .on('value', (dataSnapshot) => {
      const result = streamSnapshotToArray(dataSnapshot);
      dispatch({ type: ACTIONS.SET_REPLIES, payload: result });
    }, () => {
      dispatch({ type: ACTIONS.SET_REPLIES, payload: [] });
    });
};

export const unsubscribeMatchDetails = (params: { ownerId: string, matchId: string }) => (dispatch: ThunkDispatchType) => {
  const { ownerId, matchId } = params;
  const companyFolder = getCompanyFolder(BountyType.Match);

  if (companyFolder) {
    getCompanySpecialsRef(ownerId).child(companyFolder).child(matchId).off();
    firebaseGetUserResponsesRef(ownerId).child(matchId).off();
    firebaseGetUserResponsesRef(ownerId).child(matchId).off();

    dispatch({ type: ACTIONS.SET_REPLIES, payload: [] });
    dispatch({ type: ACTIONS.SET_DETAILS, payload: {} });
  }
};

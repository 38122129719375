import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ResponsiveGrid, { ResponsiveGridHeading } from 'Components/ResponsiveGrid';
import OrderCustomerName from 'Components/OrderCustomerName';

import { addCellProps } from 'Utils/responsiveGridUtils';
import { RECEIVED_ORDER_ROUTE } from 'Constants/routes';
import { generateLink } from 'Utils/link';
import { FirebaseOrderDetails } from 'Types/order.interface';
import { formatReward } from 'Utils/currency';
import { EMPTY_REWARD } from 'Constants/currency';
import emptyProduct from 'Assets/img/emptyContent/es_products.png';
import { getDeliveryMethodTranslationKey, getOrderStatusTranslationKey } from 'Utils/translationKeyFormatters';
import { SortDirection } from 'Constants/enums';
import { SortChange } from 'Types/sortChange.interface';
import { OrdersSortFields } from 'Constants/sort';
import { toggleSortDirection } from 'Utils/sort';
import * as styles from 'Components/OrdersList/index.module.scss';
import { Bounty } from 'Types/bounty.interface';
import SimpleButton from 'Components/SimpleButton';
import LoaderOverlay from 'Components/LoaderOverlay';
import { format } from 'date-fns';
import { FULL_DATE_FORMAT } from 'Constants/common';
import StatusLabel from 'Components/StatusLabel';
import { ORDER_STATUS_COLOR } from 'Constants/colors';

interface OrdersListProps {
  data: FirebaseOrderDetails[];
  sort?: SortChange | null;
  onSortChange?: (changes: SortChange<OrdersSortFields>) => any;
  onValidateOrder?: (order: FirebaseOrderDetails) => void;
  companyId?: string;
  isLoading?: boolean;
  onClose?: () => void;
}

const OrdersList: FC<OrdersListProps> = ({
  data,
  sort = null,
  onSortChange,
  onValidateOrder,
  companyId,
  isLoading = false,
  onClose,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const headings: ResponsiveGridHeading[] = [
    {
      title: intl.formatMessage({ id: 'label.item' }),
      id: 'item',
      className: styles.cellItem,
    },
    ...(onValidateOrder
      ? [{
        title: intl.formatMessage({ id: 'label.actions' }),
        id: 'validateOrder',
        className: styles.cellValidate,
      }] : []),
    {
      title: intl.formatMessage({ id: 'label.orderId' }),
      id: 'orderId',
      className: styles.cellOrderId,
    },
    {
      title: intl.formatMessage({ id: 'label.orderType' }),
      id: OrdersSortFields.OrderType,
      sortable: !!onSortChange,
    },
    {
      title: intl.formatMessage({ id: 'label.orderDate' }),
      id: OrdersSortFields.CreatedAt,
      sortable: !!onSortChange,
    },
    {
      title: intl.formatMessage({ id: 'label.customerName' }),
      id: 'customerName',
    },
    {
      title: intl.formatMessage({ id: 'label.status' }),
      id: 'status',
    },
    {
      title: intl.formatMessage({ id: 'label.orderTotal' }),
      id: OrdersSortFields.OrderTotal,
      sortable: !!onSortChange,
      className: styles.cellOrderTotal,
    },
  ];

  const openDetails = (order: FirebaseOrderDetails) => {
    if (order?.bounty?.id) {
      if (onClose) {
        onClose();
      }

      navigate(generateLink(RECEIVED_ORDER_ROUTE, { bountyId: order.bounty.id }));
    }
  };

  const generateRow = (orderDetails: FirebaseOrderDetails) => {
    const {
      shortId,
      createdAt,
      order,
    } = orderDetails.bounty || {};
    const { customerInfo, productsInfo, invoiceInfo } = order || {};
    const products = productsInfo?.products || {};
    const firstProductWithCover = Object.values(products)?.find((product) => product?.imageUrl);
    const productCover = firstProductWithCover?.imageUrl || emptyProduct;
    const partialBounty = {
      order,
      creator: orderDetails.creator,
      owner: orderDetails.owner,
    } as Bounty;
    const orderTotal = invoiceInfo?.summary?.sections?.IN_APP?.total || {};
    const statusColor = orderDetails?.status
      ? ORDER_STATUS_COLOR?.[orderDetails?.status]
      : '';

    const cellValues = [
      <div
        className={styles.orderImage}
        style={{ backgroundImage: `url(${productCover})` }}
      />,
      ...(onValidateOrder
        ? [(
          <SimpleButton
            label={intl.formatMessage({ id: 'button.validate' })}
            onClick={() => onValidateOrder(orderDetails)}
          />
        )] : []),
      shortId && <Box className={styles.linkStyle} onClick={() => openDetails(orderDetails)}>{shortId}</Box>,
      customerInfo?.delivery?.method && intl.formatMessage({ id: getDeliveryMethodTranslationKey(customerInfo?.delivery?.method) }),
      createdAt ? format(new Date(createdAt), FULL_DATE_FORMAT) : '',
      <OrderCustomerName bounty={partialBounty} companyId={companyId} />,
      orderDetails?.status && (
        <StatusLabel
          label={intl.formatMessage({ id: getOrderStatusTranslationKey(orderDetails?.status) })}
          fontWeight="font-weight-normal"
          labelStyle={{ backgroundColor: statusColor }}
          className="darkLabel"
        />
      ),
      orderTotal && <div className="font-weight-bold">{formatReward(orderTotal, EMPTY_REWARD)}</div>,
    ];

    return cellValues.map((value, index) => {
      const cellProps = addCellProps(headings, index);
      const hasRedirect = ['customerName', 'validateOrder'].includes(cellProps?.id);

      return (
        <ResponsiveGrid.Cell
          {...cellProps}
          value={value}
          {...(hasRedirect ? {} : { onClick: () => openDetails(orderDetails) })}
        />
      );
    });
  };

  const handleHeaderClick = (id: OrdersSortFields) => onSortChange && onSortChange({
    by: id,
    direction: sort?.by === id ? toggleSortDirection(sort?.direction) : SortDirection.DESC,
  });

  return (
    <section id={styles.ordersList}>
      <ResponsiveGrid.Table>

        <ResponsiveGrid.Row header>
          {headings.map((h) => (
            <ResponsiveGrid.HeadCell
              key={`header-${h.id}`}
              id={h.id}
              className={h.className}
              label={h.title}
              sortable={h.sortable}
              activeSort={sort?.by === h.id}
              sortDirection={sort?.direction}
              onClick={(id) => handleHeaderClick(id as OrdersSortFields)}
            />
          ))}
        </ResponsiveGrid.Row>

        <tbody>
          {data.map((order) => (
            <ResponsiveGrid.Row key={order?.bounty?.id}>
              {generateRow(order)}
            </ResponsiveGrid.Row>
          ))}
        </tbody>
      </ResponsiveGrid.Table>

      {isLoading && <LoaderOverlay />}
    </section>
  );
};

export default memo(OrdersList);

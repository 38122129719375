import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'dinamo1948',
  title: 'Dinamo Bucuresti',
  name: 'Dinamo Bucuresti',
  brandName: 'Dinamo',
  appLinkSchema: 'dinamo1948',
  appTld: 'dinamo1948.ro',
  tags: ['all', 'active'],
  auth: {
    disableSignup: true,
    disablePinFlow: true,
    disabledPins: [],
    customCss: 'smallLogo',
  },
  useCase: 'dinamo',
  customCss: {
    sidebarLogo: 'blackBackgroundLogo',
  },
  custom: {
    supportedLanguages: ['en-US'],
    isLanguageSelectorHidden: true,
  },
  TERMS_OF_SERVICE_URL: 'https://dinamo1948.ro/app-terms_and_conditions/',
  PRIVACY_STATEMENT_URL: 'https://dinamo1948.ro/app-privacy-policy/',
};

export default config;

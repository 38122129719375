import { FormattedMessage } from 'react-intl';
import { ChatAction, ChatRoomType, ChatState, ChatStatus } from 'Constants/enums';
import { notificationToast } from 'Utils/notificationToaster';
import { BULLET_SEP } from 'Utils/survey';
import { ChatMessage } from 'Types/chat-message.interface';
import { PSGRoom, UserChat } from 'Types/chatUser.interface';

// TODO: add correct type
export const getOption = (option: any) => {
  const { code, text, weight } = option;
  let optionString = '';

  if (code == null) {
    optionString += text;
  } else if (text == null) {
    optionString += code;
  } else {
    optionString += `${code}${BULLET_SEP} ${text}`;
  }

  if (weight != null) {
    optionString += ` (=${weight})`;
  }

  return optionString;
};

// TODO: add user type
export const getChoice = (user: any = {}) => {
  const { bountyInfo, choice } = user;

  if (bountyInfo && bountyInfo.option) {
    return getOption(bountyInfo.option);
  }

  return choice;
};

// TODO: add user type
export const getEffectiveRealCollocutor = (user: UserChat) => {
  const { realCollocutor, collocutor } = user;

  return realCollocutor || collocutor;
};

export const validateUserChat = (data: UserChat, userUid: string) => {
  const { roomType } = data;

  if (roomType === ChatRoomType.Private) {
    const effectiveRealCollocutor = getEffectiveRealCollocutor(data);

    if (effectiveRealCollocutor && effectiveRealCollocutor.id === userUid) {
      notificationToast.error(<FormattedMessage id="chat_with_self" />);
      return false;
    }
  }

  if (roomType === ChatRoomType.Group || roomType === ChatRoomType.Support) {
    if (!data.group) {
      notificationToast.error(<FormattedMessage id="chat_with_nobody" values={{ value: 'no group' }} />);
      return false;
    }
  }

  if (roomType === ChatRoomType.SurveyChoice) {
    if (!data.bountyId || !data.choice) {
      notificationToast.error(<FormattedMessage id="chat_with_nobody" values={{ value: 'no survery choice' }} />);
      return false;
    }
  }

  return true;
};

// TODO: add user type
export const getChatRoomName = (user: any, roomType: ChatRoomType) => {
  const {
    roomShortId, collocutor, realCollocutor, group, roomTopic,
  } = user;

  const collocutorName = (collocutor || realCollocutor)?.name || '';
  const groupName = group?.name || '';

  const roomNamePrefix = roomShortId ? `${roomShortId} - ` : '';

  let roomCommonName;

  switch (roomType) {
    case ChatRoomType.Private:
      roomCommonName = collocutorName;
      break;

    case ChatRoomType.Support:
    case ChatRoomType.Group:
      roomCommonName = groupName;
      break;

    case ChatRoomType.SurveyChoice:
      roomCommonName = getChoice(user);
      break;

    case ChatRoomType.PSG:
      roomCommonName = roomTopic;
      break;
    default:
      roomCommonName = '';
  }

  return roomCommonName ? `${roomNamePrefix}${roomCommonName}` : null;
};

export const getEffectiveRoomAvatar = (user: UserChat, roomType: ChatRoomType): { url?: string | null, IconComponent?: any } => {
  const { collocutor, roomAvatar } = user;

  switch (roomType) {
    case ChatRoomType.Private:
      return { url: (collocutor && collocutor.avatar) || null };
    case ChatRoomType.Group:
      return { IconComponent: 'business' };
    case ChatRoomType.Support:
      return { IconComponent: 'support_agent' };
    case ChatRoomType.PSG:
      return { url: roomAvatar || null };
    default:
      return { url: null };
  }
};

export const chatSortFunc = (a: ChatMessage, b: ChatMessage) => a.sentAt - b.sentAt;

export const userChatSort = (a: UserChat, b: UserChat) =>
  (b.notifiedRecvMsgAt || b.lastSentMsgAt || 0) - (a.notifiedRecvMsgAt || a.lastSentMsgAt || 0);

export const getChatActionConfirmationMessage = (action: ChatAction): string => {
  switch (action) {
    case ChatAction.Dismiss:
      return 'chatAction.confirmation.dismiss';
    case ChatAction.Resolve:
      return 'chatAction.confirmation.resolve';
    case ChatAction.Abandon:
      return 'chatAction.confirmation.abandon';
    case ChatAction.Archive:
      return 'chatAction.confirmation.archive';
    default:
      return '';
  }
};

export const getChatActionSuccessMessage = (action: ChatAction): string => {
  switch (action) {
    case ChatAction.Dismiss:
      return 'dismiss_chat_success';
    case ChatAction.Resolve:
      return 'resolve_chat_success';
    case ChatAction.Abandon:
      return 'abandon_chat_success';
    case ChatAction.Archive:
      return 'archive_chat_success';
    default:
      return '';
  }
};

export const hasUnreadMessages = ({ lastReadMsgAt, notifiedRecvMsgAt, state }: UserChat) => {
  if (!lastReadMsgAt && notifiedRecvMsgAt) {
    return true;
  }

  if (!notifiedRecvMsgAt) {
    return false;
  }

  if (state === ChatState.Dismissed) {
    return false;
  }

  return notifiedRecvMsgAt > lastReadMsgAt;
};

export const getStatusProps = (chat: UserChat) => {
  if (chat?.status === ChatStatus.Active) {
    return {
      label: 'status.active',
      className: 'statusActive',
    };
  }

  if (chat?.status === ChatStatus.New) {
    return {
      label: 'status.new',
      className: 'statusNew',
    };
  }

  if (chat?.status === ChatStatus.Resolved) {
    return {
      label: 'status.resolved',
      className: 'statusResolved',
    };
  }

  if (chat?.status === ChatStatus.Abandoned) {
    return {
      label: 'status.abandoned',
      className: 'statusAbandoned',
    };
  }

  return {
    label: null,
    className: null,
  };
};

export const formatUserChatsFromPSG = (rooms: PSGRoom[]) => (rooms.map((room) => ({
  roomId: room?.chatRoom?.id,
  id: room?.chatRoom?.id,
  bountyId: room?.chatRoom?.bountyId,
  roomType: room?.chatRoom?.roomType,
  roomTopic: room?.chatRoom?.roomTopic,
  roomAvatar: room?.chatRoom?.roomAvatar,
  status: room?.chatRoom?.psgStatus,
  state: room?.chatRoom?.roomState,
  joined: room?.joined,
  lastSentMsgAt: room?.lastSentMsgAt,
  lastReadMsgAt: room?.lastReadMsgAt,
  notifiedRecvMsgAt: room?.notifiedRecvMsgAt,
  collocutor: {
    name: '',
    id: room?.chatRoom?.collocutorId,
  },
})));

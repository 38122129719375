import { ComponentType } from 'react';

type LazyComponent = () => Promise<{ default: ComponentType<Record<string, unknown>> }>;

const CustomLazyLoader = (lazyComponent: LazyComponent, attemptsLeft: number = 3): Promise<{ default: ComponentType<Record<string, unknown>> }> => new Promise((resolve, reject) => {
  lazyComponent()
    .then(resolve)
    .catch((error: Error) => {
      setTimeout(() => {
        if (attemptsLeft === 1) {
          reject(error);
          return;
        }
        CustomLazyLoader(lazyComponent, attemptsLeft - 1)
          .then(resolve, reject);
      }, 1500);
    });
});

export default CustomLazyLoader;

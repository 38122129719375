import { Interactions } from 'Constants/enums';
import { Interactions as InteractionsInterface } from 'Types/bounty.interface';

function getDefaultInteractionsList() {
  return {
    [Interactions.Chat]: false,
    [Interactions.OfficialResponse]: false,
    [Interactions.Rebounty]: false,
    [Interactions.Recommend]: false,
    [Interactions.Seen]: false,
    [Interactions.Share]: false,
  };
}

export function addInteraction(interaction: Interactions, interactionList?: InteractionsInterface) {
  if (!interactionList) {
    const defaultInteractions = getDefaultInteractionsList();
    return {
      ...defaultInteractions,
      [interaction]: true,
    };
  }

  return {
    ...interactionList,
    [interaction]: true,
  };
}

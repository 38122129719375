export enum ApplicationsSortFields {
  ApplicationStatus = 'APPLICATION_STATUS',
  Name = 'APPLICANT_NAME',
  Gender = 'APPLICANT_GENDER',
  CreatedDate = 'APPLICATION_CREATED_AT',
  Age = 'APPLICANT_AGE',
  CountryCode = 'COUNTRY_CODE',
  Citizenship = 'CITIZENSHIP',
  Position = 'POSITION',
  Certificates = 'CERTIFICATES',
  Languages = 'LANGUAGES',
}

export enum ApplicantsSortFields {
  CreatedDate = 'CREATED_AT',
  Name = 'APPLICANT_NAME',
  Gender = 'APPLICANT_GENDER',
  Age = 'APPLICANT_AGE',
  CountryCode = 'COUNTRY_CODE',
  Citizenship = 'CITIZENSHIP',
  Position = 'POSITION',
  Certificates = 'CERTIFICATES',
  Languages = 'LANGUAGES',
}

export enum OrdersSortFields {
  CreatedAt = 'CREATED_AT',
  OrderType = 'DELIVERY_METHOD',
  OrderTotal = 'BASE_PRICE',
}

export enum CandidatesSortFields {
  CreatedDate = 'CREATED_AT',
  Name = 'NAME',
  Gender = 'GENDER',
  Age = 'AGE',
  Country = 'COUNTRY',
  Citizenship = 'CITIZENSHIP',
  SkillSet = 'SKILLSET',
  Certificates = 'CERTIFICATES',
  Languages = 'LANGUAGES',
}

export enum UsersSortFields {
  Name = 'NAME',
  EnglishName = 'ENGLISH_NAME',
  Age = 'AGE',
  Country = 'SOURCE_COUNTRY',
  Citizenship = 'CITIZENSHIP',
  CreatedDate = 'CREATED_DATE',
  LastSignedDate = 'LAST_SIGN_IN_DATE',
  Email = 'EMAIL_ADDRESS',
  PhoneNumber = 'PHONE_NUMBER',
  ReferralCode = 'REFERRAL_CODE',
  PIN = 'PIN',
  Provider = 'PROVIDER',
  Roles = 'ROLES',
  InvitedBy = 'INVITED_BY',
  ProfileStage = 'PROFILE_STAGE',
  Status = 'STATUS',
}

export enum CompaniesSortFields {
  Name = 'NAME',
  CreatedDate = 'CREATED_DATE',
  LastSignedDate = 'LAST_SIGN_IN_DATE',
  InvitedBy = 'INVITED_BY',
}

export enum BountiesSortFields {
  CreatedDate = 'CREATED_DATE',
  PostedDate = 'POSTED_DATE',
  ModifiedDate = 'MODIFIED_DATE',
  RemainingPositions = 'REMAINING_POSITIONS',
  MaxPositions = 'MAX_POSITIONS',
  ApplicationsCount = 'APPLICATIONS_COUNT',
  ActiveApplicationsCount = 'ACTIVE_APPLICATION_COUNT',
  TotalApplicationsCount = 'TOTAL_APPLICATION_COUNT',
  ContractRate = 'CONTRACT_RATE',
  ContactLength = 'CONTRACT_LENGTH',
  Title = 'TITLE',
}

export enum CompanyUsersSortFields {
  ActiveJobs = 'ACTIVE_JOBS',
  Applications = 'APPLICATIONS',
  CreatedDate = 'CREATED_AT',
  Creator = 'CREATED_BY',
  CompanyName = 'COMPANY_NAME',
  Industry = 'INDUSTRY',
  SourceCountry = 'SOURCE_COUNTRY',
  Currency = 'CURRENCY',
  PIN = 'PIN',
}

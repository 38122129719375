import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { FC } from 'react';
import {
  BlockquoteButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import { FormikValues } from 'formik';

import StyledSelect from 'Components/StyledSelect';
import ExpiryForm from 'Components/ExpiryForm';
import CustomInput from 'Components/CustomInput';
import MarkdownEditorControl from 'Components/MarkdownEditorControl';
import CustomDateTimePicker from 'Components/CustomDateTimePicker';

import { getErrorsByName } from 'Utils/formik';
import { PromotionFormType } from 'Constants/enums';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './styles.module.scss';

const toolbarButtons = [
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  BlockquoteButton,
  UnorderedListButton,
  OrderedListButton,
  BoldButton,
  ItalicButton,
];

interface PromotionFormProps {
  myCurrency: string;
  formik: FormikValues;
}

const PromotionForm: FC<PromotionFormProps> = ({ formik, myCurrency }) => {
  const intl = useIntl();

  const isDiscountType = formik.values?.type === PromotionFormType.Discount;
  const isSelectType = formik.values?.type === PromotionFormType.SelectOne;

  const availableForErrors = getErrorsByName(formik, 'minutes')
    || getErrorsByName(formik, 'hours') || getErrorsByName(formik, 'days');

  const handleChanges = ({ target: { name, value } }: SelectChangeEvent<unknown>) => {
    formik.setFieldValue(name, value);
  };

  const handleChangeDate = (date: Date | null, name: string) => {
    const time = date ? new Date(date).getTime() : null;
    formik.setFieldValue(name, time);
  };

  const onDescriptionChange = (description: string) => {
    formik.setFieldValue('description', description);
  };

  return (
    <div className={styles.root}>
      <Typography className={labelStyles.fieldLabel}>
        {`${intl.formatMessage({ id: 'bounty.type' })}*`}
      </Typography>
      <StyledSelect
        name="type"
        value={formik?.values?.type || ''}
        onChange={handleChanges}
        validationErrors={getErrorsByName(formik, 'type')}
      >
        <MenuItem value={PromotionFormType.SelectOne}><FormattedMessage id="label.select.one" /></MenuItem>
        <MenuItem value={PromotionFormType.Discount}><FormattedMessage id="label.discount" /></MenuItem>
      </StyledSelect>
      <MarkdownEditorControl
        toolbarButtons={toolbarButtons}
        value={formik?.values?.description || ''}
        onChange={onDescriptionChange}
        validationErrors={getErrorsByName(formik, 'description')}
      />
      {isDiscountType && (
        <>
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.createCouponCode' })}
          </Typography>
          <CustomInput
            name="couponCode"
            value={formik?.values?.couponCode || ''}
            onChange={handleChanges}
          />
          <Typography className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.discount' })}*`}
          </Typography>
          <CustomInput
            name="discount"
            value={formik?.values?.discount || ''}
            onChange={handleChanges}
            validationErrors={getErrorsByName(formik, 'discount')}
          />
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.overallUsageLimit' })}
          </Typography>
          <CustomInput
            name="maxOverallUsageQty"
            value={formik?.values?.maxOverallUsageQty || ''}
            onChange={handleChanges}
            validationErrors={getErrorsByName(formik, 'maxOverallUsageQty')}
          />
          <Typography className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.usageLimitPerUser' })}*`}
          </Typography>
          <CustomInput
            name="maxUsageQty"
            value={formik?.values?.maxUsageQty || ''}
            onChange={handleChanges}
            validationErrors={getErrorsByName(formik, 'maxUsageQty')}
          />
          <Box display="flex" justifyContent="space-between">
            <Box mr={3}>
              <Typography className={labelStyles.fieldLabel}>
                {`${intl.formatMessage({ id: 'label.activationDate' })}*`}
              </Typography>
              <CustomDateTimePicker
                name="activateAt"
                ampm={false}
                value={formik?.values?.activateAt}
                onDateChange={handleChangeDate}
                validationErrors={getErrorsByName(formik, 'activateAt')}
              />
            </Box>
            <Box>
              <Typography className={labelStyles.fieldLabel}>
                {`${intl.formatMessage({ id: 'label.endDate' })}*`}
              </Typography>
              <CustomDateTimePicker
                name="expiresAt"
                ampm={false}
                value={formik?.values?.expiresAt}
                onDateChange={handleChangeDate}
                validationErrors={getErrorsByName(formik, 'expiresAt')}
              />
            </Box>
          </Box>
          <div className={styles.availabilityContainer}>
            <Typography
              className={classnames(
                labelStyles.fieldLabel,
                styles.label,
                { [styles.labelSpacing]: availableForErrors?.length > 0 },
              )}
            >
              {`${intl.formatMessage({ id: 'bounty.availableFor' })}*`}
            </Typography>
            <ExpiryForm
              onChange={handleChanges}
              values={formik.values}
              errorMessage={availableForErrors}
            />
          </div>
        </>
      )}
      {isSelectType && (
        <>
          <div>
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.delivery.fee' })}
            </Typography>
            <div className={styles.priceContainer}>
              <CustomInput
                name="deliveryFee"
                value={formik?.values?.deliveryFee || ''}
                onChange={handleChanges}
                placeholder={intl.formatMessage({ id: 'placeholder.add.delivery.fee' })}
              />
              <Typography className={classnames(labelStyles.fieldLabel, styles.currency)}>
                {myCurrency}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'labels.price' })}
            </Typography>
            <div className={styles.priceContainer}>
              <CustomInput
                name="price"
                value={formik?.values?.price || ''}
                onChange={handleChanges}
                placeholder={intl.formatMessage({ id: 'placeholder.add.price' })}
              />
              <Typography className={classnames(labelStyles.fieldLabel, styles.currency)}>
                {myCurrency}
              </Typography>
            </div>
          </div>
          <div className={styles.availabilityContainer}>
            <Typography className={classnames(labelStyles.fieldLabel, styles.label)}>
              {intl.formatMessage({ id: 'bounty.setDuration' })}
            </Typography>
            <ExpiryForm onChange={handleChanges} values={formik.values} />
          </div>
        </>
      )}
    </div>
  );
};

export default PromotionForm;

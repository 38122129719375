import { isNil, merge, omitBy } from 'lodash';
import commonConfig from './common-config';
import flavourConfig from './flavour';

export const removeNilValues = (data: Record<string, any>) => Object.keys(data).reduce((accumulator, key) => {
  const isObject = typeof data[key] === 'object';
  const value = isObject ? omitBy(data[key], isNil) : data[key];
  if ((isObject && !Object.keys(value).length) || isNil(value)) {
    return accumulator;
  }
  return { ...accumulator, [key]: value };
}, {});

export default () => {
  const config = flavourConfig;
  return merge(commonConfig, removeNilValues({ ...config }));
};

import { CompanyType, Currency, EnvironmentMode, Gender, UserRole, UserStatus, UserType } from 'Constants/enums';
import { Actions } from 'Store/userProfile/actions';
import { AnyAction } from '@reduxjs/toolkit';
import { UserPrivateInfo } from 'Types/userPrivateInfo.interface';
import { Endorsement } from 'Types/endorsement.interface';
import { MyProfileResponse } from 'Types/myPofileResponse.interface';

interface State {
  userProfile: MyProfileResponse;
  userPrivateInfo: UserPrivateInfo;
  userAllowedRoles: UserRole[];
  userEndorsements: Endorsement[];
  isLoading: boolean;
}

const initialState: State = {
  userProfile: {
    basicInfo: {
      address: {
        city: '',
        countryCode: '',
        line1: '',
        line2: '',
        phoneNumber: '',
        postalCode: '',
        state: '',
      },
      citizenship: '',
      dob: '',
      email: '',
      latinizedName: '',
      firstName: '',
      middleName: '',
      lastName: '',
      secondLastName: '',
      gender: Gender.Other,
      mailingAddress: {},
      sameAddress: false,
    },
    educationExperience: {},
    workExperience: {
      didWorkFullTime: false,
      skillset: '',
      workplaceExperience: {},
    },
    identity: {
      identityCards: {},
    },
    shortId: '',
    stats: {
      following: 0,
      members: 0,
      totalStars: 0,
    },
    streamKey: '',
    entityId: '',
    user: {
      avatar: '',
      id: '',
      name: '',
      orgType: CompanyType.Unknown,
      type: UserType.Unknown,
      verified: null,
    },
  },
  userPrivateInfo: {
    citizenship: '',
    dob: '',
    gender: Gender.Other,
    inviteCode: '',
    companyInfo: {
      companyId: '',
      countryCode: '',
      createdAt: 0,
      currency: Currency.None,
      name: '',
      orgType: CompanyType.Unknown,
      pin: '',
      timezone: '',
    },
    email: '',
    environmentMode: EnvironmentMode.Default,
    phoneNumber: '',
    roles: [],
    userStatus: UserStatus.Unknown,
  },
  userAllowedRoles: [],
  userEndorsements: [],
  isLoading: false,
};

const reducer = (state: State = { ...initialState }, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case Actions.GetProfile:
      return {
        ...state,
        userProfile: payload,
      };

    case Actions.GetPrivateInfo:
      return {
        ...state,
        userPrivateInfo: payload,
      };

    case Actions.UpdateUserEmail:
      return {
        ...state,
        userPrivateInfo: {
          ...state.userPrivateInfo,
          email: payload,
        },
      };

    case Actions.UpdateBasicInfo:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          basicInfo: {
            ...state.userProfile.basicInfo,
            ...payload,
          },
        },
      };

    case Actions.GetEndorsements:
      return {
        ...state,
        userEndorsements: payload,
      };

    case Actions.GetAllowedRoles:
      return {
        ...state,
        userAllowedRoles: payload,
      };

    case Actions.UpdateRoles:
      return {
        ...state,
        userPrivateInfo: {
          ...state.userPrivateInfo,
          roles: payload,
        },
      };

    case Actions.UpdateStatus:
      return {
        ...state,
        userPrivateInfo: {
          ...state.userPrivateInfo,
          userStatus: payload,
        },
      };

    case Actions.UpdatePIN:
      return {
        ...state,
        userPrivateInfo: {
          ...state.userPrivateInfo,
          companyInfo: {
            ...state.userPrivateInfo.companyInfo,
            pin: payload,
          },
        },
      };

    case Actions.UpdateEnvMode:
      return {
        ...state,
        userPrivateInfo: {
          ...state.userPrivateInfo,
          environmentMode: payload,
        },
      };

    case Actions.Loading:
      return {
        ...state,
        isLoading: payload,
      };

    case Actions.Reset:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;

import { SpecialProgramSpecialty } from 'Constants/enums';
import { SpecialProgramsResponse } from 'Types/specialProgramsResponse.type';
import { SpecialProgramSpecialtyDictionaryItem } from 'Types/specialProgramSpecialtyDictionaryItem.type';

export const formatSpecialProgramsSpecialties = (data: SpecialProgramsResponse): SpecialProgramSpecialtyDictionaryItem[] => {
  const items: SpecialProgramSpecialtyDictionaryItem[] = [];

  data.forEach((program) => {
    program.countries.forEach((countryCode: string) => {
      program.jobPrograms.forEach((specialty: SpecialProgramSpecialty) => {
        items.push({
          label: specialty,
          value: specialty,
          programName: program.name,
          countryCode,
        });
      });
    });
  });

  return items;
};

import { AnyAction } from '@reduxjs/toolkit';

import { ACTIONS } from 'Store/matches/actions';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { Bounty } from 'Types/bounty.interface';

interface State {
  isLoading: boolean,
  list: { bounty: Bounty }[],
  details: { bounty: Bounty },
  replies: { response: BountyResponse }[],
}

const INIT_STATE: State = {
  isLoading: false,
  list: [],
  details: {} as { bounty: Bounty },
  replies: [],
};

const reducer = (state = INIT_STATE, action:AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_LIST:
      return {
        ...state,
        list: payload,
      };

    case ACTIONS.SET_DETAILS:
      return {
        ...state,
        details: payload,
      };

    case ACTIONS.SET_REPLIES:
      return {
        ...state,
        replies: payload,
      };

    default:
      return state;
  }
};

export default reducer;

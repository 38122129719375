import country from 'country-codes-list';
import { AppConfig } from 'Utils/appConfig';

const excludedCountries = ['AQ', 'BQ', 'EH'];

/** Returns an object of the form
 * {
 *   0: {label: 'Andorra', code: 'AD', phoneCode: '+376'},
 *   1: {label: 'Afghanistan', code: 'AF', phoneCode: '+93'},
 *   ...
 * }
 */

export const CountriesList = country.customArray(
  {
    label: '{countryNameEn}',
    code: '{countryCode}',
    phoneCode: '+{countryCallingCode}',
  },
)
  .filter((item: { code: string; }) => {
    const supportedCountryCodes = AppConfig?.custom?.supportedCountryCodes;
    const isExcluded = excludedCountries.some((element) => element === item.code);

    if (isExcluded) {
      return false;
    }

    if (!supportedCountryCodes) {
      return true;
    }

    return supportedCountryCodes.some((countryCode) => countryCode === item.code);
  })
  .sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));

export const CountriesDictionary = CountriesList.map(({ label, code }: {label: string, code: string}) => ({ label, value: code }));

country.customList('countryCode', '{countryNameEn}');

import { AnyAction } from '@reduxjs/toolkit';
import { ACTIONS } from 'Store/leaderboard/actions';
import { Ranking } from 'Types/ranking.interface';

interface State {
  isLoading: boolean;
  rankings: Ranking[];
}

const INIT_STATE: State = {
  isLoading: false,
  rankings: [],
};

const reducer = (state = INIT_STATE, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_RANKINGS:
      return {
        ...state,
        rankings: payload,
      };

    default:
      return state;
  }
};

export default reducer;

import classnames from 'classnames';
import { FC, forwardRef, InputHTMLAttributes, ReactNode, Ref, RefAttributes } from 'react';
import ControlValidation from 'Components/ControlValidation';
import * as styles from './index.module.scss';

export interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement>, RefAttributes<HTMLInputElement> {
  className?: string;
  prefix?: string;
  postfix?: string
  isSuccess?: boolean;
  isSmall?: boolean;
  hasError?: boolean;
  ref?: Ref<HTMLInputElement>;
  validationErrors?: string[] | ReactNode[];
}

// Max prefix/postfix length defined in styles file (currently - 5)
const CustomInput: FC<CustomInputProps> = forwardRef(({
  className = '',
  isSuccess = false,
  type = 'text',
  prefix = '',
  postfix = '',
  validationErrors = [],
  hasError = false,
  ...rest
}, ref) => (
  <div className={classnames({
    [className]: className,
    [styles.stateInvalid]: validationErrors?.length > 0 || hasError,
    [styles.stateSuccess]: isSuccess,
    [styles.stateDisabled]: rest.disabled,
  })}
  >
    <div className={styles.inputInnerWrapper}>
      {prefix && <span className={styles.prefix}>{prefix}</span>}

      <input
        {...rest}
        ref={ref as Ref<HTMLInputElement>}
        className={styles.control}
        type={type}
        data-prefix-length={prefix.length}
        data-postfix-length={postfix.length}
        onWheel={(e) => e.currentTarget.blur()}
      />

      {postfix && <span className={styles.postfix}>{postfix}</span>}
    </div>

    {validationErrors?.length > 0 && <ControlValidation validationErrors={validationErrors} />}
  </div>
));

export default CustomInput;

import { useEffect, useState, useCallback, FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//  Components
import CustomLoader from 'Components/CustomLoader';
import AppInfoData from 'Containers/AppInfoData';
import BountyManagement from 'Containers/BountyManagement';

//  Selectors
import { authLoggedUserSelector } from 'Store/auth/selectors';
import { routePermissionsSelector } from 'Store/permissions/selectors';

//  Actions
import { fetchCurrentUser, handleRestriction } from 'Store/auth/actions';

import { LANDING_ROUTE, SIGN_IN_ROUTE, SIGN_UP_ROUTE } from 'Constants/routes';
import { ThunkDispatchType } from 'Types/redux.types';
import RoutesComponent from './routes/components/RoutesComponent';
import Loadable from './routes/configs/Suspense';

const RoutesContainer: FC = () => {
  const location = useLocation();
  const [beforeFetching, setBeforeFetching] = useState(true);
  const dispatch = useDispatch<ThunkDispatchType>();
  const user = useSelector(authLoggedUserSelector)?.data || {};
  const loading = useSelector(authLoggedUserSelector)?.isLoading || false;
  const routePermissions = useSelector(routePermissionsSelector) || {};

  useEffect(() => {
    dispatch(fetchCurrentUser());
    setBeforeFetching(false);
  }, []);

  const handleRoutePermissions = useCallback(() => {
    if (!isAuth) {
      handleRestriction()(dispatch);
    }
  }, [user.uid]);

  useEffect(() => {
    if (user.uid) {
      handleRoutePermissions();
    }
  }, [user.uid]);

  const isAuth = !!user.uid;

  if ((loading || beforeFetching) && location.pathname !== SIGN_UP_ROUTE) {
    return <CustomLoader isLoading />;
  }

  if ((!isAuth && location.pathname !== SIGN_IN_ROUTE
        && location.pathname.indexOf(LANDING_ROUTE) !== 0)) {
    return <Navigate to={SIGN_IN_ROUTE} />;
  }

  return (
    <Loadable>
      <AppInfoData>
        <BountyManagement />
        <RoutesComponent permissions={routePermissions} user={user} />
      </AppInfoData>
    </Loadable>
  );
};

export default RoutesContainer;

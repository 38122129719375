import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

import { afterThan, biggerThan } from 'Utils/validation/yup';
import { isBefore } from 'date-fns';

const isPositive = (value) => +value > 0;
const isInFuture = (value) => !isBefore(new Date(+value), new Date().getTime());

const customPartySizeSchema = Yup.object({
  min: Yup
    .string()
    .nullable()
    .when('$partySize', {
      is: (partySize) => partySize === 'CUSTOM',
      then: (field) => field
        .required('validate.required_field')
        .test(
          'isPositive',
        <FormattedMessage id="validate.positiveNumber" />,
        (value) => isNil(value) || isPositive(value),
        ),
    }),
  max: Yup
    .string()
    .nullable()
    .when('$partySize', {
      is: (partySize) => partySize === 'CUSTOM',
      then: (field) => field
        .required('validate.required_field')
        .test(
          'isPositive',
        <FormattedMessage id="validate.positiveNumber" />,
        (value) => isNil(value) || isPositive(value),
        )
        .test('compare', <FormattedMessage id="error.shouldBeBiggerThanMin" />, biggerThan('min')),
    }),
});

export const reservationFilters = Yup.object({
  reservationCreatedFrom: Yup
    .string()
    .nullable(),
  reservationCreatedTo: Yup
    .string()
    .nullable()
    .test('compare', <FormattedMessage id="error.shouldBeAfterFrom" />, afterThan('reservationCreatedFrom', true)),
  customPartySize: customPartySizeSchema,
});

export const createReservation = Yup.object({
  restaurantId: Yup
    .string()
    .required('validate.required_field'),
  date: Yup
    .string()
    .required('validate.required_field')
    .test('isInFuture', <FormattedMessage id="validate.isInPast" />, (value) => isNil(value) || isInFuture(value)),
  firstName: Yup
    .string()
    .required('validate.required_field'),
  lastName: Yup
    .string()
    .required('validate.required_field'),
  notes: Yup
    .string()
    .optional(),
  reservationAttribute: Yup
    .string()
    .required('validate.required_field'),
  phone: Yup.object({
    number: Yup
      .string()
      .required('validate.required_field'),
    countryCode: Yup
      .string()
      .required('validate.required_field'),
  }),
  partySize: Yup
    .string()
    .required('validate.required_field')
    .test(
      'isPositive',
    <FormattedMessage id="validate.greaterThanValue" values={{ value: 0 }} />,
    (value) => isNil(value) || isPositive(value),
    ),
});

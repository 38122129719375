import { Actions } from 'Store/header/actions';
import { AnyAction } from '@reduxjs/toolkit';

interface State {
  title: string;
}

const initialState: State = {
  title: '',
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.SetTitle:
      return {
        ...state,
        title: payload,
      };

    default:
      return state;
  }
};

export default reducer;

import classnames from 'classnames';
import { textTruncate } from 'Utils/helpers';
import * as styles from './index.module.scss';

interface SelectableBountyCardProps<ItemT> {
  bounty: ItemT;
  description: string;
  backgroundImage: { [key: string]: string|undefined };
  handleSelect: (bounty: ItemT) => unknown;
  isActive: boolean;
  className?: string;
}

function SelectableBountyCard<ItemT>({
  bounty,
  description,
  backgroundImage,
  handleSelect,
  isActive,
  className = '',
}: SelectableBountyCardProps<ItemT>) {
  return (
    <div
      role="presentation"
      onClick={() => handleSelect(bounty)}
      className={classnames('cursor-pointer', styles.root, { [styles.selectedBounty]: isActive }, className)}
    >
      <div className="overlay-wrap overflow-hidden">
        <div className="text-center p-4">
          <div
            style={{ ...backgroundImage, height: '200px' }}
            className="image-post product-image-placeholder"
          />
        </div>
      </div>
      <div className="product-info border-top p-3">
        <h4 className="text-dark">
          {description ? textTruncate(description, 25) : '-'}
        </h4>
      </div>
    </div>
  );
}

export default SelectableBountyCard;

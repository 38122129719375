/**
 * App Redux Action Types
 */

// TODO: remove as after moving from generic actions
export enum GenericActionType {
  // User Streams
  GetBountyAnswersComments = 'GET_BOUNTY_ANSWERS_COMMENTS',
  GetAnswersComments = 'GET_ANSWERS_COMMENTS',
  SetStreamList = 'SET_STREAM_LIST',

  // COMMON
  IsLoading = 'IS_LOADING',
  IsSortable = 'IS_SORTABLE',
  UpdateDataAsync = 'UPDATE_DATA_ASYNC',
  UpdateDataAsyncPush = 'UPDATE_DATA_ASYNC_PUSH',
  UpdateDataSync = 'UPDATE_DATA_SYNC',
  SetDataSync = 'SET_DATA_SYNC',
  UpdateSimpleDataSync = 'UPDATE_SIMPLE_DATA_SYNC',
  UpdateListSync = 'UPDATE_LIST_SYNC',
  DeleteDataSync = 'DELETE_DATA_SYNC',
  ClearDataSync = 'CLEAR_DATA_SYNC',
  PushDataSync = 'PUSH_DATA_SYNC',
}

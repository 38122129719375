import { ResponsiveGridHeading } from '../components/ResponsiveGrid';

interface CellProps {
  id: string;
  label: string;
  className: string;
  key: string;
}

export const addCellProps = (headings: ResponsiveGridHeading[], index: number): CellProps => ({
  id: headings[index]?.id || '',
  label: headings[index]?.title,
  className: headings[index]?.className || '',
  key: headings[index]?.title,
});

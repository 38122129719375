import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import * as styles from './index.module.scss';

const EmptyCart: FC = () => (
  <div className={styles.root}>
    <span className={classnames('material-symbols-rounded', styles.cartIcon)}>
      <span className="shopping_cart" />
    </span>
    <FormattedMessage id="cart.CartEmptyText" />
  </div>
);

export default EmptyCart;

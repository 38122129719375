import { FC, useState } from 'react';
import './style.scss';

interface ImageProps {
  className?: string;
  alt?: string;
  height?: string | number;
  src: string;
}

const Image: FC<ImageProps> = ({ className = 'image-post', alt = 'cropped_img', src, height = 'auto' }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const onLoad = () => setIsLoaded(true);

  return (
    <>
      <img
        height={height}
        className={`image placeholder ${className}`}
        alt={alt}
        src={src}
        style={{ visibility: isLoaded ? 'hidden' : 'visible' }}
      />
      <img
        height={height}
        onLoad={onLoad}
        className={`image full  ${className}`}
        alt={alt}
        src={src}
      />
    </>
  );
};

export default Image;

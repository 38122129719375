import { useEffect, useState } from 'react';
import { func, bool } from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { notificationToast } from 'Utils/notificationToaster';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { listBadges } from 'Services/SystemService';
import SimpleButton from 'Components/SimpleButton';
import * as styles from './index.module.scss';

const RewardBadges = ({ open, handleClose, handleBadge }) => {
  const [badges, setBadges] = useState([]);
  const [noAvailableBadges, setNoAvailableBadges] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    listBadges()
      .then(({ list }) => {
        if (list && list.length) {
          return setBadges(list);
        }

        setNoAvailableBadges(true);
      });
  }, []);

  useEffect(() => {
    if (open && noAvailableBadges) {
      handleClose();
      notificationToast.warning(intl.formatMessage({ id: 'no_badge_available' }));
    }
  }, [open]);

  const onBadgeSelect = (selectedBadge) => {
    handleBadge('reward.badges', { [selectedBadge.id]: selectedBadge });
    handleClose();
  };

  const renderBadge = (badge) => (
    <div
      key={badge.id}
      role="presentation"
      className={styles.root}
      onClick={() => onBadgeSelect(badge)}
    >
      <div
        style={{
          backgroundImage: `url(${badge.info.icon})`,
        }}
        className={classnames('image-post', styles.badge)}
      />
      <div className={styles.collectionInfo}>
        <h2>{badge.info.label}</h2>
        <div>{badge.info.description}</div>
      </div>
    </div>
  );

  return (
    <Dialog
      maxWidth="sm"
      open={open && !noAvailableBadges}
      onClose={handleClose}
    >
      <DialogTitle>Select a badge</DialogTitle>
      <DialogContent className={styles.content}>
        {badges.map((item) => (
          item.badge && renderBadge(item.badge)
        ))}
      </DialogContent>
      <DialogActions>
        <div className="text-right mt-40 mr-20">
          <SimpleButton onClick={handleClose}>
            {intl.formatMessage({ id: 'button.cancel' })}
          </SimpleButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

RewardBadges.propTypes = {
  open: bool.isRequired,
  handleBadge: func.isRequired,
  handleClose: func.isRequired,
};

export default RewardBadges;

/**
 * Language Provider Helper Component
 * Used to Display Localised Strings
 */
import { createIntl } from 'react-intl';
import { arrayOf, node, string, object, oneOfType } from 'prop-types';

export function IntlConsume({ children, locale, messages }) {
  createIntl({ locale, messages });
  return children;
}

IntlConsume.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  locale: string.isRequired,
  messages: object.isRequired,
};

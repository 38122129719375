import { Drawer, Hidden } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentType, useMemo, useState } from 'react';
import BountyCreate from 'Containers/BountyCreate';
import ProductCreate from 'Containers/ProductCreate';
import PromotionCreate from 'Containers/PromotionCreate';
import CustomLoader from 'Components/CustomLoader';
import {
  contentTypeSelector,
  isDrawerOpenSelector,
} from 'Store/createBounty/selectors';
import { closeDrawer } from 'Store/createBounty/actions';
import { BountyDrawerType } from 'Constants/enums';
import ConfirmationDialog from 'Containers/BountyManagement/ConfirmationDialog';
import AddReply from 'Containers/AddReply';
import AddComment from 'Containers/AddComment';
import BannerCreation from 'Containers/BannerCreation';
import BadgeCollectionCreate from 'Containers/BadgeCollectionCreate';
import ReservationCreate from 'Containers/ReservationCreate';
import { ThunkDispatchType } from 'Types/redux.types';
import * as styles from './index.module.scss';

const ContentByType: Record<BountyDrawerType, ComponentType<any>> = {
  [BountyDrawerType.Bounty]: BountyCreate,
  [BountyDrawerType.Product]: ProductCreate,
  [BountyDrawerType.Promotion]: PromotionCreate,
  [BountyDrawerType.Banner]: BannerCreation,
  [BountyDrawerType.Comment]: AddComment,
  [BountyDrawerType.Reply]: AddReply,
  [BountyDrawerType.Reservation]: ReservationCreate,
  [BountyDrawerType.BadgeCollection]: BadgeCollectionCreate,
};

export default function BountyCreateDrawer() {
  const dispatch = useDispatch<ThunkDispatchType>();
  const isOpen = useSelector(isDrawerOpenSelector);
  const contentType:BountyDrawerType = useSelector(contentTypeSelector);
  const [showAlert, setShowAlert] = useState(false);

  const Content = useMemo(() => {
    if (contentType) {
      return ContentByType[contentType];
    }
    return null;
  }, [contentType]);

  const handleClose = () => {
    setShowAlert(false);
    dispatch(closeDrawer());
  };

  return (
    <Hidden mdUp implementation="css">
      <Drawer
        variant="temporary"
        anchor="right"
        open={isOpen}
        onClose={() => setShowAlert(true)}
        ModalProps={{ keepMounted: true }}
      >
        <div className={styles.root}>
          <CustomLoader isLoading={!Content}>
            <div>
              {Content && <Content />}
            </div>
          </CustomLoader>
        </div>
      </Drawer>
      {showAlert && (
        <div style={{ zIndex: 2000 }}>
          <ConfirmationDialog onSuccess={handleClose} onDecline={() => setShowAlert(false)} />
        </div>
      )}
    </Hidden>
  );
}

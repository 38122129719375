import { User } from 'Types/user.interface';
import { AnyAction } from '@reduxjs/toolkit';
import { Actions } from 'Store/userManagement/actions';

interface State {
  users: Record<string, User & { fetchedAt: number }>;
}

const INIT_STATE: State = {
  users: {},
};

const userManagementReducer = (state = INIT_STATE, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.SetUser:
      return {
        ...state,
        users: {
          ...state.users,
          [payload?.id]: payload,
        },
      };

    case Actions.ResetUserManagement:
      return INIT_STATE;

    default:
      return state;
  }
};

export default userManagementReducer;

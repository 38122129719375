import { getApplicationConfig } from 'Utils/appConfig';
import { getAuth, AuthErrorCodes } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import firebase from 'firebase/compat/app';

const { firebaseConfig } = getApplicationConfig();

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export default firebaseApp;

export const getIntlMessageFromFirebaseError = (errorCode: any) => {
  if (errorCode === AuthErrorCodes.USER_DELETED) {
    return 'error.emailNotFound';
  }

  if (errorCode === AuthErrorCodes.INVALID_PASSWORD) {
    return 'notification.error.invalidNamePass';
  }

  if (errorCode === AuthErrorCodes.EMAIL_EXISTS) {
    return 'error.emailExists';
  }

  if (errorCode === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
    return 'notification.error.toManyAttempts';
  }

  if (errorCode === AuthErrorCodes.WEAK_PASSWORD) {
    return 'error.emailWeakPassword';
  }

  return '';
};

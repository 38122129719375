import { getJobsFiltersActionName, Actions, JobsFiltersReducerAlias } from 'Store/jobsFilters/actions';
import { formatSortQueryProp } from 'Utils/sort';
import { BountyState, SearchMode, SortDirection } from 'Constants/enums';
import { SortAndSearch } from 'Types/sortAndSearch.type';
import { ByPageReq } from 'Types/byPageReq.interface';
import { JobsFilters } from 'Types/jobsFilters.type';
import { AnyAction } from '@reduxjs/toolkit';

const lsLimitName = 'jobsLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

interface State {
  reqProps: SortAndSearch & ByPageReq;
  filters: JobsFilters;
  tab: string | null;
  filterId: string | null;
}

const initialState: State = {
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp('MODIFIED_DATE', SortDirection.DESC),
    query: '',
  },
  filters: {
    state: [BountyState.Active],
    country: [],
    searchMode: SearchMode.Default,
    payPeriod: '',
  },
  tab: null,
  filterId: null,
};

// TODO: add actions type
export const createJobsFiltersReducer = (alias: JobsFiltersReducerAlias) =>
  (state: State = { ...initialState }, action: AnyAction) => {
    const { payload } = action;

    switch (action.type) {
      case getJobsFiltersActionName(Actions.UpdateReqProps, alias):
        if (payload.limit) {
          localStorage.setItem(lsLimitName, payload.limit);
        }
        return {
          ...state,
          reqProps: {
            ...state.reqProps,
            ...payload,
          },
        };

      case getJobsFiltersActionName(Actions.UpdateFilters, alias):
        return {
          ...state,
          reqProps: {
            ...state.reqProps,
            ...payload.reqProps,
          },
          filters: {
            ...state.filters,
            ...payload.filters,
          },
        };

      case getJobsFiltersActionName(Actions.ResetFilters, alias):
        return {
          ...state,
          filters: {
            ...initialState.filters,
          },
        };

      case getJobsFiltersActionName(Actions.UpdateTab, alias):
        return {
          ...state,
          tab: payload,
        };

      case getJobsFiltersActionName(Actions.UpdateFilterId, alias):
        return {
          ...state,
          filterId: payload,
        };

      case getJobsFiltersActionName(Actions.ResetFiltersAndReqProps, alias):
        return {
          ...state,
          reqProps: { ...initialState.reqProps },
          filters: {
            ...initialState.filters,
            ...payload,
          },
        };

      default:
        return state;
    }
  };

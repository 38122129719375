import { getApplicationConfig } from 'Utils/appConfig';
import { ApplicationConfig } from 'Types/applicationConfig.type';
import packageJson from '../../package.json';

const getUserAgent = (): string => {
  const { id }: ApplicationConfig = getApplicationConfig();

  return `${id}/release/web/${packageJson.version}/0 ${navigator.userAgent}`;
};

const getClientId = (): string | null => window.localStorage.getItem('pin') || null;

type Headers = Record<string, string>;

const getHeaders = (token: string | null, isUpload = false): Headers => {
  const agent = getUserAgent();
  const clientId = getClientId();

  const headers: Headers = {
    'X-edge-agent': agent,
  };

  if (!isUpload) {
    headers['Content-Type'] = 'application/json';
  }

  if (clientId) {
    headers['X-edge-client-id'] = clientId;
  }

  if (token) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return { ...headers };
};

const checkStatus = async (response: Response): Promise<Response> => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw response;
};

const responseParser = async (response: Response): Promise<any> => {
  let result;
  try {
    result = await response.json();
  } catch {
    result = response;
  }
  return result;
};

const errorParser = async (response: Response) => {
  let errorMessage;

  try {
    const { data: message, statusCode: status } = await response.json() || {};

    errorMessage = typeof message === 'string'
      ? { message, status } : {
        message: 'Something went wrong!',
        status: 500,
      };
  } catch {
    errorMessage = {
      message: 'The response from the server has wrong the type!',
      status: 500,
    };
  }

  throw errorMessage;
};

const replaceRouteParams = (route: string, params: Record<string, string>): string => {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
};

const addQueryParams = (route: string, queryParams: Record<string, string | number | boolean>): string => {
  const queryStrings: string[] = [];

  Object.keys(queryParams).forEach((key) => (
    queryStrings.push(`${key}=${queryParams[key]}`)
  ));

  return `${route}?${queryStrings.join('&')}`;
};

interface ApiRouteParams {
  endpoint: string;
  queryParams?: Record<string, string | number | boolean>;
  params?: Record<string, string>;
}

const formatApiRoute = ({ endpoint, queryParams, params }: ApiRouteParams): string => {
  let formattedRoute = endpoint;

  if (params) {
    formattedRoute = replaceRouteParams(formattedRoute, params);
  }

  if (queryParams && Object.keys(queryParams).length) {
    formattedRoute = addQueryParams(formattedRoute, queryParams);
  }
  return formattedRoute;
};

export {
  getUserAgent,
  getClientId,
  getHeaders,
  checkStatus,
  responseParser,
  errorParser,
  formatApiRoute,
};

export const getFilename = (contentDisposition: string | null) => {
  let filename = '';

  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = contentDisposition.match(filenameRegex);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
};

export const downloadFile = (blob: Blob, filename: string, contentType: string) => {
  //  Create blob link to download
  const newBlob = new Blob([blob], { type: contentType });

  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, filename);
    return;
  }

  // Other browsers
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  //  Force download
  link.click();

  //  Clean up and remove the link
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
};

import { ACTIONS } from 'Store/companyAccount/actions';
import { AnyAction } from '@reduxjs/toolkit';

interface State {
  isAccountLoading: boolean;
  isLoadingTransactions: boolean;
  companyAccount: any;
  companyTransactions: any;
}

const INIT_STATE = {
  isAccountLoading: false,
  isLoadingTransactions: false,
  companyAccount: {},
  companyTransactions: [],
};

const companyAccountReducer = (state = INIT_STATE, action : AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_LOADING_ACCOUNT:
      return {
        ...state,
        isAccountLoading: payload,
      };

    case ACTIONS.SET_ACCOUNT_INFO:
      return {
        ...state,
        companyAccount: payload,
      };

    case ACTIONS.SET_LOADING_TRANSACTIONS:
      return {
        ...state,
        isLoadingTransactions: payload,
      };

    case ACTIONS.SET_TRANSACTIONS:
      return {
        ...state,
        companyTransactions: payload,
      };

    case ACTIONS.RESET_REDUCER:
      return INIT_STATE;

    default:
      return state;
  }
};

export default companyAccountReducer;

import { getApiData, postApiData } from 'Services/Api';
import {
  CHAT_CMD_ROUTE,
  COMPANY_SUPPORT_CHATS_ROUTE,
  CREATE_CHAT_ROUTE,
  MARK_MSG_AS_READ_ROUTE,
  USER_CHATS_ROUTE,
  WRITE_MESSAGE_ROUTE,
} from 'Constants/apiRoutes';
import { ChatRoomType, ChatState } from 'Constants/enums';
import { PSGRoom, UserChat } from 'Types/chatUser.interface';
import { formatApiRoute } from 'Utils/api';

const getOrCreatePrivateChatRoom = async (myUserId: string, collocutorId: string, bountyId?: string): Promise<any> => {
  const chat = {
    roomType: ChatRoomType.Private,
    myUserId,
    collocutorId,
    bountyId,
  };

  return ChatService.createChat(chat);
};

export const ChatService = {
  async getUserChats(
    params: { userId: string },
    queryParams: { state?: ChatState, type?: ChatRoomType, status?: string },
    signal?: AbortSignal,
  ): Promise<{ chats: UserChat[] }> {
    const url = formatApiRoute({ endpoint: USER_CHATS_ROUTE, params, queryParams });

    return getApiData<{ chats: UserChat[] }>(url, signal);
  },
  async getCompanySupportChats(
    params: { companyId: string },
    queryParams: { state?: ChatState, type?: ChatRoomType, status?: string },
    signal?: AbortSignal,
  ): Promise<{ rooms: PSGRoom[] }> {
    const url = formatApiRoute({ endpoint: COMPANY_SUPPORT_CHATS_ROUTE, params, queryParams });

    return getApiData<{ rooms: PSGRoom[] }>(url, signal);
  },
  async createUserChat(roomType: ChatRoomType, myUserId: string, collocutorId: string, bountyId?: string) {
    switch (roomType) {
      case ChatRoomType.Private:
        return getOrCreatePrivateChatRoom(myUserId, collocutorId, bountyId);
      case ChatRoomType.SurveyChoice:
      default:
        // eslint-disable-next-line no-console
        console.log('Unsupported room type:', roomType);
    }
  },
  async writeMessage(roomId: string, message: string): Promise<{ id: string }> {
    return (
      postApiData(formatApiRoute({ endpoint: WRITE_MESSAGE_ROUTE, params: { roomId } }), message)
    );
  },
  async writeChatCmd(roomId: string, cmd: string): Promise<{ text: string }> {
    return (
      postApiData(formatApiRoute({ endpoint: CHAT_CMD_ROUTE, params: { roomId } }), { cmd })
    );
  },
  async markAsRead(roomId: string, readAt: number) {
    return (
      postApiData(formatApiRoute({ endpoint: MARK_MSG_AS_READ_ROUTE, params: { roomId } }), { readAt })
    );
  },
  async createChat(chat: { roomType: ChatRoomType; myUserId: string | null; collocutorId: string; bountyId: string | undefined; }): Promise<{ roomId: string }> {
    return (
      postApiData(CREATE_CHAT_ROUTE, chat)
    );
  },
};

export default ChatService;

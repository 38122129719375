import { formatApiRoute } from 'Utils/api';
import { FILTERS_OPEN_ROUTE } from 'Constants/apiRoutes';
import { BountyType } from 'Constants/enums';
import { getApiData } from 'Services/Api';
import { Filter } from 'Types/filter.interface';

export const getFilters = (params: URLSearchParams, bountyType: BountyType) => {
  const endpoint = formatApiRoute({
    endpoint: FILTERS_OPEN_ROUTE,
    params: { bountyType },
    queryParams: {},
  });

  return getApiData<{ filterDefs: Filter[] }>(`${endpoint}?${params}`);
};

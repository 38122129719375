import React, { ChangeEvent, FC, ReactNode } from 'react';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import classnames from 'classnames';

import CustomInput from 'Components/CustomInput';
import StyledSelect from 'Components/StyledSelect';
import ChipsSelect from 'Components/ChipsSelect';
import ExpiryForm from 'Components/ExpiryForm';
import TooltipAnchor from 'Components/TooltipAnchor';

import {
  BANNER_TYPE_OPTIONS,
  // TARGET_APP_OPTIONS,
  USER_AUTH_STATUS_OPTIONS,
} from 'Constants/dictionaries';
import { getErrorsByName } from 'Utils/formik';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import { DictionaryItem } from 'Types/dictionaryItem.type';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { BannerType } from 'Constants/enums';
import * as styles from './index.module.scss';

type OptType = DictionaryItem<string>;

interface BannerInfoProps {
  formik: any; // TODO: add the type later
  handleChanges: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => any;
  states: OptType[];
  locations: OptType[];
  canSeeLocations?: boolean;
}

const BannerInfo:FC<BannerInfoProps> = ({
  formik,
  handleChanges,
  states,
  locations,
  canSeeLocations = false,
}) => {
  const intl = useIntl();
  const selectedLocation = get(formik?.values, 'banner.locationFilters')?.[0] || '';

  const handleScheduleDate = (value: Date | null) => {
    const time = value ? new Date(value).getTime() : null;
    formik.setFieldValue('postAt', time);
  };

  const renderBoldChunks = (chunks: ReactNode[]) => <b>{chunks}</b>;
  const renderBreak = () => <br />;

  return (
    <>
      {/* <div className="mb-15"> */}
      {/*  <Typography className={labelStyles.fieldLabel}> */}
      {/*    {intl.formatMessage({ id: 'label.targetApp' })} */}
      {/*  </Typography> */}
      {/*  <StyledSelect */}
      {/*    name="banner.targetApp" */}
      {/*    value={get(formik?.values, 'banner.targetApp') || ''} */}
      {/*    onChange={handleChanges} */}
      {/*  > */}
      {/*    {TARGET_APP_OPTIONS.map(({ label, value }) => ( */}
      {/*      <MenuItem key={value} value={value}> */}
      {/*        {label} */}
      {/*      </MenuItem> */}
      {/*    ))} */}
      {/*  </StyledSelect> */}
      {/* </div> */}

      <div className="mb-15">
        <label className={labelStyles.fieldLabel}>
          {`${intl.formatMessage({ id: 'label.selectBannerType' })}*`}
        </label>
        <StyledSelect
          name="banner.bannerType"
          value={formik?.values?.banner?.bannerType}
          onChange={handleChanges}
          validationErrors={getErrorsByName(formik, 'banner.bannerType')}
        >
          {BANNER_TYPE_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </StyledSelect>
      </div>

      {canSeeLocations && (
        <>
          <div className="mb-15">
            <label className={labelStyles.fieldLabel}>
              {`${intl.formatMessage({ id: 'label.locations' })}*`}
            </label>
            <StyledSelect
              name="banner.locationFilters"
              value={locations?.length > 0 ? selectedLocation : ''}
              onChange={({ target: { name, value } }) => formik.setFieldValue(name, [value])}
              validationErrors={getErrorsByName(formik, 'banner.locationFilters')}
            >
              {locations.map(({ label, value }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className="mb-15">
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.usStates' })}
            </Typography>
            <ChipsSelect
              values={get(formik?.values, 'banner.countryFilters')}
              options={states}
              onChange={(value) => formik.setFieldValue('banner.countryFilters', value)}
            />
          </div>
        </>
      )}

      {formik?.values?.banner?.bannerType === BannerType.Hero && (
        <>
          <div className="mb-15">
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.userAuthStatus' })}
            </Typography>
            <StyledSelect
              name="banner.userAuthStatus"
              value={get(formik?.values, 'banner.userAuthStatus') || ''}
              onChange={handleChanges}
            >
              {USER_AUTH_STATUS_OPTIONS.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className="mb-15">
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.priority' })}
              {' '}
              <TooltipAnchor
                translationKey="hint.priority"
                values={{
                  br: renderBreak,
                  b: renderBoldChunks,
                }}
              />
            </Typography>
            <CustomInput
              type="number"
              name="banner.priority"
              placeholder={intl.formatMessage({ id: 'placeholder.priority' })}
              value={get(formik?.values, 'banner.priority')}
              onChange={handleChanges}
              validationErrors={getErrorsByName(formik, 'banner.priority')}
            />
          </div>
          <div className="mb-15">
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.visibilityScore' })}
              {' '}
              <TooltipAnchor
                translationKey="hint.weight"
                values={{
                  br: renderBreak,
                  b: renderBoldChunks,
                }}
              />
            </Typography>
            <CustomInput
              type="number"
              name="banner.weight"
              placeholder={intl.formatMessage({ id: 'placeholder.weight' })}
              value={get(formik?.values, 'banner.weight')}
              onChange={handleChanges}
              validationErrors={getErrorsByName(formik, 'banner.weight')}
            />
          </div>
        </>
      )}

      <div className="my-15">
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'bounty.scheduleFor' })}
          {' '}
          <TooltipAnchor
            translationKey="hint.bannerSchedule"
            values={{
              br: renderBreak,
              b: renderBoldChunks,
            }}
          />
        </Typography>
        <DateTimePicker
          ampm={false}
          sx={{ width: '100%' }}
          value={formik?.values?.postAt || null}
          onChange={handleScheduleDate}
          slotProps={{
            textField: { InputProps: { style: { height: '44px' } } },
          }}
        />
      </div>
      <div className={`mb-15 ${styles.availabilityContainer}`}>
        <Typography className={classnames(labelStyles.fieldLabel, styles.label)}>
          {intl.formatMessage({ id: 'bounty.expiresIn' })}
        </Typography>
        <ExpiryForm onChange={handleChanges} values={formik.values} />
      </div>
    </>
  );
};

export default BannerInfo;

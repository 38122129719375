import { ACTIONS } from './actions';

const INIT_STATE = {
  isLoading: false,
  promotions: [],
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_PROMOTIONS:
      return {
        ...state,
        promotions: payload,
      };

    default:
      return state;
  }
};

export default reducer;

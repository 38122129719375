import { SkillsAndIndustriesResponse } from 'Types/skillsAndIndustriesResponse.type';
import { SkillDictionaryItem } from 'Types/skillDictionaryItem.type';
import { IndustryDictionaryItem } from '../types/industryDictionaryItem.type';

export const formatSkills = (data: SkillsAndIndustriesResponse): SkillDictionaryItem[] => {
  let skills: SkillDictionaryItem[] = [];

  for (const [key, obj] of Object.entries(data)) {
    const updSkills: SkillDictionaryItem[] = obj.skills
      .map((s) => ({
        label: s.name || s.id,
        value: s.id,
        industry: {
          label: obj.name || key,
          value: key,
        },
      }))
      .sort((a, b) => (a.label).localeCompare(b.label));
    skills = skills.concat(updSkills);
  }
  return skills.sort((a: SkillDictionaryItem, b: SkillDictionaryItem) => (a.industry.label).localeCompare(b.industry.label));
};

export const formatIndustries = (data: SkillsAndIndustriesResponse): IndustryDictionaryItem[] =>
  Object.entries(data)
    .map(([key, obj]) =>
      ({
        label: obj.name || key,
        value: key,
        skills: obj.skills
          .map(({ id, name }) =>
            ({ label: name || id, value: id })),
      }));

import yaml from 'js-yaml';

const DESCRIPTION_SEP = '\n---';
const PROP_SEP = '\n';

export function encryptDescription(description: string, information: unknown) {
  const text = `${description}${DESCRIPTION_SEP}`;
  const additionalInfo = yaml.dump(information, { quotingType: '"', forceQuotes: true });

  return `${text}${PROP_SEP}${additionalInfo}${PROP_SEP}`;
}

export function parseEncryptedDescription<T>(description: { text: string }): T {
  const { text } = description;
  const info = text.split(DESCRIPTION_SEP)[1];
  const decodedInfo = info ? yaml.load(info) : {};
  return decodedInfo as T;
}

export function getDescriptionText(description: { text: string }) {
  const { text } = description;
  return text.split(DESCRIPTION_SEP)[0];
}

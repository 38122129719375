import {
  getOutboundBountyRef,
  getOutboundRef, removeAttachment,
  updateBountyTimestamp,
  updateSortKeys,
} from 'Services/bounty/BountyService';
import { Bounty } from 'Types/bounty.interface';
import { updateIdentity } from 'Services/BaseService';
import { AttachmentMeaning, BountyState, EntityType, QueueAlias } from 'Constants/enums';
import { buildAgentString } from 'Utils/appConfig';
import { getOrder } from 'Utils/attachments';
import { getAttachmentStorageRef } from 'Services/StorageService';
import { uploadAttachment } from 'Services/UploadService';
import { UserData } from 'Types/userData.interface';
import { encryptDescription } from 'Utils/yaml';
import { getOutboundPriority } from 'Utils/keyUtils';
import { formatBadgeCollection, formatBadges, getBountyUpdates } from 'Utils/bountyCreation';
import { User } from 'firebase/auth';
import { Settings } from 'Types/settings.interface';
import { CreateBadge, CreateBadgeCollection } from 'Types/badge.interface';
import { Attachment } from 'Types/attachment.interface';
import { notificationToast } from 'Utils/notificationToaster';
import { isDraftState } from 'Utils/bountyState';
import { postEvent } from 'Services/Api';
import { generateUID } from 'Utils/helpers';

export const createBadge = async (badgeCollection: CreateBadgeCollection, userData: UserData, user: User, settings: Settings) => {
  const { badges, cover, ...remainingProps } = badgeCollection;
  let cloneBounty = formatBadgeCollection({ values: remainingProps, userData, settings });
  const bountyRef = getOutboundRef(cloneBounty, user).push();
  const identity = await updateIdentity(cloneBounty);

  cloneBounty = {
    ...cloneBounty,
    ...identity,
  };
  const now = new Date().getTime();

  cloneBounty.id = bountyRef.key;
  cloneBounty.createdAt = now;
  cloneBounty.updatedAt = now;
  cloneBounty.state = BountyState.Draft;
  cloneBounty.entityId = cloneBounty.id;

  cloneBounty.agentInfo = { createdOn: buildAgentString() };
  const { uploadedAttachments = {} } = badges
    ? await uploadBadges(cloneBounty, badges, userData)
    : {};
  const oldAttachments = removeUnusedAttachments(cloneBounty, badges);

  let uploadedCover = {};

  if (cover[0] && !cover[0].id) {
    const newCover = await uploadCover(cloneBounty, cover[0], userData);

    if (newCover) {
      uploadedCover = {
        [newCover.id]: newCover,
      };
    }
  }

  cloneBounty.attachments = {
    attachments: {
      ...oldAttachments,
      ...uploadedAttachments,
      ...uploadedCover,
    },
  };

  const formattedBadges = badges ? formatBadges(badges) : {};
  cloneBounty.description = { text: encryptDescription('', { badges: formattedBadges }) };

  bountyRef.setWithPriority({ bounty: cloneBounty }, getOutboundPriority(true, now));
  updateBountyTimestamp(cloneBounty, userData, now);
  updateSortKeys(cloneBounty);

  return cloneBounty;
};

export const editBadge = async (bounty: Bounty, badgeCollection: CreateBadgeCollection, userData: UserData, user: User, settings: Settings) => {
  const { badges, cover, prevCover, ...remainingProps } = badgeCollection;
  const cloneBounty = {
    ...JSON.parse(JSON.stringify(bounty)),
    ...formatBadgeCollection({ values: remainingProps, userData, settings }),
  };
  const editedAt = new Date().getTime();

  if (cloneBounty.type === BountyState.Rejected) {
    cloneBounty.type = BountyState.Draft;
  }

  const { uploadedAttachments = {} } = badges
    ? await uploadBadges(cloneBounty, badges, userData)
    : {};

  let uploadedCover = {};

  if (cover[0] && !cover[0].id) {
    const newCover = await uploadCover(cloneBounty, cover[0], userData);

    if (newCover) {
      uploadedCover = {
        [newCover.id]: newCover,
      };

      if (prevCover) {
        await removeAttachment(cloneBounty, prevCover);
      }
    }
  }

  const oldAttachments = removeUnusedAttachments(bounty, badges);

  cloneBounty.attachments = {
    attachments: {
      ...oldAttachments,
      ...uploadedAttachments,
      ...uploadedCover,
    },
  };

  const formattedBadges = badges ? formatBadges(badges) : {};
  cloneBounty.description = { text: encryptDescription('', { badges: formattedBadges }) };

  const { updates, changes } = getBountyUpdates(bounty, cloneBounty);

  if (!Object.keys(updates).length) {
    notificationToast.info(`Nothing really changed in bounty ${cloneBounty.id}`);
    return Promise.resolve();
  }

  if (!isDraftState(cloneBounty.state)) {
    cloneBounty.editedAt = editedAt;
    updates.editedAt = editedAt;
    changes.editedAt = editedAt;
  }

  getOutboundBountyRef(cloneBounty)?.update(updates);

  updateBountyTimestamp(cloneBounty, user, editedAt);
  postEvent(QueueAlias.UpdateBounty, { bountyId: cloneBounty.id, changes });
};

const uploadOneBadge = async (userData: UserData, bounty: Bounty, badge: any, order: number) => {
  const attachmentId = generateUID();

  const attachmentStorageRef = getAttachmentStorageRef({
    entityType: EntityType.bounty,
    hasAttachments: bounty,
    attachmentType: badge.type,
    attachmentId,
    userData,
  });

  return uploadAttachment({
    attachmentStorageRef,
    attachmentId,
    order,
    attachment: badge,
  });
};

const removeUnusedAttachments = (bounty: Bounty, badges?: Record<string, CreateBadge>) => {
  const oldAttachments = bounty?.attachments?.attachments || {};

  if (!badges || !Object.keys(badges).length) {
    return oldAttachments;
  }

  const unusedAttachments: Attachment[] = [];
  const usedAttachments: Record<string, Attachment> = {};
  const badgeAttachments: string[] = Object.values(badges).reduce((acc: string[], { image, icon }) => {
    if (image) {
      acc.push(image);
    }

    if (icon) {
      acc.push(icon);
    }
    return acc;
  }, []);

  Object.values(oldAttachments).forEach((attachment) => {
    const { url, meaning } = attachment;

    if (meaning === AttachmentMeaning.Cover || badgeAttachments.includes(url)) {
      usedAttachments[attachment.id] = attachment;
    } else {
      unusedAttachments.push(attachment);
    }
  });

  unusedAttachments.forEach((attach) => removeAttachment(bounty, attach));

  return usedAttachments;
};

const uploadBadges = async (bounty: Bounty, badges: Record<string, CreateBadge>, userData: UserData) => {
  const oldAttachments = bounty?.attachments?.attachments
    ? JSON.parse(JSON.stringify(bounty.attachments.attachments))
    : {};
  let order = getOrder(Object.values(oldAttachments) || []);
  const badgeKeys = Object.keys(badges);
  const uploadedAttachments: Record<string, Attachment> = {};

  for (let index = 0; index < badgeKeys.length; index++) {
    const badge = badges[badgeKeys[index]];
    const promises: any[] = [];

    if (badge.badgeImage?.length) {
      promises.push(uploadOneBadge(userData, bounty, badge.badgeImage[0], order));
      order++;
    }

    if (badge.badgeIcon?.length) {
      promises.push(uploadOneBadge(userData, bounty, badge.badgeIcon[0], order));
      order++;
    }

    const responses = await Promise.allSettled<PromiseSettledResult<Attachment>>(promises);

    responses
      .filter((resp) => resp.status === 'fulfilled')
      .forEach(({ value }) => {
        uploadedAttachments[value.id] = value;

        if (value.meaning === AttachmentMeaning.BadgeIcon) {
          badge.icon = value.url;
        } else {
          badge.image = value.url;
        }
      });
  }

  return {
    badges,
    uploadedAttachments,
  };
};

const uploadCover = async (bounty: Bounty, cover: Attachment, userData: UserData) => {
  const oldAttachments = bounty?.attachments?.attachments
    ? JSON.parse(JSON.stringify(bounty.attachments.attachments))
    : {};
  const order = getOrder(Object.values(oldAttachments) || []);

  return uploadOneBadge(userData, bounty, cover, order);
};

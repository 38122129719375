import { FC } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { isCheckable } from 'Utils/survey';
import CustomInput from 'Components/CustomInput';
import CheckboxStyled from 'Components/CheckboxStyled';

import { BountyType } from 'Constants/enums';
import * as styles from './index.module.scss';

interface SurveyChoiceProps {
  bountyType: BountyType;
  option: any; // TODO: Add description
  hasDelete?: boolean;
  isChecked?: boolean;
  handleCorrectChoice: () => any;
  handleChoiceChanges: () => any;
  handleDelete: () => any;
}

const SurveyChoice: FC<SurveyChoiceProps> = ({
  bountyType,
  option,
  hasDelete = false,
  isChecked = false,
  handleCorrectChoice,
  handleChoiceChanges,
  handleDelete,
  ...otherProps
}) => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'bounty.choiceCode' }, { code: option.code });

  return (
    <div className={styles.root}>
      {isCheckable(bountyType) && (
        <div className="mr-10">
          <CheckboxStyled
            value={option.code}
            className={classnames({ 'text-success': isChecked })}
            checked={isChecked}
            onChange={handleCorrectChoice}
          />
        </div>
      )}
      <div className={classnames('has-wrapper', styles.inputItem)}>
        <CustomInput
          {...otherProps}
          className="has-custom-input"
          name={option.code}
          value={option?.text || ''}
          placeholder={placeholder}
          onChange={handleChoiceChanges}
        />
        {hasDelete && (
          <span
            role="presentation"
            className={classnames('has-custom-icon icon-trash ml-10', styles.trash)}
            onClick={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

export default SurveyChoice;

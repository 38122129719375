import Checkbox from '@mui/material/Checkbox';
import { FC } from 'react';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import * as styles from './styles.module.scss';

interface CheckboxStyledProps extends CheckboxProps {
  className?: string;
}

const CheckboxStyled: FC<CheckboxStyledProps> = ({ className = '', ...otherProps }) => (
  <Checkbox
    {...otherProps}
    disableRipple
    className={`${styles.checkboxStyled} ${className}`}
  />
);

export default CheckboxStyled;

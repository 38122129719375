// on android is handleCreateClick from BaseCreateActivity
import { isAllowed, getAvailableOrganizationTypes } from 'Utils/settings';
import { CompanyType } from 'Constants/enums';
import { CREATE_ORGANIZATION } from 'Constants/op';
import { Settings } from 'Types/settings.interface';
import { UserData } from 'Types/userData.interface';
// import { isBundle } from 'Utils/models/bounty/Bounty';

export function getAllowedCompanyTypes(settings: Settings, userData: UserData) {
  let companyTypes = [];

  if (isAllowed({ op: CREATE_ORGANIZATION.name, settings, userData })) {
    companyTypes = getAvailableOrganizationTypes(settings, userData);
  }

  return companyTypes;
}

/**
 * menu definitions from "CreateCompanyOptionsFragment"
 */

interface MenuOption {
  bountyType: CompanyType;
  name: string;
  icon: string;
  activityType: CompanyType;
}

export const companyDialogOptionsConfig: { [key in CompanyType]?: MenuOption } = {
  [CompanyType.Author]: {
    bountyType: CompanyType.Author,
    name: 'bounty.createAuthor',
    icon: 'person_add',
    activityType: CompanyType.Author,
  },
  [CompanyType.Merchant]: {
    bountyType: CompanyType.Merchant,
    name: 'bounty.createMerchant',
    icon: 'person_add',
    activityType: CompanyType.Merchant,
  },
  [CompanyType.Charity]: {
    bountyType: CompanyType.Charity,
    name: 'bounty.createCharity',
    icon: 'person_add',
    activityType: CompanyType.Charity,
  },
  [CompanyType.Family]: {
    bountyType: CompanyType.Family,
    name: 'bounty.createFamily',
    icon: 'group_add',
    activityType: CompanyType.Family,
  },
  [CompanyType.Employer]: {
    bountyType: CompanyType.Employer,
    name: 'bounty.createEmployer',
    icon: 'group_add',
    activityType: CompanyType.Employer,
  },
  [CompanyType.Ambassador]: {
    bountyType: CompanyType.Ambassador,
    name: 'bounty.createAmbassador',
    icon: 'group_add',
    activityType: CompanyType.Ambassador,
  },
  [CompanyType.Partner]: {
    bountyType: CompanyType.Partner,
    name: 'bounty.createPartner',
    icon: 'group_add',
    activityType: CompanyType.Partner,
  },
};

export function buildOrgMenu(companyTypes: CompanyType[]) {
  const menuOptions: MenuOption[] = [];

  companyTypes.forEach((type) => {
    const companyConfig = companyDialogOptionsConfig[type];

    if (companyConfig) {
      menuOptions.push(companyConfig);
    }
  });

  return menuOptions;
}

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyAhD2zOBOPLs10NUyYdZSeRQne7ETnAbhs',
    authDomain: 'dinamo1948-prod.firebaseapp.com',
    databaseURL: 'https://dinamo1948-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'dinamo1948-prod',
    storageBucket: 'dinamo1948-prod.appspot.com',
    messagingSenderId: '36480570928',
    appId: '1:36480570928:web:302bec50b51b561238bff5',
    measurementId: 'G-6BP5PL7YXT',
  },
  stripeKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://edge-server-fvsjwjtfha-ew.a.run.app',
  algolia_app_id: '',
  algolia_api_key: '',
};

export default config;

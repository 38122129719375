import { FC, ReactNode } from 'react';
import * as styles from './index.module.scss';

interface CustomChipProps {
  label: string | ReactNode;
  className: string;
  icon?: ReactNode;
  tooltipTitle?: string;
  onClick: () => any;
}

const CustomChip: FC<CustomChipProps> = ({ label, className = '', icon = null, onClick, tooltipTitle = '', ...rest }) => (
  <button type="button" className={`${styles.customChip} ${className}`} title={tooltipTitle}
          onClick={onClick} {...rest}>
    {icon && <div className={styles.icon}>{icon}</div>}
    <div className={styles.label}>{label}</div>
    <span className="material-symbols-rounded font-15">
      close
    </span>
  </button>
);

export default CustomChip;

import { Fee, ShippingFee } from "Types/shippingFees.interface";
import { Currency } from "Constants/enums";
import { extractNumberFromNumericFormat } from "Utils/numbers";

export const formatShippingFeeForServer = (shippingFee: ShippingFee): ShippingFee => {
  const internationalFlatAmount = shippingFee?.internationalFee?.flatRate?.money?.amount;
  const internationalFreeAmount = shippingFee?.internationalFee?.freeOver?.money?.amount;
  const formattedDomesticFee: Record<string, Fee> = {};

  Object.entries(shippingFee?.domesticFees)?.forEach(([key, fee]) => {
    const domesticFlatAmount = fee?.flatRate?.money?.amount;
    const domesticFreeAmount = fee?.freeOver?.money?.amount;

    formattedDomesticFee[key] = {
      ...fee,
      ...(domesticFlatAmount ? {
        flatRate: {
          money: {
            amount: extractNumberFromNumericFormat(domesticFlatAmount).toString(),
            currency: Currency.USD,
          },
        },
      } : {}),
      ...(domesticFreeAmount ? {
        freeOver: {
          money: {
            amount: extractNumberFromNumericFormat(domesticFreeAmount).toString(),
            currency: Currency.USD,
          },
        },
      } : {}),
    };
  });

  return {
    domesticFees: formattedDomesticFee,
    internationalFee: {
      ...shippingFee?.internationalFee,
      ...(internationalFlatAmount ? {
        flatRate: {
          money: {
            amount: extractNumberFromNumericFormat(internationalFlatAmount).toString(),
            currency: Currency.USD,
          },
        },
      } : {}),
      ...(internationalFreeAmount ? {
        freeOver: {
          money: {
            amount: extractNumberFromNumericFormat(internationalFreeAmount).toString(),
            currency: Currency.USD,
          },
        },
      } : {}),
    }
  };
};

import { union } from 'lodash';
import * as RatingUtils from 'Utils/rating';
import { Point } from 'Types/point.interface';
import { Bounty } from 'Types/bounty.interface';
import { Rating } from 'Types/rating.interface';
import { PointCurrency } from 'Constants/enums';
import { Reward } from 'Types/reward.interface';
import { UseCases } from './globalPermissions';
import { AppConfig } from './appConfig';

export const starsValue: Record<string, number> = {
  [PointCurrency.Star]: 1,
  [PointCurrency.Star2]: 2,
  [PointCurrency.Star3]: 3,
};

export const normalizeCurrency = (currency: string) => {
  switch (currency) {
    case PointCurrency.Star2:
    case PointCurrency.Star3:
      return PointCurrency.Star;
    default:
      return currency;
  }
};

export const multiplier = (currency: string) => starsValue[currency] || 1;

export const normalizePoints = (point: Point) => {
  const { amount, currency } = point;
  const pointCurrency = pointCurrencyAsEnum(currency);
  const norm = normalizeCurrency(pointCurrency);

  if (pointCurrency === norm) {
    return point;
  }

  return {
    currency: norm,
    amount: amount * multiplier(currency),
  };
};

export const isZero = (point: Point) => {
  const { amount } = point;

  return amount === 0;
};

export const negate = (point: Point) => {
  const { amount, currency } = point;

  return isZero(point) ? point : { amount: -amount, currency };
};

export const pointToString = ({ amount, currency }: Point) => `${amount} ${currency}`;

export const pointCurrencyAsEnum = (currency: string) => {
  if (!currency) {
    return PointCurrency.None;
  }

  return currency;
};

export const setStartPointValue = (value: number, rating?: Rating): Rating => {
  const newRating = rating || {
    points: null,
    userRatings: null,
    totalStars: 0,
    ratingCount: 0,
    starPointsValue: 0,
  };

  return {
    ...newRating,
    starPointsValue: value,
  };
};

export const asMap = (point: Point) => {
  if (point && Object.keys(point).length) {
    return { [point.currency]: point };
  }

  return null;
};

export const sumOfAbs = (points: Record<string, Point>) => {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  let sum = 0;

  Object.values(points).forEach(({ amount }) => {
    sum += Math.abs(amount);
  });

  return sum;
};

export const getPointsAmount = (points: Record<string, Point> | null, currency: string) => {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  const point = points[currency];

  return (point && point.amount) || 0;
};

const addInPlace = (points: Record<string, Point>, point: Point) => {
  if (point === null || !Object.keys(point).length) {
    return;
  }

  let old = points[point.currency];

  if (old !== undefined) {
    old.amount += point.amount;
  } else {
    old = point;
  }

  return {
    ...points,
    [point.currency]: old,
  };
};

export const addRating = (rating: Rating, userId: string, point: Point) => {
  const newRating = rating || {};

  if (point === null) {
    return rating;
  }

  if (newRating.points === undefined) {
    newRating.points = {};
  }

  const addedPoints = addInPlace(newRating.points, point);

  if (addedPoints) {
    newRating.points = addedPoints;
  }

  if (newRating.userRatings === undefined) {
    newRating.userRatings = {};
  }

  newRating.userRatings = {
    [userId]: point,
  };

  return newRating;
};

export const getPoints = (rating: Rating, currency: string) => ({
  amount: rating !== null ? getPointsAmount(rating?.points, currency) : 0,
  currency,
});

export const getNormalizedPointCurrencies = (points: Record<string, Point>) => {
  if (!points || (points && !Object.keys(points).length)) {
    return [];
  }

  const currencyList: string[] = [];

  Object.values(points).forEach((point) => {
    const normCurrency = normalizeCurrency(pointCurrencyAsEnum(point.currency));

    if (!currencyList.includes(normCurrency)) {
      currencyList.push(normCurrency);
    }
  });

  return currencyList;
};

export const getNormalizedPoints = (points: Record<string, Point>, currency: string) => {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  let sum = 0;

  Object.values(points).forEach((point) => {
    const normPoint = normalizePoints(point);

    if (pointCurrencyAsEnum(normPoint.currency) === currency) {
      sum += normPoint.amount;
    }
  });

  return sum;
};

export const addPoints = (existingPoints?: Record<string, Point> | null, pointsToAdd?: Record<string, Point> | null) => {
  if (!pointsToAdd || !Object.keys(pointsToAdd).length) {
    return existingPoints;
  }

  if (!existingPoints || !Object.keys(existingPoints).length) {
    return pointsToAdd;
  }

  const allCurrencies: PointCurrency[] = union(
    Object.keys(existingPoints) as PointCurrency[],
    Object.keys(pointsToAdd) as PointCurrency[],
  );

  const points: Record<string, Point> = {};
  allCurrencies.forEach((currency) => {
    if (!existingPoints[currency]) {
      points[currency] = pointsToAdd[currency];
    } else if (!pointsToAdd[currency]) {
      points[currency] = existingPoints[currency];
    } else {
      points[currency] = {
        currency,
        amount: +existingPoints[currency].amount + +pointsToAdd[currency].amount,
      };
    }
  });

  return points;
};

export const getAvgStartRating = (bounty: Bounty) => {
  if (!bounty || !bounty.rating) {
    return null;
  }

  return RatingUtils.getAvgStartRating(bounty.rating);
};

export const negatePoints = (points: Record<string, Point> | null) => {
  if (points == null) {
    return null;
  }

  const map: Record<string, Point> = {};

  if (!Object.keys(points).length) {
    return map;
  }

  Object.keys(points).forEach((key) => {
    map[key] = negate(points[key]);
  });

  return map;
};

export const subtractPoints = (p1: Record<string, Point> | null, p2: Record<string, Point> | null) => {
  if (p2 == null || !Object.keys(p2).length) {
    return p1 || null;
  }

  if (p1 == null || !Object.keys(p1).length) {
    return negatePoints(p2);
  }

  return addPoints(p1, negatePoints(p2));
};

export function getRewardPointsAmount(reward: Reward, currency: string) {
  if (!reward) {
    return 0;
  }

  if (!reward?.points || !Object.keys(reward?.points).length) {
    return 0;
  }

  return reward?.points[currency]?.amount || 0;
}

export const getPointLabelByUseCase = () => {
  if (UseCases.campaigns === AppConfig?.useCase) {
    return {
      one: 'Token',
      plural: 'Tokens',
    };
  }

  return {
    one: 'Point',
    plural: 'Points',
  };
};

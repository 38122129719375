import { FC } from 'react';
import classnames from "classnames";

import { useNavigate } from 'react-router-dom';
import { generateLink } from 'Utils/link';
import { USER_DETAILS_ROUTE } from 'Constants/routes';
import { Box } from '@mui/material';
import { formatUserName } from 'Utils/formatters';
import { Bounty } from 'Types/bounty.interface';
import * as styles from './index.module.scss';

interface OrderCustomerNameProps {
  bounty: Bounty;
  companyId?: string;
  name?: string;
  className?: string;
}

const OrderCustomerName: FC<OrderCustomerNameProps> = ({
  bounty,
  companyId,
  name,
  className = '',
}) => {
  const navigate = useNavigate();
  const { order, owner, creator } = bounty;
  const isOrderCreatorGuest = !owner?.name;
  const orderCreatorId = creator?.id;
  const customer = order?.customerInfo?.customer;
  const customerName = customer ? formatUserName(customer?.firstName, customer?.lastName) : '-';

  const openCustomerDetails = () => {
    if (orderCreatorId) {
      navigate(generateLink(`${USER_DETAILS_ROUTE}${companyId ? `?companyId=${companyId}` : ''}`, { userId: orderCreatorId }));
    }
  };

  if (isOrderCreatorGuest) {
    return name || customerName;
  }

  return (
    <Box className={classnames(className, styles.linkStyle)} onClick={openCustomerDetails}>
      {name || customerName}
    </Box>
  );
};

export default OrderCustomerName;

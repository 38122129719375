import { ChangeEvent, FC } from 'react';
import { FormikValues } from 'formik';
import { MenuItem, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import CustomInput from 'Components/CustomInput';
import StyledSelect from 'Components/StyledSelect';

import { JoiningStrategy } from 'Constants/enums';
import { getErrorsByName } from 'Utils/formik';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './index.module.scss';

interface GroupCreateInformationProps {
  formik: FormikValues;
  onChange: (t: ChangeEvent<HTMLInputElement>) => void;
}

const GroupCreateInformation: FC<GroupCreateInformationProps> = ({ formik, onChange }) => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <div>
        <Typography className={labelStyles.fieldLabel}>
          {`${intl.formatMessage({ id: 'label.groupName' })}*`}
        </Typography>
        <CustomInput
          name="name"
          value={formik?.values?.name || ''}
          onChange={onChange}
          validationErrors={getErrorsByName(formik, 'name')}
        />
      </div>
      <div>
        <Typography className={labelStyles.fieldLabel}>
          {`${intl.formatMessage({ id: 'description' })}*`}
        </Typography>
        <CustomInput
          name="description"
          value={formik?.values?.description || ''}
          onChange={onChange}
          validationErrors={getErrorsByName(formik, 'description')}
        />
      </div>
      <div>
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.membersCanJoin' })}
        </Typography>
        <StyledSelect
          displayEmpty
          name="joiningStrategy"
          value={formik?.values?.joiningStrategy || ''}
          onChange={onChange}
        >
          <MenuItem disabled value="">
            <em>{intl.formatMessage({ id: 'label.select.policy' })}</em>
          </MenuItem>
          <MenuItem value={JoiningStrategy.InviteOnly}>{intl.formatMessage({ id: 'label.invited.only' })}</MenuItem>
          <MenuItem value={JoiningStrategy.NeedsApproval}>{intl.formatMessage({ id: 'label.need.approval' })}</MenuItem>
          <MenuItem value={JoiningStrategy.Open}>{intl.formatMessage({ id: 'label.need.freely' })}</MenuItem>
        </StyledSelect>
      </div>
    </div>
  );
};

export default GroupCreateInformation;

import { useIntl } from 'react-intl';
import { Badge, ButtonBase, Popover } from '@mui/material';
import cs from 'classnames';
import React, { MouseEvent, useEffect, useState } from 'react';
import { onValue, query, off } from 'firebase/database';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import OrdersList from 'Components/OrdersList';

import { getCompanyOrders, getCompanyOrderStats } from 'Services/FirebaseService';
import { authUserCompanyIdSelector } from 'Store/auth/selectors';
import OrderService from 'Services/OrderService';

import { Bounty } from 'Types/bounty.interface';
import { FirebaseOrderDetails } from 'Types/order.interface';
import { notificationToast } from 'Utils/notificationToaster';
import { convertBountyToFirebaseOrder } from 'Utils/orders';
import * as styles from './index.module.scss';

const OrderNotifications = () => {
  const intl = useIntl();
  const [orderCount, setOrderCount] = useState<number>(0);
  const [orders, setOrders] = useState<FirebaseOrderDetails[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isOpen = Boolean(anchorEl);

  const companyIdOfLoggedUser = useSelector(authUserCompanyIdSelector);

  const handleOpenPopper = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (companyIdOfLoggedUser) {
      fetchOrderStats();
      fetchOrders();
    }
  }, [companyIdOfLoggedUser]);

  useEffect(() => () => {
    const orderStatsQuery = query(getCompanyOrderStats(companyIdOfLoggedUser));
    const ordersQuery = query(getCompanyOrders(companyIdOfLoggedUser));

    off(orderStatsQuery);
    off(ordersQuery);
  }, []);

  const fetchOrders = () => {
    const dbQuery = query(getCompanyOrders(companyIdOfLoggedUser));

    onValue(
      dbQuery,
      (dataSnapshot) => {
        const val = dataSnapshot.val() as Record<string, Bounty>;
        setOrders(val ? Object.values(val).map((bounty) => convertBountyToFirebaseOrder(bounty)) : []);
      },
      () => {
        setOrders([]);
      },
    );
  };

  const fetchOrderStats = () => {
    const dbQuery = query(getCompanyOrderStats(companyIdOfLoggedUser));

    onValue(
      dbQuery,
      (dataSnapshot) => {
        const val = dataSnapshot.val() as number;
        setOrderCount(val || 0);
      },
      () => {
        setOrderCount(0);
      },
    );
  };

  const handleValidateOrder = async (order: FirebaseOrderDetails) => {
    setIsLoading(true);
    try {
      await OrderService.removeOrderAlert(companyIdOfLoggedUser, order?.bounty?.id);
    } catch (error: any) {
      notificationToast.error(error.message || 'notification.error.unknown.error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ButtonBase
        disableRipple
        className={`${cs(styles.orderButton, { [styles.orderButtonActive]: isOpen })} no-shake`}
        onClick={handleOpenPopper}
      >
        <Badge badgeContent={orderCount} color="primary" className={styles.badge}>
          <span className={`has-custom-icon material-symbols-rounded ${cs(styles.orderIcon)}`}>
            production_quantity_limits
          </span>
        </Badge>
      </ButtonBase>

      {isOpen && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          slotProps={{ paper: { className: cs(styles.popperPaper, styles.hiddenScroll) } }}
          onClose={handleClose}
        >
          {orderCount && !isEmpty(orders) ? (
            <div className={styles.ordersWrapper}>
              <OrdersList
                data={orders}
                companyId={companyIdOfLoggedUser}
                onValidateOrder={handleValidateOrder}
                isLoading={isLoading}
                onClose={handleClose}
              />
            </div>
          ) : (
            <div className={styles.noOrders}>
              <p>{intl.formatMessage({ id: 'notification.noNewOrders' })}</p>
            </div>
          )}
        </Popover>
      )}
    </>
  );
};

export default OrderNotifications;

import classnames from 'classnames';
import { FC } from 'react';

import * as styles from './index.module.scss';

interface ReplyToProps {
  description: string;
  className?: string;
  truncated?: boolean;
}

const ReplyTo: FC<ReplyToProps> = ({ description, className = '', truncated = false }) => (
  <div className={classnames(styles.description, className)}>
    <p className={classnames({ [styles.truncated]: truncated }, 'mb-0')}>
      {description}
    </p>
  </div>
);

export default ReplyTo;

import { AnyAction } from '@reduxjs/toolkit';
import { Actions } from 'Store/specialPrograms/actions';
import { formatSpecialProgramsSpecialties } from 'Utils/specialPrograms';
import { SpecialProgramSpecialtyDictionaryItem } from 'Types/specialProgramSpecialtyDictionaryItem.type';

interface State {
  data: SpecialProgramSpecialtyDictionaryItem[];
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: State = {
  data: [],
  isLoading: false,
  isLoaded: false,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.GetData:
      return {
        ...state,
        data: formatSpecialProgramsSpecialties(payload),
      };

    case Actions.Loading:
      return {
        ...state,
        isLoading: payload,
      };

    case Actions.Loaded:
      return {
        ...state,
        isLoaded: payload,
      };

    default:
      return state;
  }
};

export default reducer;

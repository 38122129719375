import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IconButton, Tooltip } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import AttachmentFormDetails from 'Containers/BountyAttachmentManagement/AttachmentFormDetails';
import CustomDropzone from 'Components/CustomDropzone';
import { attachmentsListSelector } from 'Store/attachments/selectors';
import {
  ACTIONS,
  addAttachments,
  removeAttachments,
  reorderAttachments,
  setAttachments,
  updateAttachment,
} from 'Store/attachments/actions';
import { Bounty } from 'Types/bounty.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import { Attachment } from 'Types/attachment.interface';
import { orderAttachments } from 'Utils/attachments';
import { IMAGE_MIME_TYPES } from 'Constants/attachment';
import { notificationToast } from 'Utils/notificationToaster';
import * as actionableIcons from 'Assets/scss/modules/actionableIcons.module.scss';
import * as styles from './index.module.scss';

interface BannerManagementProps {
  bounty: Bounty;
  title: string;
  titleTooltip?: ReactNode | null;
  selectedCompanyId?: string;
}

const DragHandle = () => (
  <IconButton className={classNames(actionableIcons.iconContainer, styles.grabButton)}>
    <i className="icon-arrow-swap" />
  </IconButton>
);

interface SortableAttachProps {
  attachment: Attachment;
  selectedCompanyId?: string;
  onChange: (attach: Attachment) => void;
  onRemove: () => void;
  index: number;
  slideNumber: number;
}

const SortableAttach: FC<SortableAttachProps> = ({
  attachment,
  onChange,
  onRemove,
  selectedCompanyId,
  index,
  slideNumber,
}) => {
  const {
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: index });
  const intl = useIntl();

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'none',
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <AttachmentFormDetails
        slideNumber={slideNumber}
        attachment={attachment}
        selectedCompanyId={selectedCompanyId}
        onChange={onChange}
        onRemove={onRemove}
        customActions={(
          <Tooltip
            id="tooltip-drag"
            title={intl.formatMessage({ id: 'label.dragToReorderSlides' })}
            placement="top"
          >
            <div {...listeners}><DragHandle /></div>
          </Tooltip>
        )}
      />
    </div>
  );
};

const acceptedFiles = IMAGE_MIME_TYPES.reduce((prevVal, currVal) => ({ ...prevVal, [currVal]: [] }), {});

const BountyAttachmentManagement:FC<BannerManagementProps> = ({
  bounty,
  title,
  titleTooltip = null,
  selectedCompanyId,
}) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();
  const attachments: Attachment[] = useSelector(attachmentsListSelector) || [];
  const [attachmentsSlides, setAttachmentsSlides] = useState<number[]>([]);

  useEffect(() => {
    if (bounty) {
      const existingAttachments = bounty?.attachments?.attachments || {};

      dispatch(setAttachments({
        attachments: existingAttachments,
        inlineAttachments: orderAttachments(Object.values(existingAttachments)),
      }));
      setAttachmentsSlides(Object.keys(existingAttachments).map((_, index) => index + 1));
    }
  }, []);

  const handleChanges = (attach: Attachment, attachmentIndex: number): void => {
    dispatch(updateAttachment(attach, attachmentIndex));
  };

  const handleRemoveAttachment = (attachmentIndex: number): void => {
    dispatch(removeAttachments(attachmentIndex));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!!active.id && !!over?.id && active.id !== over.id) {
      const from = +active.id - 1;
      const to = +over.id - 1;
      const newAttachments = arrayMove(attachments, from, to);
      const newAttachmentSlides = arrayMove(attachmentsSlides, from, to);

      dispatch(reorderAttachments(newAttachments));
      setAttachmentsSlides(newAttachmentSlides);
    }
  };

  const handlePickFile = (data: Attachment[]) => {
    setAttachmentsSlides((prevSlides) => [...prevSlides, attachments.length + 1]);
    dispatch(addAttachments(data, ACTIONS.ADD_ATTACHMENT));
    notificationToast.success(<FormattedMessage id="notification.success.uploadedFindAtEnd" />);
  };

  const itemIds = useMemo(() => attachments.map((item, index) => index + 1), [attachments]);

  return (
    <>
      <div className={styles.titleHeader}>
        <h4>{title}</h4>
        {titleTooltip}
      </div>

      <CustomDropzone
        accept={acceptedFiles}
        onFilePick={handlePickFile}
      >
        <div className={styles.uploadButton}>
          <span className="material-symbols-rounded font-24 mr-5">
            <span className="upload" />
          </span>
          <b>{intl.formatMessage({ id: 'button.uploadImage' })}</b>
        </div>
      </CustomDropzone>

      <div>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
          >
            {attachments?.map((attach, index) => (
              <SortableAttach
                key={attach.id || attach.filename + index || index}
                attachment={attach}
                index={index + 1}
                slideNumber={attachmentsSlides[index]}
                selectedCompanyId={selectedCompanyId}
                onChange={(attach) => handleChanges(attach, index)}
                onRemove={() => handleRemoveAttachment(index)}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
};

export default BountyAttachmentManagement;

import { isEmpty } from 'lodash';

import { Attachment } from 'Types/attachment.interface';
import { Filter } from 'Types/filter.interface';
import { Category } from 'Types/category.interface';
import { FILTER_CODE_SEPARATOR } from 'Constants/common';

export const getSelectedFilters = (attachment: Attachment, productFilters: Filter[]) => {
  let newSelectedFilters: Record<string, string[]> = {};

  if (attachment?.action?.tags) {
    const optionIdsAndCodes = attachment?.action?.tags?.split(',');

    optionIdsAndCodes?.forEach((optionIdAndCode) => {
      const [, optionCode] = optionIdAndCode?.split(FILTER_CODE_SEPARATOR);

      productFilters?.find((filter) => {
        const foundOption = filter?.options?.some(({ code }) => code === optionCode);
        const optionsList = newSelectedFilters?.[filter?.id] || [];

        if (foundOption) {
          newSelectedFilters = {
            ...newSelectedFilters,
            [filter?.id]: [...optionsList, optionCode],
          };
        }

        return foundOption;
      });
    });
  }

  return newSelectedFilters;
};

export const getFiltersParams = (
  selectedFilters: Record<string, string[]> | null,
  selectedCategories: Category[],
  clientId: string,
) => {
  const params = new URLSearchParams();

  if (selectedFilters && !isEmpty(selectedFilters)) {
    Object.values(selectedFilters).forEach((filters) => {
      filters.forEach((filter) => {
        params.append('tag', filter);
      });
    });
  }

  const categoryFilter = selectedCategories?.[selectedCategories?.length - 1]?.code || '';

  params.append('tag', 'is_adult_use');
  params.append('tag', 'is_non_cannabis');
  params.append('clientId', clientId);

  if (categoryFilter) {
    params.append('category', categoryFilter);
  }

  return params;
};

import { firebaseGetCompanySents } from 'Services/FirebaseService';
import { CompanySentsFolder } from 'Constants/enums';
import { streamSnapshotToArray } from 'Utils/helpers';
import { settingsSelector, userDataSelector } from 'Store/settings/selectors';
import { selectedProductsSelector } from 'Store/createBounty/selectors';
import { createBounty, onEditBounty } from 'Services/bounty/BountyService';
import { formatPromotion } from 'Utils/bountyCreation';
import { setSelectedProducts } from '../createBounty/actions.ts';

export const ACTIONS = {
  SET_IS_LOADING: 'promotionsReducer/setIsLoading',
  SET_PROMOTIONS: 'promotionsReducer/setPromotions',
};

const getPromotionState = (state) => ({
  userData: userDataSelector(state).data || {},
  settings: settingsSelector(state) || {},
  selectedProducts: selectedProductsSelector(state) || [],
});

export const getSentPromotions = (ownerId) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  firebaseGetCompanySents(ownerId, CompanySentsFolder.Promotions)
    .orderByChild('bounty/createdAt')
    .on('value', (dataSnapshot) => {
      const result = streamSnapshotToArray(dataSnapshot, true).reverse();
      dispatch({ type: ACTIONS.SET_PROMOTIONS, payload: result });
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
    }, () => {
      dispatch({ type: ACTIONS.SET_PROMOTIONS, payload: [] });
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
    });
};

export const createPromotion = (values) => (dispatch, getState) => {
  const { userData, settings, selectedProducts } = getPromotionState(getState());
  const promotionData = { ...values, selectedProductData: selectedProducts[0] || {} };
  const payload = formatPromotion(promotionData, userData, settings);

  dispatch(setSelectedProducts([]));
  return dispatch(createBounty(payload, null));
};

export const editPromotion = (values, oldBounty) => (dispatch, getState) => {
  const { userData, settings, selectedProducts } = getPromotionState(getState());
  const promotionData = { ...values, selectedProductData: selectedProducts[0] || {} };
  const payload = {
    ...oldBounty,
    ...formatPromotion(promotionData, userData, settings),
  };

  dispatch(setSelectedProducts([]));
  return dispatch(onEditBounty({ newBounty: payload, oldBounty }));
};

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyCUT0zvkYjlJmfiDTkJIvMCoaHeLLuNLkg',
    authDomain: 'fcwiltz-prod.firebaseapp.com',
    databaseURL: 'https://fcwiltz-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'fcwiltz-prod',
    storageBucket: 'fcwiltz-prod.appspot.com',
    messagingSenderId: '604822142791',
    appId: '1:604822142791:web:dda3bfe85321d0e9d31662',
    measurementId: 'G-0R6XECBX6S',
  },
  stripeKey: 'pk_live_51HAd9YClAKpP590WbZpNar2FwxP2r9uZglZgIfEjT247vJnrji0TBdDoFfJVgy4X2HFVUl3eletPHW3JwTvd8YCt00mOlIW0gl',
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: '',
  algolia_api_key: '',
};

export default config;

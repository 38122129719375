import { DictionaryItem } from 'Types/dictionaryItem.type';
import moment from 'moment';
import { NOT_APPLICABLE } from "Constants/common";
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

/**
 * @typedef {Object} DictionaryItem
 * @type {Object}
 * @property {string} label Item label
 * @property {string} value Item value
 *
 */

/**
 * Convert dictionary aka [label: 'a', value: 'val', ...] to {a: 'val, ...}
 *
 *
 * @param {DictionaryItem[]} dictionary Array of items
 * @return {Object}
 */
export const dictionaryToHash = (dictionary: DictionaryItem[]): object => dictionary.reduce((map: {[key: string]: unknown}, obj) => {

  map[obj.value] = obj.label;
  return map;
}, {});

/**
 * Convert dictionary aka [value: 'My Value Label', key: 'my_value',... ] to [label: 'My Value Label', value: 'my_value', ... ]
 * @typedef {Object} OldDictionaryItem
 * @type {Object}
 * @property {string} value Item label
 * @property {string} key Item value
 *
 * @param {OldDictionaryItem[]} arr Array of old dictionary items
 * @return {DictionaryItem[]}
 */

// Key as value
export const hashToDictionary = (obj: { [s: string]: unknown; } | ArrayLike<unknown>): { label: unknown; value: string; }[] => Object.entries(obj)
  .map((b) => ({ label: b[1], value: b[0] }));

export const dateFormatList = (dateValue: moment.MomentInput, format = 'DD MMM YYYY'): string => moment(dateValue).format(format);
// TODO type any
export const dictionaryObjToArray = (obj: { [key: string]: any; } | ArrayLike<unknown>) =>
  (obj ? Object.entries(obj).map((x) => ({ name: x[1]?.name, value: x[0] })) : []);

export const formatUserName = (firstName?: string, lastName?: string): string => {
  if (!firstName && !lastName) {
    return '';
  }

  const fullName = [firstName, lastName].filter((name) => name).join(' ');

  return fullName || '';
};

export const getFormattedNumber = (
  { value, enforceDecimal = false }: { value: number; enforceDecimal?: boolean }
) => (
  (+value).toLocaleString(undefined, { minimumFractionDigits: enforceDecimal ? 2 : 0 })
);

export const formatPhoneNumber = (number?: string | null, emptyState: string = NOT_APPLICABLE) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!number) {
    return emptyState;
  }

  try {
    const phoneNumber = phoneUtil.parse(`+${number}`, '');
    const nationalFormat = phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    const countryCode = `+${phoneNumber.getCountryCode()}`;

    return `${countryCode} ${nationalFormat}`;
  } catch {
    return emptyState;
  }
};

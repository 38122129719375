import { FC, useRef } from 'react';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { BountyType } from 'Constants/enums';
import { notificationToast } from 'Utils/notificationToaster';
import { getBountyShareMessageByBountyType } from 'Utils/translations';

interface BountyShareProps {
  postUrl: string;
  bountyType: BountyType;
}

const BountyShare:FC<BountyShareProps> = ({ postUrl, bountyType }) => {
  const url = useRef<HTMLTextAreaElement>(null);
  const intl = useIntl();

  const copyURL = async () => {
    try {
      if (url.current !== null) {
        await navigator.clipboard.writeText(url.current?.value);
        notificationToast.success(intl.formatMessage({ id: 'notification.success.clipboard.copied' }));
      }
    } catch (error) {
      if (error instanceof Error) return notificationToast.error(error.message);
    }
  };

  const translatedHeadingId = getBountyShareMessageByBountyType(bountyType);
  const translatedDefaultHeading = intl.formatMessage({ id: getBountyShareMessageByBountyType(BountyType.Post) });

  return (
    <>
      <h2>{intl.formatMessage({ id: translatedHeadingId, defaultMessage: translatedDefaultHeading })}</h2>
      <div className="d-flex justify-content-between bg-light mt-10 p-10 rounded align-items-center">
        <textarea
          ref={url}
          className="mb-10 w-75 text-truncate bg-light"
          value={postUrl}
          readOnly
          style={{
            fontSize: '1.25rem',
            fontWeight: 500,
            resize: 'none',
            height: '1.25rem',
            lineHeight: '1.25rem',
            border: 'none',
            textAlign: 'center',
          }}
        >
          {postUrl}
        </textarea>
        <Button
          onClick={copyURL}
          variant="contained"
          color="secondary"
          className="text-white mr-10"
        >
          {intl.formatMessage({ id: 'button.copy' })}
        </Button>
      </div>
    </>
  );
};

export default BountyShare;

import { AnyAction } from '@reduxjs/toolkit';
import { Actions } from 'Store/orgCreation/actions';

interface State {
  companyInfo: any;
  isLoading: boolean;
  additionalInfo: any;
}

const initialState: State = {
  companyInfo: {},
  additionalInfo: {},
  isLoading: false,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.IsLoading:
      return {
        ...state,
        isLoading: payload,
      };
    case Actions.SetOrgInfo:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          ...payload,
        },
      };
    case Actions.SetAdditionalInfo:
      return {
        ...state,
        additionalInfo: payload,
      };
    case Actions.Reset:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

import { RootState } from 'Types/redux.types';
import { DictionaryItem } from 'Types/dictionaryItem.type';

export const languagesSelector = (state: RootState): DictionaryItem<string>[] =>
  state.languagesReducer.languages;

export const languagesIsLoadedSelector = (state: RootState): boolean =>
  state.skillsAndIndustriesReducer.isLoaded;

export const languagesIsLoadingSelector = (state: RootState): boolean =>
  state.skillsAndIndustriesReducer.isLoading;

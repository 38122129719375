import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Rating, SelectChangeEvent } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { ChangeEvent, SyntheticEvent, FC } from 'react';

import CustomModalButtons from 'Components/CustomModalButtons';
import UserAvatar from 'Components/UserAvatar';
import CustomInput from 'Components/CustomInput';

import { endorseUser } from 'Store/userProfile/actions';
import { updateSocialActionState } from 'Services/UserService';
import { closeModal } from 'Store/modal/actions';
import { PointCurrency, UserSocial } from 'Constants/enums';
import { userDataSelector } from 'Store/settings/selectors';

import { notificationToast } from 'Utils/notificationToaster';
import { identityMode } from 'Utils/identityMode';
import { ThunkDispatchType } from 'Types/redux.types';
import { User } from 'Types/user.interface';
import * as styles from './index.module.scss';

const DEFAULT_USER_RATING = 0;

interface EndorseUserProps {
  user: User;
}

interface FormikState {
  userRating: number;
  description: string;
}

const EndorseUser:FC<EndorseUserProps> = ({ user }) => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();

  const userData = useSelector(userDataSelector).data || {};
  const { id, name: userName } = user;

  const onCloseModal = () => dispatch(closeModal());

  const onEndorseUser = ({ userRating, description }: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    const { myself } = userData;

    if (userRating) {
      const endorsementData = {
        commentType: 'USER',
        creator: myself,
        entityId: id,
        reward: {
          points: {
            RATING: {
              amount: userRating || DEFAULT_USER_RATING,
              currency: PointCurrency.Rating,
            },
          },
        },
        identityMode: identityMode.REAL,
        sentAt: new Date().getTime(),
        text: description || '',
      };

      const userSocial = {
        actedAt: new Date().getTime(),
      };

      endorseUser(endorsementData);
      updateSocialActionState(id, UserSocial.Endorse, userSocial);
      onCloseModal();
      notificationToast.success(intl.formatMessage({ id: 'endorse_thanks_body' }, { userName }));
    } else {
      notificationToast.error(intl.formatMessage({ id: 'lbl_give_a_rating' }));
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      userRating: 0,
      description: '',
    },
    onSubmit: onEndorseUser,
  });

  const handleRatingChanges = (e: SyntheticEvent, newValue: number|null) => {
    formik.setFieldValue('userRating', newValue);
  };

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className={styles.root}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.user}>
          <UserAvatar url={user?.avatar} />
          <span>{user?.name}</span>
        </div>
        <Rating
          name="userRating"
          size="large"
          className={styles.rating}
          value={Math.round(formik?.values?.userRating)}
          onChange={handleRatingChanges}
        />
        <CustomInput
          name="description"
          placeholder={intl.formatMessage({ id: 'placeholder.sayNice' })}
          value={formik?.values?.description}
          onChange={handleChanges}
        />
        <input type="submit" hidden />
        <CustomModalButtons
          onDismiss={onCloseModal}
          onSubmit={formik.handleSubmit}
          disabled={formik.isSubmitting}
        />
      </form>
    </div>
  );
};

export default EndorseUser;

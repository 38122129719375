import { ACTIONS } from 'Store/badges/actions';

const INIT_STATE = {
  isCollectionLoading: false,
  isCollectionDetailsLoading: false,
  badgeCollections: [],
  badgeCollectionDetails: {},
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_LOADING_BADGE_COLLECTIONS:
      return {
        ...state,
        isCollectionLoading: payload,
      };

    case ACTIONS.SET_LOADING_BADGE_COLLECTION_DETAILS:
      return {
        ...state,
        isCollectionDetailsLoading: payload,
      };

    case ACTIONS.SET_BADGE_COLLECTIONS:
      return {
        ...state,
        badgeCollections: payload,
      };

    case ACTIONS.SET_BADGE_COLLECTION_DETAILS:
      return {
        ...state,
        badgeCollectionDetails: payload,
      };

    default:
      return state;
  }
};

export default reducer;

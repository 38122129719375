import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyCYnkyjE8vvPUzG92GSDOmweItgdS5fs-c',
    authDomain: 'fcdinamoge-prod.firebaseapp.com',
    databaseURL: 'https://fcdinamoge-prod.firebaseio.com',
    projectId: 'fcdinamoge-prod',
    storageBucket: 'fcdinamoge-prod.appspot.com',
    messagingSenderId: '151006572896',
    appId: '1:151006572896:web:b3c59b62edf993094fee05',
    measurementId: 'G-5TMZQH5TJS',
  },
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: '',
  algolia_api_key: '',
};

export default config;

import { getApiData } from 'Services/Api';
import { SKILLS_INDUSTRIES_API, SPECIAL_PROGRAMS_API } from 'Constants/apiRoutes';
import { SpecialProgramsResponse } from 'Types/specialProgramsResponse.type';
import { SkillsAndIndustriesResponse } from 'Types/skillsAndIndustriesResponse.type';

export const JobInformationService = {
  getSkillsAndIndustries(): Promise<SkillsAndIndustriesResponse> {
    return getApiData<SkillsAndIndustriesResponse>(SKILLS_INDUSTRIES_API);
  },

  getSpecialPrograms(): Promise<SpecialProgramsResponse> {
    return getApiData<SpecialProgramsResponse>(SPECIAL_PROGRAMS_API);
  },
};

export default JobInformationService;

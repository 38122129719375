import { getMyUserId } from 'Services/BaseService';
import { postApiData } from 'Services/Api';
import { firebaseGetCurrentUser, getUserShoppingCartsRef } from 'Services/FirebaseService';

import { getPriorityForProduct } from 'Utils/keyUtils';
import { ShoppingCart } from 'Types/cart.interface';
import { CardInfo } from 'Types/cardInfo.interface';
import { Address } from 'Types/address.interface';
import { ProductInfo } from 'Types/productInfo.interface';
import { Bounty } from 'Types/bounty.interface';
import { formatApiRoute } from 'Utils/api';
import { ORDER_SUMMARY_API, PLACE_ORDER_API } from 'Constants/apiRoutes';
import { OrderSummaryResult } from 'Types/orderSummaryResult.interface';
import { valueOfProductInfo } from 'Utils/shoppingCart';

export const getMyShoppingCartId = () => `cart_${getMyUserId()}`;

export const getShoppingCartRef = (shoppingCartId: string) => {
  const me = firebaseGetCurrentUser();
  return getUserShoppingCartsRef(me.uid).child(shoppingCartId);
};

export const getShoppingCartProductsRef = (shoppingCartId?: string) => {
  const cartId = shoppingCartId || getMyShoppingCartId();
  return getShoppingCartRef(cartId).child('products');
};

export const getShoppingCartProductsInfo = (shoppingCartId?: string) => getShoppingCartProductsRef(shoppingCartId).orderByPriority();

export const addToMyShoppingCart = (params: { productBounty: Bounty, sku: string | null }) => {
  const { productBounty, sku } = params;
  const productInfo = valueOfProductInfo({ productBounty, sku });
  addToShoppingCart(getMyShoppingCartId(), productInfo);
};

const addToShoppingCart = (shoppingCartId: string, productInfo: ProductInfo) => {
  const ref = getShoppingCartProductsRef(shoppingCartId).push();

  if (ref.key) {
    const productInfoClone = { ...productInfo, id: ref.key };
    ref.setWithPriority(productInfoClone, getPriorityForProduct(productInfoClone));
  }
};

export const removeFromShoppingCart = (params: { shoppingCartId?: string, productInfo: ProductInfo }) => {
  const { shoppingCartId = getMyShoppingCartId(), productInfo } = params;
  if (!productInfo) {
    return;
  }

  getShoppingCartProductsRef(shoppingCartId).child(productInfo.id).remove();
};

export const updateProductQuantity = (params : { newQuantity: number, shoppingCartId?: string, productInfo: ProductInfo }) => {
  const { shoppingCartId = getMyShoppingCartId(), productInfo, newQuantity } = params;

  if (!productInfo) {
    return;
  }

  if (newQuantity === 0) {
    return removeFromShoppingCart({ shoppingCartId, productInfo });
  }

  const changes = {
    updatedAt: new Date().getTime(),
    quantity: newQuantity,
  };

  getShoppingCartProductsRef(shoppingCartId).child(productInfo.id).update(changes);
};

export const saveShoppingCartDeliveryAddress = (deliveryAddress: Address) => {
  getShoppingCartRef(getMyShoppingCartId()).child('deliveryAddress').set(deliveryAddress);
};

export const saveShoppingCartPaymentInfo = (cardInfo: CardInfo) => {
  getShoppingCartRef(getMyShoppingCartId()).child('paymentInfo').set(cardInfo);
};

export const saveShoppingCartCoupon = (coupon: string) => {
  getShoppingCartRef(getMyShoppingCartId()).child('coupon').set(coupon);
};

export const saveShoppingCartRedeemTokens = (redeemTokens: string) => {
  getShoppingCartRef(getMyShoppingCartId()).child('redemptionAmount').set(redeemTokens);
};

export const saveShoppingCartNegotiatedAmount = (negotiatedAmount: string) => {
  getShoppingCartRef(getMyShoppingCartId()).child('negotiatedAmount').set(negotiatedAmount);
};

export const calculateOrderSummary = (cart: ShoppingCart): Promise<OrderSummaryResult> => {
  const cartId = getMyShoppingCartId();
  const route = formatApiRoute({
    endpoint: ORDER_SUMMARY_API,
    params: { cartId },
    queryParams: {},
  });

  return postApiData<OrderSummaryResult>(route, { ...cart, id: cartId });
};

export const placeOrder = (cart: ShoppingCart): Promise<{ orderId: string }> => {
  const cartId = getMyShoppingCartId();
  const route = formatApiRoute({
    endpoint: PLACE_ORDER_API,
    params: { cartId },
    queryParams: {},
  });

  return postApiData<{ orderId: string }>(route, { ...cart, id: cartId });
};

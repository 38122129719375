import { AnyAction } from '@reduxjs/toolkit';

import { ReservationsFilter } from 'Types/reservation.interface';
import { ACTIONS } from 'Store/reservations/actions';

interface State {
  filters: ReservationsFilter;
}

const INIT_STATE: State = {
  filters: {
    reservationCreatedFrom: '',
    reservationCreatedTo: '',
    reservationStatus: [],
    restaurantId: [],
    partySize: '',
    customPartySize: {
      min: '',
      max: '',
    },
  },
};

const reservationsReducer = (state = INIT_STATE, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_FILTERS:
      return {
        ...state,
        filters: payload,
      };

    default:
      return state;
  }
};

export default reservationsReducer;

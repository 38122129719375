import { createSelector } from '@reduxjs/toolkit';
import { UserVerification } from 'Constants/enums';

export const authUserDataSelector = (state) => state.authUser || {};
export const isInitializedSelector = (state) => state.authUser.isInitialized || false;
export const isLoadingSelector = (state) => state.authUser.user?.isLoading || false;
export const authUserUidSelector = (state) => authUserDataSelector(state)?.user?.data?.uid;
export const authLoggedUserAvatarSelector = (state) => authLoggedUserSelector(state)?.data?.photoURL;
export const authLoggedUserSelector = (state) => authUserDataSelector(state)?.user;
export const authRegistrationStepSelector = (state) => authUserDataSelector(state)?.signupStep;
export const authAccountInfoSelector = (state) => authUserDataSelector(state)?.accountInfo;
export const authUserPrivateInfoSelector = (state) =>
  authUserDataSelector(state).userPrivateInfo?.data;
export const authUserCompanyInfoSelector = (state) =>
  authUserPrivateInfoSelector(state)?.companyInfo;
export const authUserEnvModeSelector = (state) =>
  authUserPrivateInfoSelector(state)?.environmentMode;
export const authUserCompanyFeeSelector = (state) => authUserCompanyInfoSelector(state)?.fee;
export const authUserCompanyCurrencySelector = (state) => authUserCompanyInfoSelector(state)?.currency;
export const authUserPinSelector = (state) => authUserCompanyInfoSelector(state)?.pin;
export const authUserOrgTypeSelector = (state) => authUserCompanyInfoSelector(state)?.orgType;
export const authUserCompanyIdSelector = (state) => authUserCompanyInfoSelector(state)?.companyId;
export const isUserUnverifiedSelector = (state) => {
  const tags = state.authUser?.tags;
  const isTagsEmpty = !tags || Object.keys(tags).length === 0;
  return !isTagsEmpty && (!!tags[UserVerification.Moderated] || !!tags[UserVerification.Rejected]);
};

/**
 * Memorised Selectors
 */
export const authUserDataForMsgSelector = createSelector(
  authUserDataSelector,
  (user) => ({
    avatar: user.user?.data?.photoURL || '',
    name: user.user?.data?.displayName,
    uid: user.user?.data?.uid,
  }),
);

import { createSelector } from '@reduxjs/toolkit';

import { ListType, ListDef, getEffectiveOp, isEffectiveFilterByType, getVisibilityType, getShownInAsEnum, useDefaultsFrom } from 'Utils/models/Lists';
import { listTypeData } from 'Constants/streamListDefs';
import { BountyType, ShowInType, StreamListType, VisibilityModeType } from 'Constants/enums';
import { userDataSelector, settingsSelector } from 'Store/settings/selectors';
import { parseRolesList } from 'Utils/roleDef';
import { SEE_BUY_SELL, SEE_FAVORITES, SEE_MARKET } from 'Constants/op';
import * as OpManager from 'Utils/opManager';
import { getEffectiveCustomLists, isAllowed } from 'Utils/settings';
import * as ProductFlavour from 'Utils/productFlavour';
import * as ProductDefaults from 'Utils/productDefaults';

const productFlavour = ProductFlavour.getCurrent();
const { config: productDefaults } = ProductDefaults.getDefaults(productFlavour);

const ALLOWED_MULTI_LIST = [StreamListType.Market, StreamListType.Academia];

export const builtinList = {
  [StreamListType.Stream]: {
    id: StreamListType.Stream,
    code: null,
    kind: null,
    rank: 100,
    name: 'Stream',
    emptyListMsg: 'No bounties yet.',
    bountyTypes: null,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.EdgeInFocus]: {
    id: StreamListType.EdgeInFocus,
    code: listTypeData[StreamListType.EdgeInFocus].code,
    kind: null,
    rank: 110,
    name: 'In Focus',
    emptyListMsg: 'No In Focus posts yet.',
    bountyTypes: BountyType.TMOB_Coaching,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.EdgeChallenges]: {
    id: StreamListType.EdgeChallenges,
    code: listTypeData[StreamListType.EdgeChallenges].code,
    kind: null,
    rank: 120,
    name: 'Challenges',
    emptyListMsg: 'No challenges yet.',
    bountyTypes: BountyType.TMOB_Challenge,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.EdgeCoachings]: {
    id: StreamListType.EdgeCoachings,
    code: listTypeData[StreamListType.EdgeCoachings].code,
    kind: null,
    rank: 130,
    name: 'Coachings',
    emptyListMsg: 'No coaching posts yet.',
    bountyTypes: BountyType.TMOB_Coaching,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.Favorites]: { // we want it to always be the last
    id: StreamListType.Favorites,
    code: listTypeData[StreamListType.Favorites].code,
    kind: null,
    rank: 1001,
    name: 'Bookmarks',
    emptyListMsg: 'No bookmarks yet.',
    bountyTypes: null,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.BuySell]: {
    id: StreamListType.BuySell,
    code: listTypeData[StreamListType.BuySell].code,
    kind: null,
    rank: 140,
    name: 'Buy & Sell',
    emptyListMsg: 'The market is empty',
    bountyTypes: BountyType.Classified,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.Market]: {
    id: StreamListType.Market,
    code: listTypeData[StreamListType.Market].code,
    kind: null,
    rank: 150,
    name: 'Market',
    emptyListMsg: 'No goods on the market.',
    bountyTypes: null,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
  [StreamListType.Autosuggestions]: {
    id: StreamListType.Autosuggestions,
    code: listTypeData[StreamListType.Autosuggestions].code,
    kind: null,
    rank: 170,
    name: 'Auto-suggestions',
    emptyListMsg: 'No auto-suggestions created.',
    bountyTypes: null,
    userSpecial: listTypeData[StreamListType.Autosuggestions].userSpecial,
    companySpecial: listTypeData[StreamListType.Autosuggestions].companySpecial,
    op: null,
    url: null,
  },
  [StreamListType.WebView]: {
    id: StreamListType.WebView,
    code: listTypeData[StreamListType.WebView].code,
    kind: listTypeData[StreamListType.WebView].kind,
    rank: 190,
    name: null,
    emptyListMsg: null,
    bountyTypes: null,
    url: listTypeData[StreamListType.WebView].url,
    userSpecial: '',
    companySpecial: '',
    op: '',
  },
};

function getBuiltinListDef(type, rank) {
  const builtinListDef = builtinList[type] || null;

  if (builtinListDef) {
    return {
      ...builtinListDef,
      rank: rank !== null ? rank : builtinListDef.rank,
    };
  }

  return null;
}

/**
 * Get the default list defs
 * @param defaultList {Array}
 * @returns {Array}
 */
function getDefaultStreamListDefs(defaultList) {
  const list = [];

  defaultList.forEach((item) => {
    const builtinData = getBuiltinListDef(item.name, null);

    if (builtinData) {
      list.push(builtinData);
    }
  });

  return list;
}

/**
 * Sort a list of defs
 * The ones without a rank go to bottom, ordered by id (type of 'id' is {String})
 * @param list {Array}
 * @returns {Array}
 */
function sortDefsList(list) {
  list.sort((item1, item2) => {
    if (item1.rank === null && item2.rank === null) {
      return item1.id.localeCompare(item2.id);
    }

    if (item1.rank === null) {
      return 1;
    }

    if (item2.rank === null) {
      return -1;
    }

    if (item1.rank - item2.rank === 0) {
      return item1.id.localeCompare(item2.id);
    }

    return item1.rank - item2.rank;
  });

  return list;
}

/**
 * Check if a list of defs contain a specific list type
 * @param listDefs {Array}
 * @param type {String} list type
 * @returns {bool}
 */
function contains(listDefs, type) {
  return listDefs.find((item) => item?.id === type);
}

export const getStreamListDefsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getStreamListDefs(settings, userData.data),
);

export const getHomeListDefs = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getViewableListDefs({ settings, userData: userData.data, shownIn: ShowInType.Home }),
);

/**
 * Generate the list of defs depend on the company settings
 * (show in options list)
 * @param settings {Object}
 * @param userData {Object}
 * @returns {Array}
 */
export function getStreamListDefs(settings, userData) {
  const listDefs = [];
  const customLists = getEffectiveCustomLists(settings);
  let hasMarket = false;

  if (Object.keys(customLists).length) {
    Object.keys(customLists).forEach((key) => {
      if (getVisibilityType(customLists[key]?.visibilityInfo) === VisibilityModeType.User) {
        return;
      }

      const { name } = ListDef.getTypeAsEnum(customLists[key].id);

      if (name === StreamListType.None) {
        return;
      }

      const builtinData = getBuiltinListDef(name, customLists[key].rank);

      if (builtinData) {
        listDefs.push(useDefaultsFrom(customLists[key], builtinData));
      } else {
        listDefs.push(customLists[key]);
      }
    });
  } else {
    const defaultList = productDefaults.listTypes;
    listDefs.push(...getDefaultStreamListDefs(defaultList));
  }

  if (!listDefs.length) {
    listDefs.push(listTypeData[StreamListType.Stream]);
  }

  if (!contains(listDefs, StreamListType.Market) && isAllowed({ op: SEE_MARKET.name, settings, userData })) {
    listDefs.push(getBuiltinListDef(StreamListType.Market, 0));
    hasMarket = true;
  }

  if (!contains(listDefs, StreamListType.BuySell) && isAllowed({ op: SEE_BUY_SELL.name, settings, userData })) {
    listDefs.push(getBuiltinListDef(StreamListType.BuySell, hasMarket ? 1 : 0));
  }

  if (!contains(listDefs, StreamListType.BuySell)
    && OpManager.isBountyTypeAllowed({ bountyType: BountyType.Autosuggestion, settings, userData })) {
    listDefs.push(getBuiltinListDef(StreamListType.Autosuggestions, null));
  }

  if (!contains(listDefs, StreamListType.Favorites) && isAllowed({ op: SEE_FAVORITES.name, settings, userData })) {
    listDefs.push(getBuiltinListDef(StreamListType.Favorites, null));
  }

  // filter out the ones that are not allowed base on op

  sortDefsList(listDefs)
    .forEach((item, index) => {
      const op = getEffectiveOp(item.id, item.op);

      if (op && !isAllowed({ op: op.name, settings, userData })) {
        listDefs.splice(index, 1);
      }
    });

  return listDefs;
}

function isListPostable(item, userData) {
  if (!ListType.isPostable(item)) {
    return false;
  }

  if (item?.postabilityInfo?.postabilityRoles) {
    return OpManager.isAllowedByRole(
      parseRolesList(item.postabilityInfo.postabilityRoles),
      parseRolesList(userData?.roles),
      true,
    );
  }

  return true;
}

/**
 * Get the list of defs depend on the user configuration and bounty type
 * @param streamList {Object}
 * @param userData {Object}
 * @param bountyType {String}
 * @param includeFilters {boolean|null}
 * @returns {Array}
 */
export function getPostableListDefs(streamList, userData, bountyType = null, includeFilters = false) {
  const list = [];

  streamList.forEach((item) => {
    if (item.id !== 'APPROVALS' // TODO: check what is missing from the below function
      && isListPostable(item, userData)
      && (bountyType === null || ListDef.isApplicable(item, bountyType))
      && (includeFilters || !isEffectiveFilterByType(item))) {
      list.push(item);
    }
  });

  return list;
}

export function hasMultipleLists(bountyType, settings, userData) {
  const streamLists = getStreamListDefs(settings, userData);
  return getPostableListDefs(streamLists, userData, bountyType).length > 1;
}

export function isViewable(list, userData, allowMulti) {
  if (!ListDef.isViewable(list)) {
    return false;
  }

  if (ListDef.isMulti(list)) {
    return allowMulti || ALLOWED_MULTI_LIST.includes(list.id);
  }

  if (list?.visibilityInfo) {
    return OpManager.isAllowedByRole(
      parseRolesList(list.visibilityInfo?.visibilityRoles),
      parseRolesList(userData?.roles),
      true,
    );
  }

  return true;
}

export function getViewableListDefs({ settings, userData, shownIn }) {
  const listDefs = getStreamListDefs(settings, userData);

  const filteredList = listDefs.filter((list) => (
    !(!isViewable(list, userData, false) || (shownIn && shownIn !== getShownInAsEnum(list.shownIn)))
  ));

  if (shownIn === 'HOME' && !filteredList.length) {
    return listTypeData[StreamListType.Stream];
  }

  return filteredList;
}

export function getListDefsVisibleByRole({ settings, userData, allowMulti }) {
  const listDefs = getStreamListDefs(settings, userData);

  return listDefs.filter((list) => {
    if (!ListDef.isViewable(list)) {
      return false;
    }

    if (ListDef.isMulti(list) && !allowMulti) {
      return false;
    }

    if (list?.visibilityInfo) {
      return OpManager.isAllowedByRole(
        parseRolesList(list.visibilityInfo?.visibilityRoles),
        parseRolesList(userData?.roles),
        true,
      );
    }

    return true;
  });
}

export function getHomeSublists({ list, streamLists }) {
  if (list?.sublists) {
    const sublist = Object
      .keys(list.sublists)
      .reduce((acc, key) => {
        const listDef = streamLists.find((item) => item.id === key);

        if (listDef) {
          acc[key] = { ...list.sublists[key], ...(listDef || {}) };
        }

        return acc;
      }, {});

    return Object.values(sublist).sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }

      return b.order > a.order ? -1 : 0;
    });
  }

  return [];
}

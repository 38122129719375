import { IntlShape } from 'react-intl';
import langTexts, { LOCALES } from 'Translations/langTexts';
import { merge } from 'lodash';

import languagesObj, { enLang } from 'Translations/entries';
import { SpecialProgram } from '../constants/enums';
import { AppConfig } from './appConfig';

const splitLanguage = (countryCode: string) => countryCode.slice(0, 2).toLowerCase();
const checkLanguage = (countryCode: string) => languagesObj[`${countryCode}Lang`] || enLang;

/**
 * This function deep merges the default language with the language of the country
 * @param language
 * @returns {object}
 */
const mergeWithEngLang = (language: string) => merge(
  {},
  enLang,
  checkLanguage(splitLanguage(language)),
);

/**
 * This function is wrapper for the React Flag Kit library
 * @param {string} countryCode - country code
 * @return {string} - flag of the country code for the flag kit library
 */
const generateImageByCountryCode = (countryCode: string) => {
  const language = splitLanguage(countryCode);
  switch (language) {
    case 'en':
      return 'GB';
    case 'hy':
      return 'AM';
    case 'uk':
      return 'UA';
    case 'kk':
      return 'KZ';
    case 'ka':
      return 'GE';
    default:
      return language.toUpperCase();
  }
};

/**
 * This function gets default language from the browser
 * and English as default fallback
 * @return {string} - language of the browser
 */
const getDefaultLanguage = () => {
  const supportedLanguages = AppConfig?.custom?.supportedLanguages;
  const locales = supportedLanguages || Object.values(LOCALES);
  const defaultLocale = locales.find(
    (locale) => (
      locale.toLowerCase() === navigator?.language?.toLowerCase())
      || (locale.split('-')[0] === navigator?.language?.toLowerCase()
      ),
  );

  return defaultLocale || LOCALES.ENGLISH;
};

/**
 * This function checks if language returned
 * from the server is supported by the app
 * @param local
 * @returns {boolean}
 */
const isValidLanguage = (local = '') => {
  const allowedFullLocales = Object.values(LOCALES);
  const allowedShortLocales = allowedFullLocales.map((locale) => locale.split('-')[0]);
  const allowedLocales = [...allowedShortLocales, ...allowedFullLocales];
  return allowedLocales.includes(local);
};

export {
  splitLanguage,
  generateImageByCountryCode,
  getDefaultLanguage,
  checkLanguage,
  mergeWithEngLang,
  isValidLanguage,
};

export const getTranslatedCountryName = (code: string, intl: IntlShape) => intl.formatMessage({ id: `country-${code}` });

export const getTranslatedLangName = (code: string, intl: IntlShape) => intl.formatMessage({ id: `lang-${code.toUpperCase()}` });

export const setLocaleToLocalStorage = (locale: string) => localStorage.setItem('locale', locale);

export const getCurrentLocale = (locale: string | null) => locale || localStorage.getItem('locale') || navigator?.language;

export const getSpecialProgramNameTranslationId = (id: SpecialProgram) => `label.specialProgram.${id}`;

export const getSpecialProgramHintTranslationId = (id: SpecialProgram) => `hint.specialProgram.${id}`;

export const getFlavourLanguages = () => {
  const supportedLanguages = AppConfig?.custom?.supportedLanguages;

  const languages = supportedLanguages
    ? langTexts.filter((langText) => supportedLanguages?.find((languageKey) => langText.key === languageKey))
    : langTexts;

  return languages.map((v) => ({ ...v, shortKey: v.key.substr(0, 2) }));
};

import { ACTIONS } from 'Store/createBounty/actions';

const INIT_STATE = {
  isLoading: false,
  isOpen: false,
  configuration: [],
  selectedBounty: {},
  parentBounty: {},
  selectedPromotions: [],
  selectedProducts: [],
  contentType: '',
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_IS_OPEN:
      return {
        ...state,
        isOpen: payload,
      };

    case ACTIONS.SET_CONFIGURATION:
      return {
        ...state,
        configuration: payload,
      };

    case ACTIONS.SET_SELECTED_BOUNTY:
      return {
        ...state,
        selectedBounty: payload,
      };

    case ACTIONS.SET_PARENT_BOUNTY:
      return {
        ...state,
        parentBounty: payload,
      };

    case ACTIONS.SET_SELECTED_PROMOTIONS:
      return {
        ...state,
        selectedPromotions: payload,
      };

    case ACTIONS.SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: payload,
      };

    case ACTIONS.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: payload,
      };

    case ACTIONS.RESET_REDUCER:
      return INIT_STATE;

    default:
      return state;
  }
};

export default reducer;

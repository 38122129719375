import cs from 'classnames';
import { firebaseGetUserChatRef } from 'Services/FirebaseService';
import { UserChat } from 'Types/chatUser.interface';
import { ChatRoomType, ChatState } from 'Constants/enums';
import { hasUnreadMessages } from 'Utils/chat';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as userSelectors from 'Store/auth/selectors';
import { isAfter } from 'date-fns';
import { EmployerContext } from 'Contexts/employerContext';
import { notificationToast } from 'Utils/notificationToaster';
import { isNil } from 'lodash';
import * as styles from './index.module.scss';

interface MessagesIndicatorProps {
  icon: string;
  name: string;
}
const MessagesIndicator:FC<MessagesIndicatorProps> = ({ icon, name }) => {
  const [showBadge, setShowBadge] = useState(false);
  const userUid = useSelector(userSelectors.authUserUidSelector);
  const isUserUnverified = useSelector(userSelectors.isUserUnverifiedSelector);
  const { employerData } = useContext(EmployerContext);
  const [initialDate, setInitialDate] = useState<number | null>(null);

  useEffect(() => {
    firebaseGetUserChatRef(userUid).once('value', (data) => {
      handleChatUsers(data);
      setInitialDate(Date.now());
    });

    return () => {
      firebaseGetUserChatRef(userUid).off('value');
    };
  }, []);

  useEffect(() => {
    if (!isNil(initialDate)) {
      firebaseGetUserChatRef(userUid).on('value', handleChatUsers);
    }
  }, [initialDate]);

  const filterByUserVerifiedState = (chat: UserChat) => !isUserUnverified || chat.roomType === ChatRoomType.Support;

  const handleChatUsers = (snapshot: any) => {
    const data = snapshot.val() || {};

    // ToDo: this a hack to fix unnecessary badge on messages icon call when user is unverified
    if (employerData?.companyName) {
      setShowBadge(false);
    }
    try {
      const chats = Object.values(data) as UserChat[];
      const validChats = chats
        .filter((c) => !!c.roomId && c.roomState !== ChatState.Archived)
        .filter(filterByUserVerifiedState);

      const unReadChat = validChats.find((chat: UserChat) => hasUnreadMessages(chat));
      const hasAtLeastOneUnreadMessage = !!unReadChat;
      setShowBadge(hasAtLeastOneUnreadMessage);

      if (hasAtLeastOneUnreadMessage && !isNil(initialDate) && unReadChat?.notifiedRecvMsgAt && isAfter(unReadChat?.notifiedRecvMsgAt, initialDate)) {
        notificationToast.info('info.newMessage');
      }
    } catch (e) {
      setShowBadge(false);
      console.error(e);
    }
  };

  return (
    <>
      <span className="material-symbols-rounded">
        <span
          className={`${cs(styles.message, {
            [styles.messageBadge]: showBadge,
          })}`}
        >
          <span className={icon} />
        </span>
      </span>

      <span className="menu">
        <span className="font-15">
          {name}
        </span>
      </span>
    </>
  );
};

export default MessagesIndicator;

import { useIntl } from 'react-intl';
import { ReactNode } from "react";

import * as styles from './index.module.scss';

const HowItWorks = () => {
  const intl = useIntl();

  const renderBoldChunks = (chunks: ReactNode[]) => <b>{chunks}</b>;

  return (
    <div className={styles.root}>
      <h3 className="mb-0">{intl.formatMessage({ id: 'label.howItWorks' })}</h3>
      <p>{intl.formatMessage({ id: 'label.whenSetCustomShippingFee' })}:</p>
      <ol className="ml-20 mb-10">
        <li>
          <p>
            {intl.formatMessage({ id: 'label.customShippingFee.defaultShippingFee' }, { b: renderBoldChunks })}
          </p>
        </li>
        <li>
          <p>
            {intl.formatMessage({ id: 'label.customShippingFee.customShippingFee' }, { b: renderBoldChunks })}
          </p>
        </li>
        <li>
          <p>
            {intl.formatMessage({ id: 'label.customShippingFee.freeShipping' }, { b: renderBoldChunks })}
          </p>
        </li>
      </ol>

      <h3 className="mb-0">{intl.formatMessage({ id: 'label.exampleCalculation' })}:</h3>
      <ol className="ml-20 mb-0">
        <li>
          <p className="font-weight-bold">
            {intl.formatMessage({ id: 'label.vendorSettings' })}:
          </p>
          <ul className="ml-20">
            <li>
              <p>
                {intl.formatMessage({ id: 'label.vendorSettings.defaultDomesticFee' }, { b: renderBoldChunks })}
              </p>
            </li>
          </ul>
        </li>

        <li className="mt-10">
          <p className="font-weight-bold">
            {intl.formatMessage({ id: 'label.productLevelSettings' })}:
          </p>
          <ul className="ml-20">
            <li>
              <p>
                {intl.formatMessage({ id: 'label.customShippingFee.productLevelSettings.itemB' }, { b: renderBoldChunks })}
              </p>
            </li>
          </ul>
        </li>

        <li className="mt-10">
          <p className="font-weight-bold">
            {intl.formatMessage({ id: 'label.cartContents' })}:
          </p>
          <ul className="ml-20">
            <li>
              <p className="font-weight-bold">
                {intl.formatMessage({ id: 'label.customShippingFee.cartContents.itemB' })}:
              </p>
              <ul className="ml-20">
                <li>
                  <p>
                    {intl.formatMessage({ id: 'label.customShippingFee.cartContents.itemB.cost' }, { b: renderBoldChunks })}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li className="mt-10">
          <p className="font-weight-bold">
            {intl.formatMessage({ id: 'label.totalShippingCost' })}:
          </p>
          <ul className="ml-20">
            <li>
              <p>
                {intl.formatMessage({ id: 'label.totalShippingCost.defaultShippingFee' }, { b: renderBoldChunks })}
              </p>
            </li>
            <li>
              <p>
                {intl.formatMessage({ id: 'label.customShippingFee.totalShippingCost.itemB' }, { b: renderBoldChunks })}
              </p>
            </li>
            <li>
              <p>
                {intl.formatMessage({ id: 'label.customShippingFee.totalShippingCost.total' }, { b: renderBoldChunks })}
              </p>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default HowItWorks;

import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

export function getQuantityString(singularId: string, pluralId: string, value: number) {
  if (value > 1) {
    return <FormattedMessage id={pluralId} values={{ value }} />;
  }

  return <FormattedMessage id={singularId} />;
}

export function prepareFilters(filters: { [key: string]: string }) {
  return Object.keys(filters)
    .map((key) => {
      if (filters[key]) {
        return `${key}=${filters[key]}`;
      }
      return null;
    })
    .filter((item) => item)
    .join('&');
}

export function formatDiscount(discount: number) {
  return discount ? discount * 100 : 0;
}

export function sortAlphabetically({ data, sortBy }: { data: unknown[], sortBy: string }) {
  return data.sort((a, b) => {
    if (get(a, sortBy).toLowerCase() < get(b, sortBy).toLowerCase()) {
      return -1;
    }

    if (get(a, sortBy).toLowerCase() > get(b, sortBy).toLowerCase()) {
      return 1;
    }

    return 0;
  });
}

/**
 * This function remove empty request properties from the object,
 * including empty arrays, empty strings, null and undefined values.
 */
export const remoteEmptyProps = (obj: {[key: string]: unknown}) => {
  if (!obj) return {};

  const newObj = { ...obj };
  Object.entries(newObj)
    .filter((item) => {
      const val = item[1];
      return (typeof val !== 'number' && !val) // if empty string/null/undefined
        || (Array.isArray(val) && val.length === 0); // if empty array
    })
    .forEach((item) => delete newObj[item[0]]);
  return newObj;
};

/**
 * This function generate a query string in proper format,
 * ignoring 'order' prop (as this should be formatted as FIELD$ASC / FIELD$DESC).
 * This done to prevent '$' conversion with URLSearchParams function.
 */
export const getQueryString = (obj?: Record<string, any>) => {
  if (!obj) return '';

  const { order, ...rest } = remoteEmptyProps(obj);

  let qs = queryString.stringify(rest);

  if (order) {
    qs += `&order=${order}`;
  }

  return qs ? `?${qs}` : '';
};

import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { useIntl } from 'react-intl';
import cs from 'classnames';

import * as notifications from 'Assets/scss/modules/notifications.module.scss';
import * as styles from './index.module.scss';

const NotificationToaster = () => {
  const intl = useIntl();

  const getDefaultTitle = (type) => {
    switch (type) {
      case 'successType':
        return intl.formatMessage({ id: 'notification.success.confirmationModal.title' });
      case 'warningType':
        return intl.formatMessage({ id: 'notification.warning.confirmationModal.title' });
      case 'errorType':
        return intl.formatMessage({ id: 'notification.error.confirmationModal.title' });
      default:
        return intl.formatMessage({ id: 'notification.info.confirmationModal.title' });
    }
  };

  return (
    <Toaster
      reverseOrder={false}
      position="top-right"
      toastOptions={{
        duration: 6000,
        className: 'notificationRootOverride',
        style: { padding: 0, width: '100%', maxWidth: '400px' },
      }}
    >
      {(toastProps) => {
        const { id, title, message, className, hideTitle = false, hideCloseButton = false } = toastProps;
        const defaultTitle = getDefaultTitle(className);
        const messageToShow = typeof message === 'string' ? intl.formatMessage({ id: message }) : message;

        return (
          <ToastBar toast={toastProps}>
            {() => (
              <div
                role="presentation"
                onClick={() => !hideCloseButton && toast.dismiss(id)}
                className={cs(
                  styles.notificationBorderRadius,
                  notifications.notificationContainer,
                  { [notifications[className]]: className },
                  { 'cursor-pointer': !hideCloseButton },
                )}
              >
                <span className={cs(notifications.statusIcon, { [notifications[className]]: className })} />
                {!hideCloseButton && (
                  <button
                    type="button"
                    aria-label="close"
                    onClick={() => toast.dismiss(id)}
                    className={`icon-add ${notifications.closeButton}`}
                  />
                )}
                {!hideTitle && (
                  <h2
                    id="notification-title"
                    className={notifications.notificationTitle}
                  >
                    {title || defaultTitle}
                  </h2>
                )}
                {!!message && (
                  <div
                    id="notification-message"
                    className={notifications.notificationMessage}
                  >
                    {messageToShow}
                  </div>
                )}
              </div>
            )}
          </ToastBar>
        );
      }}
    </Toaster>
  );
};

export default NotificationToaster;

import { cloneDeep } from 'lodash';
import { ACTIONS } from './actions';

// initial state
const INIT_STATE = {
  cart: {
    status: 'ACTIVE',
    products: null,
    deliveryAddress: null,
    paymentInfo: null,
    coupon: null,
    negotiatedAmount: null,
    redemptionAmount: null,
  },
  totalProductsQuantity: 0,
  hasCouponAppliedSuccessfully: false,
};

function setDeliveryAddress(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.deliveryAddress = payload;

  return newState;
}

function setPaymentMethod(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.paymentInfo = payload;

  return newState;
}

function setCoupon(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.coupon = payload;
  newState.hasCouponAppliedSuccessfully = false;

  return newState;
}

function setHasCouponAppliedSuccessfully(state, payload) {
  const newState = cloneDeep(state);
  newState.hasCouponAppliedSuccessfully = payload;

  return newState;
}

function setRedeemTokens(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.redemptionAmount = payload;

  return newState;
}

function setNegotiatedAmount(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.negotiatedAmount = payload;

  return newState;
}

function setProducts(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.products = payload;

  return newState;
}

function increaseTotalProductsQuantity(state) {
  const newState = cloneDeep(state);
  newState.totalProductsQuantity += 1;

  return newState;
}

function decreaseTotalProductsQuantity(state, payload = 1) {
  const newState = cloneDeep(state);
  newState.totalProductsQuantity -= payload;

  return newState;
}

function setTotalProductsQuantity(state, payload) {
  const newState = cloneDeep(state);
  newState.totalProductsQuantity = payload;

  return newState;
}

function clearCart() {
  return INIT_STATE;
}

const handlersReducer = {
  [ACTIONS.SET_DELIVERY_ADDRESS]: setDeliveryAddress,
  [ACTIONS.SET_PAYMENT_METHOD]: setPaymentMethod,
  [ACTIONS.SET_COUPON]: setCoupon,
  [ACTIONS.SET_HAS_COUPON_APPLIED]: setHasCouponAppliedSuccessfully,
  [ACTIONS.SET_NEGOTIATED_AMOUNT]: setNegotiatedAmount,
  [ACTIONS.SET_CART_PRODUCTS]: setProducts,
  [ACTIONS.SET_REDEEM_TOKENS]: setRedeemTokens,
  [ACTIONS.CLEAR_CART]: clearCart,
  [ACTIONS.INCREASE_TOTAL_PRODUCTS_QUANTITY]: increaseTotalProductsQuantity,
  [ACTIONS.DECREASE_TOTAL_PRODUCTS_QUANTITY]: decreaseTotalProductsQuantity,
  [ACTIONS.SET_TOTAL_PRODUCTS_QUANTITY]: setTotalProductsQuantity,
};

export default function reducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}

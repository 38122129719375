import {
  createContext,
  useMemo,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction, ReactElement,
} from 'react';

interface EmployerContextType {
    employerData: null;
    employerResult: null;
    setEmployerData: Dispatch<SetStateAction<null>>;
    setEmployerResult: Dispatch<SetStateAction<null>>;
}

export const EmployerContext = createContext<EmployerContextType>({
  employerData: null,
  employerResult: null,
  setEmployerData: () => {},
  setEmployerResult: () => {},
});

interface EmployerProviderProps {
    children: ReactNode;
}

const EmployerProvider = ({ children }: EmployerProviderProps): ReactElement => {
  const [employerData, setEmployerData] = useState(null);
  const [employerResult, setEmployerResult] = useState(null);

  const contextValue = useMemo(
    () => ({
      employerData,
      employerResult,
      setEmployerData,
      setEmployerResult,
    }),
    [employerData, employerResult],
  );

  return (
    <EmployerContext.Provider value={contextValue}>
      {children}
    </EmployerContext.Provider>
  );
};

export default EmployerProvider;

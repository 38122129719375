import { FC, useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";

import LoaderOverlay from "Components/LoaderOverlay";
import ShippingFeesForm from "Components/ShippingFeesForm";
import CustomModalButtons from "Components/CustomModalButtons";
import HowItWorks from "Containers/ProductCreate/HowItWorks";
import CustomShippingFeesForm from "Components/CustomShippingFeesForm";

import { MerchantService } from "Services/MerchantService";

import { DeliveryFeeSource } from "Constants/enums";
import { ShippingFee } from "Types/shippingFees.interface";
import { authUserUidSelector } from "Store/auth/selectors";
import { notificationToast } from "Utils/notificationToaster";
import { DEFAULT_DELIVERY_FEE } from "Constants/fees";
import { shippingFeesSchema } from "Utils/validation/shippingFeesSchema";
import { formatShippingFeeForServer } from "Utils/shippingFee";
import { getInitialDeliveryFee } from "../utils";
import * as styles from "./index.module.scss";

interface ShippingFeesDrawerProps {
  isOpen: boolean;
  onClose: ({ shouldReset }: { shouldReset: boolean }) => void;
  deliveryFeeSource: DeliveryFeeSource;
  extraDeliveryFee?: ShippingFee | null;
  onSave: (shippingFee: ShippingFee) => void;
  onRedirect: () => void;
}

const ShippingFeesDrawer: FC<ShippingFeesDrawerProps> = ({
  isOpen,
  onClose,
  deliveryFeeSource,
  extraDeliveryFee,
  onSave,
  onRedirect,
}) => {
  const intl = useIntl();
  const loggedUserId = useSelector(authUserUidSelector) || '';
  const [merchantShippingFee, setMerchantShippingFee] = useState<ShippingFee>(DEFAULT_DELIVERY_FEE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const areMerchantFees = deliveryFeeSource === DeliveryFeeSource.Merchant;

  useEffect(() => {
    if (loggedUserId) {
      fetchShippingFee();
    }
  }, [loggedUserId]);

  const fetchShippingFee = async () => {
    setIsLoading(true);

    try {
      const response = await MerchantService.getShippingFee(loggedUserId);
      setMerchantShippingFee(response || null);
    } catch (error: any) {
      notificationToast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: ShippingFee) => {
    onSave(formatShippingFeeForServer(values));
    onClose({ shouldReset: false });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: areMerchantFees
      ? merchantShippingFee
      : getInitialDeliveryFee(merchantShippingFee, extraDeliveryFee),
    validationSchema: shippingFeesSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    onClose({ shouldReset: isEqual(extraDeliveryFee, DEFAULT_DELIVERY_FEE ) });
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ className: styles.drawer }}
    >
      <h3 className="mb-30">
        {intl.formatMessage({ id: areMerchantFees ? 'label.defaultShippingFees' : 'label.customShippingFees' })}
      </h3>

      {areMerchantFees
        ? (
          <div>
            <ShippingFeesForm formik={formik} isReadOnly />
            <CustomModalButtons
              onDismiss={handleClose}
              onSubmit={onRedirect}
              submitName="button.editDefaultFees"
              dismissName="button.close"
            />
          </div>
        ) : (
          <div>
            <CustomShippingFeesForm
              formik={formik}
              merchantShippingFee={!isEqual(merchantShippingFee, DEFAULT_DELIVERY_FEE) ? merchantShippingFee : null}
            />
            <HowItWorks />
            <CustomModalButtons onDismiss={handleClose} onSubmit={formik.handleSubmit} />
          </div>
        )}

      {isLoading && <LoaderOverlay />}
    </Drawer>
  );
};

export default ShippingFeesDrawer;

import { format } from 'date-fns';
import { DATE_FORMAT } from 'Constants/common';
import { ACTIONS } from 'Store/reports/actions';

const INIT_STATE = {
  isLoading: false,
  report: [],
  filters: {
    fromDate: format(new Date(), DATE_FORMAT),
    toDate: format(new Date(), DATE_FORMAT),
    userFilter: '',
    selectedReportType: '',
    selectedScopedReportType: '',
  },
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.UPDATE_FILTERS:
      return {
        ...state,
        filters: payload,
      };

    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_REPORT:
      return {
        ...state,
        report: payload,
      };

    case ACTIONS.RESET_REDUCER:
      return INIT_STATE;

    default:
      return state;
  }
};

export default reducer;

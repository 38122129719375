import RankingService from 'Services/RankingService';

import { AppDispatch } from 'Types/redux.types';

export const ACTIONS = {
  SET_IS_LOADING: 'leaderboardReducer/setIsLoading',
  SET_RANKINGS: 'leaderboardReducer/setRankings',
};

export const getRankings = (period: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  try {
    const response = await RankingService.fetchRanking(period);
    dispatch({ type: ACTIONS.SET_RANKINGS, payload: response?.rankings || [] });
  } catch {
    dispatch({ type: ACTIONS.SET_RANKINGS, payload: [] });
  } finally {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

import { FC, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import emptyImage from 'Assets/img/emptyContent/es_incoming.png';
import Image from 'Components/Image';

import * as styles from 'Containers/BadgeCollectionCreate/BadgeDetailsCard/index.module.scss';
import { CreateBadge } from 'Types/badge.interface';

export interface BadgeDetailsCardProps {
  badge: CreateBadge;
  onEdit?: (badge: CreateBadge) => void;
  onDelete?: (badge: CreateBadge) => void;
}

const BadgeDetailsCard: FC<BadgeDetailsCardProps> = ({
  badge,
  onEdit,
  onDelete,
}) => {
  const { reward, label, description } = badge;
  const badgeIcon = useMemo(() => {
    const icon = badge?.badgeIcon?.[0];
    return icon?.localUri || badge.icon;
  }, [badge]);

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <Image
          className="image-post-v2"
          alt="cropped_img"
          src={badgeIcon || emptyImage}
        />
      </div>
      <div className={styles.collectionInfo}>
        <Box mb={1} display="flex" alignItems="center" justifyContent="space-between" gap={0.5}>
          <h3 className={styles.badgeLabel}>{label}</h3>
          <Box mb={1} display="flex" alignItems="center" gap={0.5}>
            <IconButton
              className={`${styles.actionButton} ${styles.deleteButton}`}
              onClick={() => onDelete && onDelete(badge)}
            >
              <i className="icon-delete" />
            </IconButton>
            <IconButton
              className={`${styles.actionButton} ${styles.editButton}`}
              onClick={() => onEdit && onEdit(badge)}
            >
              <i className="icon-edit" />
            </IconButton>
          </Box>
        </Box>
        <div>{description}</div>
        <div>
          {reward?.amount
            ? <FormattedMessage id="badge.points_attach" values={{ points: reward?.amount }} />
            : <i><FormattedMessage id="label.noPointsAttached" /></i>}
        </div>
      </div>
    </div>
  );
};

export default BadgeDetailsCard;

import { AppDispatch } from 'Types/redux.types';
import { ApplicantsRequest } from 'Types/applicantsRequest.type';
import { ApplicantsFilters } from 'Types/applicantsFilters.type';

export enum Actions {
  UpdateFilters = 'applicantsFilterReducer/UpdateFilters',
  UpdateReqProps = 'applicantsFilterReducer/UpdateReqProps',
  ResetFilters = 'applicantsFilterReducer/ResetFilters',
  ResetAll = 'applicantsFilterReducer/ResetAll',
}

const updateApplicantsReqProps = (reqProps: Partial<ApplicantsRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.UpdateReqProps, payload: reqProps });

export const updateApplicantsOffset = (offset: number) => updateApplicantsReqProps({ start: offset });

export const updateApplicantsLimit = (limit: number) => updateApplicantsReqProps({ limit });

export const updateApplicantsOrder = (order: string) => updateApplicantsReqProps({ order, start: 0 });

export const updateApplicantsQuery = (query: string) => updateApplicantsReqProps({ query, start: 0 });

export const updateApplicantsFilters = (filters: Partial<ApplicantsFilters>) => (dispatch: AppDispatch) =>
  dispatch({
    type: Actions.UpdateFilters,
    payload: { filters, reqProps: { start: 0 } },
  });

export const resetApplicantsFilters = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.ResetFilters });

export const resetApplicantsFiltersAndReqProps = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.ResetAll });

import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { ThunkDispatchType } from 'Types/redux.types';
import { formatApiRoute, getUserAgent } from 'Utils/api';
import { CLIENTS, SYSTEM_INFO_BY_COUNTRY_ROUTE } from 'Constants/apiRoutes';
import { getApiData, postApiData } from 'Services/Api';
import { auth } from 'Utils/firebase';
import { getApplicationConfig } from 'Utils/appConfig';
import { getQueueName, getQueueParam } from 'Utils/queues';
import { QueueAlias } from 'Constants/enums';
import { firebaseGetTimestamp, getMyUserAddressesRef } from 'Services/FirebaseService';
import { QUEUES_BASE, QUEUES_INDEX } from 'Constants/queues';

export enum Actions {
  SetOrgInfo = 'orgCreationReducer/SetOrgInfo',
  SetAdditionalInfo = 'orgCreationReducer/SetAdditionalInfo',
  IsLoading = 'orgCreationReducer/IsLoading',
  Reset = 'orgCreationReducer/reset',
}

const setIsLoading = (isLoading: boolean) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: Actions.IsLoading, payload: isLoading });
};

export const setOrgInfo = (payload: any) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: Actions.SetOrgInfo, payload });
};

export const resetReducer = () => (dispatch: ThunkDispatchType) => {
  dispatch({ type: Actions.Reset });
};

export const getAdditionalOrgInfo = (countryCode: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const url = formatApiRoute({ endpoint: SYSTEM_INFO_BY_COUNTRY_ROUTE, params: { countryCode } });
    const info = await getApiData(url);
    dispatch({ type: Actions.SetAdditionalInfo, payload: info });
  } catch (e) {
    console.log('Get additional info error: ', e); // eslint-disable-line
  }
};

export const checkIfAccountExists = (email: string) => (dispatch: ThunkDispatchType) => (
  new Promise((resolve, reject) => {
    dispatch(setIsLoading(true));

    fetchSignInMethodsForEmail(auth, email)
      .then((data) => {
        dispatch(setIsLoading(false));
        return data.length
          ? reject(new Error('Email Already Exists'))
          : resolve(data);
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        reject(new Error(err.message));
      });
  })
);

const removeSecondaryApp = async () => (
  firebase
    .app('Secondary')
    .delete()
);

export const signupCompanyInFirebase = (companyInfo: any) => async (dispatch: ThunkDispatchType) => {
  try {
    const cloneCompanyInfo = { ...companyInfo };
    dispatch(setIsLoading(true));

    const { firebaseConfig } = getApplicationConfig();
    const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
    const { email, password, name } = cloneCompanyInfo;

    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    delete cloneCompanyInfo.password;
    delete cloneCompanyInfo.confirmPassword;
    const userId = user.uid;
    const updatedCompanyInfo = { ...cloneCompanyInfo, ownerId: userId };

    dispatch(setOrgInfo(updatedCompanyInfo));

    await firebase
      .app('Secondary')
      .database()
      .ref(QUEUES_BASE)
      .child(getQueueParam(QueueAlias.UpdateUser))
      .child(QUEUES_INDEX)
      .push()
      .set({
        meta: {
          userId,
          action: getQueueName(QueueAlias.UpdateUser),
          clientId: null,
          createdAt: new Date().getTime(),
          addedAt: firebaseGetTimestamp(),
          agent: getUserAgent(),
          sync: null,
        },
        userId,
        email,
        name,
      });

    const { currentUser } = secondaryApp.auth();
    currentUser?.updateProfile({ displayName: name });
    await removeSecondaryApp();

    return updatedCompanyInfo;
  } catch (error: any) {
    await removeSecondaryApp();
    throw error;
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const createCompanyPinWithAPI = (companyInfo: any) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(setIsLoading(true));
    const cloneCompanyInfo = { ...companyInfo };
    const { ownerId } = cloneCompanyInfo;

    if (ownerId) {
      const addressId = getMyUserAddressesRef(ownerId).push().key;
      cloneCompanyInfo.taxAddress = {
        id: addressId,
        type: 'taxAddress',
        line1: cloneCompanyInfo.line1,
        line2: cloneCompanyInfo.line2,
        city: cloneCompanyInfo.city,
        state: cloneCompanyInfo.state,
        postalCode: cloneCompanyInfo.postalCode,
        countryCode: cloneCompanyInfo.countryCode,
        phoneNumber: cloneCompanyInfo.phoneNumber,
        instructions: cloneCompanyInfo.instructions,
      };
    }

    if (cloneCompanyInfo.fee) {
      cloneCompanyInfo.fee /= 100;
    }

    const res: any = await postApiData(CLIENTS, cloneCompanyInfo);
    const { clientId, clientUrl } = res;

    if (res && res.status === 200) {
      dispatch(setOrgInfo({ ...cloneCompanyInfo, clientId, clientUrl }));
      dispatch(setIsLoading(false));
    } else {
      const error = { message: 'Unable to create company' };
      throw error;
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

/**
 * App Light Theme
 */
import { createTheme } from '@mui/material/styles';
import overrides from 'Utils/theme/overrides';
import palette from 'Utils/theme/palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    useNextVariants: true,
  },
  components: {
    ...overrides,
    MuiButton: {
      styleOverrides: {
        ...overrides.MuiButton.overrides,
        root: {
          textTransform: 'none',
        },
        containedSecondary: {
          color: '#fff',
          backgroundColor: palette.common.textMuted,
          '&:hover': {
            color: '#fff',
            backgroundColor: palette.common.textMuted,
          },
        },
      },
    },
  },
  palette,
});

export default theme;

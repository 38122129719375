import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getLanguages } from 'Store/languages/actions';
import { languagesIsLoadedSelector } from 'Store/languages/selectors';
import { skillsAndIndustriesIsLoadedSelector } from 'Store/skillsAndIndustries/selectors';
import { getSkillsAndIndustries } from 'Store/skillsAndIndustries/actions';
import { ThunkDispatchType } from '../types/redux.types';
import { firebaseGetCurrentUser } from '../services/FirebaseService';
import { getLocaleServer } from '../services/locale/LocaleService';
import { changeLocaleDate } from '../utils/translations';
import { setLocaleToLocalStorage } from '../utils/languagesHelpers';
import { LOCALES, MUI_LOCALES_HASH } from '../translations/langTexts';

interface LanguageContextProps {
  locale: string;
  setLocale: (locale: string) => void;
  currentCountryCode: string | null;
}

export const LanguageContext = createContext<LanguageContextProps>({
  locale: LOCALES.ENGLISH,
  setLocale: () => {},
  currentCountryCode: null,
});

interface LanguagesProviderProps {
  children: ReactNode;
}

const LanguagesProvider: FC<LanguagesProviderProps> = ({ children }) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const { locale, setLocale, currentCountryCode } = useContext(LanguageContext);
  const isSkillsAndIndustriesIsLoaded = useSelector(skillsAndIndustriesIsLoadedSelector);
  const languagesIsLoaded = useSelector(languagesIsLoadedSelector);
  const { uid, isAnonymous } = firebaseGetCurrentUser();

  useEffect(() => {
    if (uid && !isAnonymous) getLocaleServer(uid, setLocale);
  }, [isAnonymous, uid, setLocale]);

  useEffect(() => {
    if (isSkillsAndIndustriesIsLoaded) dispatch(getSkillsAndIndustries(true));
    if (languagesIsLoaded) dispatch(getLanguages(true));
  }, [locale]);

  useEffect(() => {
    changeLocaleDate(locale);
    setLocaleToLocalStorage(locale);
  }, [locale]);

  const value = useMemo(() => ({ locale, setLocale, currentCountryCode }), [locale, setLocale, currentCountryCode]);

  return (
    <LanguageContext.Provider value={value}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={MUI_LOCALES_HASH[locale]}>
        {children}
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};

export default LanguagesProvider;

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export const extractPhoneNumber = (number?: string | null) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!number) {
    return {};
  }

  try {
    const phoneNumber = phoneUtil.parse(`+${number}`, '');
    const basePhoneNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    const countryCode = `+${phoneNumber.getCountryCode()}`;

    return {
      countryCode,
      basePhoneNumber,
    };
  } catch {
    return {};
  }
};

export const getCountryCallingCode = (countryCode: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!countryCode) {
    return '';
  }

  try {
    return phoneUtil.getCountryCodeForRegion(countryCode.toUpperCase());
  } catch {
    return '';
  }
};

export const checkValidPhone = (value?: string): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!value) {
    return true;
  }

  try {
    const phoneNumber = phoneUtil.parse(`+${value}`, '');
    const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);

    if (phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)) {
      return true;
    }
  } catch {
    return false;
  }

  return false;
};

import { isAllowed } from 'Utils/settings';
import * as operations from 'Constants/op';
import { BountyType, ProductKind } from 'Constants/enums';
import * as OpManager from 'Utils/opManager';
import { getPostableListDefs, getStreamListDefs } from 'Utils/models/ListManager';
import { isContest } from 'Utils/bounty';
import { UserData } from 'Types/userData.interface';
import { Bounty } from 'Types/bounty.interface';
import { getCurrentUseCase } from 'Utils/useCases';
import { Settings } from 'Types/settings.interface';

export enum FeatureNames {
  DESCRIPTION = 'DESCRIPTION',
  DUMMY_DESCRIPTION = 'DUMMY_DESCRIPTION',
  CATEGORY = 'CATEGORY',
  ATTACHMENTS = 'ATTACHMENTS',
  REWARD = 'REWARD',
  REWARD_OPTIONAL = 'REWARD_OPTIONAL',
  REWARD_RECEIVERS = 'REWARD_RECEIVERS',
  PRICE = 'PRICE',
  RATING_VALUE = 'RATING_VALUE',
  EXPIRY_DATE = 'EXPIRY_DATE',
  DISTRIBUTION_AREA = 'DISTRIBUTION_AREA',
  DIST_TARGET_GROUP = 'DIST_TARGET_GROUP',
  DIST_EXCLUDE_GROUP = 'DIST_EXCLUDE_GROUP',
  REQUEST_BADGE = 'REQUEST_BADGE',
  CONTROL_ANONYMITY = 'CONTROL_ANONYMITY',
  CONTROL_RESPONSES_PRIVACY = 'CONTROL_RESPONSES_PRIVACY',
  DISPLAY_IN = 'DISPLAY_IN',
  CHOICES = 'CHOICES',
  TITLE = 'TITLE',
  TARGET_URL = 'TARGET_URL',
  POST_ON_BEHALF_OF = 'POST_ON_BEHALF_OF',
  STREAM_DETAILS = 'STREAM_DETAILS',
  AVAILABILITY = 'AVAILABILITY',
  QUANTITY = 'QUANTITY',
  USE_QUANTITY = 'USE_QUANTITY',
  TAGS = 'TAGS',
  SKU = 'SKU',
  SCHEDULE_FOR = 'SCHEDULE_FOR',
  TIME_TO_RESPOND = 'TIME_TO_RESPOND',
  ATTACHMENT_MANAGEMENT = 'ATTACHMENT_MANAGEMENT',
  BANNER_INFO = 'BANNER_INFO',
}

interface FeatureProps {
  [key: string]: {
    enabled: boolean;
    op: string|null;
    types: (BountyType|ProductKind)[]|null;
    conditionName: string;
    disabledForSubBounties?: boolean;
  }
}

export const features: FeatureProps = {
  [FeatureNames.DESCRIPTION]: {
    enabled: true,
    op: null,
    types: allExcept([BountyType.Banner, BountyType.Job, BountyType.Album, BountyType.News]),
    conditionName: 'hasDescription',
  },
  [FeatureNames.DUMMY_DESCRIPTION]: {
    enabled: true,
    op: null,
    types: [BountyType.Album, BountyType.News],
    conditionName: 'hasDummyDescription',
  },
  [FeatureNames.CATEGORY]: {
    enabled: true,
    op: null,
    types: allExcept([BountyType.Survey]),
    conditionName: 'hasCategory',
  },
  [FeatureNames.ATTACHMENTS]: { // foto, video, audio, file, image
    enabled: true,
    op: null,
    types: null,
    conditionName: 'hasAttachments',
  },
  [FeatureNames.REWARD]: {
    enabled: true,
    op: operations.ATTACH_REWARD.name,
    types: [
      BountyType.TalentSearch,
      BountyType.TalentRecommendation,
      BountyType.TMOB_Challenge,
      BountyType.Survey,
      BountyType.Checklist,
      BountyType.MCQ,
      BountyType.Trivia,
      BountyType.Lottery,
    ],
    conditionName: 'hasReward',
  },
  [FeatureNames.REWARD_OPTIONAL]: {
    enabled: true,
    op: null,
    types: [
      BountyType.Survey,
      BountyType.Checklist,
      BountyType.MCQ,
      BountyType.Lottery,
    ],
    conditionName: 'hasOptionalReward',
  },
  [FeatureNames.REWARD_RECEIVERS]: {
    enabled: true,
    op: null,
    types: [
      BountyType.RealEstateCustomer,
      BountyType.TalentSearch,
    ],
    conditionName: 'hasRewardReceivers',
  },
  [FeatureNames.PRICE]: {
    enabled: true,
    op: null,
    types: [BountyType.Classified],
    conditionName: 'hasPrice',
  },
  [FeatureNames.RATING_VALUE]: {
    enabled: false,
    op: null,
    types: [BountyType.TMOB_Challenge],
    conditionName: 'hasRatingValue',
  },
  [FeatureNames.EXPIRY_DATE]: {
    enabled: true,
    op: null,
    types: [
      BountyType.TMOB_Coaching,
      BountyType.TMOB_Challenge,
      BountyType.Survey,
      BountyType.Lottery,
      BountyType.News,
      BountyType.Banner,
    ],
    conditionName: 'hasExpiryFeature',
  },
  [FeatureNames.DISTRIBUTION_AREA]: {
    enabled: true,
    op: null,
    types: [
      BountyType.TalentSearch,
      BountyType.TalentRecommendation,
    ],
    conditionName: 'hasDistributionArea',
  },
  [FeatureNames.DIST_TARGET_GROUP]: {
    enabled: true,
    op: operations.CONTROL_BOUNTY_DISTRIBUTION.name,
    types: allExcept([BountyType.Job, ProductKind.Ticket]),
    conditionName: 'hasDistTargetGroup',
    disabledForSubBounties: true,
  },
  [FeatureNames.DIST_EXCLUDE_GROUP]: {
    enabled: false,
    op: operations.CONTROL_BOUNTY_DISTRIBUTION.name,
    types: null,
    conditionName: 'hasDistExcludeGroup',
    disabledForSubBounties: true,
  },
  [FeatureNames.REQUEST_BADGE]: {
    enabled: true,
    op: operations.REQUEST_ROR.name,
    types: allExcept([
      BountyType.TMOB_Challenge,
      BountyType.Survey,
      BountyType.Classified,
      BountyType.Banner,
      BountyType.News,
    ]),
    conditionName: 'hasRequestBadge',
  },
  [FeatureNames.CONTROL_ANONYMITY]: {
    enabled: true,
    op: operations.CTRL_IDENTITY.name,
    types: allExcept([BountyType.Job]),
    conditionName: 'hasControlAnonymity',
  },
  [FeatureNames.CONTROL_RESPONSES_PRIVACY]: {
    enabled: true,
    op: null,
    types: [BountyType.TMOB_Challenge],
    conditionName: 'hasControlResponsesPrivacy',
  },
  [FeatureNames.DISPLAY_IN]: {
    enabled: true,
    op: null,
    types: allExcept(getCurrentUseCase()?.isDinamo
      ? [BountyType.Job, BountyType.News, BountyType.Banner]
      : [BountyType.Job]),
    conditionName: 'hasDisplayIn',
    disabledForSubBounties: true,
  },
  [FeatureNames.CHOICES]: {
    enabled: true,
    op: null,
    types: [BountyType.Survey, BountyType.MCQ, BountyType.Checklist, BountyType.Funding],
    conditionName: 'hasSurvey',
  },
  [FeatureNames.TARGET_URL]: {
    enabled: true,
    op: null,
    types: [BountyType.News, BountyType.Lottery, BountyType.Banner],
    conditionName: 'hasTargetUrl',
  },
  [FeatureNames.POST_ON_BEHALF_OF]: {
    enabled: true,
    op: operations.CAN_GHOSTWRITE.name,
    types: allExcept([
      BountyType.News,
      BountyType.Match,
      BountyType.Banner,
      BountyType.Job,
      ProductKind.Ticket,
    ]),
    conditionName: 'hasPostOnBehalfOf',
    disabledForSubBounties: true,
  },
  [FeatureNames.STREAM_DETAILS]: {
    enabled: true,
    op: null,
    types: [BountyType.LiveStream],
    conditionName: 'hasStreamDetails',
  },
  [FeatureNames.SCHEDULE_FOR]: {
    enabled: true,
    op: null,
    types: null,
    conditionName: 'hasScheduleFor',
    disabledForSubBounties: true,
  },
  [FeatureNames.TIME_TO_RESPOND]: {
    enabled: true,
    op: null,
    types: [BountyType.MCQ],
    conditionName: 'hasTimeToRespond',
  },
};

function allExcept(typesToExclude: (BountyType|ProductKind)[]) {
  return Object.values(BountyType).filter((type) => !typesToExclude.includes(type));
}

interface AppliesTo {
  feature: FeatureNames;
  bountyType: BountyType;
  settings: Settings;
  userData: UserData;
  parentBounty?: Partial<Bounty>;
}

export function appliesTo({ feature, bountyType, settings, userData, parentBounty }: AppliesTo) {
  const featuresProps = features[feature];

  if (parentBounty?.id && featuresProps.disabledForSubBounties) {
    return false;
  }

  return Boolean(
    featuresProps.enabled
    && (featuresProps.types === null || featuresProps?.types?.includes(bountyType))
    && (featuresProps.op === null || isAllowed({ op: featuresProps.op, settings, userData })),
  );
}

interface SetupFeatures {
  bountyType: BountyType;
  settings: Settings;
  userData: UserData;
  parentBounty?: Partial<Bounty>;
}

export const setupFeatures = ({ bountyType, settings, userData, parentBounty }: SetupFeatures) => {
  const uiElements: { [key: string]: boolean } = {};

  (Object.keys(features) as FeatureNames[]).forEach((feature) => {
    const featureData = features[feature];
    uiElements[featureData.conditionName] = appliesTo({
      feature,
      bountyType,
      settings,
      userData,
      parentBounty,
    });
  });

  return uiElements;
};

interface AvailableFeatures {
  option: any; // TODO: add type for option
  selectedBounty: Bounty;
  parentBounty?: Partial<Bounty>;
  settings: Settings;
  userData: UserData;
}

export function getBountyAvailableFeatures({
  option, userData, settings, selectedBounty, parentBounty,
}: AvailableFeatures) {
  const { bountyType, isContestSubBounty } = option;
  const commonProps = { bountyType, userData, settings, parentBounty };
  const identityMode = OpManager.getIdentityMode(settings, userData);

  const allowedFeatures = setupFeatures(commonProps);
  const hasAttachments = appliesTo({ feature: FeatureNames.ATTACHMENTS, ...commonProps });
  const hasControlResponsesPrivacy = appliesTo({ feature: FeatureNames.CONTROL_RESPONSES_PRIVACY, ...commonProps });
  const hasDescription = appliesTo({ feature: FeatureNames.DESCRIPTION, ...commonProps });
  const hasDummyDescription = appliesTo({ feature: FeatureNames.DUMMY_DESCRIPTION, ...commonProps });
  const hasDistTargetGroup = appliesTo({ feature: FeatureNames.DIST_TARGET_GROUP, ...commonProps });
  const hasDistExcludeGroup = appliesTo({ feature: FeatureNames.DIST_EXCLUDE_GROUP, ...commonProps });
  const hasExpiryFeature = appliesTo({ feature: FeatureNames.EXPIRY_DATE, ...commonProps })
    && (
      bountyType !== BountyType.TMOB_Coaching
      || isAllowed({ op: operations.CONTROL_BOUNTY_DISTRIBUTION.name, settings, userData })
    );

  const streamList = getStreamListDefs(settings, userData);
  const lists = getPostableListDefs(streamList, userData, bountyType);
  const hasDisplayIn = lists && lists.length > 1 && allowedFeatures.hasDisplayIn;
  const isSubbountyOfaContest = isContestSubBounty || (selectedBounty.parentBounty && isContest(selectedBounty.parentBounty?.type));

  return {
    ...allowedFeatures,
    hasDummyDescription,
    hasReward: isSubbountyOfaContest ? false : allowedFeatures.hasReward,
    hasDistTargetGroup: isSubbountyOfaContest ? false : hasDistTargetGroup,
    hasPostOnBehalfOf: isSubbountyOfaContest ? false : allowedFeatures.hasPostOnBehalfOf,
    hasDisplayIn: isSubbountyOfaContest ? false : hasDisplayIn,
    hasExpiryFeature: isSubbountyOfaContest ? false : hasExpiryFeature,
    hasTimeToRespond: allowedFeatures.hasTimeToRespond && isSubbountyOfaContest,
    hasAttachments,
    hasDescription,
    hasControlResponsesPrivacy,
    hasDistExcludeGroup,
    hasControlAnonymity: !identityMode.forced && allowedFeatures.hasControlAnonymity,
    hasCategory: false, // for the time being, hide the cateogory stuff, we're not using it at this point
  };
}

import { ACTIONS } from './actions';

const INIT_STATE = {
  isLoading: false,
  productDetails: {},
  productReviews: {},
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: payload,
      };

    case ACTIONS.SET_PRODUCT_REVIEWS:
      return {
        ...state,
        productReviews: payload,
      };

    default:
      return state;
  }
};

export default reducer;

import * as Yup from 'yup';

export const createGroupSchema = Yup.object({
  name: Yup
    .string()
    .required('Field is required')
    .max(40, 'Should be less or equal with 40 characters'),
  description: Yup
    .string()
    .required('Field is required')
    .max(160, 'Should be less or equal with 160 characters'),
});

import { Bounty } from 'Types/bounty.interface';
import {
  AttachmentType,
  AttachmentDisplayStatus,
  AttachmentMeaning,
  AttachmentVisibility,
  AttachmentSourceOptions,
} from 'Constants/enums';
import { ATTACHMENTS_OPTIONS_PROPS, PDF_MIME_TYPE } from 'Constants/attachment';
import { getRandomInt } from 'Utils/helpers';
import { Attachment } from 'Types/attachment.interface';
import { NewFileData } from 'Types/newFileData.interface';
import { AttachmentOption } from 'Types/attachmentOption.interface';
import { isEmpty } from 'lodash';
import EmptyImage from 'Assets/img/general/image_placeholder.png';

export function getAttachmentType(mimeType: string) {
  const [baseType, fileType] = mimeType.split('/');

  if (mimeType === PDF_MIME_TYPE) {
    return AttachmentType.PDF;
  }

  if (fileType === 'gif') {
    return AttachmentType.GIF;
  }

  switch (baseType) {
    case 'image':
      return AttachmentType.Image;
    case 'audio':
      return AttachmentType.Audio;
    case 'video':
      return AttachmentType.Video;
    default:
      return AttachmentType.Other;
  }
}

/**
 * Format file before upload
 * @param data {Object} contains file props
 * @returns {Object}
 */

export function addNewFile(data: NewFileData) {
  if (!data) {
    return null;
  }

  const type = getAttachmentType(data.type);

  return {
    mimeType: data.type,
    filename: data.name,
    size: data.size,
    type,
    data,
  };
}

export function getNextAttachmentId(ids: string[] = []): string | null {
  for (let i = 0; i < 100; i++) {
    const nr = getRandomInt(0, 1000);
    const key = `a${nr}`;

    if (!ids.includes(key)) {
      return key;
    }
  }

  return null;
}

export function valueOfUploadFileWithUri(attach: Attachment) {
  const {
    localUri, ...remainingProps
  } = attach;

  return {
    ...remainingProps,
    fileUri: localUri,
    fileContent: null,
  };
}

export function valueOfUploadFile(attach: Attachment & { data: any }) {
  const {
    url, localUri, data, ...remainingProps
  } = attach;

  return {
    ...remainingProps,
    fileUri: localUri || url || null,
    fileContent: data,
  };
}

export function getOrder(attachments: Attachment[] = []) {
  let maxOrderIndex = 0;

  attachments.forEach((attach) => {
    if (!Number.isNaN(attach.order) && +attach.order > maxOrderIndex) {
      maxOrderIndex = +attach.order;
    }
  });

  return maxOrderIndex + 1;
}

export function getNextOrder(order: number) {
  const newOrder = `000${order}`;
  return newOrder.substring(newOrder.length - 4);
}

export function formatAttachment(attachment: Attachment & { downloadUri: string }, attachmentId: number, order: number) {
  return {
    id: attachmentId,
    url: attachment?.downloadUri?.toString() || null,
    type: attachment.type,
    filename: attachment.filename,
    size: attachment.size,
    mimeType: attachment.mimeType,
    meaning: attachment.meaning || AttachmentMeaning.Other,
    order: attachment?.order || getNextOrder(order),
    visibility: AttachmentVisibility.All,
    displayStyle: AttachmentDisplayStatus.Inline,
    action: attachment?.action || {
      actionUrl: null,
    },
    altText: attachment?.altText || null,
  };
}

export function chooseAttachmentSource(options: AttachmentSourceOptions[] | null = null) {
  const menuOptions = options?.length
    ? options
    : [
      AttachmentSourceOptions.PhotoLibrary,
      AttachmentSourceOptions.VideoLibrary,
      AttachmentSourceOptions.AudioLibrary,
      AttachmentSourceOptions.PDFFiles,
    ];
  return getMenuOptions(menuOptions);
}

export const getImagesFromAttachments = (attachments: Attachment[]) => {
  if (!attachments) return [];

  return attachments.filter((a) => a.mimeType.includes('image'));
};

export const getNonImageAttachments = (attachments: Attachment[]) => {
  if (!attachments) return [];

  return attachments.filter((a) => !a.mimeType.includes('image'));
};

export const getAttachedImages = (attachments: Attachment[]) => {
  const attachedImages = getImagesFromAttachments(attachments);
  return attachedImages.length > 0 ? attachedImages.map((i) => i.url) : [];
};

export const getFirstAttachedImage = (attachments: Attachment[]) => {
  const attachedImages = getImagesFromAttachments(attachments);
  return attachedImages.length > 0 ? attachedImages[0].url : null;
};

//  TODO: currently unused
/**
 * Return uploading file signature (8 symbols)
 *
 * @param {string} base64 - file in base64 format
 * @return {string} file signature
 */
export const getMimeHash = (base64: string): string => {
  let signatureHash = '';
  const arr = Uint8Array.from(atob(base64.split(',')[1]), (c) => c.charCodeAt(0)).subarray(0, 4);
  for (let i = 0; i < arr.length; i += 1) {
    signatureHash += arr[i]?.toString(16) || '';
  }
  return signatureHash;
};

export const getMenuOptions = (options: AttachmentSourceOptions[]): AttachmentOption[] => {
  const menuItems = [] as AttachmentOption[];

  options.forEach((option) => {
    const props = ATTACHMENTS_OPTIONS_PROPS[option];

    if (props) {
      menuItems.push(props);
    }
  });

  return menuItems;
};

export const orderAttachments = (attachments: Attachment[]) => (
  attachments.sort((a, b) => {
    if (!b.order || a.order < b.order) {
      return -1;
    }

    if (!a.order || a.order > b.order) {
      return 1;
    }

    return 0;
  })
);

export function getVisibilityAsEnum(visibility: AttachmentVisibility) {
  if (!visibility) {
    return AttachmentVisibility.All;
  }

  return Object.values(AttachmentVisibility).includes(visibility) ? visibility : AttachmentVisibility.None;
}

export function getAttachments(attachments: Record<string, Attachment>, visibility = AttachmentVisibility.All) {
  return Object
    .values(attachments)
    .filter((att) => getVisibilityAsEnum(att.visibility) === visibility)
    .sort((a, b) => +a.order - +b.order);
}

export function getAttachmentsWithoutCustomMeaning(bounty: Bounty) {
  return bounty.attachments && bounty.attachments.attachments
    ? Object
      .values(bounty.attachments.attachments)
      .filter((attach) => (![AttachmentMeaning.Promotion, AttachmentMeaning.Product].includes(attach.meaning)))
    : [];
}

export function getPreviewConfig(attachments: Attachment[]) {
  if (attachments.length === 1) {
    return [
      {
        source: attachments[0].cdnUrl || attachments[0].url || EmptyImage,
        type: attachments[0].type,
        cols: 2,
        rows: 1,
      },
    ];
  }

  if (attachments.length === 2) {
    return [
      {
        source: attachments[0].cdnUrl || attachments[0].url || EmptyImage,
        type: attachments[0].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[1].cdnUrl || attachments[1].url || EmptyImage,
        type: attachments[1].type,
        cols: 1,
        rows: 1,
      },
    ];
  }

  if (attachments.length === 3) {
    return [
      {
        source: attachments[0].cdnUrl || attachments[0].url || EmptyImage,
        type: attachments[0].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[1].cdnUrl || attachments[1].url || EmptyImage,
        type: attachments[1].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[2].cdnUrl || attachments[2].url || EmptyImage,
        type: attachments[2].type,
        cols: 2,
        rows: 1,
      },
    ];
  }

  if (attachments.length > 3) {
    return [
      {
        source: attachments[0].cdnUrl || attachments[0].url || EmptyImage,
        type: attachments[0].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[1].cdnUrl || attachments[1].url || EmptyImage,
        type: attachments[1].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[2].cdnUrl || attachments[2].url || EmptyImage,
        type: attachments[2].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[3].cdnUrl || attachments[3].url || EmptyImage,
        type: attachments[3].type,
        cols: 1,
        rows: 1,
      },
    ];
  }
}

export const filterProductPicture = (pictures: Attachment[], mainPictureUrl: string) => {
  /**
   * Pictures array might or might not contain main picture url.
   */
  const mainPicture = pictures?.find((picture) => picture?.url === mainPictureUrl);
  const picturesWithoutMain = pictures?.filter((picture) => picture?.url !== mainPictureUrl) || [];
  const allPictures = mainPicture ? [mainPicture, ...picturesWithoutMain] : [];

  return {
    picturesWithoutMain,
    allPictures,
  };
};

export const getAttachmentsUrls = (attachments = {} as { [key: string]: Attachment }) => {
  if (!isEmpty(attachments)) {
    return Object.values(attachments).map((attachment) => attachment);
  }

  return [];
};

export const prepareAcceptType = (types: string[]): Record<string, []> => (
  types.reduce((prevVal, currVal) => ({ ...prevVal, [currVal]: [] }), {})
);

export const getAttachmentsWithIds = (attachments: Attachment[]) => {
  const formattedAttachments: Record<string, Attachment> = {};

  attachments?.forEach((attachment) => {
    const id = getNextAttachmentId();

    if (id) {
      formattedAttachments[id] = attachment;
    }
  });

  return formattedAttachments;
};

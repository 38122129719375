import { CompanyType } from 'Constants/enums';
import { CompanyUsersFilters } from 'Types/companyUsersFilters.interface';
import { AppDispatch } from 'Types/redux.types';
import { CompanyUsersRequest } from 'Types/companyUsersRequest.interface';

export enum Actions {
  UpdateFilters = 'companyUsers/UpdateFilters',
  UpdateReqProps = 'companyUsers/UpdateReqProps',
  ResetFilters = 'companyUsers/ResetFilters',
  ResetAll = 'companyUsers/ResetAll',
  UpdateOrgType = 'companyUsers/UpdateOrgType',
}

const updateCompanyUsersReqProps = (reqProps: Partial<CompanyUsersRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.UpdateReqProps, payload: reqProps });

export const updateCompanyUsersOffset = (offset: number) => updateCompanyUsersReqProps({ start: offset });

export const updateCompanyUsersLimit = (limit: number) => updateCompanyUsersReqProps({ limit });

export const updateCompanyUsersOrder = (order: string) => updateCompanyUsersReqProps({ order, start: 0 });

export const updateCompanyUsersQuery = (query: string) => updateCompanyUsersReqProps({ query, start: 0 });

export const updateCompanyUsersFilters = (filters: CompanyUsersFilters) => (dispatch: AppDispatch) =>
  dispatch({
    type: Actions.UpdateFilters,
    payload: { filters, reqProps: { start: 0 } },
  });

export const resetCompanyUsersFilters = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.ResetFilters });

export const resetCompanyUsersFiltersAndReqProps = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.ResetAll });

export const updateCompanyType = (companyType: CompanyType) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.UpdateOrgType, payload: companyType });

import { UserService } from 'Services/UserService';

import { AppDispatch } from 'Types/redux.types';

export const ACTIONS = {
  SET_LOADING_ACCOUNT: 'companyAccountReducer/setLoadingAccount',
  SET_LOADING_TRANSACTIONS: 'companyAccountReducer/setLoadingTransactions',
  SET_ACCOUNT_INFO: 'companyAccountReducer/setAccountInfo',
  SET_TRANSACTIONS: 'companyAccountReducer/setTransactions',
  RESET_REDUCER: 'companyAccountReducer/resetReducer',
};

export const getCompanyAccount = (companyId: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING_ACCOUNT, payload: true });

  try {
    const response = await UserService.getUserAccountInfo(companyId);
    dispatch({ type: ACTIONS.SET_ACCOUNT_INFO, payload: response });
  } catch {
    dispatch({ type: ACTIONS.SET_ACCOUNT_INFO, payload: {} });
  } finally {
    dispatch({ type: ACTIONS.SET_LOADING_ACCOUNT, payload: false });
  }
};

export const getCompanyTransactions = (companyId: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING_TRANSACTIONS, payload: true });

  try {
    const response = await UserService.getUserTransactions(companyId);
    dispatch({ type: ACTIONS.SET_TRANSACTIONS, payload: response });
  } catch {
    dispatch({ type: ACTIONS.SET_TRANSACTIONS, payload: {} });
  } finally {
    dispatch({ type: ACTIONS.SET_LOADING_TRANSACTIONS, payload: false });
  }
};

export const resetStore = () => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.RESET_REDUCER });
};

import { FC, LegacyRef, ReactNode } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextFieldProps } from '@mui/material';
import { DateTimeValidationError } from '@mui/x-date-pickers';

import CustomInput from 'Components/CustomInput';

import * as styles from './index.module.scss';

interface CustomDateTimePickerProps {
  onDateChange: (date: Date | null, name: string) => any;
  name: string;
  value: string;
  ampm?: boolean;
  validationErrors?: string[] | ReactNode[];
  minDateTime?: Date;
}

type BrowserInputProps = TextFieldProps & {
  ownerState?: any;
};

const CustomTextInput = (props: BrowserInputProps) => {
  const { inputProps, InputProps, inputRef, ...other } = props;
  delete other?.error;
  delete other?.focused;
  delete other?.ownerState;

  return (
    <div className={styles.picker} ref={InputProps?.ref as LegacyRef<HTMLDivElement>}>
      <CustomInput ref={inputRef} {...inputProps} className={styles.control} validationErrors={inputProps?.validationErrors} {...(other as any)} />
      <span className={styles.icon}>{InputProps?.endAdornment}</span>
    </div>
  );
};

const CustomDateTimePicker: FC<CustomDateTimePickerProps> = ({
  onDateChange,
  value,
  name,
  validationErrors = [],
  ...remainingProps
}) => {
  const handleChange = (date: Date | null, error: { validationError: DateTimeValidationError }) => {
    const newDateObj = error?.validationError === 'invalidDate' ? null : date;
    onDateChange(newDateObj, name);
  };

  return (
    <DateTimePicker
      {...remainingProps}
      value={value ? new Date(value) : null}
      onChange={handleChange}
      slots={{
        textField: CustomTextInput,
      }}
      slotProps={{
        textField: {
          inputProps: {
            validationErrors,
          },
        },
      }}
    />
  );
};

export default CustomDateTimePicker;

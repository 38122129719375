import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Drawer, Hidden, SelectChangeEvent, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import CustomInput from 'Components/CustomInput';
import RewardField from 'Components/RewardField';
import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';
import LoaderOverlay from 'Components/LoaderOverlay';

import { settingsSelector, userDataSelector } from 'Store/settings/selectors';
import { authLoggedUserSelector } from 'Store/auth/selectors';

import { AttachmentMeaning, BountyType, ButtonType, RewardType } from 'Constants/enums';
import { badgeCollectionSchema } from 'Utils/validation/badges';
import { getErrorsByName } from 'Utils/formik';
import { Badge, CreateBadge, BadgeInfo, CreateBadgeCollection } from 'Types/badge.interface';
import { CurrencyOptions } from 'Types/currency.interface';
import { formatFormikReward, getRewardTypesAsOptions } from 'Utils/reward';
import { ThunkDispatchType } from 'Types/redux.types';
import { notificationToast } from 'Utils/notificationToaster';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import { selectedBountySelector } from 'Store/createBounty/selectors';
import { closeDrawer } from 'Store/createBounty/actions';
import BadgeCreate from 'Containers/BadgeCollectionCreate/BadgeCreate';
import BadgeDetailsCard from 'Containers/BadgeCollectionCreate/BadgeDetailsCard';
import { createBadge, editBadge } from 'Services/BadgeService';
import { parseEncryptedDescription } from 'Utils/yaml';
import { generateUID } from 'Utils/helpers';
import { prepareAcceptType } from 'Utils/attachments';
import { IMAGE_MIME_TYPES } from 'Constants/attachment';
import BadgeAttachments from 'Containers/BadgeCollectionCreate/BadgeAttachments';
import { Attachment } from 'Types/attachment.interface';
import * as styles from './index.module.scss';

const coverAccept = prepareAcceptType(IMAGE_MIME_TYPES);

const BadgeCollectionCreate = () => {
  const intl = useIntl();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedBadge, setSelectedBadge] = useState<CreateBadge|null>(null);

  const dispatch = useDispatch<ThunkDispatchType>();

  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOptions[]>([]);
  const userData = useSelector(userDataSelector)?.data || {};
  const loggedUser = useSelector(authLoggedUserSelector)?.data || {};
  const settings = useSelector(settingsSelector) || {};
  const bountyDetails = useSelector(selectedBountySelector) || {};
  const isEditMode = !!bountyDetails?.id;
  const titleId = isEditMode ? 'title.edit_collection' : 'title.create_collection';
  const oldBadges = useMemo(() => {
    const decodedDescription = bountyDetails.description
      ? parseEncryptedDescription<{ badges: BadgeInfo }>(bountyDetails.description)
      : null;
    const formattedBadges: Record<string, CreateBadge> = {};

    if (decodedDescription?.badges) {
      Object.values(decodedDescription.badges).forEach((badge) => {
        const { rewardStr, ...remainingBadgeProps } = badge;

        formattedBadges[badge.code] = {
          ...remainingBadgeProps,
          reward: {
            amount: rewardStr?.split(' ')?.[0] || '',
            currency: RewardType.Points,
          },
        };
      });
    }

    return formattedBadges;
  }, [bountyDetails?.id]);
  const cover = useMemo(() => {
    const attachments: Record<string, Attachment> = bountyDetails?.attachments?.attachments || {};
    const collectionCover = Object.values(attachments).find(({ meaning }) => meaning === AttachmentMeaning.Cover);
    return collectionCover
      ? [{ ...collectionCover, localUri: collectionCover.url }]
      : [];
  }, [bountyDetails?.id]);

  useEffect(() => {
    const options = getRewardTypesAsOptions({
      bountyType: BountyType.BadgeCollection,
      userData,
      settings,
    });
    setCurrencyOptions(options);
  }, []);

  const onCancel = () => {
    formik.resetForm({});
    dispatch(closeDrawer());
  };

  const onSave = async (values: CreateBadgeCollection) => {
    try {
      if (isEditMode) {
        await editBadge(bountyDetails, values, userData, loggedUser, settings);
      } else {
        await createBadge(values, userData, loggedUser, settings);
      }

      dispatch(closeDrawer());
    } catch (error: any) {
      notificationToast.error(error.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: BountyType.BadgeCollection,
      title: bountyDetails?.title || '',
      reward: formatFormikReward({ reward: bountyDetails?.reward, settings, userData }),
      prevCover: cover,
      cover,
      badges: oldBadges || {},
    },
    onSubmit: onSave,
    validationSchema: badgeCollectionSchema,
  });

  const handleCollectionChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => {
    formik.setFieldValue(name, value);
  };

  const handleBadgeReward = (name: string, value: Badge) => {
    formik.setFieldValue(name, value);
  };

  const handleBadgeChanges = (badge: CreateBadge) => {
    if (badge.code) {
      formik.setFieldValue('badges', {
        ...formik.values?.badges,
        [badge.code]: badge,
      });
    } else {
      const code = generateUID();

      formik.setFieldValue('badges', {
        ...formik.values?.badges,
        [code]: {
          ...badge,
          code,
        },
      });
    }

    setIsDrawerOpen(false);
  };

  const handleDeleteBadge = (badge: CreateBadge) => {
    const { badges } = formik.values;

    if (badges?.[badge.code]) {
      delete badges[badge.code];
    }

    formik.setFieldValue('badges', badges);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);

    if (selectedBadge) {
      setSelectedBadge(null);
    }
  };

  const handleOpenDrawer = (badge: CreateBadge) => {
    setIsDrawerOpen(true);

    if (badge) {
      setSelectedBadge(badge);
    }
  };

  const handleCover = (attach: Attachment[] | null) => {
    if (!attach) {
      formik.setFieldValue('cover', []);
    }

    formik.setFieldValue('cover', attach);
  };

  return (
    <Box maxWidth="500px">
      <h2>{intl.formatMessage({ id: titleId })}</h2>
      <form onSubmit={formik.handleSubmit} className={styles.content}>
        <BadgeAttachments
          badge={formik?.values?.cover || []}
          setBadge={handleCover}
          label="label.uploadCollectionCover"
          meaning={AttachmentMeaning.Cover}
          accept={coverAccept}
        />
        <Typography className={labelStyles.fieldLabel}>
          {`${intl.formatMessage({ id: 'badge.label.collectionName' })}*`}
        </Typography>
        <CustomInput
          name="title"
          value={formik?.values?.title || ''}
          onChange={handleCollectionChanges}
          validationErrors={getErrorsByName(formik, 'title')}
        />
        <RewardField
          value={formik?.values?.reward || {}}
          onChange={handleCollectionChanges}
          currencyOptions={currencyOptions}
          validationErrors={getErrorsByName(formik, 'reward')}
          handleBadge={handleBadgeReward}
        />
        <SimpleButton
          decoration={SimpleButtonDecoration.Transparent}
          label={intl.formatMessage({ id: 'label.addBadge' })}
          onClick={handleOpenDrawer}
        />

        <Box my={1}>
          {formik?.values?.badges && Object.values(formik.values.badges).map((badge, index) => (
            <BadgeDetailsCard
              key={index}
              badge={badge}
              onEdit={handleOpenDrawer}
              onDelete={handleDeleteBadge}
            />
          ))}
        </Box>
        <Box className={styles.navigation}>
          <SimpleButton
            decoration={SimpleButtonDecoration.Transparent}
            label={intl.formatMessage({ id: 'button.cancel' })}
            onClick={onCancel}
          />
          <SimpleButton
            label={intl.formatMessage({ id: 'button.save' })}
            type={ButtonType.submit}
          />
        </Box>
      </form>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={Boolean(selectedBadge && isDrawerOpen)}
          onClose={handleCloseDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {selectedBadge && (
            <Box p={3}>
              <BadgeCreate
                badge={selectedBadge}
                onCancel={handleCloseDrawer}
                onSubmit={handleBadgeChanges}
              />
            </Box>
          )}
        </Drawer>
      </Hidden>
      {formik.isSubmitting && <LoaderOverlay />}
    </Box>
  );
};

export default BadgeCollectionCreate;

import { CountriesList } from 'Utils/countries';
import { Locale } from 'date-fns';

import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import roLocale from 'date-fns/locale/ro';
import plLocale from 'date-fns/locale/pl';
import itLocale from 'date-fns/locale/it';
import esLocale from 'date-fns/locale/es';
import deLocale from 'date-fns/locale/de';
import kaLocale from 'date-fns/locale/ka';

export const MUI_LOCALES_HASH: Record<string, Locale> = {
  en: enLocale,
  ru: ruLocale,
  ro: roLocale,
  pl: plLocale,
  it: itLocale,
  es: esLocale,
  de: deLocale,
  ka: kaLocale,
};

export const LOCALES = {
  ENGLISH: 'en-US',
  RUSSIAN: 'ru-RU',
  TAJIK: 'tg-TJ',
  ROMANIAN: 'ro-RO',
  KAZAKH: 'kk-KZ',
  KYRGYZ: 'ky-KG',
  UZBEK: 'uz-UZ',
  TURKMENI: 'tk-TM',
  AZERBIJANI: 'az-AZ',
  UKRAINIAN: 'uk-UA',
  ARMENIAN: 'hy-AM',
  BELARUSIAN: 'be-BY',
  BULGARIAN: 'bg-BG',
  CATALAN: 'ca-ES',
  CROATIAN: 'hr-HR',
  CZECH: 'cs-CZ',
  DANISH: 'da-DK',
  ESTONIAN: 'et-EE',
  FINNISH: 'fi-FI',
  FRENCH: 'fr-FR',
  GEORGIAN: 'ka-GE',
  GERMAN: 'de-DE',
  GREEK: 'el-GR',
  HEBREW: 'he-IL',
  HUNGARIAN: 'hu-HU',
  ICELANDIC: 'is-IS',
  INDONESIAN: 'id-ID',
  ITALIAN: 'it-IT',
  LATVIAN: 'lv-LV',
  POLISH: 'pl-PL',
  SPANISH: 'es-ES',
};

const langTexts = [
  {
    key: LOCALES.ENGLISH,
    shortText: 'English',
    longText: 'English',
  },
  {
    key: LOCALES.RUSSIAN,
    shortText: 'Русский',
    longText: 'Русский (Russian)',
  },
  {
    key: LOCALES.ROMANIAN,
    shortText: 'Română',
    longText: 'Română (Romanian)',
  },
  {
    key: LOCALES.POLISH,
    shortText: 'Polski',
    longText: 'Polski (Polish)',
  },
  {
    key: LOCALES.ITALIAN,
    shortText: 'Italiano',
    longText: 'Italiano (Italian)',
  },
  {
    key: LOCALES.SPANISH,
    shortText: 'Español',
    longText: 'Español (Spanish)',
  },
  {
    key: LOCALES.GERMAN,
    shortText: 'Deutsch',
    longText: 'Deutsch (German)',
  },
  {
    key: LOCALES.GEORGIAN,
    shortText: 'ქართული',
    longText: 'ქართული (Georgian)',
  },
];

export const getCountry = (code: string) => CountriesList.find((country) => country.code === code);

langTexts.map((lang) => getCountry(lang.key.slice(3)));

export default langTexts;

// Others
import { UserService } from 'Services/UserService';
import { KYCLevel, QueueAlias, UserIdentityConfigState } from 'Constants/enums';
import { postEvent, getApiData, putApiData } from 'Services/Api';
import { convertObjToArray } from 'Utils/helpers';
import { firebaseGetUserCommentsRef } from 'Services/FirebaseService';
import { AppDispatch } from 'Types/redux.types';
import { Endorsement } from 'Types/endorsement.interface';
import { KYCRejectReason } from 'Types/KYCRejectReason.interface';
import { ChangeKYBRequest } from 'Types/changeKYBRequest.interface';

export enum Actions {
  Loading = 'userProfileReducer/Loading',
  Reset = 'userProfileReducer/Reset',
  GetProfile = 'userProfileReducer/GetProfile',
  GetPrivateInfo = 'userProfileReducer/GetPrivateInformation',
  GetEndorsements = 'userProfileReducer/GetEndorsements',
  GetAllowedRoles = 'userProfileReducer/GetAllowedRoles',
  UpdateBasicInfo = 'userProfileReducer/UpdateBasicInformation',
  UpdateRoles = 'userProfileReducer/UpdateRoles',
  UpdateStatus = 'userProfileReducer/UpdateStatus',
  UpdatePIN = 'userProfileReducer/UpdatePin',
  UpdateEnvMode = 'userProfileReducer/UpdateEnvironmentMode',
  UpdateUserEmail = 'userProfileReducer/UpdateUserEmail',
}

const updateLoadingStatus = (bool: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.Loading,
    payload: bool,
  });
};

export const getUserPrivateInformation = (userId: string, params: string = '') => (dispatch: AppDispatch) => {
  const endpoint = `/api/v1/users/${userId}/private${params}`;
  getApiData(endpoint)
    .then((response) => {
      dispatch({ type: Actions.GetPrivateInfo, payload: response });
    });
};

export const getUserEndorsements = (userId: string) => (dispatch: AppDispatch) => {
  updateLoadingStatus(true)(dispatch);

  firebaseGetUserCommentsRef()
    .child('users')
    .child(userId)
    .once('value', (dataSnapshot) => {
      // TODO: REFACTOR dataSnapshotVal
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val());

      dispatch({ type: Actions.GetEndorsements, payload: dataSnapshotVal });
      updateLoadingStatus(false)(dispatch);
    });
};

export const endorseUser = (endorsement: Endorsement) => {
  const addEndorsementRef = firebaseGetUserCommentsRef().child('users').child(endorsement.entityId).push();
  const endorsementKey = addEndorsementRef.key;
  endorsement.id = endorsementKey as string;
  addEndorsementRef.set(endorsement);

  postEvent(
    QueueAlias.AddComment,
    { comment: endorsement, commentId: endorsementKey },
  );
};

export const updateCompanyProfile = (updates: { description?: string, name?: string, companyId: string }) => {
  postEvent(QueueAlias.UpdateCompany, updates);
};

export const updateIdentityState = (updates: {
  userId: string;
  identityConfigState: UserIdentityConfigState;
  kycLevels: KYCLevel[];
  kycRejectReason: KYCRejectReason;
}) => UserService.updateUser(updates);

// TODO: add response type
export const changeKYBStatus = ({ companyId, state } : ChangeKYBRequest) => {
  const path = `/api/v1/clients/${companyId}/state`;
  return putApiData(path, state);
};

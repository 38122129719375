import { ACTIONS } from './actions';

const INIT_STATE = {
  isAccountLoading: false,
  areTransactionsLoading: false,
  userAccount: {},
  userTransactions: [],
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_LOADING_ACCOUNT:
      return {
        ...state,
        isAccountLoading: payload,
      };

    case ACTIONS.SET_ACCOUNT_INFO:
      return {
        ...state,
        userAccount: payload,
      };

    case ACTIONS.SET_LOADING_TRANSACTIONS:
      return {
        ...state,
        areTransactionsLoading: payload,
      };

    case ACTIONS.SET_TRANSACTIONS:
      return {
        ...state,
        userTransactions: payload,
      };

    case ACTIONS.RESET_REDUCER:
      return INIT_STATE;

    default:
      return state;
  }
};

export default reducer;

//  Other resources
import { ApplicationConfig } from 'Types/applicationConfig.type';
import flavourConfig from '../../config';

//  TODO: replace this with "import flavourConfig from '../../config';"
const getApplicationConfigContainer = flavourConfig();

export const getApplicationConfig = () => getApplicationConfigContainer;

export const AppConfig: ApplicationConfig = {
  id: 'joblio',
  brandName: '',
  ...getApplicationConfigContainer,
};

export const buildAgentString = () => `${AppConfig.id}/web`;

import { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';

import TooltipAnchor from 'Components/TooltipAnchor';
import CustomInput from 'Components/CustomInput';
import NumberInput from 'Components/NumberInput';

import * as labelStyles from 'Assets/scss/modules/label.module.scss';

interface TextInputProps {
  label: string;
  name: string;
  value: string;
  validationErrors: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
  placeholder?: string;
  tooltip?: string;
  isRequired?: boolean;
  disabled?: boolean;
  type?: string;
}

const TextInput: FC<TextInputProps> = ({
  label,
  placeholder,
  name,
  isRequired = false,
  disabled = false,
  tooltip,
  type,
  onChange,
  validationErrors,
  value,
}) => {
  const intl = useIntl();
  const Input = type === 'number' ? NumberInput : CustomInput;

  return (
    <div>
      <label className={labelStyles.fieldLabel}>
        {`${intl.formatMessage({ id: label })}${isRequired ? '*' : ''}`}
        {tooltip && (
          <>
            {' '}
            <TooltipAnchor translationKey={tooltip} />
          </>
        )}
      </label>
      <Input
        name={name}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        value={value}
        onChange={onChange}
        validationErrors={validationErrors}
        disabled={disabled}
      />
    </div>
  );
};

export default TextInput;

import {
  GET_RESERVATIONS,
  GET_RESERVATIONS_AVAILABILITY,
  GET_RESERVATIONS_STATISTICS,
  USER_RESERVATIONS,
} from 'Constants/apiRoutes';

import { getApiData, postApiData } from 'Services/Api';
import { formatApiRoute } from 'Utils/api';
import { ListResponse } from 'Types/listResponse.interface';
import { Bounty } from 'Types/bounty.interface';
import { ReservationsStatistics } from 'Types/appStatistics.interface';
import { ReservationAvailability, ReservationInfo } from 'Types/reservation.interface';

const ReservationService = {
  async getReservations(queryParams: { filters?: string, start?: number, length?: number }): Promise<ListResponse<Bounty>> {
    const formattedRoute = formatApiRoute({
      endpoint: GET_RESERVATIONS,
      params: {},
      queryParams: queryParams || {},
    });
    return getApiData<ListResponse<Bounty>>(formattedRoute);
  },

  async checkReservationAvailability(queryParams: Record<string, string | number>): Promise<ReservationAvailability> {
    const formattedRoute = formatApiRoute({
      endpoint: GET_RESERVATIONS_AVAILABILITY,
      params: {},
      queryParams: queryParams || {},
    });
    return getApiData<ReservationAvailability>(formattedRoute);
  },

  async getReservationsStatistics(clientId: string, queryParams: { filters?: string, start?: number, length?: number }): Promise<ReservationsStatistics> {
    const formattedRoute = formatApiRoute({
      endpoint: GET_RESERVATIONS_STATISTICS,
      params: { clientId },
      queryParams: queryParams || {},
    });
    return getApiData<ReservationsStatistics>(formattedRoute);
  },

  async createReservation(reservation: Partial<ReservationInfo>) {
    return postApiData(USER_RESERVATIONS, reservation);
  },
};

export default ReservationService;

import merge from 'lodash/merge';

export const FLAVOUR = process.env.FLAVOUR || 'joblio';
const TARGET_ENV = import.meta.env.PROD ? 'prod' : 'dev';


// eslint-disable-next-line
console.log(`loading ${FLAVOUR}/${TARGET_ENV} in ${import.meta.env.PROD} mode`);

// This is only Dev Config imports
import flavourJoblio from '../flavours/joblio/config/config';
import flavourBanfield from '../flavours/banfield/config/config';
import flavourBelong from '../flavours/belong/config/config';
import flavourDripdrop from '../flavours/dripdrop/config/config';
import flavourFcwiltz from '../flavours/fcwiltz/config/config';
import flavourFcdinamoge from '../flavours/fcdinamoge/config/config';
import flavourPropatrimonioro from '../flavours/propatrimonioro/config/config';
import flavourStiinta from '../flavours/stiinta/config/config';
import flavourViitorul from '../flavours/viitorul/config/config';
import flavourVera from '../flavours/vera/config/config';
import flavourYoukno from '../flavours/youkno/config/config';
import flavourAlleaves from '../flavours/alleaves/config/config';
import flavourCampaigns from '../flavours/campaigns/config/config';
import flavourStash from '../flavours/stash/config/config';
import flavourVip from '../flavours/vip/config/config';
import flavourDinamo1948 from '../flavours/dinamo1948/config/config';

// This is only Prod Config imports
import flavourJoblioProd from '../flavours/joblio/config/config-prod';
import flavourBanfieldProd from '../flavours/banfield/config/config-prod';
import flavourBelongProd from '../flavours/belong/config/config-prod';
import flavourDripdropProd from '../flavours/dripdrop/config/config-prod';
import flavourFcwiltzProd from '../flavours/fcwiltz/config/config-prod';
import flavourFcdinamogeProd from '../flavours/fcdinamoge/config/config-prod';
import flavourPropatrimonioroProd from '../flavours/propatrimonioro/config/config-prod';
import flavourStiintaProd from '../flavours/stiinta/config/config-prod';
import flavourViitorulProd from '../flavours/viitorul/config/config-prod';
import flavourVeraProd from '../flavours/vera/config/config-prod';
import flavourYouknoProd from '../flavours/youkno/config/config-prod';
import flavourAlleavesProd from '../flavours/alleaves/config/config-prod';
import flavourCampaignsProd from '../flavours/campaigns/config/config-prod';
import flavourStashProd from '../flavours/stash/config/config-prod';
import flavourVipProd from '../flavours/vip/config/config-prod';
import flavourDinamo1948Prod from '../flavours/dinamo1948/config/config-prod';

const flavours = {
    joblio: flavourJoblio,
    banfield: flavourBanfield,
    belong: flavourBelong,
    dripdrop: flavourDripdrop,
    fcwiltz: flavourFcwiltz,
    fcdinamoge: flavourFcdinamoge,
    propatrimonioro: flavourPropatrimonioro,
    stiinta: flavourStiinta,
    viitorul: flavourViitorul,
    vera: flavourVera,
    youkno: flavourYoukno,
    alleaves: flavourAlleaves,
    campaigns: flavourCampaigns,
    stash: flavourStash,
    vip: flavourVip,
    dinamo1948: flavourDinamo1948,
}

const flavoursProd = {
    joblio: flavourJoblioProd,
    banfield: flavourBanfieldProd,
    belong: flavourBelongProd,
    dripdrop: flavourDripdropProd,
    fcwiltz: flavourFcwiltzProd,
    fcdinamoge: flavourFcdinamogeProd,
    propatrimonioro: flavourPropatrimonioroProd,
    stiinta: flavourStiintaProd,
    viitorul: flavourViitorulProd,
    vera: flavourVeraProd,
    youkno: flavourYouknoProd,
    alleaves: flavourAlleavesProd,
    campaigns: flavourCampaignsProd,
    stash: flavourStashProd,
    vip: flavourVipProd,
    dinamo1948: flavourDinamo1948Prod,
}

// @ts-ignore
const flavourConfig = merge(flavours[FLAVOUR], flavoursProd[FLAVOUR]);

export default flavourConfig;

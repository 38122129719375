import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'propatrimonioro',
  title: 'Pro Patrimonio',
  name: 'Pro Patrimonio app',
  brandName: 'Pro Patrimonio',
  appLinkSchema: 'propatrimonio',
  appTld: 'propatrimonio.org',
  tags: ['all'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  TERMS_OF_SERVICE_URL: 'https://www.propatrimonio.org/wp-content/uploads/2018/12/Termeni-si-conditii-1.pdf',
  PRIVACY_STATEMENT_URL: 'https://www.propatrimonio.org/wp-content/uploads/2018/12/Politica-de-Confidentialitate.pdf',
};

export default config;

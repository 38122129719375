import { notificationToast } from 'Utils/notificationToaster';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import PromotionForm from 'Components/PromotionForm';
import PromotionAttachProduct from 'Components/PromotionAttachProduct';
import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';

import { getMyCurrencySelector } from 'Store/settings/selectors';
import { createPromotion, editPromotion } from 'Store/promotions/actions';
import { selectedBountySelector, selectedProductsSelector } from 'Store/createBounty/selectors';
import { openModal, closeModal } from 'Store/modal/actions';
import { closeDrawer, setSelectedBounty, setSelectedProducts } from 'Store/createBounty/actions';

import { BountyType, ButtonType, Currency } from 'Constants/enums';
import { formatDiscount } from 'Utils/general';
import { addProductModal } from 'Constants/modals';
import { getDescriptionText, parseEncryptedDescription } from 'Utils/yaml';
import { ThunkDispatchType } from 'Types/redux.types';
import { DescriptionPromotion, PromotionFormFields } from 'Types/promotion.interface';
import { promotionSchema } from 'Utils/validation/promotion';
import * as styles from './index.module.scss';

const PromotionCreate = () => {
  const [initialState, setInitialState] = useState<PromotionFormFields | null>(null);

  const selectedBounty = useSelector(selectedBountySelector) || {};
  const selectedProductData = useSelector(selectedProductsSelector)?.[0] || {};
  const myCurrency = useSelector(getMyCurrencySelector) || Currency.USD;

  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();

  const bountyId = selectedBounty?.id || null;
  const title = bountyId ? 'bounty.editPromotion' : 'bounty.createPromotion';

  useEffect(() => {
    if (bountyId) {
      const {
        type,
        products,
        couponCode,
        ttl,
        discountInfo,
        maxUsageQty,
        maxOverallUsageQty,
        ...remainingCustomValues
      } = parseEncryptedDescription<DescriptionPromotion>(selectedBounty.description);

      const parsedResponse = { ...selectedBounty, ...remainingCustomValues };
      const seconds = ttl ? Number(ttl) : null;

      dispatch(setSelectedBounty(parsedResponse));
      dispatch(setSelectedProducts(products));

      setInitialState({
        days: seconds ? Math.floor(seconds / (3600 * 24))?.toString() : null,
        hours: seconds ? Math.floor((seconds % (3600 * 24)) / 3600)?.toString() : null,
        minutes: seconds ? Math.floor((seconds % 3600) / 60)?.toString() : null,
        type: type || null,
        couponCode: couponCode || null,
        maxUsageQty: maxUsageQty || 1,
        maxOverallUsageQty: maxOverallUsageQty || null,
        activateAt: selectedBounty.activateAt || null,
        expiresAt: selectedBounty.expiresAt || null,
        discount: formatDiscount(discountInfo?.percentageDiscount),
        description: getDescriptionText(selectedBounty.description) || '',
      });
    }
  }, [bountyId]);

  const handleCancel = () => {
    dispatch(closeDrawer());
  };

  const onCreatePromotion = (values: PromotionFormFields) => {
    dispatch(createPromotion(values)).then(handleCancel);
  };

  const onEditPromotion = (values: PromotionFormFields) => {
    dispatch(editPromotion(values, selectedBounty))
      .then(handleCancel);
  };

  const handleCreatePromotion = (values: PromotionFormFields) => {
    if (!Object.keys(selectedProductData).length) {
      notificationToast.error(intl.formatMessage({ id: 'promotion.productMissing' }));
      return;
    }

    if (bountyId) {
      onEditPromotion(values);
    } else {
      onCreatePromotion(values);
    }
  };

  const removeProduct = () => {
    dispatch(setSelectedProducts([]));
  };

  const addProduct = () => {
    dispatch(openModal(addProductModal({
      closeModal: () => dispatch(closeModal()),
    })));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bountyType: BountyType.Promotion,
      type: initialState?.type || null,
      couponCode: initialState?.couponCode || null,
      activateAt: initialState?.activateAt || null,
      expiresAt: initialState?.expiresAt || null,
      discount: initialState?.discount || null,
      maxUsageQty: initialState?.maxUsageQty || 1,
      maxOverallUsageQty: initialState?.maxOverallUsageQty || null,
      days: initialState?.days || null,
      hours: initialState?.hours || '',
      minutes: initialState?.minutes || '',
      description: initialState?.description || null,
    },
    onSubmit: handleCreatePromotion,
    validationSchema: promotionSchema,
  });

  return (
    <div className={styles.root}>
      <h3>
        {intl.formatMessage({ id: title })}
      </h3>
      <form onSubmit={formik.handleSubmit}>
        <PromotionForm
          formik={formik}
          myCurrency={myCurrency}
        />
        <PromotionAttachProduct
          product={selectedProductData}
          addProduct={addProduct}
          removeProduct={removeProduct}
        />
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttons}>
            <SimpleButton
              decoration={SimpleButtonDecoration.Transparent}
              label={intl.formatMessage({ id: 'button.cancel' })}
              onClick={handleCancel}
            />
            <SimpleButton
              label={intl.formatMessage({ id: 'button.save' })}
              type={ButtonType.submit}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PromotionCreate;

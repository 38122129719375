import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

import { extractNumberFromNumericFormat } from 'Utils/numbers';
import { FeeStatus } from "Constants/enums";

const isPositive = (value) => +value >= 0;

const amountSchema = Yup
  .string()
  .test(
    'isPositive',
  <FormattedMessage id="validate.positiveNumber" />,
  (value) => isNil(value) || isPositive(extractNumberFromNumericFormat(value)),
  );

export const shippingFeesSchema = Yup.object({
  domesticFees: Yup.lazy((value) =>
    Yup.object(
      Object.keys(value || {}).reduce((acc, key) => {
        acc[key] = Yup.object({
          status: Yup.string(),
          flatRate: Yup.object({
            money: Yup.object({
              amount: amountSchema
                .when(`$domesticFees.${key}.freeShipping`, {
                  is: (freeShipping) => !freeShipping,
                  then: (field) => field.required(<FormattedMessage id="validate.required_field" />)
                })
            }),
          }),
          freeOver: Yup.object({
            money: Yup.object({
              amount: amountSchema,
            }),
          }),
          countryCode: Yup.string().required(<FormattedMessage id="validate.required_field" />),
        });
        return acc;
      }, {})
    )
  ),
  internationalFee: Yup.object({
    status: Yup.string(),
    flatRate: Yup.object({
      money: Yup.object({
        amount: amountSchema
          .when(['$internationalFee.status', '$internationalFee.freeShipping'], {
            is: (status, freeShipping) => status === FeeStatus.Active && !freeShipping,
            then: (field) => field.required(<FormattedMessage id="validate.required_field" />)
          })
      })
    }),
    freeOver: Yup.object({
      money: Yup.object({
        amount: amountSchema,
      })
    }),
    countryCode: Yup.string(),
  }),
});

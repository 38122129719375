import { string, func, bool } from 'prop-types';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

//  Components
import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';

const propTypes = {
  dismissName: string,
  submitName: string,
  onDismiss: func.isRequired,
  onSubmit: func.isRequired,
  disabled: bool,
  isLoading: bool,
  className: string,
  btnClassName: string,
};

const defaultProps = {
  dismissName: 'button.cancel',
  submitName: 'button.save',
  disabled: false,
  isLoading: false,
  className: '',
  btnClassName: '',
};

const CustomModalButtons = ({
  disabled,
  isLoading,
  dismissName,
  onDismiss,
  onSubmit,
  submitName,
  className,
  btnClassName,
}) => {
  const intl = useIntl();
  const translatedDismissName = intl.formatMessage({ id: dismissName });
  const translatedSubmitName = intl.formatMessage({ id: submitName });

  return (
    <div className={classnames('text-right mt-20 d-flex', className)}>
      <SimpleButton
        label={translatedDismissName}
        onClick={onDismiss}
        disabled={isLoading}
        decoration={SimpleButtonDecoration.Stroke}
      />
      <SimpleButton
        label={translatedSubmitName}
        onClick={onSubmit}
        disabled={disabled}
        isLoading={isLoading}
        className={classnames(btnClassName, 'ml-10')}
      />
    </div>
  );
};

CustomModalButtons.propTypes = propTypes;
CustomModalButtons.defaultProps = defaultProps;

export default CustomModalButtons;

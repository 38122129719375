import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { MenuItem, Popover } from '@mui/material';
import { logoutUserFromFirebase } from 'Store/auth/actions';
import { userDataSelector } from 'Store/settings/selectors';
import { resetSettings } from 'Store/settings/actions';
import { generateLink } from 'Utils/link';
import { COMPANY_PROFILE_ROUTE, USER_DETAILS_ROUTE } from 'Constants/routes';
import UserShortPreview from 'Components/UserShortPreview';
import { ThunkDispatchType } from 'Types/redux.types';
import { canSeeCompanyProfile } from 'Store/permissions/selectors';
import { hardResetClientReducer } from 'Store/client/actions';
import { authLoggedUserSelector } from 'Store/auth/selectors';
import cs from 'classnames';
import * as styles from './styles.module.scss';

const MyProfileMenu = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatchType>();
  const { myself, company } = useSelector(userDataSelector).data || {};
  const { email } = useSelector(authLoggedUserSelector).data || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenPopper = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (url: string) => () => {
    handleClose();
    navigate(url);
  };

  const handleLogout = () => {
    dispatch(logoutUserFromFirebase({ showNotification: true, clearPin: true }));
    dispatch(resetSettings());
    dispatch(hardResetClientReducer());
    navigate('/');
  };

  return (
    <div className={styles.profile}>
      <UserShortPreview
        onClick={handleOpenPopper}
        isReversed
        className={styles.user}
        name={myself?.name}
        avatarUrl={myself?.avatar}
        size="small"
      />

      {isOpen && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          slotProps={{ paper: { className: cs(styles.popperPaper) } }}
          onClose={handleClose}
        >
          <ul>
            <MenuItem className={styles.email}>
              {email}
            </MenuItem>
            <MenuItem onClick={handleNavigate(generateLink(USER_DETAILS_ROUTE, { userId: myself?.id }))}>
              {intl.formatMessage({ id: 'sidebar.myProfile' })}
            </MenuItem>
            {canSeeCompanyProfile && (
              <MenuItem onClick={handleNavigate(generateLink(COMPANY_PROFILE_ROUTE, { userId: company?.id }))}>
                {intl.formatMessage({ id: 'sidebar.myCompany' })}
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              {intl.formatMessage({ id: 'widgets.logOut' })}
            </MenuItem>
          </ul>
        </Popover>
      )}
    </div>
  );
};

export default MyProfileMenu;

import { Actions } from 'Store/companyUsersFilters/actions';
import { CompanyType, SearchMode, SortDirection } from 'Constants/enums';
import { CompanyUsersSortFields } from 'Constants/sort';
import { formatSortQueryProp } from 'Utils/sort';
import { SortAndSearch } from 'Types/sortAndSearch.type';
import { ByPageReq } from 'Types/byPageReq.interface';
import { CompanyUsersFilters } from 'Types/companyUsersFilters.interface';
import { AnyAction } from '@reduxjs/toolkit';

const lsLimitName = 'companyUsersLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

interface State {
  reqProps: SortAndSearch & ByPageReq;
  filters: CompanyUsersFilters;
  orgType: CompanyType | null;
}

const initialState: State = {
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp(CompanyUsersSortFields.CreatedDate, SortDirection.DESC),
    query: '',
  },
  filters: {
    country: [],
    searchMode: SearchMode.Default,
  },
  orgType: null,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.UpdateReqProps:
      if (payload.limit) {
        localStorage.setItem(lsLimitName, payload.limit);
      }
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload,
        },
      };

    case Actions.UpdateFilters:
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload.reqProps,
        },
        filters: {
          ...state.filters,
          ...payload.filters,
        },
      };

    case Actions.ResetFilters:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case Actions.ResetAll:
      return {
        ...state,
        ...initialState,
      };

    case Actions.UpdateOrgType:
      return {
        ...state,
        orgType: payload,
      };

    default:
      return state;
  }
};

export default reducer;

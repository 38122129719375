import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

import CustomInput from 'Components/CustomInput';

const NumberFormatCustom = forwardRef((props: any, ref: any) => {
  const preventNegativeNumbers = (e: any) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      thousandSeparator=","
      decimalSeparator="."
      onKeyDown={preventNegativeNumbers}
      customInput={CustomInput}
    />
  );
});

const NumberInput = ({
  InputProps,
  inputProps,
  isAllowed,
  decimalScale = 0,
  fixedDecimalScale = false,
  ...remainingProps
}: any) => (
  <NumberFormatCustom
    {...remainingProps}
    {...InputProps}
    {...inputProps}
    isAllowed={isAllowed}
    decimalScale={decimalScale}
    fixedDecimalScale={fixedDecimalScale}
  />
);

export default NumberInput;

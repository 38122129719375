import { useEffect, useState, FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LoaderOverlay from 'Components/LoaderOverlay';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useSelector } from 'react-redux';
import { isInitializedSelector } from 'Store/auth/selectors';
import { isEqual } from 'lodash';

interface ProtectedRoutesProps {
  redirectTo: string;
}

type isAuthState = boolean | null;

const ProtectedRoutes: FC<ProtectedRoutesProps> = ({ redirectTo }) => {
  const { auth } = firebase;
  const [isAuth, setIsAuth] = useState<isAuthState>(null);
  const isInitialized = useSelector(isInitializedSelector, isEqual);

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => setIsAuth(!!user));
    return unsubscribe;
  }, [auth]);

  if (isAuth === null) return <LoaderOverlay />;

  return isAuth && isInitialized ? <Navigate to={redirectTo} replace /> : <Outlet />;
};

export default ProtectedRoutes;

import { ThunkDispatchType } from 'Types/redux.types';
import { ReservationsFilter } from 'Types/reservation.interface';

export const ACTIONS = {
  SET_FILTERS: 'reservationsReducer/setFilters',
};

export const setFilters = (filters: ReservationsFilter) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_FILTERS, payload: filters });
};

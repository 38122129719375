import { FormattedMessage } from 'react-intl';
import LanguagesService from 'Services/languagesService';
import { notificationToast } from 'Utils/notificationToaster';
import { AppDispatch, RootState } from 'Types/redux.types';

export enum Actions {
  Loading = 'languages/Loading',
  Loaded = 'languages/Loaded',
  GetData = 'languages/GetData',
}

export const getLanguages = (isForceUpdate: boolean = false) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { languagesReducer: { isLoaded, isLoading } } = getState();

  if (isForceUpdate || (!isLoaded && !isLoading)) {
    dispatch({ type: Actions.Loading, payload: true });
    LanguagesService.getLanguages()
      .then((response) => {
        dispatch({
          type: Actions.GetData,
          payload: response?.list?.map(({ key, value }: { key: string; value: string }) => ({
            label: value,
            value: key,
          })) || [],
        });
      })

      .catch((err) => notificationToast.error(err.message, {
        title: <FormattedMessage id="notification.error.languages" />,
      }))

      .finally(() => {
        dispatch({ type: Actions.Loading, payload: false });
        dispatch({ type: Actions.Loaded, payload: true });
      });
  }
};

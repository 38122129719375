import { getDetailProduct } from 'Services/ProductService';
import { getBountyCommentsQuery } from 'Services/bounty/StatsService';

export const ACTIONS = {
  SET_IS_LOADING: 'productActions/setIsLoading',
  SET_PRODUCT_DETAILS: 'productActions/setProductDetails',
  SET_PRODUCT_REVIEWS: 'productActions/setProductReviews',
};

export const getProduct = ({ productId }) => async (dispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  try {
    const response = await getDetailProduct(productId);
    dispatch({ type: ACTIONS.SET_PRODUCT_DETAILS, payload: response });
  } finally {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

export const getProductReviews = ({ bountyId }) => (dispatch) => {
  getBountyCommentsQuery(bountyId)
    .on('value', (dataSnapshot) => {
      const bountyReviews = dataSnapshot.val() || {};
      dispatch({ type: ACTIONS.SET_PRODUCT_REVIEWS, payload: bountyReviews });
    });
};

export const clearProductDetails = () => (dispatch) => dispatch({ type: ACTIONS.SET_PRODUCT_DETAILS, payload: {} });

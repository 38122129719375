import { IdentityModeType, IdentityType } from 'Constants/enums';
import { IdentityMode } from 'Types/identityMode.interface';

const identityMode = {
  [IdentityModeType.ForcedAnon]: {
    forced: true,
    identityType: IdentityType.Anon,
  },
  [IdentityModeType.ForcedReal]: {
    forced: true,
    identityType: IdentityType.Real,
  },
  [IdentityModeType.Anon]: {
    forced: false,
    identityType: IdentityType.Anon,
  },
  [IdentityModeType.Real]: {
    forced: false,
    identityType: IdentityType.Real,
  },
};

const getIdentityType = (mode: IdentityMode) => {
  const type = mode ? mode.identityType : null;
  return getIdentityTypeAsEnum(type);
};

const getIdentityTypeAsEnum = (type: IdentityType|null) => {
  if (!type) {
    return IdentityType.Default;
  }

  return type;
};

const isComplete = (mode: IdentityMode) => mode.forced !== null && mode.forced !== undefined && mode.identityType !== IdentityType.Default;

const merge = (currentMode: IdentityMode, mode: IdentityMode) => {
  const curr = currentMode;

  if (curr.forced === null && mode.forced !== undefined) {
    curr.forced = mode.forced;
  }

  if (curr.forced === undefined) {
    curr.forced = null;
  }

  if (curr.identityType === IdentityType.Default) {
    curr.identityType = mode.identityType;
  }

  return curr;
};

const mergeAll = (identityModes: IdentityMode[]) => {
  let currentMode:IdentityMode = { forced: null, identityType: IdentityType.Default };

  identityModes.forEach((mode) => {
    if (isComplete(currentMode)) {
      return currentMode;
    }

    if (mode) {
      currentMode = merge(currentMode, mode);
    }
  });

  if (currentMode.identityType === IdentityType.Default) {
    currentMode.identityType = IdentityType.Anon;
  }

  return currentMode;
};

export {
  identityMode,
  getIdentityTypeAsEnum,
  getIdentityType,
  isComplete,
  merge,
  mergeAll,
};

import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

export const badgeCollectionSchema = Yup.object({
  title: Yup
    .string()
    .required(<FormattedMessage id="error.fieldIsRequired" />),
  reward: Yup.object({
    amount: Yup
      .number()
      .positive(<FormattedMessage id="error.positiveValue" />),
  }),
});

export const badgeSchema = Yup.object({
  label: Yup
    .string()
    .required(<FormattedMessage id="error.fieldIsRequired" />),
});

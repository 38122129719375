import { FormattedMessage } from 'react-intl';
import { SortBy, SortType } from 'Constants/enums';

const RECENT_DATA = {
  value: SortType.Recent,
  label: <FormattedMessage id="label.recent" />,
  sortListValue: SortBy.ListCreatedAt,
  sortTypeValue: SortBy.TypeCreatedAt,
};

const POPULAR_DATA = {
  value: SortType.Popular,
  label: <FormattedMessage id="label.popular" />,
  sortListValue: SortBy.ListPopularity,
  sortTypeValue: SortBy.TypePopularity,
};

const DISCUSSED_DATA = {
  value: SortType.Discussed,
  label: <FormattedMessage id="label.discussed" />,
  sortListValue: SortBy.ListDiscussed,
  sortTypeValue: SortBy.TypeDiscussed,
};

export const sortFilter = {
  defaultValue: RECENT_DATA,
  options: {
    [SortType.Recent]: RECENT_DATA,
    [SortType.Popular]: POPULAR_DATA,
    [SortType.Discussed]: DISCUSSED_DATA,
  },
};

export const sortKeys = [
  SortBy.CreatorUpdatedAt,
  SortBy.TypeCreatedAt,
  SortBy.TypePopularity,
  SortBy.TypeDiscussed,
  SortBy.ListCreatedAt,
  SortBy.ListPopularity,
  SortBy.ListDiscussed,
  SortBy.StreamCreatedAt,
  SortBy.StreamPopularity,
  SortBy.StreamDiscussed,
];

import { Area, Distribution } from 'Types/distribution.interface';

export const extractDistributionTargetList = (distribution: Distribution) => (
  distribution && distribution.areasList && distribution.areasList[0] && distribution.areasList[0].areas
    ? distribution.areasList[0].areas
    : []
);

export const extractDistributionTargetCodes = (areas: Area[]) => areas.map((item) => (item.code));

export const formatDistributionAreasList = (distributionList: Area[], exclude: boolean): Distribution => {
  const areas: Area[] = [];

  distributionList.forEach((area) => {
    if (area.exclude !== exclude) {
      areas.push({
        ...area,
        exclude,
      });
    } else {
      areas.push(area);
    }
  });

  return {
    areasList: [
      { areas },
    ],
  };
};

export const filterSelectedDistributionAreas = (distributionList: Area[], codes: string[]) => (
  distributionList.filter((item) => (codes.includes(item.code)))
);

export const formatSentTo = (distributionList: Area[]) => {
  const SPLIT_DELIMITER = '; ';
  const list: string[] = [];

  distributionList.forEach((item) => {
    list.push(item.text);
  });

  return { text: list.join(SPLIT_DELIMITER) };
};

// export const isEmpty = (areasList) => {
//   if (!areasList || (areasList && !Object.keys(areasList).length)) {
//     return true;
//   }
//
//   let isAreasListEmpty = true;
//
//   for (let i = 0; i < Object.keys(areasList).length; i++) {
//     if (areasList[i] && Object.keys(areasList[i]).length) {
//       isAreasListEmpty = false;
//       break;
//     }
//   }
//
//   return isAreasListEmpty;
// }

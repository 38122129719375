import { getMyUser } from 'Services/BaseService';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { User } from 'Types/user.interface';
import { Comment } from 'Types/comment.interface';

export const newComment = async (text: string, type: string, entityId: string) => {
  const creator = await getMyUser();

  return ({
    creator: creator || null,
    sentAt: new Date().getTime(),
    text: text || null,
    commentType: type.toString(),
    entityId,
  });
};

export const isMyComment = (creator: User) => {
  const me = firebaseGetCurrentUser();
  return !!(creator && creator.id && creator.id === me.uid);
};

export const getComments = (comments: Comment[]) => (comments ? comments.filter((comment) => comment.text) : []);
export const getRateInfo = (comments: Comment[]) => (comments ? comments.filter((comment) => !comment.text) : []);

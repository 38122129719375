import { FC, LegacyRef, ReactNode, useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateValidationError } from '@mui/x-date-pickers';
import { DATE_FORMAT, DISPLAYED_DATE_FORMAT } from 'Constants/common';
import CustomInput from 'Components/CustomInput';
import * as styles from './index.module.scss';

interface CustomDatePickerPicker {
  dataFormat?: string;
  displayedFormat?: string;
  disablePast?: boolean;
  minDate?: Date;
  onDateChange: (date: string | null) => any;
  name?: string;
  selectedDate?: string | null;
  validationErrors?: string[] | ReactNode[];
}

type BrowserInputProps = TextFieldProps & {
  ownerState?: any;
};

const CustomTextInput = (props: BrowserInputProps) => {
  const { inputProps, InputProps, inputRef, ...other } = props;
  delete other?.error;
  delete other?.focused;
  delete other?.ownerState;

  return (
    <div className={styles.picker} ref={InputProps?.ref as LegacyRef<HTMLDivElement>}>
      <CustomInput ref={inputRef} {...inputProps} className={styles.control} validationErrors={inputProps?.validationErrors} {...(other as any)} />
      <span className={styles.icon}>{InputProps?.endAdornment}</span>
    </div>
  );
};

const CustomDatePicker: FC<CustomDatePickerPicker> = ({
  onDateChange, dataFormat = DATE_FORMAT, displayedFormat = DISPLAYED_DATE_FORMAT,
  selectedDate, validationErrors = [], ...remainingProps
}) => {
  const [dateObj, setDateObj] = useState<Date | null>(null);

  useEffect(() => {
    setDateObj(selectedDate ? new Date(parse(selectedDate, dataFormat, new Date())) : null);
  }, [selectedDate]);

  const handleChange = (date: Date | null, error: { validationError: DateValidationError}) => {
    const newDateObj = error?.validationError === 'invalidDate' ? null : date;
    onDateChange(newDateObj ? format(newDateObj, dataFormat) : null);
  };

  return (
    <DatePicker
      {...remainingProps}
      format={displayedFormat}
      value={dateObj}
      onChange={handleChange}
      slots={{
        textField: CustomTextInput,
      }}
      slotProps={{
        textField: {
          inputProps: {
            validationErrors,
          },
        },
      }}
    />
  );
};

export default CustomDatePicker;

import { isRebounty } from '../../utils/bounty.tsx';

export function adjustOriginRebounties({ bounty }) {
  // if rebounty, add/remove record from origin.rebounties
  if (isRebounty(bounty)) {
    // TODO: add implementation when we'll work on rebounties
  }
}

export const getOwnerId = (bounty) => (
  bounty.owner && bounty.owner.id ? bounty.owner.id : null
);

import { VoucherType } from 'Constants/enums';
import { Voucher } from 'Types/voucher.interface';

export function isValid(voucher: Voucher) {
  return voucher.expiresAt > new Date().getTime();
}

export function isIntrinsic(voucher: Voucher) {
  return voucher.type === VoucherType.Intrinsic;
}

export function negateVoucher(voucher: Voucher) {
  return {
    ...voucher,
    negative: voucher.negative === null || !voucher.negative ? true : null,
  };
}

export function voucherToString({ type, id }: Voucher) {
  return `${type}:${id}`;
}

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { getApplicationConfig } from 'Utils/appConfig';
import { StrictMode } from 'react';

import packageJson from '../package.json';
import App from './App';
import { ApplicationConfig } from './types/applicationConfig.type';

// Instantiate Error Tracking
if (window && import.meta.env.PROD) {
  const { SENTRY_CONFIG }: ApplicationConfig = getApplicationConfig();
  Sentry.init({
    release: `${packageJson.name}@${packageJson.version}`,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    ...SENTRY_CONFIG,
  });

  Sentry.getCurrentScope().setTag('environment', import.meta.env.PROD);
}

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}

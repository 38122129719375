import { string } from 'prop-types';
import Radio from '@mui/material/Radio';
import * as styles from './styles.module.scss';

const RadioStyled = ({ className = '', ...otherProps }) => (
  <Radio
    {...otherProps}
    className={`${styles.radioStyled} ${className}`}
  />
);

RadioStyled.propTypes = {
  className: string,
};

RadioStyled.defaultProps = {
  className: '',
};

export default RadioStyled;

export const NONE = {
  name: 'NONE',
  enabledByDefault: false,
};

export const ALL = {
  name: 'ALL',
  enabledByDefault: false,
};

export const CREATE_COMMENT = {
  name: 'CREATE_COMMENT',
  enabledByDefault: true,
};

export const EDIT_COMMENT = {
  name: 'EDIT_COMMENT',
  enabledByDefault: false,
};

export const REJECT_CONTENT = {
  name: 'REJECT_CONTENT',
  enabledByDefault: false,
};

export const PROMOTE_COMMENT_AS_REPLY = {
  name: 'PROMOTE_COMMENT_AS_REPLY',
  enabledByDefault: false,
};

export const CREATE_TOKEN_PACK = {
  name: 'CREATE_TOKEN_PACK',
  enabledByDefault: false,
};

export const CREATE_BOUNTY = {
  name: 'CREATE_BOUNTY',
  enabledByDefault: true,
};

export const PROFILE_RATINGS = {
  name: 'PROFILE_RATINGS',
  enabledByDefault: false,
};

export const CREATE_ORGANIZATION = {
  name: 'CREATE_ORGANIZATION',
  enabledByDefault: false,
};

export const CREATE_BOUNTY_TAG_AA = {
  name: 'CREATE_BOUNTY_TAG_AA',
  enabledByDefault: true,
};

export const CREATE_BOUNTY_TAG_BB = {
  name: 'CREATE_BOUNTY_TAG_BB',
  enabledByDefault: false,
};

export const CREATE_BOUNTY_TAG_CC = {
  name: 'CREATE_BOUNTY_TAG_CC',
  enabledByDefault: false,
};

export const DISMISS_BOUNTY = {
  name: 'DISMISS_BOUNTY',
  enabledByDefault: true,
};

export const CONTROL_BOUNTY_DISTRIBUTION = {
  name: 'CONTROL_BOUNTY_DISTRIBUTION',
  enabledByDefault: false,
};

export const CHANGE_RATING_VALUE = {
  name: 'CHANGE_RATING_VALUE',
  enabledByDefault: false,
};

export const CHANGE_BOUNTY_LIST = {
  name: 'CHANGE_BOUNTY_LIST',
  enabledByDefault: false,
};

export const CREATE_RESPONSE = {
  name: 'CREATE_RESPONSE',
  enabledByDefault: true,
};

export const CREATE_ATTACHMENTS = {
  name: 'CREATE_ATTACHMENTS',
  enabledByDefault: true,
};

export const ATTACH_REWARD = {
  name: 'ATTACH_REWARD',
  enabledByDefault: false,
};

export const EDIT_CAPS = {
  name: 'EDIT_CAPS',
  enabledByDefault: false,
};

export const UPDATE_ACCOUNT = {
  name: 'UPDATE_ACCOUNT',
  enabledByDefault: false,
};

export const SEE_REAL_IDENTITY = {
  name: 'SEE_REAL_IDENTITY',
  enabledByDefault: false,
};

export const CTRL_IDENTITY = {
  name: 'CTRL_IDENTITY',
  enabledByDefault: false,
};

export const PIN_CONTENT = {
  name: 'PIN_CONTENT',
  enabledByDefault: false,
};

export const EDIT_BADGES = {
  name: 'EDIT_BADGES',
  enabledByDefault: false,
};

export const CREATE_BADGE = {
  name: 'CREATE_BADGE',
  enabledByDefault: false,
};

export const INVITE_MEMBERS = {
  name: 'INVITE_MEMBERS',
  enabledByDefault: false,
};

export const CAN_BLACKLIST = {
  name: 'CAN_BLACKLIST',
  enabledByDefault: false,
};

export const CREATE_GROUP = {
  name: 'CREATE_GROUP',
  enabledByDefault: false,
};

export const ADMIN_GROUP = {
  name: 'ADMIN_GROUP',
  enabledByDefault: false,
};

export const MANAGE_DEVICES = {
  name: 'MANAGE_DEVICES',
  enabledByDefault: false,
};

export const SEE_BUY_SELL = {
  name: 'SEE_BUY_SELL',
  enabledByDefault: false,
};

export const PAYMENT = {
  name: 'PAYMENT',
  enabledByDefault: true,
};

export const SEARCH = {
  name: 'SEARCH',
  enabledByDefault: true,
};

export const SETTINGS = {
  name: 'SETTINGS',
  enabledByDefault: true,
};

export const SEE_ADDRESSES = {
  name: 'SEE_ADDRESSES',
  enabledByDefault: false,
};

export const SEE_MARKET = {
  name: 'SEE_MARKET',
  enabledByDefault: false,
};

export const SEE_LIKE = {
  name: 'SEE_LIKE',
  enabledByDefault: false,
};

export const SEE_FAVORITES = {
  name: 'SEE_FAVORITES',
  enabledByDefault: false,
};

export const DEMO = {
  name: 'DEMO',
  enabledByDefault: false,
};

export const REQUEST_ROR = {
  name: 'REQUEST_ROR',
  enabledByDefault: false,
};

export const CANCEL_ROR = {
  name: 'CANCEL_ROR',
  enabledByDefault: false,
};

export const SEE_LEADERBOARD = {
  name: 'SEE_LEADERBOARD',
  enabledByDefault: false,
};

export const SEE_DASHBOARD = {
  name: 'SEE_DASHBOARD',
  enabledByDefault: false,
};

export const SEE_REPORTS = {
  name: 'SEE_REPORTS',
  enabledByDefault: false,
};

export const CREATE_PROMOTION = {
  name: 'CREATE_PROMOTION',
  enabledByDefault: false,
};

export const SEND_NOTIFICATIONS = {
  name: 'SEND_NOTIFICATIONS',
  enabledByDefault: false,
};

export const EDIT_COMPANY = {
  name: 'EDIT_COMPANY',
  enabledByDefault: false,
};

export const VOUCHERS = {
  name: 'VOUCHERS',
  enabledByDefault: false,
};

export const CAN_REWARD_MONEY = {
  name: 'CAN_REWARD_MONEY',
  enabledByDefault: false,
};

export const CAN_NEGOTIATE_PRICE = {
  name: 'CAN_NEGOTIATE_PRICE',
  enabledByDefault: false,
};

export const SEE_GROUP_EVERYBODY = {
  name: 'SEE_GROUP_EVERYBODY',
  enabledByDefault: true,
};

export const SEE_ACCOUNT = {
  name: 'SEE_ACCOUNT',
  enabledByDefault: false,
};

export const SEE_PRIVATE_INFO = {
  name: 'SEE_PRIVATE_INFO',
  enabledByDefault: false,
};

export const SEE_COMPANIES = {
  name: 'SEE_COMPANIES',
  enabledByDefault: false,
};

export const SEE_COMPANY = {
  name: 'SEE_COMPANY',
  enabledByDefault: false,
};

export const SEE_RESPONSES = {
  name: 'SEE_RESPONSES',
  enabledByDefault: false,
};

export const CONTROL_RESPONSES = {
  name: 'CONTROL_RESPONSES',
  enabledByDefault: false,
};

export const SEE_RECV_ORDERS = {
  name: 'SEE_RECV_ORDERS',
  enabledByDefault: false,
};

export const SEE_GROUPS = {
  name: 'SEE_GROUPS',
  enabledByDefault: false,
};

export const CONTROL_KYC = {
  name: 'CONTROL_KYC',
  enabledByDefault: false,
};

export const SEE_APPLICANTS = {
  name: 'SEE_APPLICANTS',
  enabledByDefault: false,
};

export const CAN_GHOSTWRITE = {
  name: 'CAN_GHOSTWRITE',
  enabledByDefault: false,
};

export const MANAGE_CAMPAIGN = {
  name: 'MANAGE_CAMPAIGN',
  enabledByDefault: false,
};

export const SEE_SEARCH_MODE_FILTER = {
  name: 'SEE_SEARCH_MODE_FILTER',
  enabledByDefault: false,
};

export const SEE_EMPLOYEES = {
  name: 'SEE_EMPLOYEES',
  enabledByDefault: false,
};

export const CAN_HIGHLIGHT_RESPONSE = {
  name: 'CAN_HIGHLIGHT_RESPONSE',
  enabledByDefault: false,
};

export const CAN_ARCHIVE_CHAT = {
  name: 'CAN_ARCHIVE_CHAT',
  enabledByDefault: false,
};

export const CAN_ATTACH_PRODUCT = {
  name: 'CAN_ATTACH_PRODUCT',
  enabledByDefault: false,
};

export const ASSIGN_ROLES = {
  name: 'ASSIGN_ROLES',
  enabledByDefault: false,
};

export const EDIT_USER_PROFILE = {
  name: 'EDIT_USER_PROFILE',
  enabledByDefault: false,
};

export const CONTROL_PIN = {
  name: 'CONTROL_PIN',
  enabledByDefault: false,
};

export const CONTROL_REFERRAL_CODE = {
  name: 'CONTROL_REFERRAL_CODE',
  enabledByDefault: false,
};

export const CAN_HAVE_MANY_INVITE_LINKS = {
  name: 'CAN_HAVE_MANY_INVITE_LINKS',
  enabledByDefault: false,
};

export const SHELL_ACCESS = {
  name: 'SHELL_ACCESS',
  enabledByDefault: false,
};

export const UNASSIGN_EMPLOYEE = {
  name: 'UNASSIGN_EMPLOYEE',
  enabledByDefault: false,
};

export const DELETE_COMPANIES = {
  name: 'DELETE_COMPANIES',
  enabledByDefault: false,
};

export const SEE_ANALYTICS = {
  name: 'SEE_ANALYTICS',
  enabledByDefault: false,
};

export const SEE_USER_MANAGEMENT = {
  name: 'SEE_USER_MANAGEMENT',
  enabledByDefault: false,
};

export const SEE_JOBS = {
  name: 'SEE_JOBS',
  enabledByDefault: false,
};

export const SEE_AI_BOT = {
  name: 'SEE_AI_BOT',
  enabledByDefault: false,
};

export const SEE_DOC_SEARCH = {
  name: 'SEE_DOC_SEARCH',
  enabledByDefault: false,
};

export const CAN_RESYNC_PRODUCTS = {
  name: 'CAN_RESYNC_PRODUCTS',
  enabledByDefault: false,
};

export const CAN_REPORT_USER = {
  name: 'CAN_REPORT_USER',
  enabledByDefault: false,
};

export const CAN_SEND_MESSAGES = {
  name: 'CAN_SEND_MESSAGES',
  enabledByDefault: false,
};

export const SEE_USERS = {
  name: 'SEE_USERS',
  enabledByDefault: false,
};

export const SEND_INVITES = {
  name: 'SEND_INVITES',
  enabledByDefault: false,
};

export const CAN_CALL_OUT = {
  name: 'CAN_CALL_OUT',
  enabledByDefault: false,
};

export const SEE_FOLLOWERS = {
  name: 'SEE_FOLLOWERS',
  enabledByDefault: false,
};

export const SEE_FOLLOWING = {
  name: 'SEE_FOLLOWING',
  enabledByDefault: false,
};

export const NEEDS_SIGNUP_AGE_RANGE = {
  name: 'NEEDS_SIGNUP_AGE_RANGE',
  enabledByDefault: false,
};

export const NEEDS_SIGNUP_INTEREST = {
  name: 'NEEDS_SIGNUP_INTEREST',
  enabledByDefault: false,
};

export const LEGAL = {
  name: 'user_legal',
  enabledByDefault: false,
};

export const op = [
  SEE_USERS,
  SEND_INVITES,
  CREATE_COMMENT,
  EDIT_COMMENT,
  REJECT_CONTENT,
  PROMOTE_COMMENT_AS_REPLY,
  CREATE_BOUNTY,
  CREATE_BOUNTY_TAG_AA,
  CREATE_BOUNTY_TAG_BB,
  CREATE_BOUNTY_TAG_CC,
  DISMISS_BOUNTY,
  CONTROL_BOUNTY_DISTRIBUTION,
  CHANGE_RATING_VALUE,
  CHANGE_BOUNTY_LIST,
  CREATE_RESPONSE,
  CREATE_ATTACHMENTS,
  ATTACH_REWARD,
  EDIT_CAPS,
  UPDATE_ACCOUNT,
  SEE_REAL_IDENTITY,
  CTRL_IDENTITY,
  PIN_CONTENT,
  EDIT_BADGES,
  INVITE_MEMBERS,
  CREATE_GROUP,
  ADMIN_GROUP,
  MANAGE_DEVICES,
  SEE_BUY_SELL,
  PAYMENT,
  SEARCH,
  SETTINGS,
  SEE_ADDRESSES,
  SEE_MARKET,
  SEE_LIKE,
  SEE_FAVORITES,
  SEE_LEADERBOARD,
  CREATE_PROMOTION,
  SEND_NOTIFICATIONS,
  VOUCHERS,
  CAN_NEGOTIATE_PRICE,
  SEE_GROUP_EVERYBODY,
  SEE_ACCOUNT,
  SEE_COMPANIES,
  EDIT_COMPANY,
  SEE_RESPONSES,
  CONTROL_RESPONSES,
  SEE_RECV_ORDERS,
  SEE_GROUPS,
  CONTROL_KYC,
  CAN_GHOSTWRITE,
  MANAGE_CAMPAIGN,
  SEE_SEARCH_MODE_FILTER,
  SEE_EMPLOYEES,
  CAN_HIGHLIGHT_RESPONSE,
  CAN_ARCHIVE_CHAT,
  CAN_BLACKLIST,
  CAN_ATTACH_PRODUCT,
  ASSIGN_ROLES,
  EDIT_USER_PROFILE,
  CONTROL_PIN,
  CONTROL_REFERRAL_CODE,
  SEE_JOBS,
  CAN_REPORT_USER,
  CAN_SEND_MESSAGES,
  CAN_HAVE_MANY_INVITE_LINKS,
  CAN_CALL_OUT,
  CAN_RESYNC_PRODUCTS,
  SEE_FOLLOWERS,
  SEE_FOLLOWING,
  NEEDS_SIGNUP_AGE_RANGE,
  NEEDS_SIGNUP_INTEREST,
  LEGAL,
];

import { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import cs from 'classnames';
import { Dialog } from '@mui/material';

import SimpleButton from 'Components/SimpleButton';
import { confirmationModalSelector } from 'Store/confirmationModal/selectors';
import { hideConfirmationModal } from 'Store/confirmationModal/actions';

import { getConfirmationModalTitleTranslationKey } from 'Utils/translationKeyFormatters';
import { ThunkDispatchType } from 'Types/redux.types';
import * as notifications from 'Assets/scss/modules/notifications.module.scss';
import * as styles from './index.module.scss';

const ConfirmationModal = () => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const { status, message, title, open, autoCLoseTimer } = useSelector(confirmationModalSelector);

  const statusClassName = `${status}Type`;

  // TODO: find proper type from material for close reason
  const handleClose = (event: MouseEvent, reason?: 'escapeKeyDown' | 'backdropClick') => {
    if (reason && reason === 'backdropClick') {
      return null;
    }
    dispatch(hideConfirmationModal());
  };

  useEffect(() => {
    if (autoCLoseTimer && open) {
      setTimeout(() => {
        dispatch(hideConfirmationModal());
      }, autoCLoseTimer);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="notification-title"
      aria-describedby="notification-message"
      maxWidth="xs"
      fullWidth
      className={styles.notificationRootOverride}
    >
      <div className={cs(notifications.notificationContainer, notifications[statusClassName])}>
        <span className={cs(notifications.statusIcon, notifications[statusClassName])} />
        <button
          type="button"
          aria-label="close"
          onClick={handleClose}
          className={`icon-add ${notifications.closeButton}`}
        />
        <h2
          id="notification-title"
          className={notifications.notificationTitle}
        >
          {title || intl.formatMessage({ id: getConfirmationModalTitleTranslationKey(status) })}
        </h2>
        {!!message && (
          <div
            id="notification-message"
            className={notifications.notificationMessage}
          >
            {message}
          </div>
        )}

        <div className={notifications.notificationActions}>
          <SimpleButton
            label={intl.formatMessage({ id: 'button.ok' })}
            onClick={handleClose}
            className={notifications.button}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;

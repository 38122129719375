import { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import classnames from 'classnames';
import { Tooltip } from '@mui/material';
import { flagCodes } from 'Constants/flagCodes';
import * as styles from './index.module.scss';

interface CountryPreviewProps {
  className?: string;
  countryCode?: string | null;
  isCountryCodeLabel?: boolean;
  iconOnly?: boolean;
  tooltip?: boolean;
}

const CountryPreview: FC<CountryPreviewProps> = ({ className = '', countryCode, isCountryCodeLabel = false, iconOnly = false, tooltip = false, ...rest }) => {
  if (!countryCode) return null;

  const intl = useIntl();
  const displayedCountryCode = countryCode.toUpperCase();
  const translatedCountry = intl.formatMessage({ id: `country-${displayedCountryCode}` });
  const text = isCountryCodeLabel ? displayedCountryCode : translatedCountry;

  const icon = useMemo(
    () =>
      (flagCodes.includes(displayedCountryCode)
        ? <FlagIcon className={styles.imgIcon} code={displayedCountryCode as FlagIconCode} size={16} {...rest} /> : null),
    [countryCode],
  );

  if (tooltip) {
    return (
      <Tooltip title={translatedCountry} placement="top">
        <div
          data-qa="countyPreview"
          data-qa-value={displayedCountryCode}
          className={classnames(styles.countryPreview, { [className]: !!className })}
          style={{ cursor: 'pointer' }}
        >
          {icon}
          {!iconOnly && text}
        </div>
      </Tooltip>
    );
  }

  if (iconOnly) return icon;

  return (
    <div
      data-qa="countyPreview"
      data-qa-value={displayedCountryCode}
      className={classnames(styles.countryPreview, { [className]: !!className })}
      title={translatedCountry}
    >
      {icon}
      {text}
    </div>
  );
};

export default memo(CountryPreview);

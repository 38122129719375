import { FC, useMemo } from 'react';
import pdfPlaceholder from 'Assets/img/general/pdf-placeholder.png';

interface PdfProps {
  className?: string;
  width?: string|number;
  height?: string|number;
  url?: string;
}

const Pdf: FC<PdfProps> = ({
  className = '',
  width = '100%',
  url,
  height,
  ...remainingProps
}) => {
  const isValidUrl = useMemo(() => {
    try {
      return url ? Boolean(new URL(url)) : false;
    } catch {
      return false;
    }
  }, [url]);

  if (isValidUrl) {
    return (
      <embed
        {...remainingProps}
        type="application/pdf"
        src={`${url}#pagemode=zoom&scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0`}
        width={width}
        height={height || '130%'}
        className={`embed-pdf ${className}`}
      />
    );
  }

  return (
    <img
      src={pdfPlaceholder}
      alt="cropped_img"
    />
  );
};

export default Pdf;

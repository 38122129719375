import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectProductCard from 'Components/SelectProductCard';

import { getPublicProducts } from 'Services/ProductService';
import { fetchBounty } from 'Services/bounty/BountyService';

import { AttachmentAction, AttachmentMeaning, AttachmentType, BountyType, CompanyType } from 'Constants/enums';
import { closeModal, openModal } from 'Store/modal/actions';
import { productPickModal } from 'Constants/modals';
import { companySettingsSelector, userDataSelector } from 'Store/settings/selectors';
import { Bounty } from 'Types/bounty.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import { DEFAULT_RECOMMENDATIONS_COUNT } from 'Constants/banners';
import { isEmpty } from 'lodash';
import { attachmentsListSelector } from 'Store/attachments/selectors';
import { setInlineAttachments, updateAttachment } from 'Store/attachments/actions';
import { Attachment } from 'Types/attachment.interface';
import { notificationToast } from 'Utils/notificationToaster';
import * as styles from './index.module.scss';

interface RecommendationsBannerProps {
  selectedCompanyId?: string;
  bounty: Bounty;
}

const RecommendationsBanner: FC<RecommendationsBannerProps> = ({
  selectedCompanyId,
  bounty,
}) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const userData = useSelector(userDataSelector)?.data || {};
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const [selectedProducts, setSelectedProducts] = useState<Bounty[]>([]);
  const attachments: Attachment[] = useSelector(attachmentsListSelector) || [];

  const isMerchant = currentOrgType === CompanyType.Merchant;
  const companyId = isMerchant ? userData?.company?.id : selectedCompanyId;

  useEffect(() => {
    const remainingCount = DEFAULT_RECOMMENDATIONS_COUNT - selectedProducts?.length;

    if (remainingCount !== 0) {
      setSelectedProducts([...selectedProducts, ...Array(remainingCount).fill({})]);
    }
  }, [selectedProducts]);

  useEffect(() => {
    const existingAttachments = bounty?.attachments?.attachments || {};
    const action = { actionType: AttachmentAction.OpenProduct };
    const defaultAttachment = { meaning: AttachmentMeaning.Promotion, type: AttachmentType.NotFile, action };

    if (!isEmpty(existingAttachments)) {
      const attachmentsList = Object.values(existingAttachments);
      const remainingCount = DEFAULT_RECOMMENDATIONS_COUNT - attachmentsList?.length;

      dispatch(setInlineAttachments([...attachmentsList, ...Array(remainingCount).fill(defaultAttachment)]));

      fetchSelectedProducts();
    } else {
      dispatch(setInlineAttachments(Array(DEFAULT_RECOMMENDATIONS_COUNT).fill(defaultAttachment)));
    }
  }, [bounty]);

  const fetchSelectedProducts = async () => {
    try {
      const promises: Promise<Response>[] = [];

      if (bounty?.attachments) {
        Object.values(bounty?.attachments?.attachments).forEach((attachment) => {
          promises.push(fetchBounty(attachment?.action?.bountyIds, null, BountyType.Product));
        });
      }

      const products = await Promise.all(promises);
      setSelectedProducts(products);
    } catch (error: any) {
      notificationToast.error(error.message);
    }
  };

  const getProducts = (
    filters?: { start: number; length: number },
  ): Promise<{ list: Bounty[], totalCount: number }> => (
    getPublicProducts(filters ? { ...filters, companyId } : { companyId })
  );

  const handleCloseModal = () => dispatch(closeModal());

  const handleSelectProduct = (bounty: Bounty, index: number) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index] = bounty;
    setSelectedProducts(updatedProducts);
    handleCloseModal();

    const imageUrl = bounty?.product?.imageUrl;
    const attach = {
      ...attachments[index],
      ...(imageUrl ? { url: imageUrl } : {}),
      action: {
        ...attachments[index]?.action,
        bountyIds: bounty?.id,
        bountyType: BountyType.Product,
      },
    };

    dispatch(updateAttachment(attach, index));
  };

  const handleOpenProducts = (attachmentIndex: number) => {
    dispatch(openModal(productPickModal({
      getProducts,
      handleSelectProduct: (bounty: Bounty) => handleSelectProduct(bounty, attachmentIndex),
      closeModal: handleCloseModal,
      selectedProducts,
    })));
  };

  const handleRemoveProduct = (index: number) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index] = {} as Bounty;
    setSelectedProducts(updatedProducts);

    const attach = {
      ...attachments[index],
      action: { actionType: AttachmentAction.OpenProduct },
    };
    dispatch(updateAttachment(attach, index));
  };

  return (
    <div className={styles.root}>
      {selectedProducts?.length > 0 && selectedProducts.map(({ product }, index) => (
        <SelectProductCard
          onSelectProduct={() => handleOpenProducts(index)}
          onRemoveProduct={() => handleRemoveProduct(index)}
          product={product}
          index={index}
          hasTitle
        />
      ))}
    </div>
  );
};

export default RecommendationsBanner;

import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import * as styles from './index.module.scss';

interface Props {
  text?: string;
  translationKey?: string;
  values?: { [key: string]: string | number | ((chunks?: any) => ReactNode) };
}

const TooltipButton: FC<Props> = ({ text = '', translationKey, values = {} }) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={translationKey ? intl.formatMessage({ id: translationKey }, values) : text}
      classes={{ tooltip: styles.tooltip }}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <i className={`icon-info-circle ${styles.tooltipAnchor}`} />
    </Tooltip>
  );
};

export default TooltipButton;

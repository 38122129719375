import * as Yup from 'yup';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { RewardType } from 'Constants/enums';

const isCustomCurrencyValid = function () { // eslint-disable-line
  const currencyType = get(this?.from[0]?.value, 'currencyType');
  const customCurrency = get(this?.from[0]?.value, 'customCurrency');

  return currencyType && [RewardType.Both, RewardType.Either, RewardType.Points].includes(currencyType)
    ? !!customCurrency
    : true;
};

function isMoneyValid (money) {
  const currency = get(this.from[1].value, 'currencyType');
  const points = get(this.parent, 'points');

  if (currency === RewardType.Both) {
    return !!money;
  }

  if (currency === RewardType.Either && (points || money)) {
    return true;
  }

  return true;
}

function isPointsValid (points) {
  const currency = get(this.from[1].value, 'currencyType');
  const money = get(this.parent, 'money');

  if ([RewardType.Both, RewardType.Points].includes(currency)) {
    return !!points;
  }

  if (currency === RewardType.Either && (money || points)) {
    return true;
  }

  return true;
}

function isMainAmountValid (value) {
  const currency = get(this.from[1].value, 'currencyType');

  if ([RewardType.Both, RewardType.Either, RewardType.Points].includes(currency)) {
    return true;
  }

  return !!value;
}

const defaultProductSchema = {
  name: Yup
    .string()
    .required(<FormattedMessage id="error.fieldIsRequired" />),
  description: Yup
    .string()
    .required(<FormattedMessage id="error.fieldIsRequired" />),
  merchantSku: Yup
    .string()
    .required(<FormattedMessage id="error.fieldIsRequired" />),
  msrp: Yup.object({
    main: Yup
      .string()
      .nullable()
      .test(
        'hasRequiredAmount',
      <FormattedMessage id="error.fieldIsRequired" />,
      isMainAmountValid,
      ),
    money: Yup
      .string()
      .nullable()
      .test(
        'hasRequiredAmount',
      <FormattedMessage id="error.fieldIsRequired" />,
      isMoneyValid,
      ),
    points: Yup
      .string()
      .nullable()
      .test(
        'hasRequiredAmount',
      <FormattedMessage id="error.fieldIsRequired" />,
      isPointsValid,
      ),
  }),
  price: Yup.object({
    main: Yup
      .string()
      .nullable()
      .test(
        'hasRequiredAmount',
      <FormattedMessage id="error.fieldIsRequired" />,
      isMainAmountValid,
      ),
    money: Yup
      .string()
      .nullable()
      .test(
        'hasRequiredAmount',
      <FormattedMessage id="error.fieldIsRequired" />,
      isMoneyValid,
      ),
    points: Yup
      .string()
      .nullable()
      .test(
        'hasRequiredAmount',
      <FormattedMessage id="error.fieldIsRequired" />,
      isPointsValid,
      ),
  }),
};

export const productSchema = Yup.object(defaultProductSchema);

export const campaignsProductSchema = Yup.object({
  ...defaultProductSchema,
  currencyType: Yup
    .string()
    .required(),
  customCurrency: Yup
    .string()
    .test(
      'hasCustomCurrency',
    <FormattedMessage id="validate.selectACustomToken" />,
    isCustomCurrencyValid,
    ),
});

export const ticketSchema = productSchema.concat(Yup.object({
  onlineProductInfo: Yup.object({
    useQty: Yup
      .number()
      .nullable()
      .positive(<FormattedMessage id="error.enterAnumber" />)
      .when('unlimitedUseQty', {
        is: (unlimitedUseQty) => !unlimitedUseQty,
        then: (field) => field
          .nullable()
          .required(<FormattedMessage id="error.fieldIsRequired" />),
      }),
  }),
}));

export const tokenSchema = productSchema.concat(Yup.object({
  onlineProductInfo: Yup.object({
    tokensQty: Yup
      .number()
      .positive(<FormattedMessage id="error.enterAnumber" />)
      .required(<FormattedMessage id="error.fieldIsRequired" />),
  }),
}));

export const reviewProductSchema = Yup.object({
  reviewDescription: Yup
    .string()
    .required(<FormattedMessage id="error.fieldIsRequired" />),
});

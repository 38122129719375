import { AnyAction } from '@reduxjs/toolkit';
import { Actions } from 'Store/call/actions';

interface State {
  activeCallNumber: string | null;
  activeCallName: string | null;
  isDeviceInitialized: boolean;
}

const initialState: State = {
  activeCallNumber: null,
  activeCallName: null,
  isDeviceInitialized: false,
};

// TODO: fix actions types
const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.DeviceInit:
      return { ...initialState, isDeviceInitialized: true };

    case Actions.CallStart:
      return { ...state, activeCallNumber: payload.phoneNumber, activeCallName: payload.name };

    case Actions.CallEnd:
      return { ...state, activeCallNumber: null, activeCallName: null };

    default:
      return state;
  }
};

export default reducer;

import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TerminalContextProvider } from 'react-terminal';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import NotificationToaster from 'Components/NotificationToaster';

// Others
import {
  LOGGED_OUT_PROFILE_ROUTE,
  MERCHANT_LANDING_ROUTE,
  MERCHANT_SIGNUP_ROUTE,
  SIGN_IN_ROUTE,
  V2_HOME_ROUTE,
  SIGN_UP_ROUTE,
  EMPLOYER_ROUTE,
  EMPLOYER_AUTH_ROUTE,
  EMPLOYER_SUCCESS_ROUTE,
  MERCHANT_REGISTER_COMPANY_ROUTE,
  MERCHANT_URL_COMPANY_ROUTE,
  INVITATION_ROUTE,
  USER_SHOP_POLICIES,
  VISIT_ROUTE,
  ADMIN_LOGIN_ROUTE,
} from 'Constants/routes';
import { getApplicationConfig } from 'Utils/appConfig';

// CSS
import 'Assets/customCss';

// Firebase
import 'Utils/firebase';

// Components
import store from 'Store';
import InterfaceLocaleProvider from 'Containers/InterfaceLocaleProvider';
import ShopHolder from 'Containers/ShopHolder';
import ConfirmationModal from 'Components/ConfirmationModal';
import CustomModal from 'Components/CustomModal';
import useAppVersionCheck from './hooks/useAppVersionCheck';
import ProtectedRoutes from './routes/components/ProtectedRoutes';
import Loadable from './routes/configs/Suspense';
import {
  AsyncMerchantLanding,
  AsyncMerchantSignup,
  AsyncDefaultUserProfilePage,
  AsyncEmployerPage,
  AsyncEmployerAuth,
  AsyncEmployerSuccess,
  AsyncMerchantRegisterCompany,
  AsyncMerchantUrlCompany,
  AsyncInvitationPage,
  AsyncShopPolicyDetailsPage,
  AsyncVisitApp,
  AsyncAdminLoginPage,
  AsyncAuthenticationPage,
} from './routes/configs/AsyncRoutes';
import RoutesContainer from './Routes';
import ErrorBoundary from './ErrorBoundary';
import LanguagesProvider from './contexts/languagesContext';
import { ApplicationConfig } from './types/applicationConfig.type';

import EmployerProvider from './contexts/employerContext';

const { title }: ApplicationConfig = getApplicationConfig();

const MainApp = () => {
  const { isOldVersion, clearCache, error } = useAppVersionCheck();

  useEffect(() => {
    if (isOldVersion) {
      clearCache();
    }
  }, [isOldVersion]);

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <Provider store={store}>
      <InterfaceLocaleProvider>
        <ErrorBoundary>
          <TerminalContextProvider>
            <Helmet>
              <title>{title}</title>
            </Helmet>

            <LanguagesProvider>
              <EmployerProvider>
                <NotificationToaster />
                <ConfirmationModal />
                <CustomModal />
                <Loadable>
                  <BrowserRouter>
                    <Routes>
                      <Route path="*" element={<RoutesContainer />} />
                      <Route element={<ProtectedRoutes redirectTo={V2_HOME_ROUTE} />}>
                        <Route path={SIGN_IN_ROUTE} element={<AsyncAuthenticationPage />} />
                      </Route>
                      <Route path={VISIT_ROUTE} element={<AsyncVisitApp />} />
                      <Route path={ADMIN_LOGIN_ROUTE} element={<AsyncAdminLoginPage />} />
                      <Route path={EMPLOYER_ROUTE} element={<AsyncEmployerPage />} />
                      <Route path={EMPLOYER_AUTH_ROUTE} element={<AsyncEmployerAuth />} />
                      <Route path={EMPLOYER_SUCCESS_ROUTE} element={<AsyncEmployerSuccess />} />

                      <Route path={MERCHANT_LANDING_ROUTE} element={<AsyncMerchantLanding />} />
                      <Route path={MERCHANT_SIGNUP_ROUTE} element={<AsyncMerchantSignup />} />
                      <Route path={MERCHANT_REGISTER_COMPANY_ROUTE} element={<AsyncMerchantRegisterCompany />} />
                      <Route path={MERCHANT_URL_COMPANY_ROUTE} element={<AsyncMerchantUrlCompany />} />
                      <Route path={LOGGED_OUT_PROFILE_ROUTE} element={<AsyncDefaultUserProfilePage />} />
                      <Route path={SIGN_UP_ROUTE} element={<AsyncAuthenticationPage />} />
                      <Route path={INVITATION_ROUTE} element={<AsyncInvitationPage />} />
                      <Route path={USER_SHOP_POLICIES} element={<AsyncShopPolicyDetailsPage />} />
                      <Route path="/shop/*" element={<ShopHolder />} />
                    </Routes>
                  </BrowserRouter>
                </Loadable>
              </EmployerProvider>
            </LanguagesProvider>
          </TerminalContextProvider>
        </ErrorBoundary>
      </InterfaceLocaleProvider>
    </Provider>
  );
};

export default MainApp;

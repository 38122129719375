import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'fcwiltz',
  title: 'FC WILTZ 71',
  name: 'FC WILTZ 71 app',
  brandName: 'FC WILTZ 71',
  appLinkSchema: 'fcwiltz',
  appTld: 'fcwiltz.com',
  tags: ['all', 'sports'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  TERMS_OF_SERVICE_URL: 'https://www.fcwiltz.com/page/fc-wiltz-71-app-92544',
  PRIVACY_STATEMENT_URL: 'https://www.fcwiltz.com/page/fc-wiltz-71-app-92544',
};

export default config;

import { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';

import { didChunkAlreadyReload, didChunkFailed, setChunkReloadAt } from './utils/chunks';

type ErrorBoundaryProps = {
  children?: ReactNode;
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    showPopup: false,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      showPopup: didChunkFailed(error) && !didChunkAlreadyReload(),
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (didChunkFailed(error) && !didChunkAlreadyReload()) {
      setChunkReloadAt();
    } else {
      // Log the error to sentry
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const { showPopup, hasError } = this.state;
    const { children } = this.props;

    if (showPopup) {
      return (
        <Dialog
          open
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id="label.chunk.newVersion" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="label.chunk.reloadPage" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleReload}>
              <FormattedMessage id="button.ok" />
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (hasError) {
      return (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ flexDirection: 'column' }}
        >
          <Typography variant="body1" fontWeight="700">
            <FormattedMessage id="label.errorOccurred" />
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button onClick={this.handleReload}>
              <FormattedMessage id="button.reload" />
            </Button>
          </Box>
        </Box>
      );
    }

    return children;
  }
}

import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'stash',
  title: 'Stash',
  name: 'Stash app',
  brandName: 'Alleaves',
  appLinkSchema: 'stash',
  appTld: 'mystash.co',
  useCase: 'shop',
  tags: ['all'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  custom: {
    welcomeStep: 'WELCOME_STEP',
    shopUrl: 'https://stash-shop.web.app',
    shopBaseUrl: 'https://stash-shop.web.app/{slug}', // TODO: remove shopUrl when we have the production url finalized
  },
  TERMS_OF_SERVICE_URL: 'http://mystash.co/terms/',
  PRIVACY_STATEMENT_URL: 'http://mystash.co/privacy/',
  customPages: {
    home: 'AsyncClientHomePage',
    profile: 'AsyncDefaultUserProfilePage',
  },
};

export default config;

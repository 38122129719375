import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useIntl } from 'react-intl';
import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { FormikValues } from 'formik';
import get from 'lodash/get';

import TooltipAnchor from 'Components/TooltipAnchor';
import NumberInput from 'Components/NumberInput';
import StyledSelect from 'Components/StyledSelect';

import { CustomCurrencyStats } from 'Types/currency.interface';
import { getErrorsByName } from 'Utils/formik';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './index.module.scss';

interface PriceInputProps {
  tooltip?: string;
  label: string;
  name: string;
  placeholder: string;
  formik: FormikValues;
  onChange: (e: SelectChangeEvent<unknown>) => void;
  customCurrencies?: CustomCurrencyStats[];
  decimalScale?: number;
  fixedDecimalScale?: boolean;
}

const PriceInput: FC<PriceInputProps> = ({
  tooltip,
  label,
  name,
  formik,
  placeholder,
  onChange,
  customCurrencies = [],
  decimalScale = 0,
  fixedDecimalScale = false,
}) => {
  const intl = useIntl();
  const inputErrors = getErrorsByName(formik, name);
  const hasCustomCurrencies = customCurrencies?.length > 0;
  const selectErrors = hasCustomCurrencies ? getErrorsByName(formik, 'customCurrency') : [];
  const errors = inputErrors?.length > 0 ? inputErrors : selectErrors;

  const renderBoldChunks = (chunks: ReactNode[]) => <b>{chunks}</b>;

  const renderBreak = () => <br />;

  const renderDashedChunks = (chunks: ReactNode[]) => <span style={{ textDecoration: 'line-through' }}>{chunks}</span>;

  return (
    <div className={styles.root}>
      <div className="w-100">
        <label className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: label })}
          {tooltip && (
            <>
              {' '}
              <TooltipAnchor
                translationKey={tooltip}
                values={{ br: renderBreak, b: renderBoldChunks, c: renderDashedChunks }}
              />
            </>
          )}
        </label>
        <NumberInput
          name={name}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          placeholder={intl.formatMessage({ id: placeholder })}
          value={get(formik?.values, name) || ''}
          validationErrors={errors}
          onChange={onChange}
          className={customCurrencies?.length > 0 ? styles.field : ''}
        />
      </div>

      {hasCustomCurrencies && (
        <StyledSelect
          name="customCurrency"
          value={get(formik?.values, 'customCurrency')}
          onChange={onChange}
          displayEmpty
          className={classnames(styles.dropdown, { [styles.errorSpacing]: errors && errors?.length > 0 })}
        >
          <MenuItem disabled value="">
            <span>
              {intl.formatMessage({ id: 'placeholder.select...' })}
            </span>
          </MenuItem>
          {customCurrencies.map(({ code }) => (
            <MenuItem key={code} value={code}>{code}</MenuItem>
          ))}
        </StyledSelect>
      )}
    </div>
  );
};

export default PriceInput;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Grid } from '@mui/material';

//  Components
import BountyCreateForm from 'Containers/BountyCreate/BountyCreateForm';
import CustomModalButtons from 'Components/CustomModalButtons';
import UploadManager from 'Containers/UploadManager';
import AttachedPromotions from 'Containers/AttachedPromotions';
import LoaderOverlay from 'Components/LoaderOverlay';
import BountyAttachmentManagement from 'Containers/BountyAttachmentManagement';

//  Actions/Selectors
import { getMyCurrencySelector, settingsSelector, userDataSelector } from 'Store/settings/selectors';
import {
  createBounty,
  listDistributionTargets,
  onEditBounty,
  updateLocalBounty,
} from 'Services/bounty/BountyService';
import { canAttachProductSelector } from 'Store/permissions/selectors';
import {
  configurationSelector,
  parentBountySelector,
  selectedBountySelector,
} from 'Store/createBounty/selectors';
import { closeDrawer } from 'Store/createBounty/actions';

//  Other resources
import { getBountyAvailableFeatures } from 'Utils/bountyFeatures';
import { BountyType, AttachmentSourceOptions, Currency, SentTypes } from 'Constants/enums';
import { getRewardTypesAsOptions } from 'Utils/reward';
import { getPostableListDefs, getStreamListDefsSelector } from 'Utils/models/ListManager';

import { setDataAction } from 'Store/genericActions';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { GenericReducerName } from 'Constants/genericReducerName';
import { bountyRefreshCountSelector } from 'Store/bounty/selectors';
import { USER_SENTS_ROUTE } from 'Constants/routes';
import { formatBounty } from 'Utils/bountyCreation';
import { bountySchema, validateBounty } from 'Utils/validation/bounty';
import { notificationToast } from 'Utils/notificationToaster';
import FilesToUpload from 'Components/FilesToUpload';
import {
  generateShowInOptions,
  getExpiryComponents,
  getAsSurvey,
  isSurveyType,
} from 'Utils/bounty';
import {
  getDefaultChoices,
  getDefaultWeight,
  DEFAULT_CORRECT_ANSWER_WEIGHT,
  DEFAULT_WRONG_ANSWER_WEIGHT,
  SurveyResult,
} from 'Utils/survey';

import { filterSelectedDistributionAreas } from 'Utils/distributionGroup';
import { eraseAttachments } from 'Store/attachments/actions';
import { getInitialValues, getTitle } from 'Containers/BountyCreate/utils';
import * as styles from './index.module.scss';

const BountyCreate = () => {
  const selectedBounty = useSelector(selectedBountySelector) || {};
  const parentBounty = useSelector(parentBountySelector) || {};
  const userData = useSelector(userDataSelector)?.data || {};
  const settings = useSelector(settingsSelector) || {};
  const streamLists = useSelector(getStreamListDefsSelector) || {};
  const myCurrency = useSelector(getMyCurrencySelector) || Currency.USD;
  const refreshCount = useSelector(bountyRefreshCountSelector) || {};
  const canAttachProduct = useSelector(canAttachProductSelector) || false;
  const configuration = useSelector(configurationSelector);

  const [uiFeatures, setUiFeatures] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [showInOptions, setShowInOptions] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [detailedTargetList, setDetailsTargetList] = useState([]);
  const [expiration, setExpiration] = useState({});
  const [initialSurveyOptions, setInitialSurveyOptions] = useState({});
  const [attachToUpload, setAttachToUpload] = useState([]);
  const [removedAttachments, setRemovedAttachments] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const { bountyType } = configuration;
  const isEditMode = !!(selectedBounty && Object.keys(selectedBounty).length && !configuration.duplicate);
  const uploadOptions = [BountyType.News, BountyType.Banner].includes(bountyType)
    ? [AttachmentSourceOptions.PhotoLibrary]
    : [];

  useEffect(() => {
    setCurrencyList();
    setAllowedFeatures();
    setShowInList();
    setBountyExpiration();
    setSurveyOptions();
  }, []);

  useEffect(() => {
    setDistributionTargets();
  }, [selectedBounty?.id]);

  const setCurrencyList = () => {
    const options = getRewardTypesAsOptions({
      bountyType: configuration?.bountyType,
      userData,
      settings,
    });
    setCurrencyOptions(options);
  };

  const setAllowedFeatures = () => {
    const features = getBountyAvailableFeatures({
      option: configuration,
      userData,
      settings,
      selectedBounty,
      parentBounty,
    });
    setUiFeatures(features);
  };

  const setShowInList = () => {
    const lists = getPostableListDefs(streamLists, userData, configuration?.bountyType);
    const options = generateShowInOptions(lists);
    setShowInOptions(options);
  };

  const setBountyExpiration = () => {
    const expirationData = getExpiryComponents(selectedBounty, configuration.bountyType);
    setExpiration(expirationData);
  };

  const setSurveyOptions = () => {
    const options = isSurveyType(bountyType) ? getSurveyOptions() : {};
    setInitialSurveyOptions(options);
  };

  const setDistributionTargets = async () => {
    const { list } = await listDistributionTargets({ bountyId: selectedBounty.id, bountyType: configuration?.bountyType });

    if (list) {
      setTargetList(distributionTargetsAsOptions(list));
      setDetailsTargetList(list);
    }
  };

  const distributionTargetsAsOptions = (arr) => arr.map(({ code, text }) => ({ label: text, value: code }));

  const getSurveyOptions = () => {
    if (selectedBounty && Object.keys(selectedBounty).length) {
      return getAsSurvey(selectedBounty)?.options?.reduce((acc, option) => {
        acc[option.code] = option;
        return acc;
      }, {});
    }
    return getDefaultChoices();
  };

  const onDismiss = () => {
    formik.resetForm();
    dispatch(closeDrawer());
    dispatch(eraseAttachments());
  };

  const getFormattedPayload = (values) => {
    const payload = {
      values: {
        ...values,
        type: bountyType,
      },
      userData,
      settings,
    };

    if (parentBounty?.id) {
      payload.parentBounty = { id: parentBounty.id, type: parentBounty.type };
    }

    if (values?.onBehalfOf?.user?.id === userData?.myself?.id) {
      payload.values.onBehalfOf = null;
    }

    return payload;
  };

  const handleCreateBounty = (values, { setSubmitting }) => {
    const bounty = formatBounty(getFormattedPayload(values));

    dispatch(createBounty(bounty, attachToUpload))
      .then(() => {
        dispatch(setDataAction(GenericReducerName.UserStreams, GenericReducerProp.RefreshBountyDetails, refreshCount + 1));
        dispatch(closeDrawer());
        formik.resetForm({});

        if (configuration.withRedirect) {
          goToSents(bounty.type);
        }
      })
      .catch(() => {
        notificationToast.error('errors.somethingWentWrong');
        setSubmitting(false);
      });
  };

  const handleEditBounty = (values, { setSubmitting }) => {
    const payload = {
      newBounty: {
        ...selectedBounty,
        ...formatBounty(getFormattedPayload(values)),
      },
      stateAttachments: attachToUpload,
      stateRemovedAttachments: removedAttachments,
      oldBounty: selectedBounty,
    };

    dispatch(onEditBounty(payload))
      .then((editedBounty) => {
        dispatch(setDataAction(GenericReducerName.UserStreams, GenericReducerProp.RefreshBountyDetails, refreshCount + 1));
        dispatch(updateLocalBounty(editedBounty));
        formik.resetForm({});
        dispatch(closeDrawer());
      })
      .catch(() => {
        notificationToast.error('errors.somethingWentWrong');
        setSubmitting(false);
      });
  };

  const onSubmit = (values, handlers) => {
    const customErrors = validateBounty(values, attachToUpload);

    if (customErrors) {
      return notificationToast.error(intl.formatMessage({ id: customErrors.message }, customErrors.values));
    }

    if (isEditMode) {
      return handleEditBounty(values, handlers);
    }

    handleCreateBounty(values, handlers);
  };

  const goToSents = (type) => {
    if ([BountyType.News, BountyType.Banner].includes(type)) {
      return navigate(`${USER_SENTS_ROUTE}?tab=${type}`);
    }

    return navigate(`${USER_SENTS_ROUTE}?tab=${SentTypes.MyDrafts}`);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(
      selectedBounty,
      expiration,
      showInOptions,
      uiFeatures,
      userData,
      settings,
      bountyType,
      initialSurveyOptions,
    ),
    validationSchema: bountySchema,
    onSubmit,
  });

  const handleChanges = ({ target: { name, value } }) => {
    formik.setFieldValue(name, value);
  };

  const handleRemove = (attachment) => {
    setRemovedAttachments((prevState) => ([...prevState, attachment]));
  };

  const onDescriptionChange = (description) => {
    formik.setFieldValue('description', description);
  };

  const handleSwitch = (e) => {
    const { target: { checked, name } } = e;
    formik.setFieldValue(name, checked);
  };

  const handleDistributionGroups = (name) => (valuesArray) => {
    formik.setFieldValue(name, valuesArray);
    formik.setFieldValue('distributionDetails', filterSelectedDistributionAreas(detailedTargetList, valuesArray));
  };

  const handleOnBehalfOf = (user) => {
    formik.setFieldValue('onBehalfOf', user);
  };

  const handleDateChanges = (name, value) => {
    const time = value ? new Date(value).getTime() : null;
    formik.setFieldValue(name, time);
  };

  const handleChoiceChanges = ({ target: { name, value } }) => {
    const payload = {
      ...formik.values.surveyOptions,
      [name]: {
        ...formik.values.surveyOptions[name],
        text: value,
      },
    };
    formik.setFieldValue('surveyOptions', payload);
  };

  const handleCorrectChoice = ({ target: { value } }) => {
    const { surveyOptions } = formik.values;
    const newOptions = {};

    Object.keys(surveyOptions).forEach((code) => {
      if (code === value) {
        newOptions[code] = { ...surveyOptions[code], weight: DEFAULT_CORRECT_ANSWER_WEIGHT };
      } else {
        newOptions[code] = { ...surveyOptions[code], weight: DEFAULT_WRONG_ANSWER_WEIGHT };
      }
    });

    formik.setFieldValue('surveyOptions', newOptions);
  };

  const handleDeleteChoice = ({ code }) => {
    const { surveyOptions } = formik.values;
    delete surveyOptions[code];
    formik.setFieldValue('surveyOptions', surveyOptions);
  };

  const handleAddChoice = () => {
    const { surveyOptions } = formik.values;

    const lastCode = Object.keys(surveyOptions).pop();
    const code = SurveyResult.getCodeByIndex({ code: lastCode, index: surveyOptions.length });

    const newOptions = {
      ...surveyOptions,
      [code]: { code, ...getDefaultWeight(bountyType) },
    };

    formik.setFieldValue('surveyOptions', newOptions);
  };

  const setFieldValue = (name, value) => formik.setFieldValue(name, value);

  const getAttachmentsSectionTitle = () => {
    if (bountyType === BountyType.Banner) {
      return intl.formatMessage({ id: 'label.bannerSlides' });
    }

    return intl.formatMessage({ id: 'label.attachments' });
  };

  const sm = uiFeatures.hasAttachmentManagement ? 6 : 12;

  return (
    <div
      className={classnames({
        [styles.containerWithAttachments]: uiFeatures.hasAttachmentManagement,
        [styles.root]: !uiFeatures.hasAttachmentManagement,
      })}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={sm}>
          <h4>{getTitle(selectedBounty, configuration, isEditMode)}</h4>

          {uiFeatures.hasAttachments && !uiFeatures.hasAttachmentManagement && (
            <UploadManager
              options={uploadOptions}
              multiple={![BountyType.News, BountyType.Banner].includes(bountyType)}
              canAttachProduct={canAttachProduct}
              allowMultipleProducts
              setAttachments={setAttachToUpload}
              attachments={attachToUpload}
            />
          )}

          <BountyCreateForm
            formik={formik}
            uiFeatures={uiFeatures}
            bountyType={bountyType}
            targetList={targetList}
            showInOptions={showInOptions}
            myCurrency={myCurrency}
            currencyOptions={currencyOptions}
            handleChanges={handleChanges}
            onDescriptionChange={onDescriptionChange}
            handleSwitch={handleSwitch}
            handleDistributionGroups={handleDistributionGroups}
            handleOnBehalfOf={handleOnBehalfOf}
            handleDateChanges={handleDateChanges}
            handleChoiceChanges={handleChoiceChanges}
            handleCorrectChoice={handleCorrectChoice}
            handleDeleteChoice={handleDeleteChoice}
            handleAddChoice={handleAddChoice}
            setFieldValue={setFieldValue}
          />

          {uiFeatures.hasAttachments && !uiFeatures.hasAttachmentManagement && (
            <FilesToUpload
              bounty={selectedBounty}
              attachments={attachToUpload}
              setAttachments={setAttachToUpload}
              onRemove={handleRemove}
            />
          )}

          {canAttachProduct && (
            <AttachedPromotions />
          )}
        </Grid>
        {uiFeatures.hasAttachmentManagement && (
          <Grid item xs={12} sm={6}>
            <BountyAttachmentManagement
              bounty={selectedBounty}
              title={getAttachmentsSectionTitle()}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={sm}>
          <CustomModalButtons
            disabled={formik.isSubmitting}
            onDismiss={onDismiss}
            onSubmit={formik.handleSubmit}
          />
        </Grid>
      </Grid>
      {formik.isSubmitting && <LoaderOverlay />}
    </div>
  );
};

export default BountyCreate;

import { getApplicationConfig } from 'Utils/appConfig';
import { ProductFlavours } from 'Constants/enums';

const flavour = getApplicationConfig().id;

const DEFAULT = ProductFlavours.Belong;

const asEnum = (product: string) => {
  if (!product) {
    return DEFAULT;
  }

  if (product === 'youkno') {
    return ProductFlavours.Backstage;
  }

  return Object.values(ProductFlavours).includes(product as ProductFlavours) ? product as ProductFlavours : DEFAULT;
};

export const getCurrent = (): ProductFlavours => asEnum(flavour);

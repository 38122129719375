import {
  GROUPS_PROFILE_ROUTE,
  LEADERBOARD_ROUTE,
  MY_ACCOUNT_ROUTE,
  STREAM_DETAILS_ROUTE,
  USER_DETAILS_ROUTE,
  JOB_DETAILS_ROUTE, RECEIVED_ORDER_ROUTE, ORDER_ROUTE,
} from 'Constants/routes';
import { fetchBounty } from 'Services/bounty/BountyService';
import { generateLink } from 'Utils/link';
import { AlertType, BountyType } from 'Constants/enums';
import { Notification } from "Types/notification.interface";

const getJobsType = async (bountyId: string) => {
  try {
    const jobData = await fetchBounty(bountyId);

    return jobData.type;
  } catch (e) {
    console.warn(e);

    return null;
  }
};

//  TODO: include all the checks from android
export const getRouteByLink = async (notification: Notification) => {
  const { link, alertType } = notification;
  const [, hostAndPathname] = link.split('//') || [];
  const notificationUrl = `https://${hostAndPathname}`;
  const route = new URL(notificationUrl);
  const [segment1, segment2, segment3] = route?.pathname?.split('/')?.filter((part) => part) || [];

  if (segment1 === 'leaderboard') {
    return LEADERBOARD_ROUTE;
  }

  if (segment1 === 'account') {
    return MY_ACCOUNT_ROUTE;
  }

  if (segment1 === 'bounties') {
    if (segment2) {
      const jobType = await getJobsType(segment2);
      return generateLink(jobType === BountyType.Job ? JOB_DETAILS_ROUTE : STREAM_DETAILS_ROUTE, { bountyId: segment2 });
    }
  }

  if (segment2 === 'orders') {
    let orderRoute = ORDER_ROUTE;

    if ([AlertType.NewRecvOrder, AlertType.ChargeSucceeded].includes(alertType)) {
      orderRoute = RECEIVED_ORDER_ROUTE;
    }

    return generateLink(orderRoute, { bountyId: segment3 });
  }

  if (segment1 === 'profiles' && segment2) {
    if (segment2 === 'group') {
      return generateLink(GROUPS_PROFILE_ROUTE, { groupId: segment3 });
    }

    if (!['company', 'me'].includes(segment2)) {
      return generateLink(USER_DETAILS_ROUTE, { userId: segment2 });
    }
  }

  return '';
};

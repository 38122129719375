import { Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { FC, useContext, useState } from 'react';
import CountryPreview from 'Components/CountryPreview';
import { LanguageContext } from 'Contexts/languagesContext';
import {
  generateImageByCountryCode,
  splitLanguage,
  setLocaleToLocalStorage,
  getFlavourLanguages,
} from 'Utils/languagesHelpers';
import { useSelector } from 'react-redux';
import { postLocaleServer } from 'Services/locale/LocaleService';
import * as userSelectors from 'Store/auth/selectors';
import { getShortLangCode } from 'Utils/translations';
import { useIntl } from 'react-intl';
import LanguagePreview from 'Components/LanguagePreview';
import * as styles from './index.module.scss';

const AppLanguageSwitcher: FC = () => {
  const userId = useSelector(userSelectors.authUserUidSelector);
  const { locale, setLocale } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const handleChange = (e: SelectChangeEvent) => {
    const { target: { value } } = e;

    if (userId) {
      return postLocaleServer(userId, value, () => {
        setLocale(value);
        setLocaleToLocalStorage(value);
      });
    }
    setLocale(value);
    setLocaleToLocalStorage(value);
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const supportedLanguages = getFlavourLanguages();

  return (
    <FormControl sx={{ maxWidth: 180 }}>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={splitLanguage(locale)}
        defaultValue={locale}
        variant="standard"
        autoWidth
        onChange={handleChange}
        disableUnderline
        renderValue={(v) => (
          <CountryPreview
            iconOnly
            className={styles.icon}
            countryCode={generateImageByCountryCode(v)}
          />
        )}
        IconComponent={() => null}
        classes={{ select: open ? styles.selectOpen : styles.select }}
        className={styles.languageSelector}
        MenuProps={{
          PaperProps: { className: styles.paper },
          MenuListProps: { disablePadding: true },
        }}
      >
        {supportedLanguages.map((language) => (
          <MenuItem
            key={language.shortKey}
            value={language.shortKey}
            title={intl.formatMessage({ id: `lang-${getShortLangCode(language.shortKey).toUpperCase()}` })}
            className={styles.menuItem}
          >
            <span className={styles.languageItem}>
              <CountryPreview
                iconOnly
                className={styles.icon}
                countryCode={generateImageByCountryCode(language.shortKey)}

              />
              <span className={styles.text}>
                <LanguagePreview code={getShortLangCode(language.shortKey).toUpperCase()} />
              </span>
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AppLanguageSwitcher;

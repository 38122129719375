// Actions/Reducers
import { getHandlers } from 'Store/genericActionsHelpers';

// Others
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { GenericReducerName } from 'Constants/genericReducerName';

//  TODO: check if can be concat with products reducer
// initial state
const INIT_STATE = {
  [GenericReducerProp.UserSents]: [],
  [GenericReducerProp.CompanySents]: [],
  [GenericReducerProp.CompanySubSents]: [],
  [GenericReducerProp.SelectedBounty]: {},
  [GenericReducerProp.CompanySentsDetails]: {},
};

const handlerReducer = getHandlers(GenericReducerName.Sents);

export default function sentsReducer(state = INIT_STATE, action = {}) {
  const handler = handlerReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}

import { AppDispatch } from 'Types/redux.types';

export enum Actions {
  SetTitle = 'headerReducer/SetTitle',
}

// TODO: add debounce?
export const setPageTitle = (title: string) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.SetTitle, payload: title });

export const resetPageTitle = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.SetTitle, payload: '' });

import { FlwrQuantityVariant } from 'Constants/enums';

export const FLWR_VARIANT_BY_QUANTITY: Record<number, FlwrQuantityVariant> = {
  1: FlwrQuantityVariant.eightOz,
  2: FlwrQuantityVariant.quarterOz,
  4: FlwrQuantityVariant.halfOz,
  8: FlwrQuantityVariant.oneOz,
};

export const FLWR_2_VARIANT_BY_QUANTITY: Record<number, FlwrQuantityVariant> = {
  1: FlwrQuantityVariant.quarterOz,
  2: FlwrQuantityVariant.halfOz,
  4: FlwrQuantityVariant.oneOz,
};

export const FLWR_4_VARIANT_BY_QUANTITY: Record<number, FlwrQuantityVariant> = {
  1: FlwrQuantityVariant.halfOz,
  2: FlwrQuantityVariant.oneOz,
};

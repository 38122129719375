import { useEffect } from 'react';
import { notificationToast } from 'Utils/notificationToaster';
import { appendScriptToParent } from 'Utils/scripts';

const ShopHolder = () => {
  const initShop = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ShopInit();
    } catch {
      notificationToast.error('The shop could not be initialized');
    }
  };

  useEffect(() => {
    appendScriptToParent('https://shop.alleaves.shop/shop/index.js', 'shopScript');
  }, []);

  useEffect(() => {
    const script = document.querySelector('#shopScript');
    script?.addEventListener('load', initShop);

    return () => script?.removeEventListener('load', initShop);
  }, []);

  return null;
};

export default ShopHolder;

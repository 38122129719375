import { FC } from 'react';
import ReactPlayer from 'react-player';
import * as styles from './index.module.scss';

interface RenderMediaProps {
  url: string;
  width?: string|number;
  height?: string|number;
}

const Media: FC<RenderMediaProps> = ({ url, width, height }) => (
  <div className={styles.playerWrapper}>
    <ReactPlayer
      url={url}
      className="react-player"
      width={width || '100%'}
      height={height || '100%'}
      controls
    />
  </div>
);

export default Media;

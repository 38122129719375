import { Currency, FeeStatus } from "Constants/enums";

export const DEFAULT_FEE = {
  status: FeeStatus.Inactive,
  flatRate: {
    money: {
      amount: '',
      currency: Currency.USD,
    },
  },
  freeOver: {
    money: {
      amount: '',
      currency: Currency.USD,
    },
  },
  freeShipping: false,
  countryCode: 'US',
};

export const DEFAULT_DELIVERY_FEE = {
  domesticFees: { US: DEFAULT_FEE },
  internationalFee: DEFAULT_FEE,
};

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormikValues } from "formik";
import { isEmpty } from "lodash";
import get from "lodash/get";

import TooltipAnchor from 'Components/TooltipAnchor';
import StyledSelect from 'Components/StyledSelect';
import FieldDetailsExtension from 'Components/FieldDetailsExtension';
import NumberInput from "Components/NumberInput";
import CountryPreview from "Components/CountryPreview";

import { getErrorsByName } from 'Utils/formik';
import { CountriesList } from 'Utils/countries';
import { Currency, FeeStatus } from 'Constants/enums';
import { ShippingFee } from "Types/shippingFees.interface";
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as placeholderStyle from 'Assets/scss/modules/placeholder.module.scss';
import * as styles from "./index.module.scss";

interface CustomShippingFeesFormProps {
  formik: FormikValues;
  merchantShippingFee?: ShippingFee | null;
}

const CustomShippingFeesForm: FC<CustomShippingFeesFormProps> = ({
  formik,
  merchantShippingFee = null,
}) => {
  const intl = useIntl();
  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    if (!isEmpty(formik?.values?.domesticFees)) {
      setCountries(Object.keys(formik?.values?.domesticFees).map((key) => key));
    }
  }, [formik?.values?.domesticFees]);

  const handleSwitch = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'internationalFee.status') {
      formik.setFieldValue(name, checked ? FeeStatus.Active : FeeStatus.Inactive);

      if (!checked) {
        formik.setFieldValue('internationalFee.freeShipping', false);
      }

      return;
    }

    if (name === 'internationalFee.freeShipping' && checked) {
      formik.setFieldValue('internationalFee.status', FeeStatus.Active);
    }

    formik.setFieldValue(name, checked);
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => {
    formik.setFieldValue(name, value);
  };

  const handleChangeCountry = ({ target: { value } }: SelectChangeEvent<unknown>, countryCode: string) => {
    const currentFees = get(formik.values, `domesticFees.${countryCode}`);
    const newCountryCode = value as string;
    const domesticFees = { ...formik.values?.domesticFees };

    delete domesticFees?.[countryCode];

    formik.setFieldValue('domesticFees', {
      ...domesticFees,
      [newCountryCode]: {
        ...currentFees,
        countryCode: newCountryCode,
      }
    });
  };

  return (
    <>
      {countries?.map((countryCode) => {
        const isActive = get(formik.values, `domesticFees.${countryCode}.status`) === FeeStatus.Active;
        const hasFreeShipping = get(formik.values, `domesticFees.${countryCode}.freeShipping`) || false;

        return (
          <div key={countryCode}>
            <div className="mb-10">
              <label className={labelStyles.fieldLabel}>
                {`${intl.formatMessage({ id: 'label.domesticLocation' })}*`}
                {' '}
                <TooltipAnchor translationKey="hint.domesticLocation"/>
              </label>
              <StyledSelect
                name={`domesticFees.${countryCode}.countryCode`}
                value={get(formik.values, `domesticFees.${countryCode}.countryCode`) || ''}
                onChange={(e) => handleChangeCountry(e, countryCode)}
                classNamePaper={styles.dropdown}
                validationErrors={getErrorsByName(formik, `domesticFees.${countryCode}.countryCode`)}
                displayEmpty
              >
                <MenuItem disabled value="">
                <span className={placeholderStyle.placeholder}>
                  {intl.formatMessage({ id: 'label.select' })}
                </span>
                </MenuItem>
                {CountriesList.map(({ code, label }) => (
                  <MenuItem key={code} value={code}>
                    <CountryPreview iconOnly countryCode={code}/>
                    {label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </div>

            <div>
              <label className={labelStyles.fieldLabel}>
                {intl.formatMessage({ id: 'label.freeDomesticShipping' })}
                {' '}
                <TooltipAnchor translationKey="hint.freeDomesticShipping"/>
              </label>
              <FormControlLabel
                control={(
                  <Switch
                    id="freeShipping"
                    color="primary"
                    className="ml-2"
                    name={`domesticFees.${countryCode}.freeShipping`}
                    checked={hasFreeShipping}
                    onChange={handleSwitch}
                  />
                )}
                label={intl.formatMessage({ id: isActive ? 'label.on' : 'label.off' })}
              />
            </div>

            {!hasFreeShipping && (
              <>
                <div className="mb-10">
                  <label className={labelStyles.fieldLabel}>
                    {intl.formatMessage({ id: 'label.defaultDomesticShippingFee' })}
                  </label>
                  <FieldDetailsExtension label={Currency.USD}>
                    <NumberInput
                      name="defaultInternationalShippingFee"
                      value={
                        merchantShippingFee
                          ? get(merchantShippingFee, `domesticFees.${countryCode}.flatRate.money.amount`) || '0'
                          : '0'
                      }
                      onChange={handleChange}
                      className={styles.numberField}
                      disabled
                    />
                  </FieldDetailsExtension>
                </div>

                <div className="mb-10">
                  <label className={labelStyles.fieldLabel}>
                    {`${intl.formatMessage({ id: 'label.extraShippingFeePerUnit' })}*`}
                    {' '}
                    <TooltipAnchor translationKey="hint.extraShippingFeePerUnit"/>
                  </label>

                  <FieldDetailsExtension
                    label={Currency.USD}
                    hasError={getErrorsByName(formik, `domesticFees.${countryCode}.flatRate.money.amount`)?.length > 0}
                  >
                    <NumberInput
                      name={`domesticFees.${countryCode}.flatRate.money.amount`}
                      value={get(formik?.values, `domesticFees.${countryCode}.flatRate.money.amount`) || ''}
                      onChange={handleChange}
                      className={styles.numberField}
                      placeholder={intl.formatMessage({ id: 'placeholder.enterAmount' })}
                      validationErrors={getErrorsByName(formik, `domesticFees.${countryCode}.flatRate.money.amount`)}
                      disabled={!isActive}
                    />
                  </FieldDetailsExtension>
                </div>

                <div className="mb-10">
                  <label className={labelStyles.fieldLabel}>
                    {intl.formatMessage({ id: 'label.freeDomesticShippingOver' })}
                    {' '}
                    <TooltipAnchor translationKey="hint.freeDomesticShippingOver"/>
                  </label>
                  <FieldDetailsExtension
                    label={Currency.USD}
                    hasError={getErrorsByName(formik, `domesticFees.${countryCode}.freeOver.money.amount`)?.length > 0}
                  >
                    <NumberInput
                      name={`domesticFees.${countryCode}.freeOver.money.amount`}
                      value={get(formik.values, `domesticFees.${countryCode}.freeOver.money.amount`) || ''}
                      onChange={handleChange}
                      className={styles.numberField}
                      placeholder={intl.formatMessage({ id: 'placeholder.enterAmount' })}
                      validationErrors={getErrorsByName(formik, `domesticFees.${countryCode}.freeOver.money.amount`)}
                      disabled={!isActive}
                    />
                  </FieldDetailsExtension>
                </div>
              </>
            )}
          </div>
        );
      })}

      <div>
        <label className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.enableInternationalShipping' })}
          {' '}
          <TooltipAnchor translationKey="hint.enableInternationalShipping"/>
        </label>
        <FormControlLabel
          control={(
            <Switch
              id="enable-international-shipping"
              color="primary"
              className="ml-2"
              name="internationalFee.status"
              checked={formik.values?.internationalFee?.status === FeeStatus.Active}
              onChange={handleSwitch}
            />
          )}
          label={intl.formatMessage({
            id: formik.values?.internationalFee?.status === FeeStatus.Active
              ? 'label.on'
              : 'label.off'
          })}
        />
      </div>

      <div>
        <label className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.freeInternationalShipping' })}
          {' '}
          <TooltipAnchor translationKey="hint.freeInternationalShipping"/>
        </label>
        <FormControlLabel
          control={(
            <Switch
              id="freeShipping"
              color="primary"
              className="ml-2"
              name="internationalFee.freeShipping"
              checked={formik?.values?.internationalFee?.freeShipping || false}
              onChange={handleSwitch}
            />
          )}
          label={intl.formatMessage({
            id: formik?.values?.internationalFee?.freeShipping
              ? 'label.on'
              : 'label.off'
          })}
        />
      </div>

      {!formik?.values?.internationalFee?.freeShipping && (
        <>
          <div className="mb-10">
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.defaultInternationalShippingFee' })}
            </label>
            <FieldDetailsExtension label={Currency.USD}>
              <NumberInput
                name="defaultInternationalShippingFee"
                value={merchantShippingFee?.internationalFee?.flatRate?.money?.amount || ''}
                onChange={handleChange}
                className={styles.numberField}
                disabled
              />
            </FieldDetailsExtension>
          </div>

          <div className="mb-10">
            <label className={labelStyles.fieldLabel}>
              {`${intl.formatMessage({ id: 'label.extraInternationalShippingPerUnit' })}*`}
              {' '}
              <TooltipAnchor translationKey="hint.extraInternationalShippingPerUnit"/>
            </label>
            <FieldDetailsExtension
              label={Currency.USD}
              hasError={getErrorsByName(formik, 'internationalFee.flatRate.money.amount')?.length > 0}
            >
              <NumberInput
                name="internationalFee.flatRate.money.amount"
                value={formik.values?.internationalFee?.flatRate?.money?.amount || ''}
                onChange={handleChange}
                className={styles.numberField}
                placeholder={intl.formatMessage({ id: 'placeholder.enterAmount' })}
                validationErrors={getErrorsByName(formik, 'internationalFee.flatRate.money.amount')}
                disabled={formik.values?.internationalFee?.status !== FeeStatus.Active}
              />
            </FieldDetailsExtension>
          </div>

          <div className="mb-10">
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.freeInternationalShippingOver' })}
              {' '}
              <TooltipAnchor translationKey="hint.freeInternationalShippingOver"/>
            </label>
            <FieldDetailsExtension
              label={Currency.USD}
              hasError={getErrorsByName(formik, 'internationalFee.freeOver.money.amount')?.length > 0}
            >
              <NumberInput
                name="internationalFee.freeOver.money.amount"
                value={formik.values?.internationalFee?.freeOver?.money?.amount || ''}
                onChange={handleChange}
                className={styles.numberField}
                placeholder={intl.formatMessage({ id: 'placeholder.enterAmount' })}
                validationErrors={getErrorsByName(formik, 'internationalFee.freeOver.money.amount')}
                disabled={formik.values?.internationalFee?.status !== FeeStatus.Active}
              />
            </FieldDetailsExtension>
          </div>
        </>
      )}
    </>
  );
};
export default CustomShippingFeesForm;

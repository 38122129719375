import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import NavMenuContent from 'Components/SideBar/NavMenuContent';

import { IMenu } from 'Types/menu.interface';
import * as styles from 'Components/SideBar/NavMenuItem/index.module.scss';

interface NavMenuItemProps {
  menu: IMenu,
  external?: boolean,
  open?: boolean,
  activeMenu?: string,
  setActiveMenu: (value : string, hasChildren?: boolean) => void,
  setIsMenuExpanded?: (value: boolean) => void,
}

const NavMenuItem:FC<NavMenuItemProps> = ({
  menu,
  external = false,
  open = true,
  activeMenu,
  setActiveMenu,
  setIsMenuExpanded,
}) => {
  const isSubMenuOpen = !!menu?.child_routes && menu?.child_routes?.some((subMenu) => subMenu?.menu_title === activeMenu);
  const isMenuOpen = activeMenu === menu.menu_title || isSubMenuOpen;

  const isMenuActive = ({ isActive }: { isActive: boolean; }) => isActive ? 'item-active' : '';

  const toggleMenu = () => {
    if (setIsMenuExpanded) {
      setIsMenuExpanded(menu?.is_expendable ? !isMenuOpen : false);
    }

    if (isMenuOpen) {
      setActiveMenu('', true);
      return;
    }

    setActiveMenu(menu?.menu_title, true);
  };

  if (!!menu?.child_routes) {
    return (
      <div>
        <ListItem
          component="li"
          className={classNames(
            'list-item',
            styles.navItem,
            'cursor-pointer',
          )}
          onClick={toggleMenu}
        >
          <Box display="flex" justifyContent="space-between" className={styles.fakeLink}>
            <NavMenuContent menu={menu} open={open} />
            {menu.has_arrow && (
              <span className={classNames('material-symbols-rounded', { [styles.openedMenu]: isMenuOpen })}>
                <span className="chevron_right" />
              </span>
            )}
          </Box>
        </ListItem>
        <Collapse in={isMenuOpen} timeout={100} className="sub-menu">
          <List className={styles.subMenuList}>
            {menu.child_routes.map((subMenu: IMenu, index: number) => (
              <ListItem
                component="li"
                key={index}
                onClick={() => setActiveMenu(subMenu?.menu_title)}
                className={classNames(
                  'list-item',
                  styles.navItem,
                  styles.subMenuItem,
                  { [styles.subMenuSpacing]: !subMenu?.menu_icon },
                )}
              >
                {!menu.external ? (
                  <NavLink
                    className={isMenuActive}
                    to={subMenu.path}
                  >
                    <NavMenuContent menu={subMenu} open={open} />
                  </NavLink>
                ) : (
                  <a className={styles.fakeLink} href={menu.path} target="_blank" rel="noopener noreferrer">
                    <NavMenuContent menu={menu} open={open} />
                  </a>
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </div>
    );
  }

  return (
    <ListItem
      component="li"
      className={classNames(
        'no-right-arrow list-item',
        styles.navItem,
      )}
      onClick={() => setActiveMenu(menu?.menu_title)}
    >
      {menu.path && !external && (
        <NavLink
          className={isMenuActive}
          to={menu.path}
        >
          <NavMenuContent menu={menu} open={open} />
        </NavLink>
      )}

      {!menu.path && (
        <div className={styles.fakeLink}>
          <NavMenuContent menu={menu} open={open} />
        </div>
      )}

      {menu.path && external && (
        <a className={styles.fakeLink} href={menu.path} target="_blank" rel="noopener noreferrer">
          <NavMenuContent menu={menu} open={open} />
        </a>
      )}
    </ListItem>
  );
};

export default NavMenuItem;

import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonType } from 'Constants/enums';
import * as styles from './index.module.scss';

export enum SimpleButtonDecoration {
  Primary,
  Additional,
  Stroke,
  Dashed,
  Transparent,
  Reject,
  Remove,
  Warning,
  Pill,
}

interface SimpleButtonProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  iconClass?: string;
  label?: string;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  decoration?: SimpleButtonDecoration;
  type?: ButtonType;
  onClick?: (e: any) => void;
}

const SimpleButton: FC<SimpleButtonProps> = (
  {
    children,
    className = '',
    decoration = SimpleButtonDecoration.Primary,
    disabled,
    iconClass,
    label = '',
    id = '',
    onClick,
    type = ButtonType.button,
    isLoading = false,
  },
) => (
  <button
    id={id}
    type={type === ButtonType.submit ? 'submit' : 'button'}
    className={classNames(styles.simpleButton, {
      [styles.primary]: decoration === SimpleButtonDecoration.Primary,
      [styles.additional]: decoration === SimpleButtonDecoration.Additional,
      [styles.dashed]: decoration === SimpleButtonDecoration.Dashed,
      [styles.stroke]: decoration === SimpleButtonDecoration.Stroke,
      [styles.transparent]: decoration === SimpleButtonDecoration.Transparent,
      [styles.reject]: decoration === SimpleButtonDecoration.Reject,
      [styles.remove]: decoration === SimpleButtonDecoration.Remove,
      [styles.warning]: decoration === SimpleButtonDecoration.Warning,
      [styles.pill]: decoration === SimpleButtonDecoration.Pill,
      [className]: !!className,
      [styles.loader]: isLoading,
    })}
    disabled={disabled || isLoading}
    onClick={onClick}
  >
    {iconClass && !isLoading && <i className={iconClass} />}

    <div className={classNames({ [styles.hidden]: isLoading })}>
      {children || label}
    </div>

    {isLoading && (
      <div className={styles.loaderWrapper}>
        <div className={styles.loading}>
          <CircularProgress size="1rem" classes={{ root: styles.spinner }} />
        </div>
      </div>
    )}
  </button>
);

export default SimpleButton;

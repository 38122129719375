import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'vera',
  title: 'Vera',
  name: 'Vera app',
  brandName: 'Vera',
  appLinkSchema: 'vera',
  appTld: '',
  useCase: 'vera',
  tags: ['all'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  customCss: {
    sidebarLogo: 'blackBackgroundLogo',
  },
  customPages: {
    home: 'AsyncVeraHomePage',
  },
  TERMS_OF_SERVICE_URL: '#',
  PRIVACY_STATEMENT_URL: '#',
};

export default config;

import { useDispatch, useSelector } from 'react-redux';
import PickProduct from 'Containers/PickProduct';
import { getProducts } from 'Services/ProductService';
import { selectedProductsSelector } from 'Store/createBounty/selectors';
import { setSelectedProducts } from 'Store/createBounty/actions';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ThunkDispatchType } from 'Types/redux.types';
import { Bounty } from 'Types/bounty.interface';

interface AttachableProductsProps {
  groupId?: string;
  allowMultiple?: boolean;
  emptyMessage?: string;
}

const AttachableProducts:FC<AttachableProductsProps> = ({
  emptyMessage,
  groupId,
  allowMultiple = false,
}) => {
  const selectedProducts: Bounty[] = useSelector(selectedProductsSelector) || [];
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();
  const emptyStateMessage = emptyMessage || intl.formatMessage({ id: 'label.noGoodsOnMarket' });

  const handleSelectProduct = (item: Bounty) => {
    const index = selectedProducts?.findIndex((selectedProm) => selectedProm.id === item.id);

    if (index > -1) {
      const newData = selectedProducts?.filter(((selectedProm) => selectedProm.id !== item.id));
      return dispatch(setSelectedProducts(newData));
    }

    return allowMultiple
      ? dispatch(setSelectedProducts([...selectedProducts, item]))
      : dispatch(setSelectedProducts([item]));
  };

  const fetchProducts = (filters: { start: number; length: number }): Promise<{ list: Bounty[], totalCount: number}> => {
    const filter = {
      listId: 'product',
      ...filters,
      ...(groupId ? { groupId } : {}),
      order: 'pinned_at',
    };

    return getProducts(filter);
  };

  return (
    <PickProduct
      emptyListMessage={emptyStateMessage}
      handleSelectProduct={handleSelectProduct}
      getProducts={fetchProducts}
      selectedProducts={selectedProducts}
    />
  );
};

export default AttachableProducts;

import { isEqual } from "lodash";

import { getInitialTags, getProductDeliveryFeeSource, initialPricing } from "Utils/product";
import { Currency, FeeStatus, PointCurrency, ProductKind } from "Constants/enums";
import { Bounty } from "Types/bounty.interface";
import { ProductSpecificInfo } from "Types/product.interface";
import { DEFAULT_DELIVERY_FEE, DEFAULT_FEE } from "Constants/fees";
import { ShippingFee } from "Types/shippingFees.interface";

export const getInitialValues = (
  bounty: Bounty,
  visibility: string,
  productKind: ProductKind,
  isToken: boolean,
  companyCurrency: Currency,
) => {
  const { variants } = bounty?.product || {};
  const currentSku = bounty?.product?.merchantSku && variants?.[bounty?.product?.merchantSku]
    ? variants?.[bounty?.product?.merchantSku]
    : {} as ProductSpecificInfo;
  const { price, customCurrency, currencyType } = initialPricing(currentSku.price);

  return {
    name: bounty?.product?.name || '',
    description: bounty?.product?.description || '',
    merchantSku: bounty?.product?.merchantSku || '',
    additionalInfo: bounty?.product?.additionalInfo || '',
    visibility: bounty?.visibility || visibility || null,
    tag: getInitialTags(bounty, productKind),
    currencyType,
    customCurrency: customCurrency || '',
    currency: companyCurrency,
    price,
    msrp: currentSku?.msrp ? initialPricing(currentSku.msrp)?.price : null,
    quantity: currentSku?.quantity || '',
    onlineProductInfo: {
      ...(isToken ? ({
        tokensQty: currentSku?.onlineProductInfo?.tokensQty,
        tokenCurrency: currentSku?.onlineProductInfo?.tokenCurrency || PointCurrency.SystemToken,
      }) : {}),
      ...(productKind === ProductKind.Ticket ? ({
        useQty: currentSku?.onlineProductInfo?.useQty,
        unlimitedUseQty: !currentSku?.onlineProductInfo?.useQty,
        availableFrom: currentSku?.onlineProductInfo?.availableFrom || null,
        availableTo: currentSku?.onlineProductInfo?.availableTo || null,
      }) : {}),
    },
    deliveryFeeSource: getProductDeliveryFeeSource(bounty),
    extraDeliveryFee: currentSku?.extraDeliveryFee || DEFAULT_DELIVERY_FEE,
  };
};

export const getInitialDeliveryFee = (
  merchantShippingFee?: ShippingFee,
  extraDeliveryFee?: ShippingFee | null,
) => {
  if (extraDeliveryFee && !isEqual(extraDeliveryFee, DEFAULT_DELIVERY_FEE)) {
    return extraDeliveryFee;
  }

  const countryCode = merchantShippingFee?.domesticFees
    ? Object?.keys(merchantShippingFee?.domesticFees)?.map((key) => key)[0]
    : 'US';

  return {
    domesticFees: {
      [countryCode]: {
        ...DEFAULT_FEE,
        status: FeeStatus.Active,
        countryCode,
      },
    },
    internationalFee: DEFAULT_FEE,
  };
};

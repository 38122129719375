import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import * as styles from './index.module.scss';

interface FieldDetailsExtensionProps {
  children: ReactNode | ReactNode[];
  label: string;
  labelClassName?: string;
  isLowerCase?: boolean;
  hasError?: boolean;
  isHidden?: boolean;
  onClick?: () => void;
}

const FieldDetailsExtension: FC<FieldDetailsExtensionProps> = ({
  children,
  label,
  labelClassName = '',
  isLowerCase = false,
  hasError = false,
  isHidden = false,
  onClick,
}) => (
  <div className={styles.root}>
    {children}
    {!isHidden && (
      <div
        onClick={onClick}
        className={classnames(
          styles.label,
          {
            'text-lowercase': isLowerCase,
            [styles.errorSpacing]: hasError,
          },
          labelClassName,
        )}
      >
        <p>{label}</p>
      </div>
    )}
  </div>
);

export default FieldDetailsExtension;

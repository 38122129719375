import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyDL9H96j2PULpu_TbXkKoKtYkUH62EOcrw',
    authDomain: 'stash-prod.firebaseapp.com',
    databaseURL: 'https://stash-prod.firebaseio.com',
    projectId: 'stash-prod',
    storageBucket: 'stash-prod.appspot.com',
    messagingSenderId: '272756466347',
    appId: '1:272756466347:web:e29aff20e0772500de4dee',
    measurementId: 'G-FXB80037Z7',
  },
  stripeKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://api.mystash.co',
  algolia_app_id: 'APYEOJ4UNH',
  algolia_api_key: '534e91f42e5cc361776ab3c7b4dfb5a1',
};

export default config;

import { GenericReducerName } from 'Constants/genericReducerName';
import { getHandlers } from 'Store/genericActionsHelpers';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { AnyAction } from '@reduxjs/toolkit';

const handlersReducer = {
  ...getHandlers(GenericReducerName.Modal),
};

//  TODO: A: refactor this after all modules are refactored
// initial state
const INIT_STATE = {
  [GenericReducerProp.ModalSettings]: {
    isOpen: false,
  },
  [GenericReducerProp.ModalIsLoading]: false,
};

type State = typeof INIT_STATE;

export default function reducer(state = INIT_STATE, action: AnyAction): State {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}

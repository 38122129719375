import { Avatar } from '@mui/material';
import { FC, MouseEvent } from 'react';

import { extractInitials } from 'Utils/miscString';
import { User } from 'Types/user.interface';
import { hashStringToColor } from 'Utils/colors';

interface MemberAvatarProps {
  user: User,
  className?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const MemberAvatar:FC<MemberAvatarProps> = ({
  user,
  className,
  onClick,
}) => {
  const hasAvatar = !!user?.avatar;
  const userInitials = user && user?.name
    ? extractInitials(user.name, 1)
    : null;

  return (
    <Avatar
      alt="avatar-img"
      className={`size-40 rounded-circle avatar d-flex ${className}`}
      src={hasAvatar ? user?.avatar : ''}
      onClick={onClick}
      sx={{ backgroundColor: hashStringToColor(user?.name) }}
    >
      {userInitials}
    </Avatar>
  );
};

export default MemberAvatar;

import { ACTIONS } from 'Store/settings/actions';

const initialState = {
  userData: null,
  userInfo: null,
  companySettings: null,
  orgTypeSettings: null,
  systemSettings: null,
  systemInfo: null,
  isSidebarOpen: false,
};

const reducer = (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.LOADING_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          isLoading: payload,
        },
      };

    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          data: {
            ...state.userData.data,
            ...payload,
          },
        },
      };

    case ACTIONS.SET_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      };

    case ACTIONS.LOADING_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          isLoading: payload,
        },
      };

    case ACTIONS.GET_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          data: {
            ...state.companySettings.data,
            ...payload,
          },
        },
      };

    case ACTIONS.LOADING_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSettings: {
          ...state.systemSettings,
          isLoading: payload,
        },
      };

    case ACTIONS.GET_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSettings: {
          ...state.systemSettings,
          data: {
            ...state.systemSettings.data,
            ...payload,
          },
        },
      };

    case ACTIONS.LOADING_ORG_TYPE_SETTINGS:
      return {
        ...state,
        orgTypeSettings: {
          ...state.orgTypeSettings,
          isLoading: payload,
        },
      };

    case ACTIONS.GET_ORG_TYPE_SETTINGS:
      return {
        ...state,
        orgTypeSettings: {
          ...state.orgTypeSettings,
          data: {
            ...state.orgTypeSettings.data,
            ...payload,
          },
        },
      };

    case ACTIONS.LOADING_SYSTEM_INFO:
      return {
        ...state,
        systemInfo: {
          ...state.systemInfo,
          isLoading: payload,
        },
      };

    case ACTIONS.GET_SYSTEM_INFO:
      return {
        ...state,
        systemInfo: {
          ...state.systemInfo,
          data: {
            ...state.systemInfo.data,
            ...payload,
          },
        },
      };

    case ACTIONS.IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: payload,
      };

    case ACTIONS.RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;

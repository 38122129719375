// on android is handleCreateClick from BaseCreateActivity
import { cloneDeep } from 'lodash';
import { isAllowed, getAvailableBountyTypes } from 'Utils/settings';
import { BountyType, ProductKind } from 'Constants/enums';
import { CREATE_BOUNTY } from 'Constants/op';
import { isBundle, excludeFromBundle } from 'Utils/bounty';
import { Settings } from 'Types/settings.interface';
import { UserData } from 'Types/userData.interface';

export const getAllowedBountyTypes = (settings: Settings, userData: UserData) => {
  let bountyTypes = [];

  if (isAllowed({ op: CREATE_BOUNTY.name, settings, userData })) {
    bountyTypes = getAvailableBountyTypes(settings, userData);
  }

  return bountyTypes;
};

export const generateBountyOptions = ({ bountyTypes, isAddToBundle = false }: { bountyTypes: BountyType[]; isAddToBundle?: boolean }) => {
  let bountyTypesClone = cloneDeep(bountyTypes);

  if (isAddToBundle) {
    bountyTypesClone = bountyTypesClone.filter((type: BountyType) => !isBundle(type) && !excludeFromBundle(type));
  }

  return buildBountyMenu(bountyTypesClone);
};

/**
 * meniu definitions from "CreateBountyOptionsDialogFragment"
 */
const REQUEST_CODE_CREATE_BOUNTY = 30;
// const REQ_CODE_ADD_BUNDLE_CHILDREN = 31;
const REQ_CODE_CREATE_BUNDLE = 32;
const REQ_CODE_CREATE_QUEST = 35;

interface MenuOption {
  bountyType: BountyType;
  kind?: ProductKind;
  name: string;
  icon: string;
  requestCode: number;
}

const TICKET_CONFIG = {
  bountyType: BountyType.Product,
  kind: ProductKind.Ticket,
  name: 'bounty.createTicket',
  icon: 'confirmation_number',
  requestCode: REQUEST_CODE_CREATE_BOUNTY,
};

export const bountyDialogOptionsConfig: { [key in BountyType | ProductKind]?: MenuOption } = {
  [BountyType.TMOB_Challenge]: {
    bountyType: BountyType.TMOB_Challenge,
    name: 'bounty.createChallenge',
    icon: 'emoji_events',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Reservation]: {
    bountyType: BountyType.Reservation,
    name: 'bounty.createReservation',
    icon: 'chair',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.TMOB_Coaching]: {
    bountyType: BountyType.TMOB_Coaching,
    name: 'bounty.createCoaching',
    icon: 'assignment',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Album]: {
    bountyType: BountyType.Album,
    name: 'bounty.createAlbum',
    icon: 'photo_album',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.News]: {
    bountyType: BountyType.News,
    name: 'bounty.createNews',
    icon: 'pages',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Banner]: {
    bountyType: BountyType.Banner,
    name: 'bounty.createBanner',
    icon: 'add_alarm',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Product]: {
    bountyType: BountyType.Product,
    name: 'bounty.createProduct',
    icon: 'local_offer',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.TalentSearch]: {
    bountyType: BountyType.TalentSearch,
    name: 'bounty.createTalentSearch',
    icon: 'assignment', // TODO: change icon
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.RealEstateCustomer]: {
    bountyType: BountyType.RealEstateCustomer,
    name: 'bounty.createRealEstateCustomer',
    icon: 'assignment', // TODO: change icon
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Survey]: {
    bountyType: BountyType.Survey,
    name: 'bounty.createPoll',
    icon: 'insert_chart',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Classified]: {
    bountyType: BountyType.Classified,
    name: 'bounty.createClassified',
    icon: 'swap_vertical_circle',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Score]: {
    bountyType: BountyType.Score,
    name: 'bounty.askForScore',
    icon: 'star',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Bundle]: {
    bountyType: BountyType.Bundle,
    name: 'bounty.createBundle',
    icon: 'view_stream',
    requestCode: REQ_CODE_CREATE_BUNDLE,
  },
  [BountyType.Quest]: {
    bountyType: BountyType.Quest,
    name: 'bounty.createGuest',
    icon: 'view_stream',
    requestCode: REQ_CODE_CREATE_QUEST,
  },
  [BountyType.MCQ]: {
    bountyType: BountyType.MCQ,
    name: 'bounty.createQuiz',
    icon: 'playlist_add_check',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Job]: {
    bountyType: BountyType.Job,
    name: 'bounty.createJob',
    icon: 'assignment',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Thread]: {
    bountyType: BountyType.Thread,
    name: 'bounty.createCoaching',
    icon: 'message',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.LiveStream]: {
    bountyType: BountyType.LiveStream,
    name: 'bounty.createLiveStream',
    icon: 'message',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Trivia]: {
    bountyType: BountyType.Trivia,
    name: 'bounty.createTrivia',
    icon: 'quiz',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [BountyType.Lottery]: {
    bountyType: BountyType.Lottery,
    name: 'bounty.createLottery',
    icon: 'local_activity',
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [ProductKind.Ticket]: TICKET_CONFIG,
  // [BountyType.CHECKLIST]: {
  //   bountyType: BountyType.CHECKLIST,
  //   name: labels.create_checklist,
  //   icon: 'check_box',
  //   requestCode: REQUEST_CODE_CREATE_BOUNTY,
  // },
};

const subtypesOptions: { [key in BountyType]?: MenuOption[] } = {
  [BountyType.Product]: [TICKET_CONFIG],
};

export const buildBountyMenu = (bountyTypes: BountyType[]) => {
  const menuOptions: MenuOption[] = [];

  bountyTypes.forEach((type) => {
    const bountyConfig = bountyDialogOptionsConfig[type];

    if (bountyConfig) {
      menuOptions.push(bountyConfig);
    }

    const subTypes = subtypesOptions[type];

    if (subTypes) {
      menuOptions.push(...subTypes);
    }
  });

  return menuOptions;
};

import { FC, ReactElement } from 'react';
// import { Configure, InstantSearch } from 'react-instantsearch';
// import { algoliasearch } from 'algoliasearch';
// import SearchResults from 'Containers/AlgoliaSearchResults';
import { UserData } from 'Types/userData.interface';
// import { getDefaultFilters } from 'Services/SystemService';
// import { getSearchIndex } from 'Utils/algoliaSearch';
// import { AppConfig } from 'Utils/appConfig';

// const searchClient = AppConfig.algolia_app_id && AppConfig.algolia_api_key
//   ? algoliasearch(AppConfig.algolia_app_id, AppConfig.algolia_api_key)
//   : null;

interface AlgoriaInstantSearchProps {
  children: ReactElement;
  userData: UserData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AlgoriaInstantSearch:FC<AlgoriaInstantSearchProps> = ({ children, userData }) => {
  // const { searchInfo } = userData;
  // const indexName = getSearchIndex(searchInfo);
  // const filters = getDefaultFilters(searchInfo);

  // if (!searchClient) {
  //   return children;
  // }

  // I've made a hotfix removing this logic due to pipelines failure:https://bitbucket.org/edgeappinc/edgewebapp/pipelines/results/182
  // <InstantSearch
  //     searchClient={searchClient}
  //     indexName={indexName}
  // >
  //   <Configure filters={filters} />
  //   <SearchResults />
  //   {children}
  // </InstantSearch>

  return (
    children
  );
};

export default AlgoriaInstantSearch;

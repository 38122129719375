import { ACTIONS } from 'Store/groups/actions';
import { AnyAction } from '@reduxjs/toolkit';
import { ExtendedGroupMember, Group, GroupMember } from 'Types/group.interface';

interface State {
  isLoading: boolean,
  groups: ExtendedGroupMember[],
  groupProfile: Group,
  colleagues: GroupMember[],
  isColleaguesLoading: boolean,
}

const INIT_STATE: State = {
  isLoading: false,
  groups: [],
  groupProfile: {} as Group,
  colleagues: [],
  isColleaguesLoading: false,
};

const reducer = (state = INIT_STATE, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_GROUPS:
      return {
        ...state,
        groups: payload,
      };

    case ACTIONS.SET_GROUP_PROFILE:
      return {
        ...state,
        groupProfile: payload,
      };

    case ACTIONS.SET_COLLEAGUES:
      return {
        ...state,
        colleagues: payload,
      };

    case ACTIONS.SET_IS_COLLEAGUES_LOADING:
      return {
        ...state,
        isColleaguesLoading: payload,
      };

    default:
      return state;
  }
};

export default reducer;

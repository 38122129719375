import { ChangeEvent, FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notificationToast } from 'Utils/notificationToaster';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import classnames from 'classnames';

//  Components
import ReplyTo from 'Components/ReplyTo';
import CustomInput from 'Components/CustomInput';
import CustomModalButtons from 'Components/CustomModalButtons';

//  Actions
import { closeModal } from 'Store/modal/actions';
import { rateBounty } from 'Services/bounty/StatsService';
import { rateBountyResponse } from 'Services/response/StatsService';
import { ownerSelector } from 'Store/settings/selectors';
import * as userAccountSelectors from 'Store/userAccount/selectors';

//  Others
import { getDescription } from 'Utils/bounty';
import { starsValue } from 'Utils/points';
import { ThunkDispatchType } from 'Types/redux.types';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { Point } from 'Types/point.interface';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './index.module.scss';

const rateAmt = 1;
const MediumStar = () => (
  <span className={classnames(styles.star, 'material-symbols-rounded')}>
    star
  </span>
);

interface AddGradeProps {
    bounty: Bounty;
    response?: BountyResponse;
  }

const AddGrade:FC<AddGradeProps> = ({ bounty, response }) => {
  const [rateNotes, setRateNotes] = useState('');
  const [rate, setRate] = useState<Point|null>(null);
  const owner = useSelector(ownerSelector) || {};
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();
  const bountyDescription = response?.note || getDescription({ bounty });
  const { points } = useSelector(userAccountSelectors.userAccountSelector);

  const submitGrade = () => {
    if (!rate) {
      return notificationToast.error(intl.formatMessage({ id: 'info.giveRating' }));
    }

    if (response) {
      const bountyInfo = response.bountyInfo || {};
      const payload = {
        bounty: bountyInfo as Bounty, // TODO: [A] improve this, no need to send bounty as it can be read from response
        bountyResponse: response,
        text: rateNotes,
        rateAmt,
        availablePts: rate,
        ownerId: owner.id,
      };
      dispatch(rateBountyResponse(payload));
    } else {
      dispatch(rateBounty({
        bounty,
        text: rateNotes,
        rateAmt,
        availablePts: rate,
      }));
    }

    dispatch(closeModal());
  };

  const handleChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setRateNotes(value);
  };

  const handleRate = (rateInfo: Point) => {
    if (rateInfo.amount < 1) {
      const starValue = starsValue[rateInfo?.currency as keyof typeof starsValue] || 0;
      return notificationToast.warning(`You have consumed the ${starValue} star for this month!`);
    }

    setRate(rateInfo);
  };

  return (
    <div className={styles.root}>
      <ReplyTo
        description={bountyDescription}
        className="mb-10"
        truncated
      />

      <div>
        <div
          className={classnames(styles.votes, {
            [styles.active]: rate?.currency === points?.STAR3?.currency,
            [styles.disabled]: points?.STAR3?.amount < 1,
          })}
          role="presentation"
          onClick={() => handleRate(points?.STAR3)}
        >
          <div className={styles.starWrapper}>
            <MediumStar/>
            <MediumStar/>
            <MediumStar/>
          </div>
          <div
            className={classnames(labelStyles.fieldLabel, {
              [styles.disabledText]: points?.STAR3?.amount < 1,
            })}
          >
            x {points?.STAR3?.amount || 0}
          </div>
        </div>
        <div
          className={classnames(styles.votes, {
            [styles.active]: rate?.currency === points?.STAR2?.currency,
            [styles.disabled]: points?.STAR2?.amount < 1,
          })}
          role="presentation"
          onClick={() => handleRate(points?.STAR2)}
        >
          <div className={styles.starWrapper}>
            <MediumStar/>
            <MediumStar/>
          </div>
          <div
            className={classnames(labelStyles.fieldLabel, {
              [styles.disabledText]: points?.STAR2?.amount < 1,
            })}
          >
            x {points?.STAR2?.amount || 0}
          </div>
        </div>
        <div
          className={classnames(styles.votes, {
            [styles.active]: rate?.currency === points?.STAR?.currency,
            [styles.disabled]: points?.STAR?.amount < 1,
          })}
          role="presentation"
          onClick={() => handleRate(points?.STAR)}
        >
          <div className={styles.starWrapper}>
            <MediumStar/>
          </div>
          <div className={classnames(labelStyles.fieldLabel, {
            [styles.disabledText]: points?.STAR?.amount < 1,
          })}
          >
            x {points?.STAR?.amount || 0}
          </div>
        </div>
      </div>

      <div>
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.description' })}
        </Typography>
        <CustomInput
          name="rateNotes"
          value={rateNotes}
          onChange={handleChanges}
          placeholder={intl.formatMessage({ id: 'placeholder.sayNice' })}
        />
      </div>

      <CustomModalButtons
        onDismiss={() => dispatch(closeModal())}
        onSubmit={submitGrade}
      />
    </div>
  );
};

export default AddGrade;

import { FC } from 'react';
import { KeyBindingUtil } from 'draft-js';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';

import { ShortcutsLabels } from 'Constants/enums';

interface MarkdownToolbarItemProps {
  useShortcuts: boolean;
  toolbarButtons: any;
  buttonProps: any;
  toolbarShortcutsLabels: ShortcutsLabels[];
}

const MarkdownToolbarItem:FC <MarkdownToolbarItemProps> = ({ useShortcuts, toolbarButtons, buttonProps, toolbarShortcutsLabels }) => {
  const intl = useIntl();
  const getOSPrefix = KeyBindingUtil.usesMacOSHeuristics() ? 'mac-' : 'win-';
  const shortcutsKeys = {
    'win-bold': 'Ctrl-B',
    'win-unordered': 'Ctrl-Shift-7',
    'win-ordered': 'Ctrl-Shift-8',
    'mac-bold': '⌘B',
    'mac-unordered': '⇧⌘7',
    'mac-ordered': '⇧⌘8',
  };

  const getShortcutKey = (label: ShortcutsLabels) => shortcutsKeys[`${getOSPrefix}${label}`];

  const getTooltipContent = (i: number) => useShortcuts
    && toolbarShortcutsLabels[i]
    && intl.formatMessage({ id: `shortcuts.${toolbarShortcutsLabels[i]}` }, { key: getShortcutKey(toolbarShortcutsLabels[i]) });

  return (
    toolbarButtons.map((Button: any, i: number) => (
      <Tooltip
        placement="top"
        arrow
        key={`toolbar-button${i}`}
        title={getTooltipContent(i)}
      >
        <span><Button {...buttonProps} /></span>
      </Tooltip>
    ))
  );
};

export default MarkdownToolbarItem;

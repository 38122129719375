import set from 'lodash/set';
import { GenericActionType } from 'Constants/genericActionType';
import { GenericReducerName } from 'Constants/genericReducerName';
import { GenericReducerProp } from 'Constants/genericReducerProp';

/**
 * Function to use when you get data from firebase or API and you want to store data in redux
 */

// We're unable to specify the state & payload until add type to each of generic reducer and create a generic type
type GenericState = any;
type GenericPayload = any;

const updateDataAsyncReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: {
    ...state[reducerProperty],
    data: payload,
  },
});

const updateDataAsyncReducerPush = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: {
    ...state[reducerProperty],
    data: [
      ...(state[reducerProperty].data || []),
      payload,
    ],
  },
});

/**
 * Function to use when you want to put some sync data to redux store
 */

const updateDataSyncReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: {
    ...state[reducerProperty],
    ...payload,
  },
});

const updateListSyncReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: [...payload],
});

const updateSimpleDataSyncReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: payload,
});

/**
 * Function to use when you want know when getting data from firebase or API is done
 */
const isLoadingReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: {
    ...state[reducerProperty],
    isLoading: payload,
  },
});

const clearDataSyncReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: {},
});

const deleteDataSyncReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => {
  const stateClone = JSON.parse(JSON.stringify(state));

  if (stateClone[reducerProperty]) {
    delete stateClone[reducerProperty][payload];
  }

  return stateClone;
};

const isSortableReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: {
    ...state[reducerProperty],
    isSortable: payload,
  },
});

const setDataReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => {
  const newState = JSON.parse(JSON.stringify(state));
  set(newState, reducerProperty, payload);

  return newState;
};

const pushDataReducer = (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp): GenericState => ({
  ...state,
  [reducerProperty]: [
    ...(state[reducerProperty] || []),
    payload,
  ],
});

export const getHandlers = (namespace: GenericReducerName): Record<string, (state: GenericState, payload: GenericPayload, reducerProperty: GenericReducerProp) => GenericState> =>
  ({
    [`${namespace}/${GenericActionType.UpdateDataAsync}`]: updateDataAsyncReducer,
    [`${namespace}/${GenericActionType.UpdateDataAsyncPush}`]: updateDataAsyncReducerPush,
    [`${namespace}/${GenericActionType.UpdateSimpleDataSync}`]: updateSimpleDataSyncReducer,
    [`${namespace}/${GenericActionType.UpdateDataSync}`]: updateDataSyncReducer,
    [`${namespace}/${GenericActionType.UpdateListSync}`]: updateListSyncReducer,
    [`${namespace}/${GenericActionType.ClearDataSync}`]: clearDataSyncReducer,
    [`${namespace}/${GenericActionType.IsLoading}`]: isLoadingReducer,
    [`${namespace}/${GenericActionType.IsSortable}`]: isSortableReducer,
    [`${namespace}/${GenericActionType.DeleteDataSync}`]: deleteDataSyncReducer,
    [`${namespace}/${GenericActionType.DeleteDataSync}`]: deleteDataSyncReducer,
    [`${namespace}/${GenericActionType.SetDataSync}`]: setDataReducer,
    [`${namespace}/${GenericActionType.PushDataSync}`]: pushDataReducer,
  });

import Editor from '@draft-js-plugins/editor';
import { useEffect, useMemo, useRef, useState, FC, KeyboardEvent } from 'react';
import { EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BoldButton,
  OrderedListButton,
  UnorderedListButton,
  DraftJsStyleButtonType,
  DraftJsBlockAlignmentButtonType,
} from '@draft-js-plugins/buttons';

import { convertToEditorStateObj, prepareMarkdownText } from 'Utils/markdownEditor';
import classnames from 'classnames';
import ControlValidation from 'Components/ControlValidation';
import MarkdownToolbarItem from 'Components/MarkdownToolbarItem';
import useDebounce from 'Hooks/useDebounce';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { ShortcutsLabels } from 'Constants/enums';
import * as styles from './index.module.scss';

interface MarkdownEditorControlProps {
  className?: string;
  value: string;
  toolbarButtons?: Array<DraftJsStyleButtonType> | Array<DraftJsBlockAlignmentButtonType>;
  validationErrors?: string[];
  isSuccess?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: (text: string) => void;
  toolbarShortcutsLabels?: ShortcutsLabels[];
  useShortcuts?: boolean;
}

interface EditorButtonProps {
  getEditorState: () => EditorState;
}

const MarkdownEditorControl:FC <MarkdownEditorControlProps> = ({
  className = '',
  value,
  toolbarButtons = [BoldButton, UnorderedListButton, OrderedListButton],
  validationErrors,
  isSuccess = false,
  disabled = false,
  placeholder = '',
  onChange,
  toolbarShortcutsLabels = [ShortcutsLabels.Bold, ShortcutsLabels.Unordered, ShortcutsLabels.Ordered],
  useShortcuts = false,
}) => {
  const [editorStateObj, setEditorStateObj] = useState(EditorState.createEmpty());
  const [isFocused, setIsFocused] = useState(false);
  const [markCounter, setMarkCounter] = useState(0);
  const [isForceHiddenPlaceholder, setIsForceHiddenPlaceholder] = useState(false);

  const [draftJsWrapper] = useState(() => {
    const linkifyPlugin = createLinkifyPlugin();
    const toolbarPlugin = createToolbarPlugin();
    const linkPlugin = createLinkPlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, linkifyPlugin, linkPlugin];
    return { plugins, Toolbar };
  });

  const { plugins, Toolbar } = draftJsWrapper;
  const { handleKeyCommand, toggleBlockType } = RichUtils;

  const editorRef = useRef(null);

  useEffect(() => {
    const content = editorStateObj.getCurrentContent();
    setIsForceHiddenPlaceholder(content.hasText() || content.getBlockMap()
      .first()
      .getType() !== 'unstyled');
  }, [editorStateObj]);

  const setFocus = () => editorRef.current.focus();

  useMemo(() => {
    if (value !== prepareMarkdownText(editorStateObj)) {
      setEditorStateObj(convertToEditorStateObj(value));
    }
  }, [value]);

  const debounceChangeEvent = useDebounce(editorStateObj, 1000);

  useEffect(() => {
    const updText = prepareMarkdownText(editorStateObj);
    if (value !== updText) {
      onChange(prepareMarkdownText(editorStateObj));
    }
    setMarkCounter(markCounter + 1);
  }, [debounceChangeEvent]);

  const renderToolbar = () => (
    <div className={styles.toolbarWrapper}>
      <Toolbar>
        {(buttonProps: EditorButtonProps) => !!buttonProps.getEditorState
          && (
            <MarkdownToolbarItem
              toolbarButtons={toolbarButtons}
              buttonProps={buttonProps}
              useShortcuts={useShortcuts}
              toolbarShortcutsLabels={toolbarShortcutsLabels}
            />
          )}
      </Toolbar>
    </div>
  );

  const handleCommand = (command: string, editorState: EditorState) => handleKeyCommand(editorState, command) && setEditorStateObj(handleKeyCommand(editorState, command));

  const handleBlockType = (command: string, editorState: EditorState) => toggleBlockType(editorState, command) && setEditorStateObj(toggleBlockType(editorState, command));

  const customKeyBindingFn = (event: KeyboardEvent) => {
    const { keyCode, metaKey, shiftKey, ctrlKey } = event;

    switch (keyCode) {
      case 66: // 66 = b keyboard key
        if (metaKey || ctrlKey) {
          handleCommand('bold', editorStateObj);
          return undefined;
        }
        break;

      case 55: // 55 = 7 keyboard key
        if (shiftKey && (metaKey || ctrlKey)) {
          handleBlockType('unordered-list-item', editorStateObj);
          return undefined;
        }
        break;

      case 56: // 56 = 8 keyboard key
        if (shiftKey && (metaKey || ctrlKey)) {
          handleBlockType('ordered-list-item', editorStateObj);
          return undefined;
        }
        break;

      default:
        break;
    }
    return getDefaultKeyBinding(event);
  };

  return (
    <>
      <div className={classnames({
        [className]: className,
        [styles.stateInvalid]: validationErrors && validationErrors?.length > 0,
        [styles.stateSuccess]: isSuccess,
        [styles.stateDisabled]: disabled,
        [styles.focused]: isFocused,
      })}
      >
        <div className={styles.control}>
          {toolbarButtons?.length > 0 ? renderToolbar() : null}
          <div
            className={styles.editorWrapper}
            onClick={setFocus}
            role="presentation"
          >
            <Editor
              placeholder={!isForceHiddenPlaceholder && placeholder ? placeholder : null}
              readOnly={disabled}
              ref={editorRef}
              editorState={editorStateObj}
              onChange={setEditorStateObj}
              plugins={plugins}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              keyBindingFn={useShortcuts ? customKeyBindingFn : undefined}
            />
          </div>
        </div>
      </div>

      {validationErrors && validationErrors?.length > 0 && <ControlValidation validationErrors={validationErrors} />}
    </>
  );
};

export default MarkdownEditorControl;

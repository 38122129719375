import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { cloneDeep, isEmpty, set } from 'lodash';

import StyledSelect from 'Components/StyledSelect';
import CustomDropzone from 'Components/CustomDropzone';
import LoaderOverlay from 'Components/LoaderOverlay';

import { getOpenCategories } from 'Services/CategoryService';

import { Attachment } from 'Types/attachment.interface';
import { Category } from 'Types/category.interface';
import { AttachmentAction, BountyType, CompanyType } from 'Constants/enums';
import { notificationToast } from 'Utils/notificationToaster';
import { companySettingsSelector, userDataSelector } from 'Store/settings/selectors';
import { formatCategoriesForAttachment, getSelectedCategories } from 'Utils/categories';
import { IMAGE_MIME_TYPES } from 'Constants/attachment';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './index.module.scss';

interface HighlightBannerProps {
  selectedCompanyId?: string;
  attachment: Attachment;
  onChange: (attachment: Partial<Attachment>) => void;
  index: number;
}
const acceptedFiles = IMAGE_MIME_TYPES.reduce((prevVal, currVal) => ({ ...prevVal, [currVal]: [] }), {});

const HighlightBanner: FC<HighlightBannerProps> = ({
  selectedCompanyId,
  attachment,
  onChange,
  index,
}) => {
  const intl = useIntl();
  const userData = useSelector(userDataSelector)?.data || {};
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category>({} as Category);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const isMerchant = currentOrgType === CompanyType.Merchant;
  const companyId = isMerchant ? userData?.company?.id : selectedCompanyId;
  const url = attachment?.cdnUrl || attachment?.url || attachment?.localUri;

  useEffect(() => {
    if (companyId) {
      fetchProductCategories();
    }
  }, [companyId]);

  useEffect(() => {
    if (!isEmpty(categories) && !selectedCategories?.length) {
      setSelectedCategories(getSelectedCategories(attachment, categories));
    }
  }, [attachment, categories]);

  useEffect(() => {
    setSelectedCategories([]);
  }, [companyId]);

  const fetchProductCategories = async () => {
    setIsLoading(true);
    try {
      const response = await getOpenCategories(BountyType.Product, companyId);
      setCategories(response);
    } catch (e: any) {
      notificationToast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectCategory = ({ target: { value } }: SelectChangeEvent<unknown>, index: number) => {
    const isAllOption = value === '';
    const category = isAllOption ? value : JSON.parse(value as string);
    let newCategories = [...selectedCategories];

    if (index + 1 !== selectedCategories?.length) {
      newCategories = newCategories.slice(0, index + 1);
    }

    if (!isAllOption) {
      newCategories.push(category);
    }

    setSelectedCategories(newCategories);

    const newAttachment = set(cloneDeep(attachment), 'action', {
      ...attachment?.action,
      categories: formatCategoriesForAttachment(newCategories),
      tags: '',
    });
    onChange(newAttachment);
  };

  const handlePickFile = (data: Attachment[]) => {
    onChange({ ...attachment, ...data?.[0] });
  };

  const handleClear = () => onChange({ action: { actionType: AttachmentAction.Search } });

  return (
    <div>
      <div className={styles.header}>
        <h4>{intl.formatMessage({ id: 'label.categoryNumber' }, { number: index + 1 })}</h4>
        {url && (
          <span
            role="presentation"
            onClick={handleClear}
            className={classnames(styles.clear, 'material-symbols-rounded')}
          >
            close
          </span>
        )}
      </div>

      <div className={styles.cardWrapper}>
        {url ? (
          <img
            alt="hero"
            src={url}
            className={styles.image}
          />
        ) : (
          <CustomDropzone
            accept={acceptedFiles}
            onFilePick={handlePickFile}
            onSetLoading={setIsLoading}
          >
            <div className={styles.uploadButton}>
              <span className="material-symbols-rounded font-24 mr-5">
                <span className="upload" />
              </span>
              <b>{intl.formatMessage({ id: 'button.uploadImage' })}</b>
            </div>
          </CustomDropzone>
        )}
      </div>

      {selectedCategories?.map((category, index) => {
        const subCategories = category?.children;

        return subCategories && subCategories?.length > 0 ? (
          <div className="mt-10" key={category?.code}>
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: index === 0 ? 'label.selectCategory' : 'label.selectSubCategory' })}
            </Typography>
            <StyledSelect
              name="banner.category"
              value={selectedCategories?.[index + 1] ? JSON.stringify(selectedCategories?.[index + 1]) : ''}
              onChange={(e) => handleSelectCategory(e, index)}
              displayEmpty
            >
              <MenuItem value="">
                {intl.formatMessage({ id: 'label.all' })}
              </MenuItem>
              {subCategories?.map((subCategory) => (
                <MenuItem key={subCategory?.code} value={JSON.stringify(subCategory)}>
                  {subCategory?.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
        ) : null;
      })}

      {isLoading && <LoaderOverlay />}
    </div>
  );
};

export default HighlightBanner;

import * as ROUTES from 'Constants/routes';
import { ComponentType, PropsWithChildren } from 'react';
import { Permissions } from 'Types/permissions.type';
import { getPageContainer } from '../utils';
import * as AsyncComponents from './AsyncRoutes';

interface RouteDetail {
  path: string;
  Component: ComponentType<PropsWithChildren<Record<string, unknown>>>;
  container?: ComponentType<PropsWithChildren<Record<string, unknown>>>;
}

const STREAM_DETAILS: RouteDetail = {
  path: ROUTES.STREAM_DETAILS_WITH_TYPE_ROUTE,
  Component: AsyncComponents.AsyncBountyDetailsPage,
};

const JOB_DETAILS: RouteDetail = {
  path: ROUTES.JOB_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncJobDetailsPage,
};

const JOB_ADD: RouteDetail = {
  path: ROUTES.JOB_ADD_ROUTE,
  Component: AsyncComponents.AsyncJobEditPage,
};

const JOB_EDIT: RouteDetail = {
  path: ROUTES.JOB_EDIT_ROUTE,
  Component: AsyncComponents.AsyncJobEditPage,
};

const SIMPLE_STREAM_DETAILS: RouteDetail = {
  path: ROUTES.STREAM_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncBountyDetailsPage,
};

const STREAM_SUB_DETAILS: RouteDetail = {
  path: ROUTES.STREAM_SUB_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncBountyDetailsPage,
};

const RESPONSE_DETAILS: RouteDetail = {
  path: ROUTES.RESPONSE_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncBountyResponseDetailsPage,
};

const MY_ACCOUNT: RouteDetail = {
  path: ROUTES.MY_ACCOUNT_ROUTE,
  Component: AsyncComponents.AsyncUserAccountPage,
};

const COMPANY_ACCOUNT: RouteDetail = {
  path: ROUTES.COMPANY_ACCOUNT_ROUTE,
  Component: AsyncComponents.AsyncCompanyAccountPage,
};

const LEADERBOARD: RouteDetail = {
  path: ROUTES.LEADERBOARD_ROUTE,
  Component: AsyncComponents.AsyncLeaderboardPage,
};

const ORG_PROFILE: RouteDetail = {
  path: '/:companyType/profile/:userId',
  Component: AsyncComponents.AsyncDefaultUserProfilePage,
};

const LOGGED_OUT_PROFILE: RouteDetail = {
  path: ROUTES.LOGGED_OUT_PROFILE_ROUTE,
  Component: AsyncComponents.AsyncDefaultUserProfilePage,
};

const USER_SENTS: RouteDetail = {
  path: ROUTES.USER_SENTS_ROUTE,
  Component: AsyncComponents.AsyncSentsContainer,
};

const USER_SENTS_DETAILS: RouteDetail = {
  path: ROUTES.USER_SENTS_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncBountyDetailsPage,
};

const USER_SENTS_SUB_DETAILS: RouteDetail = {
  path: ROUTES.USER_SENTS_SUB_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncBountyDetailsPage,
};

export const CHAT: RouteDetail = {
  path: ROUTES.CHAT_ROUTE,
  Component: AsyncComponents.AsyncChatComponent,
};

const CHAT_ROOM: RouteDetail = {
  path: ROUTES.CHAT_ROOM_ROUTE,
  Component: AsyncComponents.AsyncChatComponent,
};

const BOUNTY_SEARCH: RouteDetail = {
  path: ROUTES.BOUNTY_SEARCH_BY_HASHTAGS,
  Component: AsyncComponents.AsyncBountyHashtagResults,
};

const CART: RouteDetail = {
  path: ROUTES.CART_ROUTE,
  Component: AsyncComponents.AsyncCartPage,
};

const GROUPS: RouteDetail = {
  path: ROUTES.GROUPS_ROUTE,
  Component: AsyncComponents.AsyncGroupsPage,
};

const GROUPS_PROFILE: RouteDetail = {
  path: ROUTES.GROUPS_PROFILE_ROUTE,
  Component: AsyncComponents.AsyncGroupProfilePage,
};

const ORDER_LIST: RouteDetail = {
  path: ROUTES.ORDER_LIST_ROUTE,
  Component: AsyncComponents.AsyncOrderPage,
};

const RECEIVED_ORDER_LIST: RouteDetail = {
  path: ROUTES.RECEIVED_ORDER_LIST_ROUTE,
  Component: AsyncComponents.AsyncOrderReceivedPage,
};

const ORDER: RouteDetail = {
  path: ROUTES.ORDER_ROUTE,
  Component: AsyncComponents.AsyncOrderDetails,
};

const RECEIVED_ORDER: RouteDetail = {
  path: ROUTES.RECEIVED_ORDER_ROUTE,
  Component: AsyncComponents.AsyncOrderDetailsShop,
};

const CAMPAIGNS_RECEIVED_ORDER: RouteDetail = {
  path: ROUTES.RECEIVED_ORDER_ROUTE,
  Component: AsyncComponents.AsyncOrderDetailsCampaigns,
};

const PRODUCTS_DETAIL: RouteDetail = {
  path: ROUTES.PRODUCTS_DETAIL_ROUTE,
  Component: AsyncComponents.AsyncProductsDetailContainer,
};

const USER_ADDRESSES: RouteDetail = {
  path: ROUTES.USER_ADDRESSES_ROUTE,
  Component: AsyncComponents.AsyncUserAddressesPage,

};

const USER_CARDS: RouteDetail = {
  path: ROUTES.USER_CARDS_ROUTE,
  Component: AsyncComponents.AsyncUserCreditCardsPage,

};

const MERCHANT_PROFILE: RouteDetail = {
  path: ROUTES.MERCHANT_PROFILE_ROUTE,
  Component: AsyncComponents.AsyncMerchantProfilePage,

};

const COMPANY_CREATE: RouteDetail = {
  path: ROUTES.COMPANY_CREATE_ROUTE,
  Component: AsyncComponents.AsyncCreateOrganization,
};

const COMPANY_PRODUCTS_SENTS: RouteDetail = {
  path: ROUTES.COMPANY_SENTS_ROUTE,
  Component: AsyncComponents.AsyncCompanyProductsSents,
};

const UPLOAD_PRODUCTS: RouteDetail = {
  path: ROUTES.UPLOAD_PRODUCTS_ROUTE,
  Component: AsyncComponents.AsyncUploadProductsContainer,
};

const MY_PRODUCTS: RouteDetail = {
  path: ROUTES.MY_PRODUCTS_ROUTE,
  Component: AsyncComponents.AsyncMyProductsPage,
};

const REPORTS: RouteDetail = {
  path: ROUTES.REPORTS_ROUTE,
  Component: AsyncComponents.AsyncReportsContainer,
};

const COMPANY_PROFILE: RouteDetail = {
  path: ROUTES.COMPANY_PROFILE_ROUTE,
  Component: getPageContainer({ page: 'profile', defaultContainer: AsyncComponents.AsyncDefaultUserProfilePage }),
};

export const PRODUCTS: RouteDetail = {
  path: ROUTES.PRODUCTS_ROUTE,
  Component: AsyncComponents.AsyncBountiesPage,
};

export const COMPANY_PIN: RouteDetail = {
  path: ROUTES.COMPANY_PIN_ROUTE,
  Component: AsyncComponents.AsyncCompanyPinContainer,
};

export const PROMOTION: RouteDetail = {
  path: ROUTES.PROMOTIONS_ROUTE,
  Component: AsyncComponents.AsyncPromotionsPage,
};

export const PROMOTION_DETAILS: RouteDetail = {
  path: ROUTES.PROMOTION_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncPromotionDetailsPage,
};

export const BADGE_COLLECTIONS: RouteDetail = {
  path: ROUTES.BADGE_COLLECTIONS_ROUTE,
  Component: AsyncComponents.AsyncBadgeCollectionsPage,
};

export const BADGE_COLLECTION_DETAILS: RouteDetail = {
  path: ROUTES.BADGE_COLLECTION_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncBadgeCollectionDetailsPage,
};

export const CREATE_NOTIFICATION: RouteDetail = {
  path: ROUTES.CREATE_NOTIFICATION_ROUTE,
  Component: AsyncComponents.AsyncNotificationCreatePage,
};

export const MERCHANT_LANDING: RouteDetail = {
  path: ROUTES.MERCHANT_LANDING_ROUTE,
  Component: AsyncComponents.AsyncMerchantLanding,
};

export const MERCHANT_SIGNUP: RouteDetail = {
  path: ROUTES.MERCHANT_SIGNUP_ROUTE,
  Component: AsyncComponents.AsyncMerchantSignup,
};

export const MERCHANT_REGISTER_COMPANY: RouteDetail = {
  path: ROUTES.MERCHANT_REGISTER_COMPANY_ROUTE,
  Component: AsyncComponents.AsyncMerchantRegisterCompany,
};

export const MERCHANT_URL_COMPANY: RouteDetail = {
  path: ROUTES.MERCHANT_URL_COMPANY_ROUTE,
  Component: AsyncComponents.AsyncMerchantUrlCompany,
};

export const NOT_FOUND: RouteDetail = {
  path: ROUTES.NOT_FOUND,
  Component: AsyncComponents.AsyncNotFoundPage,
};

export const LANDING_STEP: RouteDetail = {
  path: ROUTES.LANDING_STEP_ROUTE,
  Component: AsyncComponents.AsyncOnBoardingLayout,
};

export const LANDING: RouteDetail = {
  path: ROUTES.LANDING_ROUTE,
  Component: AsyncComponents.AsyncOnBoardingLayout,
};

export const APPLICATIONS: RouteDetail = {
  path: ROUTES.APPLICATIONS_ROUTE,
  Component: AsyncComponents.AsyncApplicationsPage,
};

export const APPLICATION_DETAILS: RouteDetail = {
  path: ROUTES.APPLICATION_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncApplicationDetailsPage,
};

export const APPLICANTS: RouteDetail = {
  path: ROUTES.APPLICANTS_ROUTE,
  Component: AsyncComponents.AsyncApplicantsPage,
};

export const CANDIDATES: RouteDetail = {
  path: ROUTES.CANDIDATES_ROUTE,
  Component: AsyncComponents.AsyncCandidatesPage,
};

export const TERMINAL: RouteDetail = {
  path: ROUTES.TERMINAL_ROUTE,
  Component: AsyncComponents.AsyncTerminal,
};

export const USER_MANAGEMENT: RouteDetail = {
  path: ROUTES.USER_MANAGEMENT_ROUTE,
  Component: AsyncComponents.AsyncUsersPage,
};

export const COMPANY_USERS_LIST: RouteDetail = {
  path: ROUTES.COMPANY_USERS,
  Component: AsyncComponents.AsyncCompanyUsersPage,
};

export const MATCHES_LIST: RouteDetail = {
  path: ROUTES.MATCHES_ROUTE,
  Component: AsyncComponents.AsyncMatchesPage,
};

export const MATCH_DETAILS_LIST: RouteDetail = {
  path: ROUTES.MATCH_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncMatchDetailsPage,
};

export const V2JOBS: RouteDetail = {
  path: ROUTES.JOBS_ROUTE,
  Component: AsyncComponents.AsyncJobsPage,
};

export const SEND_INVITES: RouteDetail = {
  path: ROUTES.SEND_INVITES_ROUTE,
  Component: AsyncComponents.AsyncSendInvites,
};

const DEFAULT_HOME: RouteDetail = {
  path: ROUTES.HOME_ROUTE,
  Component: getPageContainer({ page: 'home', defaultContainer: AsyncComponents.AsyncBountiesPage }),
};

export const HOME: RouteDetail = {
  path: ROUTES.V2_HOME_ROUTE,
  Component: getPageContainer({ page: 'home', defaultContainer: AsyncComponents.AsyncBountiesPage }),
};

export const DASHBOARD_RESERVATIONS: RouteDetail = {
  path: ROUTES.DASHBOARD_RESERVATIONS_ROUTE,
  Component: AsyncComponents.AsyncReservationsPage,
};

export const RESERVATION_DETAILS: RouteDetail = {
  path: ROUTES.RESERVATION_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncReservationDetailsPage,
};

export const USER_PROFILE: RouteDetail = {
  path: ROUTES.USER_DETAILS_ROUTE,
  Component: getPageContainer({ page: 'profile', defaultContainer: AsyncComponents.AsyncDefaultUserProfilePage }),
};

const PROFILE_EDIT: RouteDetail = {
  path: ROUTES.USER_EDIT_ROUTE,
  Component: AsyncComponents.AsyncProfileEditPage,
};

const CLIENTS_LISTING: RouteDetail = {
  path: ROUTES.CLIENTS_ROUTE,
  Component: AsyncComponents.AsyncClientsPage,
};

const CLIENT_DETAILS: RouteDetail = {
  path: ROUTES.CLIENT_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncClientDetailsPage,
};

const CLIENT_EMBED: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_EMBED_ROUTE,
  Component: AsyncComponents.AsyncClientEmbedPage,
};

const CLIENT_SHOP_ITEMS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_SHOP_ITEMS_ROUTE,
  Component: AsyncComponents.AsyncClientShopItemsPage,
};

const CLIENT_ONBOARDING: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_ONBOARDING_ROUTE,
  Component: AsyncComponents.AsyncClientOnboardingPage,
};

const CLIENT_SUPPORT_SETTINGS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_SUPPORT_ROUTE,
  Component: AsyncComponents.AsyncClientSupportSettingsPage,
};

const CLIENT_SEO_SETTINGS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_SEO_ROUTE,
  Component: AsyncComponents.AsyncClientSeoSettingsPage,
};

const CLIENT_HOURS_OF_OPERATION: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_HOURS_OF_OPERATION_ROUTE,
  Component: AsyncComponents.AsyncClientSchedulePage,
};

const CLIENT_CHECKOUT: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_CHECKOUT_ROUTE,
  Component: AsyncComponents.AsyncClientCheckoutPage,
};

const CLIENT_BRANDING: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_BRANDING_ROUTE,
  Component: AsyncComponents.AsyncClientBrandingPage,
};

const CLIENT_LAYOUT_AND_SORTING: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_LAYOUT_AND_SORTING_ROUTE,
  Component: AsyncComponents.AsyncClientLayoutAndSortingPage,
};

const CLIENT_LAB_RESULTS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_LAB_RESULTS_ROUTE,
  Component: AsyncComponents.AsyncClientLabResultsPage,
};

const CLIENT_GENERAL: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_GENERAL_ROUTE,
  Component: AsyncComponents.AsyncClientGeneralPage,
};

const CLIENT_CART: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_CART_ROUTE,
  Component: AsyncComponents.AsyncClientCartPage,
};

const CLIENT_EMAILS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_EMAILS_ROUTE,
  Component: AsyncComponents.AsyncClientEmailsPage,
};

const CLIENT_KIOSKS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_KIOSKS_ROUTE,
  Component: AsyncComponents.AsyncClientKioskPage,
};

const CLIENT_SITE_SETTINGS: RouteDetail = {
  path: ROUTES.SHOP_SETTINGS_SITE_SETTINGS_ROUTE,
  Component: AsyncComponents.AsyncClientSiteSettingsPage,
};

const CLIENT_INVENTORY_SETTINGS: RouteDetail = {
  path: ROUTES.SHOP_INVENTORY_SETTINGS_ROUTE,
  Component: AsyncComponents.AsyncClientInventorySettingsPage,
};

const CLIENT_LOCATIONS: RouteDetail = {
  path: ROUTES.SHOP_LOCATIONS_ROUTE,
  Component: AsyncComponents.AsyncClientLocationsPage,
};

const COMPANY_MEMBERS: RouteDetail = {
  path: ROUTES.COMPANY_MEMBERS_ROUTE,
  Component: AsyncComponents.AsyncCompanyMembersPage,
};

const BANNERS: RouteDetail = {
  path: ROUTES.BANNERS_ROUTE,
  Component: AsyncComponents.AsyncBannersPage,
};

const ANALYTICS: RouteDetail = {
  path: ROUTES.ANALYTICS_ROUTE,
  Component: AsyncComponents.AsyncAnalyticsPage,
};

const LOCATION_DETAILS: RouteDetail = {
  path: ROUTES.SHOP_LOCATION_DETAILS_ROUTE,
  Component: AsyncComponents.AsyncLocationDetailsPage,
};

const MY_SHOP: RouteDetail = {
  path: ROUTES.MY_SHOP_ROUTE,
  Component: AsyncComponents.AsyncMyShopPage,
};

const USER_SHOP_POLICIES: RouteDetail = {
  path: ROUTES.USER_SHOP_POLICIES,
  Component: AsyncComponents.AsyncShopPolicyDetailsPage,
};

const EMPLOYER_INTERFACE: RouteDetail = {
  path: ROUTES.EMPLOYER_ROUTE,
  Component: AsyncComponents.AsyncEmployerPage,
};

const EMPLOYER_AUTH: RouteDetail = {
  path: ROUTES.EMPLOYER_AUTH_ROUTE,
  Component: AsyncComponents.AsyncEmployerAuth,
};

const EMPLOYER_SUCCESS: RouteDetail = {
  path: ROUTES.EMPLOYER_SUCCESS_ROUTE,
  Component: AsyncComponents.AsyncEmployerSuccess,
};

const DOCUMENTS_SEARCH: RouteDetail = {
  path: ROUTES.DOCUMENTS_SEARCH_ROUTE,
  Component: AsyncComponents.AsyncDocumentsSearchPage,
};

const CHAT_AI_BOT: RouteDetail = {
  path: ROUTES.CHAT_AI_ROUTE,
  Component: AsyncComponents.AsyncAiChatBotPage,
};

const TALK_TO_SUPPORT: RouteDetail = {
  path: ROUTES.TALK_TO_SUPPORT_ROUTE,
  Component: AsyncComponents.AsyncTalkToSupportPage,
};

const PURCHASE_TOKENS: RouteDetail = {
  path: ROUTES.PURCHASE_TOKENS_ROUTE,
  Component: AsyncComponents.AsyncPurchaseTokensPage,
};

const CUSTOM_TOKENS: RouteDetail = {
  path: ROUTES.CUSTOM_TOKENS_ROUTE,
  Component: AsyncComponents.AsyncCustomTokensPage,
};

export const REVIEWS: RouteDetail = {
  path: ROUTES.REVIEWS_ROUTE,
  Component: AsyncComponents.AsyncReviewsPage,
};

const CAMPAIGNS: RouteDetail = {
  path: ROUTES.CAMPAIGNS_ROUTE,
  Component: AsyncComponents.AsyncCampaignsPage,
};

const CREATE_CAMPAIGN: RouteDetail = {
  path: ROUTES.CREATE_CAMPAIGN_ROUTE,
  Component: AsyncComponents.AsyncCreateCampaignPage,
};

const AUTH_ONBOARDING: RouteDetail = {
  path: ROUTES.ONBOARDING_ROUTE,
  Component: AsyncComponents.AsyncAuthenticationOnboardingPage,
};

export const commonRoutesWithoutLayout: RouteDetail[] = [
  MERCHANT_LANDING,
  MERCHANT_SIGNUP,
  MERCHANT_REGISTER_COMPANY,
  EMPLOYER_INTERFACE,
  EMPLOYER_AUTH,
  EMPLOYER_SUCCESS,
  MERCHANT_URL_COMPANY,
  USER_SHOP_POLICIES,
  LANDING_STEP,
  LANDING,
  AUTH_ONBOARDING,
];

export const commonRoutes: RouteDetail[] = [
  DEFAULT_HOME,
  STREAM_DETAILS,
  SIMPLE_STREAM_DETAILS,
  STREAM_SUB_DETAILS,
  RESPONSE_DETAILS,
  JOB_DETAILS,
  JOB_EDIT,
  ORG_PROFILE,
  LOGGED_OUT_PROFILE,
  USER_SENTS,
  USER_SENTS_DETAILS,
  USER_SENTS_SUB_DETAILS,
  CHAT,
  CHAT_ROOM,
  BOUNTY_SEARCH,
  PRODUCTS_DETAIL,
  USER_ADDRESSES,
  USER_CARDS,
  MERCHANT_PROFILE,
  PRODUCTS,
  COMPANY_PIN,
  COMPANY_PROFILE,
  GROUPS,
  GROUPS_PROFILE,
  HOME,
  TERMINAL,
  V2JOBS,
  NOT_FOUND,
  USER_PROFILE,
  BANNERS,
];

export const getUploadsProductsRoutes = (canUploadProducts: boolean) => (
  canUploadProducts ? [COMPANY_PRODUCTS_SENTS, UPLOAD_PRODUCTS] : []);

export const getReportRoutes = (canSeeReports: boolean) => (
  canSeeReports ? [REPORTS] : []
);

export const getBadgeManagementRoutes = (canCreateBadge: boolean) => (
  canCreateBadge
    ? [
      BADGE_COLLECTIONS,
      BADGE_COLLECTION_DETAILS,
    ]
    : []);

export const getPromotionsRoutes = (canSeePromotions: boolean) => (
  canSeePromotions ? [PROMOTION, PROMOTION_DETAILS] : []
);

export const getDefaultRoutes = (permissions: Permissions): RouteDetail[] => {
  const {
    canUploadProducts,
    canSeeReports,
    canSeePromotions,
    canCreateBadge,
  } = permissions;
  const routesForAllowedToUploadProducts = getUploadsProductsRoutes(canUploadProducts);
  const reportRoutes = getReportRoutes(canSeeReports);
  const promotionsRoutes = getPromotionsRoutes(canSeePromotions);
  const badgeManagement = getBadgeManagementRoutes(canCreateBadge);

  return [
    ...commonRoutes,
    ...routesForAllowedToUploadProducts,
    ...reportRoutes,
    ...promotionsRoutes,
    ...badgeManagement,
    ...(permissions.canSeeMyAccount ? [MY_ACCOUNT] : []),
    ...(permissions.canSeeCompanyAccount ? [COMPANY_ACCOUNT] : []),
    ...(permissions.canSeeMarket ? [ORDER_LIST, ORDER] : []),
    ...(permissions.canSeeOrderHistory ? [ORDER_LIST, ORDER] : []),
    ...(permissions.canSeeCart ? [CART] : []),
    ...(permissions.canSeeRecvOrders ? [RECEIVED_ORDER_LIST, RECEIVED_ORDER] : []),
    ...(permissions.canSeeLeaderBoard ? [LEADERBOARD] : []),
    ...(permissions.canNotify ? [CREATE_NOTIFICATION] : []),
    ...(permissions.canCreateJobs ? [JOB_ADD, JOB_EDIT] : []),
    ...(permissions.canSeeResponses ? [APPLICATIONS, APPLICANTS, CANDIDATES, APPLICATION_DETAILS] : []),
    ...(permissions.canSeeCompanies ? [COMPANY_USERS_LIST] : []),
    ...(permissions.canSeeMatch ? [MATCHES_LIST, MATCH_DETAILS_LIST] : []),
    ...(permissions.canCreateOrganization ? [COMPANY_CREATE] : []),
    ...(permissions.canEditProfile ? [PROFILE_EDIT] : []),
    ...(permissions.canSeeUserManagement ? [USER_MANAGEMENT] : []),
    ...(permissions.canHaveManyInviteLinks ? [SEND_INVITES] : []),
    ...(permissions.canSeeReservations ? [DASHBOARD_RESERVATIONS, RESERVATION_DETAILS] : []),
    ...(permissions.canSeeClients ? [CLIENTS_LISTING, CLIENT_DETAILS] : []),
    ...(permissions.canSeeCampaigns ? [CAMPAIGNS] : []),
    ...(permissions.canCreateCampaign ? [CREATE_CAMPAIGN] : []),
    ...(permissions.canSeeShopSettings
      ? [
        CLIENT_GENERAL,
        CLIENT_EMBED,
        CLIENT_BRANDING,
        CLIENT_LAYOUT_AND_SORTING,
        CLIENT_LAB_RESULTS,
        CLIENT_ONBOARDING,
        CLIENT_SEO_SETTINGS,
        CLIENT_SHOP_ITEMS,
        CLIENT_SUPPORT_SETTINGS,
        CLIENT_CART,
        CLIENT_CHECKOUT,
        CLIENT_HOURS_OF_OPERATION,
        CLIENT_EMAILS,
        CLIENT_KIOSKS,
        CLIENT_INVENTORY_SETTINGS,
        CLIENT_SITE_SETTINGS,
      ]
      : []),
    ...(permissions.canSeeAiBot ? [CHAT_AI_BOT] : []),
    ...(permissions.canTalkToSupport ? [TALK_TO_SUPPORT] : []),
    ...(permissions.canSeeDocSearch ? [DOCUMENTS_SEARCH] : []),
    ...(permissions.canSeeTokenPackages ? [PURCHASE_TOKENS] : []),
    ...(permissions.canSeeCustomTokens ? [CUSTOM_TOKENS] : []),
    ...(permissions.canSeeReviews ? [REVIEWS] : []),
    ...(permissions.canSeeShop ? [MY_SHOP, UPLOAD_PRODUCTS, CAMPAIGNS_RECEIVED_ORDER] : []),
    ...(permissions.canSeeMyProducts ? [MY_PRODUCTS, UPLOAD_PRODUCTS] : []),
    ...(permissions.canSeeLocations || permissions.canSeeClients
      ? [CLIENT_LOCATIONS, LOCATION_DETAILS, RECEIVED_ORDER]
      : []),
    ...(permissions.canSeeCompanyMembers ? [COMPANY_MEMBERS] : []),
    ...(permissions.canSeeAnalytics ? [ANALYTICS] : []),
  ];
};

import { UsersFilters } from 'Types/usersFilters.interface';
import { AppDispatch } from 'Types/redux.types';
import { UsersRequest } from 'Types/usersRequest.interface';

export enum Actions {
  UpdateFilters = 'usersFiltersReducer/UpdateFilters',
  UpdateReqProps = 'usersFiltersReducer/UpdateReqProps',
  ResetFilters = 'usersFiltersReducer/ResetFilters',
  ResetAll = 'usersFiltersReducer/ResetAll',
  UpdateFilterId = 'usersFiltersReducer/UpdateFilterId',
}

export enum UsersFiltersReducerAlias {
  CommonPage = 'Common',
  UserProfileTab = 'Profile',
}

export const getUsersFiltersActionName = (actionName: Actions, alias: UsersFiltersReducerAlias): string =>
  `${actionName}_${alias}`;

const updateUsersReqProps = (alias: UsersFiltersReducerAlias) => (reqProps: Partial<UsersRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: getUsersFiltersActionName(Actions.UpdateReqProps, alias), payload: reqProps });

export const updateUsersOffset = (alias: UsersFiltersReducerAlias) => (offset: number) =>
  updateUsersReqProps(alias)({ start: offset });

export const updateUsersLimit = (alias: UsersFiltersReducerAlias) => (limit: number) =>
  updateUsersReqProps(alias)({ limit });

export const updateUsersOrder = (alias: UsersFiltersReducerAlias) => (order: string) =>
  updateUsersReqProps(alias)({ order, start: 0 });

export const updateUsersQuery = (alias: UsersFiltersReducerAlias) => (query: string) =>
  updateUsersReqProps(alias)({ query, start: 0 });

export const updateUsersFilters = (alias: UsersFiltersReducerAlias) => (filters: UsersFilters) => (dispatch: AppDispatch) =>
  dispatch({
    type: getUsersFiltersActionName(Actions.UpdateFilters, alias),
    payload: { filters, reqProps: { start: 0 } },
  });
export const resetUsersFilters = (alias: UsersFiltersReducerAlias) => (initialFilters = {}) => (dispatch: AppDispatch) =>
  dispatch({ type: getUsersFiltersActionName(Actions.ResetFilters, alias), payload: initialFilters });

export const resetUsersFiltersAndReqProps = (alias: UsersFiltersReducerAlias) => (initialFilters = {}) => (dispatch: AppDispatch) =>
  dispatch({ type: getUsersFiltersActionName(Actions.ResetAll, alias), payload: initialFilters });

export const updateUsersFilterId = (alias: UsersFiltersReducerAlias) =>
  (actor: string | null) => (dispatch: AppDispatch) =>
    dispatch({ type: getUsersFiltersActionName(Actions.UpdateFilterId, alias), payload: actor });

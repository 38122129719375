import IconButton from '@mui/material/IconButton';
import { useIntl } from 'react-intl';
import { FC } from 'react';

import SimpleButton from 'Components/SimpleButton';

import { getProductBackgroundImage, textTruncate } from 'Utils/helpers';
import { Bounty } from 'Types/bounty.interface';

interface PromotionAttachProductProps {
  product: Bounty;
  addProduct: () => void;
  removeProduct: () => void;
}

const PromotionAttachProduct: FC<PromotionAttachProductProps> = ({ product, addProduct, removeProduct }) => {
  const productDetails = product?.product;
  const backgroundImage = getProductBackgroundImage(productDetails);
  const hasSelectedProduct = productDetails && Object.keys(productDetails).length;

  const intl = useIntl();

  return (
    <div>
      <div className="my-20 ml-10">
        {!!hasSelectedProduct && (
          <div className="flex-left-h position-relative">
            <div
              style={{ ...backgroundImage, height: '50px', width: '50px' }}
              className="image-post product-image-placeholder"
            />
            <div className="text-dark pl-10">
              {!!productDetails.name && textTruncate(productDetails.name, 25)}
            </div>
            <sup className="ml-3">
              <IconButton aria-label="delete" size="small" onClick={removeProduct}>
               <span className="material-symbols-rounded font-15">
                close
               </span>
              </IconButton>
            </sup>
          </div>
        )}
      </div>
      <SimpleButton
        onClick={addProduct}
        label={intl.formatMessage({ id: 'button.addProduct' })}
      />
    </div>
  );
};

export default PromotionAttachProduct;

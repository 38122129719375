import { FormattedMessage } from 'react-intl';
import JobInformationService from 'Services/JobInformationService';
import { notificationToast } from 'Utils/notificationToaster';
import { AppDispatch, RootState } from 'Types/redux.types';

export enum Actions {
  Loading = 'specialPrograms/Loading',
  Loaded = 'specialPrograms/Loaded',
  GetData = 'specialPrograms/GetData',
}

export const getSpecialPrograms = (isForceUpdate = false) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { specialProgramsReducer: { isLoaded, isLoading } } = getState();

  if (isForceUpdate || (!isLoaded && !isLoading)) {
    dispatch({ type: Actions.Loading, payload: true });
    JobInformationService.getSpecialPrograms()
      .then((response) => {
        dispatch({
          type: Actions.GetData,
          payload: response,
        });
      })

      .catch((err) => notificationToast.error(err.message, {
        title: <FormattedMessage id="notification.error.skillsActions" />,
      }))

      .finally(() => {
        dispatch({ type: Actions.Loading, payload: false });
        dispatch({ type: Actions.Loaded, payload: true });
      });
  }
};

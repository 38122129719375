import { firstNonNull } from 'Utils/object';
import { listTypeData } from 'Constants/streamListDefs';
import { BountyType, PermissionType, ShowInType, StreamListKind, StreamListType, VisibilityModeType } from 'Constants/enums';
import { getValidBountyType } from 'Utils/bounty';

const visibilityTypes = [VisibilityModeType.Default, VisibilityModeType.Visible, VisibilityModeType.User, VisibilityModeType.Unknown];

export class ListKind {
  static isViewable(kind) {
    return kind !== StreamListKind.Target;
  }

  static isPostable(kind = '') {
    return ![
      StreamListKind.FilterByBountyType,
      StreamListKind.WebView,
      StreamListKind.RestCall,
      StreamListKind.Stats,
      StreamListKind.Multi,
    ].includes(kind);
  }

  static asEnum(kind) {
    if (!kind) {
      return StreamListKind.Inbox;
    }

    return Object.values(StreamListKind).includes(kind) ? kind : StreamListKind.Unknown;
  }
}

export class ListType {
  static asEnum(type) {
    if (!type) {
      return listTypeData[StreamListType.None];
    }

    return listTypeData[type] || listTypeData[StreamListType.Custom];
  }

  static isFavorites(typeId) {
    return typeId === StreamListType.Favorites;
  }

  static isPostable(item) {
    const permissionType = getPermissionTypeAsEnum(item);
    const kind = ListDef.getKindAsEnum(item);

    return ListKind.isPostable(kind)
      && !this.isFavorites(item.id)
      && isVisibleType(item?.visibilityInfo)
      && isWritable(permissionType);
  }

  static getOldStyleListType(code, bountyType) {
    Object.keys(listTypeData).forEach((key) => {
      const listType = listTypeData[key];

      if (listType && listType.code && code && listType.code === code && listType.bountyType === bountyType) {
        return listType.name;
      }
      return StreamListType.None;
    });
  }
}

export class ListDef {
  static isApplicable(listItem, type) {
    const { bountyType } = this.getTypeAsEnum(listItem.id);

    if (bountyType !== null) {
      return bountyType === type;
    }

    return !listItem.bountyTypes || this.getBountyTypesAsEnum(listItem).includes(type);
  }

  static getTypeAsEnum(typeId) {
    return ListType.asEnum(typeId);
  }

  static getKindAsEnum(listDef) {
    const { id, kind } = listDef;

    if (kind) {
      return ListKind.asEnum(kind);
    }

    const myKind = this.getTypeAsEnum(id).kind;

    if (myKind) {
      return myKind;
    }

    return StreamListKind.Bucket;
  }

  static getEffectiveUrl(listDef) {
    const { id, url } = listDef;

    if (url) {
      return url;
    }

    return this.getTypeAsEnum(id).url || null;
  }

  static getEffectiveCode(listDef) {
    return firstNonNull(listDef.code, this.getTypeAsEnum(listDef.id).code);
  }

  static getEffectiveListId(listId, listCode, bountyType) {
    let listType = ListType.asEnum(listId).name;

    if (listType === StreamListType.None) {
      listType = ListType.getOldStyleListType(listCode, bountyType);
    }

    switch (listType) {
      case StreamListType.None:
        return null;
      case StreamListType.Custom:
        return listId;
      default:
        return listType;
    }
  }

  static isEffectiveFilterByType(list) {
    const cloneList = { ...list };

    return this.getKindAsEnum(cloneList) === StreamListKind.FilterByBountyType;
  }

  static getFilterBountyTypeAsEnum(list) {
    const cloneList = { ...list };
    const types = this.getBountyTypesAsEnum(cloneList);

    return types.length === 1 ? types[0] : this.getTypeAsEnum(list.id).bountyType;
  }

  static getBountyTypesAsEnum(list) {
    if (!list.bountyTypes) {
      const { bountyType } = this.getTypeAsEnum(list.id);
      return bountyType ? [bountyType] : [];
    }

    const bountyTypes = list.bountyTypes.split(',');
    const finalList = [];

    bountyTypes.forEach((item) => {
      const t = getValidBountyType(item);
      if (t !== BountyType.None && t !== BountyType.Unknown) {
        finalList.push(t);
      }
    });

    return finalList;
  }

  static isWebview(listDef) {
    return this.getKindAsEnum(listDef) === StreamListKind.WebView && this.getEffectiveUrl(listDef) !== null;
  }

  static isMulti(listDef) {
    return this.getKindAsEnum(listDef) === StreamListKind.Multi;
  }

  static isViewable(listDef) {
    const permissionType = getPermissionTypeAsEnum(listDef);

    return ListKind.isViewable(listDef.kind)
      && isVisibleType(listDef?.visibilityInfo)
      && isViewableByPermissionType(permissionType);
  }
}

export function getEffectiveOp(listType, op) {
  if (op) {
    return op;
  }

  return ListDef.getTypeAsEnum(listType).op || null;
}

export function isEffectiveFilterByType(listDef) {
  return ListDef.getKindAsEnum(listDef) === StreamListKind.FilterByBountyType;
}

export function isListOf(listDef, bounty) {
  if (!bounty.listId) {
    return listDef?.id === StreamListType.Stream;
  }

  return listDef?.id === ListDef.getEffectiveListId(bounty.listId, bounty.listCode, bounty.type);
}

export function permissionsAsEnum(type) {
  if (!type) {
    return PermissionType.ReadWrite;
  }

  return Object.values(PermissionType).includes(type) ? type : PermissionType.Unknown;
}

function getPermissionTypeAsEnum(item) {
  if (!item?.permissionType) {
    const type = ListType.asEnum(item?.id)?.permissionType;
    return type || PermissionType.ReadWrite;
  }

  return permissionsAsEnum(item.permissionType);
}

function isWritable(type) {
  return [PermissionType.WriteOnly, PermissionType.ReadWrite].includes(type);
}

export function mergePermissionTypes(curr, newValue) {
  switch (curr) {
    case PermissionType.Hide:
      return newValue;
    case PermissionType.ReadOnly:
      return newValue === PermissionType.ReadOnly ? curr : PermissionType.ReadWrite;
    case PermissionType.WriteOnly:
      return newValue === PermissionType.WriteOnly ? curr : PermissionType.ReadWrite;
    default:
      return PermissionType.ReadWrite;
  }
}

export function isViewableByPermissionType(permission) {
  return [PermissionType.ReadOnly, PermissionType.ReadWrite].includes(permission);
}

export function getVisibilityType(visibilityInfo) {
  if (!visibilityInfo?.visibilityType) {
    return VisibilityModeType.Default;
  }

  return visibilityTypes.includes(visibilityInfo?.visibilityType) ? visibilityInfo.visibilityType : VisibilityModeType.Unknown;
}

function isVisibleType(visibilityInfo) {
  const type = getVisibilityType(visibilityInfo);
  return [VisibilityModeType.Default, VisibilityModeType.Visible].includes(type);
}

export function getShownInAsEnum(showIn) {
  if (!showIn) {
    return ShowInType.Home;
  }

  return Object.values(ShowInType).includes(showIn) ? showIn : ShowInType.Unknown;
}

export function useDefaultsFrom(listDef, defaultDef) {
  const clone = JSON.parse(JSON.stringify(listDef));

  if (listDef.name == null) {
    clone.name = defaultDef.name;
  }
  if (clone.emptyListMsg == null) {
    clone.emptyListMsg = defaultDef.emptyListMsg;
  }
  if (clone.rank == null) {
    clone.rank = defaultDef.rank;
  }
  if (clone.kind == null) {
    clone.kind = defaultDef.kind;
  }
  if (clone.sublists == null) {
    clone.sublists = defaultDef.sublists;
  }

  return clone;
}

export const setListDef = (listDef) => {
  if (listDef === null || listDef === undefined) {
    return {
      listId: null,
      listCode: null,
    };
  }

  const { name } = ListDef.getTypeAsEnum(listDef.id);

  if (name === StreamListType.None || name === StreamListType.Stream) {
    return {
      listId: null,
      listCode: null,
    };
  }

  if (name === StreamListType.Custom) {
    return {
      listId: listDef.id,
      listCode: null,
    };
  }

  const listCode = ListDef.getEffectiveCode(listDef);

  return {
    listId: listDef.id,
    listCode: listCode !== undefined ? listCode : null,
  };
};

export const isListStream = (listDef) => listDef.kind === StreamListKind.ListStream;

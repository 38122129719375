import { ReactNode, FC, Suspense } from 'react';
import CustomLoader from 'Components/CustomLoader';

interface LoadableProps {
  children: ReactNode;
}

const Loadable: FC<LoadableProps> = ({ children }) => (
  <Suspense fallback={<CustomLoader isLoading />}>
    {children}
  </Suspense>
);

export default Loadable;

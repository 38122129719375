import enMessages from '../locales/console-strings-en.json';
import esMessages from '../locales/console-strings-es.json';
import itMessages from '../locales/console-strings-it.json';
import plMessages from '../locales/console-strings-pl.json';
import roMessages from '../locales/console-strings-ro.json';
import ruMessages from '../locales/console-strings-ru.json';
import deMessages from '../locales/console-strings-de.json';
import kaMessages from '../locales/console-strings-ka_GE.json';

/**
 * This function is a wrapper for the react-intl provider
 * @param messages Inject with translations
 * @param locale
 * @returns {{messages, locale}}
 */
const langSelector = (messages: JSON, locale: string) =>
  ({ messages: { ...messages }, locale });

export const enLang = langSelector(enMessages, 'en');

type LanguageObject = {
  lang: JSON;
  locale: string;
};

type Languages = {
  [key: string]: ReturnType<typeof langSelector>;
};

const languages: LanguageObject[] = [
  { lang: enMessages, locale: 'en' },
  { lang: esMessages, locale: 'es' },
  { lang: itMessages, locale: 'it' },
  { lang: plMessages, locale: 'pl' },
  { lang: roMessages, locale: 'ro' },
  { lang: ruMessages, locale: 'ru' },
  { lang: deMessages, locale: 'de' },
  { lang: kaMessages, locale: 'ka' },
];

const languagesObj: Languages = languages.reduce((acc, curr) => {
  const { lang, locale } = curr;
  const langSelectorResult = langSelector(lang, locale);
  return { ...acc, [`${locale}Lang`]: langSelectorResult };
}, {});

export default languagesObj;

import { cloneDeep } from 'lodash';
import { get } from 'firebase/database';
import {
  firebaseGetGroupMemberRef,
  firebaseGetGroupMembers,
  firebaseGetGroupRef,
  firebaseGetMyProfileRef,
} from 'Services/FirebaseService';
import { convertObjToArray } from 'Utils/helpers';
import { postEvent } from 'Services/Api';
import { QueueAlias, MemberRole, JoiningStrategy, GroupType } from 'Constants/enums';
import { renderRolesList } from 'Utils/roleDef';
import { isInvalid, isGroupMemberAdmin } from 'Utils/group';
import { sortAlphabetically } from 'Utils/general';
import { AppConfig } from 'Utils/appConfig';
import { AppDispatch } from 'Types/redux.types';
import { ExtendedGroupMember, GroupMember } from 'Types/group.interface';
import { User } from 'Types/user.interface';

export const ACTIONS = {
  SET_IS_LOADING: 'groupsReducer/setIsLoading',
  SET_GROUPS: 'groupsReducer/setGroups',
  SET_GROUP_PROFILE: 'groupsReducer/setGroupProfile',
  SET_COLLEAGUES: 'groupsReducer/setColleagues',
  SET_IS_COLLEAGUES_LOADING: 'groupsReducer/setIsColleaguesLoading',
};

interface CreateGroup {
  type: GroupType;
  membersIds: Record<string, boolean>;
  description: string;
  groupId?: string;
  name: string;
  parentGroup: User;
}

export const clearGroupProfile = () => (dispatch: AppDispatch) => dispatch({ type: ACTIONS.SET_GROUP_PROFILE, payload: {} });
export const clearMyColleagues = () => (dispatch: AppDispatch) => dispatch({ type: ACTIONS.SET_COLLEAGUES, payload: [] });

export const getMyColleagues = ({ ownerId }: { ownerId: string }) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_COLLEAGUES_LOADING, payload: true });

  firebaseGetGroupMembers(ownerId)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val())?.filter(({ user }) => user);
      dispatch({ type: ACTIONS.SET_COLLEAGUES, payload: dataSnapshotVal });
      dispatch({ type: ACTIONS.SET_IS_COLLEAGUES_LOADING, payload: false });
    }, () => {
      dispatch({ type: ACTIONS.SET_COLLEAGUES, payload: [] });
      dispatch({ type: ACTIONS.SET_IS_COLLEAGUES_LOADING, payload: false });
    });
};

export const createGroup = (params: CreateGroup) => {
  const { parentGroup, type, membersIds, ...remainingGroupProps } = params;
  const newGroupRef = firebaseGetGroupMembers(parentGroup?.id).push();
  const groupId = newGroupRef.key;

  postEvent(QueueAlias.AddGroup, {
    ...remainingGroupProps,
    groupId,
    ownerId: parentGroup.id,
    subType: type,
  });

  if (membersIds && Object.keys(membersIds)?.length) {
    Object.keys(membersIds).forEach((memberId) => {
      if (membersIds[memberId]) {
        postEvent(QueueAlias.AddMember, { groupId, memberId });
      }
    });
  }
};

export const editGroup = (
  params: { groupId: string; name: string; description: string; joiningStrategy: JoiningStrategy },
) => {
  const { groupId, name, description, joiningStrategy } = params;

  return postEvent(QueueAlias.UpdateGroup, {
    groupId,
    name,
    description,
    joiningStrategy,
  });
};

export const deleteGroup = ({ groupId }: { groupId: string }) => postEvent(QueueAlias.DeleteGroup, { groupId });

// TODO logic will be changed after BE changes
const supportGroupId = '-MM27ROjnoZM_iHQaGax';

export const getGroups = (
  params: { groupId: string; isAdminUser: boolean; canSeeGroupEverybody: boolean, teamCompany: Partial<GroupMember>, userId: string },
) => (dispatch: AppDispatch) => {
  const { groupId, teamCompany, canSeeGroupEverybody, isAdminUser, userId } = params;
  let groups: GroupMember[] = [];

  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  firebaseGetGroupMembers(groupId)
    .on('value', async (dataSnapshot) => {
      const promises: Promise<GroupMember[]>[] = [];
      groups = [];
      const newGroups = convertObjToArray(dataSnapshot.val());

      for (const group of newGroups) {
        if (!isInvalid(group)) {
          const memberSnapshot = await get(firebaseGetGroupMemberRef(group.user.id, userId));
          const membership = memberSnapshot.val();

          if (membership || group.joiningStrategy !== JoiningStrategy.InviteOnly) {
            groups.push(group);

            promises.push(new Promise((resolve) => {
              firebaseGetGroupMembers(group.user.id)
                .on('value', (groupMembersSnapshot) => {
                  resolve(convertObjToArray(groupMembersSnapshot.val()) as GroupMember[]);
                });
            }));
          }
        }
      }

      const membersResponse = await Promise.allSettled(promises);
      const formattedGroups: ExtendedGroupMember[] = [];

      groups.forEach((group, index) => {
        const { status, value = [] } = membersResponse?.[index] as PromiseFulfilledResult<GroupMember[]> || {};
        const isSuccessful = status === 'fulfilled';

        if (isSuccessful) {
          formattedGroups.push({ ...group, members: value });
        }
      });

      const clonedGroups = cloneDeep(formattedGroups);
      const sortedGroups = sortAlphabetically({ data: clonedGroups, sortBy: 'user.name' });

      if (teamCompany != null && canSeeGroupEverybody) {
        sortedGroups.unshift(teamCompany);
      }

      dispatch({ type: ACTIONS.SET_GROUPS, payload: sortedGroups });
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
    });
  //  TODO: fixed this
  if (AppConfig.id === 'joblio' && isAdminUser) {
    firebaseGetGroupRef(supportGroupId)
      .on('value', (dataSnapshot) => {
        const dataSnapshotVal = dataSnapshot.val();
        groups.unshift(dataSnapshotVal);
        firebaseGetGroupMembers(dataSnapshotVal?.user?.id)
          .on('value', (groupMembersSnapshot) => {
            dataSnapshotVal.members = convertObjToArray(groupMembersSnapshot.val());
          });
        const clonedGroups = cloneDeep(groups);
        const sortedGroups = sortAlphabetically({ data: clonedGroups, sortBy: 'user.name' });
        dispatch({ type: ACTIONS.SET_GROUPS, payload: sortedGroups });
      });
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

export const getProfile = ({ groupId }: { groupId: string }) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  return firebaseGetMyProfileRef(groupId)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = dataSnapshot.val();
      firebaseGetGroupMembers(groupId)
        .on('value', (membersSnapshot) => {
          const membersObj = { members: convertObjToArray(membersSnapshot.val()) };
          const profilePayload = { ...dataSnapshotVal, ...membersObj };

          dispatch({ type: ACTIONS.SET_GROUP_PROFILE, payload: profilePayload });
          dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
        });
    }, () => (
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false })
    ));
};

export const removeMember = ({ groupId, memberId }: { groupId: string; memberId: string }) => {
  firebaseGetGroupMembers(groupId).child(memberId).remove();
  postEvent(QueueAlias.RemoveMember, { groupId, memberId });
};

export const addMember = ({ groupId, memberId }: { groupId: string; memberId: string }) => postEvent(QueueAlias.AddMember, { groupId, memberId });

export const updateMembers = (params: { values: { groupId: string; membersIds: Record<string, boolean> }, groupMembers: GroupMember[] }) => {
  const { values: { groupId, membersIds }, groupMembers } = params;
  const initialMembers = groupMembers?.map(({ user }) => user.id) || [];

  initialMembers.forEach((memberId) => {
    const foundMember = membersIds?.[memberId] || null;

    if (!foundMember) {
      removeMember({ groupId, memberId });
    }
  });

  Object.keys(membersIds).forEach((id) => {
    if (membersIds[id]) {
      addMember({ groupId, memberId: id });
    }
  });
};

export const makeMemberAdmin = (params: { groupId: string; member: GroupMember; admin: boolean }) => {
  const { groupId, member, admin } = params;

  if (isGroupMemberAdmin(member) === admin) {
    return;
  }

  const role = admin ? MemberRole.admin : MemberRole.member;
  const roles = [role];
  const memberId = member.user && member.user.id;
  const rolesString = renderRolesList(roles);

  firebaseGetGroupMemberRef(groupId, memberId)
    .child('memberRoles')
    .set(renderRolesList(roles));

  postEvent(QueueAlias.UpdateCaps, {
    groupId,
    scope: 'MEMBER',
    granteeId: memberId,
    roles: rolesString,
  });
};

import { cloneDeep } from 'lodash';
import { notificationToast } from 'Utils/notificationToaster';
import { newComment } from 'Utils/comment';
import { addValueOfPoints } from 'Utils/models/Reward';
import {
  updateLocalBounty,
  updateSortKeys,
} from 'Services/bounty/BountyService';
import { setMyPoints } from 'Services/UserService';
import * as PointUtils from 'Utils/points';
import { postEvent } from 'Services/Api';
import { QueueAlias, EntityType, BountyCardType } from 'Constants/enums';
import {
  firebaseGetCurrentUser,
  firebaseGetMyFavorites,
  firebaseGetTimestamp,
  firebaseGetUserCommentsRef,
} from 'Services/FirebaseService';
import { getFavoritePriority, getPriorityForComment } from 'Utils/keyUtils';
import { convertObjToArray } from 'Utils/helpers';
import { updateDataAsyncAction } from 'Store/genericActions';
import { GenericReducerName } from 'Constants/genericReducerName';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { isAllowedToMarkAsOfficial, isAllowedToPin } from 'Utils/bountyState';

function getCommentRef(entityType, entityId, commentId) {
  const commentsRef = entityType === BountyCardType.BOUNTY
    ? firebaseGetUserCommentsRef().child('bounties').child(entityId)
    : firebaseGetUserCommentsRef().child('responses').child(entityId);

  if (!commentId) {
    return commentsRef.push();
  }

  return commentsRef.child(commentId);
}

/* **** Comments **** */
const getBountyComments = (bountyId) => (dispatch) => (
  firebaseGetUserCommentsRef()
    .child('bounties')
    .child(bountyId)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val()).reverse();
      dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyComments, dataSnapshotVal));
    })
);

const addBountyComments = (bountyId, comment) => () => {
  const commentRef = firebaseGetUserCommentsRef().child('bounties').child(bountyId).push();
  comment.id = commentRef.key;

  commentRef.setWithPriority(comment, getPriorityForComment(comment));
  postEvent(QueueAlias.AddComment, { comment, commentId: commentRef.key });
};

const toggleLikedState = (bounty) => {
  const likedAt = bounty.likedAt ? null : new Date().getTime();
  postEvent(QueueAlias.LikeBounty, { bountyId: bounty.id, likedAt });
};

const rateBounty = ({
  bounty, text, rateAmt, availablePts,
}) => async (dispatch) => {
  const bountyClone = cloneDeep(bounty);
  const currentUser = firebaseGetCurrentUser();

  const pointCurrency = availablePts.currency;
  const pts = {
    amount: rateAmt,
    currency: pointCurrency,
  };
  bountyClone.rating = PointUtils.addRating(bountyClone.rating, currentUser.uid, pts);

  setMyPoints({ amount: availablePts.amount - pts.amount, currency: pointCurrency }); // this method gives perm denied

  const comment = await newComment(text, EntityType.bounty, bountyClone.id);
  comment.reward = addValueOfPoints(pts);

  dispatch(addBountyComments(bountyClone.id, comment));
};

const changeBountyRatingValue = ({ bounty, ratingValue }) => () => {
  postEvent(QueueAlias.ChangeBountyRatingValue, { bountyId: bounty.id, starPointsValue: ratingValue });
};

const toggleBadgeType = ({ bounty, badgeType, mark }) => () => {
  const bountyClone = cloneDeep(bounty);
  const isAllowedToPerformOp = isAllowedToMarkAsOfficial(bountyClone.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(`Cannot edit badges when ${bountyClone.state}`);
  }

  postEvent(QueueAlias.BountyEditBadge, { bountyId: bountyClone.id, badgeType, mark });
};

const pinToTop = ({ bounty, pin }) => () => {
  // TODO: remove after new interface for jobs will be implemented
  const bountyClone = cloneDeep(bounty);
  const isAllowedToPerformOp = isAllowedToPin(bountyClone.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(`Cannot edit badges when ${bountyClone.state}`);
  }

  const pinnedAt = pin ? firebaseGetTimestamp() : null;

  updateSortKeys(bountyClone);
  postEvent(QueueAlias.PinBounty, { bountyId: bountyClone.id, pin, pinnedAt });
};

const addToFavorites = ({ ownerId, bounty, creatorId }) => (dispatch) => {
  const me = firebaseGetCurrentUser();
  const now = firebaseGetTimestamp();

  const prio = getFavoritePriority(new Date().getTime());
  const key = {
    cardType: BountyCardType.BOUNTY,
    creatorId,
    bountyId: bounty.id,
    outbound: !!(bounty.outbound || bounty.isOutbound),
    '.priority': prio,
  };

  firebaseGetMyFavorites({ ownerId, userId: me.uid }).child(bounty.id).setWithPriority(key, prio);
  postEvent(QueueAlias.AddFavorite, { bountyId: bounty.id, favoritedAt: now });

  dispatch(updateLocalBounty({ ...bounty, favoritedAt: now }));
};

const removeFromFavorites = ({ ownerId, bounty }) => (dispatch) => {
  const me = firebaseGetCurrentUser();

  firebaseGetMyFavorites({ ownerId, userId: me.uid }).child(bounty.id).remove();
  postEvent(QueueAlias.RemoveFavorite, { bountyId: bounty.id });

  dispatch(updateLocalBounty({ ...bounty, favoritedAt: null }));
};

const getBountyCommentsRef = (bountyId) => firebaseGetUserCommentsRef().child('bounties').child(bountyId);

const getBountyCommentsQuery = (bountyId) => getBountyCommentsRef(bountyId).orderByPriority();

const checkIfCommentedOnBounty = (bountyId) => {
  const me = firebaseGetCurrentUser();

  return new Promise((resolve) => (
    getBountyCommentsRef(bountyId)
      .orderByChild('creator/id')
      .equalTo(me.uid)
      .once('value', (dataSnapshot) => {
        resolve(dataSnapshot.exists());
      })
  ));
};

export const deleteComment = (comment) => {
  const commentRef = getCommentRef(comment.commentType, comment.entityId, comment.id);
  commentRef.remove();

  postEvent(QueueAlias.DeleteComment, { commentId: comment.id });
};

export {
  toggleLikedState,
  toggleBadgeType,
  changeBountyRatingValue,
  rateBounty,
  pinToTop,
  getBountyComments,
  addBountyComments,
  addToFavorites,
  removeFromFavorites,
  getBountyCommentsQuery,
  checkIfCommentedOnBounty,
};

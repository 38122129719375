import { VisibilityModeType } from 'Constants/enums';
import { VisibilityMode } from 'Types/visibilityMode.interface';

export const DEFAULT = {
  forced: true,
  visibilityType: VisibilityModeType.Visible,
};

const asEnum = (type: VisibilityModeType) => type || VisibilityModeType.Default;

export const getVisibilityTypeAsEnum = (visibilityMode: VisibilityMode | VisibilityModeType.Default) => asEnum(typeof visibilityMode === 'object' ? visibilityMode?.visibilityType : visibilityMode);

import { AnyAction } from '@reduxjs/toolkit';
import { SearchMode, SortDirection } from 'Constants/enums';
import { ApplicationsSortFields } from 'Constants/sort';
import {
  Actions,
  ApplicationsFiltersReducerAlias,
  getApplicationsFiltersActionName,
} from 'Store/applicationsFilters/actions';
import { SortAndSearch } from 'Types/sortAndSearch.type';
import { ByPageReq } from 'Types/byPageReq.interface';
import { ApplicationsFilters } from 'Types/applicationsFilters.type';
import { formatSortQueryProp } from 'Utils/sort';

const lsLimitName = 'applicationsLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

interface State {
  reqProps: SortAndSearch & ByPageReq;
  filters: ApplicationsFilters & { userId?: string; companyId?: '' };
  filterId: string | null;
}

const initialState: State = {
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp(ApplicationsSortFields.CreatedDate, SortDirection.DESC),
    query: '',
  },
  filters: {
    citizenship: [],
    country: [],
    gender: [],
    jobProgram: [],
    applicationStatuses: [],
    languages: [],
    userId: '',
    companyId: '',
    bountyIds: '',
    withCertificates: false,
    searchMode: SearchMode.Default,
  },
  filterId: null,
};

// TODO: add actions type
export const createApplicationsFiltersReducer = (alias: ApplicationsFiltersReducerAlias) =>
  (state: State = { ...initialState }, action: AnyAction) => {
    const { payload } = action;

    switch (action.type) {
      case getApplicationsFiltersActionName(Actions.UpdateReqProps, alias):
        if (payload.limit) {
          localStorage.setItem(lsLimitName, payload.limit);
        }
        return {
          ...state,
          reqProps: {
            ...state.reqProps,
            ...payload,
          },
        };

      case getApplicationsFiltersActionName(Actions.UpdateFilters, alias):
        return {
          ...state,
          reqProps: {
            ...state.reqProps,
            ...payload.reqProps,
          },
          filters: {
            ...state.filters,
            ...payload.filters,
          },
        };

      case getApplicationsFiltersActionName(Actions.ResetFilters, alias):
        return {
          ...state,
          filters: {
            ...initialState.filters,
            ...payload,
          },
        };

      case getApplicationsFiltersActionName(Actions.ResetFiltersAndReqProps, alias):
        return {
          ...state,
          reqProps: { ...initialState.reqProps },
          filters: {
            ...initialState.filters,
            ...payload,
          },
        };

      case getApplicationsFiltersActionName(Actions.UpdateFilterId, alias):
        return {
          ...state,
          filterId: payload,
        };

      default:
        return state;
    }
  };

import { AnyAction } from '@reduxjs/toolkit';

import { UserChat } from 'Types/chatUser.interface';
import { Actions } from 'Store/userChats/actions';

interface State {
  data: UserChat[],
  isLoading: boolean,
}

const initialState: State = {
  data: [],
  isLoading: false,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.SetChat:
      return {
        ...state,
        data: payload,
      };

    case Actions.SetLoading:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default reducer;

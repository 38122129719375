/**
 * ResponsiveGrid components allow to render a table in a responsive way (column view on mobile devices)
 * This component is a parent wrapper for ResponsiveGrid components group.
 * Contain common styles and responsive for horizontal scrolling if there is not enough width on the page
 * @param children {object} Other ResponsiveGrid components should be passed as a children (nested)
 * @param className {string} Additional class name(s).
 */

import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { SortDirection } from 'Constants/enums';
import TooltipAnchor from 'Components/TooltipAnchor';
import * as styles from './index.module.scss';

interface CellProps {
  className?: string;
  children?: ReactNode[] | ReactNode;
  label: string;
  value?: string | number | ReactNode[] | ReactNode;
  onClick?: () => unknown;
}

interface HeadCellProps {
  className?: string;
  id?: string;
  label: string;
  sortable?: boolean;
  activeSort?: boolean;
  sortDirection?: SortDirection.ASC | SortDirection.DESC
  tooltipLabel?: string;
  onClick?: (id: string) => any;
}

interface RowProps {
  children?: ReactNode[] | ReactNode;
  header?: boolean;
  onClick?: () => any;
}

interface TableProps {
  className?: string;
  children: ReactNode[] | ReactNode;
}

export interface ResponsiveGridHeading {
  title: string;
  id?: string;
  sortable?: boolean;
  className?: string;
  tooltipLabel?: string;
}

const Table: FC<TableProps> = ({ children, className = '' }) => (
  <div className={`${styles.responsiveGridWrapper} ${className}`}>
    <table className={styles.responsiveTable} data-qa="table">
      {children}
    </table>
  </div>
);

/**
 * This component render a data cell in Responsive table, equal to 'td'.
 * Do not use for rendering headers (th).
 * ResponsiveGrid.Row wrapper required.
 * @param className {string} Provided class name.
 * @param label {string} Column header. Necessary for mobile version.
 * Could be used with 'value' only.
 * @param value {string|number} Cell plain value
 * @param children {object} Cell complex value. Use if plain value not enough (for example for a component in the cell)
 */

// TODO: replace '-' with constant or remove.
const Cell: FC<CellProps> = ({ children, className, label, value, ...remainingProps }) => (
  <td {...remainingProps} className={classnames(className, { [styles.noValue]: !children && !value && typeof value !== 'number' && value !== '-' })} data-qa="tableCell">
    <div className={styles.cellInnerLabel}>
      {label ? `${label}:` : ''}
    </div>
    <div className="cell-inner-value">
      <div
        className={styles.oneLineWrapper}
        title={(typeof value === 'string' || typeof value === 'number') ? value.toString() : ''}
      >
        {value || children}
      </div>
    </div>
  </td>
);

/**
 * This component render a header cell in Responsive table, equal to 'th'.
 * Do not use for rendering data cells (td).
 * ResponsiveGrid.Row wrapper required.
 * @param id {string} Cell identifier. Using in sorting callback.
 * @param className {string} Provided class name.
 * @param label {string} Column header.
 * @param tooltipLabel {string} Set if tooltip by column needed.
 * @param sortable {boolean=} Set if sorting by column needed
 * @param activeSort {boolean=} Set if active column for sorting
 * @param sortDirection {string=} Should be 'desc' or 'asc', ignored is sortable===false
 * @param onClick {function} Sort change callback
 */

const HeadCell: FC<HeadCellProps> = ({
  className = '',
  id,
  label,
  sortable,
  activeSort,
  sortDirection,
  onClick,
  tooltipLabel,
}) => (
  <th
    data-qa="tableHeaderCell"
    data-qa-active={activeSort}
    data-qa-value={activeSort ? sortDirection : ''}
    onClick={() => sortable && id && onClick && onClick(id)}
    className={
      classnames(
        className,
        {
          [styles.sortable]: sortable,
          [styles.active]: activeSort,
          [styles.asc]: activeSort && sortDirection === SortDirection.ASC,
        },
      )
    }
  >
    <div className={classnames({ [styles.tooltipWrapper]: !!tooltipLabel })}>
      <div className={styles.headerWrapper} title={label}>
        {label}
      </div>

      {tooltipLabel && (
        <>
          {' '}
          <TooltipAnchor translationKey={tooltipLabel} />
        </>
      )}
    </div>
  </th>
);

/**
 * This component render a row in Responsive table, equal to 'tr'.
 * Could be used both for data cells (ResponsiveGrid.Cell) and headers (ResponsiveGrid.HeadCell)
 * ResponsiveGrid.Table wrapper required.
 * @param header {boolean=} Set if use as a wrapper for ResponsiveGrid.HeadCell
 * @param children {object} ResponsiveGrid.Cell or ResponsiveGrid.HeadCell should be passed as a children (nested)
 * @param onClick {function} Row click handler
 */

const Row: FC<RowProps> = ({ children, header = false, onClick = () => {} }) => {
  const row = <tr onClick={onClick} data-qa="tableRow">{children}</tr>;

  return (header ? <thead>{row}</thead> : row);
};

const ResponsiveGrid = { Table, Row, HeadCell, Cell };

export default ResponsiveGrid;

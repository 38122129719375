import { QueueAlias } from 'Constants/enums';
import { queues } from 'Constants/queues';
import { QueueEventData } from 'Types/queueEventData.interface';

export const getQueueEvents = (alias: QueueAlias): QueueEventData | undefined => queues[alias];

export const getQueueName = (alias: QueueAlias): string | null => {
  const queueEvents = getQueueEvents(alias);
  return queueEvents ? queueEvents.name : null;
};

export const getQueueParam = (alias: QueueAlias): string | null => {
  const queueEvents = getQueueEvents(alias);
  return queueEvents ? queueEvents.param || queueEvents.name : null;
};

import { textToTranslations } from 'Utils/translations';
import {
  translationsValidationCodeSeparator,
  translationsValidationSeparator,
} from 'Constants/translations';
import { isBefore } from 'date-fns';
import { isNil } from 'lodash';

export const isValidTranslations = function (regexp) { // eslint-disable-line
  return this.test('translation', regexp, function (value) { // eslint-disable-line
    const { path, createError } = this;
    const errors = [];

    textToTranslations(value).forEach((tr) => {
      if (!tr.text) {
        errors.push(`${tr.code}${translationsValidationCodeSeparator}error.fieldIsRequired`);
      }
      if (regexp && !tr.text.match(regexp)) {
        errors.push(`${tr.code}${translationsValidationCodeSeparator}error.incorrectFormat`);
      }
    });

    if (errors.length === 0) {
      return true;
    }
    return createError({ path, message: errors.join(translationsValidationSeparator) });
  });
};

export const getValidationErrorsHash = (yupErr) => {
  const errorsHash = {};
  yupErr?.inner?.forEach((err) => {
    errorsHash[err.path] = err.message;
  });
  return errorsHash;
};

export const biggerThan = (fieldName) => {
  return function (value) { // eslint-disable-line
    return +this.parent[fieldName] <= +value;
  };
};

export const afterThan = (fieldName, canBeNull = false) => {
  return function (value) { // eslint-disable-line
    if (canBeNull && (!value || isNil(value))) {
      return true;
    }

    return isBefore(new Date(+this.parent[fieldName]), new Date(+value));
  };
};

import { FormattedMessage } from 'react-intl';

import { Bounty } from 'Types/bounty.interface';
import { getDescription, getVisibilityType, isContest, isSurveyType } from 'Utils/bounty';
import { BannerType, BountyType, TargetApp } from 'Constants/enums';
import { DEFAULT_CORRECT_ANSWER_WEIGHT } from 'Utils/survey';
import { getPostAnonymity } from 'Utils/bountyCreation';
import { extractDistributionTargetCodes, extractDistributionTargetList } from 'Utils/distributionGroup';
import { formatFormikReward } from 'Utils/reward';
import { UserData } from 'Types/userData.interface';
import { Settings } from 'Types/settings.interface';
import { SurveyOption } from 'Types/survey.interface';

export const getInitialValues = (
  selectedBounty: Bounty,
  expiration: Record<string, number>,
  showInOptions: { label: string, value: string }[],
  uiFeatures: Record<string, boolean>,
  userData: UserData,
  settings: Settings,
  bountyType: BountyType,
  initialSurveyOptions: Record<string, SurveyOption>,
) => ({
  ...selectedBounty,
  type: selectedBounty?.type || bountyType,
  description: selectedBounty.description ? getDescription({ bounty: selectedBounty }) : '',
  timeLimit: selectedBounty.timeLimit || '',
  targetUrl: selectedBounty.targetUrl || '',
  broadcastInfo: {
    streamUrl: '',
    streamKey: '',
  },
  interactions: {
    officialResponse: false,
  },
  listId: selectedBounty?.listId || showInOptions?.[0]?.value || null,
  days: expiration?.days || '',
  hours: expiration?.hours || '',
  minutes: expiration?.minutes || '',
  postAt: selectedBounty?.postAt || null,
  surveyOptions: isSurveyType(bountyType) ? initialSurveyOptions : {},
  correctWeight: bountyType === BountyType.MCQ ? DEFAULT_CORRECT_ANSWER_WEIGHT : null,
  price: uiFeatures?.hasPrice && selectedBounty?.reward?.money?.amount ? selectedBounty.reward.money.amount : '',
  anonymity: getPostAnonymity({ selectedBounty, settings, userData }),
  visibilityType: selectedBounty?.responseVisibilityMode
    ? getVisibilityType(selectedBounty?.responseVisibilityMode, true)
    : false,
  distributionDetails: selectedBounty?.distribution ? extractDistributionTargetList(selectedBounty.distribution) : null,
  distributionCodes: selectedBounty?.distribution
    ? extractDistributionTargetCodes(extractDistributionTargetList(selectedBounty.distribution))
    : [],
  onBehalfOf: selectedBounty?.onBehalfOf
    ? { user: selectedBounty.onBehalfOf }
    : uiFeatures?.hasPostOnBehalfOf ? { user: { ...userData.myself, name: 'Myself' } } : null,
  reward: formatFormikReward({ reward: selectedBounty?.reward, settings, userData }),
  banner: bountyType === BountyType.Banner
    ? {
      bannerType: BannerType.Hero,
      targetApp: TargetApp.Shop,
      userAuthStatus: selectedBounty?.banner?.userAuthStatus || '',
      priority: selectedBounty?.banner?.priority || '',
      weight: selectedBounty?.banner?.weight || '',
      countryFilters: selectedBounty?.banner?.countryFilters?.US?.stateCodes
        ? selectedBounty.banner.countryFilters.US.stateCodes.split(',')
        : [],
      locationFilters: selectedBounty?.banner?.locationFilters
        ? Object.keys(selectedBounty?.banner?.locationFilters)
        : [],
    } : null,
});

export const getTitle = (
  selectedBounty: Bounty,
  configuration: { isContestSubBounty: boolean, questionIndex: number, bountyType: BountyType, name: string },
  isEditMode = false,
) => {
  const { isContestSubBounty, questionIndex, bountyType } = configuration;

  if (!bountyType) {
    return;
  }

  if (bountyType === BountyType.Banner) {
    return <FormattedMessage id="label.bannerSettings" />;
  }

  if (isContestSubBounty || (selectedBounty?.parentBounty?.type && isContest(selectedBounty?.parentBounty?.type))) {
    if (isEditMode) {
      return <FormattedMessage id="labels.edit" />;
    }

    if (questionIndex) {
      return <FormattedMessage id="bounty.createQuestion" values={{ number: questionIndex }} />;
    }

    if (configuration.bountyType === BountyType.Thread) {
      return <FormattedMessage id="bounty.createSuccessMessage" />;
    }
  }

  const modeLabel = <FormattedMessage id={isEditMode ? 'labels.edit' : 'labels.create'} />;

  return configuration.name
    ? <FormattedMessage id={configuration.name} values={{ type: modeLabel }} />
    : `${modeLabel} ${bountyType}`;
};

import { BountyType, MatchStatus, CompanySentsFolder } from 'Constants/enums';

export const DEFAULT_RATE_STAR_POINTS_VALUE = 1;

export const SUBBOUNTIES_STATS: Record<string, string> = {
  fieldName: 'subBounties',
  name: 'SUBBOUNTIES',
};

export const BOUNTY_TYPE_LABELS: Record<string, string> = {
  [BountyType.TMOB_Challenge]: 'bounty.challenge',
  [BountyType.TMOB_Coaching]: 'bounty.post',
  [BountyType.Album]: 'bounty.album',
  [BountyType.Job]: 'bounty.job',
  [BountyType.News]: 'bounty.news',
  [BountyType.Banner]: 'bounty.banner',
  [BountyType.TalentSearch]: 'bounty.talentSearch',
  [BountyType.RealEstateCustomer]: 'bounty.realEstateCustomer',
  [BountyType.Survey]: 'bounty.poll',
  [BountyType.Thread]: 'bounty.post',
  [BountyType.LiveStream]: 'bounty.liveStream',
  [BountyType.Classified]: 'bounty.classified',
  [BountyType.Bundle]: 'bounty.bundle',
  [BountyType.Trivia]: 'bounty.trivia',
  [BountyType.Lottery]: 'bounty.lottery',
  [BountyType.Product]: 'bounty.product',
  [BountyType.Autosuggestion]: 'bounty.autosuggestion',
  [BountyType.BadgeCollection]: 'bounty.badgeCollection',
  [BountyType.Checklist]: 'bounty.checklist',
  [BountyType.Funding]: 'bounty.funding',
  [BountyType.Match]: 'bounty.match',
  [BountyType.MCQ]: 'bounty.quiz',
  [BountyType.None]: 'bounty.none',
  [BountyType.Order]: 'bounty.order',
  [BountyType.PrivateListing]: 'bounty.privateListing',
  [BountyType.Promotion]: 'bounty.promotion',
  [BountyType.Quest]: 'bounty.quest',
  [BountyType.Question]: 'bounty.question',
  [BountyType.Reservation]: 'bounty.reservation',
  [BountyType.RealEstateRecommendation]: 'bounty.realEstateRecommendation',
  [BountyType.Score]: 'bounty.score',
  [BountyType.TalentRecommendation]: 'bounty.talentRecommendation',
  [BountyType.Deal]: 'bounty.deal',
  [BountyType.Unknown]: 'bounty.unknown',
  [BountyType.Post]: 'bounty.post',
  [BountyType.Distribution]: 'bounty.distribution',
  [BountyType.Campaign]: 'bounty.campaign',
  [BountyType.Currency]: 'bounty.currency',
};

export const MATCH_STATUS_LABELS: Record<string, string> = {
  [MatchStatus.FirstHalf]: 'First Half',
  [MatchStatus.HalfTimeBreak]: 'Half Time Break',
  [MatchStatus.SecondHalf]: 'Second Half',
  [MatchStatus.ExtraTime]: 'Extra Time',
  [MatchStatus.Penalty]: 'Penalty',
  [MatchStatus.Finished]: 'Finished',
  [MatchStatus.Cancelled]: 'Cancelled',
  [MatchStatus.TBD]: 'TBD',
  [MatchStatus.Terminated]: 'Terminated',
  [MatchStatus.Interrupted]: 'Interrupted',
  [MatchStatus.Postponed]: 'Postponed',
};

export const SENTS_FOLDER_BY_TYPE: Record<string, string> = {
  [BountyType.Product]: CompanySentsFolder.Products,
  [BountyType.BadgeCollection]: CompanySentsFolder.BadgeCollections,
  [BountyType.Promotion]: CompanySentsFolder.Promotions,
  [BountyType.Job]: CompanySentsFolder.Jobs,
  [BountyType.Autosuggestion]: CompanySentsFolder.Autosuggestion,
  [BountyType.PrivateListing]: CompanySentsFolder.Listings,
  [BountyType.Order]: CompanySentsFolder.Orders,
  [BountyType.News]: CompanySentsFolder.News,
  [BountyType.Banner]: CompanySentsFolder.Banners,
  [BountyType.Match]: CompanySentsFolder.Matches,
};

export const SURVEY_TYPES = [
  BountyType.Survey,
  BountyType.Score,
  BountyType.Checklist,
  BountyType.MCQ,
  BountyType.Funding,
  BountyType.Lottery,
];

import { getHandlers } from 'Store/genericActionsHelpers';
import { GenericActionType } from 'Constants/genericActionType';
import { GenericReducerName } from 'Constants/genericReducerName';

// initial state
const INIT_STATE = {
  bountyDetails: {},
  subBounties: {},
  answerDetails: {
    comments: [],
  },
  bounties: {},
  userStreams: {},
  bountyAnswers: {},
  bountyAnswersComments: {},
  bountyComments: [],
  defaultFilter: '',
  defaultSort: '',
};

function getBountyAnswersComments(state, payload) {
  const { bountyResponseId, bountyAnswersComments } = payload;
  const answerIndex = state.bountyAnswers.data.findIndex((item) => item.response.id === bountyResponseId);

  if (state.bountyAnswers.data && state.bountyAnswers.data[answerIndex]) {
    state.bountyAnswers.data[answerIndex].bountyAnswersComments = bountyAnswersComments;
  }

  return state;
}

function getAnswerComments(state, payload) {
  state.answerDetails.comments = payload;
  return state;
}

function setStreamList(state, payload) {
  const clone = JSON.parse(JSON.stringify(state));
  if (clone.bounties[payload.listId]) {
    clone.bounties[payload.listId] = {
      ...clone.bounties[payload.listId],
      list: [...(payload.page === 0 ? [] : clone.bounties[payload.listId].list), ...payload.list],
    };

    return clone;
  }

  clone.bounties[payload.listId] = {
    list: payload.list || [],
    totalCount: payload.totalCount,
  };

  return clone;
}

function removeStream(state, payload) {
  const clone = JSON.parse(JSON.stringify(state));
  clone.bounties[payload.listId] = null;
  return clone;
}

const handlersReducer = {
  ...getHandlers(GenericReducerName.UserStreams),
  [GenericActionType.GetBountyAnswersComments]: getBountyAnswersComments,
  [GenericActionType.GetAnswersComments]: getAnswerComments,
  [GenericActionType.SetStreamList]: setStreamList,
  [GenericActionType.DeleteDataSync]: removeStream,
};

export default function reducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}

import { configureStore } from '@reduxjs/toolkit';

import onboardingReducer from 'Store/onboarding/reducer';
import leaderboardReducer from 'Store/leaderboard/reducer';
import sentsReducer from 'Store/companySents/reducer';
import { ApplicationsFiltersReducerAlias } from 'Store/applicationsFilters/actions';
import attachmentsReducer from 'Store/attachments/reducer';
import callReducer from 'Store/call/reducer';
import cartReducer from 'Store/cart/reducer';
import bountyReducer from 'Store/bounty/reducer';
import authReducer from 'Store/auth/reducer';
import userAccountReducer from 'Store/userAccount/reducer';
import modalReducer from 'Store/modal/reducer';
import reportsReducer from 'Store/reports/reducer';
import groupsReducer from 'Store/groups/reducer';
import jobReducer from 'Store/job/reducer';
import { createApplicationsFiltersReducer } from 'Store/applicationsFilters/reducer';
import applicantsFiltersReducer from 'Store/applicantsFilters/reducer';
import candidatesFiltersReducer from 'Store/candidatesFilters/reducer';
import companyUsersFiltersReducer from 'Store/companyUsersFilters/reducer';
import { createJobsFiltersReducer } from 'Store/jobsFilters/reducer';
import { createUsersFiltersReducer } from 'Store/usersFilters/reducer';
import userProfileReducer from 'Store/userProfile/reducer';
import { createCompaniesFiltersReducer } from 'Store/companiesFilters/reducer';
import badgesReducer from 'Store/badges/reducer';
import promotionsReducer from 'Store/promotions/reducer';
import confirmationModalReducer from 'Store/confirmationModal/reducer';
import languagesReducer from 'Store/languages/reducer';
import clientReducer from 'Store/client/reducer';
import userManagementReducer from 'Store/userManagement/reducer';
import companyAccountReducer from 'Store/companyAccount/reducer';
import reservationsReducer from 'Store/reservations/reducer';

import createBountyReducer from 'Store/createBounty/reducer';
import skillsAndIndustriesReducer from 'Store/skillsAndIndustries/reducer';
import specialProgramsReducer from 'Store/specialPrograms/reducer';
import { UsersFiltersReducerAlias } from 'Store/usersFilters/actions';
import { CompaniesFiltersReducerAlias } from 'Store/companiesFilters/actions';
import headerReducer from 'Store/header/reducer';
import { JobsFiltersReducerAlias } from 'Store/jobsFilters/actions';
import { GenericReducerName } from 'Constants/genericReducerName';
import settingsReducer from 'Store/settings/reducer';
import userReducer from 'Store/user/reducer';
import merchantReducer from 'Store/merchant/reducer';
import productReducer from 'Store/product/reducer';
import matchesReducer from 'Store/matches/reducer';
import userChatsReducer from 'Store/userChats/reducer';
import orgCreationReducer from 'Store/orgCreation/reducer';

const reducer = {
  applicantsFiltersReducer,
  applicationsFiltersCommonReducer: createApplicationsFiltersReducer(ApplicationsFiltersReducerAlias.CommonPage),
  applicationsFiltersFromJobReducer: createApplicationsFiltersReducer(ApplicationsFiltersReducerAlias.Job),
  applicationsFiltersFromProfileReducer: createApplicationsFiltersReducer(ApplicationsFiltersReducerAlias.UserProfileTab),
  badgesReducer,
  callReducer,
  candidatesFiltersReducer,
  companiesFiltersAssociates: createCompaniesFiltersReducer(CompaniesFiltersReducerAlias.AssociatesTab),
  companiesFiltersCommonReducer: createCompaniesFiltersReducer(CompaniesFiltersReducerAlias.CommonPage),
  companiesFiltersInvitees: createCompaniesFiltersReducer(CompaniesFiltersReducerAlias.InviteesTab),
  companyUsersFiltersReducer,
  confirmationModalReducer,
  createBountyReducer,
  groupsReducer,
  headerReducer,
  jobReducer,
  jobsFiltersCommonReducer: createJobsFiltersReducer(JobsFiltersReducerAlias.CommonPage),
  jobsFiltersFromProfileReducer: createJobsFiltersReducer(JobsFiltersReducerAlias.UserProfileTab),
  languagesReducer,
  leaderboardReducer,
  matchesReducer,
  merchantReducer,
  modalReducer,
  onboardingReducer,
  orgCreationReducer,
  productReducer,
  promotionsReducer,
  reportsReducer,
  reservationsReducer,
  settingsReducer,
  clientReducer,
  skillsAndIndustriesReducer,
  specialProgramsReducer,
  userAccountReducer,
  userChatsReducer,
  userProfileReducer,
  userReducer,
  userManagementReducer,
  companyAccountReducer,
  usersFiltersCommonReducer: createUsersFiltersReducer(UsersFiltersReducerAlias.CommonPage),
  usersFiltersFromProfileReducer: createUsersFiltersReducer(UsersFiltersReducerAlias.UserProfileTab),
  [GenericReducerName.Attachments]: attachmentsReducer,
  [GenericReducerName.Auth]: authReducer,
  [GenericReducerName.Cart]: cartReducer,
  [GenericReducerName.Sents]: sentsReducer,
  [GenericReducerName.UserStreams]: bountyReducer,
};

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer,
});

export default store;

import { FC, ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { USER_ROUTE } from 'Constants/routes';
import classNames from 'classnames';
import UserAvatar from '../UserAvatar';
import * as styles from './index.module.scss';

interface UserShortPreviewProps {
  className?: string;
  avatarUrl?: string;
  name: string;
  id?: string;
  size?: 'regular' | 'small';
  isReversed?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => unknown;
}

const UserShortPreview: FC<UserShortPreviewProps> = ({
  className = '', isReversed = false, avatarUrl,
  name, id, size = 'regular', onClick,
}) => {
  const cssClasses = classNames(styles.userShortPreview, { [styles.reversed]: isReversed, [className]: !!className });

  const renderBody = (children: ReactNode) => (
    id
      ? (
        <Link
          className={cssClasses}
          onClick={(e) => e.stopPropagation()}
          to={`${USER_ROUTE}/${id}`}
          title={name}
        >
          {children}
        </Link>
      )
      : (<div className={cssClasses} onClick={(e) => onClick?.(e)}>{children}</div>)
  );

  return renderBody(
    <>
      <UserAvatar className={styles.avatar} url={avatarUrl} size={size} />
      <div className={classNames(
        styles.textWrapper,
        {
          [styles.sizeRegularWrapper]: size === 'regular',
          [styles.sizeSmallWrapper]: size === 'small',
        },
      )}
      >
        {name}
      </div>
    </>,
  );
};

export default UserShortPreview;

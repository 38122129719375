import { FC, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import HighlightBanner from 'Containers/BannerCreation/HighlightBanner';

import { setInlineAttachments, updateAttachment } from 'Store/attachments/actions';

import { Attachment } from 'Types/attachment.interface';
import { orderAttachments } from 'Utils/attachments';
import { attachmentsListSelector } from 'Store/attachments/selectors';
import { ThunkDispatchType } from 'Types/redux.types';
import { Bounty } from 'Types/bounty.interface';
import { DEFAULT_CATEGORY_HIGHLIGHTS_COUNT } from 'Constants/banners';
import { AttachmentAction } from 'Constants/enums';
import * as styles from './index.module.scss';

interface HighlightsManagementProps {
  selectedCompanyId?: string;
  bounty: Bounty;
}

const HighlightsManagement: FC<HighlightsManagementProps> = ({
  selectedCompanyId,
  bounty,
}) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const attachments: Attachment[] = useSelector(attachmentsListSelector) || [];

  useEffect(() => {
    const existingAttachments = bounty?.attachments?.attachments || {};

    if (!isEmpty(existingAttachments)) {
      const attachmentsList = orderAttachments(Object.values(existingAttachments));
      const remainingCount = DEFAULT_CATEGORY_HIGHLIGHTS_COUNT - attachmentsList?.length;
      const action = { actionType: AttachmentAction.Search };

      dispatch(setInlineAttachments([...attachmentsList, ...Array(remainingCount).fill({ action })]));
    }
  }, [bounty]);

  const handleUpdateAttachment = (attach: Partial<Attachment>, attachmentIndex: number): void => {
    dispatch(updateAttachment(attach, attachmentIndex));
  };

  return (
    <div className={styles.highlightsWrapper}>
      {attachments.map((attach, index) => (
        <HighlightBanner
          key={attach.id || attach.filename + index || index}
          attachment={attach}
          index={index}
          selectedCompanyId={selectedCompanyId}
          onChange={(updatedAttach: Partial<Attachment>) => handleUpdateAttachment(updatedAttach, index)}
        />
      ))}
    </div>
  );
};

export default HighlightsManagement;

import { AnyAction } from '@reduxjs/toolkit';
import { emptyJobBounty } from 'Constants/emptyModels';
import { Actions } from 'Store/job/actions';
import { Bounty } from 'Types/bounty.interface';
import { JobBounty } from 'Types/jobBounty.interface';

interface State {
  bounty: Bounty | JobBounty;
  isLoading: boolean;
}

const initialState: State = {
  bounty: { ...emptyJobBounty },
  isLoading: false,
};

const reducer = (state: State = initialState, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case Actions.GetBounty:
      return {
        ...state,
        bounty: payload,
      };

    case Actions.Reset:
      return {
        ...state,
        ...initialState,
      };

    case Actions.Loading:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const appendScriptToParent = (src: string, scriptId: string) => {
  const oldScript = document.getElementById(scriptId);

  if (oldScript) {
    oldScript.remove();
  }

  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.id = scriptId;

  document.head.append(script);
};

import classnames from 'classnames';
import { FC, forwardRef, memo, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { FilterOptionsState } from '@mui/base';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CustomChip from 'Components/CustomChip';
import CheckboxStyled from 'Components/CheckboxStyled';
import ControlValidation from 'Components/ControlValidation';
import { useIntl } from 'react-intl';
import { toggleValueInArray } from 'Utils/helpers';
import { DictionaryItem } from 'Types/dictionaryItem.type';
import * as styles from './index.module.scss';

type OptType = DictionaryItem<string, string | ReactNode>;

export interface ChipsSelectProps {
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  isSuccess?: boolean;
  options: OptType[];
  filterOptions?: (options: OptType[], state: FilterOptionsState<OptType>) => OptType[];
  renderIconFunc?: (option: OptType) => ReactNode;
  renderLabelFunc?: (option: DictionaryItem<string>) => string;
  onChange: (values: string[]) => unknown;
  values: (string | number)[];
  validationErrors?: string[];
  dataQa?: string;
  groupBy?: (option: OptType) => string;
  getOptionLabel?: (option: OptType) => string;
  getOptionDisabled?: (option: OptType) => boolean;
  placeholder?: string;
}

const ChipsSelect: FC<ChipsSelectProps> = (
  {
    className = '', inputClassName = '', disabled, isSuccess, options,
    renderIconFunc, renderLabelFunc, onChange, values = [], filterOptions, getOptionDisabled,
    validationErrors = [], dataQa = 'dropdown', groupBy, getOptionLabel, placeholder = '',
  },
) => {
  const intl = useIntl();
  const [fullValues, setFullValues] = useState<DictionaryItem<string, string | ReactNode>[]>([]);

  useEffect(() => {
    setFullValues(options.filter((o) => values.includes(o.value)));
  }, [options, values]);

  const handleChange = (e: SyntheticEvent, opts: OptType[]) => onChange(opts.map((o) => o.value));

  const getLabel = (option: OptType) => (renderLabelFunc ? renderLabelFunc(option) : option.label);

  const getIcon = (option: OptType) => (renderIconFunc ? renderIconFunc(option) : null);

  const handleClick = useCallback((option: OptType) =>
    onChange(toggleValueInArray(values, option.value)), [values, toggleValueInArray, onChange]);

  const renderChipComponent = (option: OptType) => forwardRef(() => (
    <CustomChip
      className={classnames(styles.chip, { [className]: !!className })}
      label={getLabel(option)}
      icon={getIcon(option)}
      onClick={() => handleClick(option)}
    />
  ));

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.stateInvalid]: validationErrors?.length > 0,
        [styles.stateSuccess]: isSuccess,
        [styles.stateDisabled]: disabled,
      })}
    >
      <Autocomplete
        multiple
        disabled={disabled}
        filterOptions={filterOptions}
        disableCloseOnSelect
        options={options}
        value={fullValues}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleChange}
        slotProps={{
          paper: {
            className: styles.paper,
          },
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} className={classnames(props.className, styles.option)} key={option.value}>
            <CheckboxStyled className={styles.checkbox} checked={selected} />
            {getIcon(option)}
            {getLabel(option)}
          </li>
        )}
        renderInput={({ inputProps, ...params }) => (
          <TextField
            {...params}
            inputProps={{ ...inputProps, 'data-qa': dataQa }}
            placeholder={placeholder || intl.formatMessage({ id: 'label.select' })}
            className={`${styles.control} ${inputClassName}`}
          />
        )}
        renderTags={(tags) =>
          tags.map((option) => (
            <Chip component={renderChipComponent(option)} key={option.value} />
          ))}
      />
      {validationErrors?.length > 0 && <ControlValidation validationErrors={validationErrors} />}
    </div>
  );
};

export default memo(ChipsSelect);

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyDcYXOoGPCuu738yzzME3MUGipNeaE1qSY',
    authDomain: 'banfield-prod.firebaseapp.com',
    databaseURL: 'https://banfield-prod-default-rtdb.firebaseio.com',
    projectId: 'banfield-prod',
    storageBucket: 'banfield-prod.appspot.com',
    messagingSenderId: '497037420245',
    appId: '1:497037420245:web:69450a1a8958b623f7645c',
    measurementId: 'G-E0KVEFC41P',
  },
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: '',
  algolia_api_key: '',
};

export default config;

import { AppDispatch } from 'Types/redux.types';

export enum Actions {
  DeviceInit = 'call/DeviceInit',
  CallStart = 'call/Start',
  CallEnd = 'call/End',
}

export const deviceInit = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.DeviceInit });

export const callStart = (phoneNumber: string, name: string) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.CallStart, payload: { phoneNumber, name } });

export const callEnd = (phoneNumber?: string, name?: string) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.CallStart, payload: { phoneNumber, name } });

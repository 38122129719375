import { firebaseGetCurrentlySignedInUser } from 'Services/FirebaseService';
import { getApiData, postWithEmptyResponse } from 'Services/Api';
import { formatApiRoute } from 'Utils/api';
import { USERS_DATA } from 'Constants/apiRoutes';
import { getDefaultLanguage, isValidLanguage } from 'Utils/languagesHelpers';

const getLocaleServer = (userID: string, setLocale: (locale: string) => any) => {
  if (!userID) return;
  const path = formatApiRoute(
    {
      queryParams: {},
      endpoint: USERS_DATA,
      params: { userId: userID },
    },
  );
  getApiData(path).then((data) => {
    if (isValidLanguage(data.locale)) {
      setLocale(data.locale);
    } else {
      setLocale(getDefaultLanguage());
    }
  });
};

const postLocaleServer = (userID: string, data: string, callBack?: () => any) => {
  if (!userID) return;
  const path = `/api/v1/users/${userID}/locale?lang=${data}`;
  firebaseGetCurrentlySignedInUser()
    .then((token) => {
      postWithEmptyResponse(path, token).then(() => {
        // eslint-disable-next-line no-console
        console.log('posted');
        callBack?.();
      }).catch((e) => console.error('Error posting locale to server', e));
    });
};

export { getLocaleServer, postLocaleServer };

import { FC, useCallback, useContext } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { IMenu } from 'Types/menu.interface';
import { LanguageContext } from 'Contexts/languagesContext';
import MessagesIndicator from 'Components/MessagesIndicator';
import * as styles from 'Components/SideBar/NavMenuContent/index.module.scss';

interface NavMenuContentProps {
  menu: IMenu,
  open?: boolean,
}

const NavMenuContent:FC<NavMenuContentProps> = ({
  menu,
  open = true,
}) => {
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const titleTranslated = useCallback((item: IMenu) => (
    intl.formatMessage({ id: item.menu_title })
  ), [menu, locale]);

  return (
    <Box className={styles.navItemWrapper} title={!open ? titleTranslated(menu) : ''}>
      {menu.id === 'message' ? (
        <MessagesIndicator
          icon={menu.menu_icon}
          name={titleTranslated(menu)}
        />
      ) : (
        <>
          {menu.menu_icon && (
            <span className={`mr-20 material-symbols-rounded ${styles.imageWrapper}`}>
              <span className={menu.menu_icon} />
            </span>
          )}
          <span className="menu">
            <span>
              {titleTranslated(menu)}
            </span>
          </span>
        </>
      )}
    </Box>
  );
};

export default NavMenuContent;

import { getApiData } from 'Services/Api';
import { LOCATION_DETAILS_API, LOCATIONS_API, OPEN_US_STATES_API } from 'Constants/apiRoutes';
import { formatApiRoute } from 'Utils/api';
import { ClientLocation, LocationStateOption } from 'Types/clientLocation.interface';

// TODO: add response types
export const LocationsService = {
  getLocations(companyId: string): Promise<{ list: ClientLocation[] }> {
    return getApiData(formatApiRoute({ endpoint: LOCATIONS_API, params: { companyId }, queryParams: {} }));
  },
  getLocationDetails(companyId: string): Promise<{ location: ClientLocation }> {
    return getApiData(formatApiRoute({ endpoint: LOCATION_DETAILS_API, params: { companyId }, queryParams: {} }));
  },
  getUSstates(): Promise<{ list: LocationStateOption[] }> {
    return getApiData(OPEN_US_STATES_API);
  },
};

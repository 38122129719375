import { FC, ReactNode, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import 'moment/locale/es';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import customTheme from 'Utils/theme/customTheme';
import { IntlConsume } from 'Utils/intl';
import { LanguageContext } from 'Contexts/languagesContext';
import { getCurrentLocale, mergeWithEngLang, splitLanguage } from 'Utils/languagesHelpers';
import FiltersContext from 'Contexts/filtersContext';

interface InterfaceLocaleProviderProps {
  children: ReactNode | ReactNode[];
  rtlLayout?: boolean
}

const InterfaceLocaleProvider: FC<InterfaceLocaleProviderProps> = ({ children, rtlLayout = false }) => {
  const [locale, setLocale] = useState(getCurrentLocale(null));
  const currentAppLocale = mergeWithEngLang(getCurrentLocale(locale));

  const theme = customTheme;
  theme.direction = rtlLayout ? 'rtl' : 'ltr';

  const languageContext = useMemo(() => ({
    locale,
    setLocale,
  }), [locale]);

  if (locale) {
    moment.locale(locale);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LanguageContext.Provider value={languageContext}>
          <IntlProvider
            locale={splitLanguage(locale)}
            defaultLocale={splitLanguage(locale)}
            messages={mergeWithEngLang(locale).messages}
          >
            <FiltersContext.Provider value={FiltersContext}>
              <IntlConsume
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
              >
                {children}
              </IntlConsume>
            </FiltersContext.Provider>
          </IntlProvider>
        </LanguageContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default InterfaceLocaleProvider;

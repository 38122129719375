import AppLogoText from '../src/assets/img/logo/appLogoText.png';
import AppLogoTextWhite from '../src/assets/img/logo/appLogoTextWhite.png';
import BkgImage from '../src/assets/img/general/bkg-image.jpg';
import UserAvatarPlaceholder from '../src/assets/img/general/avatar-placeholder.png';

export default {
  stripeKey: 'pk_live_8n9cBjQ64DllvAPgH09bL0Dk',
  stripeTestKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  SENTRY_CONFIG: {
    dsn: 'https://9424823d10f144509217bcbfff64bad6@o116454.ingest.sentry.io/5253020',
  },
  images: {
    appLogoText: AppLogoText,
    bkgImage: BkgImage,
    userAvatarPlaceholder: UserAvatarPlaceholder,
    appLogoTextWhite: AppLogoTextWhite,
  },
};

import { Bounty } from 'Types/bounty.interface';
import { firebaseGetProductsRef } from 'Services/FirebaseService';
import { convertObjToArray } from 'Utils/helpers';
import { AppDispatch } from 'Types/redux.types';
import { Address } from 'Types/address.interface';

export enum Actions {
  SetProducts = 'onboardingReducer/SetProducts',
  SelectProduct = 'onboardingReducer/SelectProduct',
  IsLoading = 'onboardingReducer/IsLoading',
  SetAge = 'onboardingReducer/SetAge',
  SetTerms = 'onboardingReducer/SetTerms',
  SetAddress = 'onboardingReducer/SetAddress',
  Reset = 'onboardingReducer/reset',
}

export const getProducts = ({ promotionId }: { promotionId: string }) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.IsLoading, payload: true });

  firebaseGetProductsRef(promotionId)
    .once('value', (dataSnapshot) => {
      const values = convertObjToArray(dataSnapshot.val());
      dispatch({ type: Actions.IsLoading, payload: false });
      dispatch({ type: Actions.SetProducts, payload: values });
    }, () => {
      dispatch({ type: Actions.IsLoading, payload: false });
      dispatch({ type: Actions.SetProducts, payload: [] });
    });
};

export const setSelectedProduct = (product: Bounty) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SelectProduct, payload: product });
};

export const setAge = (age: number) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SetAge, payload: age });
};

export const setTerms = (accepted: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SetTerms, payload: accepted });
};

export const setAddress = (address: Address) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SetAddress, payload: address });
};

export const resetReducer = () => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.Reset });
};

import { SortDirection } from 'Constants/enums';
import { DictionaryItem } from 'Types/dictionaryItem.type';
import { SortChange } from 'Types/sortChange.interface';

export const toggleSortDirection = (direction: SortDirection): SortDirection =>
  (direction === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC) as SortDirection;

export const formatSortQueryProp = (fieldName: string, direction: SortDirection): string =>
  `${fieldName}$${direction}`;

export const parseSortQueryProp = (queryProp: string): SortChange => {
  const sortProps = queryProp.split('$');

  return {
    by: sortProps[0],
    direction: sortProps[1] as SortDirection,
  };
};

export const sortDictionaryByLabel = (a: DictionaryItem<any>, b: DictionaryItem<any>): number =>
  a.label.localeCompare(b.label);

//  Others
import {
  firebaseGetMyAccountRef,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';
import { isSortableAction } from 'Store/genericActions';
import { Actions } from './auth/actions.tsx';

export const getAccountInfo = () => (dispatch) => {
  const me = firebaseGetCurrentUser();

  firebaseGetMyAccountRef(me.uid).on('value', (dataSnapshot) => {
    const accountInfo = dataSnapshot.val();
    dispatch({ type: Actions.GetAccountInfo, payload: accountInfo });
  });
};

export const setSortable = (namespace, reduceProperty, value) => (dispatch) => {
  dispatch(isSortableAction(namespace, reduceProperty, value));
};

import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

//  Components
import Layout from 'Containers/Layout';

//  Others
import { commonRoutesWithoutLayout, getDefaultRoutes } from '../configs/common';
import { AsyncNotFoundPage } from '../configs/AsyncRoutes';
import Loadable from '../configs/Suspense';

interface RoutesComponentProps {
  user: object;
  permissions: object;
}

const RoutesComponent: FC<RoutesComponentProps> = ({ permissions }) => {
  const routesWithLayout = getDefaultRoutes(permissions);

  return (
    <Loadable>
      <Routes>
        {
          commonRoutesWithoutLayout.map(({ path, Component, ...remainingProps }, index) => (
            <Route
              key={index}
              path={path}
              element={<Component />}
              {...remainingProps}
            />
          ))
        }
        <Route
          path="*"
          element={
            <Layout>
              <Routes>
                {
                  routesWithLayout.map(({ path, Component, ...remainingProps }, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={<Component />}
                      {...remainingProps}
                    />
                  ))
                }
                <Route path="*" element={<AsyncNotFoundPage />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Loadable>
  );
};

export default RoutesComponent;

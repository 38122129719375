import { Currency } from 'Constants/enums';

export const currencySymbols: Partial<Record<Currency, string>> = {
  [Currency.USD]: '$',
  [Currency.CAD]: '$',
  [Currency.EUR]: '€',
  [Currency.RON]: 'lei',
  [Currency.MDL]: 'lei',
  [Currency.GBP]: '£',
  [Currency.PLN]: 'zł',
  [Currency.GEL]: 'gel',
  [Currency.ARS]: '$',
  [Currency.AED]: 'د.إ',
};

export const invalidCurrencyValues: Currency[] = [
  Currency.None,
  Currency.Unknown,
];

export const EMPTY_REWARD = '0';

export const TOKENS_REDEMPTION_VALUES = [
  {
    id: 'token-redemption-1',
    min: 1,
    max: 1600,
    value: 0.0003,
    gain: null,
  },
  {
    id: 'token-redemption-2',
    min: 1601,
    max: 3600,
    value: 0.00035,
    gain: 17,
  },
  {
    id: 'token-redemption-3',
    min: 3601,
    max: 8500,
    value: 0.0004,
    gain: 33,
  },
  {
    id: 'token-redemption-4',
    min: 8501,
    max: 21000,
    value: 0.00043,
    gain: 43,
  },
  {
    id: 'token-redemption-5',
    min: 21001,
    max: 51000,
    value: 0.00047,
    gain: 57,
  },
  {
    id: 'token-redemption-6',
    min: 51001,
    max: 130000,
    value: 0.00052,
    gain: 73,
  },
  {
    id: 'token-redemption-7',
    min: 130001,
    max: null,
    value: 0.0006,
    gain: 100,
  },
];

export const FORBIDDEN_TOKENS_SYMBOLS = [
  'STK', 'SKN', 'CPG', 'CMP', 'CTK', 'CAM', 'CPT', 'CSY', 'CPN', 'CGN', 'CST', 'CPM', 'CNG', 'CTN', 'CGS', 'CPK', 'CNK', 'CGK', 'CKN', 'TKN', 'CTKN',
  'CTKNS', 'CPGK', 'CPGN', 'CGNP', 'CMPK', 'CPNG', 'CAMP', 'CAMG', 'CAMPK', 'CAMPN', 'CAMPY', 'CAMPT', 'CAMTK', 'CAMPTK', 'CAMTS', 'CGNTK',
  'CMPGN', 'CMPGNS', 'CMPGK', 'CMPGT', 'CMPGTK', 'CMPGX', 'CMPGP', 'CMPNS', 'CMPNT', 'CPGNT', 'CPGNY', 'CPGNX', 'CPGNP', 'CPGSN', 'CPGST', 'CPGTN',
  'CPGSN', 'CPGST', 'CPGNK', 'CPGNS', 'STKN', 'STKNS', 'SYTKN', 'SYTKNS', 'TKNCP', 'TKNCMP', 'TKNCMS', 'TKNCM', 'TKNCG', 'TKNCPG', 'TKNCS', 'TKNCGS',
];

import { createSelector } from '@reduxjs/toolkit';
import { isSystemAdmin } from 'Utils/roleDef';
import { getMyCurrency, findFirst, isEmployer } from 'Utils/settings';

export const userDataSelector = (state) => state.settingsReducer.userData || {};
export const userInfoSelector = (state) => state.settingsReducer.userInfo || {};
export const companySettingsSelector = (state) => state.settingsReducer.companySettings || {};
export const systemSettingsSelector = (state) => state.settingsReducer.systemSettings || {};
export const orgTypeSettingsSelector = (state) => state.settingsReducer.orgTypeSettings || {};
export const pinSettingsSelector = (state) => state.settingsReducer.pin || '';
export const isLoggedUserSystemAdminSelector = (state) => isSystemAdmin(userDataSelector(state)?.data?.roles);
export const isLoggedUserEmployerSelector = (state) => isEmployer(companySettingsSelector(state)?.data?.organizationType);

export const ownerSelector = createSelector(
  userDataSelector,
  ({ data }) => ((data && data.owner) || null),
);

export const companyGroupsSelector = createSelector(
  companySettingsSelector,
  ({ data }) => ((data && data.groups) || {}),
);

export const settingsSelector = createSelector(
  userDataSelector,
  companySettingsSelector,
  systemSettingsSelector,
  orgTypeSettingsSelector,
  pinSettingsSelector,
  (userData, companySettings, systemSettings, orgTypeSettings) => ({
    userSettings: (userData && userData.data && userData.data.settings) || null,
    companySettings: (companySettings && companySettings.data) || null,
    systemSettings: (systemSettings && systemSettings.data) || null,
    orgTypeSettings: (orgTypeSettings && orgTypeSettings.data) || null,
  }),
);

createSelector(
  settingsSelector,
  (settings) => findFirst(settings, (s) => (s.sysInfo ? s.sysInfo.legal : null)),
);

export const getMyCurrencySelector = createSelector(
  settingsSelector,
  userDataSelector,
  (settings, userData) => getMyCurrency(settings, userData.data),
);

export const isSidebarOpenSelector = (state) => (state.settingsReducer.isSidebarOpen);

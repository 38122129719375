import { getApiData, postApiData } from 'Services/Api';
import { MERCHANT_SHIPPING_FEE_API } from 'Constants/apiRoutes';
import { formatApiRoute } from 'Utils/api';
import { ShippingFee } from "Types/shippingFees.interface";

export const MerchantService = {
  getShippingFee(userId: string): Promise<ShippingFee> {
    const endpoint = formatApiRoute({
      endpoint: MERCHANT_SHIPPING_FEE_API,
      params: { userId },
      queryParams: {}
    });
    return getApiData(endpoint);
  },
  addShippingFee(userId: string, payload: ShippingFee) {
    const endpoint = formatApiRoute({
      endpoint: MERCHANT_SHIPPING_FEE_API,
      params: { userId },
      queryParams: {}
    });

    return postApiData(endpoint, payload);
  },
};

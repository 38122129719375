import { FC, ReactNode, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import AlgoriaInstantSearchProps from 'Containers/AlgoliaInstantSearch';
import Header from 'Components/Header';
import useMediaQuery from "@mui/material/useMediaQuery";

import SideBar from 'Components/SideBar';
import PhoneDialer from 'Components/PhoneDialer';

import {
  getAuthUserPrivateInfo,
  getUserProfile,
  logoutUserFromFirebase,
  subscribeToAuthUserEndorsements,
  subscribeToAuthUserProfile,
  unsubscribeFromAuthUserEndorsements,
  unsubscribeFromAuthUserProfile,
} from 'Store/auth/actions';

import { isSidebarOpenSelector, userDataSelector } from 'Store/settings/selectors';
import { authUserCompanyIdSelector } from 'Store/auth/selectors';
import { CHAT_ROUTE, REPORTS_ROUTE, SIGN_IN_ROUTE } from 'Constants/routes';
import { canCallOutSelector } from 'Store/permissions/selectors';
import { ThunkDispatchType } from 'Types/redux.types';
import { EmployerContext } from 'Contexts/employerContext';
import { BREAKPOINTS } from "Constants/common";
import * as styles from './index.module.scss';

interface LayoutProps {
  children: ReactNode | ReactNode[];
}

//  TODO: check how to use preload components
const Layout: FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const isMobile = useMediaQuery(`@media (max-width:${BREAKPOINTS.sm}px)`);
  const isSidebarOpen = useSelector(isSidebarOpenSelector) || false;

  const userData = useSelector(userDataSelector)?.data;
  const companyId = useSelector(authUserCompanyIdSelector);
  const canCallOut = useSelector(canCallOutSelector);
  const userDataCheck = userData?.myself?.id;
  const { employerData, setEmployerData } = useContext(EmployerContext);

  useEffect(() => {
    if (employerData?.companyName || !userDataCheck) {
      setEmployerData(null);
      dispatch(logoutUserFromFirebase({ clearPin: true }));
      navigate(SIGN_IN_ROUTE);
      window.location.reload();
    }
    dispatch(subscribeToAuthUserProfile(userDataCheck));
    return () => unsubscribeFromAuthUserProfile(userDataCheck);
  }, [userDataCheck]);

  useEffect(() => {
    if (!userDataCheck) {
      dispatch(logoutUserFromFirebase({ clearPin: true }));
      navigate(SIGN_IN_ROUTE);
    }
    dispatch(subscribeToAuthUserEndorsements(userDataCheck));
    return () => unsubscribeFromAuthUserEndorsements();
  }, [userDataCheck]);

  useEffect(() => {
    if (!userDataCheck) {
      dispatch(logoutUserFromFirebase({ clearPin: true }));
      navigate(SIGN_IN_ROUTE);
    } else {
      dispatch(getAuthUserPrivateInfo(userDataCheck));
    }
  }, [userDataCheck]);

  useEffect(() => {
    (scrollRef?.current as any)?.scrollToTop(); // TODO: add type
  }, [location.pathname]);

  useEffect(() => {
    if (companyId && userDataCheck) {
      dispatch(getUserProfile(companyId));
    }
  }, [companyId, userDataCheck]);

  const renderPage = () => {
    if (location.pathname.startsWith(CHAT_ROUTE)) {
      return (
        <div className="rct-page-content p-0">
          {children}
        </div>
      );
    }

    return (
      <div className="rct-page-content position-relative">
        <div className={classNames(styles.innerWrapper, { [styles.content]: !location.pathname.startsWith(REPORTS_ROUTE) })}>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      <div className="app-main-container">
        {(!isMobile || isSidebarOpen) && <SideBar />}
        {userData && (
          <Box width="100%" className={styles.rootWrapper}>
            <AlgoriaInstantSearchProps userData={userData}>
              <div className="app-container">
                <div className="rct-app-content">
                  <div className="app-header">
                    <Header />
                  </div>
                  <div className="rct-page" id="rct-page">
                    {renderPage()}
                  </div>
                </div>
              </div>
            </AlgoriaInstantSearchProps>
          </Box>
        )}
      </div>
      {canCallOut && <PhoneDialer />}
    </div>
  );
};

export default Layout;

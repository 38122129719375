import { Actions } from 'Store/candidatesFilters/actions';
import { formatSortQueryProp } from 'Utils/sort';
import { CandidatesSortFields } from 'Constants/sort';
import { SearchMode, SortDirection } from 'Constants/enums';
import { SortAndSearch } from 'Types/sortAndSearch.type';
import { ByPageReq } from 'Types/byPageReq.interface';
import { CandidatesFilters } from 'Types/candidatesFilters.type';
import { AnyAction } from '@reduxjs/toolkit';

const lsLimitName = 'candidatesLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

interface State {
  reqProps: SortAndSearch & ByPageReq;
  filters: CandidatesFilters;
}

const initialState: State = {
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp(CandidatesSortFields.CreatedDate, SortDirection.DESC),
    query: '',
  },
  filters: {
    citizenship: [],
    country: [],
    gender: [],
    languages: [],
    searchMode: SearchMode.Default,
    skillset: [],
    withCertificates: false,
  },
};

// TODO: fix actions types
const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.UpdateReqProps:
      if (payload.limit) {
        localStorage.setItem(lsLimitName, payload.limit);
      }
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload,
        },
      };

    case Actions.UpdateFilters:
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload.reqProps,
        },
        filters: {
          ...state.filters,
          ...payload.filters,
        },
      };

    case Actions.ResetFilters:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case Actions.ResetAll:
      return { ...initialState };

    default:
      return state;
  }
};

export default reducer;

import { BountyResponseState, BountyType, VisibilityModeType } from 'Constants/enums';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { VisibilityMode } from 'Types/visibilityMode.interface';

export function getLevel1Id(response: BountyResponse) {
  return response?.bountyInfo?.id || null;
}

export function getLevel2Id(response: BountyResponse) {
  return response.id;
}

export function isAllowedToMarkAsOfficial(state: BountyResponseState) {
  return [
    BountyResponseState.Active,
    BountyResponseState.Accepted,
    BountyResponseState.Acked,
    BountyResponseState.Paid,
    BountyResponseState.Paying,
  ].includes(state);
}

export function isAllowedToPin(state: BountyResponseState) {
  return [
    BountyResponseState.Active,
    BountyResponseState.Accepted,
    BountyResponseState.Acked,
    BountyResponseState.Paid,
    BountyResponseState.Paying,
  ].includes(state);
}

export function isAllowedToAccept(state: BountyResponseState) {
  return [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
  ].includes(state);
}

export function isAllowedToRetract(state: BountyResponseState) {
  return [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Accepted,
  ].includes(state);
}

export function isAllowedToRefuse(state: BountyResponseState) {
  return [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
  ].includes(state);
}

export function isAllowedToDelete(state: BountyResponseState) {
  return [
    BountyResponseState.Draft,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
    BountyResponseState.Refused,
  ].includes(state);
}

export function isAllowedToDemote(state: BountyResponseState) {
  return [
    BountyResponseState.Draft,
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
    BountyResponseState.Refused,
  ].includes(state);
}

export const privateRepliesByBountyType = (bountyType: BountyType) => (
  [
    BountyType.Lottery,
    BountyType.Poll,
    BountyType.Survey,
    BountyType.Funding,
    BountyType.Classified,
    BountyType.MCQ,
  ].includes(bountyType)
);

export function areRepliesPrivate(responseVisibilityMode: VisibilityMode) {
  return responseVisibilityMode && responseVisibilityMode.visibilityType === VisibilityModeType.Private;
}

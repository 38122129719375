import { useState, useEffect, FC, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, FormControl, RadioGroup } from '@mui/material';
import { useIntl } from 'react-intl';

//  Components
import CustomModalButtons from 'Components/CustomModalButtons';
import RadioStyled from 'Components/RadioStyled';

//  Actions/Selectors
import { closeModal } from 'Store/modal/actions';
import { changeBountyRatingValue } from 'Services/bounty/StatsService';
import { changeResponseRatingValue } from 'Services/response/StatsService';
import { ownerSelector } from 'Store/settings/selectors';

//  Other resources
import { DEFAULT_RATE_STAR_POINTS_VALUE } from 'Constants/bounty';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import * as styles from './index.module.scss';

interface ChangeBountyRatingValueProps {
  bounty?: Bounty;
  response?: BountyResponse;
}

const ChangeBountyRatingValue:FC<ChangeBountyRatingValueProps> = ({ bounty = {}, response = {} }) => {
  const [ratingValue, setRatingValue] = useState('');

  const owner = useSelector(ownerSelector) || {};
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  useEffect(() => {
    setInitialRatingValue();
  }, []);

  const setInitialRatingValue = () => {
    const { rating } = bounty;
    const initialRating = (rating && rating.starPointsValue) || DEFAULT_RATE_STAR_POINTS_VALUE;
    setRatingValue(initialRating.toString());
  };

  const handleChanges = (e:ChangeEvent<HTMLInputElement>) => {
    setRatingValue(e.target.value);
  };

  const handleSubmit = () => {
    if (response?.bountyInfo) {
      dispatch(changeResponseRatingValue({
        bounty: response.bountyInfo,
        bountyResponse: response,
        ownerId: owner.id,
        ratingValue: +ratingValue,
      }));

      return dispatch(closeModal());
    }

    dispatch(changeBountyRatingValue({ bounty, ratingValue: +ratingValue }));
    dispatch(closeModal());
  };

  return (
    <div>
      <span className="d-inline-block">
        <span className={`material-symbols-rounded vertical-align-bottom ${styles.star} icon-star-1`} />
        <span> = </span>
      </span>
      <div className="ml-50">
        <FormControl component="fieldset" required>
          <RadioGroup
            aria-label="gender"
            name="rating"
            value={ratingValue}
            onChange={handleChanges}
          >
            <FormControlLabel
              value="1"
              control={<RadioStyled />}
              label={intl.formatMessage({ id: 'label.pointsValue' }, { value: 1 })}
            />
            <FormControlLabel
              value="2"
              control={<RadioStyled />}
              label={intl.formatMessage({ id: 'label.pointsValue' }, { value: 2 })}
            />
            <FormControlLabel
              value="3"
              control={<RadioStyled />}
              label={intl.formatMessage({ id: 'label.pointsValue' }, { value: 3 })}
            />
            <FormControlLabel
              value="4"
              control={<RadioStyled />}
              label={intl.formatMessage({ id: 'label.pointsValue' }, { value: 4 })}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <CustomModalButtons
        onDismiss={() => dispatch(closeModal())}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ChangeBountyRatingValue;

import { AppDispatch } from 'Types/redux.types';
import { ConfirmationModalType } from 'Constants/enums';

export enum Actions {
  Show = 'confirmationModal/ShowModal',
  Hide = 'confirmationModal/HideModal',
}

export const hideConfirmationModal = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.Hide, payload: false });

const showConfirmationModal = (status: ConfirmationModalType = ConfirmationModalType.Info, message: string = '', title: string | null = '', autoCLoseTimer: number = 0) =>
  (dispatch: AppDispatch) =>
    dispatch({
      type: Actions.Show,
      payload: {
        open: true,
        status,
        message,
        title,
        autoCLoseTimer,
      },
    });

export const confirmationModal = {
  info: (message: string, title?: string | null, autoCLoseTimer?: number) =>
    showConfirmationModal(ConfirmationModalType.Info, message, title, autoCLoseTimer),
  success: (message: string, title?: string | null, autoCLoseTimer?: number) =>
    showConfirmationModal(ConfirmationModalType.Success, message, title, autoCLoseTimer),
  warning: (message: string, title?: string | null, autoCLoseTimer?: number) =>
    showConfirmationModal(ConfirmationModalType.Warning, message, title, autoCLoseTimer),
  error: (message: string, title?: string | null, autoCLoseTimer?: number) =>
    showConfirmationModal(ConfirmationModalType.Error, message, title, autoCLoseTimer),
};

import { BountyState, BountyType, CompanyType, Currency, PayPeriodUnit, RegionLevel, UserType } from 'Constants/enums';
import { JobInfo } from 'Types/jobInfo.interface';
import { PaymentValue } from 'Types/paymentValue.interface';
import { Money } from 'Types/money.interface';
import { Bounty } from 'Types/bounty.interface';
import { JobBounty } from 'Types/jobBounty.interface';
import { User } from 'Types/user.interface';
import { Rating } from 'Types/rating.interface';
import { BountyStats } from 'Types/bountyStats.interface';

export const emptyUser: User = {
  id: '',
  name: '',
  avatar: '',
  orgType: CompanyType.Unknown,
  type: UserType.Unknown,
  verified: null,
};

export const emptyRating: Rating = {
  userRatings: {},
  ratingCount: 0,
  starPointsValue: 1,
  totalStars: 0,
};

export const emptyMoney: Money = {
  amount: null,
  currency: Currency.Unknown,
};

export const emptyPaymentValue: PaymentValue = {
  money: { ...emptyMoney },
};

export const emptyJobInfo: JobInfo = {
  benefits: {},
  bonus: { ...emptyPaymentValue },
  bonusPeriod: null,
  bonusType: null,
  contractRate: { ...emptyPaymentValue },
  countryCode: '',
  contractLength: 0,
  contractLengthUnit: PayPeriodUnit.UnknownPeriod,
  hrsPerWeekMax: 0,
  hrsPerWeekMin: 0,
  industries: {},
  minFee: { ...emptyPaymentValue },
  maxFee: { ...emptyPaymentValue },
  minOutputUnits: 0,
  maxOutputUnits: 0,
  maxPositions: 0,
  minGrossPay: { ...emptyPaymentValue },
  maxGrossPay: { ...emptyPaymentValue },
  minNetPay: { ...emptyPaymentValue },
  maxNetPay: { ...emptyPaymentValue },
  mandatoryDocuments: {},
  payLowEnd: { ...emptyPaymentValue },
  payHighEnd: { ...emptyPaymentValue },
  payPeriod: null,
  skillSet: {},
  specialPrograms: {},
  withholding: 0,
};

export const emptyBountyStats: BountyStats = {
  acceptedResponseCount: 0,
  commentCount: 0,
  inviteeCount: 0,
  likesCount: 0,
  participantCount: 0,
  pendingChats: 0,
  pendingResponseCount: 0,
  reach: 0,
  rebounties: 0,
  responseCount: 0,
  sharesRecv: 0,
  sharesSent: 0,
  subBounties: 0,
  talking: 0,
  viewsCount: 0,
};

export const emptyBounty: Bounty = {
  id: '',
  shortId: '',
  name: '',
  attachments: {
    attachments: {},
  },
  agentInfo: {
    createdOn: '',
    editedOn: '',
  },
  countryCode: '',
  createdAt: 0,
  postedAt: 0,
  author: { ...emptyUser },
  creator: { ...emptyUser },
  creatorUpdatedAtSortKey: '',
  description: {
    text: '',
  },

  listCreatedAtSortKey: '',
  listDiscussedSortKey: '',
  listId: '',
  listPopularitySortKey: '',
  metaInfo: {
    outbound: false,
    incomplete: false,
  },
  myself: { ...emptyUser },
  outboundResponses: {},
  owner: { ...emptyUser },
  rating: { ...emptyRating },
  reward: null,
  state: BountyState.Draft,
  stats: { ...emptyBountyStats },
  streamCreatedAtSortKey: '',
  streamDiscussedSortKey: '',
  streamPopularitySortKey: '',
  title: '',
  type: BountyType.Unknown,
  updatedAt: 0,
  varTerms: { hopsToLive: 0 },
  visibility: RegionLevel.Global,
};

export const emptyJobBounty: JobBounty = {
  ...emptyBounty,
  jobInfo: { ...emptyJobInfo },
  type: BountyType.Job,
};

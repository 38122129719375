import { RootState } from 'Types/redux.types';
import { SkillDictionaryItem } from 'Types/skillDictionaryItem.type';
import { IndustryDictionaryItem } from 'Types/industryDictionaryItem.type';

export const skillsSelector = (state: RootState): SkillDictionaryItem[] =>
  state.skillsAndIndustriesReducer.skills;

export const industriesSelector = (state: RootState): IndustryDictionaryItem[] =>
  state.skillsAndIndustriesReducer.industries;

export const skillsAndIndustriesIsLoadedSelector = (state: RootState): boolean =>
  state.skillsAndIndustriesReducer.isLoaded;

export const skillsAndIndustriesIsLoadingSelector = (state: RootState): boolean =>
  state.skillsAndIndustriesReducer.isLoading;

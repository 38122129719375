import get from 'lodash/get';
import { object, array, func } from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';

import NumberInput from 'Components/NumberInput';
import StyledSelect from 'Components/StyledSelect';
import TooltipAnchor from 'Components/TooltipAnchor/index';

import { getErrorsByName } from 'Utils/formik';
import { RewardType } from 'Constants/enums';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';

const ProductFormPrice = (props) => {
  const { formik, currencyOptions, onChange } = props;
  const intl = useIntl();

  const hasMainPrice = [RewardType.Money, RewardType.Points].includes(get(formik?.values, 'currencyType'));

  const renderCurrencySelector = () => (
    <div>
      <label className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: 'private.currencyName' })}
      </label>
      <StyledSelect
        name="currencyType"
        value={formik?.values?.currencyType || ''}
        onChange={onChange}
      >
        {currencyOptions.map(({ label, value: optionValue }) => (
          <MenuItem key={optionValue} value={optionValue}>{label}</MenuItem>
        ))}
      </StyledSelect>
    </div>
  );

  const renderBoldChunks = (chunks) => <b>{chunks}</b>;

  const renderBreak = () => <br />;

  return (
    <Grid container spacing={2} alignItems="flex-start">
      {hasMainPrice ? (
        <Grid item xs={8}>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'labels.price' })}
            {' '}
            <TooltipAnchor translationKey="hint.product.price" />
          </label>
          <NumberInput
            name="price.main"
            decimalScale={2}
            fixedDecimalScale
            placeholder={intl.formatMessage({ id: 'placeholder.sellingPrice' })}
            value={formik?.values?.price?.main || ''}
            validationErrors={getErrorsByName(formik, 'price.main')}
            onChange={onChange}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={4}>
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'labels.price' })}
              {' '}
              <TooltipAnchor translationKey="hint.product.price" />
            </label>
            <NumberInput
              name="price.money"
              decimalScale={2}
              fixedDecimalScale
              placeholder={intl.formatMessage({ id: 'placeholder.sellingPrice' })}
              onChange={onChange}
              value={formik?.values?.price?.money || ''}
              validationErrors={getErrorsByName(formik, 'price.money')}
            />
          </Grid>
          <Grid item xs={4}>
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'labels.pricePoints' })}
            </label>
            <NumberInput
              name="price.points"
              placeholder={intl.formatMessage({ id: 'placeholder.addPoints' })}
              value={formik?.values?.price?.points || ''}
              validationErrors={getErrorsByName(formik, 'price.points')}
              onChange={onChange}
            />
          </Grid>
        </>
      )}
      <Grid item xs={4}>
        {currencyOptions.length > 0 && renderCurrencySelector()}
      </Grid>

      {hasMainPrice ? (
        <Grid item xs={8}>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'labels.msrp' })}
            {' '}
            <TooltipAnchor
              translationKey="hint.product.retailPrice"
              values={{ br: renderBreak, b: renderBoldChunks }}
            />
          </label>
          <NumberInput
            name="msrp.main"
            decimalScale={2}
            fixedDecimalScale
            placeholder={intl.formatMessage({ id: 'placeholder.retailPrice' })}
            value={formik?.values?.msrp?.main || ''}
            validationErrors={getErrorsByName(formik, 'msrp.main')}
            onChange={onChange}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={4}>
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'labels.msrp' })}
              {' '}
              <TooltipAnchor
                translationKey="hint.product.retailPrice"
                values={{ br: renderBreak, b: renderBoldChunks }}
              />
            </label>
            <NumberInput
              name="msrp.money"
              decimalScale={2}
              fixedDecimalScale
              placeholder={intl.formatMessage({ id: 'placeholder.retailPrice' })}
              value={formik?.values?.msrp?.money || ''}
              validationErrors={getErrorsByName(formik, 'msrp.money')}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'labels.msrpPoints' })}
            </label>
            <NumberInput
              name="msrp.points"
              placeholder={intl.formatMessage({ id: 'placeholder.retailPrice' })}
              value={formik?.values?.msrp?.points || ''}
              validationErrors={getErrorsByName(formik, 'msrp.points')}
              onChange={onChange}
            />
          </Grid>
        </>
      )}
      <Grid item xs={4}>
        {currencyOptions.length > 0 && renderCurrencySelector()}
      </Grid>
    </Grid>
  );
};

ProductFormPrice.propTypes = {
  formik: object.isRequired,
  currencyOptions: array.isRequired,
  onChange: func.isRequired,
};

export default ProductFormPrice;

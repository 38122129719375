import { UserService } from 'Services/UserService';

export const ACTIONS = {
  SET_LOADING_ACCOUNT: 'userAccountReducer/setLoadingAccount',
  SET_LOADING_TRANSACTIONS: 'userAccountReducer/setLoadingTransactions',
  SET_ACCOUNT_INFO: 'userAccountReducer/setAccountInfo',
  SET_TRANSACTIONS: 'userAccountReducer/setTransactions',
  RESET_REDUCER: 'userAccountReducer/resetReducer',
};

export const getMyAccount = (userId) => async (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING_ACCOUNT, payload: true });

  try {
    const response = await UserService.getUserAccountInfo(userId);
    dispatch({ type: ACTIONS.SET_ACCOUNT_INFO, payload: response });
  } catch (e) {
    dispatch({ type: ACTIONS.SET_ACCOUNT_INFO, payload: {} });
  } finally {
    dispatch({ type: ACTIONS.SET_LOADING_ACCOUNT, payload: false });
  }
};

export const getMyTransactions = (userId, queryParams = {}) => async (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING_TRANSACTIONS, payload: true });

  try {
    const response = await UserService.getUserTransactions(userId, queryParams);
    dispatch({ type: ACTIONS.SET_TRANSACTIONS, payload: response });
  } catch (e) {
    dispatch({ type: ACTIONS.SET_TRANSACTIONS, payload: {} });
  } finally {
    dispatch({ type: ACTIONS.SET_LOADING_TRANSACTIONS, payload: false });
  }
};

export const resetStore = () => (dispatch) => {
  dispatch({ type: ACTIONS.RESET_REDUCER });
};

import { AppDispatch } from 'Types/redux.types';
import { CandidatesFilters } from 'Types/candidatesFilters.type';
import { CandidatesRequest } from 'Types/candidatesRequest.type';

export enum Actions {
  UpdateFilters = 'candidatesFilterReducer/UpdateFilters',
  UpdateReqProps = 'candidatesFilterReducer/UpdateReqProps',
  ResetFilters = 'candidatesFilterReducer/ResetFilters',
  ResetAll = 'candidatesFilterReducer/ResetAll',
}

const updateCandidatesReqProps = (reqProps: Partial<CandidatesRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.UpdateReqProps, payload: reqProps });

export const updateCandidatesOffset = (offset: number) => updateCandidatesReqProps({ start: offset });

export const updateCandidatesLimit = (limit: number) => updateCandidatesReqProps({ limit });

export const updateCandidatesOrder = (order: string) => updateCandidatesReqProps({ order, start: 0 });

export const updateCandidatesQuery = (query: string) => updateCandidatesReqProps({ query, start: 0 });

export const updateCandidatesFilters = (filters: Partial<CandidatesFilters>) => (dispatch: AppDispatch) =>
  dispatch({
    type: Actions.UpdateFilters,
    payload: { filters, reqProps: { start: 0 } },
  });

export const resetCandidatesFilters = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.ResetFilters });

export const resetCandidatesFiltersAndReqProps = () => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.ResetAll });

import { AppDispatch } from 'Types/redux.types';
import { ApplicationsFilters } from 'Types/applicationsFilters.type';
import { ApplicationsRequest } from 'Types/applicationsRequest.type';

export enum Actions {
  UpdateFilters = 'applicationsFiltersReducer/UpdateFilters',
  UpdateReqProps = 'applicationsFiltersReducer/UpdateReqProps',
  ResetFilters = 'applicationsFiltersReducer/ResetFilters',
  ResetFiltersAndReqProps = 'applicationsFiltersReducer/ResetFiltersAndReqProps',
  UpdateFilterId = 'applicationsFiltersReducer/UpdateFilterId',
}

export enum ApplicationsFiltersReducerAlias {
  CommonPage = 'Common',
  UserProfileTab = 'Profile',
  Job = 'Job',
}

export const getApplicationsFiltersActionName = (actionName: Actions, alias: ApplicationsFiltersReducerAlias): string =>
  `${actionName}_${alias}`;

const updateApplicationsReqProps = (alias: ApplicationsFiltersReducerAlias) => (reqProps: Partial<ApplicationsRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: getApplicationsFiltersActionName(Actions.UpdateReqProps, alias), payload: reqProps });

export const updateApplicationsOffset = (alias: ApplicationsFiltersReducerAlias) =>
  (offset: number) => updateApplicationsReqProps(alias)({ start: offset });

export const updateApplicationsLimit = (alias: ApplicationsFiltersReducerAlias) =>
  (limit: number) => updateApplicationsReqProps(alias)({ limit });

export const updateApplicationsOrder = (alias: ApplicationsFiltersReducerAlias) =>
  (order: string) => updateApplicationsReqProps(alias)({ order, start: 0 });

export const updateApplicationsQuery = (alias: ApplicationsFiltersReducerAlias) =>
  (query: string) => updateApplicationsReqProps(alias)({ query, start: 0 });

export const updateApplicationsFilters = (alias: ApplicationsFiltersReducerAlias) =>
  (filters: Partial<ApplicationsFilters>) => (dispatch: AppDispatch) =>
    dispatch({
      type: getApplicationsFiltersActionName(Actions.UpdateFilters, alias),
      payload: { filters, reqProps: { start: 0 } },
    });

export const resetApplicationsFilters = (alias: ApplicationsFiltersReducerAlias) =>
  (initialFilters = {}) => (dispatch: AppDispatch) =>
    dispatch({ type: getApplicationsFiltersActionName(Actions.ResetFilters, alias), payload: initialFilters });

export const resetApplicationsFiltersAndReqProps = (alias: ApplicationsFiltersReducerAlias) =>
  (initialFilters = {}) => (dispatch: AppDispatch) =>
    dispatch({
      type: getApplicationsFiltersActionName(Actions.ResetFiltersAndReqProps, alias),
      payload: initialFilters,
    });

export const updateApplicationsFilterId = (alias: ApplicationsFiltersReducerAlias) =>
  (actor: string) => (dispatch: AppDispatch) =>
    dispatch({ type: getApplicationsFiltersActionName(Actions.UpdateFilterId, alias), payload: actor });

import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import * as styles from './index.module.scss';

interface UploadIconButtonProps {
  item: {
    icon: string,
    accept: string[],
    label: string,
  };
  handleUploadFiles: () => any,
  multiple: boolean,
}

const UploadIconButton: FC<UploadIconButtonProps> = ({
  item: { icon, accept, label },
  handleUploadFiles,
  multiple,
}) => {
  const intl = useIntl();
  const acceptedHeaders = accept.reduce((prevVal, currVal) => ({ ...prevVal, [currVal]: [] }), {});

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadFiles,
    accept: acceptedHeaders,
    multiple,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Tooltip title={intl.formatMessage({ id: label })} arrow>
        <IconButton size="large">
          <span
            className={classnames('material-symbols-rounded', styles.icon)}
            aria-hidden="true"
          >
            {icon}
          </span>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default UploadIconButton;

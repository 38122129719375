import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyCFKcW2VM8UGyWoSlkGT2Ft9xPvc4Uod5A', // Your Api key will be here - this is the prod
    authDomain: 'backstage-prod.firebaseapp.com', // Your auth domain
    databaseURL: 'https://backstage-prod.firebaseio.com', // data base url
    projectId: 'backstage-prod', // project id
    storageBucket: 'backstage-prod.appspot.com', // storage bucket
    messagingSenderId: '566928728964', // messaging sender id
    appId: '1:566928728964:web:f8f3af19a8a2c3c4cd73d1',
    measurementId: 'G-QXEERTM1J0',
  },
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: 'R4HH3UVTXU',
  algolia_api_key: 'e577321c647215eaa4691513316d1597',
};

export default config;

import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoader from 'Components/CustomLoader';
import EmptyState from 'Components/EmptyState';
import SelectableBountyCard from 'Components/SelectableBountyCard';
import { firebaseGetPromotionalOffersRef } from 'Services/FirebaseService';
import { setSelectedPromotions } from 'Store/createBounty/actions';
import { selectedPromotionsSelector } from 'Store/createBounty/selectors';
import { convertObjToArray, getProductBackgroundImage } from 'Utils/helpers';
import EmptyImage from 'Assets/img/emptyContent/es_incoming.png';
import { useIntl } from 'react-intl';
import { ThunkDispatchType } from 'Types/redux.types';
import { Promotion } from 'Types/promotion.interface';
import { Product } from 'Types/product.interface';
import { Bounty } from 'Types/bounty.interface';

const AttachablePromotionsContainer = () => {
  const selectedPromotions: Promotion[] = useSelector(selectedPromotionsSelector) || [];
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const handleSelectPromotion = (item: Promotion|Bounty) => {
    const index = selectedPromotions?.findIndex((selectedProm) => selectedProm.id === item.id);

    if (index > -1) {
      const newData = selectedPromotions?.filter(((selectedProm) => selectedProm.id !== item.id));
      return dispatch(setSelectedPromotions(newData));
    }

    return dispatch(setSelectedPromotions([...selectedPromotions, item]));
  };

  const fetchPromotions = () => {
    setIsLoading(true);
    firebaseGetPromotionalOffersRef()
      .once('value', (dataSnapshot) => {
        setPromotions(convertObjToArray(dataSnapshot.val()));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  const getProductImage = (promotion: Promotion) => {
    const productDetails: Product|undefined = promotion?.products ? Object.values(promotion.products)[0]?.product : undefined;
    return getProductBackgroundImage(productDetails);
  };
  const isActive = (prom: Promotion) => !!selectedPromotions?.find((item) => item.id === prom.id);

  return (
    <CustomLoader isLoading={isLoading}>
      {promotions?.length ? (
        <Grid container spacing={3}>
          {
            promotions.map((item) => (
              <Grid item xs={6} key={`product-${item.id}`}>
                <SelectableBountyCard<Promotion>
                  description={item?.description}
                  backgroundImage={getProductImage(item)}
                  bounty={item}
                  handleSelect={handleSelectPromotion}
                  isActive={isActive(item)}
                />
              </Grid>
            ))
          }
        </Grid>
      ) : (
        <EmptyState
          title={intl.formatMessage({ id: 'label.noGoodsOnMarket' })}
          subTitle={intl.formatMessage({ id: 'emptyContent.stayTuned' })}
          emptyImage={EmptyImage}
        />
      )}
    </CustomLoader>
  );
};

export default AttachablePromotionsContainer;

import { getTheDate } from 'Utils/helpers';
import { DeliveryFeeSource, DeliveryType, ProductKind, RewardType, WeightUnit } from 'Constants/enums';
import { asMap } from 'Utils/points';
import { FormattedMessage } from 'react-intl';
import { campaignsProductSchema, productSchema, ticketSchema, tokenSchema } from 'Utils/validation/product';
import { getMainPointCurrency } from 'Utils/currency';
import { getCurrentUseCase } from 'Utils/useCases';
import { isEmpty, isNil } from 'lodash';
import { extractNumberFromNumericFormat } from 'Utils/numbers';
import {
  FLWR_2_VARIANT_BY_QUANTITY,
  FLWR_4_VARIANT_BY_QUANTITY,
  FLWR_VARIANT_BY_QUANTITY
} from "Constants/productFlwr";

export function getMainImageForProduct(attachments, oldBounty) {
  if (oldBounty && oldBounty.product && oldBounty.product.imageUrl) {
    return oldBounty.product.imageUrl;
  }

  if (attachments && attachments.attachments && Object.keys(attachments.attachments).length) {
    const firstAttachKey = Object.keys(attachments.attachments)[0];
    return attachments.attachments[firstAttachKey].url;
  }

  return null;
}

export function formatTicketAvailability(product) {
  const firstSku = product?.variants
    ? Object.values(product?.variants).find((sku) => sku.onlineProductInfo) || {}
    : {};
  const { availableFrom, availableTo } = firstSku?.onlineProductInfo || {};
  const format = 'LL';

  if (availableFrom && availableTo) {
    return `Ticket is valid between: ${getTheDate(availableFrom, format)} – ${getTheDate(availableTo, format)}`;
  }

  if (availableFrom) {
    return `Ticket is valid starting from ${getTheDate(availableFrom, format)}`;
  }

  if (availableTo) {
    return `Ticket is valid until ${getTheDate(availableTo, format)}`;
  }

  return '-';
}

export function prepareProduct(userData, kind) {
  return {
    merchant: userData.company || null,
    merchantId: userData.company.id || null,
    kind: kind || null,
    published: false,
    seoTitle: null,
    seoDescription: null,
    deliveryType: null,
  };
}

export function prepareTags(tags) {
  if (!tags) {
    return null;
  }

  const tagKey = tags.split(' ').filter((item) => item).join('_');

  return {
    tags: {
      [tagKey]: {
        id: tagKey,
        displayName: tags,
      },
    },
  };
}

export function prepareVariants(props) {
  const {
    oldVariants, extraDeliveryFee, kind, merchantSku, quantity, price, msrp, currencyType, currency, onlineProductInfo, customCurrency,
  } = props;

  if (!merchantSku) {
    return oldVariants || null;
  }

  const newVariants = {
    ...(oldVariants || {}),
    [merchantSku]: {
      ...(oldVariants?.[merchantSku] ? oldVariants[merchantSku] : {}),
      price: preparePrice(price, currencyType, currency, customCurrency),
      msrp: preparePrice(msrp, currencyType, currency, customCurrency),
      sku: merchantSku,
      quantity: quantity ? +quantity : null,
      extraDeliveryFee,
    },
  };

  if (ProductKind.Token === kind && onlineProductInfo) {
    newVariants[merchantSku].onlineProductInfo = {
      ...onlineProductInfo,
      tokensQty: onlineProductInfo?.tokensQty ? +onlineProductInfo.tokensQty : null,
    };
  }

  if (ProductKind.Ticket === kind && onlineProductInfo) {
    newVariants[merchantSku].onlineProductInfo = {
      ...onlineProductInfo,
      useQty: onlineProductInfo?.useQty ? +onlineProductInfo.useQty : null,
    };
  }

  return newVariants;
}

function preparePrice(price, currencyType, currency, customCurrency) {
  const { main, money, points } = price || {};

  const formattedPoints = !isNil(points) ? extractNumberFromNumericFormat(points?.toString()) : '';
  const formattedMoney = !isNil(money) ? extractNumberFromNumericFormat(money?.toString()) : '';

  if (!main && !money && !points) {
    return null;
  }

  if (currencyType === RewardType.Either) {
    return {
      mainPrice: {
        [RewardType.Money.toLowerCase()]: {
          amount: formattedMoney.toString(),
          currency,
        },
      },
      altPrice1: {
        [RewardType.Points.toLowerCase()]: asMap({
          amount: formattedPoints,
          currency: customCurrency || getMainPointCurrency(),
        }),
      },
    };
  }

  if (currencyType === RewardType.Both) {
    return {
      mainPrice: {
        [RewardType.Money.toLowerCase()]: {
          amount: formattedMoney.toString(),
          currency,
        },
        [RewardType.Points.toLowerCase()]: asMap({
          amount: formattedPoints,
          currency: customCurrency || getMainPointCurrency(),
        }),
      },
    };
  }

  return {
    mainPrice: {
      [currencyType.toLowerCase()]: currencyType === RewardType.Points
        ? asMap({ amount: +formattedPoints, currency: customCurrency || getMainPointCurrency() })
        : ({
          amount: !isNil(main) ? extractNumberFromNumericFormat(main.toString()).toString() : '',
          currency,
        }),
    },
  };
}

export const getProductImageAsAttachment = (product) => {
  if (product.imageUrl) {
    return {
      url: product.imageUrl,
      type: 'IMAGE',
    };
  }

  return null;
};

export const TOP_PRODUCT_KEY = '-';

const PRODUCT_SPECIFIC_INFO = {
  sku: null,
  optionKey1: null,
  optionKey2: null,
  optionKey3: null,
  manufacturer: null,
  model: null,
  weight: null,
  weightUnit: null,
  inventoryTracker: null,
  quantity: null,
  inventoryPolicy: null,
  fulfillmentService: null,
  price: null,
  msrp: null,
  costPerItem: null,
  barcode: null,
  imageUrl: null,
  requiresShipping: null,
  taxable: null,
  taxCode: null,
  onlineProductInfo: null,
};

function hasVariants(variants) {
  return variants && Object.keys(variants).length;
}

function getVariant(variants, sku) {
  if (!sku || !hasVariants(variants)) {
    return null;
  }

  return variants[sku];
}

function inheritFrom(productSpecificInfo) {
  if (!productSpecificInfo) {
    return null;
  }

  const props = {};

  Object.keys(PRODUCT_SPECIFIC_INFO).forEach((key) => {
    const value = productSpecificInfo[key];

    if (value) {
      props[key] = value;
    }
  });

  return props;
}

export function getTopLevelVariant(product) {
  if (!product) {
    return null;
  }

  const { merchantSku, variants } = product;
  const psi = {};
  const psi0 = getVariant(variants, TOP_PRODUCT_KEY);
  const psi1 = getVariant(variants, merchantSku);

  Object.assign(psi, inheritFrom(psi0));
  Object.assign(psi, inheritFrom(psi1));

  if (!psi.sku) {
    psi.sku = merchantSku;
  }

  return psi;
}

export function getEffectiveVariant(product, sku) {
  const { merchantSku, variants } = product;
  const tlv = getTopLevelVariant(product);

  if (!sku || merchantSku === sku) {
    return tlv;
  }

  const psi = getVariant(variants, sku);

  if (psi == null) {
    return null;
  }

  Object.assign(tlv, inheritFrom(psi));
  tlv.sku = sku;

  return tlv;
}

export const getProductPrices = (product) => {
  const productInfo = getTopLevelVariant(product);

  return {
    msrp: productInfo && productInfo.msrp ? productInfo.msrp : null,
    price: productInfo && productInfo.price ? productInfo.price : null,
  };
};

export const getCurrencyType = (price) => {
  if (!isEmpty(price?.mainPrice?.money) && !isEmpty(price?.mainPrice?.points)) {
    return RewardType.Both;
  }

  if (!isEmpty(price?.mainPrice?.money) && !isEmpty(price?.altPrice1?.points)) {
    return RewardType.Either;
  }

  return !isEmpty(price?.mainPrice?.points) ? RewardType.Points : RewardType.Money;
};

export const initialPricing = (price) => {
  const customCurrencyMainPrice = price?.mainPrice?.points ? Object.keys(price?.mainPrice?.points)[0] : null;
  const customCurrencyAltPrice1 = price?.altPrice1?.points ? Object.keys(price?.altPrice1?.points)[0] : null;
  const customCurrencyAltPrice2 = price?.altPrice2?.points ? Object.keys(price?.altPrice2?.points)[0] : null;

  return {
    price: {
      main: price?.mainPrice?.money?.amount,
      money: price?.mainPrice?.money?.amount || price?.altPrice1?.money?.amount || price?.altPrice2?.money?.amount,
      points: price?.mainPrice?.points?.[customCurrencyMainPrice]?.amount
        || price?.altPrice1?.points?.[customCurrencyAltPrice1]?.amount
        || price?.altPrice2?.points?.[customCurrencyAltPrice2]?.amount,
    },
    customCurrency: customCurrencyMainPrice || customCurrencyAltPrice1 || customCurrencyAltPrice2,
    currencyType: getCurrencyType(price),
  };
};

export const getInitialTags = (bounty, productKind) => {
  const { tags } = bounty || {};
  const tag = (tags?.tags && Object.values(tags.tags)[0]) || {};

  return tag?.displayName || productKind?.toLowerCase() || '';
};

export const getProductValidationSchema = (productKind, isToken) => {
  if (getCurrentUseCase().isCampaigns) {
    return campaignsProductSchema;
  }

  if (productKind === ProductKind.Ticket) {
    return ticketSchema;
  }

  if (isToken) {
    return tokenSchema;
  }

  return productSchema;
};

export const getProductModalTitle = (isEditMode, productKind, isToken) => {
  const modeLabel = <FormattedMessage id={isEditMode ? 'labels.edit' : 'labels.create'} />;
  let mainLabel = 'bounty.createProduct';

  if (productKind === ProductKind.Ticket) {
    mainLabel = 'bounty.createTicket';
  }

  if (isToken) {
    mainLabel = 'bounty.createTokenPack';
  }

  return <FormattedMessage id={mainLabel} values={{ type: modeLabel }} />;
};

export const formatChemicalValue = ({ element, summary }) => {
  if (!element.value) return '';
  const value = summary && element.uom === '%' ? Math.round(element.value) : element.value;
  return `${value}${element.uom}`;
};

export const formatChemicalComposition = (product) => {
  if (!product?.chemicalComposition) {
    return '';
  }

  return product.chemicalComposition
    .filter((element) => {
      const hasChemical = isNil(element.summary) || element.summary;
      return element.value > 0 && element.name && hasChemical;
    })
    .map((element) => `${element.name} ${formatChemicalValue({ element, summary: true })}`)
    .join(', ');
};

export const getProductDeliveryFeeSource = (bounty) => {
  const currentSku = getTopLevelVariant(bounty?.product);
  let deliveryFeeSource = DeliveryFeeSource.Merchant;

  if (currentSku?.extraDeliveryFee) {
    deliveryFeeSource = DeliveryFeeSource.Product;
  }

  if (bounty?.product?.deliveryType === DeliveryType.NoDelivery) {
    deliveryFeeSource = DeliveryFeeSource.NoShippingRequired;
  }

  return deliveryFeeSource;
};

export const getFlwrVariantByQuantity = (quantity, sortUnit) => {
  if (!sortUnit) {
    return null;
  }

  if (sortUnit === WeightUnit.FLWR) {
    return FLWR_VARIANT_BY_QUANTITY[quantity];
  }

  if (sortUnit === WeightUnit.FLWR_2) {
    return FLWR_2_VARIANT_BY_QUANTITY[quantity];
  }

  if (sortUnit === WeightUnit.FLWR_4) {
    return FLWR_4_VARIANT_BY_QUANTITY[quantity];
  }

  return null;
};

export const isFlwr = (sortUnit) => (
  sortUnit && [WeightUnit.FLWR, WeightUnit.FLWR_2, WeightUnit.FLWR_4].includes(sortUnit)
);

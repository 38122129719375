import { AnyAction } from '@reduxjs/toolkit';
import { Address } from 'Types/address.interface';
import { Bounty } from 'Types/bounty.interface';
import { Actions } from 'Store/onboarding/actions';

interface State {
  products: Bounty[];
  selectedBounty?: Bounty;
  address?: Address;
  age: number;
  terms: boolean;
  isLoading: boolean;
}

const initialState: State = {
  products: [],
  selectedBounty: undefined,
  address: undefined,
  age: 0,
  terms: false,
  isLoading: false,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.IsLoading:
      return {
        ...state,
        isLoading: payload,
      };
    case Actions.SetProducts:
      return {
        ...state,
        products: payload,
      };
    case Actions.SelectProduct:
      return {
        ...state,
        selectedBounty: payload,
      };
    case Actions.SetAge:
      return {
        ...state,
        age: payload,
      };
    case Actions.SetTerms:
      return {
        ...state,
        terms: payload,
      };
    case Actions.SetAddress:
      return {
        ...state,
        address: payload,
      };
    case Actions.Reset:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

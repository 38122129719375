import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyDUjNtR7MWfscJLYMoRot5vgteXtAeVvEM',
    authDomain: 'vip-app-6a3d4.firebaseapp.com',
    databaseURL: 'https://vip-app-6a3d4-default-rtdb.firebaseio.com',
    projectId: 'vip-app-6a3d4',
    storageBucket: 'vip-app-6a3d4.appspot.com',
    messagingSenderId: '826423990865',
    appId: '1:826423990865:web:dc16b19a12f77c014ab670',
    measurementId: 'G-8PSL9GRS3B',
  },
  API_BASE_URL: 'https://edge-server-a465iqwona-uc.a.run.app',
};

export default config;

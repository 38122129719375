import { Survey, SurveyAnswer, SurveyOption, SurveyResult as SurveyResultInterface } from 'Types/survey.interface';
import { firebaseGetTimestamp } from 'Services/FirebaseService';
import { BountyType, ProgressState } from 'Constants/enums';
import { isRewardEmpty, rewardToString } from 'Utils/models/Reward';
import { DEFAULT_SURVEY_INITIAL_OPTIONS } from 'Constants/common';
import * as StringUtils from 'Utils/string';

const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const QUESTION_SEP = '\n--';
const STAR = '★';
const MAX_STAR_INDEX = 5;
const LINE_PAT = /(.*) \(=([0-9]+)?(, ?)?(reward:<(.*)>)?\)$/;
export const BULLET_SEP = ':';
export const BULLET_SEPS = '.:>-';
export const REWARD_PREFIX = 'reward:';
export const DEFAULT_WRONG_ANSWER_WEIGHT = 0;
export const DEFAULT_CORRECT_ANSWER_WEIGHT = 100;
export const TIMEOUT_OPTION = 'T: Timeout';

const isEmpty = (value: unknown) => value === null || value === undefined;

export class Option {
  static toString(option: SurveyOption) {
    const {
      code, text, weight, reward,
    } = option;
    let buf = '';

    if (isEmpty(code)) {
      buf = text;
    } else if (!text) {
      buf = code;
    } else {
      buf = `${code}${BULLET_SEP} ${text}`;
    }

    if (!isEmpty(weight) || !isEmpty(reward)) {
      buf = `${buf} (=`;

      if (!isEmpty(weight)) {
        buf = `${buf}${weight}`;
      }

      if (!isEmpty(reward) && reward && !isRewardEmpty(reward)) {
        if (!isEmpty(weight)) {
          buf = `${buf}, `;
        }

        buf = `${buf}${REWARD_PREFIX}<${rewardToString(reward)}>`;
      }
      buf = `${buf})`;
    }

    return buf;
  }

  static valueOf(string: string): SurveyOption {
    const answer: SurveyOption = { code: '', text: '' };
    const str = string.trim();
    let txt = null;

    if (str) {
      if (str.length === 1) {
        answer.code = str;
      } else {
        const sep = str.charAt(1);

        if (BULLET_SEPS.indexOf(sep) >= 0) {
          answer.code = str.substring(0, 1);
          txt = str.substring(2).trim();
        } else {
          txt = str;
        }
      }
    }

    if (txt !== null) {
      const m = txt.match(LINE_PAT);

      if (m) {
        answer.text = m[1] || '';
        const weightStr = m[2];
        const rewardStr = m[5];

        if (!StringUtils.isEmptyString(weightStr)) {
          answer.weight = +weightStr;
        }

        if (!StringUtils.isEmptyString(rewardStr)) {
          answer.reward = rewardStr;
        }
      } else {
        answer.text = txt || '';
      }
    }

    return answer;
  }
}

export class SurveyResult {
  static getCodeByIndex({ code, index }: { code?: string; index: number }): string {
    const nextIndex = code ? alphabet.indexOf(code.toLowerCase()) + 1 : index;
    return alphabet.charAt(nextIndex).toUpperCase();
  }

  static toString(survey: Survey, type?: string): string {
    const { question, options } = survey;
    const lines = [];

    if (question) {
      lines.push(type && type !== BountyType.Survey ? `${type}${BULLET_SEP} ${question}` : question);
    }

    lines.push(QUESTION_SEP);

    options.forEach((option) => (
      lines.push(Option.toString(option))
    ));

    return Object.values(lines).join('\n');
  }
}

export const valueOf = (description: { text: string }, type: string) => {
  const { text } = description;

  if (!text) {
    return null;
  }

  return parse(text, type);
};

export const parse = (str: string, type: string): { question: string; options: SurveyOption[]; type: string } => {
  const indexOfQuestionSep = str.indexOf(QUESTION_SEP);
  let question = null;
  const options: SurveyOption[] = [];
  let newType = type;

  if (type === BountyType.Survey) {
    newType = BountyType.Poll;
  }

  if (indexOfQuestionSep < 0) {
    question = str.trim();
  } else {
    question = str.substring(0, indexOfQuestionSep).trim();
    const optionsString = str.substring(indexOfQuestionSep + QUESTION_SEP.length).trim();
    const rawOptions = optionsString.trim().split('\n');

    rawOptions.forEach((option) => (
      options.push(Option.valueOf(option))
    ));
  }

  if (question) {
    if (question.startsWith(`${type}${BULLET_SEP}`)) {
      question = question.substring(question.indexOf(BULLET_SEP) + 1).trim();
    }
  }

  return {
    question,
    options,
    type: newType,
  };
};

export const getTotalCount = (answers: Record<string, SurveyAnswer>) => {
  if (answers) {
    return Object
      .values(answers)
      .reduce((acc, { count }) => (count ? acc + count : acc), 0) || 0;
  }

  return 0;
};

export const getAnswerCount = (answers: Record<string, SurveyAnswer>, code: string) => {
  const answer = answers[code] || {};
  return answer.count || 0;
};

export const getCompletedSurveyProps = () => {
  const ts = firebaseGetTimestamp();

  return {
    status: ProgressState.Completed,
    progress: 1,
    workStartedAt: ts,
    workCompletedAt: ts,
  };
};

export const setSurveyDescription = (surveyOptions: Record<string, SurveyOption>, description: string, bountyType: string) => {
  const survey: Survey = {
    type: bountyType,
    question: description,
    options: Object.values(surveyOptions),
  };

  return { text: SurveyResult.toString(survey, bountyType) };
};

export const setScoreDescription = (description: string) => {
  const survey: Survey = {
    type: BountyType.Score,
    question: description,
    options: [],
  };

  for (let i = MAX_STAR_INDEX; i > 0; i--) {
    let optionStars = '';

    for (let j = 0; j < i; j++) {
      optionStars += STAR;
    }

    survey.options.push({
      code: SurveyResult.getCodeByIndex({ index: MAX_STAR_INDEX - i }),
      text: optionStars,
      weight: i,
    });
  }

  return { text: SurveyResult.toString(survey, BountyType.Score) };
};

export const isCorrectChoice = (option: SurveyOption, correctWeight: number): boolean => !!(option.weight && +option.weight === correctWeight);

export const isCorrectAnswer = (answer: SurveyAnswer): boolean => !!(answer && answer.maxPoints && answer.maxPoints === answer.answerPoints);

export const getPercentage = (totalCount: number, surveyAnswer: SurveyAnswer): number => {
  if (totalCount === 0 || !surveyAnswer || (surveyAnswer && !surveyAnswer.count)) {
    return 0;
  }

  return surveyAnswer.count ? Math.round((surveyAnswer.count * 100.0) / totalCount) : 0;
};

export const getLeadingSurveyAnswer = (surveyResult: SurveyResultInterface): SurveyAnswer|null => {
  let maxCount = -1;
  let maxAnswer = null;

  if (surveyResult?.answers) {
    Object.values(surveyResult.answers).forEach((answer) => {
      if (answer.count && maxCount < answer.count) {
        maxCount = answer.count;
        maxAnswer = answer;
      }
    });
  }

  return maxAnswer;
};

export const getAverageScore = (surveyResults: Record<string, SurveyAnswer>) => {
  const { answers } = surveyResults;
  let totalScore = 0;
  let totalCnt = 0;

  if (answers) {
    Object.values(answers).forEach(({ score, count, weight }) => {
      if (weight) {
        totalScore += score;
        totalCnt += count;
      }
    });
  }

  if (totalCnt) {
    const average = totalScore / totalCnt;
    return average % 1 === 0 ? average : average.toFixed(1);
  }

  return 0;
};

export const isCheckable = (type: BountyType): boolean => type === BountyType.MCQ;

export const getDefaultWeight = (type?: BountyType) => (type === BountyType.MCQ
  ? { weight: DEFAULT_WRONG_ANSWER_WEIGHT }
  : {});

export const getDefaultChoices = (): Record<string, SurveyAnswer> => {
  const options: Record<string, SurveyAnswer> = {};

  for (let i = 0; i < DEFAULT_SURVEY_INITIAL_OPTIONS; i++) {
    const code = SurveyResult.getCodeByIndex({ index: i });
    options[code] = { code, ...getDefaultWeight() };
  }

  return options;
};

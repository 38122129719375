import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';
import Switch from '@mui/material/Switch';
import { SwitchOwnProps } from '@mui/base';

import * as styles from './index.module.scss';

interface SwitchStyledProps extends SwitchOwnProps {
  className?: string;
  labelActive?: string | ReactNode;
  labelInactive?: string | ReactNode;
  hasLabels?: boolean;
  checked?: boolean;
}

const SwitchStyled: FC<SwitchStyledProps> = (
  {
    className = '',
    hasLabels = false,
    labelInactive = <FormattedMessage id="label.no" />,
    labelActive = <FormattedMessage id="label.yes" />,
    checked = false,
    ...otherProps
  },
) => (
  <div className={cs(styles.root, { [className]: !!className })}>
    {hasLabels && labelInactive && (<span className={cs(styles.label, { [styles.active]: !checked })}>{labelInactive}</span>)}
    <Switch checked={checked} {...otherProps} />
    {hasLabels && labelActive && (<span className={cs(styles.label, { [styles.active]: checked })}>{labelActive}</span>)}
  </div>
);

export default SwitchStyled;

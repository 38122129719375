import { cloneDeep } from 'lodash';
import { getOutboundResponseRefs, SENT_ITEMS } from 'Services/response/CommonService';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getOutboundPriorityForResponse } from 'Utils/keyUtils';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { User } from 'Types/user.interface';

export function getRefs(bountyResponse: BountyResponse) {
  const mySelf: Partial<User> | null = firebaseGetCurrentUser();

  if (!bountyResponse) {
    console.log('missing response'); // eslint-disable-line
  } else if (!bountyResponse.id) {
    console.log('missing response id'); // eslint-disable-line
  }

  return getOutboundResponseRefs(bountyResponse, mySelf);
}

export function saveResponse(bounty: Bounty, bountyResponse: BountyResponse) {
  const bountyClone = cloneDeep(bounty);

  if (!bountyResponse) {
    console.log('no response to save'); // eslint-disable-line
    return;
  }

  if (bountyClone && bountyClone.outboundResponses) {
    bountyClone.outboundResponses[bountyResponse.id] = bountyResponse;
  }

  // ToDo - response ref Typescript
  const responseRefs: any = getRefs(bountyResponse);
  const priority = getOutboundPriorityForResponse(bountyResponse);

  Object.keys(responseRefs).forEach((refKey) => {
    if (refKey === SENT_ITEMS) {
      const card = {
        response: bountyResponse,
      };
      const ref = responseRefs[refKey].parent;
      return ref.setWithPriority(card, priority);
    }
    return responseRefs[refKey].setWithPriority(bountyResponse, priority);
  });
}

import { onValue, query } from 'firebase/database';
import { AppDispatch } from 'Types/redux.types';
import { firebaseGetUserRef } from 'Services/FirebaseService';

export enum Actions {
  SetUser = 'userManagement/SetUser',
  ResetUserManagement = 'userManagement/Reset',
}

export const fetchUserDetails = (userId: string) => async (dispatch: AppDispatch) => {
  const dbQuery = query(firebaseGetUserRef(userId));

  onValue(
    dbQuery,
    (dataSnapshot) => {
      const result = dataSnapshot.val();

      if (result) {
        dispatch({ type: Actions.SetUser, payload: { ...result, fetchedAt: new Date() } });
      }
    },
    () => {},
    { onlyOnce: true },
  );
};

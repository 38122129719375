import { SEE_BUY_SELL, SEE_FAVORITES, SEE_MARKET } from 'Constants/op';
import { BountyType, StreamCodes, StreamListKind, StreamListType } from 'Constants/enums';
import { StreamListData } from 'Types/streamListData.interface';

const NONE_DATA: StreamListData = {
  name: StreamListType.None,
  id: StreamListType.None,
  code: null,
  bountyType: null,
  kind: null,
};

const STREAM_DATA: StreamListData = {
  name: StreamListType.Stream,
  code: null,
  kind: StreamListKind.Inbox,
  bountyType: null,
  id: StreamListType.Stream,
};

const EDGE_COACHINGS_DATA: StreamListData = {
  name: StreamListType.EdgeCoachings,
  id: StreamListType.EdgeCoachings,
  code: StreamCodes.Regular,
  bountyType: BountyType.TMOB_Coaching,
  kind: StreamListKind.Bucket,
  op: null,
};

const EDGE_IN_FOCUS_DATA: StreamListData = {
  name: StreamListType.EdgeInFocus,
  id: StreamListType.EdgeInFocus,
  code: StreamCodes.InFocus,
  bountyType: BountyType.TMOB_Coaching,
  kind: StreamListKind.Bucket,
  op: null,
};
const EDGE_CHALLENGES_DATA: StreamListData = {
  name: StreamListType.EdgeChallenges,
  id: StreamListType.EdgeChallenges,
  code: StreamCodes.Regular,
  bountyType: BountyType.TMOB_Challenge,
  kind: StreamListKind.FilterByBountyType,
  op: null,
};
const BUY_SELL_DATA: StreamListData = {
  name: StreamListType.BuySell,
  id: StreamListType.BuySell,
  code: StreamCodes.Regular,
  bountyType: BountyType.Classified,
  kind: StreamListKind.FilterByBountyType,
  op: SEE_BUY_SELL,
};

const CUSTOM_DATA: StreamListData = {
  name: StreamListType.Custom,
  id: StreamListType.Custom,
  code: StreamCodes.Custom,
  bountyType: null,
  kind: null,
  op: null,
};

const FAVORITES_DATA: StreamListData = {
  name: StreamListType.Favorites,
  id: StreamListType.Favorites,
  code: null,
  bountyType: null,
  kind: StreamListKind.Refs,
  op: SEE_FAVORITES,
};

const AUTOSUGGESTIONS_DATA: StreamListData = {
  name: StreamListType.Autosuggestions,
  id: StreamListType.Autosuggestions,
  code: StreamCodes.Custom,
  bountyType: BountyType.Autosuggestion,
  kind: StreamListKind.Special,
  userSpecial: null,
  companySpecial: 'autosuggestions',
};

const MARKET_DATA: StreamListData = {
  name: StreamListType.Market,
  id: StreamListType.Market,
  code: StreamCodes.Custom,
  bountyType: BountyType.Product,
  kind: StreamListKind.RestCall,
  op: SEE_MARKET,
};

const WEBVIEW_DATA: StreamListData = {
  name: StreamListType.WebView,
  id: StreamListType.WebView,
  code: null,
  bountyType: null,
  kind: StreamListKind.WebView,
  url: 'https://www.youkno.ai',
};

export const listTypeData: Partial<Record<StreamListType, StreamListData>> = {
  [StreamListType.None]: NONE_DATA,
  [StreamListType.Stream]: STREAM_DATA,
  [StreamListType.EdgeCoachings]: EDGE_COACHINGS_DATA,
  [StreamListType.EdgeInFocus]: EDGE_IN_FOCUS_DATA,
  [StreamListType.EdgeChallenges]: EDGE_CHALLENGES_DATA,
  [StreamListType.BuySell]: BUY_SELL_DATA,
  [StreamListType.Market]: MARKET_DATA,
  [StreamListType.Custom]: CUSTOM_DATA,
  [StreamListType.Favorites]: FAVORITES_DATA,
  [StreamListType.Autosuggestions]: AUTOSUGGESTIONS_DATA,
  [StreamListType.WebView]: WEBVIEW_DATA,
};

import { replaceLangCode } from '../utils/translations';

const useInsertLangCodeToLinks = (
  hash: {
    [x: string]: string;
  },
  locale: string | undefined,
) => {
  const translatedHash: Record<string, string> = {};

  Object.keys(hash).forEach((key) => {
    translatedHash[key] = replaceLangCode(hash[key], locale);
  });

  return translatedHash;
};

export default useInsertLangCodeToLinks;

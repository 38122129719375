import { firebaseGetSentRef } from 'Services/FirebaseService';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { User } from 'Types/user.interface';

export const INBOUND_GLOBAL = 'INBOUND_GLOBAL';
export const SENT_ITEMS = 'SENT_ITEMS';

export const getOutboundResponseRefs = (bountyResponse: BountyResponse, user: Partial<User> | null) => {
  const ref2 = firebaseGetSentRef(user?.uid).child(bountyResponse.id).child('response');
  return {
    [SENT_ITEMS]: ref2,
  };
};

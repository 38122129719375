// Others
import { FormattedMessage } from 'react-intl';
import { UserService } from 'Services/UserService';
import { setAttachments } from 'Store/attachments/actions';
import { notificationToast } from 'Utils/notificationToaster';
import { formatUserData } from 'Utils/user';
import { getRandomInt } from 'Utils/helpers';
import { AddressType, AttachmentMeaning, EnvironmentMode, SalaryType } from 'Constants/enums';
import { CompanyPrivateData, Fee } from 'Types/companyPrivateData.interface';
import { CompanyUserPrivateInfo, Fees } from 'Types/companyUserPrivateInfo.interface';
import { CompanyUserAddress } from 'Types/companyUserAddress.interface';
import { Attachment } from 'Types/attachment.interface';
import { FormattedCompanyUserData } from 'Types/formattedCompanyUserData.interface';
import { OptimizedUserData } from 'Types/optimizedUserData.interface';
import { UserData } from 'Types/userData.interface';
import { CompanyUpdateReq } from 'Types/companyUpdateReq.interface';
import { UserUpdateReq } from 'Types/userUpdateReq.interface';
import { UserBasicInfo } from 'Types/userBasicInfo.interface';
import { SystemInfo } from 'Types/systemInfo.interface';
import { AllowedRole } from 'Types/allowedRole.interface';
import { AppDispatch } from 'Types/redux.types';

export enum Actions {
  Loading = 'userReducer/Loading',
  RolesLoading = 'userReducer/RolesLoading',
  GetProfile = 'userReducer/GetUser',
  GetPrivateData = 'userReducer/GetCompanyPrivateData',
  GetSource = 'userReducer/GetSource',
  GetRoles = 'userReducer/GetRoles',
  UpdateUser = 'userReducer/UpdateUser',
  UpdateEnvMode = 'userReducer/UpdateEnvMode',
  UpdatePIN = 'userReducer/UpdatePIN',
  UpdateInviteCode = 'userReducer/UpdateInviteCode',
  UpdateBlacklistStatus = 'userReducer/UpdateBlacklistStatus',
  Reset = 'userReducer/Reset',
}

const formatAddresses = (addresses: CompanyUserAddress[]): CompanyUserAddress => {
  const address = addresses ? addresses[0] : null;

  return {
    city: address?.city || '',
    countryCode: address?.countryCode || '',
    line1: address?.line1 || '',
    postalCode: address?.postalCode || '',
    phoneNumber: address?.phoneNumber || '',
    state: address?.state || '',
    id: address?.id || getRandomInt(0, 1000).toString(), // TODO: do we really need to generate id on the client?
    type: address?.type || AddressType.Tax,
  };
};

const formatCompanyPrivateData = (response: CompanyUserPrivateInfo): CompanyPrivateData => {
  const { fees, addresses, currencies, industryIds, usersCount, contractType } = response;

  const formatFees = (fees: Fees): Fee => {
    if (fees) {
      const { commissionPercentage, commissionType } = fees && Object.values(fees)[0];
      return {
        commissionPercentage: (commissionPercentage * 100).toFixed(2),
        commissionType,
      };
    }

    return {};
  };

  return {
    fee: formatFees(fees),
    address: formatAddresses(addresses),
    currencies,
    industryIds: industryIds || [],
    usersCount,
    contractType,
  };
};

const formatCompanyFirebaseData = (companyData: FormattedCompanyUserData, attachment: Attachment | null): CompanyUpdateReq => {
  const {
    name = '', fee = 0, feeType = SalaryType.Net, website = '', line1 = '', city = '',
    state = '', postalCode = '', phoneNumber = '', countryCode = '', addressId = '', type = '', testUser = true,
    industryIds = [], contractType,
  } = companyData;

  const addressJSON = JSON.stringify({
    line1,
    city,
    state,
    postalCode,
    phoneNumber,
    countryCode,
    id: addressId,
    type,
  });

  const result = {
    name,
    fee: fee ? fee / 100 : fee,
    feeType,
    contractType,
    clientWebSiteUrl: website,
    industryKey: industryIds.join(','),
    envMode: testUser ? EnvironmentMode.Test : EnvironmentMode.Default,
    address: addressJSON,
  };

  if (attachment) {
    return { ...result, attachment: { ...attachment, meaning: AttachmentMeaning.Contract } };
  }

  return result;
};

const updateLoadingStatus = (bool: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.Loading,
    payload: bool,
  });
};

const updateRolesLoadingStatus = (bool: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.RolesLoading,
    payload: bool,
  });
};

const formatUserRoles = (data: AllowedRole[]) => {
  const roles = Object.values(data);

  return {
    allowedRoles: roles.map((item) => item.role) || [],
    appliedRoles: roles.filter(({ applied }: AllowedRole) => applied).map(({ role }) => role) || [],
  };
};

export const getUser = (userId: string) => (dispatch: AppDispatch) => {
  updateLoadingStatus(true)(dispatch);

  return UserService.getUser(userId)
    .then((response: UserData) => {
      dispatch({
        type: Actions.GetProfile,
        payload: formatUserData(response),
      });

      setUserSource(response)(dispatch);
      if (response.basicProfile?.attachments?.attachments) {
        const { attachments } = response.basicProfile?.attachments;
        setAttachments({ attachments, inlineAttachments: Object.values(attachments) })(dispatch);
      }
    })
    .catch((error) => {
      notificationToast.error(<FormattedMessage id="error.userDataProblem" />);
      return Promise.reject(error);
    })
    .finally(() => updateLoadingStatus(false)(dispatch));
};

export const getUserRoles = (userId: string) => (dispatch: AppDispatch) => {
  updateRolesLoadingStatus(true)(dispatch);

  UserService.getAllowedRoles(userId)
    .then((response) => {
      dispatch({
        type: Actions.GetRoles,
        payload: formatUserRoles(response.roles),
      });
    })
    .catch(() => notificationToast.error(<FormattedMessage id="errors.unableToGetAllowedRoles" />))
    .finally(() => updateRolesLoadingStatus(false)(dispatch));
};

export const getCompanyPrivate = (userId: string) => (dispatch: AppDispatch) => (
  UserService.getCompanyPrivateInfo(userId)
    .then((response) => {
      dispatch({
        type: Actions.GetPrivateData,
        payload: formatCompanyPrivateData(response),
      });
    })
    .catch((error) => {
      notificationToast.error(<FormattedMessage id="error.companyNotFound" />);
      return Promise.reject(error);
    })
);

export const setUserSource = (source: UserData) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.GetSource, payload: source });

export const updateEnvMode = (userId: string, isTestUser: boolean) => (dispatch: AppDispatch) =>
  UserService.updateEnvironmentMode(userId, isTestUser)
    .then(() => {
      dispatch({ type: Actions.UpdateEnvMode, payload: isTestUser });
      notificationToast.success(<FormattedMessage id="label.makeTestUser" />);
    })
    .catch(() => {
      notificationToast.error(<FormattedMessage id="errors.somethingWentWrong" />);
    });

export const updatePIN = (userId: string, pin: string) => (dispatch: AppDispatch) =>
  UserService.updatePIN(userId, pin)
    .then(() => {
      dispatch({ type: Actions.UpdatePIN, payload: pin });
      notificationToast.success(<FormattedMessage id="notification.pinWasChanged" />);
    })
    .catch(() => notificationToast.error(<FormattedMessage id="errors.somethingWentWrong" />));

export const updateUser = (userId: string, firebaseData: UserUpdateReq, formattedData: Partial<UserBasicInfo> & { systemInfo: SystemInfo }) => (dispatch: AppDispatch) =>
  UserService.updateUserProfile(userId, firebaseData)
    .then(() => {
      dispatch({ type: Actions.UpdateUser, payload: formattedData });
    });

export const updateAmbassador = (userId: string, firebaseData: UserUpdateReq, formattedData: Partial<UserBasicInfo> & { systemInfo: SystemInfo }) => (dispatch: AppDispatch) =>
  UserService.updateAmbassadorProfile(userId, firebaseData)
    .then(() => {
      dispatch({ type: Actions.UpdateUser, payload: formattedData });
    });

export const updateEmployer = (
  companyData: FormattedCompanyUserData,
  attachments: Attachment[],
  removedAttachments: Attachment[],
  user: OptimizedUserData,
  userData: UserData,
) => async () => {
  let newAttachment: Attachment | null = null;

  if (attachments.length && !attachments[0]?.id) {
    // TODO: remove 'as' after we add types to uploadContract and nested functions
    newAttachment = await UserService.uploadContract(attachments[0], user, userData, companyData.attachmentId || '') as unknown as Attachment;
  }

  if (removedAttachments.length) {
    removedAttachments.forEach(({ id }) => id && UserService.removeAttachment(companyData.id, id));
  }

  await UserService.updateEmployer(companyData.id, formatCompanyFirebaseData(companyData, newAttachment));

  return Promise.resolve();
};

export const updateInviteCode = (userId: string, inviteCode: string) => (dispatch: AppDispatch) =>
  UserService.updateInviteCode(userId, inviteCode)
    .then(() => {
      dispatch({ type: Actions.UpdateInviteCode, payload: inviteCode });
      notificationToast.success(<FormattedMessage id="notification.referralWasChanged" />);
    })
    .catch(() => notificationToast.error(<FormattedMessage id="errors.somethingWentWrong" />));

export const updateUserBlacklistStatus = (userId: string, blacklistStatus: boolean) => (dispatch: AppDispatch) => (
  UserService.updateBlacklistStatus(userId, blacklistStatus)
    .then(() => {
      dispatch({ type: Actions.UpdateBlacklistStatus, payload: blacklistStatus });
      notificationToast.success(<FormattedMessage id={blacklistStatus ? "black_list_success": "unblack_list_success"} />);
    })
    .catch(() => notificationToast.error(<FormattedMessage id="black_list_error" />))
);

export const resetUser = () => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.Reset });
};

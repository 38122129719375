import { GenericActionType } from 'Constants/genericActionType';
import { GenericReducerName } from 'Constants/genericReducerName';
import { GenericReducerProp } from 'Constants/genericReducerProp';

export const updateDataAsyncAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.UpdateDataAsync}`,
  payload,
  reducerProperty,
});

export const updateDataAsyncActionPush = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.UpdateDataAsyncPush}`,
  payload,
  reducerProperty,
});

export const updateDataSyncAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.UpdateDataSync}`,
  payload,
  reducerProperty,
});

export const updateSimpleDataSyncAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.UpdateSimpleDataSync}`,
  payload,
  reducerProperty,
});

export const updateListSyncAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.UpdateListSync}`,
  payload,
  reducerProperty,
});

export const deleteDataSyncAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.DeleteDataSync}`,
  payload,
  reducerProperty,
});

export const clearDataSyncAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp) => ({
  type: `${namespace}/${GenericActionType.ClearDataSync}`,
  reducerProperty,
});

export const isLoadingAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: boolean) => ({
  type: `${namespace}/${GenericActionType.IsLoading}`,
  payload,
  reducerProperty,
});

export const isSortableAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: boolean) => ({
  type: `${namespace}/${GenericActionType.IsSortable}`,
  payload,
  reducerProperty,
});

export const setDataAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.SetDataSync}`,
  reducerProperty,
  payload,
});

export const pushDataAction = (namespace: GenericReducerName, reducerProperty: GenericReducerProp, payload: any) => ({
  type: `${namespace}/${GenericActionType.PushDataSync}`,
  reducerProperty,
  payload,
});

import { getApiData } from 'Services/Api';
import { LANGUAGES_API } from 'Constants/apiRoutes';
import { ListResponse } from 'Types/listResponse.interface';

export const LanguagesService = {

  async getLanguages(): Promise<ListResponse<{ key: string; value: string }>> {
    return getApiData<ListResponse<{ key: string; value: string }>>(`${LANGUAGES_API}?limit=1000`);
  },
};

export default LanguagesService;

import { AddressType, ContractType } from 'Constants/enums';
import { phoneParser } from 'Utils/helpers';
import { OptimizedUserData } from 'Types/optimizedUserData.interface';
import { FormattedUserData } from 'Types/formattedUserData.interface';
import { FormattedCompanyUserData } from 'Types/formattedCompanyUserData.interface';
import { CompanyPrivateData } from 'Types/companyPrivateData.interface';
import { UserResponse } from 'Types/userResponse.interface';

export const getLevel1Id = ({ id }: { id: string }) => id ?? '';

export const formatUserProfile = (user: OptimizedUserData): FormattedUserData => {
  const {
    id, dob, latinizedName, address, countryCode, email, name,
  } = user;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { nationalNumber, country } = phoneParser(address?.phoneNumber);

  return {
    countryCode,
    dateOfBirth: dob,
    email,
    id,
    phoneNumber: nationalNumber,
    phoneCountryCode: country,
    latinizedName,
    name,
  };
};

// TODO: remove
export const formatCompanyProfile = (company: UserResponse, companyPrivateData?: CompanyPrivateData): FormattedCompanyUserData => {
  const { id, name, website, attachments, systemInfo } = company;

  const address = companyPrivateData?.address;

  return {
    id,
    name,
    fee: companyPrivateData?.fee?.commissionPercentage || null,
    feeType: companyPrivateData?.fee?.commissionType || null,
    contractType: companyPrivateData?.contractType || ContractType.Share,
    addressId: address?.id || '',
    line1: address?.line1,
    city: address?.city,
    state: address?.state,
    postalCode: address?.postalCode,
    phoneNumber: address?.phoneNumber,
    countryCode: address?.countryCode,
    type: address?.type || AddressType.Tax,
    industryIds: companyPrivateData?.industryIds || [],
    website: website || '',
    attachmentId: (attachments?.length && attachments[0].id) || null,
    attachments,
    testUser: systemInfo.testUser,
  };
};

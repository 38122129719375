import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import { ButtonBase, Badge, Popover } from '@mui/material';

import EmptyCart from 'Components/EmptyCart';
import CartContent from 'Components/CartContent';

import { getTotalNumberOfProducts, getCartTotal, getCart } from 'Store/cart/selectors';
import { setProducts, setTotalProductsQuantity } from 'Store/cart/actions';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getShoppingCartProductsInfo } from 'Services/ShoppingCart';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { CART_ROUTE } from 'Constants/routes';
import { BREAKPOINTS } from "Constants/common";
import * as styles from './index.module.scss';

const ShoppingCart = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const totalProductsQuantity = useSelector(getTotalNumberOfProducts) || 0;
  const cartTotal = useSelector(getCartTotal) || '';
  const { products } = useSelector(getCart) || {};
  const isOpen = Boolean(anchorEl);
  const isMobile = useMediaQuery(`@media (max-width:${BREAKPOINTS.sm}px)`);
  const location = useLocation();
  const dispatch = useDispatch();

  const calculateTotalQuantity = (items: any) => {
    const total = items ? Object.values(items).reduce((acc, curr) => (acc + curr.quantity), 0) : 0;

    if (totalProductsQuantity !== total) {
      dispatch(setTotalProductsQuantity(total));
    }
  };

  useEffect(() => {
    const me = firebaseGetCurrentUser();

    if (Object.keys(me).length) {
      getShoppingCartProductsInfo()
        .on('value', (dataSnapshot) => {
          const cartProducts = dataSnapshot.val() || null;
          calculateTotalQuantity(cartProducts);
          dispatch(setProducts(cartProducts));
        });
    }

    return () => getShoppingCartProductsInfo().off();
  }, []);

  const handleOpenPopper = (event: MouseEvent<HTMLElement>) => {
    if (isMobile) {
      if (location.pathname !== CART_ROUTE) {
        navigate(CART_ROUTE);
      }

      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.shoppingCart}>
      <ButtonBase
        disableRipple
        aria-label="cart"
        className={`${cs(styles.cartButton, { [styles.cartButtonActive]: isOpen })} no-shake`}
        onClick={handleOpenPopper}
      >
        <div className={styles.cartIcon}>
          <Badge className={styles.badge} badgeContent={totalProductsQuantity} color="primary">
            <span className="material-symbols-rounded">
              <span className="shopping_cart" />
            </span>
          </Badge>
        </div>
      </ButtonBase>
      {isOpen && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          className={styles.cartContent}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          slotProps={{ paper: { className: styles.popperPaper } }}
          onClose={handleClose}
        >
            {(totalProductsQuantity > 0 && products)
              ? <CartContent products={products} cartTotal={cartTotal} closeCart={handleClose} />
              : <EmptyCart />}
        </Popover>
      )}
    </div>
  );
};

export default ShoppingCart;

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyDSu7JyHCE9SFhI_kJzVbZqhaIVB246Gx0',
    authDomain: 'alleaves-prod.firebaseapp.com',
    databaseURL: 'https://alleaves-prod-default-rtdb.firebaseio.com/',
    projectId: 'alleaves-prod',
    storageBucket: 'alleaves-prod.appspot.com',
    messagingSenderId: '548556636801',
    appId: '1:548556636801:web:6d1ab413e3d578b643c380',
    measurementId: 'G-SGHJNP1D4E',
  },
  API_BASE_URL: 'https://api.alleaves.shop',
};

export default config;

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyAfY31R2Xtvl3-_xYwdtV7bD_xmWVLB0ME',
    authDomain: 'joblio-prod.firebaseapp.com',
    databaseURL: 'https://joblio-prod.firebaseio.com',
    projectId: 'joblio-prod',
    storageBucket: 'joblio-prod.appspot.com',
    messagingSenderId: '38580359324',
    appId: '1:38580359324:web:3ceabb5472c95a6ee35d59',
    measurementId: 'G-C8VJZC73H6',
  },
  stripeKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: 'APYEOJ4UNH',
  algolia_api_key: '534e91f42e5cc361776ab3c7b4dfb5a1',
};

export default config;

import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as styles from './index.module.scss';

const LoaderOverlay: FC = () => (
  <div className={styles.loaderOverlay}>
    <CircularProgress />
  </div>
);

export default LoaderOverlay;

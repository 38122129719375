import { PayPeriodUnit } from 'Constants/enums';

export const PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS: Partial<Record<PayPeriodUnit, string>> = {
  [PayPeriodUnit.Hour]: 'label.hours',
  [PayPeriodUnit.Day]: 'label.days',
  [PayPeriodUnit.Week]: 'label.weeks',
  [PayPeriodUnit.Month]: 'label.month', // Singular
  [PayPeriodUnit.Year]: 'label.year', // Singular
  [PayPeriodUnit.Job]: 'label.per.job',
  [PayPeriodUnit.Kilogram]: 'label.kilograms',
  [PayPeriodUnit.Pound]: 'label.pounds',
  [PayPeriodUnit.LinearMeter]: 'label.linearMeters',
  [PayPeriodUnit.LinearFoot]: 'label.linearFeet',
  [PayPeriodUnit.SquareMeter]: 'label.sqMeters',
  [PayPeriodUnit.SquareFoot]: 'label.sqFeet',
  [PayPeriodUnit.CubicMeter]: 'label.cubicMeters',
  [PayPeriodUnit.CubicFoot]: 'label.cubicFeet',
};

export const BONUS_PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS: { [key: string]: string } = {
  [PayPeriodUnit.Hour]: 'label.hour',
  [PayPeriodUnit.Day]: 'label.day',
  [PayPeriodUnit.Week]: 'label.week',
  [PayPeriodUnit.Month]: 'label.month',
  [PayPeriodUnit.Year]: 'label.year',
  [PayPeriodUnit.Job]: 'label.per.job',
  [PayPeriodUnit.Kilogram]: 'label.kilogram',
  [PayPeriodUnit.Pound]: 'label.pound',
  [PayPeriodUnit.LinearMeter]: 'label.linearMeter',
  [PayPeriodUnit.LinearFoot]: 'label.linearFoot',
  [PayPeriodUnit.SquareMeter]: 'label.sqMeter',
  [PayPeriodUnit.SquareFoot]: 'label.sqFoot',
  [PayPeriodUnit.CubicMeter]: 'label.cubicMeter',
  [PayPeriodUnit.CubicFoot]: 'label.cubicFoot',
};

import { getApiData, postApiData } from 'Services/Api';
import { GET_DOWNLOAD_REPORT, REPORTS_LIST } from 'Constants/apiRoutes';
import { Report, ReportConfig, ReportPayload } from 'Types/report.interface';
import { formatApiRoute } from 'Utils/api';

export const ReportsService = {
  async getReportList(queryParams: Record<string, string> | null): Promise<{ reports: Record<string, ReportConfig> }> {
    const endpoint = formatApiRoute({
      endpoint: REPORTS_LIST,
      params: {},
      queryParams: queryParams || {},
    });

    return getApiData<{ reports: Record<string, ReportConfig> }>(endpoint);
  },

  async getReport(payload: ReportPayload): Promise<Report> {
    return postApiData(GET_DOWNLOAD_REPORT, payload);
  },
};

export default ReportsService;

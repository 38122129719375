import { FormControlLabel, MenuItem, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  BlockquoteButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import { object, func, string, array } from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';

//  Components
import StyledSelect from 'Components/StyledSelect';
import CustomInput from 'Components/CustomInput';
import MarkdownEditorControl from 'Components/MarkdownEditorControl';
import SwitchStyled from 'Components/SwitchStyled';
import RewardField from 'Components/RewardField';
import ChipsSelect from 'Components/ChipsSelect';
import ExpiryForm from 'Components/ExpiryForm';
import CreateSurveyOptions from 'Components/CreateSurveyOptions';
import TooltipAnchor from 'Components/TooltipAnchor';
import CheckboxStyled from 'Components/CheckboxStyled';
import CustomDateTimePicker from 'Components/CustomDateTimePicker';
import BountyOnBehalfOf from 'Containers/BountyCreate/BountyOnBehalfOf';

//  Other resources
import { getErrorsByName } from 'Utils/formik';
import { BountyType } from 'Constants/enums';
import { generateTimeOptions } from 'Utils/bounty';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './index.module.scss';

const toolbarButtons = [
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  BlockquoteButton,
  UnorderedListButton,
  OrderedListButton,
  BoldButton,
  ItalicButton,
];

const switchComponentProps = (name) => ({
  input: { name },
});

const BountyCreateForm = (props) => {
  const {
    formik,
    uiFeatures,
    bountyType,
    myCurrency,
    targetList,
    showInOptions,
    currencyOptions,
    handleChanges,
    onDescriptionChange,
    handleSwitch,
    handleDistributionGroups,
    handleOnBehalfOf,
    handleDateChanges,
    handleChoiceChanges,
    handleCorrectChoice,
    handleDeleteChoice,
    handleAddChoice,
    setFieldValue,
  } = props;

  const intl = useIntl();

  const renderInput = (label, name, placeholder, type) => (
    <div>
      <Typography className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: label })}
      </Typography>
      <CustomInput
        type={type}
        name={name}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        value={get(formik?.values, name)}
        onChange={handleChanges}
        validationErrors={getErrorsByName(formik, name)}
      />
    </div>
  );

  const renderSwitch = (label, name) => (
    <div>
      <FormControlLabel
        classes={{
          root: styles.formLabel,
        }}
        labelPlacement="start"
        label={intl.formatMessage({ id: label })}
        name={name}
        checked={!!get(formik?.values, name)}
        onChange={handleSwitch}
        control={(
          <SwitchStyled componentsProps={switchComponentProps(name)} />
        )}
      />
    </div>
  );

  const renderSelect = (label, name, options) => (
    <div>
      <Typography className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: label })}
      </Typography>
      <StyledSelect
        name={name}
        value={get(formik?.values, name) || ''}
        onChange={handleChanges}
        validationErrors={getErrorsByName(formik, name)}
      >
        {options.map(({ label: optionLabel, value: optionValue }) => (
          <MenuItem key={optionValue} value={optionValue}>
            {optionLabel}
          </MenuItem>
        ))}
      </StyledSelect>
    </div>
  );

  return (
    <div className={styles.root}>
      {uiFeatures.hasDummyDescription && (
        renderInput('label.title', 'description')
      )}

      {uiFeatures.hasDescription && (
        <div>
          <MarkdownEditorControl
            toolbarButtons={toolbarButtons}
            value={formik?.values?.description || ''}
            onChange={onDescriptionChange}
            validationErrors={getErrorsByName(formik, 'description')}
          />
        </div>
      )}

      {uiFeatures.hasSurvey && (
        <CreateSurveyOptions
          bountyType={bountyType}
          options={formik?.values?.surveyOptions || {}}
          handleChoiceChanges={handleChoiceChanges}
          handleCorrectChoice={handleCorrectChoice}
          handleDeleteChoice={handleDeleteChoice}
          handleAddChoice={handleAddChoice}
        />
      )}

      {uiFeatures.hasTimeToRespond && (
        renderSelect('bounty.timeToRespond', 'timeLimit', generateTimeOptions({ minValue: 5, maxValue: 180, step: 5 }))
      )}

      {uiFeatures.hasTargetUrl && (
        bountyType === BountyType.Lottery
          ? renderInput('bounty.terms', 'targetUrl', 'bounty.terms.placeholder')
          : renderInput('bounty.targetUrl', 'targetUrl', 'bounty.targetUrl.placeholder')
      )}

      {uiFeatures.hasStreamDetails && (
        <>
          {renderInput('label.streamUrl', 'broadcastInfo.streamUrl', 'placeholder.addStreamUrl')}
          {renderInput('label.streamKey', 'broadcastInfo.streamKey', 'placeholder.addStreamUrl')}
        </>
      )}

      {uiFeatures.hasPrice && (
        <div className={styles.priceBox}>
          <div className={styles.price}>
            {renderInput('labels.price', 'price', 'labels.addPrice', 'number')}
          </div>
          <Typography className={classnames(labelStyles.fieldLabel, styles.currency)}>
            {myCurrency}
          </Typography>
        </div>
      )}

      {uiFeatures.hasReward && (
        <div>
          <RewardField
            value={formik?.values?.reward || {}}
            onChange={handleChanges}
            currencyOptions={currencyOptions}
            validationErrors={getErrorsByName(formik, 'reward')}
            handleBadge={setFieldValue}
          />
        </div>
      )}

      {uiFeatures.hasDistTargetGroup && targetList.length > 0 && (
        <div>
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'bounty.audience' })}
          </Typography>
          <ChipsSelect
            placeholder={!formik?.values?.distributionCodes?.length
              ? intl.formatMessage({ id: 'label.everybody' })
              : ''}
            values={formik?.values?.distributionCodes || []}
            options={targetList}
            onChange={handleDistributionGroups('distributionCodes')}
          />
        </div>
      )}

      {uiFeatures.hasRequestBadge && (
        renderSwitch('bounty.requestOfficialResponse', 'interactions.officialResponse')
      )}

      {uiFeatures.hasControlAnonymity && (
        <div className={styles.checkboxField}>
          <label className={labelStyles.fieldLabel}>
            <span>
              {intl.formatMessage({ id: 'bounty.postAnonymously' })}
              {' '}
              <TooltipAnchor translationKey="bounty.anonymityHelpContent" />
            </span>
          </label>
          <FormControlLabel
            name="anonymity"
            checked={!!formik?.values?.anonymity}
            onChange={handleSwitch}
            control={<CheckboxStyled />}
          />
        </div>
      )}

      {uiFeatures.hasControlResponsesPrivacy && (
        <div className={styles.checkboxField}>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'bounty.makeResponsesPrivate' })}
          </label>
          <FormControlLabel
            name="visibilityType"
            checked={!!formik?.values?.visibilityType}
            onChange={handleSwitch}
            control={<CheckboxStyled />}
          />
        </div>
      )}

      {uiFeatures.hasDisplayIn && !formik?.values?.distributionCodes?.length && (
        renderSelect('bounty.showIn', 'listId', showInOptions)
      )}

      {uiFeatures.hasScheduleFor && (
        <div>
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'bounty.scheduleFor' })}
          </Typography>
          <CustomDateTimePicker
            name="postAt"
            ampm={false}
            value={formik?.values?.postAt || null}
            onDateChange={(date) => handleDateChanges('postAt', date)}
          />
        </div>
      )}

      {uiFeatures.hasExpiryFeature && (
        <div className={styles.availabilityContainer}>
          <Typography className={classnames(labelStyles.fieldLabel, styles.label)}>
            {intl.formatMessage({ id: bountyType === BountyType.Lottery ? 'bounty.closeOn' : 'bounty.expiresIn' })}
          </Typography>
          <ExpiryForm onChange={handleChanges} values={formik.values} />
        </div>
      )}

      {uiFeatures.hasPostOnBehalfOf && (
        <BountyOnBehalfOf
          handleOnBehalfOf={handleOnBehalfOf}
          onBehalfOf={formik?.values?.onBehalfOf || null}
        />
      )}
    </div>
  );
};

BountyCreateForm.propTypes = {
  formik: object.isRequired,
  uiFeatures: object.isRequired,
  bountyType: string.isRequired,
  myCurrency: string.isRequired,
  targetList: array.isRequired,
  showInOptions: array.isRequired,
  currencyOptions: array.isRequired,
  handleChanges: func.isRequired,
  onDescriptionChange: func.isRequired,
  handleSwitch: func.isRequired,
  handleDistributionGroups: func.isRequired,
  handleOnBehalfOf: func.isRequired,
  handleDateChanges: func.isRequired,
  handleChoiceChanges: func.isRequired,
  handleCorrectChoice: func.isRequired,
  handleDeleteChoice: func.isRequired,
  handleAddChoice: func.isRequired,
  setFieldValue: func.isRequired,
};

export default BountyCreateForm;

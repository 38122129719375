import { firebaseGetCompanySents } from 'Services/FirebaseService';
import { convertObjToArray } from 'Utils/helpers';
import { CompanySentsFolder } from 'Constants/enums';

export const ACTIONS = {
  SET_LOADING_BADGE_COLLECTIONS: 'badgesReducer/setLoadingBadgeCollections',
  SET_LOADING_BADGE_COLLECTION_DETAILS: 'badgesReducer/setLoadingBadgeCollectionDetails',
  SET_BADGE_COLLECTIONS: 'badgesReducer/setBadgeCollections',
  SET_BADGE_COLLECTION_DETAILS: 'badgesReducer/setBadgeCollectionDetails',
};

const setBadgeCollections = (data) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_BADGE_COLLECTIONS, payload: data });
  dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: false });
};

const setBadgeCollectionDetails = (data) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_BADGE_COLLECTION_DETAILS, payload: data });
  dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTION_DETAILS, payload: false });
};

export const resetBadges = () => (dispatch) => {
  dispatch({ type: ACTIONS.SET_BADGE_COLLECTION_DETAILS, payload: {} });
};

export const getBadgeCollections = (ownerId) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: true });

  return firebaseGetCompanySents(ownerId, CompanySentsFolder.BadgeCollections)
    .on('value', (dataSnapshot) => {
      const badges = convertObjToArray(dataSnapshot.val());
      dispatch(setBadgeCollections(badges));
      dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: false });
    }, () => {
      dispatch(setBadgeCollections([]));
      dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: false });
    });
};

export const getBadgeCollectionDetails = (ownerId, collectionId) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: true });

  firebaseGetCompanySents(ownerId, CompanySentsFolder.BadgeCollections)
    .child(collectionId)
    .on('value', (dataSnapshot) => {
      const details = dataSnapshot.val();

      dispatch(setBadgeCollectionDetails(details?.bounty || {}));
      dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: false });
    }, () => {
      dispatch(setBadgeCollectionDetails({}));
      dispatch({ type: ACTIONS.SET_LOADING_BADGE_COLLECTIONS, payload: false });
    });
};

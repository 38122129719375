import { FC, ReactElement, ReactNode } from 'react';

//  Others
import * as styles from './index.module.scss';

interface EmptyStateProps {
  emptyImage: string;
  title?: string|ReactElement;
  subTitle?: string;
  width?: number;
  height?: number;
  children?: ReactNode;
}

const EmptyState:FC<EmptyStateProps> = ({
  emptyImage,
  title,
  subTitle,
  width = 158,
  height = 150,
  children = null,
}) => (
  <div className={styles.root}>
    <div
      style={{ backgroundImage: `url(${emptyImage})`, width, height }}
      className="image-post mb-20"
    />
    {title && <h2><b>{title}</b></h2>}
    {subTitle && <h3>{subTitle}</h3>}
    {children}
  </div>
);

export default EmptyState;

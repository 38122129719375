import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';
import { Box, Typography } from '@mui/material';

import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import CustomInput from 'Components/CustomInput';
import CustomTextarea from 'Components/CustomTextarea';
import BadgeAttachments from 'Containers/BadgeCollectionCreate/BadgeAttachments';
import LoaderOverlay from 'Components/LoaderOverlay';
import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';

import { AttachmentMeaning, ButtonType, RewardType } from 'Constants/enums';
import { notificationToast } from 'Utils/notificationToaster';
import { badgeSchema } from 'Utils/validation/badges';
import { getErrorsByName } from 'Utils/formik';
import { IMAGE_MIME_TYPES } from 'Constants/attachment';
import { Attachment } from 'Types/attachment.interface';
import { CreateBadge } from 'Types/badge.interface';
import { prepareAcceptType } from 'Utils/attachments';
import * as styles from 'Containers/BadgeCollectionCreate/BadgeCreate/index.module.scss';
import TooltipAnchor from 'Components/TooltipAnchor';

const badgeAccept = prepareAcceptType([...IMAGE_MIME_TYPES, 'video/mp4']);
const badgeIconAccept = prepareAcceptType(IMAGE_MIME_TYPES);

interface BadgeCreateProps {
  badge: CreateBadge | null;
  onCancel: () => void;
  onSubmit: (values: CreateBadge) => void;
}

const BadgeCreate:FC<BadgeCreateProps> = ({ onCancel, onSubmit, badge }) => {
  const intl = useIntl();

  const onSave = (values: CreateBadge, { setSubmitting }: FormikHelpers<CreateBadge>) => {
    if ((!values?.badgeImage?.length && !values?.image) || (!values?.badgeIcon?.length && !values?.icon)) {
      notificationToast.error(intl.formatMessage({ id: 'label.uploadBadge.error' }));
    } else {
      onSubmit(values);
      formik.resetForm();
    }

    setSubmitting(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: badge?.code || '',
      label: badge?.label || '',
      description: badge?.description || '',
      maxQty: badge?.maxQty,
      reward: {
        amount: badge?.reward?.amount || '',
        currency: RewardType.Points,
      },
      icon: badge?.icon || '',
      image: badge?.image || '',
      badgeImage: badge?.badgeImage || [],
      badgeIcon: badge?.badgeIcon || [],
    },
    onSubmit: onSave,
    validationSchema: badgeSchema,
  });

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldValue(name, value);
  };

  const handleBadgeImage = (attach: Attachment[] | null) => {
    if (!attach) {
      formik.setFieldValue('image', '');
    }

    formik.setFieldValue('badgeImage', attach);
  };

  const handleBadgeIcon = (attach: Attachment[] | null) => {
    if (!attach) {
      formik.setFieldValue('icon', '');
    }

    formik.setFieldValue('badgeIcon', attach);
  };

  return (
    <Box width="400px">
      <h3>{intl.formatMessage({ id: 'label.createBadge' })}</h3>
      <BadgeAttachments
        badge={formik?.values?.badgeImage || []}
        badgeUrl={formik?.values?.image}
        setBadge={handleBadgeImage}
        label="label.uploadBadge"
        meaning={AttachmentMeaning.BadgeImage}
        accept={badgeAccept}
        required
      />
      <BadgeAttachments
        badge={formik?.values?.badgeIcon || []}
        badgeUrl={formik?.values?.icon}
        setBadge={handleBadgeIcon}
        label="label.uploadBadgeIcon"
        meaning={AttachmentMeaning.BadgeIcon}
        accept={badgeIconAccept}
        required
      />
      <form onSubmit={formik.handleSubmit} className={styles.content}>
        <Typography className={labelStyles.fieldLabel}>
          {`${intl.formatMessage({ id: 'label.name' })}*`}
        </Typography>
        <CustomInput
          name="label"
          value={formik?.values?.label || ''}
          onChange={handleChanges}
          validationErrors={getErrorsByName(formik, 'label')}
        />
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.descriptionOptional' })}
        </Typography>
        <CustomTextarea
          name="description"
          value={formik?.values?.description || ''}
          onChange={handleChanges}
          validationErrors={getErrorsByName(formik, 'description')}
        />
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'badge.label.attachPoints' })}
        </Typography>
        <CustomInput
          name="reward.amount"
          value={formik?.values?.reward?.amount || ''}
          onChange={handleChanges}
          validationErrors={getErrorsByName(formik, 'reward.amount')}
        />
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'label.quantity' })}
          {' '}
          <TooltipAnchor translationKey="hint.badgeQtyDescription" />
        </Typography>
        <CustomInput
          name="maxQty"
          value={formik?.values?.maxQty || ''}
          onChange={handleChanges}
          validationErrors={getErrorsByName(formik, 'maxQty')}
        />
        <Box className={styles.navigation}>
          <SimpleButton
            decoration={SimpleButtonDecoration.Transparent}
            label={intl.formatMessage({ id: 'button.cancel' })}
            onClick={onCancel}
          />
          <SimpleButton
            label={intl.formatMessage({ id: 'button.save' })}
            type={ButtonType.submit}
          />
        </Box>
      </form>
      {formik.isSubmitting && <LoaderOverlay />}
    </Box>
  );
};

export default BadgeCreate;

import { UseCases } from 'Utils/globalPermissions';
import { getApplicationConfig } from 'Utils/appConfig';

const { useCase = '' } = getApplicationConfig();

export const getCurrentUseCase = () => ({
  isCampaigns: useCase === UseCases.campaigns,
  isDinamo: useCase === UseCases.dinamo,
  isVip: useCase === UseCases.vip,
  isShop: useCase === UseCases.shop,
});

import classnames from 'classnames';
import { FC, forwardRef, InputHTMLAttributes, ReactNode, Ref, RefAttributes } from 'react';
import ControlValidation from 'Components/ControlValidation';
import * as styles from './index.module.scss';

interface CustomTextareaProps extends InputHTMLAttributes<HTMLTextAreaElement>, RefAttributes<HTMLTextAreaElement> {
  className?: string;
  isSuccess?: boolean;
  validationErrors?: string[] | ReactNode[];
  value?: string | number;
  rows?: number;
}

const CustomTextarea: FC<CustomTextareaProps> = forwardRef((
  {
    className = '',
    isSuccess = false,
    validationErrors = [],
    ...rest
  },
  ref,
) => (
  <div className={classnames({
    [className]: className,
    [styles.stateInvalid]: validationErrors?.length > 0,
    [styles.stateSuccess]: isSuccess,
    [styles.stateDisabled]: rest.disabled,
  })}
  >
    <textarea
      {...rest}
      ref={ref as Ref<HTMLTextAreaElement>}
      className={styles.control}
    />
    {validationErrors?.length > 0 && <ControlValidation validationErrors={validationErrors} />}
  </div>
));

export default CustomTextarea;

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyB6G8sV4yiVWmbcmxHX2nGL7JuAroKZFT8',
    authDomain: 'stiinta-prod.firebaseapp.com',
    databaseURL: 'https://stiinta-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'stiinta-prod',
    storageBucket: 'stiinta-prod.appspot.com',
    messagingSenderId: '83991175673',
    appId: '1:83991175673:web:d64ad0fd705588d4eadd28',
    measurementId: 'G-9GXN76SQ6Z',
  },
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: '',
  algolia_api_key: '',
};

export default config;

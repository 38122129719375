import { ACTIONS } from './actions';

const INIT_STATE = {
  isLoading: false,
  profile: {},
  areReviewsLoading: false,
  reviews: {},
  areProductsLoading: false,
  products: [],
  productsPagination: {
    totalCount: 0,
    start: 0,
  },
};

const reducer = (state = INIT_STATE, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_PROFILE:
      return {
        ...state,
        profile: payload,
      };

    case ACTIONS.SET_ARE_REVIEWS_LOADING:
      return {
        ...state,
        areReviewsLoading: payload,
      };

    case ACTIONS.SET_REVIEWS:
      return {
        ...state,
        reviews: payload,
      };

    case ACTIONS.SET_ARE_PRODUCTS_LOADING:
      return {
        ...state,
        areProductsLoading: payload,
      };

    case ACTIONS.SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      };

    case ACTIONS.SET_PRODUCTS_START_PAGE:
      return {
        ...state,
        productsPagination: {
          ...state?.productsPagination,
          start: payload,
        },
      };

    case ACTIONS.RESET_REDUCER:
      return INIT_STATE;

    default:
      return state;
  }
};

export default reducer;

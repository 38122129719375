import { ChangeEvent, FC } from 'react';

import { FormControlLabel, FormGroup } from '@mui/material';

import CheckboxStyled from 'Components/CheckboxStyled';
import MemberAvatar from 'Components/MemberAvatar';

import { GroupMember } from 'Types/group.interface';
import * as styles from './index.module.scss';

interface GroupCreateMembersProps {
  members: GroupMember[];
  values: Record<string, boolean>;
  onChange: (memberId: string, event: ChangeEvent<HTMLInputElement>) => unknown;
}

const GroupCreateMembers: FC<GroupCreateMembersProps> = ({ members, values, onChange }) => (
  <FormGroup>
    {members.map(({ user }) => (
      <FormControlLabel
        className={styles.checkbox}
        key={user.id}
        classes={{
          root: styles.checkboxLabel,
        }}
        label={(
          <div className="flex-center-v cursor-pointer">
            <MemberAvatar user={user} />
            <h3 className={styles.userName}>
              {user.name}
            </h3>
          </div>
        )}
        control={<CheckboxStyled onChange={(e) => onChange(user.id, e)} checked={values?.[user.id] || false} />}
      />
    ))}
  </FormGroup>
);

export default GroupCreateMembers;

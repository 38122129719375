import { ONBOARDING_ROUTE } from 'Constants/routes';
import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//  Components
import CustomLoader from 'Components/CustomLoader';

//  Actions/Selectors
import {
  getCompanySettings,
  getOrgTypeSettings,
  getSystemInfo,
  getSystemSettings,
  getUserData,
  getUserInfo,
} from 'Store/settings/actions';
import { getAccountInfo } from 'Store/commonActions';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getOrganizationType } from 'Utils/settings';
import { ThunkDispatchType } from 'Types/redux.types';
import { Settings } from 'Types/settings.interface';
import { isEmpty, isEqual } from 'lodash';
import { userInfoSelector } from 'Store/settings/selectors';
import { getClientId } from 'Utils/api';
import { AppConfig } from 'Utils/appConfig';
import { isSetupRequired } from 'Utils/user';
import { useNavigate } from 'react-router-dom';
import { UserData } from 'Types/userData.interface';
import { isInitializedSelector } from 'Store/auth/selectors';
import { setIsInitialized } from 'Store/auth/actions';

interface AppInfoDataProps {
  children: ReactNode | ReactNode[];
}

//  TODO: fix the type for AppConfig
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { enableOnboarding } = AppConfig.auth;

// TODO: replace component with actions
const AppInfoData: FC<AppInfoDataProps> = ({ children }) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const userInfo = useSelector(userInfoSelector, isEqual);
  const clientId = getClientId();
  const isInitialized = useSelector(isInitializedSelector, isEqual);

  useEffect(() => {
    const { uid } = firebaseGetCurrentUser();

    if (!uid) {
      return dispatch(setIsInitialized(true));
    }

    if (clientId && isEmpty(userInfo)) {
      dispatch(getUserInfo());
    }

    getAppData(uid);
  }, []);

  const getAppData = (userId: string) => {
    Promise.all([
      dispatch(getAccountInfo()),
      dispatch(getSystemInfo()),
      dispatch(getUserData({ userId })),
      dispatch(getSystemSettings()),
    ])
      .then((result) => {
        const [,, userData, systemSettings] = result;

        const settings: Settings = {
          userSettings: (userData && userData.settings) || null,
          systemSettings: systemSettings || null,
          companySettings: {},
        };

        if (userData && userData.company) {
          return dispatch(getCompanySettings({ companyId: userData.company.id }))
            .then((companySettingsResult) => {
              settings.companySettings = companySettingsResult || null;

              fetchOrgTypeSettings(settings, userData);
            });
        }

        fetchOrgTypeSettings(settings, userData);
      });
  };

  const fetchOrgTypeSettings = (settings: Settings, userData: UserData) => {
    const orgType = getOrganizationType(settings);

    if (orgType) {
      return dispatch(getOrgTypeSettings(orgType))
        .then(() => completeFlow(userData));
    }

    return completeFlow(userData);
  };

  const completeFlow = (userData: UserData) => {
    if (enableOnboarding && isSetupRequired(userData?.flows)) {
      navigate(ONBOARDING_ROUTE, { replace: true });
    }
    setTimeout(() => dispatch(setIsInitialized(true)), 100);
  };

  if (!isInitialized) {
    return <CustomLoader isLoading />;
  }

  /* eslint-disable react/jsx-no-useless-fragment */
  return (
    <>
      {children}
    </>
  );
};

export default AppInfoData;

import { Category } from 'Types/category.interface';
import { Attachment } from 'Types/attachment.interface';

export const buildCategoriesFromCategoryCode = (code: string, categories: Category[]): Category[] | null => {
  for (let index = 0; index < categories?.length; index += 1) {
    const category = categories[index];

    if (category.code === code) {
      return [category];
    } if (category.children) {
      const childResult = buildCategoriesFromCategoryCode(code, category?.children);
      if (childResult !== null) {
        return [category, ...childResult];
      }
    }
  }

  return null;
};

export const getSelectedCategories = (attachment: Attachment, categories: Category) => {
  if (attachment?.action?.categories) {
    const attachmentCategories = attachment?.action?.categories?.split(',') || [];
    const foundCategories = categories?.children
      ? buildCategoriesFromCategoryCode(attachmentCategories?.[attachmentCategories?.length - 1], categories?.children) || []
      : [];
    return [categories, ...foundCategories];
  }

  return [categories];
};

export const formatCategoriesForAttachment = (newCategories: Category[]) => {
  const categories: string[] = [];

  newCategories?.forEach((category) => {
    if (!category.code.includes('root')) {
      categories.push(category.code);
    }
  });

  return categories?.join(',');
};

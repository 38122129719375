import { IdentityType } from 'Constants/enums';
import { UNDERCOVER_AVATAR_URI, UNDERCOVER_NAME } from 'Utils/user';
import { firebaseGetUserDataRef, firebaseGetCurrentUser } from 'Services/FirebaseService';

export const getMyUserId = () => {
  const me = firebaseGetCurrentUser();
  const myUserId = me.uid || null;

  if (myUserId === null) {
    console.error('not-logged-in');
    return null;
  }

  return myUserId;
};

export async function updateIdentity(bounty) {
  if (bounty == null || bounty.identityMode == null) {
    return;
  }

  const identityMode = bounty.identityMode || {};

  switch (identityMode.identityType) {
    case IdentityType.Real: {
      const creator = await getMyUser();

      return {
        creator,
        author: null,
      };
    }
    case IdentityType.Anon:
      return {
        creator: {
          id: getMyUserId(),
        },
        author: {
          avatar: UNDERCOVER_AVATAR_URI,
          name: UNDERCOVER_NAME,
        },
      };
    default:
      // carry on
      return {};
  }
}

export function getMyUser() {
  const fbUser = firebaseGetCurrentUser();

  return new Promise((resolve) => {
    firebaseGetUserDataRef(fbUser.uid)
      .once('value', (dataSnapshot) => {
        const myUserData = dataSnapshot.val() || {};
        let myself = null;

        if (myUserData !== null) {
          myself = myUserData.myself;
        }

        // if not available, we can fallback to synthesizing it from FirebaseUser for now
        if (fbUser !== null) {
          if (!myself) {
            myself = {};
          }

          // we merge the FirebaseUse in to make sure we have as much info about ourselves as possible
          if (!myself.id) {
            myself.id = fbUser.uid;
          }
          if (!myself.name) {
            myself.name = fbUser.displayName;
          }
          if (!myself.avatar) {
            myself.avatar = fbUser.photoURL || null;
          }

          resolve(myself);
        }
      });
  });
}

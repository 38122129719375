import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyCseQqroMo6UQVZNCuWaCZgNQqiX6ao2Kg',
    authDomain: 'campaigns-abd4a.firebaseapp.com',
    databaseURL: 'https://campaigns-abd4a-default-rtdb.firebaseio.com',
    projectId: 'campaigns-abd4a',
    storageBucket: 'campaigns-abd4a.appspot.com',
    messagingSenderId: '450530323659',
    appId: '1:450530323659:web:b9b94e5741bec759357f4e',
    measurementId: 'G-NYZ9RM9J87',
  },
  API_BASE_URL: 'https://edge-server-zosrjn6zma-uc.a.run.app',
};

export default config;

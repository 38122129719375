import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { isPrimitive } from 'Utils/isPrimitive';

const useDebounce = (value: unknown, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const effectHook = isPrimitive(value) ? useEffect : useDeepCompareEffect;

  effectHook(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );

  return debouncedValue;
};

export default useDebounce;

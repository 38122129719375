import bigInt, { BigInteger } from 'big-integer';
import { cloneDeep } from 'lodash';
import { sortKeys } from 'Constants/streamSortConfig';
import { isDraftState } from 'Utils/bountyState';
import { listTypeData } from 'Constants/streamListDefs';
import { ListDef } from 'Utils/models/Lists';
import { firstNonNull } from 'Utils/object';
import { BountyState, CtrlTypes, StreamCodes, KeyCategory, SortBy, StreamListType } from 'Constants/enums';
import { Bounty } from 'Types/bounty.interface';
import { BountyKeyInfo } from 'Types/bountyKeyInfo.interface';
import { Comment } from 'Types/comment.interface';
import { ProductInfo } from 'Types/productInfo.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';

const MAX_VALUE_LONG = '9223372036854775807';
const HEX_ARRAY = '0123456789ABCDEF'.split('');
const KEY_SEP = '-';

const longToBytes = (nr: BigInteger) => {
  const byteArray = [0, 0, 0, 0, 0, 0, 0, 0];
  let cloneNr = nr;

  byteArray.forEach((item, index) => {
    const localIdx = 7 - index;
    byteArray[localIdx] = cloneNr.and(0xff);
    cloneNr = cloneNr.shiftRight(8);
  });

  return byteArray;
};

const bytesToHex = (bytes: number[]) => {
  let hexa = '';

  bytes.forEach((item) => {
    const v = item & 0xFF; // eslint-disable-line
    hexa = `${hexa}${HEX_ARRAY[v >>> 4]}${HEX_ARRAY[v & 0x0F]}`; // eslint-disable-line
  });

  return hexa;
};

const getTimestampAsSortKeyAsc = (ts: number) => {
  const longValue = bigInt(ts);
  return bytesToHex(longToBytes(longValue));
};

export const getTimestampAsSortKeyDesc = (ts: number) => {
  const longValue = bigInt(MAX_VALUE_LONG).subtract(ts);
  return bytesToHex(longToBytes(longValue));
};

export const stitchParts = (tuple: { [key: string]: string }) => {
  if (!Object.keys(tuple)) {
    return '';
  }

  return Object.values(tuple).join(KEY_SEP);
};

const latest = (updatedAt: number, createdAt: number) => {
  const ts = Math.max(updatedAt, createdAt);

  return ts > 0 ? ts : new Date().getTime();
};

const getDiscussionScore = ({ nrResponses = 0, nrComments = 0 }: { nrResponses: number; nrComments: number }) => 10 * nrResponses + nrComments;

export const getFavoritePriority = (now: number) => {
  const tuple = {
    type: KeyCategory.Normal,
    list: StreamCodes.Regular,
    category: KeyCategory.Normal,
    sortl: getTimestampAsSortKeyDesc(now),
    ctrl: CtrlTypes.Shown,
  };

  return stitchParts(tuple);
};

// TODO: check how list code is define
const getSortKey = (bountyKeyInfo: BountyKeyInfo, type: string, ts: number) => {
  const listCode = bountyKeyInfo.listId && listTypeData[bountyKeyInfo.listId as StreamListType]
    ? listTypeData[bountyKeyInfo.listId as StreamListType]?.code
    : null;
  const listPrefix = !listCode || bountyKeyInfo.isOutbound
    || type === listCode ? StreamCodes.Regular : listCode;
  let category = KeyCategory.Normal;
  let copyTs = ts;

  if (bountyKeyInfo.pinnedAt) {
    category = KeyCategory.Important;
    copyTs = bountyKeyInfo.pinnedAt;
  }
  const sortl = getTimestampAsSortKeyDesc(copyTs);

  const tuple = {
    type: type || '0',
    list: listPrefix || StreamCodes.Regular,
    category: category || KeyCategory.Normal,
    sortl: sortl || getTimestampAsSortKeyDesc(0),
    ctrl: CtrlTypes.Shown,
  };

  return stitchParts(tuple);
};

const getSortKeyByListCommon = (bountyKeyInfo: BountyKeyInfo, nr: number) => {
  let copyListId = bountyKeyInfo.listId;

  if (!copyListId) {
    copyListId = StreamListType.Stream;
  }

  return getSortKey(bountyKeyInfo, copyListId as string, nr);
};

const getSortKeyByCreator = (bountyKeyInfo: BountyKeyInfo) => {
  const ts = latest(bountyKeyInfo.updatedAt!, bountyKeyInfo.createdAt!);
  return getSortKey(bountyKeyInfo, bountyKeyInfo.creatorId!, ts);
};

const getSortKeyByType = (bountyKeyInfo: BountyKeyInfo) => getSortKey(bountyKeyInfo, bountyKeyInfo.bountyType, bountyKeyInfo.updatedAt!);

const getSortKeyByTypeAndPopularity = (bountyKeyInfo: BountyKeyInfo) => getSortKey(bountyKeyInfo, bountyKeyInfo.bountyType, bountyKeyInfo.starRating);

const getSortKeyByTypeAndMostDiscussed = (bountyKeyInfo: BountyKeyInfo) => getSortKey(bountyKeyInfo, bountyKeyInfo.bountyType, getDiscussionScore(bountyKeyInfo));

const getSortKeyByList = (bountyKeyInfo: BountyKeyInfo) => getSortKeyByListCommon(bountyKeyInfo, bountyKeyInfo.updatedAt!);

const getSortKeyByListAndPopularity = (bountyKeyInfo: BountyKeyInfo) => getSortKeyByListCommon(bountyKeyInfo, bountyKeyInfo.starRating);

const getSortKeyByListAndMostDiscussed = (bountyKeyInfo: BountyKeyInfo) => getSortKeyByListCommon(bountyKeyInfo, getDiscussionScore(bountyKeyInfo));

const getSortKeyByStream = (bountyKeyInfo: BountyKeyInfo) => getSortKey(bountyKeyInfo, StreamCodes.Stream, bountyKeyInfo.updatedAt!);

const getSortKeyByStreamAndPopularity = (bountyKeyInfo: BountyKeyInfo) => getSortKey(bountyKeyInfo, StreamCodes.Stream, bountyKeyInfo.starRating);

const getSortKeyByStreamAndMostDiscussed = (bountyKeyInfo: BountyKeyInfo) => getSortKey(bountyKeyInfo, StreamCodes.Stream, getDiscussionScore(bountyKeyInfo));

const getSortKeyByBountyForResponse = (responseKeyInfo: BountyKeyInfo) => {
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId!, ts);
};

const getSortKeyByBountyAndPopularityForResponse = (responseKeyInfo: BountyKeyInfo) => {
  const { bountyId, nrLikes, starRating } = responseKeyInfo;
  return getSortKey(responseKeyInfo, bountyId!, nrLikes! + starRating);
};

const getSortKeyByBountyMostDiscussedForResponse = (responseKeyInfo: BountyKeyInfo) => {
  const { bountyId, nrComments } = responseKeyInfo;
  return getSortKey(responseKeyInfo, bountyId!, nrComments);
};

const getSortKeyByRecommendationApplicantForResponse = (responseKeyInfo: BountyKeyInfo) => {
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId!, ts);
};

const keyGeneratorByType = {
  [SortBy.CreatorUpdatedAt]: getSortKeyByCreator,
  [SortBy.TypeCreatedAt]: getSortKeyByType,
  [SortBy.TypePopularity]: getSortKeyByTypeAndPopularity,
  [SortBy.TypeDiscussed]: getSortKeyByTypeAndMostDiscussed,
  [SortBy.ListCreatedAt]: getSortKeyByList,
  [SortBy.ListPopularity]: getSortKeyByListAndPopularity,
  [SortBy.ListDiscussed]: getSortKeyByListAndMostDiscussed,
  [SortBy.StreamCreatedAt]: getSortKeyByStream,
  [SortBy.StreamPopularity]: getSortKeyByStreamAndPopularity,
  [SortBy.StreamDiscussed]: getSortKeyByStreamAndMostDiscussed,
};

export const getBountyKeyInfo = (bounty: Bounty): BountyKeyInfo => {
  const { stats, rating, creator } = bounty;
  let nrResponses = 0;
  let nrComments = 0;

  if (stats) {
    const { responseCount, commentCount } = stats;
    nrResponses = responseCount || 0;
    nrComments = commentCount || 0;
  }

  return {
    creatorId: creator.id,
    bountyType: bounty.type,
    pinnedAt: bounty.pinnedAt!,
    starRating: rating?.totalStars || 0,
    nrResponses,
    nrComments,
    createdAt: bounty.createdAt,
    updatedAt: bounty.updatedAt,
    listId: ListDef.getEffectiveListId(bounty.listId, bounty.listCode, bounty.type),
    isOutbound: bounty.outbound || bounty.state === BountyState.Draft,
    state: BountyState.Draft,
  };
};

export const getSortKeysObject = (bountyKeyInfo: BountyKeyInfo, keys = sortKeys) => {
  const generatedKeys: { [key: string]: string } = {};

  keys.forEach((key) => {
    const generatorFn = keyGeneratorByType[key as keyof typeof keyGeneratorByType];

    if (generatorFn) {
      generatedKeys[key] = generatorFn(bountyKeyInfo);
    }
  });

  return generatedKeys;
};

export const getOutboundPriority = (isDraft: boolean, createdAt: number) => {
  const category = isDraft ? KeyCategory.Important : KeyCategory.Normal;
  const tuple = {
    type: KeyCategory.Normal,
    list: StreamCodes.Regular,
    category,
    sortl: getTimestampAsSortKeyDesc(createdAt),
    ctrl: CtrlTypes.Shown,
  };

  return stitchParts(tuple);
};

const getInboundPriority = (bounty: Bounty) => {
  const bountyKeyInfo = getBountyKeyInfo(bounty);
  const ts = latest(bountyKeyInfo.updatedAt!, bountyKeyInfo.createdAt!);
  return getSortKey(bountyKeyInfo, KeyCategory.Normal, ts);
};

export const getPriority = (bounty: Bounty) => {
  if (bounty.outbound) {
    return getOutboundPriority(isDraftState(bounty.state), bounty.createdAt);
  }

  return getInboundPriority(bounty);
};

export const getPriorityForComment = (comment: Partial<Comment>) => {
  const {
    text, reward, editedAt, sentAt,
  } = comment;
  let category = KeyCategory.Important;

  if (!text || (text && !text.trim())) {
    category = KeyCategory.Unimporant;
  } else if (!reward) {
    category = KeyCategory.Normal;
  }

  const ts = firstNonNull(editedAt!, sentAt!);
  return ['00', category, 'GG', getTimestampAsSortKeyAsc(ts as number)].join(KEY_SEP);
};

export const getPriorityForProduct = (productInfo: Partial<ProductInfo>) => getTimestampAsSortKeyAsc(productInfo.addedAt ? productInfo.addedAt : 0);

export const forResponse = (response: BountyResponse): Partial<BountyKeyInfo> => {
  const { stats } = response;
  const nrComments = (stats && stats.commentCount) || 0;
  const nrLikes = (stats && stats.likesCount) || 0;
  const starRating = (response.rating && response.rating.totalStars) || 0;

  const creatorId = response.creator != null ? response.creator.id : null;
  const bountyId = response.bountyInfo != null ? response.bountyInfo.id : null;

  return {
    creatorId,
    bountyId,
    state: response.state,
    pinnedAt: response.pinnedAt || null,
    starRating,
    nrComments,
    nrLikes,
    createdAt: response.updatedAt || null,
    updatedAt: response.updatedAt || null,
  };
};

const getSortKeyForResponse = (key: Partial<SortBy> | string, responseKeyInfo: BountyKeyInfo) => {
  switch (key) {
    case SortBy.CreatorUpdatedAt:
      return getSortKeyByCreator(responseKeyInfo);
    case SortBy.BountyCreated:
      return getSortKeyByBountyForResponse(responseKeyInfo!);
    case SortBy.BountyPopularity:
      return getSortKeyByBountyAndPopularityForResponse(responseKeyInfo);
    case SortBy.BountyDiscussed:
      return getSortKeyByBountyMostDiscussedForResponse(responseKeyInfo);
    default:
      console.log('illegal key'); // eslint-disable-line
  }
};

export const getSortKeysForResponse = (keyInfo: BountyKeyInfo, keys: string[]) => {
  let clonedKeys = cloneDeep(keys);

  if (clonedKeys == null || clonedKeys.length === 0) {
    clonedKeys = [
      SortBy.CreatorUpdatedAt,
      SortBy.BountyCreated,
      SortBy.BountyPopularity,
      SortBy.BountyDiscussed,
    ];
  }

  const sortKeysForResponse: { [key: string]: string } = {};

  clonedKeys.forEach((key) => {
    sortKeysForResponse[key] = getSortKeyForResponse(key, keyInfo)!;
  });

  return sortKeysForResponse;
};

export const updateAllSortKeys = (response: BountyResponse) => {
  const keyInfo: Partial<BountyKeyInfo> = forResponse(response);
  response.creatorUpdatedAtSortKey = getSortKeyByCreator(keyInfo as BountyKeyInfo);
  response.bountyCreatedAtSortKey = getSortKeyByBountyForResponse(keyInfo as BountyKeyInfo);
  response.bountyPopularitySortKey = getSortKeyByBountyForResponse(keyInfo as BountyKeyInfo);
  response.bountyDiscussedSortKey = getSortKeyByBountyForResponse(keyInfo as BountyKeyInfo);

  if (response.recommendation && response.recommendation.applicant) {
    response.recommendation.applicantSortKey = getSortKeyByRecommendationApplicantForResponse(keyInfo as BountyKeyInfo);
  }

  return response;
};

export const getOutboundPriorityForResponse = (response: BountyResponse) => {
  const responseKeyInfo = forResponse(response);
  const isDraft = isDraftState(responseKeyInfo.state as BountyState);
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);

  return getOutboundPriority(isDraft, ts);
};

export const getParentOrderSortKey = (parentId: string, order: number) => {
  const MAX_VALUE = 2147483647;
  const tuple = {
    parentId,
    category: order < 0 ? KeyCategory.Important : KeyCategory.Normal,
    format: String(order < 0 ? MAX_VALUE + order : order).padStart(7, '0'),
  };

  return stitchParts(tuple);
};

import { AnyAction } from '@reduxjs/toolkit';
import { Actions } from 'Store/user/actions';
import {
  AddressType,
  CompanyType,
  Gender,
  PhoneStatus,
  SalaryType,
  UserKind,
  UserType,
  WorkExperienceDuration,
  EducationDegree,
} from 'Constants/enums';
import { UserData } from 'Types/userData.interface';
import { UserResponse } from 'Types/userResponse.interface';
import { CompanyPrivateData } from 'Types/companyPrivateData.interface';

interface State {
  source: UserData | {};
  data: UserResponse;
  companyPrivateData: CompanyPrivateData;
  isLoading: boolean;
  isRolesLoading: boolean;
}

const initialState: State = {
  data: {
    address: {
      line1: '',
      line2: '',
      phoneNumber: '',
      phoneStatus: PhoneStatus.Unverified,
      countryCode: '',
      city: '',
      state: '',
      postalCode: '',
      type: AddressType.Unknown,
    },
    avatar: '',
    citizenship: '',
    countryCode: '',
    dob: '',
    email: '',
    educationExperience: {
      certifications: [],
      didAttendSchool: false,
      highestGradeCompletedType: EducationDegree.AssociateDegree,
      schoolLocation: '',
      driversLicense: [],
    },
    firstName: '',
    gender: Gender.Other,
    id: '',
    name: '',
    lastName: '',
    latinizedName: '',
    languages: [],
    website: '',
    foreignLanguages: [],
    kind: UserKind.Unknown,
    nativeLanguage: null,
    mailingAddress: {
      line1: '',
      line2: '',
      phoneNumber: '',
      phoneStatus: PhoneStatus.Unverified,
      countryCode: '',
      city: '',
      state: '',
      postalCode: '',
      type: AddressType.Unknown,
    },
    middleName: '',
    orgType: CompanyType.Unknown,
    sameAddress: false,
    skillSetIds: [],
    socialNetworks: [],
    specialSituationInfo: { },
    jobProgramInfo: undefined,
    systemInfo: {
      blacklisted: false,
      createdAt: 0,
      createdBy: '',
      creatorId: '',
      lastLoginAt: undefined,
      pin: '',
      companyId: '',
      testUser: false,
      updatedAt: undefined,
      timezone: '',
      inviteCode: undefined,
      allowedRoles: [],
      appliedRoles: [],
    },
    type: UserType.Unknown,
    workExperience: {
      workExperienceYears: WorkExperienceDuration.Unknown,
      workplaceExperience: {},
    },
    attachments: [],
    medicalInfo: {},
  },
  companyPrivateData: {
    address: {
      id: '',
      type: AddressType.Unknown,
      line1: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: '',
    },
    fee: {
      commissionPercentage: 0,
      commissionType: SalaryType.Gross,
    },
    currencies: [],
    industryIds: [],
    usersCount: 0,
  },
  source: {},
  isLoading: false,
  isRolesLoading: false,
};

const userProfileReducer = (state: State = { ...initialState }, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case Actions.GetProfile: {
      const { systemInfo, ...otherData } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          systemInfo: {
            ...state.data.systemInfo,
            ...systemInfo,
          },
          ...otherData,
        },
      };
    }

    case Actions.GetPrivateData:
      return {
        ...state,
        companyPrivateData: {
          ...state.companyPrivateData,
          ...payload,
        },
      };

    case Actions.UpdateUser: {
      const { address, ...otherData } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          address: {
            ...state.data.address,
            ...address,
          },
          ...otherData,
        }
        ,
      };
    }

    case Actions.UpdateEnvMode:
      return {
        ...state,
        data: {
          ...state.data,
          systemInfo: {
            ...state.data.systemInfo,
            testUser: payload,
          },
        },
      };

    case Actions.UpdatePIN:
      return {
        ...state,
        data: {
          ...state.data,
          systemInfo: {
            ...state.data.systemInfo,
            pin: payload,
          },
        },
      };

    case Actions.UpdateInviteCode:
      return {
        ...state,
        data: {
          ...state.data,
          systemInfo: {
            ...state.data.systemInfo,
            inviteCode: payload,
          },
        },
      };

    case Actions.UpdateBlacklistStatus:
      return {
        ...state,
        data: {
          ...state.data,
          systemInfo: {
            ...state.data.systemInfo,
            blacklisted: payload,
          },
        },
      };

    case Actions.GetRoles:
      return {
        ...state,
        data: {
          ...state.data,
          systemInfo: {
            ...state.data.systemInfo,
            ...payload,
          },
        },
      };

    case Actions.Loading:
      return {
        ...state,
        isLoading: payload,
      };

    case Actions.RolesLoading:
      return {
        ...state,
        isRolesLoading: payload,
      };

    case Actions.Reset:
      return {
        ...state,
        ...initialState,
      };

    case Actions.GetSource:
      return {
        ...state,
        source: {
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default userProfileReducer;

import { FormattedMessage } from 'react-intl';
import { cloneDeep } from 'lodash';
import { notificationToast } from 'Utils/notificationToaster';

import {
  isLoadingAction,
  updateDataAsyncAction,
  updateSimpleDataSyncAction,
} from 'Store/genericActions';
import { convertObjToArray, streamSnapshotToArray } from 'Utils/helpers';

import {
  firebaseGetCompanySents,
  firebaseGetCompanySentSubsRef,
  firebaseGetCurrentUser,
  firebaseGetSentRef,
  firebaseGetSentSubsRef,
  firebaseGetUserCommentsRef,
  firebaseGetUserResponsesRef,
  getCommonBountyContentRef,
  getCompanySubCardsRef,
  getListStreamSubRef,
  getMySubCardsRef,
} from 'Services/FirebaseService';

import { GenericReducerProp } from 'Constants/genericReducerProp';
import { GenericReducerName } from 'Constants/genericReducerName';
import {
  BOUNTY_FLAGS_ROUTE,
  GET_STREAM_BY_LIST_ID_API,
  GET_USER_PUBLIC_STREAM_API,
  GET_USER_STREAM_API,
  REQUESTS,
} from 'Constants/apiRoutes';
import { postEvent, getApiData, putApiData } from 'Services/Api';
import { setListDef } from 'Utils/models/Lists';
import {
  getBountyKeyInfo,
  getOutboundPriority,
  getParentOrderSortKey,
  getPriority,
  getSortKeysObject,
} from 'Utils/keyUtils';
import { capitalize } from 'Utils/text';
import { getBountyUpdates } from 'Utils/bountyCreation';
import { buildAgentString } from 'Utils/appConfig';
import { adjustOriginRebounties, getOwnerId } from 'Services/bounty/CommonService';
import { SUBBOUNTIES_STATS } from 'Constants/bounty';
import { getQueueName } from 'Utils/queues';
import { updateIdentity } from 'Services/BaseService';
import { onProductAttachmentUpdate, onPromotionAttachmentUpdate, uploadBountyAttachments } from 'Services/UploadService';
import { attachmentsListSelector, removedAttachmentsSelector } from 'Store/attachments/selectors';
import { selectedProductsSelector, selectedPromotionsSelector } from 'Store/createBounty/selectors';
import { bountyDetails, userStreams } from 'Store/bounty/selectors';
import { authLoggedUserSelector } from 'Store/auth/selectors';
import { getQueryString } from 'Utils/general';
import {
  BadgeTypes,
  BountySocialNetworks,
  BountyState,
  BountyType,
  QueueAlias,
} from 'Constants/enums';
import { GenericActionType } from 'Constants/genericActionType';
import {
  getCompanyFolder,
  hasBadge,
  isNetworkLinkRequired,
  isOutboundPost,
  isRebounty,
  isSubBounty,
  setOutbound,
} from 'Utils/bounty';
import {
  isAllowedToClose,
  isAllowedToDelete,
  isAllowedToReject,
  isAllowedToRetract,
  isAllowedToSend,
  isAllowedToSimulate,
  isDraftState,
} from 'Utils/bountyState';
import { encryptDescription, parseEncryptedDescription } from 'Utils/yaml';
import { getAttachmentsWithIds } from 'Utils/attachments';
import { getMainImageForProduct } from 'Utils/product';
import { formatApiRoute } from 'Utils/api';

export const isSharedWithCompany = (bounty) => {
  const companyFolder = getCompanyFolder(bounty.type);

  if (!companyFolder) {
    return false;
  }

  switch (bounty.type) {
    case BountyType.Order:
      return !isMyBounty(bounty);
    default:
      return true;
  }
};

export const getOutboundRef = (bounty, user) => {
  const ownerId = bounty.parentBounty ? getOwnerId(bounty.parentBounty) : getOwnerId(bounty);

  if (isSubBounty(bounty)) {
    return isSharedWithCompany(bounty)
      ? firebaseGetCompanySentSubsRef(ownerId, getCompanyFolder(bounty.type)).child(bounty.parentBounty.id)
      : firebaseGetSentSubsRef(user.uid).child(bounty.parentBounty.id);
  }

  return isSharedWithCompany(bounty)
    ? firebaseGetCompanySents(ownerId, getCompanyFolder(bounty.type))
    : firebaseGetSentRef(user.uid);
};

/**
 * Get Bounty responses by id
 */
export const getBountyResponseById = (ownerId, bountyId, responseId) => (dispatch) => {
  dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.AnswerDetails, true));

  return new Promise((resolve) => (
    firebaseGetUserResponsesRef(ownerId)
      .child(bountyId)
      .child(responseId)
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();
        dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.AnswerDetails, result));
        dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.AnswerDetails, false));
        resolve(result);
      })));
};

export const getOutboundResponseById = (userId, responseId) => (dispatch) => {
  dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.AnswerDetails, true));

  return (
    new Promise((resolve) => (
      firebaseGetSentRef(userId)
        .child(responseId)
        .on('value', (dataSnapshot) => {
          const result = dataSnapshot.val();
          dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.AnswerDetails, result));
          dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.AnswerDetails, false));
          resolve(result);
        })))
  );
};

/**
 * Get Answers comments by response id
 */
export const getResponseCommentsById = (responseId) => (dispatch) => (
  firebaseGetUserCommentsRef()
    .child('responses')
    .child(responseId)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val());
      dispatch({
        type: GenericActionType.GetAnswersComments,
        payload: dataSnapshotVal,
      });
    })
);

export const updatePriority = (bounty, user) => {
  const ref = getOutboundRef(bounty, user).child(bounty.id);
  const priority = getPriority(bounty);

  ref.setPriority(priority);
};

export const updateBountyTimestamp = (bounty, user, timestamp) => {
  const bountyClone = { ...bounty };
  bountyClone.updatedAt = timestamp;

  updatePriority(bountyClone, user);
};

export const getOutboundBountyRefForStats = (bounty) => {
  const mySelf = firebaseGetCurrentUser();
  const ownerId = getOwnerId(bounty);

  return isSharedWithCompany(bounty)
    ? firebaseGetCompanySents(ownerId, getCompanyFolder(bounty.type)).child(bounty.id).child('bounty')
    : firebaseGetSentRef(mySelf.uid).child(bounty.id).child('bounty');
};

export const getOutboundBountyRef = (bounty) => {
  const mySelf = firebaseGetCurrentUser();

  if (isSharedWithCompany(bounty) || !bounty.creator || bounty.creator.id === mySelf.uid) {
    return getOutboundRef(bounty, mySelf).child(bounty.id).child('bounty');
  }

  return null;
};

export const updateSortKeys = (bounty) => {
  const bountyKeyInfo = getBountyKeyInfo(bounty);
  const sortKeys = getSortKeysObject(bountyKeyInfo);

  const newBounty = {
    ...bountyKeyInfo,
    id: bounty.id,
    type: bounty.type,
    parentBounty: bounty.parentBounty || null,
    owner: bounty.owner || null,
  };

  if (newBounty.outbound || newBounty.isOutbound) {
    const ref = getOutboundBountyRef(newBounty);

    if (ref) {
      return ref.update(sortKeys);
    }
  }

  return null;
};

const activateBounty = (bounty, user) => {
  const cloneBounty = { ...bounty };
  const activeState = { state: BountyState.Active };
  cloneBounty.createdAt = new Date().getTime();
  cloneBounty.state = BountyState.Active;

  adjustOriginRebounties({ bounty, isAdd: true });

  getOutboundBountyRef(bounty).update(activeState);
  updatePriority(cloneBounty, user);
  updateSortKeys(cloneBounty);

  postEvent(QueueAlias.ActivateBounty, { bountyId: cloneBounty.id, bountyType: cloneBounty.type });
};

const savePosting = ({ bounty, network, postingId }) => {
  // TODO: check if the first part is necessary
  const posting = {
    postingId,
    postedAt: new Date().getTime(),
  };

  getOutboundBountyRef(bounty)
    .child('posting')
    .child(network)
    .set(posting);
};

const shareToSocialNetwork = ({ bounty, network }) => {
  if (network === BountySocialNetworks.InApp) {
    savePosting({ bounty, network: BountySocialNetworks.Rebounty, postingId: null });
    postEvent(QueueAlias.PostBounty, { bountyId: bounty.id });
  }

  // TODO: implement the logic for the rest of possible networks
  return null;
};

const postBounty = ({ network, bountyData }) => {
  const { link, bounty } = bountyData;
  // TODO: check/implement BountyProducer && bountyData.link
  if (isNetworkLinkRequired(network) && !link) {
    // TODO: implement postBounty(@NonNull BountyProducer producer from
    // TODO: -> check if is necessary to implement this part now
    // @NonNull List<PostBountyOption> networks) from DefaultBountyActionListener
    return null;
  }

  return shareToSocialNetwork({ bounty, network, link });
};

const onPostBounty = ({ bounty, hideRebounty }) => {
  if (!hideRebounty) {
    return postBounty({ network: BountySocialNetworks.InApp, bountyData: { bounty } });
  }

  return notificationToast.warning('No network to post to');
};

export const requestBadge = (bounty, badgeType) => {
  postEvent(QueueAlias.RequestBadge, { bountyId: bounty.id, badgeType });
};

const removeBadge = (bounty, badgeType) => {
  postEvent(QueueAlias.RemoveBadge, { bountyId: bounty.id, badgeType });
};

export const onRemoveROR = (bounty) => () => {
  if (hasBadge(bounty, BadgeTypes.Official)) {
    return notificationToast.warning(<FormattedMessage id="bounty.alreadyHasOfficialResponse" />);
  }

  removeBadge(bounty, BadgeTypes.RequestOfficialResponse);
  return notificationToast.info(<FormattedMessage id="bounty.requestOfficialResponseCancelled" />);
};

export const onROR = (bounty) => () => {
  if (hasBadge(bounty, BadgeTypes.Official)) {
    return notificationToast.warning(<FormattedMessage id="bounty.alreadyHasOfficialResponse" />);
  }

  requestBadge(bounty, BadgeTypes.RequestOfficialResponse);
  return notificationToast.info(<FormattedMessage id="bounty.requestOfficialResponseConfirmation" />);
};

function getPostState(state) {
  return {
    attachments: attachmentsListSelector(state) || [],
    removedAttachments: removedAttachmentsSelector(state) || [],
    promotions: selectedPromotionsSelector(state) || [],
    products: selectedProductsSelector(state) || [],
    user: authLoggedUserSelector(state)?.data || {},
  };
}

/**
 * Create a bounty post with state DRAFT visible only on user sents
 * @param bounty {Object}
 * @param stateAttachments {Array} bounty attachments
 * @param ignoreAttachUpload {Boolean} ignores the uploading of the attachments
 * @returns {function(*): Promise<any>}
 */
export const createBounty = (bounty, stateAttachments, ignoreAttachUpload = false) => (async (dispatch, getState) => {
  const state = getState();
  const {
    attachments: storeAttachments, // TODO: remove this after all bounty type will use the state
    promotions,
    products,
    user,
  } = getPostState(state);
  const attachments = storeAttachments.length ? storeAttachments : stateAttachments;

  dispatch(updateSimpleDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalIsLoading, true));

  const bountyRef = getOutboundRef(bounty, user).push();
  const identity = await updateIdentity(bounty);

  const cloneBounty = {
    ...bounty,
    ...identity,
  };
  const now = new Date().getTime();

  cloneBounty.id = bountyRef.key;
  cloneBounty.createdAt = now;
  cloneBounty.updatedAt = now;
  cloneBounty.state = BountyState.Draft;
  cloneBounty.entityId = cloneBounty.id;

  cloneBounty.agentInfo = { createdOn: buildAgentString() };

  if (attachments && attachments.length) {
    cloneBounty.attachments = ignoreAttachUpload
      ? { attachments: getAttachmentsWithIds(attachments) }
      : await uploadBountyAttachments(cloneBounty, attachments, user);

    if (cloneBounty.type === BountyType.Product) {
      cloneBounty.product.imageUrl = getMainImageForProduct(cloneBounty.attachments);
    }

    if (cloneBounty.type === BountyType.Currency) {
      const attachmentsValues = Object.values(cloneBounty.attachments.attachments);
      const imageUrl = attachments.length > 0 ? attachmentsValues?.[0]?.url : '';
      const currency = {
        ...parseEncryptedDescription(cloneBounty.description),
        imageUrl,
      };

      cloneBounty.description.text = encryptDescription(`${currency.name} currency`, currency);
    }
  }

  if (promotions?.length) {
    cloneBounty.attachments = await onPromotionAttachmentUpdate(cloneBounty, promotions, user);
  }

  if (products?.length) {
    cloneBounty.attachments = await onProductAttachmentUpdate(cloneBounty, products, user);
  }

  bountyRef.setWithPriority({ bounty: cloneBounty }, getOutboundPriority(true, now));
  updateBountyTimestamp(cloneBounty, user, now);
  updateSortKeys(cloneBounty);

  if (isSubBounty(cloneBounty)) {
    const parentSortKey = getParentOrderSortKey(cloneBounty.parentBounty.id, -1); // -1 to be first in list on creation

    adjustBountyOutboundStat(cloneBounty.parentBounty, SUBBOUNTIES_STATS, 1);
    bountyRef.child('bounty').update({ orderInParentSortKey: parentSortKey });
  }

  dispatch(updateSimpleDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalIsLoading, false));

  return Promise.resolve(cloneBounty);
});

/**
 * The method use to publish a bounty post; this will set the state to ACTIVE
 * @param bounty {Object}
 * @param user {Object}
 * @returns {Function}
 */
export const onActivateBounty = (bounty, user) => {
  activateBounty(bounty, user);

  if (bounty.interactions && bounty.interactions.officialResponse) {
    if (hasBadge(bounty, BadgeTypes.Official)) {
      notificationToast.warning(<FormattedMessage id="bounty.alreadyHasOfficialResponse" />);
    } else {
      requestBadge(bounty, BadgeTypes.RequestOfficialResponse);
      notificationToast.info(<FormattedMessage id="bounty.requestOfficialResponseConfirmation" />);
    }
  }
  // TODO: need implementation -> check if is necessary to implement this part now
  // isSharePostMenuEnabled = false all the time?
  // if (isSharePostMenuEnabled) {
  //   return null;
  // }

  onPostBounty({ bounty, hideRebounty: isRebounty(bounty) });
};

/**
 *  Method used to edit a bounty
 * @param bounty {Object}
 * @param stateAttachments {Object}
 */
export const onEditBounty = ({ newBounty, oldBounty, stateAttachments, ignoreAttachUpload, stateRemovedAttachments }) => (
  async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        attachments: storeAttachments, // TODO: remove this after all bounty type will use the state
        removedAttachments: storeRemovedAttachments,
        promotions,
        products,
        user,
      } = getPostState(state);
      const attachments = storeAttachments.length ? storeAttachments : stateAttachments;
      const removedAttachments = stateRemovedAttachments?.length ? stateRemovedAttachments : storeRemovedAttachments;

      dispatch(updateSimpleDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalIsLoading, true));

      const editedAt = new Date().getTime();
      const bountyClone = cloneDeep(newBounty);
      const allowedAttachments = attachments?.filter((attach) => !attach.onlyAsUrl);

      if (oldBounty.type === BountyState.Rejected) {
        bountyClone.type = BountyState.Draft;
      }

      // TODO: improve how attachments are handle
      if (allowedAttachments?.length) {
        bountyClone.attachments = ignoreAttachUpload
          ? { attachments: getAttachmentsWithIds(allowedAttachments) }
          : await uploadBountyAttachments(oldBounty, allowedAttachments, user);

        if (bountyClone.type === BountyType.Product) {
          bountyClone.product.imageUrl = getMainImageForProduct(bountyClone.attachments, oldBounty);
        }
      } else {
        bountyClone.attachments = null;
      }

      removedAttachments?.forEach(({ id }) => removeAttachment(bountyClone, { id }));

      if (promotions?.length) {
        bountyClone.attachments = await onPromotionAttachmentUpdate(bountyClone, promotions);
      }

      if (products?.length) {
        bountyClone.attachments = await onProductAttachmentUpdate(oldBounty, products, user);
      }

      const { updates, changes } = getBountyUpdates(oldBounty, bountyClone);

      if (!Object.keys(updates).length) {
        notificationToast.info(`Nothing really changed in bounty ${bountyClone.id}`);
        return Promise.resolve();
      }

      if (!isDraftState(bountyClone.state)) {
        bountyClone.editedAt = editedAt;
        updates.editedAt = editedAt;
        changes.editedAt = editedAt;
      }

      getOutboundBountyRef(bountyClone)
        .update(updates);

      updateBountyTimestamp(bountyClone, user, editedAt);
      postEvent(QueueAlias.UpdateBounty, { bountyId: bountyClone.id, changes });

      dispatch(updateSimpleDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalIsLoading, false));

      return Promise.resolve(bountyClone);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

/**
 * Request access to bounty creation
 * @param cap {String}
 */
export const requestAccess = (cap) => () => {
  postEvent(QueueAlias.RequestAccess, { cap });
};

export const updateBountyState = async (bounty, state, isAdmin) => {
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;
  if (!isMine && !isAdmin) {
    return notificationToast.warning(<FormattedMessage id="label.youAreNotAllowedToClose" />);
  }

  const result = { state };

  // Update my bounty table
  await getOutboundBountyRef(bounty).update(result);
};

function getOutboundBountyStatsRef(bounty, statsType) {
  return getOutboundBountyRefForStats(bounty).child('stats').child(statsType.fieldName);
}

export function adjustBountyOutboundStat(bounty, statsType, diff) {
  const statsRef = getOutboundBountyStatsRef(bounty, statsType);

  statsRef.once('value', (dataSnapshot) => {
    const prevStat = dataSnapshot.val();
    const count = prevStat ? +prevStat : 0;

    statsRef.off();
    getOutboundBountyStatsRef(bounty, statsType).set(Math.max(count + diff, 0));
  });
}

export const retractBounty = (bounty) => () => {
  const bountyClone = cloneDeep(bounty);
  const isAllowedToPerformOp = isAllowedToRetract(bountyClone.state);

  if (!isAllowedToPerformOp) {
    return null;
  }

  adjustOriginRebounties({ bounty: bountyClone, isAdd: false });

  bountyClone.state = BountyState.Retracted;
  getOutboundBountyRef(bounty).child('state').set(BountyState.Retracted);
  postEvent(QueueAlias.RetractBounty, { bountyId: bountyClone.id });

  return Promise.resolve();
};

export const onDeleteBounty = (bounty) => {
  const me = firebaseGetCurrentUser();
  const bountyClone = { ...bounty };
  const isMine = bountyClone.creator && bountyClone.creator.id === me.uid;
  const isAllowedToPerformOp = isAllowedToDelete(bountyClone.state);

  if (!isAllowedToPerformOp || (!isSharedWithCompany(bounty) && !isMine)) {
    notificationToast.warning('You are not allowed to delete this post');
    return Promise.reject(new Error('You are not allowed to delete this post'));
  }

  getOutboundBountyRef(bountyClone).remove();
  postEvent(QueueAlias.DeleteBounty, { bountyId: bountyClone.id });

  if (bountyClone.parentBounty && bountyClone.parentBounty.id) {
    adjustBountyOutboundStat(bountyClone.parentBounty, SUBBOUNTIES_STATS, -1);
  }

  return Promise.resolve();
};

export const reportBounty = ({ bounty, reason }) => {
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;

  if (isMine) {
    return null;
  }

  postEvent(QueueAlias.ReportBounty, { bountyId: bounty.id, reportContentType: reason });
};

export const rejectBounty = ({ bounty, reason, onSuccess }) => {
  const isAllowedToPerformOp = isAllowedToReject(bounty.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(`Cannot reject when ${bounty.state}`);
  }

  postEvent(QueueAlias.RejectBounty, { bountyId: bounty.id, reason })
    .then(() => {
      notificationToast.info(<FormattedMessage id="bounty.reject.success" values={{ type: capitalize(bounty.type) }} />);

      if (onSuccess) {
        setTimeout(onSuccess, 1000);
      }
    })
    .catch(() => notificationToast.error(<FormattedMessage id="bounty.reject.failed" values={{ type: capitalize(bounty.type) }} />));
};

export const sendBountyToUser = ({ bounty, userId }) => {
  const isAllowedToPerformOp = isAllowedToSend(bounty.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(`Cannot send when ${bounty.state}`);
  }

  postEvent(QueueAlias.SendBounty, { bountyId: bounty.id, userId });
};

export const dismissBounty = ({ bounty, reason }) => postEvent(QueueAlias.DismissBounty, { bountyId: bounty.id, reason });

export const closeBounty = ({ bounty, isAdmin }) => {
  const isAllowedToPerformOp = isAllowedToClose(bounty.state);

  if (!isAllowedToPerformOp) {
    notificationToast.warning(`Cannot close when ${bounty.state}`);
  } else {
    updateBountyState(bounty, BountyState.Closed, isAdmin);
    postEvent(QueueAlias.CloseBounty, { bountyId: bounty.id });
  }

  return Promise.resolve();
};

export const moveBountyToList = ({ bounty, list }) => () => {
  const bountyClone = cloneDeep(bounty);
  const { listId, listCode } = setListDef(list);

  bountyClone.listId = listId;
  bountyClone.listCode = listCode;

  updateSortKeys(bountyClone);
  postEvent(QueueAlias.MoveBountyToList, { bountyId: bountyClone.id, listId, listCode });
  return Promise.resolve();
};

export const simulateSurvey = (bounty) => () => {
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;
  const isAllowedToPerformOp = isAllowedToSimulate(bounty.state);

  if (!isMine || !isAllowedToPerformOp) {
    return notificationToast.warning('Cannot simulate this post');
  }

  postEvent(QueueAlias.SimulateBounty, { bountyId: bounty.id });
};

export const listDistributionTargets = ({ bountyId = null, bountyType = null }) => {
  const action = getQueueName(QueueAlias.ListBountyDistributionTargets);
  const query = bountyId ? `bountyId=${bountyId}&bountyType=${bountyType}` : '';

  return new Promise((resolve, reject) => {
    getApiData(`${REQUESTS}/${action}?${query}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export function isMyBounty(bounty) {
  const me = firebaseGetCurrentUser();
  return bounty.creator && bounty.creator.id === me.uid;
}

export function removeAttachment(bounty, attachment) {
  // NB: do not make changes to the DB until we save
  // getOutboundBountyRef(bounty).child("attachments").child("attachments").child(attachment.getId()).removeValue();
  postEvent(QueueAlias.DeleteAttachment, { bountyId: bounty.id, attachmentId: attachment.id });
}

export const getOutboundSubbounties = (bounty) => {
  const user = firebaseGetCurrentUser();
  const ownerId = getOwnerId(bounty);
  const ref = isSharedWithCompany(bounty)
    ? firebaseGetCompanySentSubsRef(ownerId, getCompanyFolder(bounty.type))
    : firebaseGetSentSubsRef(user.uid);

  return ref.child(bounty.id);
};

export const getInboundSubbounties = (bounty) => {
  const user = firebaseGetCurrentUser();
  const ownerId = getOwnerId(bounty);
  const ref = isSharedWithCompany(bounty)
    ? getCompanySubCardsRef(ownerId, getCompanyFolder(bounty.type))
    : getMySubCardsRef(user.uid);

  return ref.child(bounty.id);
};

const getBountySubbountiesRef = (bounty) => {
  const ref = bounty.outbound ? getOutboundSubbounties(bounty) : getInboundSubbounties(bounty);

  return ref.orderByChild('bounty/orderInParentSortKey');
};

export const getSubBounties = (bounty, ownerId, listId) => (dispatch) => {
  dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.SubBounties, true));

  getBountySubbountiesRef(bounty)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = streamSnapshotToArray(dataSnapshot);

      if (dataSnapshotVal.length) {
        dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.SubBounties, dataSnapshotVal));
        dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.SubBounties, false));
      } else {
        getListStreamSubRef(ownerId, bounty.id, listId)
          .on('value', (dataSnapshot2) => {
            const dataSnapshotVal2 = streamSnapshotToArray(dataSnapshot2);

            if (dataSnapshotVal2.length) {
              dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.SubBounties, dataSnapshotVal2));
            }

            dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.SubBounties, false));
          });
      }
    });
};

export const updateOrderInParent = (parent = {}, list = []) => (
  new Promise((resolve, reject) => {
    if (parent.id) {
      for (let i = 0; i < list.length; i++) {
        const ref = getOutboundSubbounties(parent);
        const parentSortKey = getParentOrderSortKey(parent.id, i);

        ref.child(list[i].bounty.id)
          .child('bounty')
          .update({ orderInParentSortKey: parentSortKey })
          .catch(() => reject());

        postEvent(QueueAlias.UpdateBounty, { bountyId: parent.id, orderInParentSortKey: parentSortKey });
      }
      resolve();
    }

    reject();
  })
);

const getOutboundBountyById = (userId, bounty) => {
  const ref = getOutboundRef(bounty, { uid: userId });

  return new Promise((resolve) => (
    ref
      .child(bounty.id)
      .child('bounty')
      .on('value', (dataSnapshot) => {

        const bountyDetails = dataSnapshot.val();

        if (bountyDetails) {
          bountyDetails.metaInfo = setOutbound(bountyDetails.metaInfo, true);
          bountyDetails.outbound = isOutboundPost(bountyDetails.metaInfo);
        }

        resolve(bountyDetails);
      })
  ));
};

// Pass creatorId if need to search in fb first
export const fetchBounty = (bountyId, creatorId = null, bountyType) => {
  let options = {};
  if (creatorId) {
    options = { ...options, creatorId, fetchFromFb: !!creatorId, format: 'RAW' };
  }

  if (bountyType) {
    options.bounty_type = bountyType;
  }

  const queryString = getQueryString(options);

  return getApiData(`/api/v1/open/bounty_fetch/${bountyId}${queryString}`);
};

export const getBountyAny = (bounty) => (dispatch) => {
  const me = firebaseGetCurrentUser();
  dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, true));

  return new Promise((resolve, reject) => {
    getOutboundBountyById(me.uid, bounty)
      .then((result) => {
        if (result && result.id) {
          dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, result));
          dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, false));
          return resolve(result);
        }

        dispatch(getBountyCommonDetails(bounty))
          .then((result2) => {
            if (result2 && result2.id) {
              dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, false));
              resolve(result2);
            }
          });
      })
      .catch((error) => {
        dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, {}));
        dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, false));
        reject(error);
      });
  });
};

export const getBountiesByListId = (listId, queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: GET_STREAM_BY_LIST_ID_API,
    params: { listId },
    queryParams,
  });
  return getApiData(endpoint);
};

export const getUserBounties = (queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: GET_USER_STREAM_API,
    queryParams,
  });
  return getApiData(endpoint);
};

export const getUserPublicBounties = (queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: GET_USER_PUBLIC_STREAM_API,
    queryParams,
  });
  return getApiData(endpoint);
};

export const setBountyFlag = (bountyId, queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: BOUNTY_FLAGS_ROUTE,
    params: { bountyId },
    queryParams,
  });

  return putApiData(endpoint);
};

export const updateLocalBounty = (bounty) => (dispatch, getState) => {
  const state = getState();
  const localBounty = bountyDetails(state).data || null;
  const localBountyList = userStreams(state).data || null;

  if (localBounty) {
    return dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, bounty));
  }

  if (localBountyList) {
    const foundIndex = localBountyList.findIndex((item) => item?.bounty?.id === bounty.id || item?.id === bounty.id);

    if (foundIndex !== -1) {
      localBountyList[foundIndex] = 'bounty' in localBountyList[foundIndex] ? { bounty } : bounty;
      dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.UserStreams, localBountyList));
    }
  }
};

export const getBountyCommonDetails = (bounty) => (dispatch) => {
  const ref = getCommonBountyContentRef(bounty.id);
  dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, true));

  return new Promise((resolve, reject) => {
    ref
      .on('value', (dataSnapshot) => {

        const bountyDetails = dataSnapshot.val();
        if (bountyDetails && bountyDetails.id) {
          dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, bountyDetails));
          dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, false));
          return resolve(bountyDetails);
        }

        fetchBounty(bounty.id)
          .then((response) => {
            dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, response));
            dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, false));
            resolve(response);
          })
          .catch((error) => reject(error));
      }, (error) => {
        dispatch(updateDataAsyncAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, {}));
        dispatch(isLoadingAction(GenericReducerName.UserStreams, GenericReducerProp.BountyDetails, false));
        reject(error);
      });
  });
};

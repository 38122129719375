import { BadgeTypes, BadgeStyles } from 'Constants/enums';
import { Badge, BadgeInfo, Badges } from 'Types/badge.interface';

export const containsType = (badgeSource: Badges, badgeType: BadgeTypes): boolean => {
  if (!badgeSource || !badgeSource.stash || !Object.keys(badgeSource.stash).length) {
    return false;
  }

  return !!badgeSource.stash[badgeType];
};

export const getDisplayName = (badgeType: BadgeTypes, defaultLabel: string = ''): string => {
  if (!badgeType) {
    return '';
  }

  if (badgeType === BadgeTypes.Official) {
    return 'Official';
  }

  if (badgeType === BadgeTypes.RequestOfficialResponse) {
    return 'R.O.R';
  }

  return defaultLabel;
};

export const prepareBadgeStyle = (badgeType: BadgeTypes) => {
  if (badgeType === BadgeTypes.Official) {
    return BadgeStyles.Official;
  }

  return BadgeStyles.Ror;
};

export const hasBadge = (badges: Badges, badgeType: BadgeTypes) => badges.stash && badges.stash[badgeType];

export const addBadge = (badges: Badges, badgeInfo: BadgeInfo) => {
  const stash = badges.stash || {};
  const badgeStash = stash[badgeInfo.code];

  if (badgeStash) {
    badgeStash.count++;
  } else {
    stash[badgeInfo.code] = {
      count: 1,
      info: badgeInfo,
    };
  }

  return {
    ...badges,
    stash,
  };
};

export const removeBadge = (badges: Badges, badgeCode: string) => {
  const stash = badges.stash || {};

  if (stash) {
    delete stash[badgeCode];
  }

  if (stash && !Object.keys(stash).length) {
    return { stash: null };
  }

  return { stash };
};

export const negateBadge = (badge: Badge) => ({
  ...badge,
  negative: badge.negative === null || !badge.negative ? true : null,
});

const getCode = (info: BadgeInfo) => (info ? info.code : null);

export const badgeToString = (badge: Badge) => `${getCode(badge.info)}:${badge.id}`;

import { FC } from 'react';
import { MenuItem } from '@mui/material';
import { PHONE_CODE_OPTIONS } from 'Constants/dictionaries';
import StyledSelect from 'Components/StyledSelect';
import CountryPreview from 'Components/CountryPreview';

interface PhoneCodePickerProps {
  className?: string;
  disabled?: boolean;
  value: string;
  validationErrors: string[];
  onChange: (value: string) => unknown;
}

const PhoneCodePicker: FC<PhoneCodePickerProps> = ({
  className = '', disabled = false, value = '', validationErrors = [], onChange,
}) => (
  <StyledSelect
    className={className}
    disabled={disabled}
    onChange={(e) => onChange(e.target.value as string)}
    value={value}
    validationErrors={validationErrors}
    // TODO: [types] bug in MUI https://github.com/mui/material-ui/issues/34083
    renderValue={(value) => (
      <>
        <CountryPreview iconOnly countryCode={value as string} />
        {PHONE_CODE_OPTIONS.find((o) => o.value === value)?.shortLabel}
      </>
    )}
  >
    {PHONE_CODE_OPTIONS.map(({ label, value }) => (
      <MenuItem key={value} value={value}>
        <CountryPreview iconOnly countryCode={value} />
        {label}
      </MenuItem>
    ))}
  </StyledSelect>
);

export default PhoneCodePicker;

import intersection from 'lodash/intersection';
import { ProductConfig } from 'Utils/productConfig';
import { getAvailableBountyTypes, isAllowed as isOpAllowed } from 'Utils/settings';
import * as IdentityMode from 'Utils/identityMode';
import { CTRL_IDENTITY } from 'Constants/op';
import { Settings } from 'Types/settings.interface';
import { UserData } from 'Types/userData.interface';
import { BountyType } from 'Constants/enums';

/**
 * Check if a user is allowed to do a certain operation
 * @param op {String}
 * @param userData {Object}
 * @param companySettings {Object}
 * @returns {boolean}
 */
const isAllowed = ({ op, settings, userData }: { op: string; settings: Settings; userData: UserData }) => (
  isOpAllowed({ op, settings, userData })
);

const isBountyTypeAllowed = ({ bountyType, settings, userData }: { bountyType: BountyType; settings: Settings; userData: UserData }) => (
  getAvailableBountyTypes(settings, userData).includes(bountyType)
);

const getIdentityMode = (settings: Settings, userData: UserData, bounty = null) => { // eslint-disable-line
  const { companySettings } = settings || {};
  const threadMode = null; // FIXME: bounty != null ? bounty.getIdentityMode() : null;
  const modes = [threadMode, companySettings?.identityMode, ProductConfig.identityMode];
  const itemMode = IdentityMode.mergeAll(modes);

  if (itemMode.forced) {
    if (isOpAllowed({ userData, op: CTRL_IDENTITY.name, settings })) {
      itemMode.forced = false;
    }
  }

  return itemMode;
};

const isAllowedByRole = (roles: string[], userRoles: string[], defAllow: boolean) => {
  if (!roles || !roles.length) {
    return defAllow;
  }
  const includedRoles = intersection(roles, userRoles);

  return includedRoles.length > 0;
};

export {
  isAllowed,
  isBountyTypeAllowed,
  getIdentityMode,
  isAllowedByRole,
};

import {
  COMPANY_CREATE_ROUTE,
  NOT_FOUND,
  PROMOTION_DETAILS_ROUTE,
  STREAM_SUB_DETAILS_ROUTE,
  STREAM_DETAILS_WITH_TYPE_ROUTE,
  USER_SENTS_DETAILS_ROUTE,
  USER_SENTS_SUB_DETAILS_ROUTE,
  JOB_EDIT_ROUTE,
} from 'Constants/routes';
import { companyDialogOptionsConfig } from 'Utils/createOrgOptionsDialog';
import { AppConfig } from 'Utils/appConfig';
import { isSubBounty } from 'Utils/bounty';
import { BountyType, CompanyType, StreamListKind } from 'Constants/enums';
import { Bounty } from 'Types/bounty.interface';
import { StreamList } from 'types/streamList.interface';

export function generateLink(route: string, params?: Record<string, string>) {
  let formattedRoute = route;

  if (params) {
    Object.keys(params).forEach((key) => {
      formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
    });
  }

  return formattedRoute;
}

export const generateMenuOptionLink = (type: BountyType | CompanyType) => {
  if (companyDialogOptionsConfig[type as keyof typeof companyDialogOptionsConfig]) {
    return generateLink(COMPANY_CREATE_ROUTE, { orgType: type }).replace('*', 'new');
  }

  return NOT_FOUND;
};

const appendListId = (link: string, list?: StreamList) => {
  if (!list || list?.kind !== StreamListKind.ListStream || !list.id) {
    return link;
  }

  return `${link}?list=${list.id}`;
};

export function generateLinkForBountyDetails(bounty: Bounty, params: Record<string, string> = {}, isOutboundCard = false, list?: StreamList) {
  if (!bounty) return null;

  const { id, type, parentBounty } = bounty;
  const subBounty = isSubBounty(bounty);
  const bountyType = type || 'bounty';

  if (subBounty && parentBounty?.id) {
    const routeParams: Record<string, string> = { bountyType, bountyId: parentBounty?.id, subBountyId: id };
    const link = generateLink(isOutboundCard ? STREAM_SUB_DETAILS_ROUTE : USER_SENTS_SUB_DETAILS_ROUTE, routeParams);
    return appendListId(link, list);
  }

  if (!params || (params && (!Object.keys(params).length || params.bountyId !== bounty.id))) {
    let streamRoute = isOutboundCard ? USER_SENTS_DETAILS_ROUTE : STREAM_DETAILS_WITH_TYPE_ROUTE;

    if (bounty.type === BountyType.Promotion) {
      streamRoute = PROMOTION_DETAILS_ROUTE;
    }

    if (bounty.type === BountyType.Job) {
      streamRoute = JOB_EDIT_ROUTE;
    }

    const link = generateLink(streamRoute, { bountyType, bountyId: bounty.id });

    return appendListId(link, list);
  }

  return null;
}

export function getBaseUrl() {
  const { appLinkSchema, appTld } = AppConfig;
  return `${appLinkSchema}://${appTld}`;
}

export function getPromotionDetailsLink(promotionId: string) {
  return `${getBaseUrl()}/promotions/${promotionId}`;
}

export function getBountyDetailsLink(bountyId: string, type: string) {
  return `${getBaseUrl()}/bounties/${bountyId}/type/${type}#details`;
}

export const getCampaignsIOSApp = (isTestingApp: boolean) => {
  if (isTestingApp) {
    return AppConfig?.custom?.campaignTestIOS;
  }

  return AppConfig?.custom?.campaignTestIOS;
};

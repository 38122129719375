import moment from 'moment';
import { BountyType } from 'Constants/enums';

import 'moment/locale/ru';
import 'moment/locale/ro';
import 'moment/locale/pl';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/ka';

import { LANGUAGES } from 'Constants/languages';
import { TranslationItem } from 'Types/translationItem.interface';
import { DictionaryItem } from 'Types/dictionaryItem.type';
import { CombinedTranslationItem } from 'Types/combinedTranslationItem.interface';

/**
 * @typedef {Object} DictionaryItem
 * @type {Object}
 * @property {string} value Language name
 * @property {string} label Language code
 *
 */

/**
 * @typedef {Object} Translation
 * @type {Object}
 * @property {string} code Language code
 * @property {string} text Translated text
 */

export const regExpSeparator = new RegExp(/<!-- *lang: *(([A-Za-z]){2}) *-->/g);

/**
 * Generates language separator based by passed short code
 * @param {string} code Short (2-letters) language code
 *
 * @returns {string}
 */
export const generateTranslationSeparator = (code: string) => `<!-- lang: ${code} -->`;

/**
 * This function parse input text and split it by language codes
 * @param {string} text Text to be parsed
 *
 * @returns {TranslationItem[]} An array of objects with a language code as a key and translated text as a value.
 */

export const textToTranslations = (text: string = ''): TranslationItem[] => {
  const trimmedText = text.trim();
  const separators = [];
  const langCodes = [];

  let n;
  // eslint-disable-next-line no-cond-assign
  while (n = regExpSeparator.exec(trimmedText)) {
    separators.push(n[0]);
    langCodes.push(n[1].toLowerCase());
  }

  const translations = separators.length ? trimmedText
    .split(new RegExp(separators.join('|'), '')) : [trimmedText];

  // Lead language case.
  if (translations[0] === '') {
    translations.shift();
  }

  return langCodes
    .map((code, index) => ({ code, text: translations[index].trim() }));
};

/**
 * This function format united text from translations
 * @param {TranslationItem[]} translations Array of Translations to be updated
 * @returns {string} String of united Translation
 */
export const translationsToText = (translations: TranslationItem[]) =>
  translations
    .map((t) => `${generateTranslationSeparator(t.code)} \n\n${t.text}`)
    .join('\n\n');

/**
 * This function return a translated string by preferred language. If there is no preferred language in the list,
 * lead language will be returned. Otherwise there will be a source text as function result
 * @param {string} text Text to be parsed
 * @param {string} preferredLanguageCode Requested translation language code
 * @returns {string} Translated text
 */

export const getPreferredLanguageText = (text: string, preferredLanguageCode: string = 'en') => {
  const translations = textToTranslations(text || '');
  const preferredTranslation = translations
    .find(({ code }) => code === preferredLanguageCode);

  return preferredTranslation?.text || translations[0]?.text || text;
};

/**
 * This function return a language codes for translations with empty text.
 * @param {string} text Text to be parsed
 * @returns {TranslationItem[]} Array of empty translations
 */
const getEmptyTranslationsFromText = (text: string) => textToTranslations(text || '')
  .filter((t) => !t.text);

/**
 * This function compare languages by name.
 * @param {DictionaryItem} itemA
 * @param {DictionaryItem} itemB
 * @returns {number} Number
 */
export const langSortFunction = (itemA: DictionaryItem, itemB: DictionaryItem) =>
  (itemA.label).localeCompare(itemB.label);

export const getLanguageNameByCode = (code: string) => LANGUAGES
  .find((lang) => lang.value === code)?.label || code;

/**
 * This function convert translations to a sorted dropdown dictionary, where label is a full language name.
 * @param {TranslationItem[]} translations Array of Translations to be converted.
 * @returns {DictionaryItem[]} Languages dictionary
 */
export const getDictionaryFromTranslations = (translations: TranslationItem[]): DictionaryItem[] =>
  translations
    .map(({ code }) => ({ label: getLanguageNameByCode(code), value: code }))
    .sort(langSortFunction);

/**
 * This function return a list of languages names which translations are empty.
 * @param {string} text Text to be parsed.
 * @returns {string[]} Arrays of full languages names.
 */
export const getEmptyLanguagesFromText = (text: string) => {
  const translations = getEmptyTranslationsFromText(text);

  return getDictionaryFromTranslations(translations)
    .map(({ label }) => label);
};

/**
 * Convert 4-letters language codes to 2-letters.
 * @param {string} fullLanguageCode Full language code aka 'en-US'
 * @returns {string} Short language code aka 'en' or empty string if code is invalid
 */
export const getShortLangCode = (fullLanguageCode: string): string => {
  try {
    return new Intl.Locale(fullLanguageCode).language;
  } catch {
    return '';
  }
};

/**
 * Get translation text by code from the translations array.
 * @param {TranslationItem[]} arr Array of translations
 * @param {string} code Language code
 * @returns {string} Translation text
 */
export const getTranslationByCode = (arr: TranslationItem[], code: string): string =>
  arr.find((t) => t.code === code)?.text || '';

/**
 * Get sections of translations. This function parse title and description and combine that into an objects in array.
 * @typedef {Object} CombinedTranslation
 * @type {Object}
 * @property {string} title Title text
 * @property {string} description Description text
 * @property {string} code Language code
 *
 * @param {string} title Title text
 * @param {string} description Description text
 * @returns {CombinedTranslationItem[]} Array of objects like { code: '...', title: '...', description: '...' }
 */
export const getCombinedTranslations = (title: string, description: string) => {
  const titleTranslations = textToTranslations(title);
  const descriptionTranslations = textToTranslations(description);

  const allCodes = [...titleTranslations, ...descriptionTranslations]
    .map(({ code }) => code);

  return allCodes
    .filter((c, index) => allCodes.indexOf(c) === index) // Remove duplicates
    .map((code) => ({
      code,
      title: getTranslationByCode(titleTranslations, code),
      description: getTranslationByCode(descriptionTranslations, code),
    }));
};

/**
 * Convert combined sections of translations to title and description text with separators.
 *
 * @typedef {Object} TitleAndDescriptionObj
 * @property {string} title Title text
 * @property {string} description Description text
 *
 * @param {CombinedTranslationItem[]} combinedTranslations Array of combined translations
 * @returns {TitleAndDescriptionObj} Object like { title: '...', description: '...' }
 */
export const combinedTranslationsToText = (combinedTranslations: CombinedTranslationItem[]) => {
  const title = translationsToText(combinedTranslations.map((t) => ({ code: t.code, text: t.title })));
  const description = translationsToText(combinedTranslations.map((t) => ({ code: t.code, text: t.description })));
  return { title, description };
};

/**
 * Changes date format according to locale language
 *
 * @param {string} locale Current locale
 */
export const changeLocaleDate = (locale: string) => moment?.locale(locale);

/**
 * Replace language code in refs by {langCode} pattern.
 *
 * @param {string} str String to replace
 * @param {string} langCode Language code
 *
 * @returns {string} Ref with correct language code
 */
export const replaceLangCode = (str: string, langCode: string = 'en') => str?.replace('{langCode}', langCode);

/**
 * @param {Translation} intl
 * @param {string} id
 * @returns {boolean}
 */

export const getBountyShareMessageByBountyType = (bountyType: BountyType) =>
  `bountyAction.title.share.confirmation.${bountyType?.toLowerCase()}`;

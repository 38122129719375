import { ReportFiltersField } from 'Constants/enums';
import ReportsService from 'Services/ReportsService';
import { getClientId } from 'Utils/api';

export const ACTIONS = {
  SET_IS_LOADING: 'reportsReducer/setIsLoading',
  SET_REPORT: 'reportsReducer/setReport',
  UPDATE_FILTERS: 'reportsReducer/updateFilters',
  RESET_REDUCER: 'reportsReducer/resetReducer',
};

export const updateFilters = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.UPDATE_FILTERS,
    payload,
  });
};

export const resetReportsReducer = () => (dispatch) => {
  dispatch({ type: ACTIONS.RESET_REDUCER });
};

export const fetchReport = (filters) => async (dispatch) => {
  const { selectedReportType, userFilter, selectedScopedReportType } = filters;
  const clientId = getClientId();

  const body = {
    reportId: selectedReportType,
    subType: selectedScopedReportType,
    fromDateString: filters[ReportFiltersField.FromDate],
    toDateString: filters[ReportFiltersField.ToDate],
    userFilter,
    format: 'json',
    clientId: clientId || null,
  };

  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  try {
    const response = await ReportsService.getReport(body);
    dispatch({ type: ACTIONS.SET_REPORT, payload: response });
  } catch (e) {
    dispatch({
      type: ACTIONS.SET_REPORT,
      payload: {
        header: [],
        data: [],
      },
    });
  } finally {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, Typography, TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import MemberAvatar from 'Components/MemberAvatar';
import { userDataSelector } from 'Store/settings/selectors';
import { firebaseGetGroupMembers } from 'Services/FirebaseService';
import { convertObjToArray } from 'Utils/helpers';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import { User } from 'Types/user.interface';
import * as styles from './index.module.scss';

interface BehalfUser {
  user: User;
}

interface BountyOnBehalfOfProps {
  handleOnBehalfOf: (user: BehalfUser | null) => void;
  onBehalfOf?: BehalfUser | null;
}

const BountyOnBehalfOf:FC<BountyOnBehalfOfProps> = ({ handleOnBehalfOf, onBehalfOf }) => {
  const intl = useIntl();
  const [users, setUsers] = useState<BehalfUser[]>([]);

  const userData = useSelector(userDataSelector)?.data || {};

  const mySelf = {
    user: {
      ...userData.myself,
      name: 'Myself',
    },
  };

  useEffect(() => {
    if (userData?.owner) {
      firebaseGetGroupMembers(userData.owner.id)
        .orderByChild('memberRoles')
        .once('value', (dataSnapshot) => {
          const myId = userData.myself.id;
          const result = dataSnapshot.val();
          delete result[myId];

          setUsers([mySelf, ...convertObjToArray(result)]);
        });
    }
  }, []);

  const handleSelection = (e: SyntheticEvent, newValue: BehalfUser | null) => {
    handleOnBehalfOf(newValue);
  };

  return (
    <div className={styles.wrapper}>
      <Typography className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: 'label.onBehalfOf' })}
      </Typography>
      <Autocomplete
        options={users}
        value={onBehalfOf}
        onChange={handleSelection}
        getOptionLabel={(option) => option?.user?.name || ''}
        isOptionEqualToValue={(option, value) => option?.user?.id === value?.user?.id}
        renderOption={(autocompleteProps, option) => (
          <li
            {...autocompleteProps}
            key={option?.user?.id}
            className={styles.autocompleteOption}
          >
            <MemberAvatar user={option?.user} className={styles.avatarColor} />
            <Typography className={styles.username}>
              {option?.user?.name}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className={styles.control}
          />
        )}
      />
    </div>
  );
};

export default BountyOnBehalfOf;

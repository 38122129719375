import { Scrollbars } from 'react-custom-scrollbars';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';

import { string, object, func } from 'prop-types';
//  Other resources
import { getProductBackgroundImage, textTruncate } from 'Utils/helpers';
import { formatReward } from 'Utils/currency';
import { CART_ROUTE } from 'Constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductKind } from 'Constants/enums';
import { useContext } from 'react';
import { LanguageContext } from 'Contexts/languagesContext';

const propTypes = {
  products: object.isRequired,
  cartTotal: string.isRequired,
  closeCart: func.isRequired,
};

const CartContent = ({ products, cartTotal, closeCart }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const location = useLocation();
  const { locale } = useContext(LanguageContext);

  const renderProduct = (product) => {
    const { id, quantity, kind, onlineProductInfo } = product;
    const price = formatReward(product.price);
    const backgroundImage = getProductBackgroundImage(product);
    const tokensQty = onlineProductInfo?.tokensQty?.toLocaleString(locale);
    const formattedName = kind === ProductKind.Token
      ? `${product.name}: ${intl.formatMessage({ id: 'labels.quantityTokens' }, { quantity: tokensQty })}`
      : product.name;

    return (
      <li className="d-flex justify-content-between p-3" key={id}>
        <div className="media overflow-hidden w-75 mr-1">
          <div
            style={{ ...backgroundImage, height: '50px', width: '50px' }}
            className="image-post product-image-placeholder mr-15"
          />
          <div className="media-body">
            <span className="fs-14 d-block black-color">
              {textTruncate(formattedName, 25)}
            </span>
            <span className="fs-12 d-block text-muted">
              {textTruncate(product.description, 50)}
            </span>
          </div>
        </div>
        <div className="text-center">
          <span className="text-muted fs-12 d-block mb-10">
            {price}
            {' X '}
            {quantity}
          </span>
        </div>
      </li>
    );
  };

  const onGoToCheckout = () => {
    closeCart();

    if (location.pathname !== CART_ROUTE) {
      navigate(CART_ROUTE);
    }
  };

  return (
    <>
      <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280} autoHide>
        <ul>
          {Object.values(products).map((item) => (
            renderProduct(item)
          ))}
        </ul>
      </Scrollbars>
      <div className="d-flex justify-content-between align-items-center p-3">
        <Button
          variant="contained"
          color="primary"
          className="mr-30 btn-xs text-white"
          onClick={onGoToCheckout}
        >
          <FormattedMessage id="cart.checkout" />
        </Button>
        <span className="fw-normal text-dark font-weight-bold font-xs">
          <FormattedMessage id="cart.totalPrice" values={{ totalPrice: cartTotal }} />
        </span>
      </div>
    </>
  );
};

CartContent.propTypes = propTypes;

export default CartContent;

import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import * as styles from './index.module.scss';

interface StatusLabelProps {
  label?: string|ReactNode,
  text?: string,
  icon?: string,
  color?: string,
  className?: string,
  fontWeight?: string;
  labelStyle?: Record<string, string | number>;
}

const StatusLabel:FC<StatusLabelProps> = ({
  label,
  text,
  icon,
  color,
  className = '',
  fontWeight = 'font-weight-bold',
  labelStyle = {},
}) => {
  const labelToDisplay = label || text || '';
  const iconStyle = color ? { color } : {};

  return (
    <>
      {!!icon && <span className="material-symbols-rounded vertical-align-bottom" style={iconStyle}>{icon}</span>}
      {labelToDisplay && (
        <span className={classnames(styles.label, fontWeight, styles[className])} style={labelStyle}>
          {labelToDisplay}
        </span>
      )}
    </>
  );
};

export default StatusLabel;

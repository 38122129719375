import { Currency } from 'Constants/enums';
import { currencySymbols } from 'Constants/currency';
import { isNil } from 'lodash';
import { Money } from 'Types/money.interface';
import { Reward } from 'Types/reward.interface';
import { getFormattedNumber } from "./formatters";

export const moneyConstructor = (amount = null, currency = null) => ({
  amount: amount !== null && amount !== undefined ? amount : null,
  currency: currency !== null && currency !== undefined ? currency : null,
});

// TODO: refactor after found a library for bigDecimal
export const getAmountAsBigDecimal = (amount: string, enforceDecimal?: boolean) => {
  if (!amount || amount === '0' || amount === '0.0') {
    // return BigDecimal.ZERO;
    return '0';
  }

  try {
    const floatAmount = Number.parseFloat(amount);

    if (enforceDecimal) {
      return floatAmount.toFixed(2);
    }

    return (
      Number.isInteger(floatAmount)
        ? floatAmount.toString()
        : floatAmount.toFixed(2)
    );
  } catch {
    return '0';
  }
};

export const isZero = (money: Money) => !money.amount || money.amount === '0';

export const formatMoney = (reward: Reward) => {
  if (!reward) {
    return '';
  }

  const { money, points } = reward;

  if (!isNil(money?.amount)) {
    const { amount, currency } = money;
    const formattedAmount = getFormattedNumber({ value: +amount, enforceDecimal: true });

    if (currency === Currency.None) {
      return formattedAmount;
    }

    if (currencySymbols[currency]) {
      return `${currencySymbols[currency]} ${formattedAmount}`;
    }

    return `${formattedAmount} ${currency}`;
  }

  if (points?.POINT) {
    return `${points.POINT.amount} ${+points.POINT.amount > 1 ? 'points' : 'point'}`;
  }

  return '';
};

export const addMoney = (existingMoney?: Money, moneyToAdd?: Money): Money|null => {
  if (!existingMoney) {
    return moneyToAdd || null;
  }

  if (!moneyToAdd || !moneyToAdd.amount) {
    return existingMoney;
  }

  if (existingMoney.currency !== moneyToAdd.currency) {
    //  TODO: needs to be implemented
    return null;
    // amt = amt.convertTo(getCurrencyAsEnum());
    // if (amt == null) {
    //   return null;
    // }
  }

  if (!existingMoney.amount) {
    return moneyToAdd;
  }

  return {
    amount: (+existingMoney.amount + +moneyToAdd.amount).toString(),
    currency: existingMoney.currency,
  };
};

export const negateMoney = (money: Money): Money => {
  const { amount, currency } = money;

  if (amount == null) {
    return money;
  }

  const decimal = getAmountAsBigDecimal(amount);

  if (decimal === 0) {
    return money;
  }

  return {
    amount: (-decimal).toString(),
    currency,
  };
};

export const subtractMoney = (a?: Money, b?: Money) => {
  if (isNil(b)) {
    return a;
  }

  if (isNil(a)) {
    return negateMoney(b);
  }

  return subtract(a, b);
};

const subtract = (a: Money, b: Money) => {
  if (isNil(b.amount) || getAmountAsBigDecimal(b.amount) === 0) {
    return { amount: a.amount, currency: a.currency };
  }

  if (a.currency !== b.currency) {
    return null;
  }

  const decimal = getAmountAsBigDecimal(a.amount || '');

  if (decimal === 0) {
    return negateMoney(b);
  }

  const diff = +decimal - +getAmountAsBigDecimal(b.amount || '');

  return {
    amount: diff.toString(),
    currency: a.currency,
  };
};

export const moneyToString = ({ amount, currency }: Money) => `${amount} ${currency}`;

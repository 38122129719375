import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import CustomModalButtons from 'Components/CustomModalButtons';
import CustomInput from 'Components/CustomInput';

import { closeModal } from 'Store/modal/actions';
import { updateAccount } from 'Services/UserService';

import { pointCurrencyAsEnum, negate } from 'Utils/points';
import { PointCurrency } from 'Constants/enums';
import { notificationToast } from 'Utils/notificationToaster';
import { ThunkDispatchType } from 'Types/redux.types';

interface UpdateAccountRewardProps {
  userId: string;
}

const UpdateAccountReward:FC<UpdateAccountRewardProps> = ({ userId }) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();
  const [adjustments, setAdjustments] = useState('');

  const getAdjustment = (adjustment: string) => {
    if (!adjustment) {
      return null;
    }

    const firstChar = adjustment[0];

    if (!(firstChar === '-' || firstChar === '+' || firstChar === '=')) {
      return null;
    }

    const parts = adjustment.substring(1).trim().split(' ');

    if (parts.length !== 2) {
      return null;
    }

    const amount = +parts[0];

    if (Number.isNaN(amount)) {
      return null;
    }

    const currency = pointCurrencyAsEnum(parts[1]);

    if ([PointCurrency.None, PointCurrency.Unknown].includes(currency)) {
      return null;
    }

    let pt = { amount, currency };

    if (firstChar === '-') {
      pt = negate(pt);
    }

    const reward = {
      points: { [pt.currency]: pt },
    };

    return { set: firstChar === '=', reward };
  };

  const handleInputChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setAdjustments(value);

  const updateAccountOnSubmit = () => {
    const adjustment = adjustments && getAdjustment(adjustments.trim());

    if (!adjustment) {
      return notificationToast.error(intl.formatMessage({ id: 'update_account_nada' }));
    }

    updateAccount({
      userId, adjSet: adjustment.set, adjReward: adjustment.reward, note: null,
    });
    dispatch(closeModal());
  };

  return (
    <form onSubmit={updateAccountOnSubmit}>
      <CustomInput
        name="description"
        autoFocus
        value={adjustments}
        placeholder={intl.formatMessage({ id: 'update_account_hint' })}
        onChange={handleInputChanges}
      />
      <input type="submit" hidden />
      <CustomModalButtons
        onDismiss={() => dispatch(closeModal())}
        onSubmit={updateAccountOnSubmit}
      />
    </form>
  );
};

export default UpdateAccountReward;

import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Call, Device } from '@twilio/voice-sdk';
import RTCSample from '@twilio/voice-sdk/es5/twilio/rtc/sample';
import CallsService from 'Services/CallsService';
import { callActiveNameSelector, callActiveNumberSelector, isDeviceInitializedSelector } from 'Store/call/selectors';
import { callEnd, deviceInit } from 'Store/call/actions';
import { notificationToast } from 'Utils/notificationToaster';
import { secondsToDurationStr } from 'Utils/date';
import { ThunkDispatchType } from 'Types/redux.types';
import * as styles from './index.module.scss';

/* eslint-disable no-console */
const PhoneDialer: FC = () => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const callActiveNumber = useSelector(callActiveNumberSelector);
  const callActiveName = useSelector(callActiveNameSelector);
  const isDeviceInitialized = useSelector(isDeviceInitializedSelector);

  const [device, setDevice] = useState<Device | null>(null);
  const [call, setCall] = useState<Call | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [startTimeStamp, _setStartTimeStamp] = useState<number | null>(null);
  const [endTimeStamp, setEndTimeStamp] = useState<number | null>(null);
  const [duration, setDuration] = useState<number>(0);

  // To make latest state visible in handler
  const startTimeStampRef = useRef(startTimeStamp);

  const setStartTimeStamp = (data: number | null) => {
    startTimeStampRef.current = data;
    _setStartTimeStamp(data);
  };

  useEffect(() => {
    if (!isDeviceInitialized) {
      initDevice();
    }
  }, [isDeviceInitialized]);

  useEffect(() => {
    if (callActiveNumber) {
      handleCall(callActiveNumber);
    }
  }, [callActiveNumber]);

  const initDevice = () => {
    CallsService.getTwilioAccessToken()
      .then((r) => {
        const { jwt } = r;
        if (jwt && !device) {
          const d = new Device(jwt, {
            logLevel: 5,
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          });

          setDevice(d);

          dispatch(deviceInit());
        }
      })
      .catch(() => {
        console.log('Could not get a token from server!');
      });
  };

  const handleSampleChange = (sample: RTCSample) => {
    if (startTimeStampRef.current === null) {
      setStartTimeStamp(sample.timestamp);
    }

    setEndTimeStamp(sample.timestamp);
  };

  const addEventListeners = (callInstance: Call) => {
    if (callInstance) {
      callInstance.on('disconnect', handleCallFinish);

      callInstance.on('error', (error) => {
        console.info(error.message, error.code);
        notificationToast.error(intl.formatMessage({ id: 'errors.somethingWentWrong' }));
      });

      callInstance.on('mute', (isMuted) =>
        notificationToast.info(intl.formatMessage({ id: isMuted ? 'notification.call.muted' : 'notification.call.unmuted' })));

      callInstance.on('reconnected', () =>
        notificationToast.success(intl.formatMessage({ id: 'notification.call.reconnect' })));

      callInstance.on('warning', (warningName) => {
        if (warningName === 'low-mos') {
          notificationToast.success(intl.formatMessage({ id: 'notification.call.poorQuality' }));
        }
      });

      callInstance.on('reconnecting', () =>
        notificationToast.error(intl.formatMessage({ id: 'notification.call.connectionError' })));

      callInstance.on('reject', () =>
        notificationToast.warning(intl.formatMessage({ id: 'notification.call.callRejected' })));

      callInstance.on('sample', handleSampleChange);
    }
  };

  const handleCall = async (phoneNumber: string) => {
    if (device) {
      const call = await device.connect({
        params: {
          phone: phoneNumber,
        },
      });

      setCall(call);
      addEventListeners(call);
    }
  };

  const handleMute = () => {
    if (!call) {
      return;
    }

    call.mute(!isMuted);
    setIsMuted(call.isMuted());
  };

  const handleCallEndClick = () => {
    if (!call) {
      return;
    }

    call.disconnect();
  };

  const handleCallFinish = () => {
    dispatch(callEnd());
    notificationToast.info(intl.formatMessage({ id: 'notification.call.callEnded' }));

    setStartTimeStamp(null);
    setEndTimeStamp(null);
    setCall(null);
  };

  useEffect(() => {
    setDuration(
      startTimeStamp && endTimeStamp
        ? ((endTimeStamp - startTimeStamp) / 1000) + 1 // we need +1 because sample event triggerred from a 1st second
        : 0,
    );
  }, [startTimeStamp, endTimeStamp]);

  return (
    call && (
      <div className={styles.popup}>
        <header className={styles.header}>
          <div className={styles.status}>{intl.formatMessage({ id: 'label.call.ongoing' })}</div>
          <div className={styles.name} title={callActiveName}>{callActiveName}</div>
          <div className={styles.duration}>{secondsToDurationStr(duration)}</div>
        </header>
        <div className={styles.actionsWrapper}>
          <div className={styles.buttonLabelWrapper}>
            <button className={styles.button} type="button" onClick={handleMute} aria-label={intl.formatMessage({ id: isMuted ? 'label.call.unmute' : 'label.call.mute' })}>
              <i className={classnames({ 'icon-microphone-slash': isMuted, 'icon-microphone': !isMuted })} />
            </button>
            <span className={styles.buttonLabel}>
              {intl.formatMessage({ id: isMuted ? 'label.call.unmute' : 'label.call.mute' })}
            </span>
          </div>
          <div className={styles.buttonLabelWrapper}>
            <button className={classnames(styles.button, styles.endCalLButton)} type="button" onClick={handleCallEndClick} aria-label={intl.formatMessage({ id: 'label.call.end' })}>
              <i className="icon-call-slash" />
            </button>
            <span className={styles.buttonLabel}>
              {intl.formatMessage({ id: 'label.call.end' })}
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default PhoneDialer;

import { CompaniesFilters } from 'Types/companiesFilters.interface';
import { AppDispatch } from 'Types/redux.types';
import { CompaniesRequest } from 'Types/companiesRequest.interface';

export enum Actions {
  UpdateFilters = 'companiesFiltersReducer/UpdateFilters',
  UpdateReqProps = 'companiesFiltersReducer/UpdateReqProps',
  ResetFilters = 'companiesFiltersReducer/ResetFilters',
  ResetAll = 'companiesFiltersReducer/ResetAll',
  UpdateFilterId = 'companiesFiltersReducer/UpdateFilterId',
}

export enum CompaniesFiltersReducerAlias {
  CommonPage = 'Common',
  InviteesTab = 'Invitees',
  AssociatesTab = 'Associates',
}

export const getCompaniesFiltersActionName = (actionName: Actions, alias: CompaniesFiltersReducerAlias): string =>
  `${actionName}_${alias}`;

const updateCompaniesReqProps = (alias: CompaniesFiltersReducerAlias) => (reqProps: Partial<CompaniesRequest>) => (dispatch: AppDispatch) =>
  dispatch({ type: getCompaniesFiltersActionName(Actions.UpdateReqProps, alias), payload: reqProps });

export const updateCompaniesOffset = (alias: CompaniesFiltersReducerAlias) => (offset: number) =>
  updateCompaniesReqProps(alias)({ start: offset });

export const updateCompaniesLimit = (alias: CompaniesFiltersReducerAlias) => (limit: number) =>
  updateCompaniesReqProps(alias)({ limit });

export const updateCompaniesOrder = (alias: CompaniesFiltersReducerAlias) => (order: string) =>
  updateCompaniesReqProps(alias)({ order, start: 0 });

export const updateCompaniesQuery = (alias: CompaniesFiltersReducerAlias) => (query: string) =>
  updateCompaniesReqProps(alias)({ query, start: 0 });

export const updateCompaniesFilters = (alias: CompaniesFiltersReducerAlias) => (filters: CompaniesFilters) => (dispatch: AppDispatch) =>
  dispatch({
    type: getCompaniesFiltersActionName(Actions.UpdateFilters, alias),
    payload: { filters, reqProps: { start: 0 } },
  });
export const resetCompaniesFilters = (alias: CompaniesFiltersReducerAlias) => (initialFilters = {}) => (dispatch: AppDispatch) =>
  dispatch({ type: getCompaniesFiltersActionName(Actions.ResetFilters, alias), payload: initialFilters });

export const resetCompaniesFiltersAndReqProps = (alias: CompaniesFiltersReducerAlias) => (initialFilters = {}) => (dispatch: AppDispatch) =>
  dispatch({ type: getCompaniesFiltersActionName(Actions.ResetAll, alias), payload: initialFilters });

export const updateCompaniesFilterId = (alias: CompaniesFiltersReducerAlias) =>
  (actor: string | null) => (dispatch: AppDispatch) =>
    dispatch({ type: getCompaniesFiltersActionName(Actions.UpdateFilterId, alias), payload: actor });

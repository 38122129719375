import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardMedia, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';

//  Actions/Selectors
import {
  selectedProductsSelector,
  selectedPromotionsSelector,
  selectedBountySelector,
} from 'Store/createBounty/selectors';
import { setSelectedPromotions, setSelectedProducts } from 'Store/createBounty/actions';

//  Other resources
import ImagePlaceholder from 'Assets/img/general/image_placeholder.png';
import { BountyType } from 'Constants/enums';
import { getPromotionCoverUrl, getProductCoverUrl } from 'Utils/bounty';
import * as styles from './index.module.scss';

const AttachedPromotions = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedPromotions = useSelector(selectedPromotionsSelector) || [];
  const selectedProducts = useSelector(selectedProductsSelector) || [];
  const selectedBounty = useSelector(selectedBountySelector) || {};

  useEffect(() => {
    const attachments = selectedBounty?.attachments?.attachments || {};
    const promotions = Object.values(attachments).filter(((test) => test.meaning === BountyType.Promotion));
    const products = Object.values(attachments).filter(((test) => test.meaning === BountyType.Product));

    dispatch(setSelectedPromotions(promotions));
    dispatch(setSelectedProducts(products));
  }, [selectedBounty?.attachments?.attachments]);

  const removePromotion = (item) => {
    const index = selectedPromotions.findIndex((selectedProm) => selectedProm.id === item.id);

    if (index > -1) {
      const newData = selectedPromotions.filter(((selectedProm) => selectedProm.id !== item.id));
      return dispatch(setSelectedPromotions(newData));
    }
  };

  const removeProduct = (item) => {
    const index = selectedProducts.findIndex((selectedProd) => selectedProd.id === item.id);

    if (index > -1) {
      const newData = selectedProducts.filter(((selectedProd) => selectedProd.id !== item.id));
      return dispatch(setSelectedProducts(newData));
    }
  };

  return (
    selectedPromotions?.length > 0 || selectedProducts?.length > 0) && (
      <div>
        <p>{intl.formatMessage({ id: 'bounty.promotionsToAttach' })}</p>
        <div>
          {selectedPromotions.map((prom) => (
            <Box key={prom.id} m={2} position="relative" className={styles.promotionCard}>
              <CardMedia
                component="img"
                alt="promotion"
                height="120"
                classes={{ root: styles.cardRoot }}
                image={getPromotionCoverUrl(prom) || ImagePlaceholder}
              />
              <Box position="absolute" top={0} right={0}>
                <IconButton aria-label="delete" size="small" onClick={() => removePromotion(prom)}>
                  <span className="material-symbols-rounded font-15">
                    close
                  </span>
                </IconButton>
              </Box>
            </Box>
          ))}
        </div>
        <div>
          {selectedProducts.map((product) => (
            <Box key={product.id} m={2} position="relative" className={styles.promotionCard}>
              <CardMedia
                component="img"
                alt="promotion"
                height="120"
                classes={{ root: styles.cardRoot }}
                image={getProductCoverUrl(product) || ImagePlaceholder}
              />
              <Box position="absolute" top={0} right={0}>
                <IconButton aria-label="delete" size="small" onClick={() => removeProduct(product)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </div>
      </div>
  );
};

export default AttachedPromotions;

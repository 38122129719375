import compression from 'browser-image-compression';
import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { bool, func, string, node, object, number } from 'prop-types';
import { useIntl } from 'react-intl';

import { IMAGE_MIME_TYPES, UPLOAD_FILE_SIZE } from 'Constants/attachment';
import { addNewFile } from 'Utils/attachments';
import { notificationToast } from 'Utils/notificationToaster';
import { formatBytes } from 'Utils/formatBytes';

import * as styles from './index.module.scss';

const CustomDropzone = ({
  isMultiple,
  children,
  onFilePick,
  accept,
  meaning,
  fileSizeLimit,
  onSetLoading,
  checkLoadedFile,
  isDisabled,
}) => {
  const intl = useIntl();
  const isFileExceedsLimit = ({ size }) => size > fileSizeLimit;

  const readFiles = (files) => Promise.all(
    files.map((file) => new Promise((resolve, reject) => {
      if (isFileExceedsLimit(file)) {
        reject(new Error('SIZE_EXCEEDED'));
        return;
      }

      const reader = new FileReader();

      reader.onload = async ({ target: { result } }) => {
        const resolveData = addNewFile(file);
        resolveData.data = result;
        resolveData.localUri = result;
        resolveData.meaning = meaning || '';

        if (IMAGE_MIME_TYPES.includes(file.type)) {
          resolveData.data = await compression(file, {
            useWebWorker: true,
            initialQuality: 8,
            maxWidthOrHeight: 1920,
          });
        }

        if (checkLoadedFile) {
          const isCorrect = await checkLoadedFile(result);

          if (!isCorrect) {
            reject();
            return;
          }
        }

        resolve(resolveData);
      };

      reader.readAsDataURL(file);
    })),
  );

  const handleDrop = async (files) => {
    if (onSetLoading) {
      onSetLoading(false);
    }

    try {
      if (files?.length) {
        const data = await readFiles(files) || [];
        onFilePick(data);
      } else {
        notificationToast.error(intl.formatMessage({ id: 'notification.error.mimeTypeIncorrect' }));
      }
    } catch (e) {
      if (e?.message === 'SIZE_EXCEEDED') {
        notificationToast.error(intl.formatMessage(
          { id: 'notification.error.confirmationModal.fileSize' },
          { limit: formatBytes(fileSizeLimit) },
        ));
      }
    }

    if (onSetLoading) {
      onSetLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: isMultiple,
    accept,
  });

  return (
    <div {...getRootProps()} className={styles.root}>
      <input {...getInputProps()} disabled={isDisabled} />
      {children}
    </div>
  );
};

CustomDropzone.propTypes = {
  accept: object,
  meaning: string,
  isMultiple: bool,
  isDisabled: bool,
  onFilePick: func.isRequired,
  checkLoadedFile: func,
  onSetLoading: func,
  children: node.isRequired,
  fileSizeLimit: number,
};

CustomDropzone.defaultProps = {
  accept: {
    'image/*': [],
    'audio/*': [],
    'video/*': [],
    'application/pdf': [],
  },
  meaning: '',
  checkLoadedFile: null,
  isMultiple: false,
  isDisabled: false,
  fileSizeLimit: UPLOAD_FILE_SIZE.DEFAULT,
  onSetLoading: null,
};

export default memo(CustomDropzone);

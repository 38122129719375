import { ThunkDispatchType } from 'Types/redux.types';

// TODO: add types
interface OpenBountyPayload {
  configuration?: any;
  selectedBounty?: any;
  parentBounty?: any;
  contentType: string;
}

export const ACTIONS = {
  SET_IS_LOADING: 'createBountyReducer/setIsLoading',
  SET_IS_OPEN: 'createBountyReducer/setIsOpen',
  SET_CONFIGURATION: 'createBountyReducer/setConfiguration',
  SET_SELECTED_BOUNTY: 'createBountyReducer/setSelectedBounty',
  SET_PARENT_BOUNTY: 'createBountyReducer/setParentBounty',
  SET_SELECTED_PROMOTIONS: 'createBountyReducer/setSelectedPromotions',
  SET_SELECTED_PRODUCTS: 'createBountyReducer/setSelectedProducts',
  SET_CONTENT_TYPE: 'createBountyReducer/setContentType',
  RESET_REDUCER: 'createBountyReducer/resetReducer',
};

export const resetReducer = () => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.RESET_REDUCER });
export const setIsLoading = (payload: any) => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.SET_IS_LOADING, payload });
export const setContentType = (payload: any) => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.SET_CONTENT_TYPE, payload });
export const openCreateBountyDrawer = () => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.SET_IS_OPEN, payload: true });
export const closeCreateBountyDrawer = () => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.SET_IS_OPEN, payload: false });

// TODO: Add payload description
export const setSelectedBounty = (payload: any) => (dispatch: ThunkDispatchType) => (
  dispatch({
    type: ACTIONS.SET_SELECTED_BOUNTY,
    payload,
  })
);

export const setParentBounty = (payload: any) => (dispatch: ThunkDispatchType) => (
  dispatch({
    type: ACTIONS.SET_PARENT_BOUNTY,
    payload,
  })
);

export const setConfiguration = (payload: any) => (dispatch: ThunkDispatchType) => (
  dispatch({
    type: ACTIONS.SET_CONFIGURATION,
    payload,
  })
);

export const setSelectedPromotions = (payload: any) => (dispatch: ThunkDispatchType) => (
  dispatch({
    type: ACTIONS.SET_SELECTED_PROMOTIONS,
    payload,
  })
);

export const setSelectedProducts = (payload: any) => (dispatch: ThunkDispatchType) => (
  dispatch({
    type: ACTIONS.SET_SELECTED_PRODUCTS,
    payload,
  })
);

export const closeDrawer = () => (dispatch: ThunkDispatchType) => {
  dispatch(resetReducer());
  dispatch(closeCreateBountyDrawer());
};

export const openBountyDrawer = ({ configuration, selectedBounty, parentBounty, contentType }: OpenBountyPayload) => (dispatch: ThunkDispatchType) => {
  dispatch(openCreateBountyDrawer());
  dispatch(setConfiguration(configuration));
  dispatch(setContentType(contentType));

  if (selectedBounty) {
    dispatch(setSelectedBounty(selectedBounty));
  }
  if (parentBounty) {
    dispatch(setParentBounty(parentBounty));
  }
};

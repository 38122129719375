import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { BountyType, RewardType } from 'Constants/enums';
import { isSurveyType, hasAtLeastOneAttachment, hasExactlyOneImage } from 'Utils/bounty';

const isAmountValid = (values) => {
  const { type, reward } = values || {};
  const { currency, amount } = reward || {};

  // check amount only for bounty types for which it is mandatory
  return !([BountyType.TMOB_Challenge].includes(type) && !amount && ![RewardType.BADGE, RewardType.VOUCHER].includes(currency));
};

export const bountySchema = Yup.object({
  description: Yup
    .string()
    .nullable()
    .when('type', {
      is: (type) => ![BountyType.News, BountyType.Banner].includes(type),
      then: (field) => field
        .required(<FormattedMessage id="error.fieldIsRequired" />),
    })
    .when('type', {
      is: (type) => [BountyType.News, BountyType.Album].includes(type),
      then: (field) => field
        .max(120, <FormattedMessage id="error.maximumStringLength" values={{ value: '120' }} />),
    }),
  reward: Yup.object({
    amount: Yup
      .string()
      .nullable(),
  }),
  banner: Yup.object({
    priority: Yup
      .number(<FormattedMessage id="error.enterAnumber" />)
      .min(0, <FormattedMessage id="error.shouldBeGreaterThanZero" values={{ value: 0 }} />),
    weight: Yup
      .number(<FormattedMessage id="error.enterAnumber" />)
      .min(0, <FormattedMessage id="error.shouldBeGreaterThanZero" values={{ value: 0 }} />),
  })
    .nullable(),
}).test(
  'isAmountValid',
  <FormattedMessage id="error.fieldIsRequired" />,
  isAmountValid,
);

export const validateBounty = ({ type, surveyOptions = '', days, hours, minutes, correctWeight = '' }, attachments) => {
  if (hasExactlyOneImage(type) && attachments?.length !== 1) {
    return { message: 'bounty.required.oneImage' };
  }

  if (hasAtLeastOneAttachment(type) && !attachments?.length) {
    return { message: 'bounty.required.atLeastOneMedia' };
  }

  if (isSurveyType(type) && type !== BountyType.Lottery) {
    const emptyField = Object.keys(surveyOptions).find((key) => !surveyOptions[key].text);

    if (emptyField) {
      return { message: 'bounty.choiceIsRequired', values: { code: emptyField } };
    }
  }

  if (type === BountyType.MCQ) {
    const correctAnswer = Object.keys(surveyOptions).filter((key) => (
      +surveyOptions[key].weight === correctWeight
    ));

    if (!correctAnswer || !correctAnswer.length) {
      return { message: 'bounty.checkCorrectAnswer' };
    }
  }

  if (type === BountyType.Lottery && !days && !minutes && !hours) {
    return { message: 'bounty.setLotteryCloseDate' };
  }

  return null;
};

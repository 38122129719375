import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'joblio',
  title: 'Joblio',
  name: 'Joblio app',
  brandName: 'Joblio',
  appLinkSchema: 'joblio',
  appTld: 'joblio.co',
  tags: ['all'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  TERMS_OF_SERVICE_URL: 'https://joblio.co/{langCode}/terms-of-use',
  PRIVACY_STATEMENT_URL: 'https://joblio.co/{langCode}/privacy-policy',
  customPages: {
    home: 'AsyncHomePage',
    profile: 'AsyncUserProfilePage',
  },
  custom: {
    intercomAppId: 'kjadpzxv',
  },
};

export default config;

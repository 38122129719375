import toast, { ToastOptions } from 'react-hot-toast';
import { ReactElement, ReactNode } from 'react';

type NotificationToastOptions = ToastOptions & { title?: string | ReactNode; };

export const notificationToast = {
  info: (message: string, optionsObj?: NotificationToastOptions) => toast(message, { ...optionsObj, className: 'infoType' }),
  success: (message: string | ReactElement, optionsObj?: NotificationToastOptions) => toast(message, { ...optionsObj, className: 'successType' }),
  warning: (message: string | ReactElement, optionsObj?: NotificationToastOptions) => toast(message, { ...optionsObj, className: 'warningType' }),
  error: (message: string | ReactElement, optionsObj?: NotificationToastOptions) => toast(message, { ...optionsObj, className: 'errorType' }),
};

import { useIntl } from 'react-intl';
import { FC } from 'react';

interface LanguagePreviewProps {
  code: string;
}

const LanguagePreview: FC<LanguagePreviewProps> = ({ code }) => {
  const intl = useIntl();
  const langTitle = intl.formatMessage({ id: `lang-${code.toUpperCase()}` });

  return (
    <span data-qa="languagePreview" data-qa-value={code} title={langTitle}>
      {langTitle}
    </span>
  );
};

export default LanguagePreview;

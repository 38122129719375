import {
  UserKind,
  JoiningStrategy,
  MemberRole,
  MoreMenuGroupOptions,
  MemberActions,
  BountyState,
} from 'Constants/enums';
import { getUserKindAsEnum } from 'Utils/user';
import { isAllowed } from 'Utils/settings';
import { EDIT_CAPS, EDIT_COMPANY } from 'Constants/op';
import { ExtendedGroupMember, Group, GroupMember } from 'Types/group.interface';
import { User } from 'Types/user.interface';
import { UserData } from 'Types/userData.interface';
import { Settings } from 'Types/settings.interface';
import { GroupOptionsMenu } from 'Types/option.interface';

export const TEAM_COMPANY_ID = 'TEAM_COMPANY';

export const GROUP_ACTIONS_LABELS: Record<string, GroupOptionsMenu> = {
  [MoreMenuGroupOptions.AddProfilePicture]: {
    action: MoreMenuGroupOptions.AddProfilePicture,
    text: 'lbl_add_profile_picture',
    icon: 'icon-camera',
  },
  [MoreMenuGroupOptions.Edit]: {
    action: MoreMenuGroupOptions.Edit,
    text: 'option_edit_group',
    icon: 'icon-edit-2',
  },
  [MoreMenuGroupOptions.Add_member]: {
    action: MoreMenuGroupOptions.Add_member,
    text: 'option_add_member',
    icon: 'icon-add-user',
  },
  [MoreMenuGroupOptions.Delete]: {
    action: MoreMenuGroupOptions.Delete,
    text: 'button.delete',
    icon: 'icon-trash',
  },
};

export const MEMBER_ACTIONS_LABELS: Record<string, GroupOptionsMenu> = {
  [MemberActions.Message]: {
    action: MemberActions.Message,
    text: 'option_send_message',
    icon: 'icon-message',
  },
  [MemberActions.Remove]: {
    action: MemberActions.Remove,
    text: 'option_remove_member',
    icon: 'icon-profile-delete',
  },
  [MemberActions.MakeGroupAdmin]: {
    action: MemberActions.MakeGroupAdmin,
    text: 'option_make_group_admin',
    icon: 'icon-shield-tick',
  },
  [MemberActions.RemoveGroupAdmin]: {
    action: MemberActions.RemoveGroupAdmin,
    text: 'option_remove_group_admin',
    icon: 'icon-shield-cross',
  },
  [MemberActions.Roles]: {
    action: MemberActions.Roles,
    text: 'option_roles',
    icon: 'assignment_ind',
  },
};

export const getJoinStrategyAsEnum = (joinStrategy?: JoiningStrategy) => joinStrategy || JoiningStrategy.InviteOnly;

export const getJoiningStrategyText = (joinStrategy: JoiningStrategy) => {
  if (joinStrategy === JoiningStrategy.InviteOnly) {
    return 'lbl_join_policy_invitation';
  }

  if (joinStrategy === JoiningStrategy.NeedsApproval) {
    return 'lbl_join_policy_approval';
  }

  if (joinStrategy === JoiningStrategy.Open) {
    return 'lbl_join_policy_freely';
  }

  return null;
};

export const isInvalid = (member: GroupMember) => !(member && member.user && member.user.id);

export const getTeamCompanyName = (owner: User) => {
  let teamCompanyName = null;

  if (owner != null) {
    teamCompanyName = owner.name;
  }

  if (!teamCompanyName) {
    teamCompanyName = 'drawer_item_team_anonymous';
  }

  return teamCompanyName;
};

export const initTeamCompanyGroup = (owner: User): Partial<GroupMember> => {
  const teamCompany = {
    id: TEAM_COMPANY_ID,
    name: getTeamCompanyName(owner),
  } as User;

  return {
    group: true,
    user: teamCompany,
  };
};

export const isGroupMember = (members: GroupMember[], loggedUser: Partial<User>) => !!(members?.find((member) => loggedUser.uid === member?.user?.id));

export const isGroupAdmin = (members: GroupMember[], loggedUser: Partial<User>) => {
  if (!members?.length) {
    return false;
  }

  const groupInfoOfLoggedUser = members.find((member) => loggedUser?.uid === member?.user?.id);
  return Boolean(groupInfoOfLoggedUser?.memberRoles === MemberRole.admin);
};

export const canLeaveGroup = (members: GroupMember[], loggedUser: Partial<User>) => {
  const mIsGroupAdmin = isGroupAdmin(members, loggedUser);

  if (!mIsGroupAdmin) {
    return true;
  }

  const admins = members?.filter(({ memberRoles }) => memberRoles === MemberRole.admin);

  return admins?.length > 1;
};

export const isAdmin = (
  params: { loggedUser: Partial<User>; settings: Settings; userData: UserData; group: Group },
) => {
  const { group, loggedUser, settings, userData } = params;

  if (group && group.user) {
    if (getUserKindAsEnum(group.user) === UserKind.Company) {
      return isAllowed({ op: EDIT_COMPANY.name, settings, userData });
    }

    if (getUserKindAsEnum(group.user) === UserKind.Group) {
      return isGroupAdmin(group?.members, loggedUser);
    }
  }

  return false;
};

export const canAddMembers = (mIsGroupAdmin: boolean, mIsGroupMember: boolean, joiningStrategy?: JoiningStrategy) => (joiningStrategy === JoiningStrategy.InviteOnly ? mIsGroupAdmin : mIsGroupMember);

export const getJoinButtonProps = (
  params: { hasJoined: boolean; isPending: boolean; mIsGroupMember: boolean; joinStrategy: JoiningStrategy | null },
) => {
  const { hasJoined, isPending, mIsGroupMember, joinStrategy } = params;
  switch (joinStrategy) {
    case JoiningStrategy.NeedsApproval: {
      return {
        visible: true,
        text: hasJoined ? (isPending ? 'Join requested' : 'Joined') : 'Join',
      };
    }
    case JoiningStrategy.Open: {
      return {
        visible: true,
        text: hasJoined ? 'Joined' : 'Join',
      };
    }
    case JoiningStrategy.InviteOnly: {
      return {
        visible: mIsGroupMember,
        text: 'Joined',
      };
    }
    case JoiningStrategy.Closed: {
      return {
        visible: true,
        text: hasJoined ? 'Subscribed' : 'Subscribe',
      };
    }
    default: {
      return {
        visible: false,
        text: '',
      };
    }
  }
};

export const buildGroupMenu = ({ group, loggedUser }: { group: ExtendedGroupMember, loggedUser: Partial<User> }) => {
  const finalMenu = [];
  const mIsGroupAdmin = isGroupAdmin(group?.members, loggedUser);
  const mIsGroupMember = isGroupMember(group?.members, loggedUser);
  const mCanInviteMembers = canAddMembers(mIsGroupAdmin, mIsGroupMember, group?.joiningStrategy);

  if (mIsGroupAdmin) {
    finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.Edit]);
    finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.Delete]);
  }

  if (mCanInviteMembers) {
    finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.Add_member]);
  }

  return finalMenu;
};

export const isEmployeeGroup = (groupId: string) => groupId === TEAM_COMPANY_ID;

export const buildGroupProfileMenu = (
  params: { loggedUser: Partial<User>; userData: UserData, settings: Settings; group: Group, groupId: string },
) => {
  const { group, loggedUser, settings, userData, groupId } = params;

  const mIsGroupAdmin = isGroupAdmin(group?.members, loggedUser);
  const mIsAdmin = isAdmin({ group, loggedUser, settings, userData });
  const mIsGroupMember = isGroupMember(group?.members, loggedUser);
  const mCanInviteMembers = canAddMembers(mIsGroupAdmin, mIsGroupMember, group?.joiningStrategy);
  const userKind = getUserKindAsEnum(group.user);
  const finalMenu = [];
  const employeeGroup = isEmployeeGroup(groupId);

  if (mIsAdmin) {
    finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.AddProfilePicture]);

    if (!employeeGroup) {
      finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.Edit]);
    }
  }

  if (mIsAdmin && userKind === UserKind.Group && !employeeGroup) {
    finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.Delete]);
  }

  if (userKind === UserKind.Group && mCanInviteMembers) {
    finalMenu.push(GROUP_ACTIONS_LABELS[MoreMenuGroupOptions.Add_member]);
  }

  return finalMenu;
};

export const isGroupMemberAdmin = (member: GroupMember) => member?.memberRoles === MemberRole.admin;

interface BuildMemberMenuProps {
  member: GroupMember;
  owner: User;
  settings: Settings;
  userData: UserData;
  mIsGroupAdmin: boolean;
  noOfAdmins: number;
}

export const buildMemberMenu = ({
  member,
  owner,
  settings,
  userData,
  mIsGroupAdmin,
  noOfAdmins,
}: BuildMemberMenuProps) => {
  const isTeamGroup = owner?.id === member?.user?.id;
  const isCustomGroup = !isTeamGroup;
  const isMemberAdmin = isGroupMemberAdmin(member);
  const isMe = userData?.myself && member?.user && userData?.myself?.id === member?.user?.id;
  const finalMenu: GroupOptionsMenu[] = [];

  Object.values(MemberActions).forEach((action) => {
    switch (action) {
      case MemberActions.Message:
        if (!isMe) {
          finalMenu.push(MEMBER_ACTIONS_LABELS[action]);
        }
        break;
      case MemberActions.Roles:
        if (isTeamGroup && isAllowed({ op: EDIT_CAPS.name, settings, userData })) {
          finalMenu.push(MEMBER_ACTIONS_LABELS[action]);
        }
        break;
      case MemberActions.Remove:
        if (mIsGroupAdmin && noOfAdmins > 1) {
          finalMenu.push(MEMBER_ACTIONS_LABELS[action]);
        }
        break;
      case MemberActions.MakeGroupAdmin:
      case MemberActions.RemoveGroupAdmin:
        if (!mIsGroupAdmin || !isCustomGroup) {
          break;
        }

        if (isMemberAdmin) {
          if (action === MemberActions.RemoveGroupAdmin && noOfAdmins > 1) {
            finalMenu.push(MEMBER_ACTIONS_LABELS[action]);
          }
        } else if (action === MemberActions.MakeGroupAdmin) {
          finalMenu.push(MEMBER_ACTIONS_LABELS[action]);
        }
        break;
      default:
        break;
    }
  });

  return finalMenu;
};

export const needApproval = (member: GroupMember) => member.state === BountyState.Pending;

import moment from 'moment';
import { format, Locale } from 'date-fns';
import { de, es, it, ka, pl, ro, ru } from 'date-fns/locale';
import { DISPLAYED_DATE_FORMAT, MONTH_FORMAT } from 'Constants/common';

import { AttachmentType, AvatarProperties, BountyCardType, BountyState } from 'Constants/enums';
import { Bounty } from 'Types/bounty.interface';
import { setOutbound } from 'Utils/bounty';
import { ClassifiedBackgroundImageAttachments } from 'Types/classifiedBackgroundImageAttachments.type';
import { User } from 'Types/user.interface';
import { Product } from 'Types/product.interface';
import { DataSnapshot } from 'firebase/database';
import { extractPhoneNumber } from "Utils/phoneUtils";

// Function to convert hex to rgba
export const hexToRgbA = (hex: string, alpha: string) => {
  let c: any | string;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`; // eslint-disable-line
  }
  throw new Error('Bad Hex');
};

// Text Truncate
export const textTruncate = (str: string, length: number = 100, ending: string = '...'): string => {
  if (str && str.length > length) {
    return str.substring(0, length) + ending;
  }
  return str;
};

// Users Chat List Last Message Truncate
export const lastMessageTruncate = (str: string, length: number = 100, ending: string = '...'): string => {
  if (str.length > length) {
    return str.substring(0, length - 3) + ending;
  }
  return str;
};

export const getDate = (
  timestamp: number | Date,
  formatData = DISPLAYED_DATE_FORMAT,
  locale?: string & Locale,
): string => {
  if (!timestamp) return '';

  const locales = { ru, ro, pl, it, es, de, ka };
  const options = locale ? { locale: locales[locale as keyof typeof locales] } : undefined;

  return format(timestamp, formatData, options);
};

// Get The Date
// TODO: Replace by getDate fnc after refactoring
export const getTheDate = (timestamp: number, format = DISPLAYED_DATE_FORMAT): string => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return format
    .replace('dd', day)
    .replace('MM', month)
    .replace('yyyy', year);
};

// Get Current Month
export const getCurrentMonth = (): string => moment().format(MONTH_FORMAT);

// Convert Date To Timestamp
export const convertDateToTimeStamp = (date: Date, format: string): number => {
  const formatDate = format || 'YYYY-MM-DD';
  return moment(date, formatDate).unix();
};

export const getUserAge = (date: Date) => moment().diff(date, 'years');

// Function to return current app layout
export const getAppLayout = (url: URL) => {
  const location = url.pathname;
  const path = location.split('/');
  return path[1];
};

export const convertObjToArray = (data: any): any[] => (data ? Object.values(data) : []);

export const streamSnapshotToArray = (dataSnapshot: DataSnapshot, isOutbound: boolean = false) => {
  const dataSnapshotVal: any[] = [];

  dataSnapshot
    .forEach((child) => {
      const data = child.val();
      const cardType = Object.keys(data)[0] as BountyCardType;

      if ([BountyCardType.bounty, BountyCardType.response].includes(cardType) && !data.dismissedAt && data[cardType]?.id) {
        if (isOutbound) {
          const key = Object.keys(data)[0];
          data[key].metaInfo = setOutbound(data[key].metaInfo, true);
        }

        dataSnapshotVal.push(data);
      }
    });

  return dataSnapshotVal;
};

export const splitStringAtCaretUp = (str: string) : string[] | string => (str ? str.split('^') : '');

// firebase provides small versions of avatars for users who use google/fb accounts
// too get full size images, urls need to be adopted
export const getFullSizeAvatar = (url: string): string => {
  let avatar = url;

  if (!url) {
    return '';
  }

  if (avatar.includes('google')) {
    avatar = avatar.replace('=s96-c', '');
  }
  if (avatar.includes('facebook')) {
    avatar = `${avatar}?height=500`;
  }

  return avatar;
};

export const getScrollBarStyle = (): { height: string } => ({
  height: 'calc(100vh - 50px)',
});

export const getAuthorName = (data: { author?: User; creator?: User; }) => {
  if (data && data.author) {
    return data.author.name;
  }

  return (data && data.creator && data.creator.name) || '';
};

export const getAvatarProperties = (avatar: string = '') => {
  const options = avatar && (avatar.split('avatar:')[1] || '');

  if (!options) {
    return {};
  }

  const properties = options.split('&') || [];
  const avatarProperties: AvatarProperties | { [key: string]: string } = {};

  properties.forEach((option) => {
    const property = option.split('=');
    const [prop, value] = property;
    avatarProperties[prop] = prop === AvatarProperties.KeyColor ? `${AvatarProperties.CodeColor}${value}` : value;
  });

  return avatarProperties;
};

export const getProductBackgroundImage = (product?: Product) => {
  const imageUrl = product?.imageUrl || product?.url;
  return imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};
};

export const getClassifiedBackgroundImage = (attachments: ClassifiedBackgroundImageAttachments): { backgroundImage: string } | {} => {
  const firstImage = Object.values(attachments.attachments || {})
    .find((attachment) => attachment.type === AttachmentType.Image);

  return firstImage ? { backgroundImage: `url(${firstImage.url})` } : {};
};

export const currencyFormatter = ({ currency = 'USD', amount = 0, minimumFractionDigits = 2 }) => {
  const formatConfig = {
    style: 'currency',
    currency, // default to USD
    minimumFractionDigits,
    currencyDisplay: 'symbol',
  };

  return new Intl.NumberFormat('en', formatConfig).format(amount);
};

export const toggleValueInArray = <T>(arr: T[] = [], value: T): T[] => (arr.includes(value) ? arr.filter((v) => v !== value) : [...arr, value]);

export const bytesToSize = (bytes: number): string => {
  const sizes = ['bytes', 'kb', 'mb', 'gb'];

  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
  return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`;
};

export const stringToNumber = (str: string, defaultValue: number | null = 0) => (str ? Number(str) : defaultValue);

/** Function check phone number
 * @param {string} phone - if phone is empty, func will return default object
 * if phone in incorrect format (not international) (ex. 89125678765) func return it without formatting
 * to do it manually
 */
export const phoneParser = (phone: string) => {
  if (!phone) {
    return { nationalNumber: '', country: '' };
  }

  const { countryCode, basePhoneNumber } = extractPhoneNumber(phone);
  return { nationalNumber: basePhoneNumber || phone, country: countryCode || '' };
};

export const setJobBountyOutbound = (bounty: Bounty) => {
  const isBountyOutbound = [BountyState.Draft, BountyState.Closed, BountyState.Retracted, BountyState.Rejected, BountyState.Archived]
    .includes(bounty.state);
  return setOutbound(bounty, isBountyOutbound);
};

/**
 * Get a random integer between `min` and `max`.
 *
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */

export const getRandomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min) + 2;

export const generateEmptyArray = (length: number, allowZero = false) => {
  const arrayLength = allowZero ? length + 1 : length;
  return Array.from({ length: arrayLength }, (_, i) => (allowZero ? i : i + 1));
};

export function generateUID() {
  return Date.now().toString(36);
}

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { titleSelector } from 'Store/header/selectors';

interface PageTitleProps {
  className?: string;
}

const PageTitle: FC<PageTitleProps> = ({ className = '' }) => {
  const title = useSelector(titleSelector) || '';

  return <div className={className} title={title} data-qa="pageTitle">{title}</div>;
};

export default PageTitle;

import { Grant } from 'Types/grant.interface';

function getRoleList(grant: Grant) {
  const roles = grant ? grant.roles : '';

  if (roles) {
    return roles.split(',');
  }

  return [];
}

function isGranted(grant: Grant, userRoles: string[]) {
  const grantRoles = getRoleList(grant);

  if (!grantRoles.length || !userRoles.length) {
    return false;
  }

  return !!userRoles.find((role) => grantRoles.includes(role));
}

export { isGranted };

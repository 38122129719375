import { ReactElement, FC, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as styles from './index.module.scss';

interface CustomLoaderProps {
  isLoading: boolean,
  children?: ReactNode | ReactNode[],
  Component?: ReactElement,
  className?: string,
}

const CustomLoader:FC<CustomLoaderProps> = ({ isLoading, children = <div />, Component, className }): ReactNode | ReactNode[] => {
  if (isLoading) {
    return (
      <div className={className || styles.loader}>
        {Component || <CircularProgress />}
      </div>
    );
  }

  return (
    children
  );
};

export default CustomLoader;

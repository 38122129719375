import { object, string, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';
import SurveyChoice from 'Components/SurveyChoice';
import { BountyType } from 'Constants/enums';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import { DEFAULT_CORRECT_ANSWER_WEIGHT, isCorrectChoice } from 'Utils/survey';

const propTypes = {
  bountyType: string.isRequired,
  options: object.isRequired,
  handleChoiceChanges: func.isRequired,
  handleCorrectChoice: func.isRequired,
  handleDeleteChoice: func.isRequired,
  handleAddChoice: func.isRequired,
};

const CreateSurveyOptions = (props) => {
  const {
    bountyType,
    options,
    handleChoiceChanges,
    handleCorrectChoice,
    handleDeleteChoice,
    handleAddChoice,
  } = props;
  const intl = useIntl();

  return (
    <div>
      {bountyType === BountyType.MCQ && (
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: 'bounty.checkCorrectAnswer' })}
        </Typography>
      )}

      <div className="mt-20">
        {Object.values(options).map((option, index) => (
          <SurveyChoice
            key={option.code}
            hasDelete={index > 1}
            handleCorrectChoice={handleCorrectChoice}
            handleChoiceChanges={handleChoiceChanges}
            option={option}
            bountyType={bountyType}
            handleDelete={() => handleDeleteChoice(option)}
            isChecked={isCorrectChoice(option, DEFAULT_CORRECT_ANSWER_WEIGHT)}
          />
        ))}
      </div>
      <SimpleButton
        decoration={SimpleButtonDecoration.Transparent}
        onClick={handleAddChoice}
        label={intl.formatMessage({ id: 'button.addOption' })}
      />
    </div>
  );
};

CreateSurveyOptions.propTypes = propTypes;

export default CreateSurveyOptions;

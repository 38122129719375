import { ACTIONS } from 'Store/attachments/actions';

const initialState = {
  attachments: {},
  inlineAttachments: [],
  chatAttachments: [],
  removedAttachments: [],
};

const reducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_ATTACHMENTS:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.SET_CHAT_ATTACHMENTS:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.ADD_ATTACHMENT:
      return {
        ...state,
        inlineAttachments: [
          ...state.inlineAttachments,
          payload,
        ],
      };

    case ACTIONS.ADD_CHAT_ATTACHMENT:
      return {
        ...state,
        chatAttachments: [
          ...state.chatAttachments,
          payload,
        ],
      };

    case ACTIONS.ADD_SINGLE_ATTACHMENT:
      return {
        ...state,
        inlineAttachments: [
          payload,
        ],
      };

    case ACTIONS.REMOVE_ATTACHMENT:
      return {
        ...state,
        inlineAttachments: [
          ...state.inlineAttachments.filter((value, index) => index !== payload),
        ],
        removedAttachments: [
          ...state.removedAttachments,
          ...state.inlineAttachments.filter((value, index) => index === payload),
        ],
      };

    case ACTIONS.REMOVE_CHAT_ATTACHMENT:
      return {
        ...state,
        chatAttachments: [
          ...state.chatAttachments.filter((value, index) => index !== payload),
        ],
        removedAttachments: [
          ...state.removedAttachments,
          ...state.chatAttachments.filter((value, index) => index === payload),
        ],
      };

    case ACTIONS.UPDATE_ATTACHMENT: {
      const updatedAttachments = [...state.inlineAttachments];
      updatedAttachments[payload.index] = payload.attach;

      return {
        ...state,
        inlineAttachments: updatedAttachments,
      };
    }

    case ACTIONS.REORDER_ATTACHMENTS:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;

//  Others
import {
  firebaseGetMyCompanySettingsRef,
  firebaseGetSystemInfoRef,
  firebaseGetSystemSettingsRef,
  firebaseGetOrgTypeSettingsRef,
  firebaseGetUserDataRef,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';
import { UserService } from 'Services/UserService';
import { notificationToast } from 'Utils/notificationToaster';

export const ACTIONS = {
  LOADING_USER_DATA: 'settingsReducer/LoadingUserData',
  GET_USER_DATA: 'settingsReducer/GetUserData',
  LOADING_COMPANY_SETTINGS: 'settingsReducer/LoadingCompanySettings',
  GET_COMPANY_SETTINGS: 'settingsReducer/GetCompanySettings',
  LOADING_SYSTEM_SETTINGS: 'settingsReducer/LoadingSystemSettings',
  GET_SYSTEM_SETTINGS: 'settingsReducer/GetSystemSettings',
  LOADING_ORG_TYPE_SETTINGS: 'settingsReducer/LoadingOrgTypeSettings',
  GET_ORG_TYPE_SETTINGS: 'settingsReducer/GetOrgTypeSettings',
  LOADING_SYSTEM_INFO: 'settingsReducer/LoadingSystemInfo',
  GET_SYSTEM_INFO: 'settingsReducer/GetSystemInfo',
  SET_USER_INFO: 'settingsReducer/SetUserInfo',
  IS_SIDEBAR_OPEN: 'settingsReducer/IsSidebarOpen',
  RESET: 'settingsReducer/ResetData',
};

export const getUserData = ({ userId }) => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.LOADING_USER_DATA, payload: true });

    firebaseGetUserDataRef(userId).on('value', (dataSnapshot) => {
      const user = dataSnapshot.val() || {};
      if (!user.myself) {
        const fbUser = firebaseGetCurrentUser();

        user.myself = {
          id: fbUser.uid,
          name: fbUser.displayName,
          avatar: fbUser.photoURL || null,
        };
      }

      dispatch({ type: ACTIONS.GET_USER_DATA, payload: user });
      dispatch({ type: ACTIONS.LOADING_USER_DATA, payload: false });

      resolve(user);
    }, (error) => {
      dispatch({ type: ACTIONS.LOADING_USER_DATA, payload: false });
      reject(error);
    });
  })
    .catch((error) => console.log('userData err:', error)) // eslint-disable-line
);

export const getCompanySettings = ({ companyId }) => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.LOADING_COMPANY_SETTINGS, payload: true });
    firebaseGetMyCompanySettingsRef(companyId).on('value', (dataSnapshot) => {
      const result = dataSnapshot.val();

      dispatch({ type: ACTIONS.GET_COMPANY_SETTINGS, payload: result });
      dispatch({ type: ACTIONS.LOADING_COMPANY_SETTINGS, payload: false });

      resolve(result);
    }, (error) => {
      dispatch({ type: ACTIONS.LOADING_COMPANY_SETTINGS, payload: false });
      reject(error);
    });
  })
    .catch((error) => console.log('companySettings err:', error))// eslint-disable-line
);

export const getSystemSettings = () => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.LOADING_SYSTEM_SETTINGS, payload: true });
    firebaseGetSystemSettingsRef()
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();

        dispatch({ type: ACTIONS.GET_SYSTEM_SETTINGS, payload: result });
        dispatch({ type: ACTIONS.LOADING_SYSTEM_SETTINGS, payload: false });
        resolve(result);
      }, (error) => {
        dispatch({ type: ACTIONS.LOADING_SYSTEM_SETTINGS, payload: false });
        reject(error);
      });
  })
    .catch((error) => console.log('systemSettings err:', error))// eslint-disable-line
);

export const getOrgTypeSettings = (orgType) => (dispatch) => (
  new Promise((resolve, reject) => {
    if (!orgType) {
      return resolve();
    }

    dispatch({ type: ACTIONS.LOADING_ORG_TYPE_SETTINGS, payload: true });
    firebaseGetOrgTypeSettingsRef(orgType)
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();

        dispatch({ type: ACTIONS.GET_ORG_TYPE_SETTINGS, payload: result });
        dispatch({ type: ACTIONS.LOADING_ORG_TYPE_SETTINGS, payload: false });
        resolve(result);
      }, (error) => {
        dispatch({ type: ACTIONS.LOADING_ORG_TYPE_SETTINGS, payload: false });
        reject(error);
      });
  })
    .catch((error) => console.log('orgTypeSettings err:', error))// eslint-disable-line
);

export const getSystemInfo = () => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.LOADING_SYSTEM_INFO, payload: true });
    firebaseGetSystemInfoRef()
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();

        dispatch({ type: ACTIONS.GET_SYSTEM_INFO, payload: result });
        dispatch({ type: ACTIONS.LOADING_SYSTEM_INFO, payload: false });
        resolve(result);
      }, (error) => {
        dispatch({ type: ACTIONS.LOADING_SYSTEM_INFO, payload: false });
        reject(error);
      });
  })
    .catch((error) => console.log('systemInfo err:', error))// eslint-disable-line
);

export const resetSettings = () => (dispatch) => dispatch({ type: ACTIONS.RESET });

export const getUserInfo = () => async (dispatch) => {
  dispatch({ type: ACTIONS.LOADING_SYSTEM_SETTINGS, payload: true });

  try {
    const result = await UserService.getUserInfo();
    dispatch({ type: ACTIONS.SET_USER_INFO, payload: result });
  } catch (error) {
    notificationToast.error(error.message || 'notification.error.unknown.error');
  } finally {
    dispatch({ type: ACTIONS.LOADING_SYSTEM_SETTINGS, payload: false });
  }
};

export const setUserInfo = (userInfo) => (dispatch) => dispatch({ type: ACTIONS.SET_USER_INFO, payload: userInfo });

export const setIsSidebarOpen = (isSidebarOpen) => (dispatch) => (
  dispatch({ type: ACTIONS.IS_SIDEBAR_OPEN, payload: isSidebarOpen })
);

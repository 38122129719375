import { FC, useEffect, useState, ChangeEvent } from 'react';
import { Grid, MenuItem, Typography, SelectChangeEvent } from '@mui/material';
import { useIntl } from 'react-intl';

import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as placeholderStyle from 'Assets/scss/modules/placeholder.module.scss';
import CustomInput from 'Components/CustomInput';
import StyledSelect from 'Components/StyledSelect';
import RewardBadges from 'Components/RewardBadges';

import { formatNestedError } from 'Utils/formik';
import { notificationToast } from 'Utils/notificationToaster';

import { RewardType } from 'Constants/enums';
import { Badge } from 'Types/badge.interface';
import { CurrencyOptions } from 'Types/currency.interface';

interface RewardFieldProps {
  value?: {
    amount?: string | number;
    currency?: RewardType;
    badges?: Badge[];
    badge?: Badge;
  };
  validationErrors: any;
  onChange: (event: SelectChangeEvent<unknown> | ChangeEvent<HTMLInputElement>) => unknown;
  currencyOptions: CurrencyOptions[];
  handleBadge: (name: string, badge: Badge) => unknown;
}

const RewardField: FC<RewardFieldProps> = ({ value = {}, validationErrors = [], onChange, currencyOptions, handleBadge }) => {
  const [openBadgesModal, setOpenBadgesModal] = useState(false);
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [isAmountDisabled, setIsAmountDisabled] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (value?.currency && [RewardType.Voucher].includes(value.currency)) {
      setIsAmountDisabled(true);
    } else {
      setIsAmountDisabled(false);
    }
  }, [value?.currency]);

  const toggleBadgesModal = () => setOpenBadgesModal(!openBadgesModal);

  const handleCurrency = (e: SelectChangeEvent<unknown>) => {
    const { target: { value: currency } } = e;
    onChange(e);

    if (currency === RewardType.Badge) {
      toggleBadgesModal();
    }
  };

  const handleAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const { target: { value: amount } } = e;

    if (value?.currency === RewardType.Badge) {
      return;
    }

    if (Number.isNaN(+amount)) {
      if (!isNotificationActive) {
        notificationToast.error(intl.formatMessage({ id: 'bounty.amountInvalid' }));
        setIsNotificationActive(true);
        setTimeout(() => setIsNotificationActive(false), 5000);
      }
    } else {
      onChange(e);
    }
  };

  const onAmountClick = () => {
    if (value?.currency === RewardType.Badge) {
      toggleBadgesModal();
    }
  };

  const getAmountValue = () => {
    const badge = value?.badges?.[0] || value?.badge || null;

    if (value?.currency === RewardType.Badge && badge) {
      return badge?.info?.label || '';
    }

    return value.amount || '';
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography className={labelStyles.fieldLabel}>
          {`${intl.formatMessage({ id: 'label.reward' })}`}
        </Typography>
        <CustomInput
          name="reward.amount"
          disabled={isAmountDisabled}
          placeholder={intl.formatMessage({ id: 'placeholder.addAmount' })}
          value={getAmountValue()}
          onChange={handleAmount}
          onClick={onAmountClick}
          validationErrors={formatNestedError(validationErrors[0], 'amount')}
        />
      </Grid>

      {currencyOptions.length > 0 && (
        <Grid item xs={6}>
          <Typography className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'private.currencyName' })}`}
          </Typography>
          <StyledSelect
            displayEmpty
            name="reward.currency"
            value={value?.currency}
            onChange={handleCurrency}
            validationErrors={formatNestedError(validationErrors[0], 'currency')}
          >
            <MenuItem disabled value="">
              <span className={placeholderStyle.placeholder}>
                {intl.formatMessage({ id: 'placeholder.select.currency' })}
              </span>
            </MenuItem>
            {currencyOptions.map(({ label, value: optionValue }) => (
              <MenuItem key={optionValue} value={optionValue}>{label}</MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      )}

      <RewardBadges
        open={openBadgesModal}
        handleClose={toggleBadgesModal}
        handleBadge={handleBadge}
      />
    </Grid>
  );
};

export default RewardField;

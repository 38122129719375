import classnames from 'classnames';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import SimpleButton from 'Components/SimpleButton';

import EmptyImage from 'Assets/img/general/image_placeholder.png';
import { Product } from 'Types/product.interface';
import * as styles from './index.module.scss';

interface SelectProductCardProps {
  index?: number;
  product?: Product | null;
  onSelectProduct: () => void;
  onRemoveProduct: () => void;
  hasTitle?: boolean;
}

const SelectProductCard: FC<SelectProductCardProps> = ({
  index = 0,
  product,
  onSelectProduct,
  onRemoveProduct,
  hasTitle = false,
}) => {
  const intl = useIntl();
  const imageUrl = product?.imageCdn?.imageUrl || EmptyImage;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {hasTitle && (
          <h4>{intl.formatMessage({ id: 'label.itemNumber' }, { number: index + 1 })}</h4>
        )}
        <span
          role="presentation"
          onClick={onRemoveProduct}
          className={classnames(styles.clear, 'material-symbols-rounded')}
        >
          close
        </span>
      </div>
      {(imageUrl || product?.name) && (
        <>
          {imageUrl && <img alt="appLogo" src={imageUrl} className={styles.productImage} />}
          <p className="mb-20">{product?.name}</p>
        </>
      )}
      <SimpleButton onClick={onSelectProduct} className="w-100">
        {intl.formatMessage({ id: 'label.selectProduct' })}
      </SimpleButton>
    </div>
  );
};

export default SelectProductCard;

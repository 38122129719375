import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cs from 'classnames';
import { ButtonBase, Button, Popover } from '@mui/material';

import { Scrollbars } from 'react-custom-scrollbars';
import { authUserUidSelector } from 'Store/auth/selectors';
import { deleteMyNotifications } from 'Services/UserService';
import { firebaseGetNotificationsRef } from 'Services/FirebaseService';
import { getRouteByLink } from 'Utils/notification';
import { sortUsersDueRelevantDate } from 'Utils/user';
import { convertObjToArray } from 'Utils/helpers';
import * as styles from './index.module.scss';

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const userUid = useSelector(authUserUidSelector);
  const navigate = useNavigate();
  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    firebaseGetNotificationsRef(userUid)
      .limitToFirst(50)
      .on(
        'value',
        (dataSnapshot) => {
          const sortedNotifications = sortUsersDueRelevantDate(convertObjToArray(dataSnapshot.val()));
          setNotifications(sortedNotifications);
        },
      );
  }, []);

  const handleOpenPopper = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    deleteMyNotifications();
    handleClose();
  };

  const handleClickNotification = (notification) => async () => {
    const to = await getRouteByLink(notification);

    if (to) {
      navigate(to);
      handleClose();
    }
  };

  return (
    <div className={styles.bell}>
      <ButtonBase
        disableRipple
        size="large"
        aria-label="bell"
        className={`${cs(styles.bellButton, { [styles.bellButtonActive]: isOpen })} no-shake`}
        onClick={handleOpenPopper}
      >
        <span className="material-symbols-rounded">
          <span
            className={`${cs(styles.bellIcon, {
              [styles.bellIconActive]: !!notifications.length,
            })}`}
          >
            <span className="notifications" />
          </span>
        </span>
      </ButtonBase>
      {isOpen && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          className={styles.bellContent}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          slotProps={{ paper: { className: styles.popperPaper } }}
          onClose={handleClose}
        >
          <Scrollbars
            className="rct-scroll"
            autoHeight
            autoHeightMax={312}
          >
            {!notifications.length
                && (
                <ul className={styles.centeredText}>
                  <li
                    role="presentation"
                    className={styles.bellItem}
                  >
                    <p><FormattedMessage id="notification.noNewNotifications" /></p>
                  </li>
                </ul>
                )}
            <ul>
              {notifications?.map((notification, key) => (
                <li
                  key={key}
                  role="presentation"
                  onClick={handleClickNotification(notification)}
                  className={`${styles.bellItem} cursor-pointer`}
                >
                  <p>{notification.body}</p>
                  <p>{moment(notification.sentAt).format('DD MMM')}</p>
                </li>
              ))}
            </ul>
          </Scrollbars>
          {!!notifications.length && (
            <div className={styles.bellClear}>
              <Button color="primary" onClick={handleClear}>
                <FormattedMessage id="menu_notification_delete" />
              </Button>
            </div>
          )}
        </Popover>
      )}
    </div>
  );
};

export default Notifications;

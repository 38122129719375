import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import * as styles from './index.module.scss';

const ControlValidation: FC<{ validationErrors: string[] | ReactNode[] }> = ({ validationErrors = [] }) => {
  const intl = useIntl();
  const translatedValidationStrings = validationErrors.map((el, key) => {
    const strEl = typeof el === 'string' ? intl.formatMessage({ id: el }) : el;

    return (
      <div key={key} className={styles.validationMessage}>
        {strEl}
      </div>
    );
  });

  return (
    <div className={styles.validationWrapper}>
      {translatedValidationStrings}
    </div>
  );
};

export default ControlValidation;

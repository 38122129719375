import { Actions } from 'Store/skillsAndIndustries/actions';
import { AnyAction } from '@reduxjs/toolkit';
import { SkillDictionaryItem } from 'Types/skillDictionaryItem.type';
import { IndustryDictionaryItem } from 'Types/industryDictionaryItem.type';

interface State {
  skills: SkillDictionaryItem[],
  industries: IndustryDictionaryItem[],
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: State = {
  skills: [],
  industries: [],
  isLoading: false,
  isLoaded: false,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.GetData:
      return {
        ...state,
        skills: payload.skills,
        industries: payload.industries
          .sort((a: IndustryDictionaryItem, b: IndustryDictionaryItem) => a.label.localeCompare(b.label)),
      };

    case Actions.Loading:
      return {
        ...state,
        isLoading: payload,
      };

    case Actions.Loaded:
      return {
        ...state,
        isLoaded: payload,
      };

    default:
      return state;
  }
};

export default reducer;

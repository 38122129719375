import { useEffect, useState } from 'react';

const useAppVersionCheck = () => {
  const [isOldVersion, setIsOldVersion] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const oldVersion = localStorage.getItem('appVersion');
      const newVersion = (document?.querySelector('meta[name="version"]') as HTMLMetaElement)?.content;

      const hasChanged = oldVersion?.toString() !== newVersion.toString();

      if (hasChanged) {
        console.info('versions', {
          oldVersion,
          newVersion,
          hasChanged,
        });
      }

      if (!oldVersion) {
        localStorage.setItem('appVersion', newVersion);
      } else {
        setIsOldVersion(oldVersion.toString() !== newVersion.toString());
      }
    } catch (err: any) {
      setError(err);
    }
  }, []);

  const clearCache = () => {
    try {
      const newVersion = (document?.querySelector('meta[name="version"]') as HTMLMetaElement)?.content;

      localStorage.setItem('appVersion', newVersion);
      window.location.reload();
    } catch (err: any) {
      setError(err);
    }
  };

  return {
    isOldVersion,
    clearCache,
    error,
  };
};

export default useAppVersionCheck;

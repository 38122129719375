import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { DAY_IN_MILLIS, HOUR_IN_MILLIS, MINUTE_IN_MILLIS } from 'Constants/dates';
import { addSeconds, format } from 'date-fns';

export function getDaysHrsMinTimeUtilFormat(timeRefMs: number, timeUntil: number) {
  const timeDiff = +new Date(timeUntil) - +new Date(timeRefMs);

  const days = Math.floor(timeDiff / DAY_IN_MILLIS);
  const hours = Math.floor(timeDiff / HOUR_IN_MILLIS) % 24;
  const minutes = Math.floor(timeDiff / MINUTE_IN_MILLIS) % 60;

  return {
    days,
    hours,
    minutes,
  };
}

export function isExpired(expiresAt: number) {
  return expiresAt && momentTimezone(expiresAt).isBefore(momentTimezone());
}

export function formatExpireInLabel(expiresAt: number) {
  const { days, hours, minutes } = getDaysHrsMinTimeUtilFormat(new Date().getTime(), +new Date(expiresAt));
  const expiresInLabel = 'expires in';

  if (days) {
    return `${expiresInLabel} ${days}d`;
  }

  if (hours) {
    return `${expiresInLabel} ${hours}h`;
  }

  if (minutes) {
    return `${expiresInLabel} ${minutes}m`;
  }

  return '';
}

export const formatChatTimestamp = (timestamp: number, locale: string) => {
  moment.locale(locale);
  momentTimezone.updateLocale(locale, moment.localeData()._config);
  momentTimezone.locale(locale);

  const d = new Date(timestamp);
  const userZone = momentTimezone.tz.guess();
  const currentDate = momentTimezone();
  const dateToFormat = momentTimezone(timestamp);
  const isCurrentYear = currentDate.year() === dateToFormat.year();

  if (momentTimezone(timestamp).isBefore(momentTimezone(), 'day')) {
    return momentTimezone(timestamp).tz(userZone).format(isCurrentYear ? 'MMMM DD hh:mm A' : 'lll');
  }

  return d.toLocaleString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export function formatTime(timestamp: number) {
  return momentTimezone(timestamp).format('hh:mm');
}

export function formatLongDate(timestamp: number) {
  return momentTimezone(timestamp).format('LLLL');
}

export const formatDateToYears = (dateMs: number) =>
  (dateMs ? Math.floor((Date.now() - dateMs) / 31536000000) : 0); // where 31536000000 - ms per year

export const getTimeOptions = () => {
  const HOURS_OPTIONS = [];
  const MINUTES_OPTIONS = [];
  const AMPM_OPTIONS = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
  ];

  for (let hour = 1; hour <= 12; hour += 1) {
    HOURS_OPTIONS.push({
      label: hour.toString(),
      value: hour.toString(),
    });
  }

  for (let minutes = 0; minutes < 60; minutes += 15) {
    let formattedMinutes = minutes.toString();

    if (minutes === 0) {
      formattedMinutes = '00';
    }

    MINUTES_OPTIONS.push({
      label: formattedMinutes,
      value: formattedMinutes,
    });
  }

  return {
    HOURS_OPTIONS, MINUTES_OPTIONS, AMPM_OPTIONS,
  };
};

export const secondsToDurationStr = (seconds: number) => format(addSeconds(new Date(0), seconds), 'mm:ss');

import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'dripdrop',
  title: 'dripdrop',
  name: 'dripdrop app',
  brandName: 'dripdrop',
  appLinkSchema: 'dripdrop',
  appTld: 'dripdrop.gg',
  tags: ['all', 'sports'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  TERMS_OF_SERVICE_URL: 'https://www.dripdrop.gg/terms-conditions',
  PRIVACY_STATEMENT_URL: 'https://www.dripdrop.gg/privacy-policy',
};

export default config;

import { FC } from 'react';
import classNames from 'classnames';
import { AppConfig } from 'Utils/appConfig';
import * as styles from './index.module.scss';

interface UserAvatarProps {
  className?: string;
  isActive?: boolean;
  url?: string | null;
  size?: 'regular' | 'small';
}

const UserAvatar: FC<UserAvatarProps> = ({ className = '', isActive = false, url, size = 'regular' }) => (
  <div
    className={classNames(styles.userAvatar, {
      [className]: !!className,
      [styles.active]: isActive,
      [styles.sizeRegular]: size === 'regular',
      [styles.sizeSmall]: size === 'small',
    })}
    style={{ backgroundImage: `url(${url || AppConfig.images.userAvatarPlaceholder})` }}
  />
);

export default UserAvatar;

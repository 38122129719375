import { Box, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import classnames from 'classnames';
import useMediaQuery from "@mui/material/useMediaQuery";

import ProductFormPrice from 'Components/ProductFormPrice';
import CustomTokensPriceForm from 'Components/ProductForm/CustomTokensPriceForm';
import TextInput from 'Components/ProductForm/TextInput';
import CustomTextarea from 'Components/CustomTextarea';
import TicketForm from "Components/ProductForm/TicketForm";
import TooltipAnchor from 'Components/TooltipAnchor';
import StyledSelect from "Components/StyledSelect";
import FieldDetailsExtension from "Components/FieldDetailsExtension";

import { DeliveryFeeSource, ProductKind } from 'Constants/enums';
import { getErrorsByName } from 'Utils/formik';
import { getCurrentUseCase } from 'Utils/useCases';
import { DELIVERY_FEE_SOURCE_OPTIONS } from "Constants/dictionaries";
import { BREAKPOINTS } from "Constants/common";
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from './index.module.scss';
import { getDeliveryLabel } from "./utils.ts";

const ProductForm = ({
  formik,
  currencyOptions,
  kind = '',
  onSeeFees,
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(`@media (max-width:${BREAKPOINTS.sm}px)`);
  const PriceForm = !getCurrentUseCase().isCampaigns ? ProductFormPrice : CustomTokensPriceForm;

  const handleCheckboxChange = ({ target: { checked } }) => {
    formik.setValues({
      ...formik?.values,
      onlineProductInfo: {
        ...formik?.values?.onlineProductInfo,
        unlimitedUseQty: checked,
        useQty: '',
      },
    });
  };

  const handleChanges = ({ target: { name, value } }) => {
    formik.setFieldValue(name, value);
  };

  const handleDateChanges = (name, value) => {
    formik.setFieldValue(name, new Date(value).getTime());
  };

  return (
    <Box className={classnames(styles.root, { [styles.extendedWidth]: getCurrentUseCase().isCampaigns })}>
      <form onSubmit={formik.handleSubmit} noValidate className={styles.form}>
        <TextInput
          label="label.name"
          placeholder="placeholder.enterProductName"
          isRequired
          tooltip="hint.product.name"
          name="name"
          value={get(formik?.values, 'name') || ''}
          validationErrors={getErrorsByName(formik, 'name')}
          onChange={handleChanges}
        />

        <div>
          <label className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.description' })}*`}
            {' '}
            <TooltipAnchor translationKey="hint.product.description" />
          </label>
          <CustomTextarea
            name="description"
            placeholder={intl.formatMessage({ id: 'placeholder.addProductDescription' })}
            value={formik?.values?.description || ''}
            onChange={handleChanges}
            validationErrors={getErrorsByName(formik, 'description')}
          />
        </div>

        <TextInput
          label="label.productSku"
          placeholder="placeholder.addSku"
          name="merchantSku"
          isRequired
          tooltip="hint.product.sku"
          value={get(formik?.values, 'merchantSku') || ''}
          validationErrors={getErrorsByName(formik, 'merchantSku')}
          onChange={handleChanges}
        />

        {kind === ProductKind.Token ? (
          <TextInput
            label="label.howManyTokensShouldHave"
            name="onlineProductInfo.tokensQty"
            isRequired
            value={get(formik?.values, 'onlineProductInfo.tokensQty') || ''}
            validationErrors={getErrorsByName(formik, 'onlineProductInfo.tokensQty')}
            onChange={handleChanges}
          />
        ) : (
          <>
            <TextInput
              label="label.quantity"
              placeholder="placeholder.quantity"
              tooltip="hint.product.quantity"
              name="quantity"
              value={get(formik?.values, 'quantity') || ''}
              validationErrors={getErrorsByName(formik, 'quantity')}
              onChange={handleChanges}
              type="number"
            />
            <TextInput
              label="label.additionalInfo"
              placeholder="placeholder.addInformation"
              tooltip="hint.product.addInformation"
              name="additionalInfo"
              value={get(formik?.values, 'additionalInfo') || ''}
              validationErrors={getErrorsByName(formik, 'additionalInfo')}
              onChange={handleChanges}
            />
          </>
        )}

        <PriceForm
          onChange={handleChanges}
          formik={formik}
          currencyOptions={currencyOptions}
        />

        <div>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.shippingOptions' })}
            {' '}
            <TooltipAnchor translationKey="info.shippingOptions" />
          </label>
          <FieldDetailsExtension
            label={
              intl.formatMessage({
                id: getDeliveryLabel(formik?.values?.deliveryFeeSource === DeliveryFeeSource.Product, isMobile)
              })
            }
            hasError={getErrorsByName(formik, 'deliveryFeeSource')?.length > 0}
            labelClassName={styles.link}
            isHidden={formik?.values?.deliveryFeeSource === DeliveryFeeSource.NoShippingRequired}
            onClick={onSeeFees}
          >
            <StyledSelect
              name="deliveryFeeSource"
              value={formik?.values?.deliveryFeeSource || ''}
              onChange={handleChanges}
              validationErrors={getErrorsByName(formik, 'deliveryFeeSource')}
              className={classnames(styles.deliveryField, { [styles.deliveryFieldBorders]: !!formik?.values?.deliveryFeeSource })}
            >
              {DELIVERY_FEE_SOURCE_OPTIONS.map(({ label, value }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </StyledSelect>
          </FieldDetailsExtension>
        </div>

        {kind === ProductKind.Ticket && (
          <TicketForm
            formik={formik}
            onChange={handleChanges}
            onDateChange={handleDateChanges}
            onCheckboxChange={handleCheckboxChange}
          />
        )}

        <TextInput
          label="label.tag"
          placeholder="placeholder.addTag"
          disabled={[ProductKind.Ticket, ProductKind.Token].includes(kind)}
          tooltip="hint.product.tag"
          name="tag"
          value={get(formik?.values, 'tag') || ''}
          validationErrors={getErrorsByName(formik, 'tag')}
          onChange={handleChanges}
        />

        <input type="submit" hidden />
      </form>
    </Box>
  );
};

export default ProductForm;

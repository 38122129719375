import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyC6CiUP1PQvNXjpvCqBtmlp21laISypVjo',
    authDomain: 'vera-af0c2.firebaseapp.com',
    databaseURL: 'https://vera-af0c2-default-rtdb.firebaseio.com',
    projectId: 'vera-af0c2',
    storageBucket: 'vera-af0c2.appspot.com',
    messagingSenderId: '1016973916060',
    appId: '1:1016973916060:web:ab60871cdf2f4bc7b0ba59',
    measurementId: 'G-NJ0NM78WX8',
  },
  API_BASE_URL: 'https://edge-server-dfkcix6pxa-uc.a.run.app',
};

export default config;

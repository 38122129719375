import { isNil } from 'lodash';
import * as Reward from 'Utils/models/Reward';
import { asMap, getPointLabelByUseCase, getPointsAmount } from 'Utils/points';
import { BountyType, PointCurrency, RewardType } from 'Constants/enums';
import * as Money from 'Utils/money';
import { isPartOfCompany, getMyCurrency, isAllowed } from 'Utils/settings';
import { CAN_REWARD_MONEY, VOUCHERS } from 'Constants/op';

//  TODO: add intl after product creation switch to the new reward component
const getRewardName = ({ type, localCurrency = 'USD' }) => {
  switch (type) {
    case RewardType.Badge:
      return 'badge';
    case RewardType.Voucher:
      return 'voucher';
    case RewardType.Points:
      return getPointLabelByUseCase().plural;
    case RewardType.Money:
      return localCurrency;
    case RewardType.Both:
      return 'Both';
    case RewardType.Either:
      return 'Either';
    default:
      return '';
  }
};

export const getRewardTypes = (settings, userData, bountyType) => {
  const isAllowedToAddVouchers = isAllowed({ op: VOUCHERS.name, settings, userData });
  const isAllowedToAddMoney = isAllowed({ op: CAN_REWARD_MONEY.name, settings, userData });
  const rewardTypes = [RewardType.Points];

  if (isAllowedToAddMoney && getMyCurrency(settings, userData)) {
    rewardTypes.unshift(RewardType.Money);
  }

  if (isAllowedToAddVouchers) {
    rewardTypes.push(RewardType.Voucher);
  }

  if (isPartOfCompany(userData) && bountyType !== BountyType.BadgeCollection) {
    rewardTypes.push(RewardType.Badge);
  }

  return rewardTypes;
};

export function generateRewardTypeOptions({ rewardTypes, localCurrency, ignoreUseCase }) {
  const options = [];

  rewardTypes.forEach((type) => {
    const label = getRewardName({ type, localCurrency, ignoreUseCase });

    if (label) {
      options.push({
        label,
        value: type,
      });
    }
  });

  return options;
}

export function getRewardTypesAsOptions({ userData, settings, bountyType }) {
  const localCurrency = getMyCurrency(settings, userData);
  const listOfRewardTypes = getRewardTypes(settings, userData, bountyType);

  return generateRewardTypeOptions({ rewardTypes: listOfRewardTypes, localCurrency });
}

// [ANDROID] This information is set in onCreate functions from createPostActivity and BaseCreateActivity
export function formatFormikReward({ reward, userData, settings }) {
  if (reward) {
    if (Reward.hasMoney(reward, true)) {
      const { amount } = reward.money;
      return {
        amount,
        currency: RewardType.Money, // TODO: check is this type correct (I guess should be Currency)
      };
    }

    if (Reward.hasPoints(reward)) {
      return {
        amount: getPointsAmount(reward?.points, PointCurrency.Point),
        currency: RewardType.Points, // TODO: check is this type correct (I guess should be Currency)
      };
    }

    if (Reward.hasBadge(reward)) {
      return {
        amount: null,
        currency: RewardType.Badge,
        badges: reward.badges || null,
      };
    }

    // TODO: voucher implementation
  }

  const rewardTypes = getRewardTypes(settings, userData);

  return { currency: rewardTypes[0] };
}

export function formatApiReward(rewardData = {}, settings, userData) {
  const { currency, amount, badges } = rewardData;
  const amountValue = !isNil(amount) ? amount.toString() : null;
  const reward = {
    money: null,
    points: null,
    voucher: null,
    badges: null,
  };

  if (!rewardData || !currency || (currency !== RewardType.Badge && !amountValue)) {
    return null;
  }

  if (!currency) {
    return reward;
  }

  switch (currency) {
    case RewardType.Badge:
      reward.badges = badges || null;
      break;
    case RewardType.Points:
      reward.points = asMap({ amount: +amountValue, currency: PointCurrency.Point });
      break;
    case RewardType.Voucher:
      // TODO: needs implementation
      break;
    default:
      reward.money = Money.moneyConstructor(amountValue, getMyCurrency(settings, userData));
      break;
  }

  return reward;
}

import get from 'lodash/get';
import { FormikValues } from 'formik';
import { ReactNode } from 'react';

export function getErrorsByName(formik: FormikValues, name: string) {
  return get(formik.errors, name) && get(formik.touched, name) ? [get(formik.errors, name)] : [];
}

export function formatNestedError(errors: { [key: string]: string[] | ReactNode[]; }, name: string) {
  return errors?.[name] ? [errors[name]] : [];
}

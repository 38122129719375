import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import classnames from 'classnames';
import * as labelStyles from '../../../assets/scss/modules/label.module.scss';
import CustomDropzone from '../../../components/CustomDropzone';
import BadgeAttachedImage from '../../../components/BadgeAttachedImage';
import SimpleButton, { SimpleButtonDecoration } from '../../../components/SimpleButton';

import { Attachment } from '../../../types/attachment.interface';
import * as styles from './index.module.scss';

export interface BadgeAttachmentsProps {
  badge: Attachment[];
  setBadge: (files: Attachment[] | null) => unknown;
  label: string;
  accept: Record<string, []>;
  meaning: string;
  badgeUrl?: string;
  required?: boolean
}

const BadgeAttachments: FC<BadgeAttachmentsProps> = ({
  badge,
  label,
  accept,
  meaning,
  setBadge,
  badgeUrl,
  required = false,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteFile = () => setBadge(badge?.length ? [] : null);
  const handlePickFile = (files: Attachment[]) => setBadge(files);
  const file = badge?.length ? badge[0] : { localUri: badgeUrl } as Attachment;

  return (
    <div>
      <div className={styles.dropzone}>
        <Typography className={classnames(labelStyles.fieldLabel, 'mb-2')}>
          <FormattedMessage id={label} />
          {required ? '*' : ''}
        </Typography>
        {file.localUri
          ? (
            <BadgeAttachedImage file={file} removeImage={handleDeleteFile} />
          ) : (
            <CustomDropzone
              meaning={meaning}
              accept={accept}
              onFilePick={handlePickFile}
              onSetLoading={setIsLoading}
            >
              <SimpleButton
                decoration={SimpleButtonDecoration.Stroke}
                label={intl.formatMessage({ id: 'label.uploadFile' })}
                iconClass="material-symbols-rounded file_upload mr-5"
                isLoading={isLoading}
              />
            </CustomDropzone>
          )}
      </div>
    </div>
  );
};

export default BadgeAttachments;

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyDPBAsMIcjQKzGBBi3-Lx2pJdLy6RHJRMI', // Your Api key will be here - this is the prod
    authDomain: 'dripdrop-prod.firebaseapp.com', // Your auth domain
    databaseURL: 'https://dripdrop-prod.firebaseio.com', // data base url
    projectId: 'dripdrop-prod', // project id
    storageBucket: 'dripdrop-prod.appspot.com', // storage bucket
    messagingSenderId: '6840480424', // messaging sender id
    appId: '1:6840480424:web:139a95fe8ecc429fde089d', // app id
    measurementId: 'G-HTQ7H7JW0T',
  },
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: 'I6V96KFSWJ',
  algolia_api_key: 'b512fb40b05ff2085f0eda1835951f94',
  SENTRY_CONFIG: {
    dsn: 'https://61ef5c5cb39d4fd5b681b040ff153616@o116454.ingest.sentry.io/5251492',
  },
};

export default config;

import { firebaseGetMyProfileRef } from 'Services/FirebaseService';
import { paginationItemsPerPage } from 'Constants/common';
import { getProducts } from 'Services/ProductService';
import { getUserCommentsRef } from 'Services/UserService';

export const ACTIONS = {
  SET_IS_LOADING: 'merchantReducer/setIsLoading',
  SET_PROFILE: 'merchantReducer/setProfile',
  SET_ARE_REVIEWS_LOADING: 'merchantReducer/setAreReviewsLoading',
  SET_REVIEWS: 'merchantReducer/setReviews',
  SET_PRODUCTS: 'merchantReducer/setProducts',
  SET_PRODUCTS_PAGINATION: 'merchantReducer/setProductsPagination',
  SET_PRODUCTS_START_PAGE: 'merchantReducer/setProductsStartPage',
  SET_ARE_PRODUCTS_LOADING: 'merchantReducer/setAreProductsLoading',
  RESET_REDUCER: 'merchantReducer/resetReducer',
};

export const getMerchantProfile = ({ merchantId }) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  firebaseGetMyProfileRef(merchantId)
    .on('value', (dataSnapshot) => {
      const profile = dataSnapshot.val();

      dispatch({ type: ACTIONS.SET_PROFILE, payload: profile });
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
    }, () => {
      dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
    });
};

export const clearMerchantProfile = () => (dispatch) => {
  dispatch({ type: ACTIONS.RESET_REDUCER });
};

export const getMerchantProducts = ({ merchantId, start }) => async (dispatch) => {
  dispatch({ type: ACTIONS.SET_ARE_PRODUCTS_LOADING, payload: true });

  const defaultFilter = {
    listId: 'product',
    start,
    length: paginationItemsPerPage,
    order: 'pinned_at',
  };
  const filter = merchantId ? { ...defaultFilter, groupId: merchantId } : defaultFilter;

  try {
    const { list, totalCount } = await getProducts(filter);
    dispatch({ type: ACTIONS.SET_PRODUCTS, payload: list });
    dispatch({ type: ACTIONS.SET_PRODUCTS_PAGINATION, payload: { start, totalCount } });
  } finally {
    dispatch({ type: ACTIONS.SET_ARE_PRODUCTS_LOADING, payload: false });
  }
};

export const setProductsStartPage = ({ start }) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_PRODUCTS_START_PAGE, payload: start });
};

export const getReviews = ({ merchantId }) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_ARE_REVIEWS_LOADING, payload: true });

  getUserCommentsRef(merchantId)
    .orderByPriority()
    .on('value', (dataSnapshot) => {
      const reviewsData = dataSnapshot.val();
      dispatch({ type: ACTIONS.SET_REVIEWS, payload: reviewsData });
      dispatch({ type: ACTIONS.SET_ARE_REVIEWS_LOADING, payload: false });
    }, () => {
      dispatch({ type: ACTIONS.SET_ARE_REVIEWS_LOADING, payload: false });
    });
};

import { FC } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import MyProfileMenu from 'Components/MyProfileMenu';
import AppLanguageSwitcher from 'Components/AppLanguageSwitcher';
import NotificationsBell from 'Components/Notifications';
import ShoppingCart from 'Components/ShoppingCart';
import PageTitle from 'Components/PageTitle';
import OrderNotifications from 'Containers/OrderNotifications';

import { setIsSidebarOpen } from "Store/settings/actions";

import { canSeeCartSelector, canSeeOrderNotificationsSelector } from 'Store/permissions/selectors';
import { AppConfig } from 'Utils/appConfig';
import { isSidebarOpenSelector } from "Store/settings/selectors";
import { ThunkDispatchType } from "Types/redux.types";
import { BREAKPOINTS } from "Constants/common";
import * as styles from './index.module.scss';

const Header: FC = () => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const isMobile = useMediaQuery(`@media (max-width:${BREAKPOINTS.sm}px)`);
  const canSeeCart = useSelector(canSeeCartSelector, isEqual) || false;
  const isSidebarOpen = useSelector(isSidebarOpenSelector) || false;
  const canSeeOrderNotifications = useSelector(canSeeOrderNotificationsSelector, isEqual) || false;

  const toggleSideBar = () => dispatch(setIsSidebarOpen(!isSidebarOpen));

  return (
    <AppBar position="static" className={`rct-header ${styles.header}`}>
      <Toolbar className={styles.toolbar}>
        {isMobile ? (
          <IconButton onClick={toggleSideBar} size="medium" className={styles.burgerMenu}>
            <span className="material-symbols-rounded">
              <span className="icon_menu" />
            </span>
          </IconButton>
        ) : <PageTitle className={styles.title} />}

        <div className={styles.actions}>
          {canSeeCart && (
            <div className={styles.headerItem}>
              <ShoppingCart />
            </div>
          )}
          <div className={`${styles.headerItem} ${styles.notificationBell}`}>
            <NotificationsBell />
          </div>
          {canSeeOrderNotifications && (
            <div className={styles.headerItem}>
              <OrderNotifications />
            </div>
          )}
          {!AppConfig?.custom?.isLanguageSelectorHidden && (
            <div className={styles.headerItem}>
              <AppLanguageSwitcher />
            </div>
          )}
          <div className={styles.headerItem}>
            <MyProfileMenu />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

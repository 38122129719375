import { FC, useEffect, useState } from 'react';
import { BREAKPOINTS, paginationItemsPerPage, paginationItemsPerPageMobile } from 'Constants/common';
import { ButtonBase } from '@mui/material';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as styles from './index.module.scss';

// Local helpers
const getPagesGroups = (limit: number, total: number, pageGroupSize: number): number[][] => {
  const pages = Math.ceil(total / limit);
  const pagesNumbers = Array.from({ length: pages }, (_, i) => i + 1);
  const pageGroups = [];
  for (let i = 0; i < pagesNumbers.length; i += pageGroupSize) {
    pageGroups.push(pagesNumbers.slice(i, i + pageGroupSize));
  }

  return pageGroups;
};

const getPagesGroupIndexWithPageNumber = (pagesGroups: number[][], pageNumber: number) =>
  pagesGroups.findIndex((arr: number[]) => arr.includes(pageNumber));

interface PaginationProps {
  disabled: boolean;
  limit?: number;
  onChange: (num: number) => unknown;
  offset?: number;
  total: number;
}

// Component
const Pagination: FC<PaginationProps> = ({ disabled = false, limit = paginationItemsPerPage, onChange, offset, total }) => {
  const [pagesGroups, setPagesGroups] = useState<number[][]>([]);
  const [currentPagesGroupIndex, setCurrentPagesGroupIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageGroupSize, setPageGroupSize] = useState<number>(paginationItemsPerPageMobile);
  const isMobile = useMediaQuery(`@media (max-width:${BREAKPOINTS.md}px)`);
  const handleResize = () => {
    setPageGroupSize(!isMobile ? paginationItemsPerPage : paginationItemsPerPageMobile);
  };

  useEffect(
    () => setTotalPages(Math.ceil(total / limit)),
    [limit, total],
  );

  useEffect(
    () => setCurrentPagesGroupIndex(getPagesGroupIndexWithPageNumber(pagesGroups, currentPage)),
    [pagesGroups, currentPage],
  );

  useEffect(
    () => setPagesGroups(getPagesGroups(limit || paginationItemsPerPage, total, pageGroupSize)),
    [limit, total, pageGroupSize],
  );

  useEffect(
    () => setCurrentPage(Math.floor(offset / limit) + 1),
    [pagesGroups, offset],
  );

  useEffect(() => {
    handleResize();
  }, [isMobile]);

  const handleNextPagesGroup = () => setCurrentPagesGroupIndex(currentPagesGroupIndex + 1);

  const handlePrevPagesGroup = () => setCurrentPagesGroupIndex(currentPagesGroupIndex - 1);

  const handlePageChange = (pageNumber: number) =>
    (pageNumber !== currentPage ? onChange((pageNumber - 1) * limit) : null);

  return (
    <div className={classnames(styles.paginationWrapper, { [styles.disabled]: disabled })} data-qa="pagination">
      <ButtonBase
        data-qa="paginationPrevGroup"
        disabled={currentPagesGroupIndex === 0}
        onClick={handlePrevPagesGroup}
      >
        <i className="icon-arrow-left-2" />
        <i className="icon-arrow-left-2" />
      </ButtonBase>
      <ButtonBase
        data-qa="paginationPrev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <i className="icon-arrow-left-2" />
      </ButtonBase>
      {pagesGroups[currentPagesGroupIndex]?.map((pageItem) => (
        <ButtonBase
          data-qa="paginationCurrent"
          key={pageItem}
          onClick={() => handlePageChange(pageItem)}
          className={classnames({ [styles.active]: pageItem === currentPage })}
        >
          {pageItem}
        </ButtonBase>
      ))}
      <ButtonBase
        data-qa="paginationNext"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <i className="icon-arrow-right-3" />
      </ButtonBase>
      <ButtonBase
        data-qa="paginationNextGroup"
        disabled={currentPagesGroupIndex === pagesGroups.length - 1}
        onClick={handleNextPagesGroup}
      >
        <i className="icon-arrow-right-3" />
        <i className="icon-arrow-right-3" />
      </ButtonBase>
    </div>
  );
};

export default Pagination;

import { BountyResponseState, ResponseActions } from 'Constants/enums';

export const DRAFT_BOUNTY_RESPONSE_STATES: BountyResponseState[] = [
  BountyResponseState.None,
  BountyResponseState.Draft,
  BountyResponseState.Pending,
  BountyResponseState.Retracted,
  BountyResponseState.Rejected,
];

export const isDraftBountyResponseState = (state: BountyResponseState) =>
  DRAFT_BOUNTY_RESPONSE_STATES.includes(state);

export const VALID_STATES_BY_ACTIONS: Record<string, string[]> = {
  [ResponseActions.Publish]: [
    BountyResponseState.Draft,
    BountyResponseState.Retracted,
  ],
  [ResponseActions.Edit]: [
    BountyResponseState.Draft,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Refused,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
  ],
  [ResponseActions.Rate]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.ChangeOffer]: [
    BountyResponseState.Draft,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Refused,
    BountyResponseState.Rejected,
  ],
  [ResponseActions.Comment]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.Demote]: [
    BountyResponseState.Draft,
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Refused,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
  ],
  [ResponseActions.Delete]: [
    BountyResponseState.Draft,
    BountyResponseState.Refused,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
  ],
  [ResponseActions.Retract]: [
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.ACK]: [BountyResponseState.Active],
  [ResponseActions.Progress]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
  ],
  [ResponseActions.Accept]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
  ],
  [ResponseActions.Refuse]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
  ],
  [ResponseActions.Message]: [
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Refused,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.Report]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.ChangeRatingValue]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.PinToTop]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.UnpinToTop]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.Reject]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
  ],
  [ResponseActions.Pay]: [
    BountyResponseState.Accepted,
  ],
  [ResponseActions.Bookmark]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.MarkOfficial]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.UnmarkOfficial]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.Like]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.MessageApplicant]: [
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.MessageResponder]: [
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Refused,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.MarkAsHighlight]: [
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Refused,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
  [ResponseActions.UnmarkAsHighlight]: [
    BountyResponseState.Pending,
    BountyResponseState.Active,
    BountyResponseState.Acked,
    BountyResponseState.Progressing,
    BountyResponseState.Accepted,
    BountyResponseState.Refused,
    BountyResponseState.Paying,
    BountyResponseState.Paid,
  ],
};

interface MenuAction {
  readonly text: string;
  readonly icon: string;
  readonly isExpandable?: boolean;
}

export const ACTIONS_LABELS:{ [key: string]: MenuAction } = {
  [ResponseActions.Comment]: {
    text: 'button.comment',
    icon: 'icon-refresh-2',
  },
  [ResponseActions.Rate]: {
    text: 'button.rate',
    icon: 'icon-star',
  },
  [ResponseActions.ChangeRatingValue]: {
    text: 'button.changeRatingValue',
    icon: 'icon-ranking',
  },
  [ResponseActions.Message]: {
    text: 'button.message',
    icon: 'icon-message',
  },
  [ResponseActions.Report]: {
    text: 'button.report',
    icon: 'icon-info-circle',
  },
  [ResponseActions.Reject]: {
    text: 'button.reject',
    icon: 'icon-info-circle',
  },
  [ResponseActions.MessageResponder]: {
    text: 'button.messageResponder',
    icon: 'icon-chat',
  },
  [ResponseActions.MessageApplicant]: {
    text: 'button.messageApplicant',
    icon: 'icon-chat',
  },
  [ResponseActions.PinToTop]: {
    text: 'label.pinToTop',
    icon: 'icon-send-square',
  },
  [ResponseActions.UnpinToTop]: {
    text: 'label.unpinFromTop',
    icon: 'icon-receive-square',
  },
  [ResponseActions.ChangeOffer]: {
    text: 'menu_change_offer',
    icon: 'icon-star',
  },
  [ResponseActions.Edit]: {
    text: 'button.edit',
    icon: 'icon-edit-2',
  },
  [ResponseActions.Retract]: {
    text: 'label.retract',
    icon: 'icon-undo',
  },
  [ResponseActions.Delete]: {
    text: 'button.delete',
    icon: 'icon-trash',
  },
  [ResponseActions.Publish]: {
    text: 'button.publish',
    icon: 'icon-eye',
  },
  [ResponseActions.Demote]: {
    text: 'button.demoteAsComment',
    icon: 'icon-message-2',
  },
  [ResponseActions.Accept]: {
    text: 'button.accept',
    icon: 'icon-like-1',
  },
  [ResponseActions.ACK]: {
    text: 'button.acknowledge',
    icon: 'icon-like-shapes',
  },
  [ResponseActions.Refuse]: {
    text: 'button.refuse',
    icon: 'icon-slash',
  },
  [ResponseActions.Progress]: {
    text: 'button.progress',
    icon: 'icon-star',
  },
  [ResponseActions.Pay]: {
    text: 'button.payoutDots',
    icon: 'icon-direct',
  },
  [ResponseActions.MarkOfficial]: {
    text: 'button.markAsOfficial',
    icon: 'icon-tick-square',
  },
  [ResponseActions.UnmarkOfficial]: {
    text: 'button.unmarkAsOfficial',
    icon: 'icon-shield-cross',
  },
  [ResponseActions.MarkAsHighlight]: {
    text: 'button.markAsHighlights',
    icon: 'icon-lamp-on',
  },
  [ResponseActions.UnmarkAsHighlight]: {
    text: 'button.unmarkAsHighlights',
    icon: 'icon-lamp-slash',
  },
};

import { QueueAlias } from 'Constants/enums';
import { QueueEventData } from 'Types/queueEventData.interface';

export const QUEUES_BASE = 'queues';
export const QUEUES_INDEX = '0';

// TODO: review unused events.
export const queues: Partial<Record<QueueAlias, QueueEventData>> = {
  // User events
  [QueueAlias.UserActivity]: { name: 'user_activity' },
  [QueueAlias.UpdateDevice]: { name: 'device_update' },
  [QueueAlias.AcceptDevice]: { name: 'device_accept' },
  [QueueAlias.RejectDevice]: { name: 'device_reject' },
  [QueueAlias.UpdateUser]: { name: 'user_update', param: 'user' },
  [QueueAlias.UpdateUserProfile]: { name: 'user_profile_update', param: 'user' },
  [QueueAlias.UpdateUserLocation]: { name: 'user_loc_update' },
  [QueueAlias.UpdateCaps]: { name: 'user_caps' },
  [QueueAlias.CreateInviteImg]: { name: 'user_invite_img' },
  [QueueAlias.ReportUser]: { name: 'user_report' },
  [QueueAlias.BlockUser]: { name: 'user_block' },
  [QueueAlias.DeleteUser]: { name: 'user_delete' },
  [QueueAlias.FetchUser]: { name: 'user_fetch' },
  [QueueAlias.FetchRanking]: { name: 'user_ranking_fetch' },
  [QueueAlias.Cashout]: { name: 'user_cashout' },
  [QueueAlias.Legal]: { name: 'user_legal' },
  [QueueAlias.DeeplinkIn]: { name: 'user_deeplink_in', param: 'user' },
  [QueueAlias.NewCreditCard]: { name: 'user_credit_card_new' },
  [QueueAlias.RmCreditCard]: { name: 'user_credit_card_rm' },
  [QueueAlias.DismissBulletin]: { name: 'bulletin_dismiss' },
  [QueueAlias.BulletinAction]: { name: 'bulletin_action' },
  [QueueAlias.CreateChat]: { name: 'chat_create' },
  [QueueAlias.ChatCommand]: { name: 'chat_command' },
  [QueueAlias.OptOut]: { name: 'opt_out', param: 'queue' },
  [QueueAlias.SendInvite]: { name: 'invite_send' },
  [QueueAlias.ResendInvite]: { name: 'invite_resend' },
  [QueueAlias.CancelInvite]: { name: 'invite_cancel' },
  [QueueAlias.AddGroup]: { name: 'group_add' },
  [QueueAlias.DeleteGroup]: { name: 'group_delete' },
  [QueueAlias.UpdateGroup]: { name: 'group_update' },
  [QueueAlias.AddMember]: { name: 'member_add' },
  [QueueAlias.RemoveMember]: { name: 'member_remove' },
  [QueueAlias.AcceptMember]: { name: 'member_accept' },
  [QueueAlias.RejectMember]: { name: 'member_reject' },
  [QueueAlias.NewCompany]: { name: 'company_new' },
  [QueueAlias.AssignCompany]: { name: 'company_assign' },
  [QueueAlias.UpdateCompany]: { name: 'company_update' },
  [QueueAlias.ListEmployees]: { name: 'company_employees_list' },
  [QueueAlias.AddTransaction]: { name: 'tx_add' },
  [QueueAlias.AddComment]: { name: 'comment_add' },
  [QueueAlias.EditComment]: { name: 'comment_edit' },
  [QueueAlias.DeleteComment]: { name: 'comment_del' },
  [QueueAlias.PromoteComment]: { name: 'comment_promo' },
  [QueueAlias.UpdateAccount]: { name: 'account_update' },
  [QueueAlias.SearchBounties]: { name: 'user_search_bounties' },
  [QueueAlias.RequestAccess]: { name: 'user_req_access' },
  [QueueAlias.ForceSignout]: { name: 'user_signout_force' },
  [QueueAlias.Signout]: { name: 'user_signout' },

  //  Bounty events
  [QueueAlias.FetchBounty]: { name: 'bounty_fetch' },
  [QueueAlias.ActivateBounty]: { name: 'bounty_activate', param: 'bounty' },
  [QueueAlias.PostBounty]: { name: 'bounty_post', param: 'bounty' },
  [QueueAlias.AcceptParticipant]: { name: 'bounty_participant_accept' },
  [QueueAlias.RejectParticipant]: { name: 'bounty_participant_reject' },
  [QueueAlias.SealBounty]: { name: 'bounty_seal' },
  [QueueAlias.ApplyBounty]: { name: 'bounty_apply' },
  [QueueAlias.DropoffBounty]: { name: 'bounty_dropoff' },
  [QueueAlias.WorkStartedBounty]: { name: 'bounty_work_started' },
  [QueueAlias.WorkPausedBounty]: { name: 'bounty_work_paused' },
  [QueueAlias.WorkProgressBounty]: { name: 'bounty_work_progress' },
  [QueueAlias.WorkCompletedBounty]: { name: 'bounty_work_completed' },
  [QueueAlias.SendBounty]: { name: 'bounty_send', param: 'bounty' },
  [QueueAlias.UpdateBounty]: { name: 'bounty_update' },
  [QueueAlias.DeleteBounty]: { name: 'bounty_delete' },
  [QueueAlias.RetractBounty]: { name: 'bounty_retract', param: 'bounty' },
  [QueueAlias.ArchiveBounty]: { name: 'bounty_archive', param: 'bounty' },
  [QueueAlias.SimulateBounty]: { name: 'bounty_simulate' },
  [QueueAlias.DismissBounty]: { name: 'bounty_dismiss' },
  [QueueAlias.PauseBounty]: { name: 'bounty_pause', param: 'bounty' },
  [QueueAlias.ResumeBounty]: { name: 'bounty_resume', param: 'bounty' },
  [QueueAlias.ClaimBounty]: { name: 'bounty_claim' },
  [QueueAlias.CloseBounty]: { name: 'bounty_close' },
  [QueueAlias.InvoiceBounty]: { name: 'bounty_invoice', param: 'bounty' },
  [QueueAlias.PayBounty]: { name: 'bounty_pay', param: 'bounty' },
  [QueueAlias.ApproveBounty]: { name: 'bounty_approve' },
  [QueueAlias.RejectBounty]: { name: 'bounty_reject' },
  [QueueAlias.RejectClaim]: { name: 'bounty_claim_reject' },
  [QueueAlias.CancelClaim]: { name: 'bounty_claim_cancel' },
  [QueueAlias.ReportBounty]: { name: 'bounty_report' },
  [QueueAlias.NotifyResumeBounty]: { name: 'bounty_resume_notify' },
  [QueueAlias.CreateShareBountyImg]: { name: 'bounty_share_img' },
  [QueueAlias.CreateBountyLink]: { name: 'bounty_link' },
  [QueueAlias.CreateRecommendation]: { name: 'bounty_recommendation' },
  [QueueAlias.SendRecommendation]: { name: 'bounty_recommend' },
  [QueueAlias.ChangeBountyRatingValue]: { name: 'bounty_change_rating_value' },
  [QueueAlias.PinBounty]: { name: 'bounty_pin' },
  [QueueAlias.BountyEditBadge]: { name: 'bounty_edit_badge' },
  [QueueAlias.ListBountyPayeeCandidates]: { name: 'bounty_payee_candidates' },
  [QueueAlias.ListBountyDistributionTargets]: { name: 'bounty_distribution' },
  [QueueAlias.MoveBountyToList]: { name: 'bounty_move_to_list' },
  [QueueAlias.RebountyBounty]: { name: 'bounty_rebounty' },

  // Response events
  [QueueAlias.ACKResponse]: { name: 'response_ack' },
  [QueueAlias.AcceptResponse]: { name: 'response_accept' },
  [QueueAlias.RefuseResponse]: { name: 'response_refuse' },
  [QueueAlias.PromoteResponse]: { name: 'response_promote' },
  [QueueAlias.UpdateResponse]: { name: 'response_update' },
  [QueueAlias.DeleteResponse]: { name: 'response_delete' },
  [QueueAlias.TalentInterest]: { name: 'response_talent_interest' },
  [QueueAlias.BountyAttachment]: { name: 'response_attachment' },
  [QueueAlias.ReportResponse]: { name: 'response_report' },
  [QueueAlias.ChangeResponseRatingValue]: { name: 'response_change_rating_value' },
  [QueueAlias.RejectResponse]: { name: 'response_reject' },
  [QueueAlias.RetractResponse]: { name: 'response_retract' },
  [QueueAlias.DemoteResponse]: { name: 'response_demote' },
  [QueueAlias.PinResponse]: { name: 'response_pin' },
  [QueueAlias.ResponseEditBadge]: { name: 'response_edit_badge' },
  [QueueAlias.PublishResponse]: { name: 'response_publish' },
  [QueueAlias.SendResponse]: { name: 'send_response' },

  [QueueAlias.GetList]: { name: 'list_get' },
  [QueueAlias.RequestBadge]: { name: 'badge_request' },
  [QueueAlias.RemoveBadge]: { name: 'badge_remove' },

  [QueueAlias.AddAttachment]: { name: 'attachment_add' },
  [QueueAlias.DeleteAttachment]: { name: 'attachment_delete' },
  [QueueAlias.UpdateAttachment]: { name: 'attachment_update' },

  [QueueAlias.AddFavorite]: { name: 'favorite_add' },
  [QueueAlias.RemoveFavorite]: { name: 'favorite_remove' },

  [QueueAlias.TestRetryLater]: { name: 'test_retry_later' },
  [QueueAlias.LikeBounty]: { name: 'bounty_like' },
  [QueueAlias.LikeResponse]: { name: 'response_like' },
  [QueueAlias.FollowUser]: { name: 'user_follow' },
};

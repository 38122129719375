import ChatService from 'Services/ChatService';
import { firebaseGetUserChatRef } from 'Services/FirebaseService';

import { AppDispatch } from 'Types/redux.types';
import { UserChat } from 'Types/chatUser.interface';
import { formatUserChatsFromPSG, userChatSort } from 'Utils/chat';
import { ChatRoomType, ChatState } from 'Constants/enums';

export enum Actions {
  SetLoading = 'userChats/Loading',
  SetChat = 'userChats/SetChat',
}

export const setUserChats = (userChats: UserChat[]) => (dispatch: AppDispatch) =>
  dispatch({ type: Actions.SetChat, payload: userChats });

export const fetchSupportChats = (companyId: string, filterStatus: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SetLoading, payload: true });

  try {
    const params = { companyId };
    const queryParams = {
      ...(filterStatus ? { status: filterStatus } : {}),
    };

    const { rooms } = await ChatService.getCompanySupportChats(params, queryParams);
    const formattedChats = rooms?.length ? formatUserChatsFromPSG(rooms) : [];

    dispatch({ type: Actions.SetChat, payload: formattedChats });
  } catch {
    dispatch({ type: Actions.SetChat, payload: [] });
  } finally {
    dispatch({ type: Actions.SetLoading, payload: false });
  }
};

export const fetchDismissedChats = (userId: string, controller: AbortController) => async (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SetLoading, payload: true });

  try {
    const params = { userId };
    const queryParams = { state: ChatState.Dismissed };
    const response = await ChatService.getUserChats(params, queryParams, controller.signal);

    dispatch({ type: Actions.SetChat, payload: response?.chats || [] });
  } catch {
    dispatch({ type: Actions.SetChat, payload: [] });
  } finally {
    dispatch({ type: Actions.SetLoading, payload: false });
  }
};

export const fetchRecentChatUsers = (userUid: string, isUserUnverified: boolean) => async (dispatch: AppDispatch) => {
  firebaseGetUserChatRef(userUid)
    .on('value', (snapshot) => {
      const snapshotValue = snapshot.val();
      const chats = snapshotValue ? Object.values(snapshotValue) as UserChat[] : [];
      const updatedChats = chats
        .filter((c) => !!c.roomId && c.roomState !== ChatState.Archived)
        .filter((chat: UserChat) => !isUserUnverified || chat.roomType === ChatRoomType.Support)
        .sort(userChatSort);

      dispatch({ type: Actions.SetChat, payload: updatedChats || [] });
    });
};

import { createSelector } from '@reduxjs/toolkit';

import { GenericReducerName } from 'Constants/genericReducerName';
import { calculateCartTotal } from 'Utils/shoppingCart';

export const getCart = (state) => (state[GenericReducerName.Cart].cart || {});
export const getTotalNumberOfProducts = (state) => (state[GenericReducerName.Cart].totalProductsQuantity || 0);
export const getHasCouponAppliedSuccessfully = (state) => (state[GenericReducerName.Cart].hasCouponAppliedSuccessfully || false);

export const getCartTotal = createSelector(
  getCart,
  ({ products }) => (products ? calculateCartTotal(products) : ''),
);

import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'youkno',
  title: 'Youkno',
  name: 'Youkno app',
  brandName: 'Youkno',
  appLinkSchema: 'rebounty',
  appTld: 'youkno.ai',
  tags: ['all'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  customCss: {
    sidebarLogo: 'blueBackgroundLogo',
  },
  TERMS_OF_SERVICE_URL: 'http://youkno.ai/terms/',
  PRIVACY_STATEMENT_URL: 'http://youkno.ai/privacy/',
};

export default config;

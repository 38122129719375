import { getApiData, postApiData } from 'Services/Api';
import { CATEGORIES_OPEN_ROUTE, CATEGORIES_ROUTE } from 'Constants/apiRoutes';
import { Category } from 'Types/category.interface';
import { formatApiRoute } from 'Utils/api';
import { BountyType } from 'Constants/enums';

export const updateCategories = (category: Category, clientId: string) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_ROUTE,
    params: {},
    queryParams: { clientId },
  });

  return postApiData(endpoint, category);
};

export const getOpenCategories = (bountyType: BountyType, clientId?: string) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_OPEN_ROUTE,
    params: { bountyType },
    queryParams: clientId ? { clientId } : {},
  });

  return getApiData<Category>(endpoint);
};

export const getCategories = (clientId: string, bountyType: BountyType) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_ROUTE,
    params: { },
    queryParams: { clientId, categoryType: bountyType },
  });

  return getApiData<Category>(endpoint);
};

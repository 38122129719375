import { IconButton, MenuItem, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty, isNil, set } from 'lodash';

import CustomInput from 'Components/CustomInput';
import StyledSelect from 'Components/StyledSelect';
import SelectProductCard from 'Components/SelectProductCard';
import ProductFilterFlyout from 'Containers/BountyAttachmentManagement/ProductFiltersFlyout';

import { getPublicProducts } from 'Services/ProductService';
import { closeModal, openModal } from 'Store/modal/actions';
import { getOpenCategories } from 'Services/CategoryService';
import { getFilters } from 'Services/FilterService';
import { fetchBounty } from 'Services/bounty/BountyService';

import { Attachment } from 'Types/attachment.interface';
import { ATTACHMENT_ACTION_OPTIONS, PRODUCT_SORTING_OPTIONS } from 'Constants/dictionaries';
import { AttachmentAction, BountyType, CompanyType } from 'Constants/enums';
import { FILTER_CODE_SEPARATOR } from 'Constants/common';
import { Bounty } from 'Types/bounty.interface';
import { productPickModal } from 'Constants/modals';
import { ThunkDispatchType } from 'Types/redux.types';
import { companySettingsSelector, userDataSelector } from 'Store/settings/selectors';
import { notificationToast } from 'Utils/notificationToaster';
import { Category } from 'Types/category.interface';
import { canUseActionSearch } from 'Store/permissions/selectors';
import { Filter } from 'Types/filter.interface';
import { formatCategoriesForAttachment, getSelectedCategories } from 'Utils/categories';
import * as actionableIcons from 'Assets/scss/modules/actionableIcons.module.scss';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';

import { getFiltersParams, getSelectedFilters } from '../utils';
import * as styles from './index.module.scss';

interface AttachmentFormDetailsProps {
  attachment: Attachment;
  onChange: (attachment: Attachment) => void;
  onRemove: () => void;
  slideNumber: number;
  selectedCompanyId?: string;
  customActions?: ReactNode;
}

const AttachmentFormDetails:FC<AttachmentFormDetailsProps> = ({
  attachment,
  onChange,
  onRemove,
  slideNumber,
  customActions = null,
  selectedCompanyId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const userData = useSelector(userDataSelector)?.data || {};

  const [categories, setCategories] = useState<Category>({} as Category);
  const [productFilters, setProductFilters] = useState<Filter[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]> | null>(null);
  const [areFiltersLoading, setAreFiltersLoading] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Bounty | null>(null);

  const isMerchant = currentOrgType === CompanyType.Merchant;
  const companyId = isMerchant ? userData?.company?.id : selectedCompanyId;

  const handleCloseModal = () => dispatch(closeModal());

  useEffect(() => {
    if (canUseActionSearch && companyId) {
      fetchProductCategories();
    }
  }, [canUseActionSearch, companyId]);

  useEffect(() => {
    if (canUseActionSearch && companyId && selectedCategories?.length > 0) {
      fetchProductFilters({ activeFilters: selectedFilters || {}, activeCategories: selectedCategories });
    }
  }, [canUseActionSearch, companyId, selectedCategories, selectedFilters]);

  useEffect(() => {
    if (canUseActionSearch && !isEmpty(categories) && !selectedCategories?.length) {
      setSelectedCategories(getSelectedCategories(attachment, categories));
    }
  }, [attachment, categories]);

  useEffect(() => {
    setSelectedCategories([]);
    setSelectedFilters(null);
  }, [companyId]);

  useEffect(() => {
    if (canUseActionSearch && productFilters?.length > 0 && isNil(selectedFilters)) {
      setSelectedFilters(getSelectedFilters(attachment, productFilters));
    }
  }, [attachment, productFilters]);

  const fetchProductFilters = async (
    { activeFilters = {}, activeCategories = [] }: { activeFilters?: Record<string, string[]> | null, activeCategories?: Category[] },
  ) => {
    try {
      setAreFiltersLoading(true);

      const params = getFiltersParams(activeFilters, activeCategories, companyId);
      const filters = await getFilters(params, BountyType.Product);
      setProductFilters(filters?.filterDefs || []);
    } catch (e: any) {
      notificationToast.error(e.message);
    } finally {
      setAreFiltersLoading(false);
    }
  };

  const fetchProductCategories = async () => {
    try {
      const response = await getOpenCategories(BountyType.Product, companyId);
      setCategories(response);
    } catch (e: any) {
      notificationToast.error(e.message);
    }
  };

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'action.actionUrl' && !value.includes('https://')) {
      return;
    }

    const newAttachment = set(cloneDeep(attachment), name, value);
    onChange(set(newAttachment, name, value));
  };

  const handleSelect = ({ target: { name, value } }: SelectChangeEvent<unknown>) => {
    const newAttachment = set(cloneDeep(attachment), name, value);
    onChange(newAttachment);
  };

  const handleActionTypeChanges = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    onChange({
      ...attachment,
      action: {
        ...attachment?.action,
        actionUrl: value === AttachmentAction.OpenUrl ? 'https://' : '',
        actionType: value as AttachmentAction,
      },
    });
  };

  const handleSelectProduct = (item: Bounty) => {
    onChange({
      ...attachment,
      action: {
        ...attachment?.action,
        bountyIds: item?.id,
      },
    });
    setSelectedProduct(item);
    handleCloseModal();
  };

  const getProducts = (filters: { start: number; length: number }): Promise<{ list: Bounty[], totalCount: number }> =>
    getPublicProducts({ ...filters, companyId });

  const handleOpenProducts = () => {
    if (!companyId) {
      notificationToast.warning('Please select a location'); // TODO: temp solution
    } else {
      dispatch(openModal(productPickModal({
        getProducts,
        handleSelectProduct,
        closeModal: handleCloseModal,
        selectedProducts: selectedProduct ? [selectedProduct] : [],
      })));
    }
  };

  const handleSelectCategory = ({ target: { value } }: SelectChangeEvent<unknown>, index: number) => {
    const isAllOption = value === '';
    const category = isAllOption ? value : JSON.parse(value as string);
    let newCategories = [...selectedCategories];

    if (index + 1 !== selectedCategories?.length) {
      newCategories = newCategories.slice(0, index + 1);
    }

    if (!isAllOption) {
      newCategories.push(category);
    }

    setSelectedCategories(newCategories);
    setSelectedFilters({});

    const newAttachment = set(cloneDeep(attachment), 'action', {
      ...attachment?.action,
      categories: formatCategoriesForAttachment(newCategories),
      tags: '',
    });
    onChange(newAttachment);
  };

  const handleSelectFilter = (newSelectedFilters: Record<string, string[]>) => {
    const tags: string[] = [];
    Object.entries(newSelectedFilters)?.forEach(([filterId, filters]) => {
      filters?.forEach((filterCode) => {
        tags.push(`${filterId}${FILTER_CODE_SEPARATOR}${filterCode}`);
      });
    });

    const newAttachment = set(cloneDeep(attachment), 'action.tags', tags.join(','));
    onChange(newAttachment);

    setSelectedFilters(newSelectedFilters);
  };

  const handleRemoveProduct = () => {
    setSelectedProduct(null);
    onChange({
      ...attachment,
      action: {
        ...attachment?.action,
        bountyIds: '',
      },
    });
  };

  useEffect(() => {
    const { bountyIds, actionType } = attachment?.action || {};

    if (actionType === AttachmentAction.OpenProduct && !!bountyIds && !selectedProduct) {
      fetchProduct();
    }
  }, [attachment, selectedProduct]);

  const fetchProduct = async () => {
    try {
      const response = await fetchBounty(attachment?.action?.bountyIds, null, BountyType.Product);
      setSelectedProduct(response);
    } catch (error: any) {
      notificationToast.error(error.message);
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <p className="font-700 font-15 mb-0">
          {intl.formatMessage({ id: 'label.slideNumber' }, { number: slideNumber })}
        </p>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {customActions}
          <Tooltip
            id="tooltip-delete"
            title={intl.formatMessage({ id: 'label.removeSlide' })}
            placement="top"
          >
            <IconButton className={actionableIcons.iconContainer} onClick={onRemove}>
              <i className="icon-delete" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className={styles.contentPadding}>
        <div className={styles.imageWrapper}>
          <img
            alt="hero"
            src={attachment?.cdnUrl || attachment?.url || attachment?.localUri}
            className={styles.image}
          />
        </div>
        <div className="mb-15">
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.altText' })}
          </Typography>
          <CustomInput
            name="altText"
            placeholder={intl.formatMessage({ id: 'placeholder.describeImageContent' })}
            value={attachment.altText || ''}
            onChange={handleChanges}
          />
        </div>
        <div className="mb-15">
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.attachmentActionType' })}
          </Typography>
          <StyledSelect
            name="action.actionType"
            value={attachment?.action?.actionType || ''}
            onChange={handleActionTypeChanges}
          >
            {ATTACHMENT_ACTION_OPTIONS.map(({ label, value }) => {
              if (!canUseActionSearch && value === AttachmentAction.Search) {
                return null;
              }

              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </StyledSelect>
        </div>
        <div>
          {attachment?.action?.actionType === AttachmentAction.OpenUrl && (
            <Typography className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.actionUrl' })}
            </Typography>
          )}

          {attachment?.action?.actionType === AttachmentAction.OpenUrl && (
            <CustomInput
              name="action.actionUrl"
              placeholder={intl.formatMessage({ id: 'placeholder.imageUrlAction' })}
              value={attachment?.action?.actionUrl || ''}
              onChange={handleChanges}
            />
          )}
          {attachment?.action?.actionType === AttachmentAction.OpenProduct && (
            <SelectProductCard
              onSelectProduct={handleOpenProducts}
              onRemoveProduct={handleRemoveProduct}
              product={selectedProduct?.product}
            />
          )}
          {attachment?.action?.actionType === AttachmentAction.Search && canUseActionSearch && (
            <>
              {selectedCategories?.map((category, index) => {
                const subCategories = category?.children;

                return subCategories && subCategories?.length > 0 ? (
                  <div className="mb-15" key={category?.code}>
                    <Typography className={labelStyles.fieldLabel}>
                      {intl.formatMessage({ id: index === 0 ? 'label.selectCategory' : 'label.selectSubCategory' })}
                    </Typography>
                    <StyledSelect
                      name="banner.category"
                      value={selectedCategories?.[index + 1] ? JSON.stringify(selectedCategories?.[index + 1]) : ''}
                      onChange={(e) => handleSelectCategory(e, index)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: 'label.all' })}
                      </MenuItem>
                      {subCategories?.map((subCategory) => (
                        <MenuItem key={subCategory?.code} value={JSON.stringify(subCategory)}>
                          {subCategory?.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                ) : null;
              })}

              <div className="mb-15">
                <ProductFilterFlyout
                  filters={productFilters}
                  selectedFilters={selectedFilters || {}}
                  onSelectFilters={handleSelectFilter}
                  isLoading={areFiltersLoading}
                />
              </div>

              <div className="mb-15">
                <Typography className={labelStyles.fieldLabel}>
                  {intl.formatMessage({ id: 'label.selectSorting' })}
                </Typography>
                <StyledSelect
                  name="action.sort"
                  value={attachment?.action?.sort || ''}
                  onChange={handleSelect}
                  displayEmpty
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: 'label.featured' })}
                  </MenuItem>
                  {PRODUCT_SORTING_OPTIONS.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttachmentFormDetails;

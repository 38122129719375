import { getApiData } from 'Services/Api';

import { formatApiRoute } from 'Utils/api';
import { RANKINGS_API } from 'Constants/apiRoutes';
import { Ranking } from 'Types/ranking.interface';

const RankingService = {
  async fetchRanking(period: string): Promise<{ rankings: Ranking[] }> {
    const queryParams = {
      full: true,
      period,
    };
    const formattedRoute = formatApiRoute({ endpoint: RANKINGS_API, queryParams });

    return getApiData<{ rankings: Ranking[] }>(formattedRoute);
  },
};

export default RankingService;

import { Actions } from 'Store/languages/actions';
import { AnyAction } from '@reduxjs/toolkit';
import { DictionaryItem } from 'Types/dictionaryItem.type';

interface State {
  languages: DictionaryItem<string>[],
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: State = {
  languages: [],
  isLoading: false,
  isLoaded: false,
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.GetData:
      return {
        ...state,
        languages: payload
          .sort((a: DictionaryItem<string>, b: DictionaryItem<string>) => a.value.localeCompare(b.value)),
      };

    case Actions.Loading:
      return {
        ...state,
        isLoading: payload,
      };

    case Actions.Loaded:
      return {
        ...state,
        isLoaded: payload,
      };

    default:
      return state;
  }
};

export default reducer;

import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'belong',
  title: 'Belong',
  name: 'Belong app',
  brandName: 'Belong',
  appLinkSchema: 'belong',
  appTld: 'belong.is',
  tags: ['all', 'active'],
  auth: {
    disabledPins: ['1515'],
  },
  customCss: {
    sidebarLogo: 'blackBackgroundLogo',
  },
  TERMS_OF_SERVICE_URL: 'http://belong.is/terms/',
  PRIVACY_STATEMENT_URL: 'http://belong.is/privacy/',
};

export default config;

import { isNil } from 'lodash';
import { mergePermissionTypes, permissionsAsEnum } from 'Utils/models/Lists';
import { ALL } from 'Constants/op';
import { AllowMode } from 'Utils/productDefaults';
import { ListInfo, CapInfo, DefaultRoleDef, RoleAuth } from 'Types/baseSettings.interface';
import { AllowMode as AllowModeEnum } from 'Constants/enums';

const isEmptyCapInfo = (capInfo: CapInfo) => isNil(capInfo.allowMode);

export const mergeCapInfo = (curr: CapInfo, newValue: CapInfo) => {
  if (!newValue || isEmptyCapInfo(newValue)) {
    return curr;
  }

  return newValue;
};

const isEmptyListInfo = (listInfo: ListInfo) => listInfo.rank === null && !listInfo.permissionType;

export const mergeListInfo = (curr: ListInfo, newValue: ListInfo) => {
  if (!newValue || isEmptyListInfo(newValue)) {
    return newValue;
  }

  if (isEmptyListInfo(curr)) {
    return newValue;
  }

  let listInfo = JSON.parse(JSON.stringify(curr));

  if (listInfo.rank === null || (newValue.rank && newValue.rank > listInfo.rank)) {
    listInfo.rank = newValue.rank;
  }

  if (!listInfo.permissionType) {
    listInfo = newValue.permissionType;
    return listInfo;
  }

  if (!newValue.permissionType) {
    listInfo.permissionType = mergePermissionTypes(
      permissionsAsEnum(curr.permissionType),
      permissionsAsEnum(listInfo.permissionType),
    );
    return listInfo;
  }

  return listInfo;
};

export const getAllowModeAsEnum = (allowMode: string) => AllowMode.asEnum(allowMode as AllowModeEnum);

export const getRoleDefByName = (roles: Record<string, DefaultRoleDef>, roleName: string) => (
  roles && Object.keys(roles).length && roles[roleName] ? roles[roleName] : null
);

const getRoleAuth = (ops?: Record<string, RoleAuth>, op?: string): RoleAuth | null => (ops && op ? ops[op] : null);

export const getExplicitlyAllowed = (op: string, ops?: Record<string, RoleAuth>) => {
  if (!op) {
    return null;
  }

  const opAuth = getRoleAuth(ops, op);
  return opAuth?.allow || null;
};

export const isForbidden = (op: string, ops?: Record<string, RoleAuth>) => {
  const allAuth = getRoleAuth(ops, ALL.name);

  if (allAuth && allAuth.forbid) {
    return true;
  }

  const opAuth = getRoleAuth(ops, op);
  return !!(opAuth && opAuth.forbid);
};

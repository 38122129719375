import { Drawer } from '@mui/material';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import SimpleButton from 'Components/SimpleButton';
import CustomChip from 'Components/CustomChip';

import { Filter, FilterOption } from 'Types/filter.interface';
import * as styles from './index.module.scss';

interface ProductFilterFlyoutProps {
  filters: Filter[];
  isLoading?: boolean;
  selectedFilters: Record<string, string[]>;
  onSelectFilters: (selectedFilters: Record<string, string[]>) => void;
}

const ProductFilterFlyout: FC<ProductFilterFlyoutProps> = ({
  filters,
  selectedFilters,
  onSelectFilters,
  isLoading = false,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleClearAll = () => {
    onSelectFilters({});
  };

  const handleRemoveFilter = (filterId: string, filterCode: string, event?: MouseEvent<HTMLDivElement>) => {
    if (event) {
      event.stopPropagation();
    }

    const newFilterValue = selectedFilters?.[filterId]?.filter((code) => code !== filterCode);
    onSelectFilters({ ...selectedFilters, [filterId]: newFilterValue });
  };

  const handleSelectFilter = (filterId: string, option: FilterOption) => {
    const filters = selectedFilters?.[filterId] || [];
    const isSelected = filters?.some((selectedFilter) => selectedFilter === option?.code);

    if (!isSelected) {
      onSelectFilters({ ...selectedFilters, [filterId]: [...filters, option?.code] });
    }
  };

  const filterOptions = useMemo(() => {
    const selectedOptions: { option: FilterOption, filterId: string}[] = [];

    Object.keys(selectedFilters)?.forEach((filterId) => selectedFilters?.[filterId]?.forEach((optionCode) => {
      const filter = filters?.find((filter) => filter?.id === filterId);
      const option = filter?.options?.find(({ code }) => code === optionCode);

      if (option && filterId) {
        selectedOptions.push({
          option,
          filterId,
        });
      }
    }));

    return selectedOptions;
  }, [selectedFilters, filters]);

  return (
    <div>
      <SimpleButton
        label={intl.formatMessage({ id: 'button.selectFilters' })}
        onClick={handleOpen}
        className="w-100"
        disabled={!filters?.length}
        isLoading={isLoading}
      />

      {filterOptions?.length > 0 && (
        <div className="mt-15">
          <p className="mb-1">
            {`${intl.formatMessage({ id: 'label.selectedFilters' })}:`}
          </p>

          {filterOptions?.map(({ option, filterId }) => (
            <CustomChip
              key={`chip-${option?.code}`}
              className={styles.chip}
              label={option?.name}
              onClick={() => handleRemoveFilter(filterId, option?.code)}
            />
          ))}
        </div>
      )}

      {isOpen && (
        <Drawer
          variant="temporary"
          anchor="right"
          open={isOpen}
          onClose={handleClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ className: styles.drawer }}
        >
          <div className={styles.headerWrapper}>
            <h3>{intl.formatMessage({ id: 'label.filters' })}</h3>
            <h3 className={styles.clearAll} onClick={handleClearAll}>
              {intl.formatMessage({ id: 'label.clearAll' })}
            </h3>
          </div>

          <div className={styles.content}>
            <div className={styles.filtersWrapper}>
              {filters.map((filter) => (
                <div key={filter.id}>
                  <h4 className={styles.sectionTitle}>{filter.name}</h4>

                  <div className={styles.optionsWrapper}>
                    {filter.options.map((option) => {
                      const isActive = selectedFilters?.[filter?.id]?.some((code: string) => code === option?.code);
                      return (
                        <div
                          key={option?.code}
                          role="presentation"
                          className={classnames(styles.option, { [styles.activeOption]: isActive })}
                          onClick={() => handleSelectFilter(filter?.id, option)}
                        >
                          {option.name}
                          {isActive && (
                            <div
                              role="presentation"
                              className={styles.removeFilter}
                              onClick={(event) => handleRemoveFilter(filter?.id, option?.code, event)}
                            >
                              <span className="material-symbols-rounded">close</span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>

            <div>
              <SimpleButton
                label={intl.formatMessage({ id: 'button.close' })}
                onClick={handleClose}
              />
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default ProductFilterFlyout;

import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { EditorState } from 'draft-js';

export const convertToMarkdown = (editorStateObj: EditorState): string => (
  editorStateObj.getCurrentContent && stateToMarkdown(editorStateObj?.getCurrentContent())
);

/**
 * the library is adding an extra \n at the end of each sequence of \n's
 * we need to remove it otherwise it's breaking the rendering on the other platforms
*/
const removeExtraNewlines = (input: string): string => (
  input.replace(/\n{2,}/g, (match) => '\n'.repeat(match.length - 1))
);

/**
 * in order to display it correctly using the library, we need to add the extra \n back
 */
export const addExtraNewlines = (input: string): string => (
  input.replace(/\n+/g, (match) => '\n'.repeat(match.length + 1))
);

export const convertToEditorStateObj = (markdown: string): EditorState => (
  EditorState.createWithContent(stateFromMarkdown(addExtraNewlines(markdown)))
);

export const prepareMarkdownText = (editorState: EditorState): string => {
  const convertToMD = convertToMarkdown(editorState)
    .replace(/\\_/g, '_')
    .replace(/\u21b5/g, '')
    .replace(/\*\*[^*]+\*\*/gm, (x) => {
      let text = x;
      if (x.indexOf('** ') === 0) {
        text = text.replace('** ', ' **');
      }

      if (x.lastIndexOf(' **') > 0) {
        text = `${text.slice(0, x.lastIndexOf(' **'))}** `;
      }

      return text;
    })
    .trim();
  // Editor inserts a nonEditable charCode of 8203, only when field is empty
  return convertToMD.length === 1 && convertToMD.charCodeAt(0) === 8203 ? '' : removeExtraNewlines(convertToMD);
};

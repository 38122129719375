import { addBountyComments } from 'Services/bounty/StatsService';
import { createBounty, onEditBounty } from 'Services/bounty/BountyService';
import { uploadFile } from 'Services/UploadService';

import { authLoggedUserSelector } from 'Store/auth/selectors';
import { settingsSelector, userDataSelector } from 'Store/settings/selectors';
import { deleteApiData, getApiData, postApiData, putApiData } from 'Services/Api';

import {
  CLIENTS,
  PRODUCT_CHEMICALS_API,
  OPEN_PRODUCTS_API,
  REQUESTS,
  PRODUCT_CUSTOM_CHEMICALS_API,
} from 'Constants/apiRoutes';
import { getQueueName } from 'Utils/queues';
import { newComment } from 'Utils/comment';
import { addValueOfPoints } from 'Utils/models/Reward';
import { prepareFilters } from 'Utils/general';
import { getIdentityMode } from 'Utils/bountyCreation';
import {
  BountyState,
  BountyType,
  PointCurrency,
  QueueAlias,
  EntityType,
  DeliveryFeeSource,
  DeliveryType
} from 'Constants/enums';
import { formatApiRoute } from 'Utils/api';
import { prepareProduct, prepareTags, prepareVariants } from 'Utils/product';

export const uploadProducts = (file) => {
  const pin = window.localStorage.getItem('pin');

  return uploadFile(`${CLIENTS}/${pin}/products`, file);
};

export const getProducts = (filter = {}) => {
  const endpoint = `${REQUESTS}/${getQueueName(QueueAlias.GetList)}?${prepareFilters(filter)}`;

  return getApiData(endpoint);
};

export const getDetailProduct = (bountyId) => {
  const action = getQueueName(QueueAlias.FetchBounty);

  return getApiData(`${REQUESTS}/${action}?bountyId=${bountyId}`)
    .then((response) => response)
    .catch((err) => err);
};

export const onReviewProduct = (bounty, text, rating) => async (dispatch) => {
  const { id } = bounty;
  const comment = await newComment(text, EntityType.bounty, id);

  if (rating && typeof rating === 'number') {
    comment.reward = addValueOfPoints({
      amount: rating,
      currency: PointCurrency.Rating,
    });
  }

  dispatch(addBountyComments(bounty.id, comment));
};

const getStoreData = (state) => ({
  user: authLoggedUserSelector(state).data || {},
});

export function createProduct(values, kind, attachments = null) {
  return (dispatch, getState) => {
    const state = getState();
    const userData = userDataSelector(state)?.data || {};
    const settings = settingsSelector(state)?.data || {};

    const {
      name,
      description,
      additionalInfo,
      merchantSku,
      tag,
      currencyType,
      customCurrency,
      currency,
      visibility,
      deliveryFeeSource,
      extraDeliveryFee,
      ...remainingProps
    } = values;

    const bounty = {
      owner: userData.owner || null,
      author: userData.company || null,
      identityMode: getIdentityMode({ settings, userData }),
      state: BountyState.Draft,
      type: BountyType.Product,
      visibility: visibility || null,
      description: { text: description },
      tags: prepareTags(tag),
      product: {
        ...prepareProduct(userData, kind),
        name,
        description,
        merchantSku,
        additionalInfo,
        deliveryType: deliveryFeeSource === DeliveryFeeSource.NoShippingRequired ? DeliveryType.NoDelivery : null,
        privateListingInfo: { listingMode: currencyType || null },
        variants: prepareVariants({
          kind,
          merchantSku,
          currency,
          customCurrency,
          currencyType,
          extraDeliveryFee: deliveryFeeSource === DeliveryFeeSource.Product ? extraDeliveryFee : null,
          ...remainingProps,
        }),
      },
    };

    return dispatch(createBounty(bounty, attachments));
  };
}

export function editProduct(values, kind, oldBounty, stateAttachments = null, stateRemovedAttachments = []) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getStoreData(state);

    const {
      name,
      description,
      additionalInfo,
      merchantSku,
      tag,
      currencyType,
      customCurrency,
      deliveryFeeSource,
      currency,
      extraDeliveryFee,
      ...remainingProps
    } = values;

    const newBounty = {
      ...oldBounty,
      description: { text: description },
      tags: prepareTags(tag),
      product: {
        ...(oldBounty?.product || {}),
        imageUrl: stateAttachments?.length > 0 ? oldBounty?.product?.imageUrl : '',
        name,
        description,
        merchantSku,
        additionalInfo,
        deliveryType: deliveryFeeSource === DeliveryFeeSource.NoShippingRequired ? DeliveryType.NoDelivery : null,
        privateListingInfo: { listingMode: currencyType || null },
        variants: prepareVariants({
          oldVariants: oldBounty?.product?.variants,
          merchantSku,
          kind,
          currency,
          customCurrency,
          currencyType,
          extraDeliveryFee: deliveryFeeSource === DeliveryFeeSource.Product ? extraDeliveryFee : null,
          ...remainingProps,
        }),
      },
    };

    const payload = {
      ...data,
      newBounty,
      oldBounty,
      stateRemovedAttachments,
      stateAttachments,
    };

    return dispatch(onEditBounty(payload));
  };
}

export const getProductChemicals = (companyId) => {
  const endpoint = formatApiRoute({
    endpoint: PRODUCT_CHEMICALS_API,
    params: { companyId },
    queryParams: {},
  });
  return getApiData(endpoint);
};

export const updateProductChemicals = (companyId, payload) => {
  const endpoint = formatApiRoute({
    endpoint: PRODUCT_CHEMICALS_API,
    params: { companyId },
    queryParams: {},
  });
  return postApiData(endpoint, payload);
};

export const getProductCustomChemicals = (companyId, queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: PRODUCT_CUSTOM_CHEMICALS_API,
    params: { companyId },
    queryParams: queryParams || {},
  });
  return getApiData(endpoint);
};

export const createProductCustomChemicals = (companyId, payload, queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: PRODUCT_CUSTOM_CHEMICALS_API,
    params: { companyId },
    queryParams: queryParams || {},
  });
  return postApiData(endpoint, payload);
};

export const updateProductCustomChemicals = (companyId, payload, queryParams) => {
  const endpoint = formatApiRoute({
    endpoint: PRODUCT_CUSTOM_CHEMICALS_API,
    params: { companyId },
    queryParams: queryParams || {},
  });
  return putApiData(endpoint, payload);
};

export const deleteProductCustomChemicals = (companyId, chemicalId, categoryPath) => {
  const endpoint = formatApiRoute({
    endpoint: PRODUCT_CUSTOM_CHEMICALS_API,
    params: { companyId },
    queryParams: { id: chemicalId, categoryPath },
  });
  return deleteApiData(endpoint);
};

export const getPublicProducts = (filter = {}) => {
  const endpoint = `${OPEN_PRODUCTS_API}?${prepareFilters(filter)}`;
  return getApiData(endpoint);
};

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { notificationToast } from 'Utils/notificationToaster';
import * as styles from './index.module.scss';

interface InviteLinkProps {
  link: string;
}

const InviteLink:FC<InviteLinkProps> = ({ link }) => {
  const intl = useIntl();

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);
      notificationToast.success(intl.formatMessage({ id: 'notification.success.clipboard.copied' }));
    } catch (error) {
      if (error instanceof Error) return notificationToast.error(error.message);
    }
  };

  return (
    <div className={styles.root}>
      <div
        role="presentation"
        onClick={copyLink}
        className={styles.text}
      >
        {link}
      </div>
      <i role="presentation" onClick={copyLink} className="icon-document-copy" />
    </div>
  );
};

export default InviteLink;

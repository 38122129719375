import * as Yup from 'yup';
import { PromotionType } from 'Constants/enums';
import { FormattedMessage } from 'react-intl';

const getDiscountRestriction = ({ multiple } = { multiple: false }) => ({
  is: (type) => type === PromotionType.Discount,
  then: (field) => field
    .nullable()
    .required(multiple ? 'validate.required_fields' : 'validate.required_field'),
});

export const promotionSchema = Yup.object({
  description: Yup
    .string()
    .nullable()
    .required('validate.required_field'),
  type: Yup
    .string()
    .nullable()
    .required('validate.required_field'),
  discount: Yup
    .number()
    .integer()
    .nullable()
    .min(0, <FormattedMessage id="validate.greaterOrEqualValue" values={{ value: 0 }} />)
    .max(100, <FormattedMessage id="validate.lessOrEqualValue" values={{ value: 100 }} />)
    .when('type', getDiscountRestriction()),
  maxUsageQty: Yup
    .number()
    .integer()
    .nullable()
    .min(1, <FormattedMessage id="validate.greaterThanValue" values={{ value: 0 }} />)
    .max(10, <FormattedMessage id="validate.lessOrEqualValue" values={{ value: 10 }} />)
    .when('type', getDiscountRestriction()),
  maxOverallUsageQty: Yup
    .number()
    .integer()
    .nullable()
    .min(0, <FormattedMessage id="validate.greaterOrEqualValue" values={{ value: 0 }} />),
  activateAt: Yup
    .string()
    .nullable()
    .when('type', getDiscountRestriction()),
  expiresAt: Yup
    .string()
    .nullable()
    .when('type', getDiscountRestriction()),
  hours: Yup
    .string()
    .nullable()
    .when('type', getDiscountRestriction({ multiple: true })),
  days: Yup
    .string()
    .nullable()
    .when('type', getDiscountRestriction({ multiple: true })),
  minutes: Yup
    .string()
    .nullable()
    .when('type', getDiscountRestriction({ multiple: true })),
});

import { ConfigEnv } from '../../../src/types/config.interface';

const config: ConfigEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyCo867PMdXcCjETt-JlPYaiXfk4v-mr7Fs',
    authDomain: 'propatrimonioro-prod.firebaseapp.com',
    databaseURL: 'https://propatrimonioro-prod.firebaseio.com',
    projectId: 'propatrimonioro-prod',
    storageBucket: 'propatrimonioro-prod.appspot.com',
    messagingSenderId: '98996751508',
    appId: '1:98996751508:web:9765475e8c59164704b881',
    measurementId: 'G-T0FTSY2TFL',
  },
  API_BASE_URL: 'https://api.youkno.ai',
  algolia_app_id: '',
  algolia_api_key: '',
};

export default config;

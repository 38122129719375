import { createSelector } from '@reduxjs/toolkit';
import { userDataSelector, settingsSelector, companySettingsSelector, userInfoSelector } from 'Store/settings/selectors';
import { BountyType, CompanyType, StreamListType, UserType } from 'Constants/enums';
import { getAllowedBountyTypes } from 'Utils/createBountyOptionsDialog';
import { getAllowedCompanyTypes } from 'Utils/createOrgOptionsDialog';
import { getListDefsVisibleByRole } from 'Utils/models/ListManager';
import { AllowedOpTypes, getGlobalPermissions, UseCases } from 'Utils/globalPermissions';
import { getApplicationConfig } from 'Utils/appConfig';
import { isSystemAdmin } from 'Utils/roleDef';
import * as Ops from 'Constants/op';
import { isAllowed } from 'Utils/settings';
import { getCurrentUseCase } from 'Utils/useCases';
import { getUserSelector } from 'Store/user/selectors';
import { NEEDS_SIGNUP_INTEREST, NEEDS_SIGNUP_AGE_RANGE } from 'Constants/op';

const { useCase } = getApplicationConfig();

export const routePermissionsSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
    });

    if (permissionsByUseCase) {
      return permissionsByUseCase;
    }

    const allowedBountyTypes = getAllowedBountyTypes(settings, userData.data);
    const streamLists = getListDefsVisibleByRole({ settings, userData: userData.data, allowMulti: true });
    const hasMatchDetails = streamLists.some((list) => list.id === StreamListType.MatchDetails);

    const ops = [
      {
        type: AllowedOpTypes.canCreateBounty,
        allowed: allowedBountyTypes.length > 0,
      },
      {
        type: AllowedOpTypes.canSeeSents,
        allowed: settings.companySettings?.organizationType === CompanyType.Special,
      },
      {
        type: AllowedOpTypes.canUploadProducts,
        allowed: allowedBountyTypes.includes(BountyType.Product),
      },
      {
        type: AllowedOpTypes.canCreateJobs,
        allowed: allowedBountyTypes.includes(BountyType.Job), // TODO: remove this if a new op for SEE_JOBS is added
      },
      {
        type: AllowedOpTypes.canSeeJobs,
        allowed: isAllowed({ op: Ops.SEE_JOBS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canNotify,
        allowed: isAllowed({ op: Ops.SEND_NOTIFICATIONS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeePromotions,
        allowed: isAllowed({ op: Ops.CREATE_PROMOTION.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canCreateBadge,
        allowed: isAllowed({ op: Ops.CREATE_BADGE.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeReports,
        allowed: isAllowed({ op: Ops.SEE_REPORTS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeLeaderBoard,
        allowed: isAllowed({ op: Ops.SEE_LEADERBOARD.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeMarket,
        allowed: isAllowed({ op: Ops.SEE_MARKET.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeCart,
        allowed: isAllowed({ op: Ops.SEE_MARKET.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeMyAccount,
        allowed: isAllowed({ op: Ops.SEE_ACCOUNT.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeCompanies,
        allowed: isAllowed({ op: Ops.SEE_COMPANIES.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeResponses,
        allowed: isAllowed({ op: Ops.SEE_RESPONSES.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeRecvOrders,
        allowed: isAllowed({ op: Ops.SEE_RECV_ORDERS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeGroups,
        allowed: isAllowed({ op: Ops.SEE_GROUPS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeApplicants,
        allowed: isAllowed({ op: Ops.SEE_APPLICANTS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canUpdateAccount,
        allowed: isAllowed({ op: Ops.UPDATE_ACCOUNT.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeBlackList,
        allowed: isAllowed({ op: Ops.CAN_BLACKLIST.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canCreateOrganization,
        allowed: isAllowed({ op: Ops.CREATE_ORGANIZATION.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canEditProfile,
        allowed: isAllowed({ op: Ops.EDIT_USER_PROFILE.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeShell,
        allowed: isAllowed({ op: Ops.SHELL_ACCESS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeUsers,
        allowed: isSystemAdmin(userData?.data?.roles),
      },
      {
        type: AllowedOpTypes.canSeeCompany,
        allowed: isAllowed({ op: Ops.SEE_COMPANY.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeMatch,
        allowed: hasMatchDetails,
      },
      {
        type: AllowedOpTypes.canHaveManyInviteLinks,
        allowed: isAllowed({ op: Ops.CAN_HAVE_MANY_INVITE_LINKS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeCompanyMembers,
        allowed: isAllowed({ op: Ops.SEE_USERS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeAnalytics,
        allowed: isAllowed({ op: Ops.SEE_ANALYTICS.name, settings, userData: userData.data }),
      },
      {
        type: AllowedOpTypes.canSeeMessages,
        allowed: true,
      },
      {
        type: AllowedOpTypes.canSeeUserManagement,
        allowed: isAllowed({ op: Ops.SEE_USER_MANAGEMENT.name, settings, userData: userData.data }),
      },
    ];

    return ops.reduce((result, op) => ({ ...result, [op.type]: op.allowed }), {});
  },
);

export const isAllowedToUploadProductsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => {
    const allowedBountyTypes = getAllowedBountyTypes(settings, userData.data);
    return allowedBountyTypes.includes(BountyType.Product);
  },
);

export const canSeeRealIdentitySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_REAL_IDENTITY.name, settings, userData: userData.data }),
);

export const canSeePrivateInfoSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_PRIVATE_INFO.name, settings, userData: userData.data }),
);

export const canAddToBlackListSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_BLACKLIST.name, settings, userData: userData.data }),
);

export const canUpdateAccountSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.UPDATE_ACCOUNT.name, settings, userData: userData.data }),
);

export const canSeeMarketSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_MARKET.name, settings, userData: userData.data }),
);

export const canSeeNegotiatedAmountSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_NEGOTIATE_PRICE.name, settings, userData: userData.data }),
);

export const canSeeGroupEverybodySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_GROUP_EVERYBODY.name, settings, userData: userData.data }),
);

export const allowedOrganizationTypesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getAllowedCompanyTypes(settings, userData.data),
);

export const allowedBountyTypesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getAllowedBountyTypes(settings, userData.data),
);

export const canSeeResponsesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_RESPONSES.name, settings, userData: userData.data }),
);

export const canControlKYCSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CONTROL_KYC.name, settings, userData: userData.data }),
);

export const canSeeApplicantsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_APPLICANTS.name, settings, userData: userData.data }),
);

export const canControlResponsesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CONTROL_RESPONSES.name, settings, userData: userData.data }),
);

export const canEditCapsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.EDIT_CAPS.name, settings, userData: userData.data }),
);

export const canSeePushNotificationsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.MANAGE_CAMPAIGN.name, settings, userData: userData.data }),
);

export const canSeeSearchModeFilterSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_SEARCH_MODE_FILTER.name, settings, userData: userData.data }),
);

export const canSeeEmployeesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_EMPLOYEES.name, settings, userData: userData.data }),
);

export const canArchiveChatSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_ARCHIVE_CHAT.name, settings, userData: userData.data }),
);

export const canAttachProductSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_ATTACH_PRODUCT.name, settings, userData: userData.data }),
);

export const canAssignRolesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.ASSIGN_ROLES.name, settings, userData: userData.data }),
);

export const canEditUserProfileSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.EDIT_USER_PROFILE.name, settings, userData: userData.data }),
);

export const canEditPINSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CONTROL_PIN.name, settings, userData: userData.data }),
);

export const canEditReferralCodeSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CONTROL_REFERRAL_CODE.name, settings, userData: userData.data }),
);

export const canHaveManyInviteLinksSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_HAVE_MANY_INVITE_LINKS.name, settings, userData: userData.data }),
);

export const canCreateBountySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CREATE_BOUNTY.name, settings, userData: userData.data }),
);

export const canCreateOrganizationSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CREATE_ORGANIZATION.name, settings, userData: userData.data }),
);

export const canReportUserSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_REPORT_USER.name, settings, userData: userData.data }),
);

export const canSendMessagesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_SEND_MESSAGES.name, settings, userData: userData.data }),
);

export const canEditCompanySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.EDIT_COMPANY.name, settings, userData: userData.data }),
);

export const canSendInvitesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEND_INVITES.name, settings, userData: userData.data }),
);

export const canSeeUsersSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_USERS.name, settings, userData: userData.data }),
);

export const canCallOutSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_CALL_OUT.name, settings, userData: userData.data }),
);

export const canResyncProductsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_RESYNC_PRODUCTS.name, settings, userData: userData.data }),
);

export const canSeeShellSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SHELL_ACCESS.name, settings, userData: userData.data }),
);

export const canRemoveMemberFromCompanySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.UNASSIGN_EMPLOYEE.name, settings, userData: userData.data }),
);

export const canRemoveClientSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.DELETE_COMPANIES.name, settings, userData: userData.data }),
);

export const canGhostWriteSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_GHOSTWRITE.name, settings, userData: userData.data }),
);

export const canSendBountySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => (
    isAllowed({ op: Ops.CREATE_BOUNTY.name, settings, userData: userData.data })
    && ![UseCases.shop, UseCases.campaigns, UseCases.vera].includes(useCase)
  ),
);

export const canSeeProfileRatingsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.PROFILE_RATINGS.name, settings, userData: userData.data }),
);

export const defaultProfilePermissionSelector = createSelector(
  userDataSelector,
  settingsSelector,
  getUserSelector,
  (userData, settings, userProfile) => ({
    canSeeProfileRatings: isAllowed({ op: Ops.PROFILE_RATINGS.name, settings, userData: userData.data }),
    canSeeFollowers: isAllowed({ op: Ops.SEE_FOLLOWERS.name, settings, userData: userData.data }),
    canSeeFollowing: isAllowed({ op: Ops.SEE_FOLLOWING.name, settings, userData: userData.data }),
    canSeeSupport: isSystemAdmin(userData.data?.roles),
    canSeeUserBadges: ![UseCases.shop, UseCases.campaigns, UseCases.dinamo].includes(useCase),
    canSeeUserOrders: useCase === UseCases.shop && userProfile.userType === UserType.Individual,
    canSeeCampaigns: useCase === UseCases.campaigns,
    canSeePosts: useCase === UseCases.dinamo,
    canSeeShop: useCase === UseCases.campaigns,
    canSeeShopPolicy: useCase === UseCases.campaigns,
  }),
);

export const canAddProductToShopSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase ? permissionsByUseCase?.[AllowedOpTypes.canUploadProducts] : false;
  },
);

export const canCreateTokenPackSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase ? permissionsByUseCase?.[AllowedOpTypes.canCreateTokenPack] : false;
  },
);

export const canSeeOrderNotificationsSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase ? permissionsByUseCase?.[AllowedOpTypes.canSeeOrderNotifications] : false;
  },
);

export const canLockClientSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase ? permissionsByUseCase?.[AllowedOpTypes.canLockClient] : false;
  },
);

export const canSeeOrderHistorySelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase ? permissionsByUseCase?.[AllowedOpTypes.canSeeOrderHistory] : false;
  },
);

export const canSeeCartSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase
      ? permissionsByUseCase?.[AllowedOpTypes.canSeeCart]
      : isAllowed({ op: Ops.SEE_MARKET.name, settings, userData: userData.data });
  },
);

export const canCreateLocationSelector = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase?.[AllowedOpTypes.canCreateLocation];
  },
);

export const canMarkAsSupportAgentSelect = createSelector(
  userDataSelector,
  userInfoSelector,
  settingsSelector,
  companySettingsSelector,
  (userData, userInfo, settings, companySettings) => {
    const permissionsByUseCase = getGlobalPermissions({
      userData,
      userInfo,
      companySettings: companySettings.data,
      settings: settings.data,
    });

    return permissionsByUseCase?.[AllowedOpTypes.canMarkAsSupport];
  },
);

export const onboardingPermissions = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => ({
    canSeeAgeRange: isAllowed({ op: Ops.NEEDS_SIGNUP_AGE_RANGE.name, settings, userData: userData.data }),
    canSeeInterest: isAllowed({ op: Ops.NEEDS_SIGNUP_INTEREST.name, settings, userData: userData.data })
  }),
);

// TODO: change all of this with an Op when we have it
export const canSeeDismissedChats = !getCurrentUseCase().isShop;
export const canSeeCompanyProfile = !getCurrentUseCase().isCampaigns && !getCurrentUseCase().isShop;
export const canSetProductVisibilityUserInternal = getCurrentUseCase().isCampaigns;
export const canSeeProductPurchaseNotice = getCurrentUseCase().isCampaigns;
export const canLoginWithApple = [UseCases.campaigns, UseCases.dinamo].includes(useCase);
export const canSeeReportsAnalytics = ![UseCases.shop, UseCases.dinamo].includes(useCase);
export const canSeeSidebarMoreMenu = ![UseCases.shop, UseCases.campaigns].includes(useCase);
export const canSeeLocationsOrStates = getCurrentUseCase().isShop;
export const canHaveAccountLocked = getCurrentUseCase().isShop;
export const canRedeemTokens = getCurrentUseCase().isCampaigns;
export const canUseActionSearch = getCurrentUseCase().isShop;

import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'banfield',
  title: 'C.A. Banfield',
  name: 'C.A. Banfield app',
  brandName: 'C.A. Banfield',
  appLinkSchema: 'banfield',
  appTld: 'clubabanfield.org',
  tags: ['all', 'sports'],
  auth: {
    disabledPins: ['1515'],
  },
  TERMS_OF_SERVICE_URL: 'https://belong.is/terms',
  PRIVACY_STATEMENT_URL: 'https://belong.is/privacy',
};

export default config;

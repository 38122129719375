import { getUsersFiltersActionName, Actions, UsersFiltersReducerAlias } from 'Store/usersFilters/actions';
import { UsersSortFields } from 'Constants/sort';
import { SearchMode, SortDirection } from 'Constants/enums';
import { formatSortQueryProp } from 'Utils/sort';
import { SortAndSearch } from 'Types/sortAndSearch.type';
import { ByPageReq } from 'Types/byPageReq.interface';
import { UsersFilters } from 'Types/usersFilters.interface';
import { AnyAction } from '@reduxjs/toolkit';

const lsLimitName = 'usersLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

interface State {
  reqProps: SortAndSearch & ByPageReq;
  filters: UsersFilters;
  filterId: string | null;
}

const initialState: State = {
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp(UsersSortFields.LastSignedDate, SortDirection.DESC),
    query: '',
  },
  filters: {
    blacklisted: false,
    citizenship: [],
    country: [],
    provider: [],
    role: [],
    searchMode: SearchMode.Default,
    companyId: '',
  },
  filterId: null,
};

// TODO: add actions type
export const createUsersFiltersReducer = (alias: UsersFiltersReducerAlias) =>
  (state: State = { ...initialState }, action: AnyAction) => {
    const { payload } = action;

    switch (action.type) {
      case getUsersFiltersActionName(Actions.UpdateReqProps, alias):
        if (payload.limit) {
          localStorage.setItem(lsLimitName, payload.limit);
        }
        return {
          ...state,
          reqProps: {
            ...state.reqProps,
            ...payload,
          },
        };

      case getUsersFiltersActionName(Actions.UpdateFilters, alias):
        return {
          ...state,
          reqProps: {
            ...state.reqProps,
            ...payload.reqProps,
          },
          filters: {
            ...state.filters,
            ...payload.filters,
          },
        };

      case getUsersFiltersActionName(Actions.ResetFilters, alias):
        return {
          ...state,
          filters: {
            ...initialState.filters,
            ...payload,
          },
        };

      case getUsersFiltersActionName(Actions.ResetAll, alias):
        return {
          ...initialState,
          filters: { ...initialState.filters, ...payload },
        };

      case getUsersFiltersActionName(Actions.UpdateFilterId, alias):
        return {
          ...state,
          filterId: payload,
        };

      default:
        return state;
    }
  };

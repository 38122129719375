import { FormattedMessage } from 'react-intl';
import JobInformationService from 'Services/JobInformationService';
import { notificationToast } from 'Utils/notificationToaster';
import { formatIndustries, formatSkills } from 'Utils/skillsAndIndustries';
import { AppDispatch, RootState } from 'Types/redux.types';

export enum Actions {
  Loading = 'skillsAndIndustries/Loading',
  Loaded = 'skillsAndIndustries/Loaded',
  GetData = 'skillsAndIndustries/GetData',
}

export const getSkillsAndIndustries = (isForceUpdate: boolean = false) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { skillsAndIndustriesReducer: { isLoaded, isLoading } } = getState();

  if (isForceUpdate || (!isLoaded && !isLoading)) {
    dispatch({ type: Actions.Loading, payload: true });
    JobInformationService.getSkillsAndIndustries()
      .then((response) => {
        dispatch({
          type: Actions.GetData,
          payload: {
            skills: formatSkills(response),
            industries: formatIndustries(response),
          },
        });
      })

      .catch((err) => notificationToast.error(err.message, {
        title: <FormattedMessage id="notification.error.skillsActions" />,
      }))

      .finally(() => {
        dispatch({ type: Actions.Loading, payload: false });
        dispatch({ type: Actions.Loaded, payload: true });
      });
  }
};

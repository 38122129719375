import { array, bool, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/material';
import classnames from 'classnames';

import UploadIconButton from 'Components/UploadIconButton';

import { closeModal, openModal } from 'Store/modal/actions';

import { addNewFile, chooseAttachmentSource } from 'Utils/attachments';
import { addProductModal, addPromotionModal } from 'Constants/modals';
import { AttachmentType } from 'Constants/enums';
import * as styles from './index.module.scss';

const propTypes = {
  options: array,
  attachments: array,
  setAttachments: func.isRequired,
  multiple: bool,
  canAttachProduct: bool,
  allowMultipleProducts: bool,
};

const defaultProps = {
  options: [],
  multiple: true,
  canAttachProduct: false,
  allowMultipleProducts: false,
  attachments: [],
};

const UploadManager = ({
  options,
  multiple,
  canAttachProduct,
  allowMultipleProducts,
  attachments,
  setAttachments,
}) => {
  const uploadOptions = chooseAttachmentSource(options);
  const dispatch = useDispatch();

  const uploadOneFile = (file) => {
    const data = addNewFile(file);

    if (data) {
      const reader = new FileReader();

      reader.onload = (event) => {
        data.localUri = event.target.result;

        if (data.type === AttachmentType.Image) {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            data.w = image.width;
            data.h = image.height;
          };
        }

        if (data.type === AttachmentType.Video) {
          data.data = file;
          data.fileBlob = URL.createObjectURL(file);
          const videoUrl = URL.createObjectURL(file);
          const video = document.createElement('video');
          video.src = videoUrl;

          video.onloadedmetadata = () => {
            data.w = video.videoWidth;
            data.h = video.videoHeight;
            URL.revokeObjectURL(videoUrl);
          };
        }

        setAttachments((prevState) => (
          multiple ? [data, ...prevState] : [data]
        ));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUploadFiles = (acceptedFields) => {
    if (attachments.length > 0 && !multiple) {
      setAttachments([]);
    }

    if (acceptedFields) {
      acceptedFields.forEach((file) => uploadOneFile(file));
    }
  };

  const onCloseModal = () => dispatch(closeModal());

  const onAddProduct = () => {
    dispatch(openModal(addProductModal({
      closeModal: onCloseModal,
      allowMultipleProducts,
    })));
  };

  const onAddPromotion = () => {
    dispatch(openModal(addPromotionModal({
      closeModal: onCloseModal,
    })));
  };

  return (
    <Box display="flex">
      {uploadOptions.map((item, index) => (
        <UploadIconButton
          key={index}
          item={item}
          multiple={multiple}
          handleUploadFiles={handleUploadFiles}
        />
      ))}
      {canAttachProduct && (
        <>
          <Tooltip title="Attach product" arrow>
            <IconButton onClick={onAddProduct} size="large">
              <span className={classnames('material-symbols-rounded', styles.icon)}>
                store
              </span>
            </IconButton>
          </Tooltip>
          <Tooltip title="Attach promotion" arrow>
            <IconButton onClick={onAddPromotion} size="large">
              <span className={classnames('material-symbols-rounded', styles.icon)}>
                local_offer
              </span>
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

UploadManager.propTypes = propTypes;
UploadManager.defaultProps = defaultProps;

export default UploadManager;

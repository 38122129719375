import { FormattedMessage } from 'react-intl';
import { notificationToast } from 'Utils/notificationToaster';
import * as Money from 'Utils/money';
import * as VoucherUtils from 'Utils/voucher';
import * as Badge from 'Utils/badges';
import { addPoints, sumOfAbs, negatePoints, subtractPoints, pointToString } from 'Utils/points';
import { voucherToString } from 'Utils/voucher';
import { Reward } from 'Types/reward.interface';
import { Point } from 'Types/point.interface';
import { Voucher } from 'Types/voucher.interface';

export const hasMoney = (reward: Reward, allowZero = false) => {
  const amount = reward.money?.amount;

  return reward && reward.money && !Number.isNaN(amount ? +amount : amount) && (allowZero || !Money.isZero(reward.money));
};

export const hasPoints = (reward: Reward) => reward && reward.points && Object.keys(reward.points).length > 0 && sumOfAbs(reward.points) > 0;

export const hasVoucher = (reward: Reward) => {
  if (!reward || !reward.vouchers || !Object.keys(reward.vouchers).length) {
    return false;
  }

  return Object.values(reward.vouchers).some((voucher) => VoucherUtils.isValid(voucher));
};

export const hasIntrinsicVoucher = (reward: Reward) => {
  if (!reward || !reward.vouchers || !Object.keys(reward.vouchers).length) {
    return false;
  }

  return Object.values(reward.vouchers).some((voucher) => VoucherUtils.isIntrinsic(voucher));
};

export const hasBadge = (reward: Reward) => reward && reward.badges;

export const toMap = (reward: Reward = {}) => ({
  money: reward?.money || null,
  points: reward?.points || null,
  voucher: reward?.vouchers || null, // TODO: needs implementation
  badges: reward?.badges || null, // TODO: needs implementation
});

export const isPayableDirectly = (reward: Reward) => isPointsOnly(reward) || (hasVoucher(reward) && hasIntrinsicVoucher(reward));

export const isPointsOnly = (reward: Reward) => hasPoints(reward) && !hasMoney(reward) && !hasVoucher(reward) && !hasBadge(reward);

export const addValueOfPoints = (pts: Point) => {
  if (!pts || !pts.amount || !pts.currency) {
    return { points: null };
  }

  return {
    points: {
      [pts.currency]: pts,
    },
  };
};

const isSingleItemReward = (reward: Reward) => {
  if (hasMoney(reward)) {
    return !hasPoints(reward) && !hasVoucher(reward) && !hasBadge(reward);
  }
  if (hasPoints(reward) && reward.points) {
    if (Object.keys(reward.points).length > 1) {
      return false;
    }
    return !hasMoney(reward) && !hasVoucher(reward) && !hasBadge(reward);
  }
  if (hasVoucher(reward)) {
    return !hasMoney(reward) && !hasPoints(reward) && !hasBadge(reward);
  }
  if (hasBadge(reward)) {
    return !hasMoney(reward) && !hasPoints(reward) && !hasVoucher(reward);
  }
  return false;
};

export const unrollReward = (reward: Reward) => {
  if (!reward || (reward && !Object.keys(reward).length)) {
    return [];
  }

  if (isSingleItemReward(reward)) {
    return [reward];
  }

  const list = [];

  if (hasMoney(reward, true)) {
    list.push({ money: reward.money });
  }

  if (hasPoints(reward) && reward.points) {
    Object.keys(reward.points).forEach((key) => {
      list.push({ points: { [key]: reward?.points?.[key] } });
    });
  }

  if (hasVoucher(reward)) {
    list.push({ vouchers: reward.vouchers });
  }

  return list;
};

export const isRewardEmpty = (reward: Reward) => !hasMoney(reward) && !hasPoints(reward) && !hasVoucher(reward) && !hasBadge(reward);

export const negateVouchers = (vouchers: Record<string, Voucher>) => {
  let newVouchers = {};

  Object.keys(vouchers).forEach((key) => {
    newVouchers = {
      ...newVouchers,
      [key]: VoucherUtils.negateVoucher(vouchers[key]),
    };
  });

  return newVouchers;
};

export const negateReward = (reward: Reward): Reward => {
  const {
    money, points, vouchers, badges,
  } = reward;

  if (isRewardEmpty(reward)) {
    return reward;
  }

  const negatedReward: Reward = {} as Reward;

  if (money) negatedReward.money = Money.negateMoney(money);
  if (points) negatedReward.points = negatePoints(points);
  if (vouchers) negatedReward.vouchers = negateVouchers(vouchers);
  if (badges) negatedReward.badges = Badge.negateBadge(badges); // TODO: revisit this

  return negatedReward;
};

export const add = (existingReward: Reward | null, rewardToAdd: Reward | null): Reward | null => {
  if (!existingReward) {
    return rewardToAdd;
  }

  if (!rewardToAdd || isRewardEmpty(rewardToAdd)) {
    return existingReward;
  }

  if (isRewardEmpty(rewardToAdd)) {
    return rewardToAdd;
  }

  if (hasVoucher(existingReward) && hasVoucher(rewardToAdd)) {
    notificationToast.error(<FormattedMessage id="reward.maxOneVoucher" />);
    return null;
  }

  if (hasBadge(existingReward) && hasBadge(rewardToAdd)) {
    notificationToast.error(<FormattedMessage id="reward.maxOneBadge" />);
    return null;
  }

  if (hasMoney(existingReward) && hasMoney(rewardToAdd) && existingReward?.money?.currency !== rewardToAdd?.money?.currency) {
    notificationToast.error(<FormattedMessage id="reward.maxOneCurrency" />);
    return null;
  }

  return {
    money: Money.addMoney(existingReward.money, rewardToAdd.money) || undefined,
    points: addPoints(existingReward.points, rewardToAdd.points) || null,
    vouchers: existingReward.vouchers || rewardToAdd.vouchers,
    badges: existingReward.badges || rewardToAdd.badges,
  };
};

export const sum = (rewards: Reward[] | null) => {
  if (!rewards || !rewards.length) {
    return {};
  }

  if (rewards.length === 1) {
    return rewards[0];
  }

  let result: Reward | null = {};

  rewards.forEach((reward) => {
    result = add(result, reward);
  });

  return result;
};

export const cloneReward = (reward?: Reward) => {
  if (!reward || isRewardEmpty(reward)) {
    return {};
  }

  const {
    money, points, vouchers, badges,
  } = reward;

  return {
    money: money ? { ...money } : null,
    points: points ? { ...points } : null,
    vouchers: vouchers ? { ...vouchers } : null,
    badges: badges ? { ...badges } : null,
  };
};

export const subtractReward = (left?: Reward, right?: Reward) => {
  if (!left) {
    return right ? negateReward(right) : null;
  }

  return subtract(left, right);
};

const subtract = (left?: Reward, right?: Reward) => {
  if (!right || isRewardEmpty(right)) {
    return cloneReward(left);
  }

  if (!left || isRewardEmpty(left)) {
    return negateReward(right);
  }

  return {
    money: Money.subtractMoney(left.money, right.money),
    points: subtractPoints(left.points, right?.points),
    vouchers: left.vouchers || null,
    badges: left.badges || null,
  };
};

export const rewardToString = (reward: Reward) => {
  const arr = [];

  if (isRewardEmpty(reward)) {
    return '';
  }

  if (hasMoney(reward) && reward.money) {
    arr.push(Money.moneyToString(reward.money));
  }

  if (hasPoints(reward) && reward.points) {
    Object.values(reward.points).forEach((point) => {
      arr.push(pointToString(point));
    });
  }

  if (hasVoucher(reward) && reward?.vouchers) {
    Object.values(reward?.vouchers).forEach((voucher) => {
      arr.push(`voucher:${voucherToString(voucher)}`);
    });
  }

  if (hasBadge(reward)) {
    arr.push(`badge:${Badge.badgeToString(reward.badges)}`); // TODO: revisit this
  }

  return arr.join(', ');
};

import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'fcdinamoge',
  title: 'FC Dinamo Tbilisi',
  name: 'FC Dinamo Tbilisi app',
  brandName: 'FC Dinamo Tbilisi',
  appLinkSchema: 'fcdinamoge',
  appTld: 'fcdinamo.ge',
  tags: ['all', 'sports'],
  auth: {
    disabledPins: ['1515'],
    customCss: '',
  },
  TERMS_OF_SERVICE_URL: 'TBA',
  PRIVACY_STATEMENT_URL: 'TBA',
};

export default config;

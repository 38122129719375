export enum GenericReducerProp {
  //  Async
  UserStreams = 'userStreams',
  BountyDetails = 'bountyDetails',
  RefreshBountyDetails = 'refreshBountyDetails',
  AnswerDetails = 'answerDetails',
  BountyAnswers = 'bountyAnswers',
  BountyAnswersComments = 'bountyAnswersComments',
  BountyComments = 'bountyComments',

  UserSents = 'userSents',
  CompanySents = 'companySents',
  CompanySubSents = 'companySubSents',
  CompanySentsDetails = 'companySentDetails',
  SelectedBounty = 'selectedBounty',
  SubBounties = 'subBounties',

  // Auth
  ResetState = 'RESET_STATE',

  //  Sync
  BountyDefaultFilter = 'defaultFilter',
  BountyDefaultSort = 'defaultSort',
  ModalSettings = 'settings',
  ModalIsLoading = 'isLoading',
  ModalSelectedBounty = 'selectedBounties',
}
